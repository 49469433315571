import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import filter from '../../../assets/images/png/filter.png'
import { Div, DriverDiv, PaginationDiv, PaginationButton } from './adminDrivers.styles';
import { FiSearch } from 'react-icons/fi';
import Driver from './driver';
import DriverRegistration from '../../../pages/Service_provider/DriverRegistration/driverRegistration';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAllDriver, setFilterPagination, setFilterPaginationData, setDriverPageNumber } from '../../../slices/admin';
import { baseUrl } from '../../../services/providerApi';
import axios from 'axios';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import toast from 'react-hot-toast'
import { Toaster } from "react-hot-toast";


const AdminDrivers: FC = () => {

    const user: any = useAppSelector((state: any) => state?.auth?.auth)
    const dispatch: any = useAppDispatch()
    const pageNumber: number = useAppSelector((state: any) => state?.admin?.driverCurrentPage)

    const [currentPage, setCurrentPage] = useState<Number>(pageNumber);
    const drivers = useAppSelector((state: any) => state?.admin?.allDriver)
    const filterPagination = useAppSelector((state: any) => state?.admin?.filterPagination)
    const filterPaginationData = useAppSelector((state: any) => state?.admin?.filterPaginationData)
    const [hide, setHide] = useState<boolean>(false);
    const [unHide, setUnHide] = useState<boolean>(false);
    

    console.log(filterPagination,filterPaginationData)
    useEffect(() => {
        dispatch(setFilterPaginationData(false))
        dispatch(setFilterPagination(false))
        
    }, [])
  
    let promise: any;
    const getDrivers = async () => {
        await axios.get(`${baseUrl}list-driver-admin?page=${currentPage}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                dispatch(setAllDriver(response?.data))
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);
                toast.success('Successfully Loaded!')
            }

        }).catch((err) => {
        })
    }

    useEffect(() => {
        getDrivers()
    }, [currentPage,hide,unHide])
    
   

    const handlePageChange = (newPage: number) => {
        // Validate newPage to prevent requesting invalid pages
        if (newPage >= 1) {
            setCurrentPage(newPage);
            dispatch(setDriverPageNumber(newPage))
            promise = toast.loading('Loading...');

        }
    };
    const getFilteredDriver = async (data: string, pageNo:any) => {
        const promise = toast.loading('Loading...');
        await axios.get(`${baseUrl}filter-driver-status/${data}?page=${pageNo}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {

                dispatch(setAllDriver(response?.data))
                // dispatch(setFilterPagination(true))
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);
                toast.success('Successfully Loaded!')
            }

        }).catch((err) => {
        })
    }
    const handlePageChangeTwo = (newPage: number) => {
        // Validate newPage to prevent requesting invalid pages
        if (newPage >= 1) {
            getFilteredDriver(filterPaginationData,newPage)
            dispatch(setDriverPageNumber(newPage))


        }
    };
    let paginationData = drivers?.data?.links
    let realPagination=paginationData?.filter((pagination:any)=>{
      return Number(pagination.label)
    })
  
   
    return (
        <>
            <Toaster />

            <DriverDiv>
                {drivers?.data?.data?.map((driver: any) => {
                    return <Driver 
                    setUnHide={setUnHide}
                    setHide={setHide}
                    key={driver.id} 
                    driverDetails={driver}
                    />
                })
                }
            </DriverDiv>

            <PaginationDiv>
                {realPagination?.map((pageData: any, index: number) => (
                    <PaginationButton
                        key={index}
                        onClick={() => {
                            filterPagination === true ? handlePageChangeTwo(Number(pageData.label)) :
                                handlePageChange(Number(pageData.label))
                        }
                        }
                        disabled={pageData.active}

                    >
                        {pageData.label === '&laquo; Previous' && !pageData.active ? (
                            <><BiChevronLeft /></>
                        ) : pageData.label === 'Next &raquo;' && !pageData.active ? (
                            <><BiChevronRight /></>
                        ) : (
                            <>{pageData.label}</>
                        )}
                    </PaginationButton>
                ))}
            </PaginationDiv>


        </>
    )
}

export default AdminDrivers