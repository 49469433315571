// import "./styles/Footer.css"
import logo from "../../assets/images/svg/roadlers-logo.svg";
import phoneIcon from "../../assets/images/svg/phone-icon.svg";
import locationIcon from "../../assets/images/svg/location-icon.svg";
import facebook from "../../assets/images/png/fbb.png";
import insta from "../../assets/images/png/igg.png";
import twitter from "../../assets/images/png/twitter.png";
import locate from "../../assets/images/png/locate.png";
import call from "../../assets/images/png/call.png";
import {
  AddressDiv,
  FooterContent,
  FooterHeader,
  LocationBox,
  RoadlersFooter,
  H2,
  H3,
} from "./Footer.styles";
import { Link, NavLink } from "react-router-dom";
import { IoLocation } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { RiTwitterLine } from "react-icons/ri";
const Footer = () => {
  return (
    <RoadlersFooter>
      <FooterHeader>
        <div className="footer-header-inner">
          <NavLink to="/" className="logo-wrapper">
            <img src={logo} alt="Roadler-logo" />
          </NavLink>

          <div className="social-icon-wrapper">
            <a
              href="https://www.facebook.com/Roadlers"
              target="_blank"
              className="facebook-social"
            >
              <img src={facebook} alt="" />
            </a>

            <a
              href="https://www.instagram.com/roadlers/"
              target="_blank"
              className="insta-social"
            >
              <img src={insta} alt="" />
            </a>

            <a
              href="https://twitter.com/roadlers?t=pFGGOse56wlj5DwK0al7Sg&s=08"
              target="_blank"
              className="twitter-social"
            >
              {/* <img src={twitter} alt="" /> */}
              <RiTwitterLine />
            </a>
          </div>
        </div>
      </FooterHeader>

      <div className="footer-body">
        <div className="footer-body-inner">
          <div className="first-section">
            <h2>Find vehicles and chauffeurs for rental and travel </h2>
          </div>
        </div>
      </div>
      <FooterContent>
        <div className="left-side">
          <H2>Locations</H2>
          <div className="left-footer-content">
            <LocationBox>
              <AddressDiv>
                <IoLocation />
                <p>Toronto Canada</p>
              </AddressDiv>

              <AddressDiv>
                <IoIosCall />
                <div className="email">
                  <p>+1-647-954-9101</p>
                  <p>E-mail: admin@roadlers.com</p>
                </div>
              </AddressDiv>
            </LocationBox>

            <LocationBox>
              <AddressDiv>
                <IoLocation />

                <p>Victoria Island Lagos Nigeria. (Edo House)</p>
              </AddressDiv>

              <AddressDiv>
                <IoIosCall />

                <div className="email">
                  <p>+2349044442200</p>
                  <p>E-mail: admin@roadlers.com</p>
                </div>
              </AddressDiv>
            </LocationBox>
          </div>
        </div>
        <div className="right-side">
          <div>
            <H3>Earn</H3>
            <Link to="/provider-signup">List your Vehicle</Link>
            <Link to="/provider-signup">Become a chaffeur</Link>
          </div>

          <a
            href="https://api.whatsapp.com/send/?phone=2349044442200&text=Please%2C+I+want+to+make+enquiries+on+Roadlers+services&type=phone_number&app_absent=0"
            target="_blank"
            className="chat"
          >
            Chat Now
          </a>
        </div>
      </FooterContent>
    </RoadlersFooter>
  );
};

export default Footer;
