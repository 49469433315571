import styled from 'styled-components'

export const Input = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:white;
border:1px solid ${(props: any) => props.theme.text};
width:100%;
border-radius:12px;
overflow:hidden;
`
export const IdButton = styled.button`
background: #FFD58C;
color: #000;
padding:4px 12px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
`
export const TextInput = styled.input`
padding: 22px;
width: 60%;
border: none; 
outline: none;
font-size:16px;
border:none;
outline:none;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`
export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-bottom:-40px;
position:absolute;

right:20px;
width:360px;
z-index:1000;
@media only screen and (min-width: 700px) {
  right:200px;

}
@media only screen and (min-width: 1090px) {
  right:300px;

}
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:60%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Radio = styled.button`
border:3px solid #000;
padding:4px;
border-radius:50px;
`
export const Text = styled.div`
font-size:16px;
margin-top:3px;
color:#000;
font-weight:600;
width:100%;

`
export const Texts = styled.div`
font-size:16px;
margin-top:3px;
color:#000;
font-weight:600;
width:60px;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
display:flex;
align-items:center;
justify-content:center;
gap:10px;
padding:8px 15px;
border-radius:5px;
`