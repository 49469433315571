import React, { useState } from 'react'
import { AmountEnteredBox } from '../fundWalletTwo/fundwallettwo.styles'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import { BankInfoWrap, CopiedTxt, FundWalletBankWrap, GreyBoxTextWrap, H23 } from './fundwalletbank.styles'

import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'

const FundWalletBank = ({displaybox, setSubComponent, topUpAmount, bankName, accountNumber, removeWalletModal, handleOverallCheckout, handleShowComponent}: any) => {

    const [copiedTxt, setCopiedTxt] = useState('')

    const handleCopy = () => {
        navigator.clipboard.writeText(accountNumber)

        setCopiedTxt('Copied to clipboard')

        setTimeout(() => { 
           setCopiedTxt('') 
        }, 2000)
    }

  return (
    <FundWalletBankWrap display={displaybox}>
        <BackBtnWrap top={'-58px'} onClick={() =>  handleShowComponent('a')}>
            <img src={leftArrow} />
        </BackBtnWrap>


        {/* <AmountEnteredBox>
            <p>Amount Entered</p>
            <h3>₦{`${(Number(topUpAmount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h3>
        </AmountEnteredBox> */}

        
        <BankInfoWrap>
            <H23>Pay from my bank</H23>
            <CopiedTxt>{copiedTxt}</CopiedTxt>
            <GreyBoxTextWrap onClick={handleCopy}>
                <h3>Transfer to -  {accountNumber} - {bankName}</h3>
                <p>
                Your wallet will reflect your transaction instantly within 10 minutes.
                </p>
            </GreyBoxTextWrap>
        </BankInfoWrap>
       


        <SubmitBtn onClick={() => {
            removeWalletModal();
            handleShowComponent('a')
        }}>
            <p>Done</p>
        </SubmitBtn>
    </FundWalletBankWrap>
  )
}

export default FundWalletBank