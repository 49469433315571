import styled from 'styled-components'



export const DIV = styled.div`
display:flex;
align-items:center;
gap:20px;
justify-content:space-between;
margin-bottom:30px;
width:100%;

`

export const INPUTS = styled.input`
width:100%;
border-radius:7px;
font-size:16px;
`

export const P = styled.p`
color: #fff;
font-size:13px;
margin-left:12px;
margin-bottom:5px;
display:flex;
align-items:center;
gap:5px;

`
export const COLDIV = styled.div`
display:flex;
flex-direction:column;
width:50%;
position:relative;
`
export const SELECTDIV = styled.div`
display:flex;
width:100%;
border-radius:7px;
background:#fff;
overflow:hidden;
align-items:center;
padding:15px 12px;
justify-content:space-between;
`
export const IDDIV = styled.div`
position:relative;
width:100%;
border-radius:9px;
background:#fff;
overflow:hidden;
margin-top:25px;
height:210px;
border:1px solid #fff;
`

export const FILEDIV = styled.div`
padding:.5em;
background:#000;
top:20px;
right:20px;

position:absolute;
display:flex;
align-items:center;
justify-content:center;
border-radius:7px;


`
export const UploadButton = styled.div`
border:1px solid #ffa100;
background:#000;
padding:0px 15px;
display:flex;
gap:8px;
align-items:center;
justify-content:center;
position:absolute;
top:36%;
left:23%;
border-radius:8px;
color:#fff;
`
export const ButtonLabel = styled.p`
font-size:16px;
`
export const SelectDate = styled.div`
padding:.4em;
background:#000;
width:30px;
height:30px;
overflow:hidden;

border-radius:7px;
display:flex;
align-items:center;
justify-content:center;
`
export const Flex = styled.div`
background:#fff;
border-radius:7px;
padding 0 15px ;
display:flex;
align-items:center;
justify-content:center;
`




export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
display:flex;
align-items:center;
justify-content:center;
gap:10px;
`
export const Text = styled.div`
font-size:16px;
margin-top:3px;
color:#000;
font-weight:600;
width:100%;

`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`
export const Important = styled.div`
color:red;
`
export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-bottom:-40px;
position:absolute;
left:-100px;
width:360px;
z-index:1000;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:60%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`

font-size:16px;
margin-top:3px;
color:#000;
font-weight:600;


`
// width:120px;
// white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
export const Radio = styled.button`
border:3px solid #000;
padding:4px;
border-radius:50px;
`


export const Button = styled.button`
background: #FFA100;
color: #000;
width:100%;
border-radius:30px;
padding:15px 10px;
margin-top:3rem;
width:55%;
outline:none;
border:none;
font-size:16px;
font-weight:bold;
`
export const RegionDiv = styled.div`
background: #000;
color: #fff;

border-radius:10px;
padding:14px 10px;
outline:none;
border:1px solid #fff;
font-weight:bold;
`


export const NameDIV = styled.div`
display:flex;
gap:20px;
justify-content:space-between;
flex-direction:column;

width:100%;

`
export const Inputs = styled.input`
width:100%;
border-radius:7px;
`

export const ColDiv = styled.div`
display:flex;
flex-direction:column;
width:50%;

`
export const SelectDiv = styled.div`
display:flex;
flex-direction:column;
width:100%;
border-radius:7px;
background:#fff;
overflow:hidden;

`
export const IdDiv = styled.div`
position:relative;
width:100%;
border-radius:7px;
background:#fff;
overflow:hidden;
margin-top:30px;
`

export const FileDiv = styled.div`
padding:.5em;
background:#000;
top:20px;
right:20px;

position:absolute;
display:flex;
align-items:center;
justify-content:center;
border-radius:7px;


`


export const InputField = styled.input`
color: #868686;
font-size: 16px;
border:none;
 padding:16px 0px;
 outline:none;

`


export const FLEXDIV = styled.div`
display:flex;
width:100%;
gap:20px;
padding:0px;

`
export const FLEXDIV2 = styled.div`
display:flex;
width:100%;
gap:20px;
background:#fff;
border-radius:7px;
overflow:hidden;
margin-top:-15px;
border:none;

`

export const ICONDIV = styled.div`
background:#000;
color:#fff;
width:40%;
display:flex;
align-items:center;
justify-content:center;
font-size:28px;
border-radius:0px 8px 8px 0px;
`


export const Input = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:white;
width:100%;
border-radius:7px;
overflow:hidden;
border:none;
`



