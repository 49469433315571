import React from 'react'
import { BoxesWrap, ColumnWrap, FlexInputWrap, FundWalletThreeWrap, GreyBox, HeaderSection, InputBottomWrap } from './fundwalletthree.styles'

import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import creditCard from '../../assets/images/svg/credit-card.svg'
import person from  '../../assets/images/svg/person-icon.svg'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import ToggleBar from '../toggleBar'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'
import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'

const FundWalletThree = ({displaybox, handleShowComponent, onPay}: any) => {
  return (
    <FundWalletThreeWrap display={displaybox}>
        <BackBtnWrap top={'-43px'} onClick={() => handleShowComponent('a')}>
            <img src={leftArrow} />
        </BackBtnWrap>

        <HeaderSection>
            <h3>Paying with my card </h3>

            <div className="right-side">
                <img src={master} />
                <img src={visa} />
            </div>
        </HeaderSection>

        <GreyBox>
            <FlexInputWrap>
                <input type="text" placeholder='Card Number'/>
                <img src={creditCard}/>
            </FlexInputWrap>

            <FlexInputWrap>
                <input type="text" placeholder='Name'/>
                <img src={person}/>
            </FlexInputWrap>

            <BoxesWrap>
                <ColumnWrap>
                    <label>Exp Date</label>
                    <input type="text"  placeholder='MM/YY'/>
                </ColumnWrap>

                <ColumnWrap>
                    <label>CVV</label>
                    <input type="text"  placeholder='***'/>
                </ColumnWrap>
            </BoxesWrap>

            <BoxesWrap>
                <InputBottomWrap>
                    <input type="text"  placeholder='Poster Code'/>
                </InputBottomWrap>

                <InputBottomWrap>
                    <p> Set as Primary card</p>
                    {/* <ToggleBar /> */}
                </InputBottomWrap>
            </BoxesWrap>
        </GreyBox>

        <SubmitBtn onClick={() => {
            // handleShowComponent('otp');
            onPay();
        }}>
            <p>Pay</p>
        </SubmitBtn>
    </FundWalletThreeWrap>
  )
}

export default FundWalletThree