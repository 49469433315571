import styled from 'styled-components'



export const WalletModalBox = styled.div `
    width: 350px;
    background-color: #fff;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 7px 4px rgba(0,0,0,0.3);
    z-index: 500;
    /* min-height: 400px; */
    padding-top: 30px;
    position: relative;

    & .inner-modal-box {
        padding: 0px 30px;
        padding-top: 10px;
        padding-bottom: 40px;
        height: 500px;
        overflow-y: scroll;

        /* width */
        &::-webkit-scrollbar {
        width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
        background: transparent; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #555; 
        }

        h4 {
            font-size: 16px;
            font-weight: 800;
            color: #ffa100;
        }
    }

    @media only screen and (max-width: 454px) {
        width: 300px;

        .inner-modal-box {
            height: 400px;
        }
    }
`

export const OverallPaymentWrap = styled.div `
    height: calc(100vh - 80px);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media only screen and (max-width: 454px)  {
        height: calc(100vh - 60px);
    }
`

export const PaymentBox = styled(WalletModalBox) `
    left: unset;
    transform: unset;
    top: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .inner-modal-box {
        height: 500px;
    }

    @media only screen and (max-width: 454px)  {
        .inner-modal-box {
            height: 450px;
        }
    }
`

export const RoadlersHomeCone = styled.img `
        position: absolute;
        top: -22px;
        left: 50%;
        width: 100px;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        
`

export const RoadlersBlackCircle = styled.div `
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        width: 20px;
    }

    @media only screen and (max-width: 454px) {

    }
`

export const WalletModalHeader = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
        border: solid 4px #EAEAEA;
        width: 80px;
        border-radius: 10px;
    }
`

export const WalletContentWrap = styled.div `
    margin-top: 25px;
`