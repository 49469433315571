import React, { FC, useEffect, useState, useCallback, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import xmark from "../../../assets/images/png/X.png";
import mark from "../../../assets/images/png/Y.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  HourButton,
  DISCOUNT, 
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  Input,
  MARKDIV,
  Wrapper,
  BlackOpacity,
  Text,
  DropDown,
  ServiceLabel,
  FullDayInput,
  HalfDayInput,
  HourlyInput,
} from "./fourthScreen.styles";
import DiscountModal from "../../discountModal";
import {
  setServiceVisibility,
  setVehicleDetailss,
} from "../../../slices/provider";
import { useAppDispatch, useAppSelector } from "../../../store";
import { DeleteModal } from "../deleteModal";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";
const FourthScreen: FC<{
  setVehicleDetails: any;
  vehicleDetails: any;
  setWidth: any;
  setQuestion: any;
}> = ({ vehicleDetails, setVehicleDetails, setWidth, setQuestion }) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const vehicleInfo = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails
  );
  const [isDiscountVisible, setIsDiscountVisible] = useState(false);

  const fullDayServiceVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.fullDayServiceVisible
  );
  const halfDayServiceVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.halfDayServiceVisible
  );
  const hourlyRateVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.hourlyRateVisible
  );

  let halfDayPrice = vehicleInfo?.service_price?.filter(
    (price: { service_title?: string }) => {
      return price?.service_title?.includes("Half Day Service");
    }
  );

  let hourlyPrice = vehicleInfo?.service_price?.filter(
    (price: { service_title?: string }) => {
      return price?.service_title?.includes("Hourly Rate");
    }
  );

  let dayPrice = vehicleInfo?.service_price?.filter(
    (price: { service_title?: string }) => {
      return price?.service_title?.includes("Full Day Service");
    }
  );

  const [discount, setDiscount] = useState(dayPrice[0]?.discount || 0);
  const [halfDayDiscount, setHalfDayDiscount] = useState(
    halfDayPrice[0]?.discount || 0
  );
  const [hourlyDiscount, setHourlyDiscount] = useState(
    hourlyPrice[0]?.discount || 0
  );

  const [isHalfDayDiscountVisible, setIsHalfDayDiscountVisible] =
    useState(false);
  const [isHourlyDiscountVisible, setIsHourlyDiscountVisible] = useState(false);

  const [isFullDayDel, setIsFullDayDel] = useState(false);
  const [isHalfDayDel, setIsHalfDayDel] = useState(false);
  const [isHourlyDel, setIsHourlyDel] = useState(false);

  const [fullDayServiceHour, setFullDayServiceHour] = useState(
    Number(dayPrice[0]?.hour)
  );
  const [fullDayServicePrice, setFullDayServicePrice] = useState(
    dayPrice[0]?.price || ""
  );

  const [halfDayServiceHour, setHalfDayServiceHour] = useState(
    Number(halfDayPrice[0]?.hour)
  );
  const [halfDayServicePrice, setHalfDayServicePrice] = useState(
    halfDayPrice[0]?.price || ""
  );

  const [hourlyRateHour, setHourlyRateHour] = useState(
    Number(hourlyPrice[0]?.hour)
  );
  const [hourlyRatePrice, setHourlyRatePrice] = useState(
    hourlyPrice[0]?.price || ""
  );

  useEffect(() => {
    setWidth(50);
    setQuestion(4);
  }, []);

  const BlurOptionFullday = () => {
    setIsFullDayDel((prev) => !prev);
  };
  const BlurOptionHalfday = () => {
    setIsHalfDayDel((prev) => !prev);
  };
  const BlurOptionHour = () => {
    setIsHourlyDel((prev) => !prev);
  };

  let fullDayArray = [
    {
      price: fullDayServicePrice,
      hour: fullDayServiceHour,
      discount: 0,
      service_title: "Full Day Service",
    },
  ];

  let halfDayArray = [
    {
      price: halfDayServicePrice,
      hour: halfDayServiceHour,
      discount: 0,
      service_title: "Half Day Service",
    },
  ];

  let hourlyArray = [
    {
      price: hourlyRatePrice,
      hour: hourlyRateHour,
      discount: 0,
      service_title: "Hourly Rate",
    },
  ];

  const dispatch: any = useAppDispatch();

  const operation = (x: any, data: any, title: string) => {
    if (x === "0" || x === "") {
      return;
    } else {
      const updatedData = [
        {
          ...data[0],
          price: x,
        },
      ];

      let newServicePrice = [...vehicleDetails.service_price, ...updatedData];
      const updatedServicePrice = newServicePrice?.map((service: any) => {
        if (service.service_title === title) {
          return {
            ...service,
            price: x,
          };
        }
        return service;
      });

      // this is to prevent the price deta from getting duplicated
      //@ts-ignore
      const uniqueServicePrices = updatedServicePrice.reduce(
        (uniquePrices, price) => {
          if (
            !uniquePrices.some(
              (p: any) => p.service_title === price.service_title
            )
          ) {
            uniquePrices.push(price);
          }
          return uniquePrices;
        },
        []
      );
      setVehicleDetails({
        ...vehicleDetails,
        service_price: uniqueServicePrices,
      });
    }
  };
  const hourOperation = (hour: any, data: any, title: string) => {
    if (hour === "0" || hour === "") {
      return;
    } else {
      const updatedData = [
        {
          ...data[0], // Spread the properties of the existing object
          hour: hour, // Update the price property with the desired value
        },
        ...data.slice(1), // Spread the remaining objects in the data array
      ];
      let newServicePrice = [...vehicleDetails.service_price, ...updatedData];

      const updatedServicePrice = newServicePrice?.map((service: any) => {
        if (service.service_title === title) {
          return {
            ...service,
            hour: hour,
          };
        }
        return service;
      });

      // this is to prevent the price deta from getting duplicated
      //@ts-ignore
      const uniqueServicePrices = updatedServicePrice.reduce(
        (uniquePrices, hour) => {
          if (
            !uniquePrices.some(
              (p: any) => p.service_title === hour.service_title
            )
          ) {
            uniquePrices.push(hour);
          }
          return uniquePrices;
        },
        []
      );
      setVehicleDetails({
        ...vehicleDetails,
        service_price: uniqueServicePrices,
      });
    }
  };

  function dispatchDetails() {
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        service_price: [...vehicleDetails?.service_price],
      })
    );
  }

  useEffect(() => {
    dispatchDetails();
  }, [fullDayServicePrice]);

  const handleFullDayPrice = (event: any) => {
    setFullDayServicePrice(event);
    operation(event, fullDayArray, "Full Day Service");
  };
  const handleHalfDayPrice = (event: any) => {
    setHalfDayServicePrice(event);
    operation(event, halfDayArray, "Half Day Service");
  };

  const handleHourlyRatePrice = (event: any) => {
    setHourlyRatePrice(event);
    operation(event, hourlyArray, "Hourly Rate");
  };

  const handleEnableFullDay = () => {
    dispatch(setServiceVisibility({ fullDayServiceVisible: true }));
  };
  const handleEnableHalfDay = () => {
    dispatch(setServiceVisibility({ halfDayServiceVisible: true }));
  };
  const handleEnableHourly = () => {
    dispatch(setServiceVisibility({ hourlyRateVisible: true }));
  };

  // useEffect(() => {
  //     dispatchHalfDayBlur()
  // }, [isFulldayVisible])

  return (
    <>
      {fullDayServiceVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}
      {isFullDayDel && (
        <DeleteModal
          setIsFullDayDel={setIsFullDayDel}
          vehicleDetails={vehicleDetails}
          isFullDayDel={isFullDayDel}
          setVehicleDetails={setVehicleDetails}
          setFullDayServiceHour={setFullDayServiceHour}
          setDiscount={setDiscount}
          setFullDayServicePrice={setFullDayServicePrice}
          deleteFor="Full Day Service"
        />
      )}
      <FLEXDIV>
        <FullDayInput fullDayServiceVisible={fullDayServiceVisible}>
          <ServiceLabel htmlFor="">Full Day Service</ServiceLabel>
          <HourButton>
            <P style={{ left: "0px", top: "-37px" }}>Hours</P>
            {fullDayServiceHour ? (
              <Text>{fullDayServiceHour}</Text>
            ) : (
              <Text>{24} </Text>
            )}
            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (fullDayServiceHour > 23) return;

                  setFullDayServiceHour((prev: any) => prev + 1);
                  hourOperation(
                    fullDayServiceHour + 1,
                    fullDayArray,
                    "Full Day Service"
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (fullDayServiceHour < 11) return;
                  setFullDayServiceHour((prev: any) => prev - 1);
                  hourOperation(
                    fullDayServiceHour - 1,
                    fullDayArray,
                    "Full Day Service"
                  );
                }}
              />
            </DropDown>
          </HourButton>

          <BLACKDIV>
            <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
            <Input>
              <CurrencyInput
                style={{
                  // background: "transparent",
                  border: "none",
                  outline: "none",
                  fontWeight:"600",
                  padding:"5px"

                  
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}
                placeholder="0.00"
                value={fullDayServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleFullDayPrice(e)}
              />
            </Input>

            <DISCOUNT onClick={() => setIsDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-45px" }}>Discount</P>
              {discount}%
            </DISCOUNT>
          </BLACKDIV>
        </FullDayInput>

        <MARKDIV style={{backgroundColor:"transparent"}}>
          {/* {fullDayServiceVisible ? (
            <img onClick={BlurOptionFullday} src={xmark} alt="" width={17} />
          ) : (
            <img
              src={mark}
              alt=""
              width={18}
              onClick={() => handleEnableFullDay()}
            />
          )} */}
        </MARKDIV>
      </FLEXDIV>

      {isDiscountVisible && (
        <DiscountModal
          setDiscount={setDiscount}
          discount={discount}
          discountFor="Full Day Service"
          fullDayArray={fullDayArray}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setIsDiscountVisible={setIsDiscountVisible}
        />
      )}
      {halfDayServiceVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {isHalfDayDel && (
        <DeleteModal
          setIsHalfDayDel={setIsHalfDayDel}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setFullDayServiceHour={setFullDayServiceHour}
          setDiscount={setDiscount}
          setHalfDayServicePrice={setHalfDayServicePrice}
          deleteFor="Half Day Service"
          isHalfDayDel={isHalfDayDel}
        />
      )}
      <FLEXDIV>
        <HalfDayInput halfDayServiceVisible={halfDayServiceVisible}>
          <ServiceLabel htmlFor="">Half Day Service</ServiceLabel>
          <HourButton
          // onClick={openModalTwo}
          >
            <P style={{ left: "0px", top: "-37px" }}>Hours</P>

            {halfDayServiceHour ? (
              <Text>{halfDayServiceHour}</Text>
            ) : (
              <Text>{12} </Text>
            )}

            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (halfDayServiceHour > 11) return;
                  setHalfDayServiceHour((prev: any) => prev + 1);
                  hourOperation(
                    halfDayServiceHour + 1,
                    halfDayArray,
                    "Half Day Service"
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (halfDayServiceHour < 7) return;
                  setHalfDayServiceHour((prev: any) => prev - 1);
                  hourOperation(
                    halfDayServiceHour - 1,
                    halfDayArray,
                    "Half Day Service"
                  );
                }}
              />
            </DropDown>
          </HourButton>

          <BLACKDIV>
            <P style={{ left: "0px", top: "-37px" }}>Set Price</P>

            <Input>
              <CurrencyInput
                style={{
                  border: "none",
                  outline: "none",
                  padding:"5px",
                  fontWeight:"600",

                  // background:"transparent"
                  
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}
                placeholder="0.00"
                value={halfDayServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleHalfDayPrice(e)}
              />
            </Input>
            <DISCOUNT onClick={() => setIsHalfDayDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-45px" }}>Discount</P>
              {halfDayDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </HalfDayInput>
        <MARKDIV onClick={() => handleEnableHalfDay()}>
          {halfDayServiceVisible ? (
            <img onClick={BlurOptionHalfday} src={xmark} alt="" width={17} />
          ) : (
            <img src={mark} alt="" width={18} />
          )}
        </MARKDIV>
      </FLEXDIV>

      {isHalfDayDiscountVisible && (
        <DiscountModal
          setDiscount={setHalfDayDiscount}
          discount={halfDayDiscount}
          discountFor="Half Day Service"
          fullDayArray={halfDayArray}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setIsDiscountVisible={setIsHalfDayDiscountVisible}
        />
      )}
      {hourlyRateVisible ? (
        ""
      ) : (
        <>
          {!vehicleDetails.vehicle_type.includes("Bike") && (
            <Wrapper
              style={{
                width: "100%",
                position: "relative",
                height: "68px",
                zIndex: 6,
                marginTop: -66,
              }}
            >
              <BlackOpacity
                style={{
                  background: "#000",
                  opacity: 0.8,
                  position: "absolute",
                  width: "90%",
                  bottom: -118,
                  height: "100%",
                }}
              ></BlackOpacity>
            </Wrapper>
          )}
        </>
      )}
      {isHourlyDel && (
        <DeleteModal
          setIsHourlyDel={setIsHourlyDel}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setHourlyRateHour={setHourlyRateHour}
          setDiscount={setDiscount}
          setHourlyRatePrice={setHourlyRatePrice}
          deleteFor="Hourly Rate"
          isHourlyDel={isHourlyDel}
        />
      )}

      {vehicleDetails.vehicle_type.includes("Bike") ? (
        ""
      ) : (
        <FLEXDIV>
          <HourlyInput hourlyRateVisible={hourlyRateVisible}>
            <ServiceLabel htmlFor="">Hourly rates</ServiceLabel>
            <HourButton>
              <P style={{ textAlign: "left", left: "-5px", top: "-47px" }}>
                Minimum hours
              </P>
              {hourlyRateHour ? (
                <Text>{hourlyRateHour}</Text>
              ) : (
                <Text>{1} </Text>
              )}
              <DropDown>
                <IoIosArrowUp
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  onClick={() => {
                    if (hourlyRateHour > 4) return;
                    setHourlyRateHour((prev: any) => prev + 1);
                    hourOperation(
                      hourlyRateHour + 1,
                      hourlyArray,
                      "Hourly Rate"
                    );
                  }}
                />
                <IoIosArrowDown
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  onClick={() => {
                    if (hourlyRateHour < 2) return;
                    setHourlyRateHour((prev: any) => prev - 1);
                    hourOperation(
                      hourlyRateHour - 1,
                      hourlyArray,
                      "Hourly Rate"
                    );
                  }}
                />
              </DropDown>
            </HourButton>

            <BLACKDIV>
              <P style={{ left: "0px", top: "-39px" }}>Price per hour</P>

              <Input>
                <CurrencyInput
                  style={{
                    border: "none",
                    outline: "none",
                    padding:"5px",
                  fontWeight:"600",
                    
                  }}
                  decimalsLimit={2}
                  prefix={formatCurrencyPrefix(currentLocation)}
                  placeholder="0.00"
                  value={hourlyRatePrice}
                  defaultValue={0.0}
                  onValueChange={(e: any) => handleHourlyRatePrice(e)}
                />
              </Input>

              <DISCOUNT onClick={() => setIsHourlyDiscountVisible(true)}>
                <P style={{ left: "0px", top: "-47px" }}>Discount</P>
                {hourlyDiscount}%
              </DISCOUNT>
            </BLACKDIV>
          </HourlyInput>
          <MARKDIV 
          // onClick={() => handleEnableHourly()}
          style={{backgroundColor:"transparent"}}
          >
            {/* {hourlyRateVisible ? (
              <img onClick={BlurOptionHour} src={xmark} alt="" width={17} />
            ) : (
              <img src={mark} alt="" width={18} />
            )} */}
          </MARKDIV>
        </FLEXDIV>
      )}
      {isHourlyDiscountVisible && (
        <DiscountModal
          setDiscount={setHourlyDiscount}
          discount={hourlyDiscount}
          discountFor="Hourly Rate"
          fullDayArray={hourlyArray}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setIsDiscountVisible={setIsHourlyDiscountVisible}
        />
      )}
    </>
  );
};
export default FourthScreen;
