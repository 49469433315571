import { ModalOverlay } from './../optionModal/optionmodal.styles';
import styled from "styled-components"

export const BookingTimeOverlay = styled(ModalOverlay) `

    & .bootking-time-box .css-epd502 {
        justify-content: center !important;
    }

    & .booking-time-box .iRDxjt .css-1jm66jt-MuiClockPicker-root {
        @media only screen and (max-width: 455px) {
            margin-left: 0 !important;
        }
    }

    & .booking-time-box .iRDxjt .css-1j9v0by-MuiClock-root {
        @media only screen and (max-width: 455px) {
            justify-content: center !important;
        }
    }

    & .booking-time-wrap {
        padding: 40px 20px;

        @media only screen and (max-width: 454px) {
            width: 320px !important;
        }
    }

`