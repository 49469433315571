import {
  AssetImg,
  Card,
  EmptyBox,
  ExtrasBox,
  HeaderText,
  ImageTag,
  ImgTextBox,
  ImgWrapper,
  InfoWrapper,
  LocationWrap,
  NewPrice,
  OldPrice,
  PriceLocationBox,
  RegText,
  RightExtraBox,
  StarTag,
  VerifiedImg,
  VerifiedTxt,
  WhiteInnerCard,
  AbsoluteDiv,
} from "./assetCard.styles";
import merc from "../../assets/images/jpg/mercedes-f1.jpeg";
import location from "../../assets/images/png/locatn.png";
import like from "../../assets/images/svg/like-icon.svg";
import likeFilled from "../../assets/images/svg/likeFilled.svg";
import share from "../../assets/images/svg/share.svg";
import passenger from "../../assets/images/svg/user-sit.svg";
import star from "../../assets/images/svg/starr.svg";
import verified from "../../assets/images/png/verified.png";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import {
  useAddVehicleLikesMutation,
  useGetVehicleLikesByIdQuery,
} from "../../services/customerApi";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { addToFavourite, clearFavourites } from "../../slices/favourites";
import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { baseImageUrl } from "../../services/utils";
import { FaRegHeart } from "react-icons/fa";
import axios from "axios";
import { baseUrl } from "../../services/providerApi";

const AssetCard = ({
  item,
  firstPrice,
  discountPrice,
  discount,
  name,
  locate,
  vehicleImg,
  vehicleId,
  year,
  passengerNum,
  rating,
  likes,
}: any) => {
  // const [locationInfo, setLocationInfo] = useState<{
  //   city?: string;
  //   country?: string;
  // } | null>(null);
  // async function getCityStateFromAddress(address: string) {
  //   const apiKey = "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs";
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //     address
  //   )}&key=${apiKey}`;

  //   try {
  //     // Fetch data from the API
  //     const response = await fetch(url);
  //     const data = await response.json();

  //     // Check if the API returned a valid response
  //     if (data.status === "OK") {
  //       let city, country;

  //       // Extract city and state from the formatted address
  //       data.results[0].address_components.forEach((component) => {
  //         if (component.types.includes("locality")) {
  //           city = component.long_name;
  //         } else if (component.types.includes("country")) {
  //           country = component.long_name;
  //         }
  //       });

  //       return { city, country };
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return null;
  //   }
  // }

  // useEffect(() => {
  //   getCityStateFromAddress(item?.vehicle_location || item?.vehicle_address)
  //     .then((result) => {
  //       if (result) {
  //         setLocationInfo(result);
  //       } else {
  //         console.log("Failed to fetch city and country");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);

  const navigate = useNavigate();
  const params = { vehicleId: `${vehicleId}` };
  const dispatch: AppDispatch = useAppDispatch();
  const [likeNo, setLikeNo] = useState<any>([]);

  const { favourites } = useAppSelector<any>(
    (store: RootState) => store?.favourites
  );

  const [addLike, { data, isLoading, isSuccess, isError, error }] =
    useAddVehicleLikesMutation();

  const {
    data: likesData,
    isFetching: isLikesFetching,
    refetch: refetchLikes,
    error: likesError,
  } = useGetVehicleLikesByIdQuery(vehicleId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    refetchLikes();
    setLikeNo(likesData?.data);
  }, [data, likesData]);

  const goToVehicleBooking = () => {
    // navigate({
    //     pathname: "/car-booking",
    //     search: `?${createSearchParams(params).toString()}`
    // })
    navigate(`/car-booking/${vehicleId}`);
  };
  const { token = "" } =
    useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};
  const handleAddVehicleLike = () => {
    let formData: any = new FormData();

    formData.append("id", vehicleId);
    formData.append("likes", "1");

    addLike(formData);
    dispatch(addToFavourite(vehicleId));
  };

  const addressParts =
    item?.vehicle_location?.split(",") || item?.vehicle_address?.split(",");
  const city =
    addressParts?.length <= 2 ? addressParts : addressParts?.[1]?.trim();
  const country =
    addressParts?.length <= 2
      ? " "
      : addressParts?.[addressParts.length - 1]?.trim();

  const fullAddress = city + ", " + country;

  const truncateAddress = (address, maxLength) => {
    if (address?.length <= maxLength) {
      return address;
    }
    return address?.substring(0, maxLength) + "...";
  };
  // console.log(item?.vehicle_location?.split(',')?.length)

  return (
    <>
      <Card>
        <AbsoluteDiv>
          {item?.come_with_driver?.toLowerCase() === "yes" && (
            <>
              <VerifiedTxt>Chauffered</VerifiedTxt>
            </>
          )}
          {item?.verified_status == "1" && (
            <>
              <VerifiedTxt>Verified</VerifiedTxt>
              <VerifiedImg src={verified} />
            </>
          )}
        </AbsoluteDiv>

        <ImgWrapper onClick={goToVehicleBooking}>
          <AssetImg src={`${baseImageUrl}${vehicleImg}`} loading="lazy" />
        </ImgWrapper>
        <WhiteInnerCard>
          <EmptyBox />
          <InfoWrapper>
            <div>
              <HeaderText>
                {name} - ({year})
              </HeaderText>
            </div>

            <PriceLocationBox>
              <div>
                <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
                  <NewPrice>
                    <span>{item?.currency} </span>
                    {Number(discountPrice).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </NewPrice>
                  <span>
                    <RegText>/</RegText>
                  </span>

                  <span>
                    <RegText>day</RegText>
                  </span>
                </div>
                <OldPrice>
                  {item?.currency}{" "}
                  {Number(firstPrice).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </OldPrice>
              </div>

              <div
                className="right-side-location"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ImageTag src={location} />

                {fullAddress ? (
                  <LocationWrap>
                    {truncateAddress(fullAddress, 18)}
                    {/* {locationInfo.city + " " + locationInfo.country} */}
                    {/* {item?.vehicle_location||item?.vehicle_address} */}
                  </LocationWrap>
                ) : (
                  ""
                )}
              </div>
            </PriceLocationBox>

            <ExtrasBox>
              <div>
                <ImgTextBox>
                  {favourites?.includes(Number(vehicleId)) ? (
                    <>
                      <ImageTag src={likeFilled} />
                    </>
                  ) : (
                    <>
                      {/* <ImageTag src={like} /> */}
                      {token?.length ? (
                        <FaRegHeart
                          onClick={handleAddVehicleLike}
                          style={{ width: 20, height: 20, color: "#bbb" }}
                        />
                      ) : (
                        <Link to="/login">
                          <FaRegHeart
                            style={{ width: 20, height: 20, color: "#bbb" }}
                          />
                        </Link>
                      )}
                    </>
                  )}

                  <RegText>{likeNo?.[0]?.likes || "0"}</RegText>
                </ImgTextBox>
              </div>

              <RightExtraBox>
                <RWebShare
                  data={{
                    text: `Book this vehicle with the link below`,
                    url: `https://m.roadlers.com/car-rental/${vehicleId}`,
                    title: "Book Vehicles on Roadlers",
                  }}
                >
                  <ImageTag src={share} style={{ width: "35px" }} />
                </RWebShare>

                <ImgTextBox>
                  <ImageTag src={passenger} />
                  <RegText>{passengerNum}</RegText>
                </ImgTextBox>
              </RightExtraBox>
            </ExtrasBox>
          </InfoWrapper>
        </WhiteInnerCard>
      </Card>
    </>
  );
};

export default AssetCard;
