import "./styles/ShareArticle.css";
import facebook from "../../assets/images/svg/facebook-rect.svg"
import twitter from "../../assets/images/svg/twitter-rect.svg"
import insta from "../../assets/images/svg/insta-rect.svg"
import share from "../../assets/images/svg/share-icon.svg"
import { useState } from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

const ShareArticle = ({setComment, handleComment, setUserMail, setUserName, link, title}: any) => {

  return (
    <div className="share-article-container">
        <div className="share-article-inner">
            <div className="top-section">
                <div className="inner-section">
                    <div className="contact-input-section">
                        <div className="name-input">
                            <label htmlFor="name">Name:</label>
                            <input type="text" onChange={e => setUserName(e.target.value)}/>
                        </div>

                        <div className="mail-input">
                            <label htmlFor="mail">Mail:</label>
                            <input type="email" onChange={e => setUserMail(e.target.value)}/>
                        </div> 
                    </div>

                    <div className="share-article-action-section">
                        <h2>share this article</h2>

                        <div className="social-icons">

                            <FacebookShareButton
                                url={`https://blog.roadlers.com/blogpost/${link}`}
                                quote={`Roadlers: ${title}`}
                                hashtag={`#Roadlers`}
                            >
                                <img src={facebook} alt="facebook" />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={`https://blog.roadlers.com/blogpost/${link}`}
                                title={`Roadlers: ${title}`}
                            >
                                <img src={twitter} alt="twitter" />
                            </TwitterShareButton>

                            <LinkedinShareButton
                                url={`https://blog.roadlers.com/blogpost/${link}`}
                                title={`${title}`}
                                source={`Roadlers: Ride with us`}
                            >
                                <img src={insta} alt="linkedin" />
                            </LinkedinShareButton>
                        </div>

                        <div className="share-btn-wrapper">
                            <button><img src={share} alt="share icon" /></button>
                            <div className="no-of-shares">
                                <p>0 shares</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom-section">
                <div className="inner-section">
                    <label htmlFor="comment">Add a comment:</label>
                    <div className="comment-box-wrapper">
                        <textarea name="comment" id="comment" placeholder='Type here' rows={14} onChange={e => setComment(e.target.value)}></textarea>
                        <div className="button-wrapper">
                            <button onClick={handleComment}>save</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default ShareArticle