import styled from 'styled-components'

// export const Page = styled.div`
//     position: relative;
//     min-height: 100vh;
//     width: 100vw;
//     place-items: center;
//     transition: .5s;
//     background-color: #242321;
// padding:1.3em;

// `

export const AbsoluteDiv = styled.div`
display:flex;
align-items:center;
height:100px;
border-radius:0px 0px 15px 15px;
background:#fff;
width:100%;
position: relative;
padding:0 1.2em;
margin-top:-70px;
justify-content:space-between;

`
export const IconDiv = styled.div`
display:flex;
align-items:center;
justify-content:center;
font-size:25px;
font-weight:400;
height:45px;
width:51px;
border-radius:7px;
gap:5px;
background:#000;
flex:1;
position: relative;
top:50px;
@media only screen and (max-width: 390px) {
    height:43px;
    width:43px;
font-size:20px;

}

`
export const P = styled.p`
color:#fff;
position:absolute;
font-size:9.5px;
top:37px;
`
export const Button = styled.button`
background:#ffa100;
padding:.5em;
margin-top:90px;
border:none;
outline:none;
font-size:14px;
font-weight:bold;
border-radius:6px;
color:#000;
`
