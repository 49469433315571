import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import TransactionAlertBox from "../../../components/transactionAlertBox";
import { AlertHeader, AlertPageOverall } from "./successpage.styles";

import smiley from "../../../assets/images/png/successful-smiley.png";
import axios from "axios";
import { baseUrl } from "../../../services/adminApi";
import { AppDispatch, useAppDispatch, useAppSelector } from "../../../store";
import { clearCartItem } from "../../../slices/cart";

const TransactionSuccessPage = () => {
  const [bookings, setBooking] = useState<any>();
  const user: any = useAppSelector((state: any) => state?.auth?.auth);
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch: AppDispatch = useAppDispatch();

  // Get the reference number
  const referenceNumber = urlParams.get("reference");

  const getBookingDetails = async () => {
    await axios
      .get(`${baseUrl}get-booking-by-reference/${referenceNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        const foundSelfDrive = response?.data?.data.find(
          (item) => item?.self_drive === "Yes"
        );
        setBooking(foundSelfDrive);
        // if (response?.data?.message === "success") {
        //   // dispatch(setAllBooking(response?.data?.data));
        // }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBookingDetails();
  }, [referenceNumber]);

  useEffect(() => {
         dispatch(clearCartItem());

  }, [])
  
  console.log("Reference response:", bookings);

  return (
    <>
      <Header />
      <AlertPageOverall>
        <AlertHeader>
          <h1>Transaction Succesful</h1>
        </AlertHeader>

        <TransactionAlertBox
          alertHeader={"Transaction Successful"}
          smileyIcon={smiley}
          alertContent={
            "Your request has been sent to the vehicle owner pending acceptance "
          }
          buttonText={"View My Booking"}
          btnBg={"#005FEE"}
          insureDisplay={bookings?.self_drive === "Yes" ? "block" : "none"}
        />
      </AlertPageOverall>
      <Footer />
    </>
  );
};

export default TransactionSuccessPage;
