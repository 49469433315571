import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { RiUser3Fill } from 'react-icons/ri';
import { FaSuitcase } from 'react-icons/fa';
import {
    DIV, H2, H4, DivContainer, IconDiv1, LoaderContainer, Loader, Loader2, Dotted, Container, IconDivContainer
} from '../../../components/providerAccountReg/firstScreen/firstScreen.styles';
import Footer from '../../../components/footer'
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAccount } from '../../../slices/provider';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ListImg from '../../../assets/images/png/listPng.png'
import BookImg from '../../../assets/images/png/bookPng.png'
import Header from '../../../components/header';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';
import { Page } from '../../Service_provider/Registration/registration.styles';
import { setRole } from '../../../slices/auth';
import { baseUrl } from '../../../services/providerApi';
import axios from 'axios';


const Choose = () => {

    const user = useAppSelector((state: any) => state?.auth?.auth)
    const userRole = useAppSelector((state: any) => state?.auth?.user_role)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    // useEffect(() => {
    //     setIsLoading(false)
    //     if (userRole === "provider") {
    //         navigate('/')
    //     }
    // }, [])




    const registrationNotification = async (role: string) => {
        dispatch(setRole(role))

        let formData = new FormData();
        formData.append("user_id", user?.id);
        formData.append("option", role);
        axios({
            method: "post",
            url: `${baseUrl}send-reg-notify`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            // const message = response.data.data;
            if (response?.data?.data === "Provider Selected") {
                navigate("/individual-reg")

            } else {
                navigate("/login")

            }

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    }


    return (


        <>
            <Header />
          
                    <Page>
                        <PagePadding className='padding'>
                            <DIV>
                                <div style={{ display: 'flex', gap: 10, alignItems: 'center', flexDirection: 'row' }}>


                                    <H2>
                                        Welcome <span style={{ color: '#FFD58C' }}>{user?.firstname}</span>, Let’s Setup Your Account
                                    </H2>

                                </div>
                            </DIV>
                            <H4>What would you like to achieve today?</H4>


                            <Container>

                                <IconDivContainer>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <IconDiv1 onClick={() => registrationNotification('customer')}>

                                            <Dotted style={userRole === "customer" ? { backgroundColor: "#ffa100" } : { backgroundColor: "#fff" }} />
                                            <img src={BookImg} alt="" width={70} />


                                        </IconDiv1>


                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

                                            <p style={{ fontWeight: '600', marginTop: '5px', marginBottom: '0px', color: '#FFD58C' }}>Book</p>
                                            <p style={{ fontSize: '12px', marginTop: '8px', marginBottom: '0px', color: '#fff', textAlign: 'center', fontWeight: 400 }}>
                                                I would like to book  a  vehicles or a driver
                                            </p>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',

                                    }}>
                                        <IconDiv1 onClick={() => registrationNotification('provider')}>
                                            <Dotted style={userRole === "provider" ? { backgroundColor: "#ffa100" } : { backgroundColor: "#fff" }} />
                                            <img src={ListImg} alt="" width={70} />
                                        </IconDiv1>
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <p style={{ fontWeight: '600', marginTop: '5px', marginBottom: '0px', color: '#FFD58C' }}>List</p>
                                            <p style={{ fontSize: '12px', marginTop: '8px', marginBottom: '0px', color: '#fff', textAlign: 'center' }}>
                                                I would like to list my  vehicles or become a driver
                                            </p>
                                        </div>


                                    </div>

                                </IconDivContainer>
                            </Container>

                        </PagePadding>
                    </Page>
            

        </>


    )
}

export default Choose