import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { DriverCounterBox, Page, } from './providers.styles';
import AdminHeader from '../../../components/adminHeader/';
import Counter from '../../../components/Admin/Counter';
import Filter from '../../../components/Admin/Filter';
import ButtonGroups from '../../../components/Admin/ButtonGroups';
import CounterFilter from '../../../components/Admin/CounterFilter';
import LinkHeader from '../../../components/Admin/Header/header';
import AdminBusinessProviders from '../../../components/Admin/Business/adminProviders';
import { PagePadding } from '../Dashboard/dashboard.styles';

const ListBusinessProviders: FC = () => {
    return (
        <>
            <AdminHeader />
            <Page>

            <PagePadding className='padding'>


                    <LinkHeader />

                    <DriverCounterBox>
                        <Counter business={true} />
                        <CounterFilter business={true} />
                    </DriverCounterBox>
                    <Filter business={true} />
                    <ButtonGroups isBusiness={true} />
                    <AdminBusinessProviders />
                </PagePadding>
            </Page>
        </>
    )
}
export default ListBusinessProviders