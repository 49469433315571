import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import whiteMark from "../../../../assets/images/png/whiteMark.png";

import {
  VerifyButton,
  ShadowDiv,
  Label,
  Button,
  InfoDiv,
  P,
  DisabledButton,
} from "./firstScreen.styles";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  useCarUpdateMutation,
  useGetInsuranceQuery,
  useUpdateInsuranceMutation,
  useUploadInsuranceMutation,
} from "../../../../services/providerApi";
import { useAppSelector } from "../../../../store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

type Props = {
  vehicleProfile: any;
  vehicleInsurance: any;
};
const SetupInsurance: FC<Props> = ({ vehicleInsurance, vehicleProfile }) => {
  // const [percent, setPercent] = useState<number>(0);

  const insuranceId: any = vehicleInsurance?.data[0]?.id;

  const vehicleId = vehicleProfile?.data[0]?.id;

  const [isBaseFee, setIsBaseFee] = useState<boolean>(true);
  const [isAdditionalFee, setIsAdditionalFee] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(
    vehicleProfile?.data[0]?.charge
  );
  console.log(vehicleProfile?.data[0]?.id);
  console.log(vehicleProfile?.data[0]?.charge);
  useEffect(() => {
    if (vehicleProfile?.data[0] && vehicleProfile?.data[0]?.charge) {
      setPercent(Number(vehicleProfile?.data[0]?.charge));
    }
  }, []);


  const [vehicleInfo, setVehicleInfo] = useState(vehicleProfile?.data[0]);

  let obj = {
    'vehicle_id': vehicleInfo?.id,
    'vehicle_name': vehicleInfo?.vehicle_name,
    'brand_name': vehicleInfo?.brand_name,
    'plate_number': vehicleInfo?.plate_number,
    'num_passengers': vehicleInfo?.num_passengers,
    'year': vehicleInfo?.year,
    'charge': vehicleInfo?.charge,
    'vehicle_for': vehicleInfo?.vehicle_for,
    'vehicle_location': vehicleInfo?.vehicle_location || vehicleInfo?.vehicle_address,
    'vehicle_type': vehicleInfo?.vehicle_type,
    'file': vehicleInfo?.image1,
    'file2': vehicleInfo?.image2,
    'file3': vehicleInfo?.image3,
    'file5': vehicleInfo?.image5,
    'latitude': vehicleInfo?.latitude,
    'longitude': vehicleInfo?.longitude,
    'air_con': vehicleInfo?.air_con,
    'come_with_driver': vehicleInfo?.come_with_driver,
    'travel_carpool': vehicleInfo?.travel_carpool,
    'fuel_type': vehicleInfo?.fuel_type,
    'vehicle_tracker':vehicleInfo?.vehicle_tracker,
    'night_trips': vehicleInfo?.night_trips,
    'self_drive': vehicleInfo?.self_drive,
}
  const [isFifteenPercentInfo, setIsFifteenPercentInfo] = useState<boolean>(false);
  const [isTwentyFivePercentInfo, setIsTwentyFivePercentInfo] =
    useState<boolean>(false);
  const [btnLoad, setBtnLoad] = useState<boolean>(false);

  const handlePercentOption = (option: string): void => {
    if (option === "FifteenPercent") {
      setIsFifteenPercentInfo((prev) => !prev);
      setIsTwentyFivePercentInfo(false);
    }
    if (option === "TwentyFivePercent") {
      setIsTwentyFivePercentInfo((prev) => !prev);
      setIsFifteenPercentInfo(false);
    }
  };
  const [updateVehicle, { data, isLoading, isSuccess, isError, error }] = useCarUpdateMutation()

  const handlePercent = (value: number): void => {
    setPercent(value);
    if (value === 25) {
      setIsTwentyFivePercentInfo(true);
      setIsFifteenPercentInfo(false);
      obj['charge'] = 25;
      //@ts-ignore
      updateVehicle([obj])

      // let formData: any = new FormData();
      // // formData.append('id', insuranceId)
      // formData.append("vehicle_id", vehicleId);
      // formData.append("insurance_percent", value);
      // if (vehicleInsurance?.data?.length < 1) {
      //   let formData: any = new FormData();
      //   formData.append("vehicle_id", vehicleId);
      //   formData.append("insurance_percent", value);
      //   setupInsurance(formData);
      // } else {
      //   let formData: any = new FormData();
      //   formData.append("id", insuranceId);
      //   formData.append("vehicle_id", vehicleId);
      //   formData.append("insurance_percent", value);
      //   updateInsurance(formData);
      // }
    } else if (value === 15) {
      setIsTwentyFivePercentInfo(false);
      setIsFifteenPercentInfo(true);
      obj['charge'] = 15;
      //@ts-ignore
      updateVehicle([obj])

      // if (vehicleInsurance?.data?.length < 1) {
      //   let formData: any = new FormData();
      //   formData.append("vehicle_id", vehicleId);
      //   formData.append("insurance_percent", value);
      //   setupInsurance(formData);
      // } else {
      //   let formData: any = new FormData();
      //   formData.append("id", insuranceId);
      //   formData.append("vehicle_id", vehicleId);
      //   formData.append("insurance_percent", value);
      //   updateInsurance(formData);
      // }
    }
  };

  // const [updateInsurance, { data, isLoading, isSuccess, isError, error }] =
  //   useUpdateInsuranceMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);
      // navigate('/provider-dashboard')
      notifySuccess("Service Charge Updated successfully");
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const [
    setupInsurance,
    {
      data: setUpInsuaranceData,
      isLoading: setupLoading,
      isSuccess: setupSuccess,
      isError: setupIsError,
      error: setupError,
    },
  ] = useUploadInsuranceMutation();

  useEffect(() => {
    if (setupSuccess) {
      setBtnLoad(false);
      notifySuccess("Service Charge Updated successfully");
    }
    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [setupLoading, setupSuccess, setupIsError, setUpInsuaranceData]);

  const alertError = () => {
    notifyError("Please Select any Insurance Option");
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />
      <P>Service fee preference</P>
      <ShadowDiv>
        <Button
          onClick={() => handlePercent(15)}
          style={percent === 15 ? { background: "#ffa100" } : {}}
        ></Button>
        <Label>15% fee on every transaction </Label>

        <VerifyButton onClick={() => handlePercentOption("FifteenPercent")}>
          <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
        </VerifyButton>
      </ShadowDiv>

      {isFifteenPercentInfo && (
        <InfoDiv>
          <ul style={{ color: "#000", fontSize: 13, fontWeight: 600 }}>
            <li style={{ marginBottom: 12 }}>Roadlers Service Charge</li>
          </ul>
        </InfoDiv>
      )}

      <ShadowDiv>
        <Button
          onClick={() => handlePercent(25)}
          style={percent === 25 ? { background: "#ffa100" } : {}}
        ></Button>
        <Label>25% fee on every transaction </Label>

        <VerifyButton onClick={() => handlePercentOption("TwentyFivePercent")}>
          <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
        </VerifyButton>
      </ShadowDiv>

      {isTwentyFivePercentInfo && (
        <InfoDiv>
          
          <ul style={{ color: "#000", fontSize: 13, fontWeight: 600 }}>
            
          <li style={{ marginBottom: 12 }}>
              Roadlers Service Charge 10%
            </li>
            <li style={{ marginBottom: 12 }}>
              Cover shall be granted based on the following: fire, accidental
              damage, and 3rd party liability.
            </li>
            <li style={{ marginBottom: 12 }}>
              Cover shall only be active when the vehicle is rented through
              Roadlers.
            </li>
            <li style={{ marginBottom: 12 }}>
              Renters will bear an excess of N10,000 or 10% of any claim,
              whichever is higher.
            </li>
            <li style={{ marginBottom: 12 }}>Medical benefit: - N50,000</li>
            {/* <li style={{ marginBottom: 12 }}>
              Death/ Permanent Disability - N100,000
            </li> */}
          </ul>
        </InfoDiv>
      )}
    </>
  );
};

export default SetupInsurance;
