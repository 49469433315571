import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport, IoWalletOutline } from "react-icons/io5";
import { BiUser } from "react-icons/bi";
import { BsCalendar2Minus } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";

import { AbsoluteDiv, Page, IconDiv, P, FlexCol } from "./dashboard.styles";

import Header from "../../../components/providerHeader";

import { Link, Navigate, useNavigate } from "react-router-dom";
import ProviderBalance from "../../../components/providerDashboard/providerBalance/providerBalance";
import ProviderActions from "../../../components/providerDashboard/providerActions/providerActions";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  baseUrl,
  useGetBikesQuery,
  useGetNextOfKinByProviderIdQuery,
  useGetVehiclesQuery,
} from "../../../services/providerApi";
import {
  clearVehicleDetails,
  clearDriverInfo,
  setAllBike,
  setAllVehicle,
  setRegisteredVehicle,
  setServiceVisibility,
  setAllRequest,
  setProfile,
  setNextOfKin,
  clearProfile,
  clearNextOfKin,
  setUploadedDriverInfo,
  setBusinessProfile,
  clearBusinessProfile,
  setAccount,
} from "../../../slices/provider";
import axios from "axios";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";

const Dashboard: FC = () => {
  const { id, name, firstname, lastname, token } = useAppSelector(
    (state: any) => state?.auth?.auth || ""
  );

  let [requestComponent, setRequestComponent] = useState(false);
  const dispatch: any = useAppDispatch();

  const businessProfileStore = useAppSelector(
    (state: any) => state?.provider?.businessProfile
  );

  const [providerInfo, setProviderInfo] = useState<any>({});

  const getProviderDetails = async () => {
    await axios
      .get(`${baseUrl}provider-next-of-kin/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response && response.data[0]) {
          setProviderInfo(response.data[0]);
        }
        if (
          response?.data[0]?.gender === null &&
          response?.data[0]?.category.toLowerCase() !== "business" &&
          response?.data[0]?.service.toLowerCase() !== "driver"
        ) {
          navigate("/profile-setup");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProviderDetails();
  }, []);

  // const checkInsurance = async () => {
  //     await axios.get(`${baseUrl}detect-insurance`, {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${token}`,
  //             "Access-Control-Allow-Origin": "*",
  //         }
  //     }).then((response) => {
  //         console.log(response?.data?.data)
  //         if (response?.data?.data === '0') {
  //             navigate('/insurance-setup')

  //         } else {
  //             dispatch(setRegisteredVehicle({}))

  //         }

  //     }).catch((err) => {
  //     })

  // }

  // useEffect(() => {
  //     checkInsurance()
  // }, [])

  useEffect(() => {
    dispatch(setUploadedDriverInfo({}));
    dispatch(clearDriverInfo({}));
    dispatch(setAccount(null));
    // dispatch(setAllRequest([]))
    dispatch(
      setServiceVisibility({
        fullDayServiceVisible: true,
        halfDayServiceVisible: true,
        hourlyRateVisible: true,
        airportVisible: true,
        travelVisible: true,
        halfMonthVisible: true,
        fullMonthVisible: true,
        driverHourlyRateVisible: true,
        driverFullDayServiceVisible: true,
        driverAllNightServiceVisible: true,
        driverWeeklyServiceVisible: true,
        driverMonthlyVisible: true,
        driverTravelVisible: true,
      })
    );
    dispatch(clearProfile({}));
    dispatch(clearNextOfKin({}));
  }, []);
  useEffect(() => {
    dispatch(clearBusinessProfile({}));
  }, []);
  useEffect(() => {
    dispatch(clearVehicleDetails({}));
    dispatch(setRegisteredVehicle([]));
    
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Page>
        {!providerInfo?.user_role ? (
          "Loading"
        ) : (
          <PagePadding className="padding">
            <AbsoluteDiv>
              <Link
                to="/user-profile-page"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <FlexCol>
                  <IconDiv>
                    <BiUser />
                  </IconDiv>
                  <P>Profile</P>
                </FlexCol>
              </Link>
              <Link
                to="/user-booking-page"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <FlexCol>
                  <IconDiv>
                    <IoCarSport />
                  </IconDiv>
                  <P>Bookings</P>
                </FlexCol>
              </Link>
              <Link
                to="/user-wallet-page"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <FlexCol>
                  <IconDiv>
                    <IoWalletOutline />
                  </IconDiv>
                  <P>Wallet</P>
                </FlexCol>
              </Link>
              {/* <Link to='/user-earning-page' style={{ color: '#fff', textDecoration: 'none' }}>

                        <IconDiv>
                            <GiReceiveMoney />
                            <P>Earnings</P>
                        </IconDiv>
                    </Link> */}

              <FlexCol
                onClick={() => setRequestComponent((prev: any) => !prev)}
              >
                <IconDiv>
                  <BsCalendar2Minus />
                </IconDiv>
                <P>Requests</P>
              </FlexCol>
            </AbsoluteDiv>
            <ProviderBalance providerInfo={providerInfo} />
            <ProviderActions
              setRequestComponent={setRequestComponent}
              requestComponent={requestComponent}
            />
          </PagePadding>
        )}
      </Page>
    </>
  );
};
export default Dashboard;
