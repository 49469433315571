import styled from 'styled-components'



export const ExportButton = styled.button`
border:1px solid #fff;
color:#fff;
padding:5px 15px;
font-size:13px;
font-weight:bold;
background:#000;
border-radius:5px;
`
export const CounterBox = styled.div`
width:43%;
margin-top:100px;

`
export const Box = styled.div`
display:flex;
align-items:center;
margin-top:30px;
gap:5px;
`
export const Button = styled.button`
border:2px solid white;
padding:5px;
border-radius:50%;
`
export const Text = styled.label`
color:white;
font-size:14px;
`

export const DarkBox = styled.div`
display:flex;
align-items:center;
margin-top:15px;
gap:5px;
background:black;
border-radius:10px;
justify-content:center;
padding:10px;

`
export const H1 = styled.h1`
font-weight:bold;
font-size:25px;
margin:0;

`





