import styled from 'styled-components'
import { PillWrapper } from '../Homepage/homepage.styles'

export const SearchHeaderSection = styled.section `
    padding: 20px 30px;
    display: flex;
    justify-content: center;
`

export const SearchHeaderWrap = styled.div `
    display: flex;
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    width: 95%;

    @media only screen and (min-width: 800px) {
        width: 50%;
    }
`

export const HeaderInput = styled.input `
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom-left-radius: 10px;
    padding: 5px 20px;
    padding-left: 40px;
    border-top-left-radius: 10px;
    font-size: 12px;
    /* height: 40px; */

    &::placeholder {
        color: #868686;
        font-size: 12px;
    }
`

export const ExtrasInputWrap = styled.div `
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 8px 7px;
    border-left: solid 1px #DFDBDB;
    position: relative;
    align-items: center;
    justify-content: center;

    & p {
        margin: 0;
        font-size: 10px;
        color: #000;
        white-space: nowrap;
        text-align: center;
        overflow: hidden;
        width: 40px;
        text-overflow: ellipsis;
    }

    img {
        width: 30px;

        &.location {
            width: 20px;
        }
    }

    & .icon-description {
        position: absolute;
        top: -15px;
        left: 0;
        color: ${(props: any) => props.theme.text};
        font-weight: 700;
        font-size: 11px;
        text-align: center;
        width: 100%;
    }
`

export const HeaderImgWrap = styled.div `
    position: absolute;
    top:55%; 
    left: 10px; 
    transform:translate(-50%, -50%); 

    img {
        width: 50px;
    }
`

export const FilterWrap = styled.div `
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-bottom: 20px;
    gap: 15px;

    @media only screen and (min-width: 500px) {
        justify-content: center;
    }
`

export const FilterBox = styled.div `
    position: relative;

    & .filter-text-wrap {
        position: absolute;
        left: 10%;
        bottom: -10px;
        color: ${(props:any) => props.theme.text};
        font-weight: 700;
    }
`

export const HeaderPillWrap = styled(PillWrapper) `
    margin: 0;
    width: 460px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 5px;
`

export const HeaderFilterPill = styled.div `
    background-color: ${(props:any) =>  props.active === true ? "#ffa100" : '#C4C4C4'};
    color: ${(props: any) => props.theme.black};
    padding: 6px 12px;
    min-width: 95px;
    border-radius: 3px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    font-size: 13px;
    align-items: center;
    cursor: pointer;
    font-weight: 700;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
        padding: 4px 8px;
        min-width: 80px;
    }
`

export const FilterVerifiedWrap = styled.div `
    padding: 0px 0px;
    padding-left: 45px;
    margin-top: 30px;

    div {
        font-size: 13px;
    }

`

export const SearchAssetOverall = styled.div ` 
    margin: 20px 0px;
    margin-bottom: 60px;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    & a {
        text-decoration: none;
    }

    @media only screen and (min-width: 355px)  {
        padding: 0 30px;
        display: flex;
        column-gap: 10px;
        row-gap: 35px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (min-width: 415px)  {
        padding: 0 30px;
    }

    @media only screen and (min-width: 450px)  {
        padding: 0 30px;
    }


    @media only screen and (min-width: 500px)  {
        grid: auto /repeat(2, 1fr);
        display: grid;
        grid-gap: 20px;
        justify-content: space-evenly;
        justify-items: center;
        /* padding: 0; */
    }

    @media only screen and (min-width: 715px)  {
        grid: auto /repeat(3, 1fr);
        display: grid;
        grid-gap: 25px;
    }

    @media only screen and (min-width: 959px)  {
        grid: auto /repeat(4, 1fr);
        row-gap: 50px; 
    }

    @media only screen and (min-width: 1200px) {
        padding: 40px 150px;
    }
`

export const NoResultScreen = styled.div `
    height: 50vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NoResultText = styled.div `
    color: ${(props: any) => props.theme.text};;
    font-size: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
`