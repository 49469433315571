import React from 'react'
import { setShowDriverOption } from '../../slices/showDriverOption'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'
import { CancelModalBox, ModalOverlay, OptionPill, PillRadioBtn } from '../optionModal/optionmodal.styles'
import { DriverModalBox } from './driveroptionmodal.styles'

const DriverOptionModal = ({handleService, driverService, handleServiceName, setPrice, setChoiceDriver}: any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showDriverOption} = useAppSelector<any>((store:RootState) => store?.showDriverOption)

    const driverOptionModalControl = () => {
        dispatch(setShowDriverOption(false))
    }
    
    const partTimeOptions:any = [
        {type: 'Daily driver', name: 'Full day price' },
        {type: 'Night driver', name: 'Night service' },
        {type: '1 Week driver', name: 'Weekly service' },
        {type: 'Monthly Driver', name: 'Monthly service' },
        {type: 'Hourly Driver', name: 'Hourly Rate'}
    ]

    const fullTimeOptions:any = [
        {type: 'Full-time Regular driver '},
        {type: 'Full-time Police driver '},
        {type: 'Full-time Night driver '},
        {type: '2 month regular driver'},
        {type: '3 month regular driver'},
    ]

    const handleServiceChoice = (value:any) => {
        handleService(value?.type)
        handleServiceName(value?.name)
        setPrice(0)
        setChoiceDriver('')
        driverOptionModalControl()
    }


  return (
    <ModalOverlay activeModal={showDriverOption} >
        <CancelModalBox onClick={() => driverOptionModalControl()}/>
    <div className="modal-inner-wrapper">
        <DriverModalBox>
            <div className="inner-modal-box">
                <h4>Part-time options</h4>

                {
                    partTimeOptions?.map((item:any, id:any) => {
                        return (
                            <OptionPill key={id} onClick={() => handleServiceChoice(item)}>
                                <PillRadioBtn activePill={driverService === item?.type ? 'fill' : ''} />
                                <p>
                                {item?.type}
                                </p>
                            </OptionPill>
                        )
                    })
                }


                <div className="fulltime-wrap" style={{marginTop: '60px'}}>
                    <h4>Full-time options</h4>

                    {
                        fullTimeOptions?.map((item:any, id:any) => {
                            return (
                                <OptionPill key={id} onClick={() => handleServiceChoice(item?.type)} >
                                    <PillRadioBtn activePill={driverService === item?.type ? 'fill' : ''} />
                                    <p>
                                    {item?.type}
                                    </p>
                                </OptionPill>
                            )
                        })
                    }

                </div>


            </div>
        </DriverModalBox>
    </div>
</ModalOverlay>
  )
}

export default DriverOptionModal