import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Label,
  Flex,
  ImageContainer,
  TextContainer,
  BackButton,
  DeleteButton,
  ModalContainer,
} from "./popUp.styles";
import deleteImage from "../../../assets/images/png/modalOption.png";
import spinner from "../../../assets/images/gif/spinner.gif";

import "react-toastify/dist/ReactToastify.css";

// import { ToastContainer, toast } from 'react-toastify'
import {
  useDeleteDriverMutation,
  useDeleteVehicleMutation,
} from "../../../services/providerApi";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setAllVehicle } from "../../../slices/provider";
import {
    baseUrl,
  useVerifyDriverMutation,
  useVerifyVehicleMutation,
} from "../../../services/adminApi";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

export const CancelModal = ({ request, setCancelRequest }: any) => {
  const token = useAppSelector((state: any) => state?.auth?.auth?.token);
  const [isLoading, setisLoading] = useState<any>(false);
  const dispatch: any = useAppDispatch();

  const closeCancelModal = () => {
    setCancelRequest(false)
  };
  const handleCancelRequest = () => {
    let formData: any = new FormData();
    formData.append("booking_id", request?.id);
    formData.append("provider_status", "Cancel");
    formData.append("book_status", "Cancel");

    axios({
        method: "post",
        url: `${baseUrl}cancel-request/${request?.id}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(function (response:any) {
        //   const message = response.data.data;
        //   dispatch(setAllVehicle(response.data));
        //   setVehicleSearch("");
        //   // dispatch(setFilterPaginationData("search"))
        //   dispatch(setFilterPagination(true));
        console.log(response?.data)
        })
        .catch(function (error) {
          //handle error
        });
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <Toaster />

      <ModalContainer onClick={() => closeCancelModal()}>
        <Modal>
          <ImageContainer>
            <img src={deleteImage} style={{ width: "100%" }} />
          </ImageContainer>
          <TextContainer>
            <Label>Are you sure you want to Cancel this request?</Label>
            .
            <Flex>
              <BackButton onClick={() => closeCancelModal()}>No</BackButton>

              <>
                {isLoading ? (
                  <DeleteButton>
                    loading <img src={spinner} width={50} />
                  </DeleteButton>
                ) : (
                  <DeleteButton onClick={()=>handleCancelRequest()}>Yes</DeleteButton>
                )}
              </>
            </Flex>
          </TextContainer>
        </Modal>
      </ModalContainer>
    </>
  );
};
