import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../assets/images/svg/roadlers-profile-logo.svg";
import spin from "../../../assets/images/gif/spinner.gif";
import closedEye from "../../../assets/images/svg/closed-eye.svg";
import openedEye from "../../../assets/images/svg/opened-eye.svg";

import { ToastContainer, toast } from "react-toastify";
import {
  useLoginMutation,
  useResetPasswordMutation,
} from "../../../services/onboardingApi";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { setAuth, setRole } from "../../../slices/auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import { SignupDiv } from "../../Service_provider/Auth/Login/Login.styles";
import { useCreateFiatWalletMutation } from "../../../services/walletApi";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";
// import { useDispatch } from 'react-redux';

const Reset = () => {
  // console.log();
  // const url = window.location.href;
  // const startIndex =
  //   url.indexOf("https://roadlers.com/password-reset/") +
  //   "https://roadlers.com/password-reset/".length;
  // const endIndex = url.indexOf("?");
  // const extractedString = url.substring(startIndex, endIndex);

  const paramsId = useParams();
  const [params] = useSearchParams();
  const [load, setLoad] = useState("");

  const paramsMail = params.get("email");

  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [eyeOpen, setEyeOpen] = useState<any>("password");
  const dispatch = useAppDispatch();

  // RTK endpoints
  const [resetPassword, { data, isLoading, isSuccess, isError, error }] =
    useResetPasswordMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);
    }
    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      notifyError(error?.data?.message);
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handleNewPassword = (e: any) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e: any) => {
    setPasswordConfirm(e.target.value);
  };

  const recoveryEmail = localStorage.getItem("recovery_email");
  const handleLogin = (e: any) => {
    e.preventDefault();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let formData: any = new FormData();

    formData.append("email", recoveryEmail);
    formData.append("password", password);
    formData.append("password_confirmation", passwordConfirm);
    formData.append("token", paramsId?.id);

    if (password.length === 0) {
      notifyError("Please type in your password");
    } else {
      resetPassword(formData)
        .then((response: any) => {
          if (response?.error?.data?.message?.password[0]) {
            notifyError(response?.error?.data?.message?.password[0]);
            navigate("/login");
          } else {
            localStorage.removeItem("recovery_email");
            navigate("/login");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <Header />
      <PagePadding className="padding">
        <div className="inner-login-wrapper">
          <div className="header-text">
            <h2>
              Reset Your, <span>Password</span>
            </h2>
          </div>

          <div className="login-box">
            <form className="inner-login-box" onSubmit={(e) => handleLogin(e)}>
              <SignupDiv>
                <div className="input-password-box">
                  <input
                    type={eyeOpen}
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => handleNewPassword(e)}
                  />
                  <div className="img-eye">
                    {eyeOpen === "password" ? (
                      <img
                        src={openedEye}
                        onClick={() => setEyeOpen("text")}
                        alt="opened eye"
                      />
                    ) : (
                      <img
                        src={closedEye}
                        onClick={() => setEyeOpen("password")}
                        alt="closed eye"
                      />
                    )}
                  </div>
                </div>

                <div className="input-password-box">
                  <input
                    type={eyeOpen}
                    placeholder="Confirm Password"
                    value={passwordConfirm}
                    onChange={(e) => handleConfirmPassword(e)}
                  />
                  <div className="img-eye">
                    {eyeOpen === "password" ? (
                      <img
                        src={openedEye}
                        onClick={() => setEyeOpen("text")}
                        alt="opened eye"
                      />
                    ) : (
                      <img
                        src={closedEye}
                        onClick={() => setEyeOpen("password")}
                        alt="closed eye"
                      />
                    )}
                  </div>
                </div>
              </SignupDiv>

              <br />
              <div className="login-btn-box" style={{ marginTop: "1em" }}>
                {btnLoad && (
                  <button
                    disabled
                    style={{
                      background: "#000",
                      color: "gray",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>Loading</p> <img src={spin} alt="spin gif" />{" "}
                  </button>
                )}
                {!btnLoad && (
                  <button type="submit">
                    <p>Continue</p>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </PagePadding>

      <Footer />
    </div>
  );
};

export default Reset;
