import styled from "styled-components";
import parallax from "../../assets/images/png/parallax.png";

export const GarageOverall = styled.div`
  position: relative;
  margin-top: 80px;
  padding: 0px 180px;
  @media only screen and (max-width: 484px) {
    padding: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props: any) => props.theme.cardColorBg};
  position: relative;
  border-radius: 50px;
  padding: 18px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -150px;
  @media only screen and (max-width: 484px) {
    padding: 10px 30px 30px 30px;
    border-radius: 20px;
    margin-top: -40px;
  }
`;

export const HeaderBox = styled.div``;

export const H1 = styled.h2`
  font-size: 28px;
  color: ${(props: any) => props.theme.garageText};
  font-weight: 800;
  margin: 0;
  width: 80%;
  @media only screen and (max-width: 484px) {
    font-size: 22px;
    width: 100%;
  }
`;

export const HorizontalLine = styled.hr`
  border-bottom: solid 1px #ffa100;
  margin: 0;
  margin-top: 8px;
  width: 30%;
`;

export const H11 = styled(H1)`
  color: #797979;
`;

export const ContentWrap = styled.div`
  flex: 0.6;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  & .travel-garage-link {
    text-decoration: none;
    color: unset;
  }
  @media only screen and (max-width: 484px) {
    flex: 0.5;
  }
`;

export const P = styled.p`
  color: ${(props: any) => props.theme.garageText};
  font-size: 17px;
  font-weight: 400;
  flex: 0.5;
  padding-right: 100px;
  @media only screen and (max-width: 484px) {
    font-size: 12px;
    padding-right: 0px;
    margin: 10px 0px;
  }
`;

export const ImageWrap = styled.div`
  flex: 0.4;
  @media only screen and (max-width: 484px) {
    flex: 0.5;
  }
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Button = styled.button`
  position: relative;
  background-color: ${(props: any) => props.theme.themeColor};
  width: 170px;
  color: ${(props: any) => props.theme.cardColorBg};
  padding: 12px 17px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 800;
  @media only screen and (max-width: 484px) {
    padding: 8px;
    font-size: 13px;
  }
`;

export const Boxes = styled.img`
  position: absolute;
  left: -45px;
  top: -18px;
  width: 70px;
`;

export const ParallaxImgWrap = styled.div`
  /* The image used */
  background-image: url(${parallax});

  /* Set a specific height */
  min-height: 100px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 400;
`;

export const BottomImg = styled.img`
  width: 100%;
`;
