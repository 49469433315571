import { NavLink } from "react-router-dom";
import logo from "../../assets/images/svg/roadlers-logo-no-bg.svg";

import "./styles/Modal.css";

const Modal = ({ activeClass }: any) => {
  return (
    <div className={`modal-bg ${activeClass}`}>
      <div className="modal-inner-wrapper">
        <div className="modal-action-box">
          <div className="modal-action-box-inner">
            <div className="modal-header">
              <img src={logo} alt="Roadlers Logo" />
            </div>

            <div
              className="modal-content"
              style={{ color: "#000", padding: "0px 12px" }}
            >
              <p>
                Congraulations! Your post is now live. <br />
                Do you want to create a new post or check your live post?
              </p>
            </div>

            <div className="modal-footer">
              <NavLink to={"upload-blog-page"}>
                <button>Create Post</button>
              </NavLink>

              <NavLink to={"/blog"}>
                <button>View Post</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
