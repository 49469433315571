import React from "react";
import {
  BookingDayWrap,
  BookingDetailsWrap,
  BookingStateInfo,
  CartBox,
  CartFooter,
  CartImageWrap,
  CartLocationWrap,
  CartSections,
  EmptySpaceBox,
  IconDiv,
  NumberControlOverall,
  PriceBox,
  RemoveBtn,
  SectionsHeader,
} from "./cart.styles";
import { InputIcons } from "../locationModal/locationmodal.styles";

import location from "../../assets/images/svg/location-black.svg";
import car from "../../assets/images/png/cart-car.png";
import minus from "../../assets/images/svg/minus.svg";
import plus from "../../assets/images/svg/plus.svg";
import clock from "../../assets/images/svg/clock.svg";
import calendar from "../../assets/images/svg/calendar-cart.svg";
import {
  BoxExtras,
  AssetOverall,
} from "../../pages/user/Homepage/homepage.styles";
import AssetCard from "../assetCard";
import OffersCard from "../offersCard";
import { H11 } from "../travelGarageBox/garagebox-styles";
import {
  BookingDetailsSubWrap,
  EditBookingText,
  LocationDetailsWrap,
} from "../deliveryCart/deliveryCart.styles";
import { ImLocation } from "react-icons/im";
import { FaRegCalendarAlt } from "react-icons/fa";
import { baseImageUrl } from "../../services/utils";
const Cart = ({
  booking_title,
  pickup_date,
  pickup_time,
  pickup_location,
  image,
  trnasport_options,
  category,
  amount,
  no_of_hours,
  state_location,
  plate_number,
  vehicle_id,
  provider_id,
  vehicle_year,
  handleDelete,
  handleEditBooking,
  from,
  to,
  currencyCode,
}: any) => {
  const imgLink = `https://v2api.roadlers.com/storage/`;
  const imgLink2 = "https://testapi.roadlers.com/storage/";
  return (
    <CartBox>
      <div className="cart-inner-wrap">
        <CartSections>
          <SectionsHeader>
            <h3>{`${booking_title} (${vehicle_year})`}</h3>
            <hr />
          </SectionsHeader>

          <CartImageWrap>
            <img src={`${baseImageUrl}${image}`} />
          </CartImageWrap>
        </CartSections>

        <CartSections>
          <SectionsHeader>
            <h3>Booking Details</h3>
            <hr />
          </SectionsHeader>
          <EmptySpaceBox />

          {/* NOT USED */}
          {/* <BookingDayWrap>
                    <p>3 days</p>

                    <NumberControlOverall>
                        <div className="control-wrap">
                            <img src={minus} alt="" />
                        </div>

                        <div className="control-wrap">
                            <img src={plus} alt="" />
                        </div>
                    </NumberControlOverall>
                </BookingDayWrap> */}
          <LocationDetailsWrap>
            {/* <InputIcons src={location} iconWidth={"20px"} /> */}
            <IconDiv>
              <ImLocation />
            </IconDiv>
            <p>{pickup_location}</p>
          </LocationDetailsWrap>

          <BookingDetailsSubWrap>
            <IconDiv>
            <FaRegCalendarAlt/>
            </IconDiv>
            {/* <img src={calendar} className={"calendar"} /> */}

            <p>{pickup_date}</p>
          </BookingDetailsSubWrap>

          <BookingDetailsSubWrap>
            <img src={clock} />

            <p>{pickup_time}</p>
          </BookingDetailsSubWrap>

          {category === "Travel Garage Booking" ? (
            <BookingStateInfo style={{ margin: "0px 0px 10px 0px" }}>
              Travelling from {from} to {to}
            </BookingStateInfo>
          ) : (
            <BookingStateInfo>{state_location}</BookingStateInfo>
          )}
        </CartSections>

        <CartSections>
          <SectionsHeader>
            <h3>Price Per Day</h3>
            <hr />
          </SectionsHeader>

          <PriceBox>
            <p>
              {currencyCode}{" "}
              {`${amount?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}`}
            </p>
          </PriceBox>
        </CartSections>
      </div>

      <CartFooter>
        <div className="cart-footer-inner">
          <div className="btns-flex">
            {/* <EditBookingText onClick={() => handleEditBooking()}>
                        Edit
                    </EditBookingText> */}

            <RemoveBtn onClick={() => handleDelete()}>Remove</RemoveBtn>
          </div>

          <h3>Sub-total</h3>

          <h3>
            {currencyCode}{" "}
            {`${amount?.toLocaleString("en-US", { maximumFractionDigits: 2 })}`}
          </h3>
        </div>
      </CartFooter>
    </CartBox>
  );
};

export default Cart;
