import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import providerPlaceholder from '../../../assets/images/png/providerPlaceholder.png'
import ratings from '../../../assets/images/png/ratings.png'
import pin from '../../../assets/images/png/pin.png'
import deleteicon from '../../../assets/images/png/delete.png'
import { GiCheckMark } from 'react-icons/gi';
import { IoCallSharp, IoEllipsisHorizontalOutline } from 'react-icons/io5';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BsEnvelope, BsPinFill } from 'react-icons/bs';
import car from '../../../assets/images/png/Car.png'
import userr from '../../../assets/images/png/phebe.png'




import {
    DriverDiv, Div, ImageDiv, CheckMark, DriverDetails, FlexDiv, H6, Id, StatusLabel, HideLabel,
    TripsText, CallDiv, CallButton, IdDiv, AddressDiv, FirstNameDiv, NameLabel, PinnedInfoDiv,
    TopLabel, JoinDiv, MessageDiv, Ellipses
} from './provider.styles';
import { Link } from 'react-router-dom';
import { DeleteModal } from '../../providerDashboard/deleteModal'
import { PinModal } from '../pinModal'
import { EllipsesModal } from '../Ellipses/Modal'
import moment from 'moment'

type Props={
    providerDetails?:any;
    isModal?: boolean;
}
const BusinessProvider: FC<Props> = ({ isModal, providerDetails }) => {
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [isPin, setIsPin] = useState<boolean>(false)
    const [isUnPin, setIsUnPin] = useState<boolean>(false)

    const [isEllipsesVisible, setIsEllipsesVisible] = useState(false)


    const handleDeleteModal = async () => {
        setIsDelete((prev: boolean) => !prev)
    }
    const handlePinModal = async () => {
        setIsPin((prev: boolean) => !prev)
    }
    const handleUnPinModal = async () => {
        setIsUnPin((prev: boolean) => !prev)
    }

    const openEllipses = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,) => {
        setIsEllipsesVisible((prev) => !prev)
    };

    return (
        <>
            {
                isDelete && <DeleteModal
                    id={providerDetails?.id}
                    setIsDelete={setIsDelete}
                    isProvider={true}

                />
            }

            {
                isPin && <PinModal id={providerDetails?.id}
                    setIsPin={setIsPin}
                    isProvider={true}
                />
            }
            {
                isUnPin && <PinModal id={providerDetails?.id}
                    setIsPin={setIsPin}
                    isUnPin={isUnPin}
                    setIsUnPin={setIsUnPin}
                    isProvider={true}

                />
            }

            {isEllipsesVisible &&
                <EllipsesModal
                    isProvider={true}
                    setIsEllipsesVisible={setIsEllipsesVisible}
                />
            }
            <DriverDiv>
                {
                    !isModal &&
                    <PinnedInfoDiv>
                        <JoinDiv>
                            <img src={pin} width={15} onClick={handlePinModal} />
                            <TopLabel>Joined: {moment(providerDetails?.created_at).format('DD/MM/YYYY')}</TopLabel>
                        </JoinDiv>
                        <img src={deleteicon} width={15} onClick={handleDeleteModal} />
                        <MessageDiv>
                            <BsEnvelope style={{ fontSize: 18 }} />
                        </MessageDiv>
                        <TopLabel>Vehicle Owner</TopLabel>
                        <Ellipses  onClick={openEllipses}>
                            <IoEllipsisHorizontalOutline style={{ fontSize: 25, color: '#FFD58C' }} />
                        </Ellipses>
                    </PinnedInfoDiv>
                }


                <IdDiv>
                    <ImageDiv>
                        <img width='100%' height='100%' src={userr} />

                    </ImageDiv>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>Next of Kin</p>
                    </CheckMark>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>Driver's License</p>
                    </CheckMark>

                    <CheckMark>
                        <img src={car} alt="" width={20} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>{providerDetails?.vehicle_profile?.length} Vehicles</p>

                    </CheckMark>

                </IdDiv>

                <AddressDiv>
                    <DriverDetails>
                        <FirstNameDiv>
                            <NameLabel>First</NameLabel>
                            <H6>{providerDetails?.firstname || providerDetails?.name}</H6>
                        </FirstNameDiv>

                        <FirstNameDiv>
                            <NameLabel>Lastname</NameLabel>
                            <H6>{providerDetails?.lastname || '.'}</H6>
                        </FirstNameDiv>
                    </DriverDetails>

                    <DriverDetails>
                        <FirstNameDiv>
                            <NameLabel>Phone Number</NameLabel>
                            <H6>{providerDetails?.phone_number || '.'}</H6>
                        </FirstNameDiv>

                    </DriverDetails>

                    <DriverDetails>
                        <FirstNameDiv>
                            <NameLabel>Location</NameLabel>
                            <H6>{providerDetails?.state || '.'}</H6>
                        </FirstNameDiv>

                    </DriverDetails>
                </AddressDiv>

                <Div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', gap: 10 }}>
                    <Id>ID: {providerDetails?.id}</Id>
                    {(providerDetails?.status === 'Pending' || providerDetails?.status ===  '0') ? <StatusLabel style={{backgroundColor:'#005feeff'}}>Pending</StatusLabel>:''}
                    {(providerDetails?.status === 'Approved' || providerDetails?.status ===  '1') ? <StatusLabel style={{backgroundColor:'#06ae2bff'}}>Approved</StatusLabel>:''}
                    {(providerDetails?.status === 'Rejected' || providerDetails?.status ===  '2') ? <StatusLabel style={{backgroundColor:'#cf0303ff'}}>Rejected</StatusLabel>:''}
                    {/* <HideLabel>Hide</HideLabel> */}
                    <br />
                    <TripsText>20 trips</TripsText >
                    <img src={ratings} width={50} />

                    <CallDiv>
                        <FaRegCalendarAlt style={{ fontSize: 18 }} />
                        <CallButton>

                            <a style={{ color: '#fff', padding: '0' }} href={`tel: ${providerDetails.phone_number}`} >
                                <IoCallSharp />

                            </a>

                        </CallButton>

                    </CallDiv>
                </Div>

            </DriverDiv>

        </>

    )
}

export default BusinessProvider