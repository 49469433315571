import React, { FC, useEffect, useState } from 'react'
import {
    ModalContainer, Modal, Label, Flex, ImageContainer, TextContainer, BackButton, 
    Text, SetButton, CancelBtn
} from './index.styles';
import deleteImage from '../../../../assets/images/png/modalOption.png'
import spinner from '../../../assets/images/gif/spinner.gif'
import xmark from '../../../../assets/images/png/X.png'

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'
// import { useDeleteVehicleMutation } from '../../../services/providerApi';
// import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setServiceVisibility, setVehicleDetailss } from '../../../../slices/provider';
// import { setAllVehicle } from '../../../slices/provider';

type Props = {
    service?: string;
    setServicePopUp?: any;
    fullDayPopUp?: any;
    setFullDayPopUp?: any;
    hourlyPopUp?: any;
    setHourlyPopUp?: any;
    isHourlyService?: any;
    travelPopUp?: any;
    setTravelPopUp?: any;
    travelServicePrice?: any;
    setDriverFullDayPopUp?:any;
    allNightPopUp?:any;
    setDriverAllNightPopUp?:any;
    weeklyPopUp?:any;
    monthlyPopUp?:any;
    setDriverMonthlyPopUp?:any;
    setDriverWeeklyPopUp?:any;
}
export const PopupModal: FC<Props> = ({
    setDriverMonthlyPopUp,
    setDriverWeeklyPopUp,
    monthlyPopUp,weeklyPopUp, travelPopUp, setTravelPopUp, 
    travelServicePrice,  hourlyPopUp,
    setDriverAllNightPopUp,
    
    allNightPopUp,
    setDriverFullDayPopUp,
    setHourlyPopUp, fullDayPopUp, 

     setServicePopUp, service }) => {


    const dispatch: any = useAppDispatch()

    const handleCancelAllNight = () => {
        dispatch(setServiceVisibility({ driverAllNightServiceVisible: false }))
        setDriverAllNightPopUp(false)
    }

    const handleCancelFullDay = () => {
        dispatch(setServiceVisibility({ driverFullDayServiceVisible: false }))
        setDriverFullDayPopUp(false)
      }
    const handleCancelHourly = () => {
        dispatch(setServiceVisibility({ driverHourlyRateVisible: false }))
        setHourlyPopUp(false)
    }

    const handleCancelWeekly = () => {
        dispatch(setServiceVisibility({ driverWeeklyServiceVisible: false }))
        setDriverWeeklyPopUp(false)
    }
   

    const handleCancelTravel = () => {
        setTravelPopUp(false)
        dispatch(setServiceVisibility({ driverTravelVisible: false }))

    }
   
    const handleCancelMonthly = () => {
        dispatch(setServiceVisibility({ driverMonthlyVisible: false }))
        setDriverMonthlyPopUp(false)


    }

    const handleSetFullDay = () => {
        setDriverFullDayPopUp(false)
        // setHalfDayPopUp(false)
    }

    const handleSetHourly = () => {
        setHourlyPopUp(false)
    }

    const handleSetAllNight = () => {
        setDriverAllNightPopUp(false)
    }
    
    const handleSetMonthly = () => {
        setDriverMonthlyPopUp(false)
    }
    const handleSetWeekly = () => {
        setDriverWeeklyPopUp(false)
    }
    
    const handleSetTravel = () => {
        setTravelPopUp(false)
    }

   





    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",

    })

    return (
        <ModalContainer>
            <ToastContainer />

            <Modal>
                <ImageContainer>
                    <img src={deleteImage} style={{ width: '100%' }} />
                </ImageContainer>
                <TextContainer>
                    <Text>Note</Text>
                    <Label>
                        You are yet to input price detail for <span style={{ fontSize: '13px', fontWeight: '700' }}>{service}</span>,
                        you can click on the cancel button if you don't want a price detail for {service}
                    </Label>

                    <Flex>

                    {
                            hourlyPopUp && <SetButton onClick={handleSetHourly}>
                                Set Hourly
                            </SetButton>
                        }
                        
                        {
                            hourlyPopUp && <CancelBtn onClick={handleCancelHourly}>
                               Cancel
                            </CancelBtn>
                        }
                        {
                            fullDayPopUp && <SetButton onClick={handleSetFullDay}>
                                Set Full Day
                            </SetButton>
                        }
                        
                        {
                            fullDayPopUp && <CancelBtn onClick={handleCancelFullDay}>
                               Cancel
                            </CancelBtn>
                        }

                        {
                            allNightPopUp && <SetButton onClick={handleSetAllNight}>
                                Set All Night
                            </SetButton>
                        } 
                     {
                            allNightPopUp && <CancelBtn onClick={handleCancelAllNight}>
                               Cancel

                            </CancelBtn>
                        }


                        {
                            weeklyPopUp && <SetButton onClick={handleSetWeekly}>
                                Set Weekly
                            </SetButton>
                        }
                        {
                            weeklyPopUp && <CancelBtn onClick={handleCancelWeekly}>
                               Cancel

                            </CancelBtn>
                        }

                      

                        {
                            travelPopUp && <SetButton onClick={handleSetTravel}>
                                Set Travel
                            </SetButton>
                        }

                        {
                            travelPopUp && <CancelBtn onClick={handleCancelTravel}>
                               Cancel

                            </CancelBtn>
                        }
                         
                         {
                           monthlyPopUp && <SetButton onClick={handleSetMonthly}>
                                Set Monthly
                            </SetButton>
                        }

                        {
                            monthlyPopUp && <CancelBtn onClick={handleCancelMonthly}>
                               Cancel

                            </CancelBtn>
                        } 
                       


                    </Flex>
                </TextContainer>


            </Modal>
        </ModalContainer>

    )
}


