import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import filter from '../../../assets/images/png/filter.png'

import {
    Div, FlexDiv, Button, H6, DriverDiv, Input, InputFlexBetween, H4
} from './providerDrivers.styles';

import { FiSearch } from 'react-icons/fi';

import Driver from './driver';
import DriverRegistration from '../../../pages/Service_provider/DriverRegistration/driverRegistration';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useGetDriversQuery } from '../../../services/providerApi';
import { setAllDriver } from '../../../slices/provider';


const ProviderDrivers: FC = () => {
    let [addDriverComponent, setAddDriverComponent] = useState(false)
    let [ListDriverComponent, setListDriverComponent] = useState(true)

    const dispatch:any = useAppDispatch()
    const user = useAppSelector((state: any) => state?.auth?.auth)
    
    const { data: providerDrivers, isFetching: isFetchingDrivers, refetch: refetchDrivers } = useGetDriversQuery(user?.id)
     const drivers = useAppSelector((state: any) => state?.provider.allDriver)

     console.log('drivers',drivers)
     console.log('providerDrivers?.data',providerDrivers?.data)


    const handleAddDriverComponent = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setAddDriverComponent(true)
        setListDriverComponent(false)
    };

    const handleListDriverComponent = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        // alert('first')
        setListDriverComponent(true)
        setAddDriverComponent(false)
    };

    useEffect(() => {
        refetchDrivers()
        dispatch(setAllDriver(providerDrivers?.data))
    }, [user, providerDrivers])

    // const [driverData, setDriverData] = useState(drivers)
    // const [driverSearch, setDriverSearch] = useState(driverData)

    // useEffect(() => {
    //     setDriverData(drivers)
    //     setDriverSearch(drivers)
    // }, [user])

    //@ts-ignore
    const handleSearch = (e) => {
        let search = e.target.value.toLowerCase();

        // setDriverSearch(
        //     //@ts-ignore
        //     providerDrivers?.data.filter((data) => {
        //         return data?.firstname?.toLowerCase().includes(search.toLowerCase())
        //             ||
        //             data?.lastname?.toLowerCase().includes(search.toLowerCase())
        //     })
        // )
        dispatch(setAllDriver(providerDrivers?.data?.filter((item: any) => {
            let name = item?.firstname.toLowerCase()

            return name && name?.includes(search.toLowerCase())
                   
        })))
    }

    // STATES AND REF FOR HORIZONTAL SCROLL ACTIONS
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);


    //Slide click
    //@ts-ignore
    const slide = (shift) => {
        //@ts-ignore
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (
            //@ts-ignore
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            //@ts-ignore
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const scrollCheck = () => {
        //@ts-ignore
        setscrollX(scrl.current.scrollLeft);
        if (
            //@ts-ignore
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            //@ts-ignore
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    return (
        <>

            <Div>
                <FlexDiv>
                    <Button active={ListDriverComponent} onClick={handleListDriverComponent}>Drivers</Button>
                    <Button active={addDriverComponent} onClick={handleAddDriverComponent}>Add Driver</Button>
                </FlexDiv>

            </Div>


            {
                addDriverComponent &&
                <div style={{ marginTop: '-10px' }}>
                    <DriverRegistration hideHeader={true} dashboard={true} />
                </div>
            }
            {
                ListDriverComponent &&
                <>
                    <Div style={{ display: 'flex', justifyContent: 'center' }}>
                        <InputFlexBetween>
                            <Input placeholder='Search'
                                onChange={handleSearch}

                            />
                            <FiSearch style={{ color: '#DA6317', fontWeight: '700', fontSize: '25px' }} />
                        </InputFlexBetween>
                    </Div>

                    <div style={{marginBottom:30, overflow:'auto', display:'flex', scrollSnapAlign:'x mandatory', width: '100%' }}>
                        <DriverDiv style={{ marginTop: '25px' }}>
                            {/* <div className='ul-driver' ref={scrl} onScroll={scrollCheck} > */}

                            {
                                drivers?.map((driver: any) => {
                                    return <Driver key={driver.id} driverDetails={driver}
                                    />
                                })
                            }
                            {/* </div> */}
                        </DriverDiv>
                    </div>
                    <br/>
                    <br/>

                </>
            }







        </>

    )
}

export default ProviderDrivers