import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { IoIosArrowBack } from 'react-icons/io';
import { RiUser3Fill, RiArrowDownSLine } from 'react-icons/ri';
import { FaSuitcase } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import ID from '../../../assets/images/png/ID.png'
import ownership from '../../../assets/images/png/ownership.png'
import { BsEnvelopeFill } from 'react-icons/bs';
import { RiBriefcase4Fill } from 'react-icons/ri';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'


import {
    DOCDIV, DOC, Button, DOC1, P2, H44, DIV, Input, InputDiv, Label, FlexDiv, IconDiv
} from './index.styles';

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useGetBusinessByIdQuery, useUpdateBusinessDocMutation, useUpdateProfileMutation, useUpdateProviderMutation } from '../../../services/providerApi';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setBusinessProfile } from '../../../slices/provider';
import { DivContainer, H3, Loader, Loader2, LoaderContainer, Page } from '../ProfileSetup/profileSetup.styles';
import { FiChevronLeft } from 'react-icons/fi';
import Header from '../../../components/providerHeader';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';

const BusinessScreen: FC = () => {
    const dispatch: any = useAppDispatch()
    const category = useAppSelector((state: any) => state?.provider?.account_type)

    const businessProfileStore = useAppSelector((state: any) => state?.provider?.businessProfile)
    const navigate = useNavigate()


    const [image1, setImage1] = useState<any>();
    const [image1Prev, setImage1Prev] = useState<any>()
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [btnLoad, setBtnLoad] = useState<boolean>(false)



    const [businessName, setBusinessName] = useState<string>(businessProfileStore?.company_name || '');
    const [regNumber, setRegNumber] = useState<string>(businessProfileStore?.govt_num || '');
    const [businessPhone, setBusinessPhone] = useState<string>(businessProfileStore?.business_number || '')
    const [businessEmail, setBusinessEmail] = useState<string>(businessProfileStore?.business_email || '');





    const handleBusinessName = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setBusinessName((event.target as HTMLTextAreaElement).value)
        dispatch(setBusinessProfile({ company_name: (event.target as HTMLTextAreaElement).value }))

    };

    const handleBusinessPhone = (event: any) => {
        setBusinessPhone(event)
        dispatch(setBusinessProfile({ business_number: event }))

    };


    const handleRegNumber = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setRegNumber((event.target as HTMLTextAreaElement).value)
        dispatch(setBusinessProfile({ govt_num: (event.target as HTMLTextAreaElement).value }))

    };

    const handleBusinessEmail = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setBusinessEmail((event.target as HTMLTextAreaElement).value)
        dispatch(setBusinessProfile({ business_email: (event.target as HTMLTextAreaElement).value }))

    };



    const [updateBusiness, { data, isLoading, isSuccess, isError, error }] = useUpdateProviderMutation()
    const [updateBusinessDoc, { data: businessDoc, isLoading: isLoadingBusinessDoc, isSuccess: isDocSuccess, isError: isDocError, error: docError }] = useUpdateBusinessDocMutation()


    const user = useAppSelector((state: any) => state?.auth?.auth)
    const currentLocation = useAppSelector((state: any) => state?.currentLocation?.currentLocation?.country)
    const serviceOffering = useAppSelector((state: any) => state?.provider?.service_offering)

    // check if the provider has already filled his business info
    const { data: businessData, isFetching, refetch } = useGetBusinessByIdQuery(user?.id)

    useEffect(() => {
        refetch()
    }, [refetch, data])

    useEffect(() => {
        if (businessData?.data[0]?.company_name?.length > 1) {


            if (serviceOffering === 'Driver') {

                navigate('/add-guarantor')
            } else {
                navigate('/provider-dashboard')

            }
        } else {
            navigate('/add-business')
        }




    }, [businessData])



    // const category = useAppSelector((state: any) => state?.provider?.account_type)


    const handleUpdateBusiness = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let formData: any = new FormData()
        formData.append('user_id', user?.id)
        formData.append('category', 'business')
        formData.append('company_name', businessName)
        formData.append('govt_num', regNumber)
        formData.append('business_email', businessEmail)
        formData.append('business_number', businessPhone)
        formData.append('phone_number', user?.phone_number)
        formData.append('user_role', 'provider')


        updateBusiness(formData)
        if (category === "Business") {
            navigate('/provider-dashboard')

        } else if (category === "Individual" && serviceOffering === 'Driver') {

            navigate('/add-guarantor')
        } else {
            navigate('/provider-dashboard')

        }




    }



    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const file = event.currentTarget.files;
        if (file != null) {
            setImage1Prev(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                if (e.target != null) {
                    setImage1Prev(e.target.result);
                }
                setImage1(file[0]);
                handleUploadBusinessDocument()
            };
            reader.readAsDataURL(file[0]);
        }

        // setFrontImage(false)
        // setBackImage(true)
    };

    const handleUploadBusinessDocument = () => {
        let formData: any = new FormData()
        formData.append('user_id', user?.id)
        formData.append('doc_type', 'business certificate')
        formData.append('file', image1)



        updateBusinessDoc(formData)

    }
    const alertError = () => {
        notifyError('Please check if all details are correctly filled')
    }

    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    return (
        <>
            <ToastContainer />
            <Header />
            <Page>
                <PagePadding>


                    <div style={{ display: 'flex', gap: 10, alignItems: 'center', flexDirection: 'row' }}>




                        <div style={{ display: 'flex', gap: 10, alignItems: 'center', flexDirection: 'row' }}>

                            <FiChevronLeft
                                onClick={() => {
                                    navigate('/profile-setup')
                                }}
                                style={{ marginLeft: '-7px', color: '#fff', fontSize: 30 }} />
                            <H3>Finish Your  Profile</H3>
                        </div>
                    </div>


                    <LoaderContainer>
                        <DivContainer>
                            <Loader />
                            <Loader />
                        </DivContainer>
                        <DivContainer>
                            <Loader />
                            <Loader />
                        </DivContainer>
                        <DivContainer>
                            <Loader />
                            <Loader2 />
                        </DivContainer>
                    </LoaderContainer>
                    <DOCDIV style={{ marginTop: '10px' }}>
                        <DOC>
                            <DOC1 onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                event.preventDefault();
                                if (fileInputRef.current != null) {
                                    fileInputRef.current.click()
                                }
                            }}>
                                {image1Prev ?
                                    <img src={image1Prev} alt="" width={100} /> :
                                    <img src={ownership} alt="" width={100} />
                                }


                                <span style={{ position: 'absolute', right: '13px', top: '10px' }}>&uarr;</span>
                            </DOC1>
                            <P2>Certificate of incorporation </P2>
                        </DOC>

                    </DOCDIV>

                    <H44>Upload image or PDF</H44>

                    <DIV>


                        <div style={{ marginTop: '40px' }}>
                            <Label>Business Name</Label>
                            <Input
                                placeholder='Business Name'
                                value={businessName}
                                onChange={handleBusinessName}
                            />

                        </div>

                        <div style={{ marginTop: '10px' }}>
                            <Label>Government Registration  Number</Label>
                            <FlexDiv>
                                <IconDiv>
                                    <RiBriefcase4Fill />
                                </IconDiv>
                                <Input
                                    value={regNumber}
                                    onChange={handleRegNumber}
                                    style={{ width: '78%', margin: 0 }}
                                    placeholder='Registration  Number'
                                />
                            </FlexDiv>
                        </div>

                        <div style={{ marginTop: '10px' }}>
                            <Label>Business Email</Label>
                            <FlexDiv>
                                <IconDiv>
                                    <BsEnvelopeFill />

                                </IconDiv>
                                <Input
                                    type="email"
                                    value={businessEmail}
                                    onChange={handleBusinessEmail}
                                    style={{ width: '78%', margin: 0 }}
                                    placeholder='Business Email'
                                />
                            </FlexDiv>
                        </div>

                        <div style={{ marginTop: '10px' }} className='business'>
                            <Label>Business Number</Label>

                            <InputDiv>
                                <PhoneInput
                                    defaultCountry={currentLocation}
                                    //@ts-ignore
                                    value={businessPhone}
                                    defaultValue={businessPhone}
                                    onChange={handleBusinessPhone}
                                    international
                                    withCountryCallingCode
                                />
                            </InputDiv>

                        </div>





                        
                        {(businessName?.length < 3) ||
                            (regNumber?.length < 3) ||
                            (businessPhone?.length < 3) ||
                            (businessEmail?.length < 7) ?
                            <DIV style={{ justifyContent: 'center', flexDirection: 'row' }}>

                                <Button onClick={() => alertError()} style={{ background: '#000', color: 'gray' }} >
                                    Continue
                                </Button>
                            </DIV> :

                            <>
                                {btnLoad ?
                                    <DIV style={{ justifyContent: 'center', flexDirection: 'row' }}>

                                        <Button style={{ background: '#000', color: 'grey' }}>
                                            Please Wait...
                                        </Button>
                                    </DIV> :
                                    <DIV style={{ justifyContent: 'center', flexDirection: 'row' }}>


                                        <Button onClick={handleUpdateBusiness}>
                                            Finish
                                        </Button>


                                    </DIV>

                                }
                            </>

                        }

                    </DIV>

                    <input type="file" style={{ display: 'none' }} ref={fileInputRef} accept="image/*" onChange={onChange} />
                </PagePadding>

            </Page>
        </>

    )
}

export default BusinessScreen