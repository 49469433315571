import React, { useEffect, useState } from 'react'
import { CarouselImg, VehicleNameBox, VehiclePrice } from './carousel.styles'

import car1 from '../../assets/images/svg/carousel1.svg'
import car2 from '../../assets/images/svg/carousel2.svg'
import car3 from '../../assets/images/svg/carousel3.svg'
import { baseImageUrl } from '../../services/utils'

const Carousel = ({ currencyCode,slides, display, priceDisplay, title, displayAmount, imgHeight, linkPresent }: any) => {
    const imgLink = `https://v2api.roadlers.com/storage/`
    const imgLink2 = 'https://testapi.roadlers.com/storage/'
    const [currentSlide, setCurrentSlide] = useState(0)


    const infiniteScroll = () => {
        if (currentSlide === slides?.length - 1) {
            return setCurrentSlide(0)
        }
        return setCurrentSlide(currentSlide + 1)
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {infiniteScroll()}, 3000)

    //     return () => clearInterval(interval)
    // })

    const goToSlide = (id: number) => {
        setCurrentSlide(id)
    }

    return (
        <>
            <VehicleNameBox>
                {title}
            </VehicleNameBox>
            <div className="left-carousel-section">
                {
                    slides?.map((item: { img: any }, index: number) => {
                        return (
                            <div className="carousel-item"
                                key={index}
                                style={{ transform: `translate(-${currentSlide * 100}%)` }}
                            >
                                {
                                    linkPresent === true ?
                                        <CarouselImg src={baseImageUrl + item?.img} imgWidth={'100%'} imgHeight={imgHeight} loading="lazy" /> :
                                        <CarouselImg src={baseImageUrl+item?.img} imgWidth={'100%'} imgHeight={imgHeight} loading="lazy" />
                                }
                            </div>
                        )
                    })
                }

                <VehiclePrice display={priceDisplay}>
                    {currencyCode}{" "}{displayAmount}
                </VehiclePrice>
            </div>

            <div className="right-carousel-section">
                {
                    slides?.map((item: { img: any }, id: number) => {
                        return (
                            <div key={id} onClick={() => goToSlide(id)}>
                                {
                                    linkPresent === true ?
                                        <CarouselImg display={display} src={baseImageUrl + item?.img} imgWidth={'100%'} loading="lazy" /> :
                                        <CarouselImg display={display} src={baseImageUrl+item?.img} imgWidth={'100%'} loading="lazy" />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </>

    )
}

export default Carousel