import styled from 'styled-components'

export const WalletHeaderWrap = styled.div `
    width: 100%;

    & .inner-wrap {
        padding: 0 40px;
        display: flex;
        align-items: center;
        gap: 20px;

        @media only screen and (min-width: 1100px) {
            justify-content: center;
        }
    }

`

export const WalletProfileWrap = styled.div `
    border-radius: 50%;

    & img {
        width: 100%;
        height: 60px;
        object-fit: cover;
        object-position: center;
    }

    @media only screen and (max-width: 454px) {
        display: flex;
        justify-content: center;
        img {
            height: 40px;
        }
    }
`

export const WalletHeaderText = styled.div `
    & h2 {
        font-weight: 700;
        font-size: 22px;
        margin: 0;
        color: ${(props:any) => props.theme.text};
    }

    & p {
        font-size: 13px;
        margin: 0;
        color: ${(props:any) => props.theme.text};
    }

    @media only screen and (max-width: 454px) {
        h2 {
            font-size: 17px;
        }

        p {
            font-size: 9px;
        }
    }
`

export const CountryImgWrap = styled.div `
    & img {
        width: 30px;
    }

    & span {
        font-size: 30px !important;
    }

    @media only screen and (max-width: 454px) {
        span {
            font-size: 20px !important;
        }
    }
`

export const WalletCardWrap = styled.div `
    padding: 30px 40px;

    @media only screen and (min-width: 654px) {
        display: flex;
        justify-content: center;
    }

`

export const RowGapWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    padding: 0 50px;

    @media only screen and (max-width: 454px) {
        margin-top: 30px;
    }
`

export const DarkBtnWrap = styled.div <{inactive?: boolean}> `
    display: flex;
    gap: 10px;
    padding: 13px 16px;
    align-items: center;
    border-radius: 9px;
    background-color: ${(props:any) => props.inactive ? "rgba(134, 134, 134, 0.9)" : "#09051C"};
    cursor: ${(props:any) => props.inactive ? "not-allowed" : "pointer"};
    justify-content: center;
    position: relative;

    &.dark-btn-wrap {
        padding: 22px;
        background-color: ${(props:any) => props.inactive ? "rgba(134, 134, 134, 0.9)" : "#09051C"};
        p {
            color: ${(props:any) => props?.inactive ? "rgba(0,0,0, 0.5)" : ""}
        }

    }

    .transfer-icon {
        width: 25px;

        @media only screen and (max-width: 454px) {
            width: 15px;
        }
    }

    & p {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        margin: 0;
        white-space: nowrap;
    }

    & h3 {
        position: absolute;
        bottom: -35px;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
    }

    img.swap-icon {
        filter: brightness(0) saturate(100%) invert(34%) sepia(11%) saturate(8%) hue-rotate(326deg) brightness(97%) contrast(91%);
    }

    & .envelope {
        width: 40px;
    }

    @media only screen and (max-width: 454px) {
        padding: 10px 10px;
        border-radius: 6px;

        &.dark-btn-wrap {
            padding: 12px;
        }

        p {
            font-size: 11px;
        }

        h3 {
            font-size: 9px;
            bottom: -28px;
        }

        & .envelope {
            width: 20px;
        }
    }
`

export const WithdrawBtn = styled(DarkBtnWrap) `
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 7px 1px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 7px 1px rgba(0,0,0,1);
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,1);
    padding: 18px;

    & img {
        width: 20px;
    }

    & p {
        color: #000;
    }

    @media only screen and (max-width: 454px) {
        padding: 11px 12px;

        img {
            width: 14px;
        }
    }
` 

export const RowWrap = styled(RowGapWrap) `
    margin-top: 20px;
    margin-bottom: 30px;

    & .subwrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .swap-box {
            padding: 10px 13px;

            @media only screen and (max-width: 454px) {
                padding: 8px 10px;
            }
        }

        .transfer-icon {
            width: 25px;

            @media only screen and (max-width: 454px) {
                width: 15px;
            }
        }

        .swap-icon {
            width: 35px;

            @media only screen and (max-width: 454px) {
                width: 20px;
            }
        }
    }

    &.left-move {
        margin-left: 5px;
        gap: 15px;
    }
`

export const HistoryOverall = styled.div `
    display: flex;
    justify-content: flex-end;
    margin-top: 70px;
    margin-bottom: 20px;
    padding: 0 40px;

    @media only screen and (min-width: 1100px) {
        padding-right: 22%;
    }
`

export const PaymentHistoryBtn = styled.div `
    border-radius: 5px;
    position: relative;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #868686;
    cursor: pointer;
    gap: 20px;

    & p {
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        margin: 0;
    }

    & img {
        width: 25px;
    }

    & input {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background-color: transparent !important;
        border: none !important;
        color: transparent !important;
    }

    @media only screen and (max-width: 454px) {
        padding: 8px 10px;

        p {
            font-size: 10px;
        }

        img {
            width: 18px;
        }
    }
`

export const TabOverall = styled.div `
    padding: 20px 40px;
    padding-top: 0;

    @media only screen and (min-width: 1000px) {
        display: flex;
        justify-content: center;
    }
`

export const PaymentHistoryCardOverall = styled.div `
    padding: 30px 45px;
    padding-top: 0px;
    margin: 30px 0;
    height: 530px;
    overflow-y: scroll;
`

export const SeeMoreBtn = styled.div `
    background-color: #ffa100;
    color: #000;
    font-weight: 700;
    cursor: pointer;
    border-radius: 7px;
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    width: 180px; 
    margin-top: 30px;
`