import React from 'react'
import { CheckoutPayWrapper } from '../checkoutPayComponent/checkoutPay.styles'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'

import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import { AmountEnteredBox, ErrorTopUpBox } from '../fundWalletTwo/fundwallettwo.styles'
import { InactiveSubmitBtn, SubmitBtn } from '../toggleBar/togglebar.styles'
import LittleLoader from '../littleLoader'
import { RootState, useAppSelector } from '../../store'

const CheckoutCardChoice = ({showBox, setSubComponent, amountTotal, handlePayWithCard, payWithCardError, timedAddBookingError, addBookingError, handleAddBooking, cardPayIsLoading}:any) => {
    const { cart } = useAppSelector<any>((store: RootState) => store?.cart);

    if(cardPayIsLoading) {
        return <LittleLoader></LittleLoader>
    }

  return (

    <CheckoutPayWrapper display={showBox} >
        <BackBtnWrap top={'-50px'} onClick={() => {
            setSubComponent('a')
        }}>
            <img src={leftArrow} />
        </BackBtnWrap>

        <AmountEnteredBox>
            <p>Amount Entered</p>
            <h3>{cart[0]?.currency_code} {" "}
{`${(Number(amountTotal))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h3>
        </AmountEnteredBox>
        <ErrorTopUpBox>{payWithCardError || timedAddBookingError}</ErrorTopUpBox>


        <SubmitBtn onClick={() => {
            handleAddBooking('card')
        }}>
            <p>Pay</p>
        </SubmitBtn>

    </CheckoutPayWrapper>
  )
}

export default CheckoutCardChoice