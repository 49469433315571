import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import {
    Div, RequestDiv, FlexBetween, Buttons, Label, P
} from './providerRequests.styles';

import { BsCalendar3 } from 'react-icons/bs';


import Requests from './requests';
import RequestsCalendar from './requestsCalender';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useGetRequestsQuery } from '../../../services/providerApi';
import { setAllRequest, setRequestCount } from '../../../slices/provider';
import { List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import NoData from './NoData';

interface Request {
    book_status: string;

}


const ProviderRequests: FC = () => {


    const [requests, setRequests] = useState([])

    const user = useAppSelector((state: any) => state?.provider.service_provider)
    const providerRequest = useAppSelector((state: any) => state?.provider?.allRequest)

    const { data: providerRequests, isFetching: isFetchingRequests, refetch: refetchRequests } = useGetRequestsQuery()

    const totalMessageCount = providerRequests?.data?.length; // Simulated total message count



    const dispatch = useAppDispatch()

    useEffect(() => {
        refetchRequests()

        dispatch(setAllRequest(providerRequests?.data))
        dispatch(setRequestCount(totalMessageCount))
    }, [])



    let [calendarComponent, setCalendarComponent] = useState(0)
    let [requestComponent, setRequestComponent] = useState(1)


    const handleCalendarComponent = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setCalendarComponent(prev => prev = 1)
        setRequestComponent(prev => prev = 0)

    };
    const handleRequestComponent = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {

        setRequestComponent(prev => prev = 1)
        setCalendarComponent(prev => prev = 0)

    };

    // const requestOpenCount = providerRequest?.filter((item: Request,) => {
    //     return item?.book_status === 'Open';
    // })?.length;

    // const requestPendingCount = providerRequest?.filter((item: Request,) => {
    //     return item?.book_status === 'Pending';
    // })?.length;
    // const requestProgressCount = providerRequest?.filter((item: Request,) => {
    //     return item?.book_status === 'In Progress';
    // })?.length;

    // const requestCompletedCount = providerRequest?.filter((item: Request,) => {
    //     return item?.book_status === 'Completed';
    // })?.length;

    const requestOpenCount = Array.isArray(providerRequest)
    ? providerRequest?.filter((item: Request) => item?.book_status === 'Open').length
    : 0;

const requestPendingCount = Array.isArray(providerRequest)
    ? providerRequest?.filter((item: Request) => item?.book_status === 'Pending').length
    : 0;
const requestProgressCount = Array.isArray(providerRequest)
    ? providerRequest?.filter((item: Request) => item?.book_status === 'In Progress').length
    : 0;

const requestCompletedCount = Array.isArray(providerRequest)
    ? providerRequest?.filter((item: Request) => item?.book_status === 'Completed').length
    : 0;
    

    const [visible, setVisible] = useState(4);
    const [clickOpen, setClickOpen] = useState(false);
    const [clickPending, setClickPending] = useState(false);
    const [clickProgress, setClickProgress] = useState(false);
    const [clickHistory, setClickHistory] = useState(false);


    const [filterStatus, setFilterStatus] = useState('');

    const showMoreRequest = () => {
        setVisible((prevValue) => prevValue + 4);
    }

    const openFilter = () => {
        setClickOpen(true)
        setClickPending(false)
        setClickProgress(false)
        setClickHistory(false)
        setFilterStatus('Open')
    }
    const pendingFilter = () => {
        setClickOpen(false)
        setClickPending(true)
        setClickProgress(false)
        setClickHistory(false)
        setFilterStatus('Pending')
    }
    const progressFilter = () => {
        setClickOpen(false)
        setClickProgress(true)
        setClickProgress(false)
        setClickHistory(false)
        setFilterStatus('In Progress')
    }
    const historyFilter = () => {
        setClickOpen(false)
        setClickHistory(true)
        setClickProgress(false)
        setClickHistory(false)
        setFilterStatus('Finish')
    }


    console.log(providerRequests)
    // //@ts-ignore
    const filteredRequest = providerRequest?.filter(item => item?.book_status === filterStatus);


    return (

        <>
             <Div>
                <FlexBetween>
                    <Buttons clicked={true}  onClick={openFilter}>
                        open
                        {requestOpenCount > 0 &&
                            <P>{requestOpenCount}</P>
                        }
                    </Buttons>
                    <Buttons onClick={() => pendingFilter()}>
                        Pending
                        {requestPendingCount > 0 &&
                            <P>{requestPendingCount}</P>
                        }
                    </Buttons>
                    <Buttons onClick={() => progressFilter()}>
                        Progress
                        {requestProgressCount > 0 &&
                            <P>{requestProgressCount}</P>
                        }
                    </Buttons>
                    <Buttons onClick={() => historyFilter()}>History
                        {requestCompletedCount > 0 &&
                            <P>{requestCompletedCount}</P>
                        }
                    </Buttons>
                </FlexBetween>

                <FlexBetween style={{ marginTop: '15px' }}>
                    <Label>{providerRequest?.length} Requests</Label>

                    {calendarComponent === 1 &&
                        <FlexBetween onClick={(e: any) => handleRequestComponent(e)} style={{ width: 'auto', background: '#000', borderRadius: '5px', padding: '5px 8px' }}>
                            <Label style={{ fontSize: '12px' }}>View Requests</Label>
                        </FlexBetween>}

                    {requestComponent === 1 &&
                        <FlexBetween onClick={(e: any) => handleCalendarComponent(e)} style={{ width: 'auto', background: '#000', borderRadius: '5px', padding: '5px 8px' }}>
                            <Label style={{ fontSize: '12px' }}>View Calendar</Label>
                            <BsCalendar3 />
                        </FlexBetween>}

                </FlexBetween>


            </Div>



            <RequestDiv style={{ marginTop: '45px' }}>

                {calendarComponent === 1 && <RequestsCalendar
                    setRequestComponent={setRequestComponent}
                    setCalendarComponent={setCalendarComponent}
                    requests={providerRequest} />}


                {requestComponent === 1 &&
                    clickOpen || clickPending || clickProgress || clickHistory ?
                    <>
                        {filteredRequest.length > 0 ?
                            filteredRequest?.slice(0, visible).map((request: any) => {
                                return < Requests key={request?.id} request={request} />
                            })
                            :
                            <NoData />
                        }
                    </>
                    :
                    (providerRequest?.slice(0, visible).map((request: any) => {
                        return < Requests key={request?.id} request={request} />
                    }))



                }
                {providerRequest?.length > 4 ? (

                    <button
                        style={{
                            background: '#ffa100',
                            outline: 'none',
                            fontSize: 15,
                            fontWeight: 'bold',
                            border: 'none',
                            padding: '10px 00px',
                            borderRadius: 5,
                            width:'100%',
                            color:'#000'
                        }}
                        onClick={showMoreRequest}>
                        Load More
                    </button>) : (
                    filteredRequest?.length > 4 ?
                        (<button
                            style={{
                                background: '#ffa100',
                                outline: 'none',
                                fontSize: 15,
                                fontWeight: 'bold',
                                border: 'none',
                                padding: '10px 00px',
                                borderRadius: 5,
                                color:'#000'
                            }}
                            onClick={showMoreRequest}>
                            Load More
                        </button>) : ''
                )}
            </RequestDiv>
 



        </>

    )
}

export default ProviderRequests