import styled from "styled-components";

export const AccordionOverall = styled.div`
  margin-bottom: 30px;
`;

export const AccordionHeader = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
  cursor: pointer;
  border-radius: 12px;

  & .inner-accordion-header {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;

    .doc-title {
      font-size: 17px;
      font-weight: 700;
      color: #000;
    }
  }
`;

export const RightAccordionHeadWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ApprovedBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  border-radius: 7px;
  background-color: #005fee;
`;

export const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 35px;
    transform: rotate(267deg);
  }
`;

export const AccordionBody = styled.div`
  padding: 0 30px;
`;

export const AccordionContentWrap = styled.div`
  display: ${(props: any) => (props.contentShow ? "flex" : "none")};;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #eaeaea;
  // max-height: ${(props: any) => (props.contentShow ? "9999px" : 0)};
  // height: ${(props: any) => (props.contentShow ? "160px" : "unset")};
  transition: ${(props: any) =>
    props.contentShow
      ? "all 0.5s cubic-beizer(1, 0, 1, 0)"
      : "all 0.5s cubic-beizer(0, 1, 0, 1)"};
`;
