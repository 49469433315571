import React, { useState } from "react";
import { ModalFilterBox } from "../filterBoxModal/filterModal.styles";
import {
  CancelModalBox,
  ModalOverlay,
} from "../optionModal/optionmodal.styles";
import { SubmitBtn } from "../toggleBar/togglebar.styles";
import {
  LocationActionWrap,
  LocationHeader,
  LocationInputWrapper,
  LocationModalBox,
  LocationModalOverlay,
} from "./locationInput.styles";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";

import cancel from "../../assets/images/svg/cancel-yellow.svg";

const LocationInputModal = ({
  location,
  setLocation,
  showModal,
  setShowModal,
  setCity,
  setPickupLatLong,
}: any) => {
  const [origin, setOrigin] = useState();
  const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null);

  // const libraries: (
  //   | "places"
  //   | "drawing"
  //   | "geometry"
  //   | "localContext"
  //   | "visualization"
  // )[] = ["places", "geometry", "drawing"];

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
  //   libraries,
  // });

  const onOriginPlaceChanged = () => {
    if (autocompleteOrigin !== null) {
      const places = autocompleteOrigin.getPlace().formatted_address;
      if (places) {
        setOrigin(places);
      } else {
        setOrigin(autocompleteOrigin.getPlace().name);
      }
    }
  };

  async function calculateRoute() {
    // @ts-ignore
    if (origin?.length === 0) {
      return;
    }
    const address = origin;
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (result: any, status) => {
      if (status === "OK") {
        setPickupLatLong({
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng(),
        });
        if (result[0]) {
          // Get the city from the results
          const extractedCity = extractCityFromResults(result[0]);
          setCity(extractedCity);
        }
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  }

  const extractCityFromResults = (result: any) => {
    let city = "";
    for (let component of result.address_components) {
      for (let type of component.types) {
        if (type === "locality" || type === "administrative_area_level_1") {
          city = component.long_name;
          break;
        }
      }
      if (city !== "") {
        break;
      }
    }
    return city;
  };

  return (
    <LocationModalOverlay activeModal={showModal}>
      <CancelModalBox onClick={() => setShowModal(false)} />

      <LocationModalBox>
        <div className="inner-modal-box">
          <LocationHeader>
            <p>Location of Vehicle</p>

            <div className="cancel-wrap">
              <img src={cancel} onClick={() => setShowModal(false)} />
            </div>
          </LocationHeader>

          <LocationInputWrapper>
            <Autocomplete
              className="autocomplete-wrap"
              onPlaceChanged={onOriginPlaceChanged}
              onLoad={(value) => setAutocompleteOrigin(value)}
            >
              <input
                type="text"
                placeholder="Type here"
                value={origin}
                onChange={(e: any) => {
                  setLocation(e.target.value);
                  setOrigin(e.target.value);
                }}
              />
            </Autocomplete>
          </LocationInputWrapper>

          <LocationActionWrap>
            <SubmitBtn
              onClick={() => {
                setShowModal(false);
                calculateRoute();
              }}
            >
              Continue
            </SubmitBtn>
          </LocationActionWrap>
        </div>
      </LocationModalBox>
    </LocationModalOverlay>
  );
};

export default LocationInputModal;
