import React, { useState } from 'react'
import { DropdownContent } from '../dropdown/dropdown.styles'
import { InsuranceDropdownContent, InsuranceDropdownWrap } from './insurancedropdown.styles'

import arrow from '../../assets/images/svg/arrow-down-vector.svg'

const InsuranceDropdown = ({list, selectType, choice, setChoice}:any) => {
    const [dropdownActive, setDropdownActive] = useState(false)

    const handleListChosen = (item:any) => {
        setChoice(item.name)
    }
  return (
    <>
    <InsuranceDropdownWrap onClick={() => setDropdownActive(!dropdownActive)}>
        <p> { choice || <span>{selectType}</span>}</p>
        <img src={arrow} />

        <InsuranceDropdownContent active={dropdownActive}>
            {list?.map((item:any, id:any) => 
                    <div className="content-text" key={id} onClick={() => handleListChosen(item)}>
                        {item?.name}
                    </div>
            )}
        </InsuranceDropdownContent>
    </InsuranceDropdownWrap>
    </>
  )
}

export default InsuranceDropdown