import styled from 'styled-components'


export const H4 = styled.h4`
color:#fff;
margin-top:50px;
`


export const ButtonFlex =styled.div`
 display:flex;
width:100%;
justify-content:flex-end;
margin-top:50px;
`
export const Button = styled.button`
color:#000;
padding:10px 20px;
background:#ffa100;
border-radius:5px;
font-size:14px;
font-weight:bold;
border:none;
`
export const FlexDiv =styled.div`
 display:flex;
width:100%;
justify-content:space-between;
margin-top:50px;
flex-wrap:wrap;
`

export const BalanceDiv=styled.div`
background:#000;
height:160px;
width:100%;
margin-top:20px;
border-radius:20px;
position:relative;
overflow:hiddem;
`

export const FlexNoWrap =styled.div`
 display:flex;
width:100%;
justify-content:space-between;
margin-top:50px;
align-items:center;
`
export const CountryDiv=styled.div`
background:#fff;
border-radius:7px;
width:50%;
display:flex;
align-items:center;
gap:5px;
padding-right:10px;
`

export const Country=styled.div`
background:#000;
border-radius:7px;
width:40%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
padding:7px;

`






