import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { LabelDiv, AddSpan, Add } from "./longTermRates.styles";
import {
  VerifyButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  Input,
  MARKDIV,
  Wrapper,
  BlackOpacity,
  Text,
  DropDown,
  Label,
  LabelTitle,
  SubmitDiv,
  SubmitButton,
} from "../regularRates/regularRates.styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  useAddNewCarPriceMutation,
  useCarPriceUpdateMutation,
} from "../../../services/providerApi";
import LongTermPopUp from "./longTermPopUp";
import DiscountModal from "../discountModal";
import { AnyIfEmpty } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";
import { useAppSelector } from "../../../store";

type Props = {
  vehicleProfile: any;
};
const LongTermRates: FC<Props> = ({ vehicleProfile }) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [updateVehiclePrice, { data, isLoading, isSuccess, isError, error }] =
    useCarPriceUpdateMutation();
  const [
    addNewVehiclePrice,
    {
      data: newData,
      isLoading: newIsLoading,
      isSuccess: newIsSucess,
      isError: newIsError,
      error: newError,
    },
  ] = useAddNewCarPriceMutation();

  const [vehicleInfo, setVehicleInfo] = useState(vehicleProfile?.data[0]);
  const [isDiscountVisible, setIsDiscountVisible] = useState(false);

  const [isNewLongTerm, setIsNewLongTerm] = useState(false);

  const [priceList, setPriceList] = useState({});

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  let LongRatesPrice = vehicleInfo?.service_price?.filter(function (data: any) {
    return data?.service_title === "Full Month Service";
  });

  // setFullMonthPrice(event.target.value)

  const handlePrice = (event: any, servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = vehicleInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        return {
          ...data,
          price: event,
        };
      }
      return data;
    });

    setVehicleInfo({
      ...vehicleInfo,
      service_price: updatedServiceData,
    });
  };

  const handleIncrease = (servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = vehicleInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        if (servicePrice?.service_title === "Full Month Service") {
          const currentMonth = Number(data?.hour);
          const newMonth = currentMonth + 1;
          const updatedMonth = newMonth <= 12 ? newMonth : currentMonth;
          return {
            ...data,
            hour: updatedMonth,
          };
        }
      }
      return data;
    });
    setVehicleInfo({
      ...vehicleInfo,
      service_price: updatedServiceData,
    });
  };

  const handleDecrease = (servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = vehicleInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        if (servicePrice?.service_title === "Full Month Service") {
          const currentMonth = Number(data?.hour);
          const newMonth = currentMonth - 1;
          const updatedMonth = newMonth >= 1 ? newMonth : currentMonth;
          return {
            ...data,
            hour: updatedMonth,
          };
        }
      }
      return data;
    });
    setVehicleInfo({
      ...vehicleInfo,
      service_price: updatedServiceData,
    });
  };

  const saveData = () => {
    setIsSaveLoading(true);
    updateVehiclePrice(vehicleInfo.service_price)
      .then((response: any) => {
        // setUserId(response?.data?.data[0]?.user_id)
        setIsSaveLoading(false);
        setShowSaveButton(false);

        // setProviderDetails(response?.data)
      })
      .catch((err) => {
        setIsSaveLoading(false);
      });
  };

  const differences: any = [];

  console.log(vehicleProfile?.data[0].service_price);
  console.log(vehicleInfo?.service_price);

  vehicleInfo?.service_price.forEach((item2: any) => {
    const foundItem = vehicleProfile?.data[0]?.service_price.find(
      (item1: any) => item1.created_at === item2.created_at
    );

    if (!foundItem) {
      differences.push(item2);
    }
  });

  // Send the differences when adding new travel
  const addData = () => {
    setIsSaveLoading(true);
    addNewVehiclePrice(differences)
      .then((response: any) => {
        setIsSaveLoading(false);
        setShowSaveButton(false);
      })
      .catch((err: any) => {
        setIsSaveLoading(false);
      });
  };
  return (
    <>
      <LabelDiv>
        <Label>Long Term Rates </Label>
        <AddSpan onClick={() => setIsNewLongTerm((prev: boolean) => !prev)}>
          <Add>+</Add>
          <Text>Add New </Text>
        </AddSpan>
      </LabelDiv>

      {LongRatesPrice?.map((priceList: any) => (
        <FLEXDIV key={priceList?.id}>
          <INPUTDIV>
            <label
              style={{
                color: "#868686",
                display: "flex",
                width: "40%",
                fontSize: "14px",
                fontWeight: "normal",
              }}
              htmlFor=""
            >
              {priceList?.service_title}
            </label>
            <VerifyButton
            // onClick={openModal}
            >
              <P style={{ left: "0px", top: "-37px" }}>Months</P>

              <Text>{priceList?.hour || priceList?.months || 0}</Text>
              <DropDown>
                <IoIosArrowUp
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  onClick={() => handleIncrease(priceList)}
                />
                <IoIosArrowDown
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  onClick={() => handleDecrease(priceList)}
                />
              </DropDown>
            </VerifyButton>

            <BLACKDIV>
              <P style={{ left: "0px", top: "-37px" }}>Set Price</P>

              <Input>
                <CurrencyInput
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    color: "#fff",
                  }}
                  decimalsLimit={2}
                  prefix={formatCurrencyPrefix(currentLocation)}
                  placeholder="0.00"
                  value={priceList?.price}
                  onValueChange={(e: any) => handlePrice(e, priceList)}
                />
              </Input>
              <DISCOUNT
                onClick={() => {
                  setIsDiscountVisible(true);
                  setPriceList(priceList);
                  setShowSaveButton(true);
                }}
              >
                <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                {priceList?.discount}%
              </DISCOUNT>
            </BLACKDIV>
          </INPUTDIV>
        </FLEXDIV>
      ))}

      {isDiscountVisible && (
        <DiscountModal
          priceList={priceList}
          setServiceData={setVehicleInfo}
          serviceData={vehicleInfo}
          setIsDiscountVisible={setIsDiscountVisible}
        />
      )}

      {isNewLongTerm && (
        <LongTermPopUp
          setVehicleInfo={setVehicleInfo}
          vehicleInfo={vehicleInfo}
          setIsNewLongTerm={setIsNewLongTerm}
          setShowSaveButton={setShowSaveButton}
        />
      )}

      {vehicleInfo?.service_price?.length >
      vehicleProfile?.data[0].service_price?.length ? (
        <SubmitDiv>
          <SubmitButton onClick={() => addData()}>Add and Update</SubmitButton>
        </SubmitDiv>
      ) : (
        showSaveButton && (
          <SubmitDiv>
            {isSaveLoading ? (
              <SubmitButton style={{ backgroundColor: "#059bbf" }}>
                Updating Pricing...
              </SubmitButton>
            ) : (
              <SubmitButton
                style={{ backgroundColor: "#059bbf" }}
                onClick={() => saveData()}
              >
                Save and Update Long Term Price
              </SubmitButton>
            )}
          </SubmitDiv>
        )
      )}
    </>
  );
};
export default LongTermRates;
