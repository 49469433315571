import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { BsEnvelopeFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import Envelop from "../../../assets/images/png/envelop.png";

import {
  DIV,
  INPUTS,
  P,
  FLEXDIV,
  FLEXDIV2,
  SELECTDIV,
  Input,
  ICONDIV,
  Text,
  DropDown,
  Modal,
  Div,
  Option,
  Radio,
  Button,
} from "./kin.styles";
import {
  useGetNextOfKinByProviderIdQuery,
  useNextofKinMutation,
  useUpdateKinMutation,
} from "../../../services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitButton, SubmitDiv } from "../regularRates/regularRates.styles";
import { setSaveNextOfKin } from "../../../slices/provider";

type Props = {
  userId?: any;
  providerInfo?: any;
  setProviderInfo?: any;
};
const KinScreen: FC<Props> = ({ userId, providerInfo, setProviderInfo }) => {
  const location = useLocation();
  const currentUrl = location.pathname;


  console.log("providerInfo",providerInfo)
  const dispatch: any = useAppDispatch();

  // setSaveNextOfKin
  const saveNextOfKinStatus: any = useAppSelector(
    (state: any) => state?.provider?.saveNextOfKin
  );

  const {
    data: providerKin,
    isFetching: isFetchingProviderKin,
    refetch: refetchProviderKin,
  } = useGetNextOfKinByProviderIdQuery(userId);
  console.log("providerKin", providerKin);

  useEffect(() => {
    refetchProviderKin();
  }, [refetchProviderKin, providerKin]);

  // useEffect(() => {
  //     dispatch(setSaveNextOfKin(true))

  //   }, [])

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [btnLoad, setBtnLoad] = useState<boolean>(false);
  const navigate = useNavigate();

  let [nextOfKin, setNextOfKin] = useState(providerInfo?.next_of_kin);

  useEffect(() => {
     
    if(providerInfo && providerInfo?.next_of_kin){
      setNextOfKin(providerInfo?.next_of_kin)
    }
  }, [providerInfo?.next_of_kin ])
  
  const handleRelationship = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setNextOfKin((prevState: any) => ({
      ...prevState,
      relationship: (event.target as HTMLTextAreaElement).value,
    }));
    setShowSaveButton(true);
  };
  const handleFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setNextOfKin((prevState: any) => ({
      ...prevState,
      firstname: (event.target as HTMLTextAreaElement).value,
    }));
    setShowSaveButton(true);
  };
  const handleLastName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setNextOfKin((prevState: any) => ({
      ...prevState,
      lastname: (event.target as HTMLTextAreaElement).value,
    }));
    setShowSaveButton(true);
  };
  const handlePhone = (e: any) => {
    setNextOfKin((prevState: any) => ({
      ...prevState,
      phone_number: e,
    }));
    setShowSaveButton(true);
  };
  const handleEmail = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setNextOfKin((prevState: any) => ({
      ...prevState,
      email: (event.target as HTMLTextAreaElement).value,
    }));
    setShowSaveButton(true);
  };

  const [nextofKin, { data, isLoading, isSuccess, isError, error }] =
    useUpdateKinMutation();
  const [
    addNextOfKin,
    {
      data: kin,
      isLoading: kinLoading,
      isSuccess: kinSuccess,
      isError: kinIsError,
      error: kinError,
    },
  ] = useNextofKinMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);
      // navigate('/provider-dashboard')
      // navigate('/insurance-setup')
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handleUpdateNextofKin = () => {
    setIsSaveLoading(true);

    let formData: any = new FormData();
    formData.append("user_id", providerInfo?.id);
    formData.append("relationship", nextOfKin?.relationship);
    formData.append("firstname", nextOfKin?.firstname);
    formData.append("lastname", nextOfKin?.lastname);
    formData.append("email", nextOfKin?.email);
    formData.append("phone_number", nextOfKin?.phone_number);

    dispatch(setSaveNextOfKin(false));
    if (providerKin[0]?.next_of_kin === null) {
      addNextOfKin(formData)
        .then((response: any) => {
          setIsSaveLoading(false);
          setShowSaveButton(false);
        })
        .catch((err) => {
          setIsSaveLoading(false);
          notifyError("Error Occured");
        });
    } else {
      nextofKin(formData)
        .then((response: any) => {
          setIsSaveLoading(false);
          setShowSaveButton(false);
        })
        .catch((err) => {
          setIsSaveLoading(false);
          notifyError("Error Occured");
        });
    }
  };

  // const pop = () => {
  //     if (!currentUrl?.includes('vehicle-profile') && showSaveButton === true) {

  //         window.confirm("Do you want to continue?");
  //     }
  // }
  // useEffect(() => {
  //     pop()
  //   }, [currentUrl])

  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );

  const alertError = () => {
    notifyError("Please Fill all Important Fields");
  };
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return (
    <>
      <ToastContainer />

      <DIV>
        <DIV>
          {/* {kinProfile ? '' : <P>Add next of kin *</P>} */}
          <INPUTS
            type="text"
            value={nextOfKin?.relationship}
            onChange={handleRelationship}
            style={{
              width: "100%",
              padding: "20px",
              border: "none",
              outline: "none",
            }}
            placeholder="Relationship"
          />
        </DIV>

        <FLEXDIV style={{ marginTop: "10px" }}>
          <INPUTS
            type="text"
            value={nextOfKin?.firstname}
            onChange={handleFirstName}
            style={{ padding: "20px", border: "none", outline: "none" }}
            placeholder="Firstname"
          />
          <INPUTS
            type="text"
            value={nextOfKin?.lastname}
            onChange={handleLastName}
            style={{ padding: "20px", border: "none", outline: "none" }}
            placeholder="Lastname"
          />
        </FLEXDIV>

        <DIV>
          <div className="phone-select">
            <Input className="phone">
              <PhoneInput
                defaultCountry={currentLocation}
                defaultValue={nextOfKin?.phone_number}
                //@ts-ignore
                value={nextOfKin?.phone_number}
                onChange={handlePhone}
                international
                withCountryCallingCode
              />
            </Input>
          </div>
        </DIV>

        <FLEXDIV2>
          <ICONDIV>
            <BsEnvelopeFill />
          </ICONDIV>
          <INPUTS
            type="email"
            value={nextOfKin?.email}
            onChange={handleEmail}
            style={{
              width: "78%",
              padding: "20px 0px",
              border: "none",
              outline: "none",
            }}
            placeholder="Johndoe@gmail.com"
          />
        </FLEXDIV2>
      </DIV>

      {showSaveButton && (
        <SubmitDiv>
          {isSaveLoading ? (
            <SubmitButton style={{ backgroundColor: "#059bbf" }}>
              Updating Profile...
            </SubmitButton>
          ) : (
            <SubmitButton
              style={{ backgroundColor: "#059bbf" }}
              onClick={() => handleUpdateNextofKin()}
            >
              Save and Update Next of Kin
            </SubmitButton>
          )}
        </SubmitDiv>
      )}
    </>
  );
};

export default KinScreen;
