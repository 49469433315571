import React, { useState } from "react";
import "./styles/Header.css";
import {
  CancelMenu,
  HeaderOverlay,
  MenuBar,
  RoadlerHeader,
} from "./Header.styles";
import ToggleBar from "../toggleBar";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { setTheme } from "../../slices/theme";
import { Icons } from "../../pages/user/Homepage/homepage.styles";
import { NavLink, useNavigate } from "react-router-dom";

import logo from "../../assets/images/png/logo.png";
import profileIcon from "../../assets/images/png/user-icon.png";
import cartIcon from "../../assets/images/png/cartIcon.png";

import giftIcon from "../../assets/images/svg/gift-icon-header.svg";
import cancel from "../../assets/images/svg/cancel-yellow.svg";
import home from "../../assets/images/svg/home-link.svg";
import vehicle from "../../assets/images/svg/vehicle-link.svg";
import driver from "../../assets/images/svg/driver-link.svg";
import delivery from "../../assets/images/svg/delivery-link.svg";
import promotion from "../../assets/images/svg/promotion-link.svg";
import blog from "../../assets/images/svg/blog-link.svg";
import user from "../../assets/images/svg/user-link.svg";
import partner from "../../assets/images/svg/partner-link.svg";
import { clearAuth, setRole } from "../../slices/auth";
import { setLogout } from "../../slices/provider";

const Header = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { theme } = useAppSelector<any>((store: RootState) => store?.theme);

  const { token = "" } =
    useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};

  const [menuShow, setMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenu = () => {
    setMenuShow(!menuShow);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const themeToggle = () => {
    theme === "dark" ? dispatch(setTheme("light")) : dispatch(setTheme("dark"));
  };

  const handleLogout = () => {
    dispatch(clearAuth({}));
    dispatch(setRole(""));
    navigate("/");
  };

  return (
    <RoadlerHeader>
      <HeaderOverlay
        showMenu={menuShow}
        onClick={() => {
          setMenuShow(false);
        }}
      />
      <nav style={{ overflowY: "hidden" }}>
        {/* <div className="logo-wrapper">
          <NavLink to="/">
            <img src={logo} alt="" width={60} height={20} />
          </NavLink>
        </div> */}
        <div className="logo-wrapper">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>

        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/">Services</NavLink>
          </li>
          <li>
            <NavLink to="/">Partner</NavLink>
          </li>
          <li>
            <NavLink className="blog-link" to="/admin-blog-list">
              Blog
            </NavLink>
          </li>
        </ul>

        <div className="buttons-wrapper">
          {/* <NavLink to={'/user-earning-page'}>
                    <Icons className="gift-icon" src={giftIcon} />
                </NavLink> */}

          {/* <NavLink to={'/cart'}>
                    <Icons className="cart-icon" src={cartIcon}  />
                </NavLink> */}

          <NavLink to={"/admin-dashboard"}>
            <Icons src={profileIcon} />
          </NavLink>

          {token.length ? (
            <>
              <button onClick={() => handleLogout()} className="signup">
                Logout
              </button>
            </>
          ) : (
            <>
              <NavLink to="/login" className="login">
                Login
              </NavLink>
              <a href="https://roadlers.com/sign-up" className="signup">
                Sign-Up
              </a>
            </>
          )}

          <div className="toggle-wrapper" onClick={handleMobileMenu}>
            <MenuBar>
              <div></div>
            </MenuBar>
          </div>
        </div>

        <div className={menuShow ? "mobile-menu active-mobile" : "mobile-menu"}>
          <div className="inner-wrapper">
            <CancelMenu>
              <img
                src={cancel}
                onClick={() => {
                  setMenuShow(false);
                }}
              />
            </CancelMenu>

            <ul className="mobile-list">
              <li>
                <NavLink to="/">
                  <img src={home} /> Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  <img src={vehicle} /> Vehicles
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  {" "}
                  <img src={driver} /> Drivers
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  {" "}
                  <img src={delivery} /> Deliveries
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  {" "}
                  <img src={promotion} /> Promotions
                </NavLink>
              </li>
              <li>
                <NavLink to="/individual-reg">
                  {" "}
                  <img src={partner} /> Partner with us
                </NavLink>
              </li>
              <li className="accordion-overall">
                <div className="accordion">
                  <div className="accordion-header" onClick={toggleAccordion}>
                    <span>
                      <img src={user} />
                    </span>
                    <h3>Sign Up</h3>
                  </div>

                  <div
                    className={`accordion-content ${
                      isOpen ? "open" : "closed"
                    }`}
                  >
                    <ul className="signup-call-wrap">
                      <li>
                        <NavLink to="/customer-signup">
                          Create an Account
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/individual-reg">
                          List your vehicle
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/individual-reg">Be a Driver</NavLink>
                      </li>
                      <li>
                        <NavLink to="/individual-reg">
                          Be a Logistics Partner
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/individual-reg">
                          Business Affiliate
                        </NavLink>
                      </li>
                    </ul>
                    {/* <p>Content for section 1 goes here</p> */}
                  </div>
                </div>
              </li>
              {token ? (
                <li
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                  onClick={handleLogout}
                >
                  <img src="" /> Logout
                </li>
              ) : (
                <li>
                  <NavLink to="/login">Login</NavLink>
                </li>
              )}
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <NavLink className="blog-link" to="/admin-blog-list">
                  {" "}
                  <img src={blog} /> Blog
                </NavLink>
                <ToggleBar onClick={themeToggle} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </RoadlerHeader>
  );
};

export default Header;
