import styled from 'styled-components'

export const DriverDiv = styled.div`
background:#fff;
display:flex;
border-radius:10px;
padding:.9em .7em;
gap:10px;
border:1px solid #242321;
width:300px;
`
export const Div = styled.div`
background:#fff;
display:flex;
flex-direction:column;
color:#000;

`

export const ImageDiv = styled.div`
background:#fff;
padding:.3em;
box-shadow: 4px 4px 15px 2px #00000024;
width:80px;
height:80px;
border-radius:10px;
display:flex;
align-items:center;
justify-content:center;
overflow:hidden;
`
export const CheckMark = styled.div`
display:flex;
align-items:center;
gap:5px;
margin-bottom:-12px;
`
export const DriverDetails = styled.div`
position:relative;
display:flex;
flex-direction:column;
gap:15px;
margin-bottom:-25px;
`
export const FlexDiv = styled.div`
display:flex;
gap:8px;
align-items:start;
justify-content:space-between;
width:100px;

`
export const H6 = styled.h6`
font-weight:800;
font-size:10px;
`

export const Button = styled.button`
font-weight:bold;
background: #FFD58C;
font-size:11px;
padding:2px 5px;
border-radius:3px;
color:#000;
outline:none;
border:none;
`
export const Label = styled.label`
font-weight:bold;
background: #DA6317;
font-size:11px;
padding:2px 5px;
border-radius:3px;
color:#fff;


`
