import { ModalBox } from "./../optionModal/optionmodal.styles";
import { colors } from "./../../assets/styles/Theme";
import styled from "styled-components";
import { OptionPill } from "../optionModal/optionmodal.styles";
import { SaveBtn } from "../extrasModal/extrasmodal.styles";

export const LocationBox = styled.div`
  border-radius: 14px;
  border: solid 1px white;
  height: 300px;
  width: 100%;
  overflow: hidden;

  &img {
    width: 100px;
    height: 100%;
    object-fit: contain;
  }
  @media only screen and (max-width: 484px) {
    height: 200px;
  }
  @media only screen and (min-width: 700px) {
    height: 180px;
  }
`;

export const LocationModalBox = styled(ModalBox)`
  display: ${(props: any) => props.boxDisplay};

  & .inner-modal-box {
    .header-text h2 {
      font-weight: 800;
      font-size: 30px;
      text-align: center;
    }

    .content-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;

      p {
        text-align: center;
        font-size: 18px;
        margin: 0;
      }
    }

    .redirect-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
      color: #ffa100;

      button {
        background-color: transparent !important;
        color: #ffa100 !important;
        outline: none !important;
        border: none !important;
      }
    }

    @media only screen and (max-width: 484px) {
      padding: 20px;
    }
  }
`;

export const UserLocationWrap = styled.div`
  & .where-pill-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;

    .yellow-dash {
      width: 40px;
      border: solid 3px #ffa100;
      border-radius: 9px;
      margin: 40px 0;

      @media only screen and (max-width: 454px) {
        margin: 20px 0;
      }
    }
  }

  & .location-input-overall {
    label {
      margin-left: 20px;
      font-size: 13px;
      color: #c4c4c4;
      font-weight: 700;
    }

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 25px;
      margin-top: 0;
    }

    .booking-info {
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-top: 25px;
      span {
        color: #ffa100;
        cursor: pointer;

        button {
          border: none;
          outline: none;
          background-color: transparent;
          color: #fbbd28;
        }
      }
    }
  }
`;

export const WhereOptionPill = styled(OptionPill)`
  background-color: transparent;
  padding: 0;

  & p {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
  }
`;

export const WhereRadioPill = styled.div`
  border-radius: 50%;
  border: solid 3px #fff;
  width: 16px;
  height: 16px;
  background-color: ${(props: any) =>
    props.pillActive?.length > 0 ? "#FF5E03" : "transparent"};
`;

export const LocationInputWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 8px;
  background-color: #fff;
  padding: 14px;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 5px;

  @media only screen and (max-width: 454px) {
    padding: 10px;
  }

  & .autocomplete-wrap {
    width: 100%;
    border: none;
  }

  & input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    width: 70%;
    height: 100%;
    margin: 0px 5px;

    @media only screen and (max-width: 454px) {
      font-size: 12px;
    }
  }

  & input::placeholder {
    color: #8f8e8e;
    font-size: 15px;

    @media only screen and (max-width: 454px) {
      font-size: 11px;
    }
  }

  & .state-wrap {
    color: #000;
    border: none;
    background-color: transparent;
    font-size: 13px;
  }

  & .PhoneInputCountry {
    padding: 10px 9px !important;
    border-radius: 6px;
    height: 0px;

    .PhoneInputCountrySelect {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .PhoneInputCountrySelectArrow {
      color: #000;
      display: none;
    }

    .PhoneInputCountryIconImg {
      width: 16px;
      margin-left: 3px;
    }
  }
`;

export const InputIcons = styled.img`
  width: ${(props: any) => props.iconWidth};

  @media only screen and (max-width: 454px) {
    width: 25px;
  }
`;

export const InputBtn = styled.div`
  display: flex;
  gap: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 15px;
  border-radius: 8px;
  background-color: #000;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

  & p {
    color: #fff;
    margin: 0;
    font-size: 12px;
  }
`;

export const ArrowDownWrap = styled.div`
  background-color: #ffd58c;
  border-radius: 4px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
`;

export const YellowBgArrowWrap = styled(ArrowDownWrap)`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

export const DestinationOverall = styled.div`
  display: ${(props: any) => props.display};
`;

export const SpecialReqBtn = styled(SaveBtn)`
  padding: 15px;

  button {
    background-color: #ffa100 !important;
    color: #000 !important;
    font-weight: 700;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    outline: none !important;
    border: none !important;
  }
`;
export const SearchResult = styled.div`
  min-height: 200px;
  background: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: ${({ isVisible }) => (isVisible ? "200px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
`;
