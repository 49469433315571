import React, { useEffect, useMemo, useRef, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {
  ExtrasInputWrap,
  FilterBox,
  FilterVerifiedWrap,
  FilterWrap,
  HeaderFilterPill,
  HeaderImgWrap,
  HeaderInput,
  HeaderPillWrap,
  NoResultScreen,
  NoResultText,
  SearchAssetOverall,
  SearchHeaderSection,
  SearchHeaderWrap,
} from "./searchpage.styles";

import location from "../../../assets/images/svg/headerlocation-icon.svg";
import searchIcon from "../../../assets/images/svg/header-search-icon.svg";
import userImg from "../../../assets/images/svg/header-search-user.svg";
// import filterIcon from "../../../assets/images/svg/grey-filter.svg";
import filterIcon from "../../../assets/images/png/filterPng.png";

import {
  AssetOverall,
  FilterVerifiedHeader,
  PillWrapper,
  RadioOverall,
  RadioBtn,
} from "../Homepage/homepage.styles";
import ToggleBar from "../../../components/toggleBar";
import AssetCard from "../../../components/assetCard";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetAllVehicleListQuery,
  useGetSearchVehicleListQuery,
} from "../../../services/customerApi";
import FilterModal from "../../../components/filterBoxModal";
import {
  AppDispatch,
  RootState,
  useAppSelector,
  useAppDispatch,
} from "../../../store";
import { setShowFilter } from "../../../slices/showFilter";
import LittleLoader from "../../../components/littleLoader";
import Loader from "../../../components/loader";
import SearchLocationInputModal from "../../../components/searchModal";
import PaginationComponent from "../../../components/paginationComponent";
import { setPaginationNum } from "../../../slices/pagination";
import { setSearchData } from "../../../slices/showCarOption";

const SearchPage = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { currentLocation } = useAppSelector<any>(
    (store: RootState) => store?.currentLocation
  );
  const searchDetail = useAppSelector<any>(
    (store: RootState) => store?.showCarOption
  );
  const [searchParams] = useSearchParams();
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [locationModal, setLocationModal] = useState(false);
  const [searchValue, setSearchValue] = useState<any>(searchDetail?.searchData);
  const value: any = searchValue || searchParams.get("inputValue");
  const [searchLocation, setSearchLocation] = useState(
    searchParams.get("vehicleLocation")
  );
  // const vehicleLocation = searchLocation || searchParams.get("vehicleLocation");
  const [filterPill, setFilterPill] = useState<any[]>([]);
  const [findCar, setFindCar] = useState<any>([]);
  const [minYear, setMinYear] = useState<any>();
  const [maxYear, setMaxYear] = useState<any>();
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [vehicleLocationText, setVehicleLocationText] = useState("");
  const [vehicleName, setVehicleName] = useState<string>("");
  const [vehicleLocation, setVehicleLocation] = useState<string>("");
  const [vehicleBrand, setVehicleBrand] = useState<string>("");
  const [vehicleTypeText, setVehicleTypeText] = useState<any[]>([]);
  const [periodType, setPeriodType] = useState("");
  const [pickupLatLong, setPickupLatLong] = useState<any>();
  const [nearbyVehicleArr, setNearbyVehicleArr] = useState<any[]>([]);
  const [city, setCity] = useState("");
  const [fuelType, setFuelType] = useState("");

  const searchListRef: any = useRef();
  let [vehicles, setVehicles] = useState<any>();
  let [filteredVehicles, setFilteredVehicles] = useState<any>();

  const { data, isLoading, refetch, isSuccess } = useGetAllVehicleListQuery();
  useEffect(() => {
    // if (isSuccess) {
    // setSearchResult(data?.data);
    setVehicles(data?.data);
    setSearchResult(data?.data);
    setFilteredVehicles(
      data?.data?.filter((data: any) => {
        return data?.vehicle_name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
    );

    // }

    // Did this when there's nothing in the searchValue state it'll add a space string in it so as to enable the search endpoint to work
    // if (searchValue == "" || !searchValue) {
    //   setSearchValue(" ");
    // }
  }, [isSuccess, data, searchValue]);

  // const { data, isLoading, refetch, isSuccess } = useGetSearchVehicleListQuery(
  //   { vehicleLocation, value },
  //   { refetchOnMountOrArgChange: true }
  // );

  const { showFilter } = useAppSelector<any>(
    (store: RootState) => store?.showFilter
  );
  const { paginationNum } = useAppSelector<any>(
    (store: RootState) => store?.setPaginationNum
  );

  const filterModalControl = () => {
    dispatch(setShowFilter(true));
  };

  const handleFilterPill = (filterValue: any) => {
    if (vehicleTypeText?.includes(filterValue)) {
      const updateArr = vehicleTypeText?.filter(
        (item: any) => item !== filterValue
      );
      setVehicleTypeText(updateArr);
    } else {
      setFilterPill([...filterPill, filterValue]);
      setVehicleTypeText([...vehicleTypeText, filterValue]);
    }
  };

  useEffect(() => {
    if (searchValue !== null) {
      refetch();
    }
  }, [searchValue]);

  useEffect(() => {
    setFilteredVehicles(
      vehicles?.filter((data) => {
        return data?.vehicle_name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
    );
    setSearchResult(
      vehicles?.filter((data) => {
        return data?.vehicle_name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
    );
  }, [vehicles]);

  useEffect(() => {
    if (searchLocation) {
      setCity(searchLocation);
    }
  }, [searchLocation]);

  const carTypes = [
    { vehicleName: "Luxury Car" },
    { vehicleName: "SUV" },
    { vehicleName: "Saloon Car" },
    { vehicleName: "Sport Car" },
    { vehicleName: "Vintage Car" },
    { vehicleName: "Flat Bird" },
    { vehicleName: "Mini Bus" },
    { vehicleName: "Coaster Bus" },
    { vehicleName: "School Bus" },
    { vehicleName: "Luxury Bus" },
    { vehicleName: "Van " },
    { vehicleName: "Truck" },
    { vehicleName: "Trailers" },
    { vehicleName: "Pick-up Truck" },
    { vehicleName: "Caterpillers" },
    { vehicleName: "Power Bike " },
    { vehicleName: "Delivery Bikes" },
    { vehicleName: "Boats" },
    { vehicleName: "Helicopters" },
    { vehicleName: "Jets" },
    { vehicleName: "Emergency " },
  ];

  // FILTER THROUGH TO SORT OBJECTS ACCORDING TO DISTANCE
  function getObjectsByDistance(objects: any, address: any) {
    const R = 6371; // Radius of the earth in km
    const { latitude: lat1, longitude: lon1 } = address;

    // Calculate distances for each object
    const objectsWithDistances = objects.map((obj: any) => {
      const { latitude: lat2, longitude: lon2 } = obj;
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in km
      return { ...obj, distance };
    });

    // Sort objects based on distance in ascending order
    const sortedObjects = objectsWithDistances.sort(
      (a: any, b: any) => a.distance - b.distance
    );

    return sortedObjects;
  }

  function toRad(value: any) {
    return (value * Math.PI) / 180; // Convert degrees to radians
  }

  useEffect(() => {
    if (pickupLatLong) {
      const objects = searchResult;
      const address = {
        latitude: Number(pickupLatLong?.lat),
        longitude: Number(pickupLatLong?.lng),
      };
      const nearbyObjects = getObjectsByDistance(objects, address);
      // setNearbyVehicleArr(nearbyObjects);
    }
  }, [pickupLatLong]);

  useEffect(() => {
    let filtered: any = vehicles;
    if (vehicleBrand) {
      filtered = vehicles?.filter((item: any) => {
        return (
          item?.brand_name
            .toLowerCase()
            ?.includes(vehicleBrand?.toLowerCase()) ||
          item?.vehicle_name
            .toLowerCase()
            ?.includes(vehicleBrand?.toLowerCase())
        );
      });
    }
    if (minYear && maxYear) {
      filtered = filtered?.filter(
        (item: any) =>
          Number(item?.year) >= Number(minYear) &&
          Number(item?.year) <= Number(maxYear)
      );
    }
    if (minPrice && maxPrice) {
      filtered = filtered.filter((item: any) => {
        const price = Number(item.final_discounted_price);
        return price >= minPrice && price <= maxPrice;
      });
    }

    if (vehicleLocation) {
      filtered = filtered?.filter((item: any) =>
        item?.vehicle_location
          .toLowerCase()
          ?.includes(vehicleLocation?.toLowerCase())
      );
    }
    // if (vehicleName) {
    //   filtered = filtered?.filter((item: any) =>
    //     item?.vehicle_name.toLowerCase()?.includes(vehicleName?.toLowerCase())
    //   );
    // }

    if (vehicleTypeText && vehicleTypeText?.length > 0) {
      filtered = filtered?.filter((item: any) =>
        vehicleTypeText.some((name) =>
          item?.vehicle_type.toLowerCase()?.includes(name.toLowerCase())
        )
      );
    }

    if (periodType) {
      filtered = filtered?.filter((item: any) =>
        item?.service_price?.some((item: any) =>
          item?.service_title?.includes(periodType)
        )
      );
    }

    if (fuelType) {
      filtered = filtered?.filter(
        (item: any) => item?.fuel_type.toLowerCase() === fuelType?.toLowerCase()
      );
    }

    setFilteredVehicles(filtered);
  }, [
    vehicleLocationText,
    vehicleLocation,
    // searchResult,
    fuelType,
    minPrice,
    minYear,
    maxPrice,
    maxYear,
    vehicleName,
    vehicleBrand,
    vehicleTypeText,
    periodType,
    nearbyVehicleArr,
    pickupLatLong,
    // searchValue,
    // data,
    // value,
  ]);

  const addMaxYear = () => {
    setMaxYear(maxYear + 1);
  };

  const subtractMaxYear = () => {
    if (maxYear > 1700) {
      setMaxYear(maxYear - 1);
    }
  };

  const addMinYear = () => {
    setMinYear(minYear + 1);
  };

  const subtractMinYear = () => {
    if (minYear > 1700) {
      setMinYear(minYear - 1);
    }
  };

  const handleClearFilter = () => {
    setMinYear(1700);
    setMaxYear(2030);
    setMinPrice(0);
    setMaxPrice(0);
    // setVehicleLocationText("");
    setVehicleLocation("");
    setVehicleName("");
    setVehicleTypeText([]);
    setFilteredVehicles(vehicles);
    setSearchValue("");
    dispatch(setSearchData(""));
    setFuelType("");
  };
  let PageSize = 12;

  // const currentTableData = () => {
  //   const firstPageIndex = (paginationNum - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;

  //   return filteredVehicles?.slice(firstPageIndex, lastPageIndex);
  // };

  // useEffect(() => {
  //   if (!currentTableData?.length) {
  //     dispatch(setPaginationNum(1));
  //   }
  // }, [currentTableData]);

  const goToListTop: any = () => {
    window.scrollTo({
      top: searchListRef?.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <FilterModal
        isHomepage={false}
        modalFilter={showFilter}
        minYear={minYear}
        maxYear={maxYear}
        setMinYear={setMinYear}
        setMaxYear={setMaxYear}
        addMaxYear={addMaxYear}
        addMinYear={addMinYear}
        subtractMaxYear={subtractMaxYear}
        subtractMinYear={subtractMinYear}
        minPrice={minPrice}
        maxPrice={maxPrice}
        setMinPrice={setMinPrice}
        setMaxPrice={setMaxPrice}
        vehicleLocation={vehicleLocationText}
        vehicleName={vehicleName}
        setVehicleName={setVehicleName}
        setVehicleLocation={setVehicleLocation}
        setVehicleBrand={setVehicleBrand}
        vehicleType={vehicleTypeText}
        setVehicleType={setVehicleTypeText}
        clearFilter={handleClearFilter}
        handleFilterPill={handleFilterPill}
        setPickupLatLong={setPickupLatLong}
        setCity={setCity}
        setPeriodType={setPeriodType}
        periodType={periodType}
        fuelType={fuelType}
        setFuelType={setFuelType}
        resultsNo={filteredVehicles?.length}
      />

      <SearchLocationInputModal
        location={city || currentLocation?.city}
        setLocation={setSearchLocation}
        showModal={locationModal}
        setShowModal={setLocationModal}
      />

      <Header />

      <FilterVerifiedWrap>
        <FilterVerifiedHeader>
          Go Pro, see only verified vehicles
          <ToggleBar />
        </FilterVerifiedHeader>
      </FilterVerifiedWrap>

      <SearchHeaderSection>
        <SearchHeaderWrap>
          <HeaderImgWrap>
            <img src={userImg} />
          </HeaderImgWrap>
          <HeaderInput
            placeholder="What Vehicle? | Model? "
            onChange={(e: any) => {
              // let search = value.toLowerCase();
              setSearchValue(e.target.value.toLowerCase());
              dispatch(setSearchData(e.target.value.toLowerCase()));
              // if (value.length > 2) {
              setFilteredVehicles(
                vehicles?.filter((data) => {
                  return data?.vehicle_name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
                })
              );
              //   } else {
              //     setFilteredVehicles(vehicles);
              // console.log("vitered filteredVehicles", vehicles);

              //   }
            }}
            value={searchValue}
          />
          <ExtrasInputWrap onClick={() => setLocationModal(true)}>
            <div className="icon-description">Where?</div>
            <img className="location" src={location} />
            <p>{city || currentLocation?.city}</p>
          </ExtrasInputWrap>

          <ExtrasInputWrap onClick={filterModalControl}>
            <div className="icon-description">Filter</div>
            <img src={filterIcon} height={25} width={40} />
          </ExtrasInputWrap>
        </SearchHeaderWrap>
      </SearchHeaderSection>

      <FilterWrap>
        <HeaderPillWrap>
          {carTypes?.map((item: any, id: any) => {
            return (
              <HeaderFilterPill
                active={
                  vehicleTypeText?.includes(item?.vehicleName) ? true : false
                }
                key={id}
                onClick={() => handleFilterPill(item?.vehicleName)}
              >
                {item?.vehicleName}
              </HeaderFilterPill>
            );
          })}
        </HeaderPillWrap>
      </FilterWrap>

      {isLoading ? (
        <Loader></Loader>
      ) : searchResult?.length === 0 ? (
        <NoResultScreen>
          <NoResultText>Nothing to show here</NoResultText>
        </NoResultScreen>
      ) : (
        <>
          <SearchAssetOverall ref={searchListRef}>
            {filteredVehicles?.map((item: any, id: any) => {
              return (
                <AssetCard
                  firstPrice={item?.final_normal_price}
                  discountPrice={item?.final_discounted_price}
                  discount={item?.discount}
                  name={item?.vehicle_name}
                  locate={item?.vehicle_location || item?.state}
                  vehicleImg={item?.image1}
                  vehicleId={item?.id}
                  key={id}
                  year={item?.year}
                  passengerNum={item?.num_passenger}
                  rating={item?.rating}
                  likes={item?.likes}
                />
              );
            })}
          </SearchAssetOverall>

          {/* <PaginationComponent
            currentPage={1}
            totalCount={filteredVehicles?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => dispatch(setPaginationNum(page))}
            goToListTop={() => goToListTop()}
          /> */}
        </>
      )}

      <Footer />
    </>
  );
};

export default SearchPage;
