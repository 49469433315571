import React from 'react'
import { CheckoutPayWrapper } from '../checkoutPayComponent/checkoutPay.styles'
import { CheckoutGreyWrap } from '../checkoutPayErrorComponent/checkoutPayError.styles'
import { FundWalletOption, PillRadioWrap, CardTxt, OptionsImgBox } from '../fundWalletOne/fundwalletone.styles'
import { PillRadioBtn } from '../optionModal/optionmodal.styles'
import { TextHeaderWrap } from './checkoutIntial.styles'

import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import wallet from '../../assets/images/svg/wallet-pay.svg'

const CheckoutInitialPay = ({showBox, onSelect}: any) => {
  return (
    <CheckoutPayWrapper display={showBox}>
        <TextHeaderWrap>
            I would like to pay with my
        </TextHeaderWrap>

        <CheckoutGreyWrap>

            <FundWalletOption onClick={() => {onSelect('wallet')}}>
                <PillRadioWrap >
                    <PillRadioBtn activePill={''} />

                    <CardTxt>
                        My Wallet
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'#78C19373'}>
                    <img src={wallet} />
                </OptionsImgBox>

            </FundWalletOption>

            <FundWalletOption onClick={() => {onSelect('card')}}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={''} />

                    <CardTxt>
                        My Card
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'#FFE8B6'}>
                    <img src={master} />

                    <img src={visa} />
                </OptionsImgBox>
            </FundWalletOption>
        </CheckoutGreyWrap>
    </CheckoutPayWrapper>
  )
}

export default CheckoutInitialPay