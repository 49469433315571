import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'


import { Page } from './registration.styles';
import FirstScreen from '../../../components/providerAccountReg/firstScreen/firstScreen'
import SecondScreen from '../../../components/providerAccountReg/secondScreen/secondScreen'
import Header from '../../../components/providerHeader';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';
import { useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';

const Registration: FC = () => {
    const [showFirstScreen, setShowFirstScreen] = useState<boolean>(true);
    const [providerDetails, setProviderDetails] = useState({
        account_type: '',
        service_offering: ''
    });

    const userRole = useAppSelector((state: any) => state?.auth?.user_role)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

   
    return (
        <>
            <Header />

           
            <Page>
            <PagePadding className='padding'>

                    {showFirstScreen ?
                        <FirstScreen
                            setShowFirstScreen={setShowFirstScreen}
                            showFirstScreen={showFirstScreen}
                            setProviderDetails={setProviderDetails}
                            providerDetails={providerDetails}
                        /> :
                        <SecondScreen
                            setProviderDetails={setProviderDetails}
                            providerDetails={providerDetails}
                            setShowFirstScreen={setShowFirstScreen}

                        />
                    }
                </PagePadding>
            </Page>

        </>
    )
}

export default Registration