import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  RANGEDIV,
  UploadButton,
  SPAN,
  Container,
  DisabledButton,
  Button,
  Page,
  DIV,
  H44,
  ButtonDiv,
} from "./carRegistration.styles";

import VehicleDoc from "../../../components/providerCarReg/vehicleDoc/vehicleDoc";
import whiteMark from "../../../assets/images/png/whiteMark.png";
import FirstScreen from "../../../components/providerCarReg/firstScreen/firstScreen";
import SecondScreen from "../../../components/providerCarReg/secondScreen/secondScreen";
import ThirdScreen from "../../../components/providerCarReg/thirdScreen/thirdScreen";
import FourthScreen from "../../../components/providerCarReg/fourthScreen/fourthScreen";
import FifthScreen from "../../../components/providerCarReg/fifthScreen/fifthScreen";
import SixthScreen from "../../../components/providerCarReg/sixthScreen/sixthScreen";
import SeventhScreen from "../../../components/providerCarReg/seventhScreen/seventhScreen";

import { Navigate, useNavigate } from "react-router-dom";
import CarImageComponent from "../../../components/providerCarReg/carImageComponent";
import {
  baseUrl,
  useCarRegistrationMutation,
} from "../../../services/providerApi";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  clearVehicleDetails,
  setRegisteredVehicle,
  setServiceOffering,
  setVehicleDetailss,
} from "../../../slices/provider";
import { PopupModal } from "./Popup";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";
import BikeThirdScreen from "../../../components/providerBikeReg/bikeThirdScreen/thirdScreen";

const CarRegistration: FC<{ dash: boolean }> = ({ dash }) => {
  const dispatch: any = useAppDispatch();

  const navigate = useNavigate();
  const [value, setValue] = useState();
  const refDiv = React.useRef<HTMLInputElement>(null);
  const [btnLoad, setBtnLoad] = useState(false);
  const [scroll, setScroll] = useState(false);

  const [width, setWidth] = useState(0);
  let [question, setQuestion] = useState(1);
  let [component, setComponent] = useState(1);
  let [bikeSelection, setBikeSelection] = useState(0);
  const docRef: any = useRef();
  const imageRef: any = useRef();
  const [allQuestion, setAllQuestion] = useState(7);
  const [bikeQuestion, setBikeQuestion] = useState(6);

  const lastVehicleDetails: any = useAppSelector(
    (state: any) => state?.provider.vehicle
  );

  const vehicleInfo = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails
  );
  const fullDayServiceVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.fullDayServiceVisible
  );
  const halfDayServiceVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.halfDayServiceVisible
  );
  const hourlyRateVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.hourlyRateVisible
  );
  const airportVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.airportVisible
  );
  const travelVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.travelVisible
  );
  const halfMonthVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.halfMonthVisible
  );
  const fullMonthVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.fullMonthVisible
  );

  const user = useAppSelector((state: any) => state?.auth?.auth);

  const [vehicleDetails, setVehicleDetails] = useState({
    title: vehicleInfo?.vehicle_type.includes("Bike")
      ? "Bike Registration"
      : "Vehicle Registration",
    user_id: user?.id,
    charge: 15,
    vehicle_for: vehicleInfo?.vehicle_for || "",
    vehicle_name: vehicleInfo?.vehicle_name || "",
    year: vehicleInfo?.year || 2000,
    brand_name: vehicleInfo?.brand_name || "",
    plate_number: vehicleInfo?.plate_number || "",
    first_name: vehicleInfo?.first_name || "",
    phone_number: vehicleInfo?.phone_number || "",
    last_name: vehicleInfo?.last_name || "",
    num_passengers: vehicleInfo?.num_passengers || "",
    vehicle_type: vehicleInfo?.vehicle_type || "",
    longitude: vehicleInfo?.longitude || "",
    latitude: vehicleInfo?.latitude || "",
    air_con: vehicleInfo?.air_con || "",
    come_with_driver: vehicleInfo?.come_with_driver || "",
    vehicle_tracker: vehicleInfo?.vehicle_tracker || "",
    fuel_type: vehicleInfo?.fuel_type || "",
    night_trips: vehicleInfo?.night_trips || "",
    self_drive: vehicleInfo?.self_drive || "",
    travel_carpool: vehicleInfo?.travel_carpool || "",
    vehicle_location: vehicleInfo?.vehicle_location || "",
    file: vehicleInfo?.file || "",
    file2: vehicleInfo?.file2 || "",
    file3: vehicleInfo?.file3 || "",
    file5: vehicleInfo?.file5 || "",
    travel_trip: vehicleInfo?.travel_trip || [],
    service_price: [
      {
        price: 0,
        hour: 12,
        discount: 0,
        service_title: "Full Day Service",
      },
      {
        price: 0,
        hour: 6,
        discount: 0,
        service_title: "Half Day Service",
      },
      {
        price: 0,
        hour: 1,
        discount: 0,
        service_title: "Hourly Rate",
      },

      {
        price: 0,
        discount: 0,
        service_title: "Airport Pickup",
      },
      {
        price: 0,
        hour: 1,
        discount: 0,
        service_title: "Full Month Service",
      },
      {
        price: 0,
        hour: 1,
        discount: 0,
        service_title: "Half Month Service",
      },
    ],
    availability: vehicleInfo?.availability || [],
  });

  let servicePrice = vehicleDetails?.service_price;
  let travelServicePrice = vehicleDetails?.travel_trip;

  // states to check if the service prices are filled or not
  let [fullDayPopUp, setFullDayPopUp] = useState(false);
  let [halfDayPopUp, setHalfDayPopUp] = useState(false);
  let [hourlyPopUp, setHourlyPopUp] = useState(false);
  let [airportPopUp, setAirportPopUp] = useState(false);
  let [travelPopUp, setTravelPopUp] = useState(false);
  let [halfMonthPopUp, setHalfMonthPopUp] = useState(false);
  let [fullMonthPopUp, setFullMonthPopUp] = useState(false);

  let isFullDayService = servicePrice?.filter((price: any) => {
    return price?.service_title === "Full Day Service";
  });
  let isHalfDayService = servicePrice?.filter((price: any) => {
    return price?.service_title === "Half Day Service";
  });

  let isHourlyService = servicePrice?.filter((price: any) => {
    return price?.service_title === "Hourly Rate";
  });
  let isAirportService = servicePrice?.filter((price: any) => {
    return price?.service_title === "Airport Pickup";
  });
  let isHalfMonthService = servicePrice?.filter((price: any) => {
    return price?.service_title === "Half Month Service";
  });
  let isFullMonthService = servicePrice?.filter((price: any) => {
    return price?.service_title === "Full Month Service";
  });

  const handleNextComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (component === 1) {
      if (vehicleDetails?.vehicle_for === "") {
        setComponent(0);
        notifyError("What is your vehicle for?");
      }
    }
    if (component === 2) {
      if (vehicleDetails?.vehicle_name === "") {
        setComponent(1);
        notifyError("Fill in your Vehicle Name");
      } else if (vehicleDetails?.brand_name === "") {
        setComponent(1);
        notifyError("Fill in your Vehicle Brand Name ");
      } else if (vehicleDetails?.plate_number === "") {
        setComponent(1);
        notifyError("Fill in your Plate Number");
      }
    }

    console.log(vehicleDetails);
    if (component === 3) {
      if (
        vehicleDetails?.num_passengers === "" ||
        vehicleDetails?.vehicle_type === "" ||
        vehicleDetails?.vehicle_location === ""
      ) {
        setComponent(2);
        notifyError(
          "Fill in the Number of Passengers seat, Category and Vehicle Address"
        );
      }
    }
    if (component === 4) {
      if (
        vehicleDetails?.first_name === "" &&
        vehicleDetails?.vehicle_type.includes("Bike")
      ) {
        setComponent(3);
        notifyError("Fill in your first name");
      }
      if (
        vehicleDetails?.last_name === "" &&
        vehicleDetails?.vehicle_type.includes("Bike")
      ) {
        setComponent(3);
        notifyError("Fill in your last name");
      }
      if (
        vehicleDetails?.phone_number.length < 10 &&
        vehicleDetails?.vehicle_type.includes("Bike")
      ) {
        setComponent(3);
        notifyError("Fill in your correct phone number");
      }
      if (isFullDayService[0]?.price === 0 && fullDayServiceVisible === true) {
        if (!vehicleDetails?.vehicle_type.includes("Bike")) {
          setFullDayPopUp(true);
        } else {
          setFullDayPopUp(false);
        }
      } else if (
        isHalfDayService[0]?.price === 0 &&
        halfDayServiceVisible === true
      ) {
        if (!vehicleDetails?.vehicle_type.includes("Bike")) {
          setHalfDayPopUp(true);
        } else {
          setHalfDayPopUp(false);
        }
      } else if (
        isHourlyService[0]?.price === 0 &&
        hourlyRateVisible === true
      ) {
        setHourlyPopUp(true);
      }
    }
    if (component === 5) {
      if (isFullDayService[0]?.price === 0 && fullDayServiceVisible === true) {
        if (vehicleDetails?.vehicle_type.includes("Bike")) {
          setFullDayPopUp(true);
        } else {
          setFullDayPopUp(false);
        }
      } else if (
        isHalfDayService[0]?.price === 0 &&
        halfDayServiceVisible === true
      ) {
        // setHalfDayPopUp(true)
        if (vehicleDetails?.vehicle_type.includes("Bike")) {
          setHalfDayPopUp(true);
        } else {
          setHalfDayPopUp(false);
        }
      } else if (isAirportService[0]?.price === 0 && airportVisible === true) {
        setAirportPopUp(true);
      } else if (travelServicePrice[0]?.price === 0 && travelVisible === true) {
        setTravelPopUp(true);
      }
    }
    if (component === 6) {
      if (isHalfMonthService[0]?.price === 0 && halfMonthVisible === true) {
        setHalfMonthPopUp(true);
      } else if (
        isFullMonthService[0]?.price === 0 &&
        fullMonthVisible === true
      ) {
        setFullMonthPopUp(true);
      }
    }
    setComponent((prev) => prev + 1);
  };
  const handlePrevComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setComponent((prev) => prev - 1);
    setQuestion((prev) => prev - 1);
  };
  const [addVehicle, { data, isLoading, isSuccess, isError, error }] =
    useCarRegistrationMutation();

  if (scroll) {
    setTimeout(() => {
      docRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start", // or 'end'
      });
    }, 0);
  }

  useEffect(() => {
    dispatch(setServiceOffering("Vehicle"));
  }, []);

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);
      notifySuccess(
        "Vehicle Image Upload Unlocked, Now upload your Vehicle Images"
      );
      dispatch(setRegisteredVehicle(data?.data));
    }
    if (isError && "status" in error!) {
      if (error?.data?.message === "Authentication required") {
        navigate("/login");
      }
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handleUpload = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      vehicleDetails?.air_con === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("Does Your Vehicle have working Air Conditioning?");
    } else if (
      vehicleDetails?.come_with_driver === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("Does Your Vehicle Come with a driver?");
    } else if (
      vehicleDetails?.vehicle_tracker === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("Does Your Vehicle Come with a Vehicle Tracker?");
    } else if (
      vehicleDetails?.night_trips === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("Is Your Vehicle available for Night Trips?");
    } else if (
      vehicleDetails?.self_drive === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("Is Your Vehicle available for Self Drive?");
    } else if (
      vehicleDetails?.travel_carpool === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("Is your Vehicle Available for travel?");
    } else if (
      vehicleDetails?.fuel_type === "" &&
      !vehicleDetails?.vehicle_type.includes("Bike")
    ) {
      notifyError("What is your vehicle Fuel Type?");
    } else if (isFullMonthService?.length === 0 && fullMonthVisible === true) {
      if (vehicleDetails?.vehicle_type.includes("Bike")) {
        setFullMonthPopUp(true);
      } else {
        setFullMonthPopUp(false);
      }
    } else if (isHalfMonthService?.length === 0 && halfMonthVisible === true) {
      if (vehicleDetails?.vehicle_type.includes("Bike")) {
        setHalfMonthPopUp(true);
      } else {
        setHalfMonthPopUp(false);
      }
    } else {
      //@ts-ignore
      addVehicle([vehicleDetails])
        .then((response: any) => {
          imageRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start", // or 'end'
          });
          if (response.error.data.message) {
            notifyError(response.error.data.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const handleGotoCarComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setComponent(3);
    // vehicleDetails.vehicle_type = 'Category'
    setVehicleDetails({
      ...vehicleDetails,
      vehicle_type: "Category",
    });
  };

  const address = vehicleDetails?.vehicle_location;
  const geocoder = new window.google.maps.Geocoder();

  useEffect(() => {
    geocoder.geocode({ address }, (result: any, status) => {
      if (status === "OK") {
        setVehicleDetails({
          ...vehicleDetails,
          latitude: result[0]?.geometry.location.lat(),
          longitude: result[0]?.geometry.location.lng(),
        });
        dispatch(
          setVehicleDetailss({
            ...vehicleDetails,
            latitude: result[0]?.geometry.location.lat(),
          })
        );
        dispatch(
          setVehicleDetailss({
            ...vehicleDetails,
            longitude: result[0]?.geometry.location.lng(),
          })
        );
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

    //@ts-ignore
  }, [address]);

  return (
    <>
      <ToastContainer />
      <Page
        style={dash === true ? { padding: ".5em" } : { padding: "1em 2em" }}
      >
        {/* <PagePadding style={dash === true ? { padding: '0' } : { padding: '0em 18em' }}> */}
        <PagePadding className={dash === true ? "" : "padding"}>
          {fullDayPopUp && (
            <PopupModal
              vehicleDetails={vehicleDetails}
              // halfDayPopUp={halfDayPopUp}
              fullDayPopUp={fullDayPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              isFullDayService={isFullDayService}
              isHalfDayService={isHalfDayService}
              isHourlyService={isHourlyService}
              service="Full Day Service"
              setComponent={setComponent}
              setServicePopUp={setFullDayPopUp}
              hourlyPopUp={hourlyPopUp}
              setHourlyPopUp={setHourlyPopUp}
              airportPopUp={airportPopUp}
              setAirportPopUp={setAirportPopUp}
              isAirportService={isAirportService}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
            />
          )}

          {halfDayPopUp && (
            <PopupModal
              vehicleDetails={vehicleDetails}
              halfDayPopUp={halfDayPopUp}
              // fullDayPopUp={fullDayPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              isHalfDayService={isHalfDayService}
              isFullDayService={isFullDayService}
              isHourlyService={isHourlyService}
              service="Half Day Service"
              setComponent={setComponent}
              setServicePopUp={setHalfDayPopUp}
              hourlyPopUp={hourlyPopUp}
              setHourlyPopUp={setHourlyPopUp}
              airportPopUp={airportPopUp}
              setAirportPopUp={setAirportPopUp}
              isAirportService={isAirportService}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
            />
          )}
          {hourlyPopUp && (
            <PopupModal
              airportPopUp={airportPopUp}
              halfDayPopUp={halfDayPopUp}
              fullDayPopUp={fullDayPopUp}
              hourlyPopUp={hourlyPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              setHourlyPopUp={setHourlyPopUp}
              setAirportPopUp={setAirportPopUp}
              isHourlyService={isHourlyService}
              isFullDayService={isFullDayService}
              isHalfDayService={isHalfDayService}
              service="Hourly Rate"
              setComponent={setComponent}
              setServicePopUp={setHourlyPopUp}
              isAirportService={isAirportService}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
            />
          )}

          {airportPopUp && (
            <PopupModal
              airportPopUp={airportPopUp}
              halfDayPopUp={halfDayPopUp}
              fullDayPopUp={fullDayPopUp}
              hourlyPopUp={hourlyPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              setHourlyPopUp={setHourlyPopUp}
              setAirportPopUp={setAirportPopUp}
              isHourlyService={isHourlyService}
              isFullDayService={isFullDayService}
              isHalfDayService={isHalfDayService}
              isAirportService={isAirportService}
              service="Airport Pickup"
              setComponent={setComponent}
              setServicePopUp={setAirportPopUp}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
            />
          )}

          {travelPopUp && (
            <PopupModal
              airportPopUp={airportPopUp}
              halfDayPopUp={halfDayPopUp}
              fullDayPopUp={fullDayPopUp}
              hourlyPopUp={hourlyPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              setHourlyPopUp={setHourlyPopUp}
              setAirportPopUp={setAirportPopUp}
              isHourlyService={isHourlyService}
              isFullDayService={isFullDayService}
              isHalfDayService={isHalfDayService}
              isAirportService={isAirportService}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              service="Travel Service"
              setComponent={setComponent}
              setServicePopUp={setTravelPopUp}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
            />
          )}

          {halfMonthPopUp && (
            <PopupModal
              airportPopUp={airportPopUp}
              halfDayPopUp={halfDayPopUp}
              fullDayPopUp={fullDayPopUp}
              hourlyPopUp={hourlyPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              setHourlyPopUp={setHourlyPopUp}
              setAirportPopUp={setAirportPopUp}
              isHourlyService={isHourlyService}
              isFullDayService={isFullDayService}
              isHalfDayService={isHalfDayService}
              isAirportService={isAirportService}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              service="Half Month Service"
              setComponent={setComponent}
              setServicePopUp={setTravelPopUp}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
            />
          )}

          {fullMonthPopUp && (
            <PopupModal
              airportPopUp={airportPopUp}
              halfDayPopUp={halfDayPopUp}
              fullDayPopUp={fullDayPopUp}
              hourlyPopUp={hourlyPopUp}
              fullMonthPopUp={fullMonthPopUp}
              halfMonthPopUp={halfMonthPopUp}
              setHalfDayPopUp={setHalfDayPopUp}
              setFullDayPopUp={setFullDayPopUp}
              setHourlyPopUp={setHourlyPopUp}
              setAirportPopUp={setAirportPopUp}
              setFullMonthPopUp={setFullMonthPopUp}
              setHalfMonthPopUp={setHalfMonthPopUp}
              isHourlyService={isHourlyService}
              isFullDayService={isFullDayService}
              isHalfDayService={isHalfDayService}
              isAirportService={isAirportService}
              isFullMonthService={isFullMonthService}
              isHalfMonthService={isHalfMonthService}
              travelPopUp={travelPopUp}
              setTravelPopUp={setTravelPopUp}
              travelServicePrice={travelServicePrice}
              service="Full Month Service"
              setComponent={setComponent}
              setServicePopUp={setTravelPopUp}
            />
          )}

          <>
            <div ref={imageRef}>
              <CarImageComponent
                setScroll={setScroll}
                component={component}
                vehicleDetails={vehicleDetails}
                setVehicleDetails={setVehicleDetails}
              />
            </div>

            {!vehicleDetails?.vehicle_type.includes("Bike") && (
              <H44 style={{ fontWeight: 700 }}>
                Upload Your Vehicle in{" "}
                <SPAN>
                  {" "}
                  {8 - component} {component === 7 ? "Move" : "Moves"}
                </SPAN>
              </H44>
            )}

            {vehicleDetails?.vehicle_type.includes("Bike") && (
              <H44 style={{ fontWeight: 700 }}>
                Upload Your Bike in{" "}
                <SPAN>
                  {" "}
                  {7 - component} {component === 7 ? "Move" : "Moves"}
                </SPAN>
              </H44>
            )}

            {!vehicleDetails?.vehicle_type.includes("Bike") && (
              <RANGEDIV
                style={
                  component > 3 && component < 7
                    ? { width: "89%" }
                    : { width: "100%" }
                }
              >
                <input
                  type="range"
                  min="1"
                  max={allQuestion}
                  value={question}
                  className="slider"
                  id="myRange"
                />
                <div id="background"></div>
                <div
                  id="progress"
                  ref={refDiv}
                  style={{ width: width + "%" }}
                ></div>
              </RANGEDIV>
            )}

            {vehicleDetails?.vehicle_type.includes("Bike") && (
              <RANGEDIV
                style={component > 3 ? { width: "89%" } : { width: "100%" }}
              >
                <input
                  type="range"
                  min="1"
                  max={allQuestion}
                  value={question}
                  className="deliverySlider"
                  id="myRange"
                />
                <div id="deliverybackground"></div>
                <div
                  id="deliveryprogress"
                  ref={refDiv}
                  style={{ width: width + "%" }}
                ></div>
              </RANGEDIV>
            )}

            {component === 1 && (
              <FirstScreen
                vehicleDetails={vehicleDetails}
                setVehicleDetails={setVehicleDetails}
                setWidth={setWidth}
              />
            )}
            {component === 2 && (
              <SecondScreen
                vehicleDetails={vehicleDetails}
                setVehicleDetails={setVehicleDetails}
                setWidth={setWidth}
                setQuestion={setQuestion}
              />
            )}
            {component === 3 && (
              <ThirdScreen
                vehicleDetails={vehicleDetails}
                bikeSelection={bikeSelection}
                setVehicleDetails={setVehicleDetails}
                setWidth={setWidth}
                setQuestion={setQuestion}
                setComponent={setComponent}
                setBikeSelection={setBikeSelection}
              />
            )}
            {component === 4 && (
              <>
                {vehicleDetails.vehicle_type.includes("Bike") ? (
                  <BikeThirdScreen
                    setWidth={setWidth}
                    setQuestion={setQuestion}
                    vehicleDetails={vehicleDetails}
                    setVehicleDetails={setVehicleDetails}
                  />
                ) : (
                  <FourthScreen
                    vehicleDetails={vehicleDetails}
                    setVehicleDetails={setVehicleDetails}
                    setWidth={setWidth}
                    setQuestion={setQuestion}
                  />
                )}
              </>
            )}

            {component === 5 && (
              <>
                {vehicleDetails.vehicle_type.includes("Bike") ? (
                  <FourthScreen
                    setWidth={setWidth}
                    setQuestion={setQuestion}
                    vehicleDetails={vehicleDetails}
                    setVehicleDetails={setVehicleDetails}
                  />
                ) : (
                  <FifthScreen
                    vehicleDetails={vehicleDetails}
                    setVehicleDetails={setVehicleDetails}
                    setWidth={setWidth}
                    setQuestion={setQuestion}
                  />
                )}
              </>
            )}

            {component === 6 && (
              <SixthScreen
                vehicleDetails={vehicleDetails}
                setVehicleDetails={setVehicleDetails}
                setWidth={setWidth}
                setQuestion={setQuestion}
              />
            )}
            {component === 7 && (
              <SeventhScreen
                setWidth={setWidth}
                setQuestion={setQuestion}
                vehicleDetails={vehicleDetails}
                setVehicleDetails={setVehicleDetails}
              />
            )}

            {!vehicleDetails.vehicle_type.includes("Bike") ? (
              <Container>
                {component === 7 ? (
                  btnLoad ? (
                    <DisabledButton>Please Wait...</DisabledButton>
                  ) : (
                    <>
                      {lastVehicleDetails?.length > 0 ? (
                        <DisabledButton>
                          <h4>Vehicle Uploaded</h4>
                        </DisabledButton>
                      ) : (
                        <UploadButton onClick={handleUpload}>
                          <h4>Upload Vehicle</h4>
                        </UploadButton>
                      )}
                    </>
                  )
                ) : (
                  <Button onClick={handleNextComponent}>
                    <h4>OK</h4>
                    <img src={whiteMark} alt="" width={28} height={23} />
                  </Button>
                )}
                <DIV
                  style={
                    component > 3 && component < 7
                      ? { paddingRight: "30px" }
                      : { paddingRight: "0px" }
                  }
                >
                  {component === 1 &&
                    vehicleDetails.vehicle_type === "Delivery" && (
                      <ButtonDiv
                        style={{ background: "transparent", border: "none" }}
                        onClick={handleGotoCarComponent}
                      >
                        <BsChevronLeft
                          style={{
                            color: "red",
                            fontSize: 27,
                            fontWeight: 400,
                          }}
                        />
                      </ButtonDiv>
                    )}

                  {component >= 2 && (
                    <ButtonDiv onClick={handlePrevComponent}>
                      <BsChevronLeft
                        style={{ fontSize: 27, fontWeight: 700 }}
                      />
                    </ButtonDiv>
                  )}

                  {component < 7 && (
                    <ButtonDiv
                      style={{ background: "transparent", border: "none" }}
                      onClick={handleNextComponent}
                    >
                      <BsChevronRight
                        style={{ fontSize: 27, fontWeight: 700 }}
                      />
                    </ButtonDiv>
                  )}
                </DIV>
              </Container>
            ) : (
              ""
            )}

            {vehicleDetails.vehicle_type.includes("Bike") ? (
              <Container>
                {component === 6 ? (
                  btnLoad ? (
                    <DisabledButton>Please Wait...</DisabledButton>
                  ) : (
                    <>
                      {lastVehicleDetails?.length > 0 ? (
                        <DisabledButton>
                          <h4>Vehicle Uploaded</h4>
                        </DisabledButton>
                      ) : (
                        <UploadButton onClick={handleUpload}>
                          <h4>Upload Vehicle</h4>
                        </UploadButton>
                      )}
                    </>
                  )
                ) : (
                  <Button onClick={handleNextComponent}>
                    <h4>OK</h4>
                    <img src={whiteMark} alt="" width={28} height={23} />
                  </Button>
                )}

                <DIV
                  style={
                    component > 3 && component < 7
                      ? { paddingRight: "30px" }
                      : { paddingRight: "0px" }
                  }
                >
                  {component === 1 &&
                    vehicleDetails.vehicle_type === "Delivery" && (
                      <ButtonDiv
                        style={{ background: "transparent", border: "none" }}
                        onClick={handleGotoCarComponent}
                      >
                        <BsChevronLeft
                          style={{
                            color: "red",
                            fontSize: 27,
                            fontWeight: 400,
                          }}
                        />
                      </ButtonDiv>
                    )}

                  {component >= 2 && (
                    <ButtonDiv
                      style={{ background: "transparent", border: "none" }}
                      onClick={handlePrevComponent}
                    >
                      <BsChevronLeft
                        style={{ fontSize: 27, fontWeight: 700 }}
                      />
                    </ButtonDiv>
                  )}
                  {component < 6 && (
                    <ButtonDiv
                      style={{ background: "transparent", border: "none" }}
                      onClick={handleNextComponent}
                    >
                      <BsChevronRight
                        style={{ fontSize: 27, fontWeight: 700 }}
                      />
                    </ButtonDiv>
                  )}
                </DIV>
              </Container>
            ) : (
              ""
            )}
          </>

          {scroll && (
            <div>
              <VehicleDoc />
            </div>
          )}

          <div style={{ marginTop: 140 }} ref={docRef}></div>
        </PagePadding>
      </Page>
    </>
  );
};
export default CarRegistration;
