import { ItemCardWrap, CardTextWrap, CardImgWrap, AbsoluteButton, CardContentWrap } from './../walletCardComponent/walletcard.styles';
import styled from 'styled-components'

export const CheckoutCardWrap = styled(ItemCardWrap) `
    height: 130px;
    min-height: unset;

    & .inner-item-card {
        padding: 5px 20px;
        position: relative;
        height: 100%;
    }

    @media only screen and (max-width: 454px) {
        height: 110px;
    }
`

export const CheckoutCardContentWrap = styled(CardContentWrap) `
    margin-top: 20px;
`

export const CheckoutCardTextWrap = styled(CardTextWrap) `
    margin-bottom: 40px;
    & p {
        margin-left: 0;
        font-size: 12px;
    }

    & h2 {
        margin: 0;
        font-size: 14px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 9px;
        }

        h2 {
            font-size: 10px;
        }
    }

`

export const CheckoutImgWrap = styled(CardImgWrap) `

    img {
        width: 150px;
    }

    @media only screen and (max-width: 454px) {
        img {
            width: 100px;
        }
    }
`

export const CheckoutAbsoluteButton = styled(AbsoluteButton) `
    & .inner-button {

        img {
            width: 20px;
        }

        p {
            font-size: 15px;
        }

        @media only screen and (max-width: 454px) {
            img {
                width: 12px;
            }

            p {
                font-size: 11px;
            }
        }
    }
`