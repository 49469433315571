import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: ${(props: any) => props.theme.body};

padding:2em;

`

export const DIV = styled.div`
display:flex;
align-items:center;
gap:15px;
height:120px;
`

export const IMAGEDIV = styled.div`
display:flex;
width:59%;
border:1px solid #eee;
border-radius:10px;
height:120px;
position:relative;
justify-content:center;
align-items:center;
overflow:hidden;

`
export const IMAGESDIV = styled.div`
display:flex;
align-items:center;
width:41%;
flex-wrap:wrap;
gap:10px;
border-radius:6px;
height:100%;
`
export const IMAGE1 = styled.div`
width:42%;
overflow:hidden;
border:1px solid #eee;
border-radius:10px;
height:40%;
background-repeat:no-repeat;
background-size:cover;
`

export const BUTTON = styled.div`
color:#FFA100;
background:#000;
position:absolute;
padding:.3em .7em;
border-radius:50%;
font-weight:600;
text-align:center;
top:10px;
right:10px;
font-size:14px;
`
export const SPAN = styled.span`
color:red;
`

export const H3 = styled.h2`
font-weight:500;
color:${(props: any) => props.theme.white};


`

export const DivTop = styled.div`
display:flex;
padding:10px;
align-items:center;
gap:20px;
margin-bottom:30px;
color:${(props: any) => props.theme.white};

`
export const H44 = styled.h4`
font-weight:500;
color:#ffa100;
padding-top:20px;
font-size:12.8px;

`

export const LoaderContainer = styled.div`
display:flex;
gap:10px;
margin-bottom:60px;
margin-top:0px;
height:3px;
width:70%;

`
export const DivContainer = styled.div`
display:flex;
background-color:#fff;
height:3px;
width:100%;
`

export const Container = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-top:60px;
`
export const Button = styled.div`
background: #000;
color: #fff;
padding:0px 18px;
border-radius:5px;
border:1px solid #ddd;
display:flex;
align-items:center;
height:50px;
`
export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:${(props: any) => props.theme.white};

width:50%;
border-radius:7px;
gap:5px;
padding:7px 10px;
justify-content:space-between;
position:relative;
`

export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:${(props: any) => props.theme.pureWhite};
width:100%;
justify-content:space-evenly;
border-radius:5px;

`

export const PP = styled.p`
color:${(props: any) => props.theme.white};
font-size:13px;
font-weight:bold;

`
export const DIVS = styled.div`
display:flex;
align-items:center;
gap:5px;

`
export const RoundedBtn = styled.button`
border: '2px solid ${(props: any) => props.theme.white}'

`
