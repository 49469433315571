import styled from 'styled-components'
import { ModalBox } from '../optionModal/optionmodal.styles'

export const DriverModalBox = styled(ModalBox) `
    height: 600px;
    overflow-y: scroll;

    &.driver-vehicle-type-box {
        height: unset;
    }
`