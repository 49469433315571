import React from 'react'
import { CheckBox, CheckBoxLabel, CheckBoxWrapper } from './togglebar.styles'

const ToggleBar = ({onClick, top}: any ) => {
  return (
        <CheckBoxWrapper onClick={onClick}>
            <CheckBox id="checkbox" type="checkbox" />
            <CheckBoxLabel htmlFor="checkbox"/>
        </CheckBoxWrapper>
  )
}

export default ToggleBar