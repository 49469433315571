import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'

import snap from "../../../assets/images/png/snap.png";

import {
  DIV,
  INPUTS,
  P,
  VerifyButton,
  Text,
  DropDown,
  COLDIV,
  RegionDiv,
  SELECTDIV,
  ButtonLabel,
  IDDIV,
  FILEDIV,
  SelectDate,
  Flex,
  UploadButton,
  Important,
  Modal,
  Div,
  Label,
  Option,
  Radio,
  Button,
  FLEXDIV2,
  ICONDIV,
  Input,
  InputField,
  NameDIV,
} from "./IndividualProfile.styles";

import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import { BsEnvelopeFill } from "react-icons/bs";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useDriverProfileUpdateMutation, useDriverUpdateMutation, useUpdateProfileMutation } from "../../../services/providerApi";
import DatePicker from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon";
import "react-multi-date-picker/styles/colors/yellow.css";
import type { Value } from "react-multi-date-picker";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { setProfile } from "../../../slices/provider";
// import { FLEXDIV } from '../kin/kin.styles';
import axios from "axios";
// import { SubmitButton, SubmitDiv } from '../regularRates/regularRates.styles';
import { ColumnWrap } from "../../fundWalletThree/fundwalletthree.styles";
import { baseImageUrl } from "../../../services/utils";
import { BiCalendar } from "react-icons/bi";
import {
  baseUrl,
  useUpdateOwnerProfileMutation,
} from "../../../services/adminApi";
import { useAppSelector } from "../../../store";
import { Autocomplete } from "@react-google-maps/api";
import {
  SubmitButton,
  SubmitDiv,
} from "../../providerVehicleProfile/regularRates/regularRates.styles";

type Props = {
  userId: any;
  providerInfo: any;
  setProviderInfo: any;
  vehicleId?: any;
  driverProfile?: any;
};
const IndividualProfile: FC<Props> = ({
  driverProfile,
  vehicleId,
  setProviderInfo,
  providerInfo,
  userId,
}) => {
  const theme = createTheme({
    palette: {},
  });

  const [open, setOpen] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState({
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    phone_number: "",
    address: null,
  });

  const [driverProfileInfo, setDriverProfileInfo] = useState(
    driverProfile?.data[0]
  );

  
  
  useEffect(() => {
    if (driverProfile && driverProfile?.data && driverProfile.data.length > 0) {
        setDriverProfileInfo(driverProfile?.data[0]);
    }
}, [driverProfile]);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const token = useAppSelector((state: any) => state?.auth?.auth?.token);

  const getBusinessCarOwnerDetails = async () => {
    await axios
      .get(`${baseUrl}fetch-car-owner-detail/${vehicleId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setOwnerInfo(response?.data?.data[response?.data?.data.length - 1]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBusinessCarOwnerDetails();
  }, []);

  const eighteenYearsAgo = dayjs().subtract(6574, "day");

  let [image1Prev, setImage1Prev] = useState<any>();

  useEffect(() => {
    if (driverProfile) {
      setImage1Prev(baseImageUrl + driverProfile?.data[0]?.id_image);
    }
  }, [driverProfile]);


  //@ts-ignore
  let convertedDob: any = driverProfileInfo?.dob;
  let day, month, year;
  //@ts-ignore
  if (driverProfileInfo?.dob) {
    [day, month, year] = convertedDob?.split("/");
  }

  let date = new Date(`${month}/${day}/${year}`);
  let formattedDate = date?.toString();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [genderModal, setGenderModal] = useState<boolean>(false);
  const [idModal, setIdModal] = useState<boolean>(false);
  //@ts-ignore
  const [image1, setImage1] = useState<any>();
  const [btnLoad, setBtnLoad] = useState<boolean>(false);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [DOB, setDOB] = useState<any>(formattedDate || new Date());

  const handleIDNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    // setProviderInfo((prevState: any) => ({
    //   ...prevState,
    //   id_number: (event.target as HTMLTextAreaElement).value,
    // }));
    setDriverProfileInfo((prevState: any) => ({
        ...prevState,
        id_number: (event.target as HTMLTextAreaElement).value,
      }));
  };
  const handleFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      firstname: (event.target as HTMLTextAreaElement).value,
    }));
  };

  const handleCountry = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    let country: any = event;
    // setProviderInfo((prevState: any) => ({
    //   ...prevState,
    //   country: country,
    // }));
    setDriverProfileInfo((prevState: any) => ({
        ...prevState,
        country: country,
      }));
  };

  const handleState = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    let state: any = event;
    // setProviderInfo((prevState: any) => ({
    //   ...prevState,
    //   state: state,
    // }));
    setDriverProfileInfo((prevState: any) => ({
        ...prevState,
        state: state,
      }));
  };

  const openModalIDModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIdModal((prev) => !prev);
    setGenderModal(false);
  };
  const openGenderModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGenderModal((prev) => !prev);
    setIdModal(false);
  };
  const selectMale = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);

    setDriverProfileInfo((prevState: any) => ({
      ...prevState,
      gender: "Male",
    }));
    
  };
  const selectFemale = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);

    setDriverProfileInfo((prevState: any) => ({
      ...prevState,
      gender: "Female",
    }));
  };
  const selectOthers = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);

    setDriverProfileInfo((prevState: any) => ({
      ...prevState,
      gender: "Others",
    }));
  };
  const selectNIN = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setIdModal(false);

    setDriverProfileInfo((prevState: any) => ({
      ...prevState,
      identification: "NIN",
    }));
  };
  const selectInternationalPassport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setIdModal(false);

    setDriverProfileInfo((prevState: any) => ({
      ...prevState,
      identification: "International Passport",
    }));
  };
  const selectLicense = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setIdModal(false);

    setDriverProfileInfo((prevState: any) => ({
      ...prevState,
      identification: "Drivers License",
    }));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSaveButton(true);

    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImage1Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage1Prev(e.target.result);
        }
        setImage1(file[0]);
      };
      reader.readAsDataURL(file[0]);

      setDriverProfileInfo((prevState: any) => ({
        ...prevState,
        id_image: file[0],
      }));
    }
    // setFrontImage(false)
    // setBackImage(true)
  };
//   const [updateProfile, { data, isLoading, isSuccess, isError, error }] =
//     useUpdateProfileMutation();

    const [updateDriver, { data, isLoading, isSuccess, isError, error }] =
    useDriverProfileUpdateMutation();
  const [
    updateOwnerProfile,
    {
      data: updateData,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateIsError,
      error: updateError,
    },
  ] = useUpdateOwnerProfileMutation();

  const handleUpdateProfile = () => {
    setIsSaveLoading(true);

    let formData: any = new FormData();
    formData.append("id", driverProfileInfo?.id);
    formData.append("gender", driverProfileInfo?.gender);
    formData.append("dob", driverProfileInfo?.dob);
    formData.append("id_number", driverProfileInfo?.id_number);
    formData.append("id_image", driverProfileInfo?.id_image);
    formData.append("identification", driverProfileInfo?.identification);
    formData.append("state", driverProfileInfo?.state);
    formData.append("country", driverProfileInfo?.country);
    // formData.append("firstname", driverProfileInfo?.firstname);
    // formData.append("lastname", driverProfileInfo?.lastname);
    // formData.append("email", driverProfileInfo?.email);
    formData.append("phone_number", driverProfileInfo?.phone_number);
    // formData.append("user_role", providerInfo?.user_role);

    updateDriver(formData)
      .then((response: any) => {
        // setUserId(response?.data?.data[0]?.user_id)
        setIsSaveLoading(false);
        setShowSaveButton(false);

        if (response?.error?.data?.message) {
          notifyError(response?.error?.data?.message);
        }
        notifySuccess(response?.data?.message);

        // setProviderDetails(response?.data)
      })
      .catch((err) => {
        setIsSaveLoading(false);
        notifyError("Error Occured");
      });
  };

  const handleUpdateOwnerProfile = () => {
    setIsSaveLoading(true);

    let formData: any = new FormData();
    formData.append("id", ownerInfo?.id);
    formData.append("address", ownerInfo?.address);

    formData.append("firstname", ownerInfo?.firstname);
    formData.append("lastname", ownerInfo?.lastname);
    formData.append("email", ownerInfo?.email);

    formData.append("phone_number", ownerInfo?.phone_number);

    updateOwnerProfile(formData)
      .then((response: any) => {
        // setUserId(response?.data?.data[0]?.user_id)
        setIsSaveLoading(false);
        setShowSaveButton(false);

        if (response?.error?.data?.message) {
          notifyError(response?.error?.data?.message);
        }
        notifySuccess(response?.data?.message);

        // setProviderDetails(response?.data)
      })
      .catch((err) => {
        setIsSaveLoading(false);
        notifyError("Error Occured");
      });
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const [autocomplete, setAutocomplete] = useState<any>(null);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setOwnerInfo({
          ...ownerInfo,
          address: places,
        });
        // dispatch(setVehicleDetailss({ ...vehicleDetails, vehicle_location: places }));
      } else {
        setOwnerInfo({
          ...ownerInfo,
          address: autocomplete.getPlace().name,
        });
      }
    }
  };

  const handleOwnerFirstName = () => {};
  return (
    <>
      <ToastContainer />
      <>
        <DIV>
          <COLDIV>
            <P>
              Gender <Important>*</Important>
            </P>
            <SELECTDIV>

              <Text>{driverProfileInfo?.gender}</Text>
              <DropDown onClick={openGenderModal}>
                <IoIosArrowDown
                  style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
                />
              </DropDown>
            </SELECTDIV>
            <div>
              {genderModal && (
                <Div style={{ width: "300px" }}>
                  <Modal>
                    <Option onClick={selectMale}>
                      <Radio
                        style={
                          driverProfileInfo?.gender === "Male"
                            ? { background: "#ffa100" }
                            : { background: "#fff" }
                        }
                      ></Radio>
                      <Text> Male</Text>
                    </Option>
                    <Option onClick={selectFemale}>
                      <Radio
                        style={
                          driverProfileInfo?.gender === "Female"
                            ? { background: "#ffa100" }
                            : { background: "#fff" }
                        }
                      ></Radio>
                      <Text> Female</Text>
                    </Option>
                    <Option onClick={selectOthers}>
                      <Radio
                        style={
                          driverProfileInfo?.gender === "Others"
                            ? { background: "#ffa100" }
                            : { background: "#fff" }
                        }
                      ></Radio>
                      <Text> Others</Text>
                    </Option>
                  </Modal>
                </Div>
              )}
            </div>
          </COLDIV>
          <COLDIV>
            <P>
              Date of Birth <Important>*</Important>
            </P>
            <Flex>
              <INPUTS
                type="text"
                readOnly
                style={{
                  fontSize: "13px",
                  padding: "19px 0px",
                  border: "none",
                  outline: "none",
                }}
                placeholder={moment(formattedDate || new Date()).format(
                  "DD/MM/YYYY"
                )}
                value={moment(formattedDate || new Date()).format("DD/MM/YYYY")}
              />

              <SelectDate>
                <BiCalendar style={{ color: "#fff", position: "absolute" }} />
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      value={DOB}
                      disableMaskedInput={true}
                      maxDate={eighteenYearsAgo}
                      showToolbar={false}
                      onChange={(date: any) => {
                        setShowSaveButton(true);
                        // @ts-ignore
                        setDriverProfileInfo((prevState: any) => ({
                          ...prevState,
                          // @ts-ignore
                          dob: moment(
                            JSON.stringify(new Date(date).toString())
                          ).format("DD/MM/YYYY"),
                        }));

                        // setDOB(JSON.stringify(new Date(date).toString()))
                      }}
                      renderInput={(params) => (
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={(e: any) => setOpen(true)}
                          onKeyDown={onKeyDown}
                          {...params}
                        />
                      )}
                      // @ts-ignore
                      sx={{ background: "transparent" }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </SelectDate>
            </Flex>
          </COLDIV>
        </DIV>
        <DIV>
          <COLDIV>
            <P>
              Means of ID <Important>*</Important>
            </P>
            <SELECTDIV>
              <Label>{driverProfileInfo?.identification}</Label>

              <DropDown onClick={openModalIDModal}>
                <IoIosArrowDown
                  style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
                />
              </DropDown>
            </SELECTDIV>

            <div>
              {idModal && (
                <Div>
                  <Modal style={{ width: "70%" }}>
                    <Option onClick={selectInternationalPassport}>
                      <Radio
                        style={
                          driverProfileInfo?.identification ===
                          "International Passport"
                            ? { background: "#ffa100" }
                            : { background: "#fff" }
                        }
                      ></Radio>
                      <Text>International Passport</Text>
                    </Option>
                    <Option onClick={selectLicense}>
                      <Radio
                        style={
                          driverProfileInfo?.identification === "Drivers License"
                            ? { background: "#ffa100" }
                            : { background: "#fff" }
                        }
                      ></Radio>
                      <Text>Drivers License</Text>
                    </Option>
                    <Option onClick={selectNIN}>
                      <Radio
                        style={
                          driverProfileInfo?.identification === "NIN"
                            ? { background: "#ffa100" }
                            : { background: "#fff" }
                        }
                      ></Radio>
                      <Text> NIN</Text>
                    </Option>
                  </Modal>
                </Div>
              )}
            </div>
          </COLDIV>
          <COLDIV>
            <P>
              ID Number <Important>*</Important>
            </P>
            <INPUTS
              type="text"
              //@ts-ignore
              value={driverProfileInfo?.id_number}
              onChange={handleIDNumber}
              style={{
                fontWeight: "600",
                padding: "17px",
                border: "none",
                outline: "none",
              }}
              placeholder="ID Number"
            />
          </COLDIV>
        </DIV>

        <IDDIV
          style={{
            background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${image1Prev})`,
            objectFit: "cover",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
        
          <img
            src={image1Prev}
            alt={image1Prev}
            width="100%"
            style={{ objectFit: "cover" }}
          />
          <FILEDIV
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              if (fileInputRef.current != null) {
                fileInputRef.current.click();
              }
            }}
          >
            <img src={snap} alt="" width="30px" />
          </FILEDIV>
        </IDDIV>

        <DIV style={{ marginTop: 25 }}>
          <COLDIV>
            <P>
              Country <Important>*</Important>
            </P>

            <div className="country-select">
              <RegionDiv>
                <CountryDropdown
                  value={driverProfileInfo?.country}
                  //@ts-ignore
                  onChange={handleCountry}
                />
              </RegionDiv>
            </div>
          </COLDIV>

          <COLDIV>
            <P>
              State <Important>*</Important>
            </P>
            <div className="country-select">
              <RegionDiv>
                <RegionDropdown
                  country={driverProfileInfo?.country}
                  value={driverProfileInfo?.state}
                  //@ts-ignore
                  onChange={handleState}
                />
              </RegionDiv>
            </div>
          </COLDIV>
        </DIV>

        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          accept="image/*"
          onChange={onChange}
        />

        {showSaveButton && (
          <SubmitDiv>
            {isSaveLoading ? (
              <SubmitButton style={{ backgroundColor: "#001aff" }}>
                Updating Profile...
              </SubmitButton>
            ) : (
              <SubmitButton
                style={{ backgroundColor: "#001aff" }}
                onClick={() => handleUpdateProfile()}
              >
                Save and Update Profile
              </SubmitButton>
            )}
          </SubmitDiv>
        )}
      </>
    </>
  );
};

export default IndividualProfile;
