import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CartItem = {
  booking_id: string;
  // Add other properties of the cart item here
};

interface CartState {
  cart: CartItem[];
}

const initialState: CartState = {
  cart: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state: CartState, action: PayloadAction<CartItem>) => {
      state.cart = Array.isArray(state.cart)
        ? [...state.cart, action.payload]
        : [action.payload];
    },
    deleteFromCart: (state: CartState, action: PayloadAction<string>) => {
      state.cart = Array.isArray(state.cart)
        ? state.cart.filter((item) => item.booking_id !== action.payload)
        : [];
    },
    replaceCartItem: (state: CartState, action: PayloadAction<CartItem>) => {
      state.cart = Array.isArray(state.cart)
        ? state.cart.map((item) =>
            item.booking_id !== action.payload.booking_id
              ? item
              : action.payload
          )
        : [action.payload];
    },
    clearCartItem: (state: CartState) => {
      state.cart = [];
    },
  },
});

const { reducer, actions } = cartSlice;
export const { addToCart, deleteFromCart, replaceCartItem, clearCartItem } =
  actions;
export default reducer;
