import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import "./styles/Login.css";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../assets/images/svg/roadlers-profile-logo.svg";
import spin from "../../../assets/images/gif/spinner.gif";
import closedEye from "../../../assets/images/svg/closed-eye.svg";
import openedEye from "../../../assets/images/svg/opened-eye.svg";

import { ToastContainer, toast } from "react-toastify";
import { useLoginMutation } from "../../../services/onboardingApi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setAuth, setRole } from "../../../slices/auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import { SignupDiv } from "../../Service_provider/Auth/Login/Login.styles";
import { useCreateFiatWalletMutation } from "../../../services/walletApi";
import { H2 } from "../../../components/signupCard/signupCard.styles";
import { Text } from "./index.styles";
// import { useDispatch } from 'react-redux';

const Login = () => {
  let lastLoggedIn = localStorage.getItem("roadlersLastVisited");
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [eyeOpen, setEyeOpen] = useState<any>("password");
  const dispatch = useAppDispatch();

  const vehicleRegistrationExist = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails?.vehicle_name
  );

  // RTK endpoints
  const [login, { data, isLoading, isSuccess, isError, error }] =
    useLoginMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);

      if (data?.data.user_role === "admin") {
        dispatch(setAuth(data?.data));
        dispatch(setRole("admin"));

        const { from } = location.state || {
          from: { pathname: "/admin-dashboard" },
        };
        navigate(from);
      } else if (data?.data.user_role === "provider") {
        dispatch(setAuth(data?.data));
        dispatch(setRole("provider"));

        const { from } = location.state || {
          from: { pathname: "/provider-dashboard" },
        };
        navigate(from);
      } else if (data?.data.user_role === "customer") {
        dispatch(setAuth(data?.data));
        dispatch(setRole("customer"));
        const from = lastLoggedIn || "/user-wallet-page";
        navigate(from);
        localStorage.clear();
      } else {
        dispatch(setAuth(data?.data));
        navigate("/");
      }
    }
    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      notifyError(error?.data?.message);
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handleMail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let formData: any = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    if (email.length === 0) {
      notifyError("Please type in your mail");
    } else if (password.length === 0) {
      notifyError("Please type in your password");
    } else if (!email.match(mailformat)) {
      notifyError("Invalid Mail Format");
    } else {
      login(formData);
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <Header />
      <div className="inner-login-wrapper">
        <div className="header-text">
          <H2>
            Welcome Back, <span>Login</span>
          </H2>
        </div>

        <div className="login-box">
          <form className="inner-login-box" onSubmit={(e) => handleLogin(e)}>
            <SignupDiv>
              <div className="input-mail-box">
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => handleMail(e)}
                />
                <img src={logo} alt="roadlers profile logo" />
              </div>

              <div className="input-password-box">
                <input
                  type={eyeOpen}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => handlePassword(e)}
                />
                <div className="img-eye">
                  {eyeOpen === "password" ? (
                    <img
                      src={openedEye}
                      onClick={() => setEyeOpen("text")}
                      alt="opened eye"
                    />
                  ) : (
                    <img
                      src={closedEye}
                      onClick={() => setEyeOpen("password")}
                      alt="closed eye"
                    />
                  )}
                </div>
              </div>
            </SignupDiv>

            <div className="login-btn-box" style={{ marginTop: "1em" }}>
              <p style={{ marginBottom: "1em", fontSize: "13px" }}>
                <Link to={"/forgot-password"} style={{ color: "#FFA100" }}>
                  Forgot Password
                </Link>
              </p>
              {btnLoad && (
                <button
                  disabled
                  style={{
                    background: "#000",
                    color: "gray",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>Loading</p> <img src={spin} alt="spin gif" />{" "}
                </button>
              )}
              {!btnLoad && (
                <button type="submit">
                  <p>Login</p>
                </button>
              )}
              <p style={{ marginBottom: "3em", fontSize: "13px" }}>
                <Text>Don't have an account? </Text>
                <Link to={"/customer-signup"} style={{ color: "#FFA100" }}>
                  Click Here
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
