import React, { useEffect, useState } from 'react'
import { H01, NumText, ProgressBarImage, ProgressBarInner, ProgressBarWrap, ProgressNumWrap, ProgressOverall } from './progressbar.styles'

import car from '../../assets/images/svg/progressCarIcon.svg'

const ProgressBar = ({progressText, barDisplay, progressNum}: any) => {
    const [barPercent, setbarPercent] = useState('33%')

    const handleBarWidth = () => {
        if(progressNum === 1) { 
            setbarPercent('8%')
        }else if(progressNum === 2) {
            setbarPercent('50%')
        }else {
            setbarPercent('95%')
        }
    }

    useEffect(() => {
        handleBarWidth()
    }, [progressNum])

  return (
    <ProgressOverall>

        <H01>
            {progressText}
        </H01>
        
        <ProgressBarWrap >
            <ProgressNumWrap barDisplay={barDisplay}>
                <NumText>
                    {`${progressNum}/3`}
                </NumText>

                <NumText>
                    3
                </NumText>
            </ProgressNumWrap>
            <ProgressBarInner barWidth={barPercent}>
                <ProgressBarImage src={car} />
            </ProgressBarInner>
        </ProgressBarWrap>
    </ProgressOverall>

  )
}

export default ProgressBar