import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import car from '../../../assets/images/png/Car.png'
import Sandcar from '../../../assets/images/png/SandCar.png'

import {
    Button,
    CounterBox,
    ExportButton,
    Text,
    Box,
    DarkBox,
    H1
} from './counter.styles';
import { useAppDispatch, useAppSelector } from '../../../store';
// import { useGetAdminDriversQuery, useGetAdminRequestsQuery, useGetAdminVehiclesQuery } from '../../../services/adminApi';
import ExportToExcel from '../ExportToExcel';
import { setAllDriver } from '../../../slices/admin';
import axios from 'axios';
import { baseUrl } from '../../../services/adminApi';

type Props = {
    driver?: boolean;
    request?: boolean;
    vehicle?: boolean;
    provider?: boolean;
    customer?: boolean;
    business?: boolean;
    requestsData?: any;

}
interface ExportData {
    driver_picture?: string;
    image1?: string;
    image2?: string;
    image3?: string;
    image4?: string;
    image5?: string;
    book_picture?: string;
}

const Counter: FC<Props> = ({ requestsData, business, driver, request, vehicle, provider, customer }) => {
    // const vehicles: any = useAppSelector((state: any) => state?.admin.allVehicle)
    // const providers: any = useAppSelector((state: any) => state?.admin?.allProvider)
    const requests: any = useAppSelector((state: any) => state?.admin?.allBooking)
    // const drivers: any = useAppSelector((state: any) => state?.admin?.allDriver)
    const allCustomer = useAppSelector((state: any) => state?.admin.allCustomer)

    const dispatch: any = useAppDispatch()

    const user = useAppSelector((state: any) => state?.auth?.auth)

    const [drivers, setDrivers] = useState({})
    const [vehicles, setVehicles] = useState({})
    const [providers, setProviders] = useState({})
    const [totalCount, setTotalCount] = useState({
        totalBusiness: 0,
        totalSignUp: 0
    })


    // const { data: adminDrivers, isFetching: isFetchingDrivers, refetch: refetchDrivers } = useGetAdminDriversQuery()

    // const { data: adminRequests, isFetching: isFetchingRequests, refetch: refetchRequests } = useGetAdminRequestsQuery(1)
    // const { data: adminVehicles, isFetching: isFetching, refetch: refetchVehicles } = useGetAdminVehiclesQuery()



    // useEffect(() => {
    //     refetchRequests()
    // }, [refetchRequests, adminRequests])

    // useEffect(() => {
    //     refetchVehicles()
    // }, [refetchVehicles, adminVehicles])

    // useEffect(() => {
    //     refetchDrivers();
    //     if (adminDrivers?.data) {
    //         dispatch(setAllDriver(adminDrivers?.data?.data));
    //     }
    // }, [refetchDrivers, adminDrivers, dispatch]);


    // const vehicleResult = adminVehicles?.data?.data?.map(({image1,image2,image3,image4,image5,...rest}:ExportData) => ({...rest}));
    // const bookingResult = adminDrivers?.data?.data?.map(({book_picture,...rest}:ExportData) => ({...rest}));
    // const driverResult = adminDrivers?.data?.map(({driver_picture,...rest}:ExportData) => ({...rest}));
    // const driverResult = adminDrivers?.data?.map(({driver_picture,...rest}:ExportData) => ({...rest}));

    const getDriverCount = async () => {
        await axios.get(`${baseUrl}count-driver-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response: any) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setDrivers(response?.data?.data)
            }

        }).catch((err) => {
        })
    }

    const getVehicleCount = async () => {
        await axios.get(`${baseUrl}count-vehicle-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response: any) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setVehicles(response?.data?.data)
            }

        }).catch((err) => {
        })
    }

    const getProviderCount = async () => {
        await axios.get(`${baseUrl}count-provider-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response: any) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setProviders(response?.data?.data)
            }

        }).catch((err) => {
        })
    }


    const getTotalCount = async () => {
        await axios.get(`${baseUrl}fetch-total-counts`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            // dispatch(setAllDriver(response?.data))
            setTotalCount(response?.data?.data)

        }).catch((err) => {
        })
    }



    useEffect(() => {
        getTotalCount()
        getDriverCount()
        getVehicleCount()
        getProviderCount()
    }, [])



    //@ts-ignore
    const totalDriverCount = Object.values(drivers).reduce((sum, count) => sum + count, 0);
   
    //@ts-ignore
    const totalProviderCount = Object.values(providers).reduce((sum, count) => sum + count, 0);


    //@ts-ignore
    const totalVehicleCount = Object.keys(vehicles).reduce((sum, key) => {
        // Check if the key is not "countPinned" before adding to the sum
        if (key !== "countPinned") {
            return sum + vehicles[key];
        }
        return sum;
    }, 0);

    const [assets, setAssets] = useState([]);
    const [driversData, setDriversData] = useState([]);
    const [providersData, setprovidersData] = useState([]);
    const [customersData, setCustomersData] = useState([]);


    const getAssets = async () => {
        await axios({
            method: "get",
            url: `${baseUrl}vehicle-status`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const allVehicle = response.data.data;

            setAssets(allVehicle);

        });
    };

    const fetchDrivers = async () => {
        await axios
            .get(`${baseUrl}list-drivers`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const driverData = response.data.data;


                setDriversData(driverData)


            });
    }

    const fetchCustomers = () => {
        axios
            .get(`${baseUrl}registered-customers`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const cusData = response.data.data;
                setCustomersData(cusData)


            });
    }

    const getProvidersData = async () => {
        await axios({
            method: "get",
            url: `${baseUrl}provider-details`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;

            setprovidersData(respData);

        });
    }

    useEffect(() => {
        getAssets();
        fetchDrivers()
        fetchCustomers()
        getProvidersData()
    }, []);

     const driverResult = driversData?.map(({driver_picture,...rest}:ExportData) => ({...rest}));

    return (
        <CounterBox>




            {request &&
                <>
                    <ExportToExcel apiData={requests} fileName={'Roadlers Requests'} />
                    <Box>
                        <Button style={{ background: '#ffa100' }}>
                        </Button>
                        <Text>
                            Total Request
                        </Text>
                    </Box>
                </>
            }
            {driver &&
                <>

                    <ExportToExcel apiData={driverResult} fileName={'Roadlers Drivers'} />

                    <Box>
                        <Button style={{ background: '#ffa100' }}>
                        </Button>
                        <Text>
                            Total Drivers
                        </Text>
                    </Box>
                </>
            }

            {vehicle &&
                <>
                    <ExportToExcel 
                    apiData={assets} fileName={'Roadlers Vehicles'} />

                    <Box>
                        <Button style={{ background: '#ffa100' }}>

                        </Button>
                        <Text>
                            Total Vehicle
                        </Text>

                    </Box>
                </>
            }

            {provider &&

                <>
                    
                    <ExportToExcel apiData={providersData} fileName={'Roadlers Providers'}/>

                    <Box>
                        <Button style={{ background: '#ffa100' }}>

                        </Button>
                        <Text>
                            Total Provider
                        </Text>

                    </Box>
                </>
            }

            {customer &&
                <>
                    <ExportToExcel apiData={customersData} fileName={'Roadlers Customers'}/>
                       
                    <Box>
                        <Button style={{ background: '#ffa100' }}>

                        </Button>
                        <Text>
                            Total Users
                        </Text>

                    </Box>
                </>

            }
            {business &&
                <>
                    
                    <ExportToExcel apiData={providersData} fileName={'Roadlers Providers'}/>

                    <Box>
                        <Button style={{ background: '#ffa100' }}>

                        </Button>
                        <Text>
                            Total Business
                        </Text>

                    </Box>
                </>

            }



            {driver && <DarkBox>
                <img src={car} alt="" width={40} />
                <H1>{totalDriverCount}</H1>
            </DarkBox>}

            {request && <DarkBox>
                <img src={car} alt="" width={40} />
                <H1>{requests?.length}</H1>
            </DarkBox>}

            {vehicle && <DarkBox>
                <img src={Sandcar} alt="" width={40} />
                <H1>{totalVehicleCount}</H1>
            </DarkBox>}

            {provider && <DarkBox>
                <img src={car} alt="" width={40} />
                <H1>{totalProviderCount}</H1>
            </DarkBox>}
            {customer && <DarkBox>
                <img src={car} alt="" width={40} />
                <H1>{allCustomer?.data?.total}</H1>
            </DarkBox>}

            {business && <DarkBox>
                <img src={car} alt="" width={40} />
                <H1>{totalCount?.totalBusiness}</H1>
            </DarkBox>}

        </CounterBox>
    )
}

export default Counter