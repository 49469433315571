import React from 'react'
import { CardContentWrap, CardTextWrap, CardImgWrap, AbsoluteButton } from '../walletCardComponent/walletcard.styles'
import { EarningBtn, EarningCardContentWrap, EarningCardTextWrap, EarningCardWrap } from './earningswalletcard.styles'

import wallet from '../../assets/images/png/earning-img.png'
import fund from '../../assets/images/svg/fund-img.svg'
import withdraw from '../../assets/images/svg/withdraw-icon.svg'


const EarningWalletCard = () => {
  return (
    <>
        <EarningCardWrap>
            <div className="inner-item-card">
                <EarningCardContentWrap>
                    <EarningCardTextWrap>
                        <p>$10.00</p>

                        <EarningBtn>
                            My Earning
                        </EarningBtn>
                    </EarningCardTextWrap>
                    <CardImgWrap>
                        <img src={wallet}/>
                    </CardImgWrap>
                </EarningCardContentWrap>
            </div>

            <AbsoluteButton>
                <div className="inner-button">
                    <img src={withdraw} />

                    <p>Use Funds</p>
                </div>
            </AbsoluteButton>
        </EarningCardWrap>
    </>
  )
}

export default EarningWalletCard