import React, { FC, useEffect, useRef, useState } from "react";
import { BackgroundBox, Button, H3, ModalBox } from "./index.styles";
import { Link } from "react-router-dom";
import { useAdminCustomerDataMutation } from "../../../services/adminApi";
import userImg from "../../../assets/images/png/phebe.png";
import locationBlack from "../../../assets/images/png/locationBlack.png";
import location from "../../../assets/images/png/location.png";
import seat from "../../../assets/images/png/seat.png";
import cam from "../../../assets/images/png/cam.png";
import {
  useGetVehiclesQuery,
  useProviderPictureUpdateMutation,
  useTravelGarageMutation,
} from "../../../services/providerApi";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import CurrencyInput from "react-currency-input-field";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Compressor from "compressorjs";
import uuid from "react-uuid";
import { deleteLonePassenger, setPassengers } from "../../../slices/travelPassengers";
import { IoMdClose } from "react-icons/io";

const ViewPassengerModal: FC<{ viewPassengerModal; setViewPassengerModal }> = ({
  setViewPassengerModal,
  viewPassengerModal,
}) => {
  let [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [imagePrev, setImagePrev] = useState<any>();

  const user = useAppSelector((state: any) => state?.auth.auth);
  const { passengers } = useAppSelector<any>(
    (store: RootState) => store?.travel
  );

  const {
    data: providerVehicles,
    isFetching: isFetchingVehicles,
    refetch: refetchVehicles,
  } = useGetVehiclesQuery(user?.id);

  const [travelGarageData, { data, isLoading, isSuccess, isError, error }] =
    useTravelGarageMutation();

  useEffect(() => {
    refetchVehicles();
  }, [providerVehicles, refetchVehicles]);

  const dispatch: any = useAppDispatch();
  let [passengerDetails, setPassengerDetails] = useState<any>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    next_of_kin_contact: "",
  });

 

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const [
    uploadImage,
    {
      data: providerImage,
      isLoading: providerImageLoading,
      isSuccess: providerImageSuccess,
      isError: providerImageIsError,
      error: providerImageError,
    },
  ] = useProviderPictureUpdateMutation();

  function deletePassenger(id) {
    dispatch(deleteLonePassenger(id));
  }

  return (
    <>
      <ToastContainer />

      <BackgroundBox
        onClick={() => setViewPassengerModal((prev: any) => !prev)}
      >
        <ModalBox onClick={(e: any) => e.stopPropagation()}>
          <div className="modal-box">
            <h2>View Passengers</h2>
            {passengers.map((passenger) => {
              return (
                <div key={passenger?.id} className="passenger">
                  <div className="details">
                    <h4>
                      {passenger.firstname} {passenger?.lastname}
                    </h4>
                  </div>
                  <div
                    className="remove"
                    onClick={() => deletePassenger(passenger?.id)}
                  >
                    <IoMdClose />
                  </div>
                </div>
              );
            })}
          </div>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default ViewPassengerModal;
