import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: #242321;
padding:1.3em;

`

export const DriverCounterBox = styled.div`
 display:flex;
gap:20px;
align-items:flex-end;

`