import styled from 'styled-components'


export const H4 = styled.h4`
color:#fff;
margin-top:50px;
`
export const Box = styled.div`
display:flex;
margin-top:35px;
justify-content:space-between;


`
export const ButtonArea = styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`
export const Button = styled.button`
border-radius:5px;
padding:4px 5px;
color:white;
border:none;
outline:none;
font-size:11px;
border:1px solid #fff;
font-weight:bold;
display:flex;
flex:1;
@media only screen and (max-width: 380px)  {
    font-size: 10px;
padding:4px 5px;

}
`
export const Buttons = styled.button`
border-radius:5px;
padding:4px 8px;
color:white;
border:none;
outline:none;
font-size:12px;
border:1px solid #fff;
font-weight:normal;
display:flex;
flex:1;
@media only screen and (max-width: 380px)  {
    font-size: 12px;
padding:4px 7px;

}
`
export const Label = styled.label`
font-size:11.5px;
margin-top:5px;
`



