import React, { useRef, useState } from 'react'
import "./styles/InputVideo.css"

import cancelBtn from "../../assets/images/svg/cancel-icon-yellow.svg"
import yellowCross from "../../assets/images/svg/yellow-cross.svg"
import camera from "../../assets/images/svg/camera.svg"


import { Player } from 'video-react';

const InputVideo = () => {
    const [blogVid, setBlogVid] = useState<any>()
    const [videoSrc , seVideoSrc] = useState("");
    const blogVidRef: any = useRef()

    const blogVidChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setBlogVid(URL.createObjectURL(file))
        } else {
            setBlogVid(null)
        }
    }

    const removeBlogImg = () => {
        setBlogVid(null)
    }

    const handleChange = ({file}:any) => {
      var reader = new FileReader();
      var url = URL.createObjectURL(file.originFileObj);
      seVideoSrc(url);
    };

    return <div className="video-input-wrapper">
        <div className="circle-top-add">
            <img src={yellowCross} alt="" />
        </div>

        <input type="file" className='blog-image-input' ref={blogVidRef} onChange={blogVidChange} />

        <div className="circle-bottom-add">
            <img src={yellowCross} alt="" />
        </div>
        <div className="image-inner-wrapper">

            {
                !blogVid &&
                <div className="upload-action-box">
                    <div className="action-btn" onClick={(e) => {
                        e.preventDefault()
                        blogVidRef.current.click()
                    }}>
                        <img src={camera} alt="" />
                        <p>Upload Video</p>
                    </div>
                </div>
            }


            {
                blogVid &&
                <div className="blog-img-wrapper">
                    {/* <img src={URL.createObjectURL(blogVid)} alt="Cover " /> */}
                    <video controls width="400" height={150} className="videoplayer">
                        <source src={blogVid} type="video/mp4"/>
                    </video>
                    {/* <Player
                        playsInline
                        src={videoSrc}
                        fluid={false}
                        width={480}
                        height={272}
                    /> */}
                    <button onClick={removeBlogImg}><img src={cancelBtn} alt="cancel btn" /></button>
                </div>
            }
        </div>
    </div>
}

export default InputVideo