import { PillWrapper } from "./../../pages/user/Homepage/homepage.styles";
import { ModalBox } from "./../optionModal/optionmodal.styles";
import { colors } from "./../../assets/styles/Theme";
import styled from "styled-components";
import {
  InputBtn,
  LocationBox,
  WhereRadioPill,
} from "../locationModal/locationmodal.styles";

export const TimeBox = styled(LocationBox)`
  height: 340px;
  border: none;
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* .roadlers-time {
        border: solid 2px #fff;
        border-radius: 6px;
        outline: solid 1px #ffa100;
        background: transparent;
        color: #ffa100;
        font-size: 20px;
        padding: 6.5px 11px 6.5px 65px;

        .ant-picker-input {
            input {
                color: #ffa100;
                font-size: 40px;
                font-weight: 600;
                display: flex;
                justify-content: center;
            }

            .ant-picker-suffix {
                display: none;
            }
        }

        .ant-picker-now {
            a {
                color: #ffa100;
                font-weight: 600;
            }
        }

        .ant-picker-ok {
            button {
                background: #ffa100;
            }
        }
    } */

  @media only screen and (max-width: 454px) {
    height: 300px;
  }

  & .MuiClockPicker-root {
    @media only screen and (max-width: 454px) {
      /* border: solid 1px red !important; */
      /* margin-left: 17px !important; */
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;
    }
  }

  /* OVERRIDEING THE CSS OF THE CLOCK */

  & .MuiCalendarOrClockPicker-root .css-epd502 {
    /* border: solid 1px red !important; */
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }

  & .MuiCalendarOrClockPicker-root-hyRPnu {
    flex-direction: column-reverse !important;
    align-items: center;
  }

  & .MuiClock-root {
    @media only screen and (max-width: 454px) {
      justify-content: center !important;
    }
  }

  & .MuiPickerStaticWrapper-content {
    background-color: transparent !important;

    @media only screen and (max-width: 454px) {
      min-width: unset !important;
    }
  }

  & .MuiClock-clock {
    background-color: #242321 !important;
    padding: 13px !important;
    position: relative !important;
    width: 250px !important;
    height: 250px !important;

    @media only screen and (max-width: 454px) {
      width: 220px !important;
      height: 220px !important;
    }

    &::after {
      content: "";
      border: dotted 1px #ffa100;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 93%;
      height: 93%;

      @media only screen and (max-width: 454px) {
        width: 91%;
        height: 91%;
      }
    }
  }

  & .MuiClock-squareMask {
    @media only screen and (max-width: 454px) {
      top: 0;
      width: 75% !important;
      font-size: 11px;
    }
  }

  & .MuiClock-pin,
  & .MuiClockPointer-root,
  & .MuiClockPointer-thumb,
  & .MuiClockPointer-root {
    background-color: #ffa100 !important;
  }

  & .MuiClockPointer-thumb,
  & .MuiClockPointer-thumb {
    border: 16px solid #ffa100 !important;
  }

  & .MuiClockNumber-root.Mui-selected {
    color: #000 !important;
  }

  & .MuiClockNumber-root {
    color: #fff !important;

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }

    @media only screen and (max-width: 454px) {
      height: 16px !important;
    }
  }

  & .MuiPickersToolbar-content {
    justify-content: center !important;
  }

  & .MuiTimePickerToolbar-amPmSelection {
    margin-right: unset !important;
  }

  & .MuiDialogActions-root,
  & .MuiTypography-root {
    display: none !important;
  }

  & .MuiTypography-root-PrivatePickersToolbarText-root,
  &
    .MuiTypography-root-PrivatePickersToolbarText-root-MuiTimePickerToolbar-separator,
  & .MuiTypography-root-PrivatePickersToolbarText-root {
    color: #fff !important;
  }

  & .MuiTypography-root-PrivatePickersToolbarText-root,
  .MuiTypography-root-PrivatePickersToolbarText-root-MuiTimePickerToolbar-separator {
    @media only screen and (max-width: 454px) {
      font-size: 2rem;
    }
  }

  .MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel {
    @media only screen and (max-width: 454px) {
      font-size: 12px !important;
    }
  }

  .MuiPickersToolbar-root-MuiTimePickerToolbar-root {
    @media only screen and (max-width: 454px) {
      padding: 0 !important;
    }
  }

  /* DIGITAL CLOCK DESIGN */
  .MuiPickersToolbar-root {
    padding-top: 0px;

    .MuiGrid-root-gfgujD {
      &.kbPpDK {
        flex-flow: unset;
        column-gap: 10px;

        .MuiTimePickerToolbar-amPmSelection-bJbCAC {
          &.gwxxPW {
            flex-direction: column;
            row-gap: 3px;
          }
        }
      }
    }
  }

  & .MuiGrid-root {
    .MuiTimePickerToolbar-hourMinuteLabel {
      .MuiButtonBase-root {
        display: block !important;
        color: #fff !important;

        .MuiTypography-root {
          display: block !important;
          color: #fff !important;
          /* font-size: 20px !important; */
        }
      }

      .MuiTypography-root {
        display: block !important;
        color: #fff !important;
      }
    }

    .MuiTimePickerToolbar-ampmSelection {
      margin-right: unset;
      .MuiButtonBase-root {
        display: block !important;
        color: #fff !important;
        .MuiTypography-root {
          display: block !important;
          color: #fff !important;

          &.Mui-selected {
            color: #ffa100 !important;
          }
        }
      }
    }

    .MuiButtonBase-root {
      display: none;
    }
  }
`;

export const TimeDateModalBox = styled(ModalBox)`
  display: ${(props: any) => props.boxDisplay};

  & .inner-modal-box {
    @media only screen and (max-width: 454px) {
      padding: 25px 22px !important;
    }

    .calendar-wrap {
      padding: 20px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* align-items: center; */
      border-radius: 12px;

      .header-wrap {
        display: flex;
        justify-content: flex-start;

        h3 {
          color: #868686;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .select-type-wrap {
        margin-bottom: 20px;
      }

      .subwrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .rmdp-shadow {
          box-shadow: none !important;
        }

        .rmdp-arrow-container:hover {
          background-color: #ffa100 !important;
        }

        .rmdp-week-day {
          color: #c5c7c9 !important;
        }

        .bsCcPI .inner-modal-box .calendar-wrap .subwrapper .rmdp-range {
          border-radius: 10px !important;
        }

        .rmdp-day span {
          border-radius: 10px !important;
        }

        .rmdp-range {
          background-color: #ffa100 !important;
          border-radius: 10px !important;
        }

        .rmdp-day.rmdp-disabled {
          color: #8798ad !important;
        }

        .rmdp-day.rmdp-selected {
          background-color: #ffa100 !important;
          color: #fff !important;
          border-radius: 4px !important;
        }

        .rmdp-day.rmdp-today span {
          background-color: transparent !important;
          color: black !important;
        }

        .dNdA-dG
          .inner-modal-box
          .calendar-wrap
          .subwrapper
          .rmdp-day:not(.rmdp-disabled)span:hover {
          background-color: unset !important;
          color: unset !important;
        }

        .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
          background-color: unset !important;
          color: unset !important;
        }

        /* .rmdp-day{
                    background-color: unset !important;
                    color: #000 !important;
                } */

        .rmdp-day.rmdp-selected span:not(.highlight) {
          background-color: unset !important;
          color: unset !important;
          box-shadow: none !important;
        }

        .rmdp-arrow {
          /* border: solid #000 !important;
                    border-width: 0 2px 2px 0; */
          border: solid #000 !important;
          border-width: 0 2px 2px 0 !important;
        }
      }

      .footer-btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const DatePrefWrap = styled(PillWrapper)`
  margin-bottom: 0px;
  padding-bottom: 10px;
  align-items: center;
`;

export const RadioPill = styled(WhereRadioPill)`
  background-color: ${(props: any) =>
    props.pillActive?.length !== 0 ? "#FFA100" : "#fff"};
  border: solid 2px #000;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const DatePreferencePill = styled.div`
  padding: 8px 15px;
  border-radius: 10px;
  background-color: #fff;
  border: solid 1px
    ${(props: any) => (props.pillActive?.length !== 0 ? "#000" : "#fff")};
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 1, 0.25);
  display: flex;
  justify-content: center;
  color: #000;
  font-size: 14px;
  font-weight: 700;

  @media only screen and (max-width: 454px) {
    font-size: 12px;
  }
`;

export const FooterBtn = styled.div`
  cursor: pointer;
  padding: 13px 35px;
  border-radius: 18px;
  background-color: ${(props: any) =>
    props.active === true ? "#FFD58C" : " #000"};
  color: ${(props: any) => (props.active === true ? "#000" : " #fff")};
  font-weight: 700;
  -webkit-box-shadow: 0px 1px 17px 0px
    ${(props: any) => (props.active === true ? "none" : " rgba(0,0,0, 0.45)")};
  -moz-box-shadow: 0px 1px 17px 0px
    ${(props: any) => (props.active === true ? "none" : " rgba(0,0,0,0.45)")};
  box-shadow: 0px 1px 17px 0px
    ${(props: any) => (props.active === true ? "none" : " rgba(0,0,0,0.45)")};
`;

export const InputSqrBtn = styled(InputBtn)`
  border-radius: 7px;

  @media only screen and (max-width: 454px) {
    padding: 6px 7px !important;
  }
`;
