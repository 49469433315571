import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const onboardingApi = createApi({
    reducerPath: 'onboardingApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}` );
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    endpoints: builder => ({
        login: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'login',
                    method: 'post',
                    body: value
                };
            },
        }),
        customerSignUp: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'sign-up',
                    method: 'post',
                    body: value
                };
            },
        }),
        customerOTP:  builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'otp-authentication',
                    method: 'post',
                    body: value
                };
            },
        }),
        confirmCustomerOTP: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'otp-confirm',
                    method: 'post',
                    body: value
                };
            },
        }),
        resetPassword: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'reset-password',
                    method: 'post',
                    body: value
                };
            },
        }),

    }),
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useCustomerSignUpMutation,
    useConfirmCustomerOTPMutation,
    useCustomerOTPMutation,
} = onboardingApi;