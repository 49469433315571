import React, { useEffect, useState } from "react";
import { InputIcons } from "../locationModal/locationmodal.styles";
import {
  CancelModalBox,
  ModalOverlay,
} from "../optionModal/optionmodal.styles";
import { RadioPill } from "../timeDateModal/timedatemodal.styles";
import {
  ClearFilterBtn,
  ControlWrap,
  FilterFooter,
  FuelType,
  GreyInputWrap,
  LocationInputBox,
  ModalFilterBox,
  ModaLHeader,
  PriceRangeInput,
  PriceRangeWrap,
  RangeSubWrap,
  RangeWrap,
  SeeResultBtn,
  VehicleFilterOverall,
  VehiclePillWrap,
  VehicleTypePill,
  YearRange,
} from "./filterModal.styles";
import {
  AppDispatch,
  RootState,
  useAppSelector,
  useAppDispatch,
} from "../../store";
import { setShowFilter } from "../../slices/showFilter";
import CurrencyInput from "react-currency-input-field";

import location from "../../assets/images/svg/location-black.svg";
import add from "../../assets/images/svg/triangle-up.svg";
import subtract from "../../assets/images/svg/triangle-down.svg";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { setSearchData } from "../../slices/showCarOption";

const FilterModal = ({
  isHomepage,
  vehicleBrand,
  setVehicleBrand,
  setMinYear,
  setMaxYear,
  modalFilter,
  maxYear,
  minYear,
  subtractMaxYear,
  subtractMinYear,
  addMaxYear,
  addMinYear,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  vehicleName,
  setVehicleName,
  vehicleType,
  setVehicleType,
  vehicleLocation,
  setVehicleLocation,
  clearFilter,
  resultsNo,
  handleFilterPill,
  setPickupLatLong,
  setCity,
  periodType,
  setPeriodType,
  fuelType,
  setFuelType,
}: any) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { showFilter } = useAppSelector<any>(
    (store: RootState) => store?.showFilter
  );

  const [vehicleNameType, setVehicleNameType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null);
  const [origin, setOrigin] = useState();

  // const libraries: (
  //   | "places"
  //   | "drawing"
  //   | "geometry"
  //   | "localContext"
  //   | "visualization"
  // )[] = ["places", "geometry", "drawing"];

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
  //   libraries,
  // });

  const onOriginPlaceChanged = () => {
    if (autocompleteOrigin !== null) {
      const places = autocompleteOrigin.getPlace().formatted_address;
      if (places) {
        setOrigin(places);
      } else {
        setOrigin(autocompleteOrigin.getPlace().name);
      }
    }
  };

  const setModalOpen = () => {
    setShowModal(true);
  };

  async function calculateRoute() {
    // @ts-ignore
    if (origin?.length === 0) {
      return;
    }
    const address = origin;
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (result: any, status) => {
      if (status === "OK") {
        setPickupLatLong({
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng(),
        });
        if (result[0]) {
          // Get the city from the results
          const extractedCity = extractCityFromResults(result[0]);
          setCity(extractedCity);
        }
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  }

  const extractCityFromResults = (result: any) => {
    let city = "";
    for (let component of result.address_components) {
      for (let type of component.types) {
        if (type === "locality" || type === "administrative_area_level_1") {
          city = component.long_name;
          break;
        }
      }
      if (city !== "") {
        break;
      }
    }
    return city;
  };

  const filterModalControl = () => {
    dispatch(setShowFilter(false));
    calculateRoute();
  };

  const carTypes = [
    { vehicleName: "Luxury Car" },
    { vehicleName: "SUV" },
    { vehicleName: "Saloon Car" },
    { vehicleName: "Sport Car" },
    { vehicleName: "Vintage Car" },
    // {vehicleName: 'Flat Bird'},
    { vehicleName: "Mini Bus" },
    { vehicleName: "Coaster Bus" },
    { vehicleName: "School Bus" },
    { vehicleName: "Luxury Bus" },
    { vehicleName: "Van " },
    { vehicleName: "Truck" },
    // {vehicleName: 'Trailers'},
    { vehicleName: "Pick-up Truck" },
    // {vehicleName: 'Caterpillers'},
    // {vehicleName: 'Power Bike '},
    { vehicleName: "Delivery Bikes" },
    // {vehicleName: 'Boats'},
    // {vehicleName: 'Helicopters'},
    // {vehicleName: 'Jets'},
    // {vehicleName: 'Emergency '},
  ];

  const carPeriod = [
    { type: "Hour" },
    { type: "Full Day" },
    { type: "Week" },
    { type: "Month" },
  ];

  const handleClearFilter = () => {
    clearFilter();
    filterModalControl();
  };

  const handlePeriodPill = (item: any) => {
    setPeriodType(item);
  };

  return (
    <ModalOverlay activeModal={showFilter}>
      <CancelModalBox onClick={filterModalControl} />
      <div className="modal-inner-wrapper">
        <ModalFilterBox>
          <div className="inner-modal-box">
            <ModaLHeader>
              <LocationInputBox>
                <InputIcons src={location} iconWidth={20} />
                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onOriginPlaceChanged}
                  onLoad={(value) => setAutocompleteOrigin(value)}
                >
                  <input
                    type="text"
                    placeholder="Where"
                    value={origin || vehicleLocation}
                    onChange={(e: any) => {
                      setVehicleLocation(e.target.value);
                      setOrigin(e.target.value);
                    }}
                  />
                </Autocomplete>
              </LocationInputBox>

              <GreyInputWrap
                placeholder=" Brand Name"
                value={vehicleBrand}
                onChange={(e: any) => {
                  setVehicleBrand(e.target.value);
                  dispatch(setSearchData(""));
                }}
              />
            </ModaLHeader>

            <ModaLHeader>
              <RangeWrap>
                <p>Year Range</p>

                <RangeSubWrap>
                  <YearRange>
                    {/* <p>{minYear}</p> */}
                    <input
                      type="text"
                      onChange={(e: any) => {
                        setMinYear(Number(e.target.value));
                      }}
                      placeholder="1999"
                      style={{
                        width: "50%",
                        background: "transparent",
                        padding: "0px 1px",
                        height: "100%",
                        fontSize: "12px",
                        border: "none",
                        outline: "none",
                      }}
                    />
                    <ControlWrap>
                      <img
                        src={add}
                        onClick={() => {
                          setMinYear((prev) => prev + 1);
                        }}
                      />
                      <img src={subtract} onClick={() => subtractMinYear()} />
                    </ControlWrap>
                  </YearRange>

                  <YearRange>
                    <input
                      type="text"
                      onChange={(e: any) => {
                        setMaxYear(Number(e.target.value));
                      }}
                      placeholder="2023"
                      style={{
                        width: "50%",
                        background: "transparent",
                        padding: "0px 1px",
                        height: "100%",
                        fontSize: "12px",
                        border: "none",
                        outline: "none",
                      }}
                    />
                    <ControlWrap>
                      <img
                        src={add}
                        onClick={() => {
                          setMaxYear((prev) => prev + 1);
                        }}
                      />
                      <img src={subtract} onClick={() => subtractMaxYear()} />
                    </ControlWrap>
                  </YearRange>
                </RangeSubWrap>
              </RangeWrap>

              <RangeWrap>
                <p>Price Range</p>
                <RangeSubWrap>
                  <PriceRangeWrap>
                    <CurrencyInput
                      prefix="₦"
                      // value={minPrice}
                      placeholder="Min"
                      // defaultValue={1000}
                      onValueChange={(value, name) => setMinPrice(value)}
                    />
                  </PriceRangeWrap>
                  <PriceRangeWrap>
                    <CurrencyInput
                      prefix="₦"
                      // value={maxPrice}
                      placeholder="Max"
                      // defaultValue={1000}
                      onValueChange={(value, name) => setMaxPrice(value)}
                    />
                  </PriceRangeWrap>
                </RangeSubWrap>
              </RangeWrap>
            </ModaLHeader>

            <VehicleFilterOverall>
              <h3>Vehicle Types</h3>

              <VehiclePillWrap>
                {carTypes?.map((item: any, id: any) => {
                  return (
                    <VehicleTypePill
                      key={id}
                      onClick={() => handleFilterPill(item?.vehicleName)}
                    >
                      <RadioPill
                        pillActive={
                          vehicleType?.includes(item?.vehicleName) ? "ttt" : ""
                        }
                      />
                      <p>{item?.vehicleName}</p>
                    </VehicleTypePill>
                  );
                })}
              </VehiclePillWrap>
            </VehicleFilterOverall>

            {!isHomepage && (
              <VehicleFilterOverall>
                <h3>Period</h3>

                <VehiclePillWrap className="period-pill">
                  {carPeriod?.map((item: any, id: any) => {
                    return (
                      <VehicleTypePill
                        key={id}
                        onClick={() => handlePeriodPill(item?.type)}
                      >
                        <RadioPill
                          pillActive={
                            periodType?.includes(item?.type) ? "ttt" : ""
                          }
                        />
                        <p>{item?.type}</p>
                      </VehicleTypePill>
                    );
                  })}
                </VehiclePillWrap>
              </VehicleFilterOverall>
            )}

            <VehicleFilterOverall>
              <h3>Fuel Type</h3>

              <VehiclePillWrap>
                <FuelType
                  active={fuelType === "petrol" ? true : false}
                  onClick={() => setFuelType("petrol")}
                >
                  Petrol
                </FuelType>

                <FuelType
                  active={fuelType === "electric" ? true : false}
                  onClick={() => setFuelType("electric")}
                >
                  Electric
                </FuelType>

                <FuelType
                  active={fuelType === "diesel" ? true : false}
                  onClick={() => setFuelType("diesel")}
                >
                  Diesel
                </FuelType>
              </VehiclePillWrap>
            </VehicleFilterOverall>

            <FilterFooter>
              <ClearFilterBtn onClick={() => handleClearFilter()}>
                Clear All Filter
              </ClearFilterBtn>

              <SeeResultBtn onClick={filterModalControl}>
                See {resultsNo} Results
              </SeeResultBtn>
            </FilterFooter>
          </div>
        </ModalFilterBox>
      </div>
    </ModalOverlay>
  );
};

export default FilterModal;
