import styled from 'styled-components'

export const Div = styled.div`
   
    display:flex;
    flex-direction:column;
    padding:2em;
    justify-content:center;
    align-items:center;
    height:100vh;
`
export const Box = styled.div`
    position: relative;
    height: auto;
    /* width: 100vw; */
    /* place-items: center; */
    transition: .5s;
    background-color: #fff;
    display:flex;
    flex-direction:column;
    padding:2em 1.5em;
    border-radius:10px;
`
export const H1 = styled.h1`
    color:#000;
    margin:0;
    font-size:20px;

`
export const P = styled.p`
    color:#000;
    font-size:16px;
    font-weight:500;

`
export const Button = styled.button`
    color:#fff;
    outline:none;
    border:none;
    font-size:20px;
    font-weight:700;
    background:#000;
    padding:1em;
    border-radius:20px;
    width:60%;
`