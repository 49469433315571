import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    RatingsBox, P, H3, H6
} from './index.styles';
import ratings from '../../../assets/images/png/ratings.png'

import { BsCalendar3, BsFillFolderFill } from 'react-icons/bs';
const Ratings = () => {
    return (
        <>
            <RatingsBox>
                <H6>Average Ratings Score</H6>
                <P>4/5</P>
                <img src={ratings} alt="" width='100px' />

            </RatingsBox>

        </>
    )
}

export default Ratings