import { Flex } from "./../providerDashboard/providerVehicles/deliveryBikes.styles";
import styled from "styled-components";

export const SearchWrapper = styled.div`
  margin: 15px 0px;
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  position: relative;
  box-shadow: ${(props: any) => props.theme.shadowBox};

  .inner-search-wrap {
    display: flex;
    /* padding: 10px */
  }
  @media only screen and (max-width: 484px) {
    max-width: 90%;
  }
`;

export const InputOverall = styled.div`
  display: flex;
  padding: 15px 0;
  width: 100%;
`;

export const VehicleTypeInput = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  color: #000;
  font-size: 13px;
  width: 100%;
  padding-left: 35px;
  padding-right: 5px;

  &::placeholder {
    color: #868686;
    font-size: 11px;
  }
`;

export const VehicleYearInput = styled(VehicleTypeInput)`
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  text-align: start;
  padding-left: 5px;
  border-left: solid 1px #868686;

  &::placeholder {
    color: #868686;
    text-align: start;
  }
`;

export const UserStateWrap = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  border-right: solid 1px #868686;
  border-left: solid 1px #868686;

  img {
    width: 20px;
    height: 25px;
  }

  p {
    font-size: 9px;
    color: #000;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    width: 40px;
    text-overflow: ellipsis;
  }

  .absolute-txt {
    position: absolute;
    font-size: 9px;
    color: ${(props: any) => props.theme.text};
    top: -14px;
    font-weight: 600;
  }
`;

export const UserImageWrap = styled.div`
    position: absolute;
    border-radius: 50%; 
    left: -25px;
    
    img {
        border-radius: 50%
        width: 50px;
        width: 50px;

    }
`;

export const FilterSearchBtn = styled.button`
  position: relative;
  outline: none;
  border: none;
  background-color: #fff;
  background: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  img {
    width: 28px;
    height: 25px;
  }

  .absolute-txt {
    position: absolute;
    font-size: 9px;
    color: ${(props: any) => props.theme.text};
    top: -14px;
    font-weight: 600;
  }
`;
