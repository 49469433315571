import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  paginationNum: 1
};
const setPaginationNumSlice = createSlice({
  name: 'paginationNum',
  initialState,
  reducers: {
    setPaginationNum: (state, action) => {
      return { ...state, paginationNum: action.payload };
    },

  }
});

const { reducer, actions } = setPaginationNumSlice;
export const { setPaginationNum} = actions;
export default reducer;