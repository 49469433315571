import React, { useState } from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import TransactionAlertBox from '../../../components/transactionAlertBox'
import { AlertHeader, AlertPageOverall } from '../TransactionSuccessPage/successpage.styles'

import smiley from '../../../assets/images/png/successful-smiley.png'

const TransactionInsuranceSuccessPage = () => {
    const [isDisplay, setIsDisplay] = useState('block')

  return (
    <>
        <Header />
        <AlertPageOverall>
        
            <AlertHeader>
                <h1>Transaction Succesful</h1>
            </AlertHeader>

            <TransactionAlertBox 
                alertHeader={'Transaction Successful'}
                smileyIcon={smiley}
                alertContent={'Your request has been sent to the vehicle owner pending acceptance '}
                buttonText={'View My Booking'}
                btnBg={'#005FEE'}
                insureDisplay={isDisplay}
                setIsDisplay={setIsDisplay}
            />

        </AlertPageOverall>
        <Footer />
    </>

  )
}

export default TransactionInsuranceSuccessPage