import React, { FC, useEffect, useState } from 'react'
import {
    ModalContainer, Modal, Label, Flex, ImageContainer, TextContainer, BackButton, DeleteButton, Text
} from './index.styles';
import deleteImage from '../../../assets/images/png/modalOption.png'
import spinner from '../../../assets/images/gif/spinner.gif'

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'
// import { useDeleteVehicleMutation } from '../../../services/providerApi';
// import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setServiceVisibility, setVehicleDetailss } from '../../../slices/provider';
// import { setAllVehicle } from '../../../slices/provider';

type Props = {
    setIsFullDayDel?: any;
    setVehicleDetails?: any;
    setFullDayServiceHour?: any;
    setDiscount?: any;
    vehicleDetails?: any;
    setFullDayServicePrice?: any;
    deleteFor: string;
    setIsHalfDayDel?: any;
    setHalfDayServicePrice?: any
    isHalfDayDel?: any;
    isFullDayDel?: any;
    setIsHourlyDel?: any;
    setHourlyRateHour?: any;
    setHourlyRatePrice?: any;
    isHourlyDel?: any;
    setIsAirportDel?: any;
    setAirportPrice?: any;
    isAirportDel?: any;
    isHalfMonthDel?: any;
    setIsHalfMonthDel?: any;
    setIsFullMonthDel?: any;
    setHalfMonthServicePrice?: any;
    setFullMonthServicePrice?: any;
    isFullMonthDel?: any;
    setFullMonthServiceMonths?: any;
    
}
export const DeleteModal: FC<Props> = ({ isFullMonthDel, setFullMonthServiceMonths, setIsHalfMonthDel,
    setIsFullMonthDel,
    setHalfMonthServicePrice,
    setFullMonthServicePrice, isHalfMonthDel, isAirportDel, setIsAirportDel,

    isHourlyDel, setIsHourlyDel,
    setHourlyRatePrice, isHalfDayDel,
    isFullDayDel, setIsHalfDayDel,
    setHalfDayServicePrice, deleteFor, setFullDayServicePrice, setDiscount, setFullDayServiceHour, vehicleDetails, setVehicleDetails, setIsFullDayDel }) => {

    const dispatch: any = useAppDispatch()


    // const [deleteVehicle, { data, isLoading, isSuccess, isError, error }] = useDeleteVehicleMutation()






    const deleteService = (title: string) => {
        setVehicleDetails((prevDetails: any) => {
            const updatedServicePrice = prevDetails.service_price.map((detail: any) => {
                if (detail.service_title === title) {
                    return {
                        ...detail,
                        price: 0,
                        hour: 0,
                        discount: 0,
                    };
                } else {
                    return detail;
                }
            });
            
            return { ...prevDetails, service_price: updatedServicePrice };
        });
    };
    

    const handleDelete = async () => {
        dispatch(setServiceVisibility({ fullDayServiceVisible: false }))
        deleteService(deleteFor)
        setFullDayServicePrice('')
        setDiscount('0')
        setIsFullDayDel(false)

    }

    const handleDeleteHalfDay = async () => {
        dispatch(setServiceVisibility({ halfDayServiceVisible: false }))
        deleteService(deleteFor)
        setHalfDayServicePrice('')
        setDiscount('0')
        setIsHalfDayDel(false)

    }
    const handleDeleteHourly = async () => {
        dispatch(setServiceVisibility({ hourlyRateVisible: false }))
        deleteService(deleteFor)
        setHourlyRatePrice('')
        setDiscount('0')
        setIsHourlyDel(false)

    }

    const handleDeleteAirport = async () => {
        dispatch(setServiceVisibility({ airportVisible: false }))
        deleteService(deleteFor)
        setDiscount('0')
        setIsAirportDel(false)

    }

    const handleDeleteHalfMonth = async () => {
        dispatch(setServiceVisibility({ halfMonthVisible: false }))
        deleteService(deleteFor)
        setDiscount('0')
        setIsHalfMonthDel(false)
        setHalfMonthServicePrice('')

    }
    const handleDeleteFullMonth = async () => {
        dispatch(setServiceVisibility({ fullMonthVisible: false }))
        deleteService(deleteFor)
        setDiscount('0')
        setIsFullMonthDel(false)
        setFullMonthServicePrice('')

    }


    const handleCancelHourlyModal = async () => {
        setIsHourlyDel((prev: any) => !prev)

    }
    const handleCancelFullDayModal = async () => {
        setIsFullDayDel((prev: any) => !prev)

    }
    const handleCancelHalfDayModal = async () => {
        setIsHalfDayDel((prev: any) => !prev)

    }
    const handleCancelAirportModal = async () => {
        setIsAirportDel((prev: any) => !prev)

    }
    const handleCancelFullMonthModal = async () => {
        setIsFullMonthDel((prev: any) => !prev)

    }
    const handleCancelHalfMonthModal = async () => {
        setIsHalfMonthDel((prev: any) => !prev)

    }


    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",

    })

    return (
        <ModalContainer>
            <ToastContainer />

            <Modal>
                <ImageContainer>
                    <img src={deleteImage} style={{ width: '100%' }} />
                </ImageContainer>
                <TextContainer>
                    <Text>Note</Text>
                    <Label>
                        By clicking this button your vehicle will be unavaliable for <span style={{ fontSize: '13px', fontWeight: '700' }}>{deleteFor}</span>,
                        to reactivate click the green tick. Do you want to proceed?
                    </Label>

                    <Flex>

                        {isFullDayDel &&
                            <>
                                <DeleteButton onClick={handleDelete}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelFullDayModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }

                        {isHalfDayDel &&
                            <>
                                <DeleteButton onClick={handleDeleteHalfDay}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelHalfDayModal}>
                                    Cancel
                                </BackButton>
                            </>



                        }
                        {isHourlyDel &&
                            <>
                                <DeleteButton onClick={handleDeleteHourly}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelHourlyModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }

                        {isAirportDel &&
                            <>
                                <DeleteButton onClick={handleDeleteAirport}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelAirportModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }
                        {isHalfMonthDel &&
                            <>
                                <DeleteButton onClick={handleDeleteHalfMonth}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelHalfMonthModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }
                        {isFullMonthDel &&
                            <>
                                <DeleteButton onClick={handleDeleteFullMonth}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelFullMonthModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }


                    </Flex>
                </TextContainer>


            </Modal>
        </ModalContainer >

    )
}


