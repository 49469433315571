import { SaveBtn } from './../extrasModal/extrasmodal.styles';
import { Wrapper } from './../travelGarageBox/garagebox-styles';
import { InputBtn, UserLocationWrap } from './../locationModal/locationmodal.styles';
import styled from 'styled-components'

export const DeliveryLocationWrap = styled(UserLocationWrap) `
    & .location-input-overall {
        height: 200px;
        overflow-y:  scroll;

        .save-wrapper {
            display: flex;
            margin-top: 30px;
            justify-content: flex-start;
        }

        .save-add-wrapper {
            display: flex;
            padding-top: 10px;
            justify-content: space-between;
            align-items: center;

            .left-wrap {
                display: flex;
                column-gap: 10px;
                align-items: center;

                .delete-btn-wrap {
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                }
            }
        }
    }
`

export const InputWrapOverall = styled.div `
    margin-top: 30px;
`

export const DeliveryInputOverall = styled.div `
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 29px;
    flex-wrap: nowrap;
    width: 100%;
    margin: 30px 0;

    .input-box-wrapper {
        width: 100%;
        background-color: #fff;
        padding: 15px 14px;
        border-radius: 10px;
        color: #000;
        font-size: 13px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;

        .input {
            background-color: transparent;
            border: none;
            outline: none;
            width: 100%;
        }

        .absolute-txt {
            color: #c4c4c4;
            position: absolute;
            top: -18px;
            left: 20px;
            font-size: 12px;
            font-weight: 700;
        }

        .edit-btn {
            border-radius: 7px;
            background-color: #000;
            color: #fff;
            font-size: 10px;
            justify-content: center;
            display: flex;
            font-weight: 700;
            align-items: center;
            cursor: pointer;
            padding: 7px 15px;
        }
    }
`

export const SaveDeliveryBtn = styled(SaveBtn) `
    min-width: 140px;
    font-size: 14px;

    @media only screen and (max-width: 454px)  {
        padding: 8px 14px;
        font-size: 11px;
        border-radius: 4px;
    }
`

export const DoneBtn = styled.button `
    border: solid 1px #F88B0E;
    border-radius: 8px;
    padding: 10px 17px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    outline: none;
    background-color: unset;

    @media only screen and (max-width: 454px)  {
        padding: 8px 14px;
        font-size: 11px;
        border-radius: 4px;
    }
`

export const ItemsBtnList = styled.div`
    /* width: 320px; */
    overflow-x: scroll;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
`

export const ItemBtn = styled.div `
    border: solid 1px ${(props:any) => props.active ? 'none' : '#fff'};
    background-color: ${(props:any) => props.active ? '#FFD58C' : 'transparent'};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props:any) => props.active ? '#000' : '#fff'};
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 11px;
    min-width: 70px;
`

export const DeleteFormWrap = styled.div `
    display: flex;
    justify-content: flex-start;
`

export const DeleteFormBtn = styled.div `
    background-color: #FF3A3A;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px 8px;
    cursor: pointer;
    display: ${(props:any) => props.display};
`

export const DoneOverall = styled.div`
    border: solid 1px red;
    width: 100%;
    
`
export const DeleteQuestionWrap = styled.div `
    border: solid 1px red;
    width: 100%;
    height: 100%;
    position: absolute;
    /* background-color: rgba(255,255,255,0.9); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
`

export const DeleteQuestionBox = styled.div `
    border-radius: 15px;
    background-color: #fff;
    width: 225px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);

    .question-wrap {
        padding: 20px;
        margin-top: 20px;
        p {
            font-size: 16px;
            color: #000;
            font-weight: 600;
            margin: 0;
            text-align: center;
        }
    }

    .btns-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 50px;
    }
`

export const YesBtn = styled(InputBtn) `
    background-color: #D52B1E;
`