import React from "react";
import {
  CancelModalBox,
  ModalOverlay,
} from "../optionModal/optionmodal.styles";
import {
  WalletModalBox,
  WalletModalHeader,
} from "../walletModal/walletmodal.styles";
import {
  AlertModalBody,
  AlertModalFooter,
  AlertModalHead,
  CancelBookngModalOverlay,
  NoBtn,
  YesBtn,
} from "./cancelbookingmodal.styles";

import pic from "../../assets/images/png/create-wallet.png";
import {
  ActionsContainer,
  ImageContainer,
  WalletCardWrap,
} from "../createWalletCard/createWalletCard.styles";

const CancelBookingModal = ({
  showCancelModal,
  setShowCancelModal,
  handleCancelBooking,
}: any) => {
  return (
    <>
      <CancelBookngModalOverlay activeModal={showCancelModal}>
        <CancelModalBox onClick={() => setShowCancelModal(false)} />

        <WalletCardWrap className="cancel-card">
          <ImageContainer>
            <img src={pic} />
          </ImageContainer>
          <ActionsContainer>
            <div className="inner-action-wrap cancel-modal">
              <AlertModalHead>
                <h2>Are you sure you want to cancel?</h2>
              </AlertModalHead>

              <AlertModalBody>
                <p>
                  Please note that only 70% will be refunded to your wallet if
                  this request is cancelled, please make sure your wallet is
                  active for swift refund.{" "}
                </p>
              </AlertModalBody>

              <AlertModalFooter>
                <YesBtn
                  onClick={() => {
                    handleCancelBooking()
                    setTimeout(() => {
                      setShowCancelModal(false);
                    }, 1000);
                  }}
                >
                  Cancel
                </YesBtn>

                <NoBtn onClick={() => setShowCancelModal(false)}>Go Back</NoBtn>
              </AlertModalFooter>
            </div>
          </ActionsContainer>
        </WalletCardWrap>

        {/* <WalletModalBox>
                <div className="inner-modal-box">

                    <AlertModalBody>
                        <p>Are you sure you want to cancel this booking?</p>
                    </AlertModalBody>

                    <AlertModalFooter>
                        <YesBtn onClick={handleCancelBooking}>
                            Yes
                        </YesBtn>

                        <NoBtn onClick={() => setShowCancelModal(false)}>
                            Back
                        </NoBtn>
                    </AlertModalFooter>
                </div>
            </WalletModalBox> */}
      </CancelBookngModalOverlay>
    </>
  );
};

export default CancelBookingModal;
