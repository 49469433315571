import styled from 'styled-components'



export const Input = styled.div`
display:flex;
align-items:center;
background:${(props: any) => props.theme.white};
width:100%;
border-radius:12px;
overflow:hidden;
justify-content:space-around;
padding:12px 15px;
@media only screen and (max-width: 380px) {
padding:9px 15px;
}
`
export const ColumnInput = styled.div`
display:flex;
align-items:center;
flex-direction:column;
margin-top:50px;
gap:7px;
}
`
export const TextField = styled.input`
font-size: 14px;
border: none;
outline: none;
width: 65% ;
background:transparent;
color:${(props: any) => props.theme.body};

::placeholder, ::-webkit-input-placeholder {
    font-size: 16px;
    color:${(props: any) => props.theme.body};
    font-weight:500;
}
@media only screen and (max-width: 380px) {
    ::placeholder, ::-webkit-input-placeholder {
        font-size: 14px;
    }
    }


`

export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:8px 12px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
display:flex;
align-items:center;
justify-content:center;
gap:10px;
`
export const Text = styled.div`
font-size:16px;
margin-top:3px;
@media only screen and (max-width: 380px) {
    font-size:13px;
 
    }
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-bottom:-40px;
margin-top:20px;
position:absolute;
right:25px;
width:300px;
@media only screen and (min-width: 700px) {
    right:200px;
}
@media only screen and (min-width: 1090px) {
    right:300px;
}

`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:80%;


`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`
color:${(props: any) => props.theme.darkborder};
width:100%;


`

