import React from 'react'
import "./styles/Loader.css"

import logo from "../../assets/images/svg/roadlers-logo-no-bg.svg"

const Loader = () => {
  return (
    <div className='loader-wrapper'>
        <div className="lds-ring">
            <img src={logo}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default Loader