import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  FLEXDIV,
  INPUTS,
  DIV,
} from "../../providerDriverReg/firstScreen/firstScreen.styles";

import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

import { IoLocationSharp } from "react-icons/io5";
import FirstScreen from "../../../components/providerAccountSetup/firstScreen/firstScreen";
import { OwnerLabel } from "../../../pages/Service_provider/VehicleProfile/vehicleProfile.styles";
import { LabelTitle } from "../servicePrices/servicePrices.styles";
import { useAppDispatch } from "../../../store";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import {
  Div,
  DropDown,
  Input,
  Modal,
  Option,
  Radio,
  Text,
  TextInput,
  Texts,
  VerifyButton,
} from "../../providerDriverReg/secondScreen/secondScreen.styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDriverUpdateMutation } from "../../../services/providerApi";
import { useParams } from "react-router-dom";

interface ComponentProps {
  driverProfile: any;
}
const DriverProfileForms: FC<ComponentProps> = ({ driverProfile }) => {
  const [updateDriver, { data, isLoading, isSuccess, isError, error }] =
    useDriverUpdateMutation();

  const [driverDetails, setDriverDetails] = useState<any>();

  useEffect(() => {
    if (driverProfile && driverProfile?.data && driverProfile.data.length > 0) {
      setDriverDetails(driverProfile.data[0]);
    }
  }, [driverProfile]);

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const dispatch: any = useAppDispatch();

  // const libraries: (
  //     | 'places'
  //     | 'drawing'
  //     | 'geometry'
  //     | 'localContext'
  //     | 'visualization'
  // )[] = ['places', 'geometry', 'drawing'];

  // const { isLoaded } = useJsApiLoader({
  //     id: 'google-map-script',
  //     googleMapsApiKey: 'AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs',
  //     libraries,
  // })

  const [location, setLocation] = useState(driverDetails?.address);

  let obj = {
    lastname: driverDetails?.lastname,
    driver_id: driverDetails?.id,
    availability1: driverDetails?.availability1,
    availability: driverDetails?.availability,
    address: driverDetails?.address,
    phone_number: driverDetails?.phone_number,
    firstname: driverDetails?.firstname,
    gender: driverDetails?.gender,
    country: driverDetails?.country,
    state: driverDetails?.state,
    id_number: driverDetails?.id_number,
    identification: driverDetails?.identification,
    latitude: driverDetails?.latitude,
    longitude: driverDetails?.longitude,
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setLocation(places);
        setDriverDetails({
          ...driverDetails,
          address: places,
        });

        setLocation(places);
        obj["address"] = places;
        //@ts-ignore
        updateDriver([obj]);
      } else {
        setLocation(autocomplete.getPlace().name);
      }
    }
  };

  const address = driverDetails?.address;
  const geocoder = new window.google.maps.Geocoder();

  useEffect(() => {
    geocoder.geocode({ address }, (result: any, status) => {
      if (status === "OK") {
        setDriverDetails({
          ...driverDetails,
          latitude: result[0]?.geometry.location.lat(),
          longitude: result[0]?.geometry.location.lng(),
        });
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

    //@ts-ignore
  }, [address]);

  const regex: RegExp = /^[A-Za-z]+$/; // Regex to match alphabetic characters only

  const handleFirstname = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDriverDetails({
      ...driverDetails,
      firstname: event.target.value,
    });
    obj["firstname"] = event.target.value;
    //@ts-ignore
    updateDriver([obj]);
  };

  const handleLastname = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDriverDetails({
      ...driverDetails,
      lastname: event.target.value,
    });
    obj["lastname"] = event.target.value;
    //@ts-ignore
    updateDriver([obj]);
  };

  const handleLocation = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDriverDetails({
      ...driverDetails,
      address: event.target.value,
    });
    setLocation(event.target.value);
    obj["address"] = event.target.value;
    //@ts-ignore
    updateDriver([obj]);
  };
  const handlePhone = (e: any) => {
    setDriverDetails({
      ...driverDetails,
      phone_number: e,
    });
    obj["phone_number"] = e;
    //@ts-ignore
    updateDriver([obj]);
  };
  const handleIDnumber = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDriverDetails({
      ...driverDetails,
      id_number: event.target.value,
    });
    obj["id_number"] = event.target.value;
    //@ts-ignore
    updateDriver([obj]);
  };

  const selectLicense = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDriverDetails({
      ...driverDetails,
      identification: "Drivers License",
    });
    obj["identification"] = "Drivers License";
    //@ts-ignore
    updateDriver([obj]);
    setIdModal((prev) => !prev);
  };

  const selectInternationalPassport = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDriverDetails({
      ...driverDetails,
      identification: "International Passport",
    });
    obj["identification"] = "International Passport";
    //@ts-ignore
    updateDriver([obj]);
    setIdModal((prev) => !prev);
  };

  const selectNIN = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDriverDetails({
      ...driverDetails,
      identification: "NIN",
    });
    obj["identification"] = "NIN";
    //@ts-ignore
    updateDriver([obj]);
    setIdModal((prev) => !prev);
  };

  const [idModal, setIdModal] = useState<boolean>(false);

  const openModalIDModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIdModal((prev) => !prev);
  };

  return (
    <>
      <FLEXDIV style={{ marginTop: "50px" }}>
        <INPUTS
          type="text"
          value={driverDetails?.firstname}
          onChange={handleFirstname}
          style={{ padding: "20px", border: "none", outline: "none" }}
          placeholder="Firstname"
        />
        <INPUTS
          type="text"
          value={driverDetails?.lastname}
          onChange={handleLastname}
          style={{ padding: "20px", border: "none", outline: "none" }}
          placeholder="Lastname"
        />
      </FLEXDIV>

      <DIV>
        <div className="autoFill">
          <Autocomplete
            className="autocomplete-wrap"
            onPlaceChanged={onPlaceChanged}
            onLoad={(value) => setAutocomplete(value)}
          >
            <INPUTS
              type="text"
              placeholder="Location"
              onChange={(e: any) => {
                setLocation(e.target.value);
              }}
              value={location || driverDetails?.address}
              style={{ padding: "18px 5px" }}
            />
          </Autocomplete>
        </div>

        <IoLocationSharp
          style={{ color: "#FFA100", fontSize: 35, fontWeight: 600 }}
        />
      </DIV>

      <Input style={{ marginTop: "30px" }}>
        <div className="driverCode">
          <PhoneInput
            defaultCountry="NG"
            value={driverDetails?.phone_number}
            onChange={handlePhone}
            international={true}
            style={{ fontSize: 18 }}
          />
        </div>
      </Input>

      {/* <Input style={{ marginTop: '28px', padding: '0px 15px' }}>

                <TextInput
                    type="text"
                    placeholder='ID Number'
                    value={driverDetails?.id_number}
                    onChange={handleIDnumber}
                />

                <VerifyButton>
                    <Texts>{driverDetails?.identification}</Texts>
                    <DropDown onClick={openModalIDModal}>
                        <IoIosArrowDown style={{ color: '#FFD58C', fontSize: 20, fontWeight: 600 }} />
                    </DropDown>
                </VerifyButton>

            </Input>
            <div>
                {
                    idModal && <Div>
                        <Modal style={{ width: '75%' }}>

                            <Option onClick={selectInternationalPassport}>
                                <Radio style={driverDetails?.identification === 'International Passport' ? { background: '#ffa100' } : { background: '#fff' }}></Radio>
                                <Text>International Passport</Text>
                            </Option>
                            <Option onClick={selectLicense}>
                                <Radio style={driverDetails?.identification === 'Drivers License' ? { background: '#ffa100' } : { background: '#fff' }}></Radio>
                                <Text>Drivers License</Text>
                            </Option>
                            <Option onClick={selectNIN}>
                                <Radio style={driverDetails?.identification === 'NIN' ? { background: '#ffa100' } : { background: '#fff' }}></Radio>
                                <Text> NIN</Text>
                            </Option>
                        </Modal>
                    </Div>
                }
            </div> */}
    </>
  );
};
export default DriverProfileForms;
