import React from 'react'
import { CancelModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import { ActionsContainer, ImageContainer, WalletCardWrap } from './createWalletCard.styles'

import pic from '../../assets/images/png/create-wallet.png'
import LittleLoader from '../littleLoader'

const CreateWalletCard = ({creatWalletActive, setCreateWalletModal, setBvn, handleCreateVirtualWallet, bvnError, isLoading, comingFrom}:any) => {
  return (
    <ModalOverlay activeModal={creatWalletActive}>
      <CancelModalBox onClick={() => {setCreateWalletModal(false)}} />

      <WalletCardWrap>
        <ImageContainer>
          <img src={pic} />
        </ImageContainer>

        {
          isLoading ? 
          <LittleLoader /> :
          <ActionsContainer>
            <div className="inner-action-wrap">
              <div className="header-section">
                Create Wallet
              </div>

              <div className="content-section">
                <p>To create your wallet please insert your BVN</p>
              </div>

              <div className="input-section">
                <input type="text" placeholder='Type BVN' onChange={(e:any) => setBvn(e.target.value)} />

                <div className="bvn-error">{bvnError}</div>
              </div>

              <div className="btn-overall">
                {
                  comingFrom === "cart" &&
                  <div className="create-btn back-btn" onClick={() => {setCreateWalletModal(false)}}>
                    Back
                  </div>
                }

                <div className="create-btn" onClick={handleCreateVirtualWallet}>
                  Create
                </div>
              </div>
            </div>
          </ActionsContainer>
        }
      </WalletCardWrap>
    </ModalOverlay>
  )
}

export default CreateWalletCard