import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {
  AssetOverall,
  BoxExtras,
  H11,
  PillWrapper,
  SearchBtn,
} from "../Homepage/homepage.styles";
import {
  BookingQuestionsWrap,
  CarouselImg,
  CarouselSection,
  ModalCallBtn,
  PP,
  QuestionBox,
  QuestionHeader,
  QuestionImg,
  SearchInput,
  SearchOverall,
  VehicleNameBox,
  VehiclePrice,
  ModalCallBtnWrap,
  BookingFooterWrap,
  BookingFooterBtn,
  FeatureWrapper,
  SubFeatureWrap,
  TickBox,
  SetHoursBox,
  HoursControlBox,
  WhereImg,
  QuestImg,
  BookingOverall,
  BookingFlexWrap,
  BookingExtrasFlexWrap,
} from "./CarBooking.styles";
import AssetCard from "../../../components/assetCard";
import ProgressBar from "../../../components/progressBar";
import OwnersNoteCard from "../../../components/ownersNoteCard";
import RoadlersCard from "../../../components/roadlersCard";
import OffersCard from "../../../components/offersCard";
import Carousel from "../../../components/carousel";
import Modal from "../../../components/modal";
import OptionsModal from "../../../components/optionModal";
import LocationModal from "../../../components/locationModal";
import { setShowLocation } from "../../../slices/showLocation";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store";
import { setShowCarOption } from "../../../slices/showCarOption";
import TimeDateModal from "../../../components/timeDateModal";
import { setShowTimeDate } from "../../../slices/showTimeDate";
import { setShowExtraNote } from "../../../slices/showExtraNote";
import ExtrasModal from "../../../components/extrasModal";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SearchComponent from "../../../components/searchComponent";
import {
  useGetVehicleByIdQuery,
  useGetVehicleListQuery,
  useSpecialRequestMutation,
} from "../../../services/customerApi";

import car1 from "../../../assets/images/svg/carousel1.svg";
import car2 from "../../../assets/images/svg/carousel2.svg";
import car3 from "../../../assets/images/svg/carousel3.svg";
import arrowDown from "../../../assets/images/svg/arrowDown.svg";
import whereTag from "../../../assets/images/svg/where-tag.svg";
import whenTag from "../../../assets/images/svg/when-tag.svg";
import noteTag from "../../../assets/images/svg/note-tag.svg";
import tickX from "../../../assets/images/svg/tickx-icon.svg";
import tickR from "../../../assets/images/svg/tickr-icon.svg";
import addArrow from "../../../assets/images/svg/addArrow.svg";
import subtractArrow from "../../../assets/images/svg/subtractArrow.svg";
import { addToCart } from "../../../slices/cart";
import uuid from "react-uuid";
import { PaddingWrap } from "../CartPage/cartPage.styles";
import SearchUpdateComponent from "../../../components/searchUpdateComponent";
import Loader from "../../../components/loader";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { baseImageUrl } from "../../../services/utils";
import { AnyObjectSchema } from "yup";

const CarBooking = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const vehicleId: any = searchParams.get("vehicleId");
  const dispatch: AppDispatch = useAppDispatch();
  const { cart } = useAppSelector<any>((store: RootState) => store?.cart);
  const [vehicleInfoArray, setVehicleInfoArray] = useState<any>([]);
  const [hours, setHours] = useState(1);
  const [halfDayHour, setHalfDayHour] = useState(6);
  const [month, setMonth] = useState(1);
  const [days, setDays] = useState(1);
  const [weeks, setWeeks] = useState(1);
  const [whenModalBtn, setWhenModalBtn] = useState(false);
  const [whereModalBtn, setWhereModalBtn] = useState(false);
  const [bookedDate, setBookedDate] = useState([]);
  const [pickupTime, setPickupTime] = useState("");
  const [carOwnerInstruction, setCarOwnerInstruction] = useState("");
  const [escortDays, setEscortDays] = useState("");
  const [escortNumber, setEscortNumber] = useState("");
  const [progressbarNum, setProgressBarNum] = useState(1);
  const [progressInfo, setProgressInfo] = useState(
    "Start Booking - Let’s Move"
  );
  const [transportType, setTransportType] = useState("Within state");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [isStateWithin, setIsStateWithin] = useState(true);
  const [tripOptionsChosen, setTripOptionsChosen] = useState("Select option");
  const [tripChosenPrice, setTripChosenPrice] = useState<number>(0);
  const [bookingAmount, setBookingAmount] = useState(0);
  const [insuranceAmount, setInsuranceAmount] = useState<number>(0);
  const [insuranceType, setInsuranceType] = useState("");
  const [dateLength, setDateLength] = useState(6);
  const [vehicleList, setVehicleList] = useState<any>([]);
  const [filterVehicleList, setFilterVehiclelist] = useState<any>([]);

  const { data, isFetching, isLoading, refetch, error } =
    useGetVehicleByIdQuery(params?.vehicleId, {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: vehicleListData,
    isFetching: isVehicleListFetching,
    refetch: refetchVehiclelist,
    isSuccess: isVehicleListSuccess,
  } = useGetVehicleListQuery({
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [
    specialRequest,
    {
      data: specialRequestData,
      isLoading: specialRequestLoading,
      isSuccess: specialRequestSuccess,
      isError: isSpecialRequestError,
      error: specialRequestError,
    },
  ] = useSpecialRequestMutation();

  useEffect(() => {
    if (params?.vehicleId > 0) {
      refetch();
    }
  }, [params?.vehicleId]);

  useEffect(() => {
    refetch();
    setVehicleInfoArray(data?.data?.[0]);
  }, [params?.vehicleId, vehicleInfoArray, data]);

  useEffect(() => {
    refetchVehiclelist();
    setVehicleList(vehicleListData?.data);
  }, [vehicleListData, params?.vehicleId, vehicleList]);

  const slides: any = [
    {
      img: `${vehicleInfoArray?.vehicleData?.[0]?.image1}`,
      name: "Main Image",
      id: 0,
    },
    {
      img: `${vehicleInfoArray?.vehicleData?.[0]?.image2}`,
      name: "Inner Image",
      id: 1,
    },
    {
      img: `${vehicleInfoArray?.vehicleData?.[0]?.image3}`,
      name: "Back Image",
      id: 2,
    },
    {
      img: `${
        vehicleInfoArray?.vehicleData?.[0]?.image5 ||
        vehicleInfoArray?.vehicleData?.[0]?.image4
      }`,
      name: "Side Image",
      id: 3,
    },
  ];

  // USEFFECT FOR FILTERING ALL RELATED VEHICLES
  useEffect(() => {
    let filtered = vehicleList?.filter((vehicle: any) => {
      return vehicle?.id !== Number(vehicleInfoArray?.vehicleData?.[0]?.id);
    });

    if (vehicleInfoArray?.vehicleData?.[0]?.vehicle_location) {
      filtered = filtered?.filter((item: any) =>
        item?.vehicle_location
          ?.toLowerCase()
          ?.includes(
            vehicleInfoArray?.vehicleData?.[0]?.vehicle_location?.toLowerCase()
          )
      );
    }

    if (vehicleInfoArray?.vehicleData?.[0]?.vehicle_type) {
      filtered = filtered?.filter((item: any) =>
        item?.vehicle_type
          ?.toLowerCase()
          ?.includes(
            vehicleInfoArray?.vehicleData?.[0]?.vehicle_type?.toLowerCase()
          )
      );
    }

    setFilterVehiclelist(filtered);
  }, [vehicleList, vehicleInfoArray]);

  const locationModalControl = () => {
    dispatch(setShowLocation(true));
  };

  const carOptionModalControl = () => {
    dispatch(setShowCarOption(true));
  };

  const timeDateModalControl = () => {
    dispatch(setShowTimeDate(true));
  };

  const extraModalControl = () => {
    dispatch(setShowExtraNote(true));
  };

  const handleIncreaseHours = () => {
    setHours(hours + 1);
  };

  const handleDecreaseHours = () => {
    const hourlyRate = vehicleInfoArray?.serviceData?.find(
      (item: any) => item.service_title === "Hourly Rate"
    );
    if (hours > hourlyRate?.hour) {
      return setHours(hours - 1);
    }
  };

  const handleIncreaseDays = () => {
    setDays(days + 1);
  };

  const handleDecreaseDays = () => {
    if (days > 1) {
      return setDays(days - 1);
    }
  };

  const handleIncreaseMonth = () => {
    setMonth(month + 1);
  };

  const handleDecreaseMonth = () => {
    if (month > 1) {
      return setMonth(month - 1);
    }
  };

  const handleLocationComplete = (itemComplete: any) => {
    setWhereModalBtn(itemComplete);
  };

  const handleTimeComplete = () => {
    setWhenModalBtn(true);
  };

  // USEFFECT FUNCTION TO DETERMINE THE PRICE BASED ON THE VEHICLE SERVICE PICKED
  useEffect(() => {
    if (tripOptionsChosen?.includes("Hourly Rate")) {
      setBookingAmount(tripChosenPrice * hours);
    } else if (tripOptionsChosen?.includes("Full Day Service")) {
      setBookingAmount(tripChosenPrice * days);
    } else if (tripOptionsChosen?.includes("Full Month Service")) {
      setBookingAmount(tripChosenPrice * month);
    } else if (tripOptionsChosen?.includes("Half Day Service")) {
      // setHalfDay()
      setBookingAmount(
        tripChosenPrice * (bookedDate?.length ? bookedDate?.length : 1)
      );
    } else if (tripOptionsChosen?.includes("Airport Pickup")) {
      setBookingAmount(
        tripChosenPrice * (bookedDate?.length ? bookedDate?.length : 1)
      );
    } else {
      setBookingAmount(tripChosenPrice);
    }
  }, [
    days,
    hours,
    month,
    days,
    weeks,
    tripOptionsChosen,
    tripChosenPrice,
    bookedDate,
  ]);
  useEffect(() => {
    if (bookedDate?.length !== 0 && pickupTime?.length !== 0) {
      handleTimeComplete();
    }
  }, [bookedDate, pickupTime]);

  useEffect(() => {
    if (whereModalBtn === true) {
      setProgressBarNum(progressbarNum + 1);
    } else {
      setProgressBarNum(progressbarNum);
    }
  }, [whereModalBtn]);

  useEffect(() => {
    if (whenModalBtn === true) {
      setProgressBarNum(progressbarNum + 1);
    } else {
      setProgressBarNum(progressbarNum);
    }
  }, [whenModalBtn]);

  useEffect(() => {
    if (progressbarNum === 2) {
      setProgressInfo("Almost there - Keep Moving");
    } else if (progressbarNum === 3) {
      setProgressInfo("Completed - Proceed to Move with Ease");
    }
  }, [progressbarNum]);

  useEffect(() => {
    if (tripOptionsChosen?.includes("Hourly Rates")) {
      setHours(6);
    } else {
      setHours(1);
    }
  }, [tripOptionsChosen]);

  useEffect(() => {
    if (tripOptionsChosen?.includes("Hourly Rates")) {
    }
  }, [tripOptionsChosen]);
  useEffect(() => {
    if (tripOptionsChosen?.includes("Half Day Service")) {
      const halfDayHour = vehicleInfoArray?.serviceData?.find(
        (item: any) => item.service_title === "Half Day Service"
      );
      setHalfDayHour(halfDayHour?.hour);
    }
  }, [tripOptionsChosen]);

  useEffect(() => {
    if (bookedDate?.length > 0) {
      if (tripOptionsChosen?.includes("Full Day Service")) {
        setDays(bookedDate?.length);
      }
    }
  }, [bookedDate, tripOptionsChosen, days]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (progressbarNum >= 3) {
      if (tripOptionsChosen?.includes("Hourly Rates")) {
        const bookingDetails: any = {
          pickup_date: bookedDate,
          pickup_time: moment(pickupTime, "HH:mm").format("hh:mm a"),
          pickup_location: origin,
          destination: destination,
          escort_personel: escortNumber,
          escort_days: escortDays,
          passenger_instruction: carOwnerInstruction,
          hours_needed: hours,
          image: vehicleInfoArray?.vehicleData?.[0]?.image1,
          transport_options: tripOptionsChosen,
          category: "Car Booking",
          amount: bookingAmount * bookedDate?.length + insuranceAmount,
          insurance_amount: insuranceAmount,
          insurance: insuranceType,
          state_location: transportType,
          plate_number: vehicleInfoArray?.vehicleData?.[0]?.plate_number,
          vehicle_id: vehicleInfoArray?.vehicleData?.[0]?.id,
          provider_id: vehicleInfoArray?.vehicleData?.[0]?.user_id,
          booking_title: vehicleInfoArray?.vehicleData?.[0]?.vehicle_name,
          vehicle_year: vehicleInfoArray?.vehicleData?.[0]?.year,
          currency_code: vehicleInfoArray?.serviceData?.[0]?.currency_code,
          booking_id: uuid(),
          total: tripChosenPrice + insuranceAmount,
          tripPrice: tripChosenPrice,
          booking_price: bookingAmount,
          num_of_day: bookedDate?.length,
          book_picture: vehicleInfoArray?.vehicleData?.[0]?.image1,
          self_drive: vehicleInfoArray?.vehicleData?.[0]?.self_drive,
        };

        dispatch(addToCart(bookingDetails));
        navigate("/cart");
      } else {
        const bookingDetails: any = {
          pickup_date: bookedDate,
          pickup_time: moment(pickupTime, "HH:mm").format("hh:mm a"),
          pickup_location: origin,
          destination: destination,
          escort_personel: escortNumber,
          escort_days: escortDays,
          passenger_instruction: carOwnerInstruction,
          image: vehicleInfoArray?.vehicleData?.[0]?.image1,
          transport_options: tripOptionsChosen,
          category: "Car Booking",
          amount: bookingAmount + insuranceAmount,
          insurance: insuranceType,
          insurance_amount: insuranceAmount,
          state_location: transportType,
          plate_number: vehicleInfoArray?.vehicleData?.[0]?.plate_number,
          vehicle_id: vehicleInfoArray?.vehicleData?.[0]?.id,
          provider_id: vehicleInfoArray?.vehicleData?.[0]?.user_id,
          booking_title: vehicleInfoArray?.vehicleData?.[0]?.vehicle_name,
          vehicle_year: vehicleInfoArray?.vehicleData?.[0]?.year,
          currency_code: vehicleInfoArray?.serviceData?.[0]?.currency_code,
          booking_id: uuid(),
          total: tripChosenPrice + insuranceAmount,
          tripPrice: tripChosenPrice,
          booking_price: bookingAmount,
          num_of_day: bookedDate?.length,
          book_picture: vehicleInfoArray?.vehicleData?.[0]?.image1,
          self_drive: vehicleInfoArray?.vehicleData?.[0]?.self_drive,
        };

        dispatch(addToCart(bookingDetails));
        navigate("/cart");
      }
    } else {
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  let fullDayInitialPrice =
    vehicleInfoArray?.serviceData?.find(
      (item: any) => item.service_title === "Full Day Service"
    ) || 0;

  let vehicleMiniumHour = vehicleInfoArray?.serviceData?.find(
    (item: any) => item.service_title === "Hourly Rate"
  )?.hour;

  // const vehicleId = vehicleInfoArray?.vehicleData?.[0]?.id;
  return (
    <>
      <Helmet>
        ‍
        <title>
          Rent {" " + vehicleInfoArray?.vehicleData?.[0]?.vehicle_name + " "}{" "}
          from Roadlers, cheap car rental in
          {" " + vehicleInfoArray?.vehicleData?.[0]?.vehicle_location + " "} get
          luxury and economy car rental with a chauffeur
        </title>
        ‍
        <meta
          name="description"
          content={
            "Find the perfect wheels for your journey with Roadlers car rental. Discover convenient car rental options near you, from cheap auto rentals to luxury car hire. Explore affordable truck rentals for moving or opt for long-term vehicle rental solutions. Enjoy hassle-free bookings for SUVs, minivans, and more. Choose Roadlers for budget-friendly and reliable car rental services."
          }
        />
        <meta
          name="keywords"
          content={
            "car rental, car rental near me, cheap car rentals, vehicle rental near me, auto rental near me, truck rentals for moving, cheap auto rentals, car rental agencies near me, car hire, chauffeur service, economy car rental, long term car rental, rent a van, monthly car rental, airport car rental, minivan rental, luxury car rental, booking car rental, car rental companies, discount car rental, uber car rental, suv rental, rent a van near me, exotic car rental, rent a car for a day, budget car rental near me, cheap car hire near me, chauffeur"
          }
        />
        <link
          rel="canonical"
          href={`car-booking/${vehicleInfoArray?.vehicleData?.[0]?.id}`}
        />
        {/* <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Roadlers Travel Garage" />        
        <meta name="twitter:description" content="Roadlers Travel Garage" />         */}
        <meta
          name="twitter:image"
          content={baseImageUrl + vehicleInfoArray?.vehicleData?.[0]?.image1}
        />
        <meta property="og:title" content="Roadlers Car Booking" />
        <meta property="og:description" content="Roadlers Car Booking" />
        <meta property="og:image" content="url_to_image" />
        <meta property="og:url" content="roadlers.com" />
        <meta property="og:site_name" content="Roadlers Car Booking" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <ToastContainer />

      <OptionsModal
        setTripOptions={setTripOptionsChosen}
        tripOptionChoice={tripOptionsChosen}
        setPrice={setTripChosenPrice}
        setHours={setHours}
        tripOptionsList={vehicleInfoArray?.serviceData}
      />

      <LocationModal
        setLocationBtn={handleLocationComplete}
        setOrigin={setOrigin}
        handleDestination={setDestination}
        setTransportType={setTransportType}
        states={vehicleInfoArray?.travelTrip}
        setPrice={setTripChosenPrice}
        specialRequest={specialRequest}
      />

      <TimeDateModal
        handleBookedDate={setBookedDate}
        handlePickupTime={setPickupTime}
        bookedDate={bookedDate}
        dateLength={dateLength}
        setDays={setDays}
        vehicleId={params?.vehicleId}
      />

      <ExtrasModal
        handleInstruction={setCarOwnerInstruction}
        instruction={carOwnerInstruction}
        setEscortNum={setEscortNumber}
        setEscortDay={setEscortDays}
        insuranceAmount={insuranceAmount}
        setInsuranceAmount={setInsuranceAmount}
        insuranceType={insuranceType}
        setInsuranceType={setInsuranceType}
        insuranceLabel={
          "Would you like to be covered by insurance on this trip?"
        }
      />
      <Header />

      {/* <SearchComponent /> */}
      <PaddingWrap>
        <SearchUpdateComponent />
      </PaddingWrap>

      <BookingFlexWrap>
        <CarouselSection bg={"#fff"}>
          <Carousel
            linkPresent={true}
            slides={slides}
            display={"block"}
            priceDisplay={"block"}
            currencyCode={vehicleInfoArray?.serviceData?.[0]?.currency_code}
            title={`${vehicleInfoArray?.vehicleData?.[0]?.vehicle_name} ${vehicleInfoArray?.vehicleData?.[0]?.year}`}
            displayAmount={`${Number(
              vehicleInfoArray?.serviceData?.[0]?.final_discounted_price
            ).toLocaleString("en-US", { maximumFractionDigits: 2 })}`}
          />
        </CarouselSection>

        <BookingOverall>
          <div
            className="booking-suboverall"
            style={{ padding: "30px", paddingRight: "80px" }}
          >
            <ProgressBar
              progressText={progressInfo}
              barDisplay={"flex"}
              progressNum={progressbarNum}
            />

            <BookingQuestionsWrap>
              <QuestionHeader>
                How long do you need this vehicle?
              </QuestionHeader>
              <QuestionBox>
                <PP>{tripOptionsChosen}</PP>

                {tripOptionsChosen?.includes("Hourly Rate") && (
                  <SetHoursBox>
                    <h2>{hours}</h2>
                    <p>Hours</p>

                    <HoursControlBox>
                      <img src={addArrow} onClick={handleIncreaseHours} />
                      <img src={subtractArrow} onClick={handleDecreaseHours} />
                    </HoursControlBox>
                  </SetHoursBox>
                )}

                {tripOptionsChosen?.includes("Full Day Service") && (
                  <SetHoursBox>
                    <h2>{days}</h2>
                    <p>
                      {days === 1 && "Day"} {days > 1 && "Days"}
                    </p>

                    <HoursControlBox>
                      <img src={addArrow} onClick={handleIncreaseDays} />
                      <img src={subtractArrow} onClick={handleDecreaseDays} />
                    </HoursControlBox>
                  </SetHoursBox>
                )}

                {tripOptionsChosen?.includes("Half Day Service") && (
                  <SetHoursBox>
                    <h2>{halfDayHour}</h2>
                    <p>{"Hours"}</p>

                    <HoursControlBox></HoursControlBox>
                  </SetHoursBox>
                )}

                {tripOptionsChosen?.includes("Full Month Service") && (
                  <SetHoursBox>
                    <h2>{month}</h2>
                    <p>
                      {month === 1 && "Month"} {month > 1 && "Months"}
                    </p>

                    <HoursControlBox>
                      <img src={addArrow} onClick={handleIncreaseMonth} />
                      <img src={subtractArrow} onClick={handleDecreaseMonth} />
                    </HoursControlBox>
                  </SetHoursBox>
                )}

                <WhereImg
                  src={arrowDown}
                  onClick={() => carOptionModalControl()}
                  width={"50px"}
                />
              </QuestionBox>

              <ModalCallBtnWrap>
                <ModalCallBtn
                  complete={whereModalBtn}
                  onClick={() => locationModalControl()}
                >
                  <WhereImg src={whereTag} width={"30px"} />

                  <p>Where?</p>
                </ModalCallBtn>

                <ModalCallBtn
                  complete={whenModalBtn}
                  onClick={() => timeDateModalControl()}
                >
                  <QuestImg
                    complete={whenModalBtn}
                    src={whenTag}
                    width={"30px"}
                  />

                  <p>When?</p>
                </ModalCallBtn>

                <ModalCallBtn
                  complete={
                    carOwnerInstruction || insuranceAmount ? true : false
                  }
                  onClick={() => extraModalControl()}
                >
                  <QuestImg
                    className="note-img"
                    complete={
                      carOwnerInstruction || insuranceAmount ? true : false
                    }
                    src={noteTag}
                    width={"20px"}
                  />

                  <p>Extra/Note</p>
                </ModalCallBtn>
              </ModalCallBtnWrap>
            </BookingQuestionsWrap>

            <BookingFooterWrap>
              {/* {bookingAmount === 0 ? (
                <BookingFooterBtn
                  text={"#fff"}
                  bordercolor={"#fff"}
                  bg={"#000"}
                >
                  {vehicleInfoArray?.serviceData?.[0]?.currency_code}{" "}
                  {`${fullDayInitialPrice?.final_discounted_price?.toLocaleString(
                    "en-US",
                    { maximumFractionDigits: 2 }
                  )}`}
                </BookingFooterBtn>
              ) : ( */}
              <BookingFooterBtn text={"#fff"} bordercolor={"#fff"} bg={"#000"}>
                {vehicleInfoArray?.serviceData?.[0]?.currency_code}{" "}
                {`${(bookingAmount + insuranceAmount)?.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}`}
              </BookingFooterBtn>
              {/* )} */}
              {tripOptionsChosen?.includes("Select option") ||
              progressbarNum < 3 ? (
                <BookingFooterBtn
                  text={"#000"}
                  bg={"#bbb"}
                  onClick={() =>
                    notifyError(
                      "You need to select a booking option from the dropdown, click where  button to fill your destination, click the when button to select the time and date of preference"
                    )
                  }
                >
                  Proceed Booking
                </BookingFooterBtn>
              ) : (
                <BookingFooterBtn
                  text={"#000"}
                  bordercolor={"#ffa100"}
                  bg={"#ffa100"}
                  onClick={(e) => handleSubmit(e)}
                >
                  Proceed Booking
                </BookingFooterBtn>
              )}
            </BookingFooterWrap>

            <p>
              {tripOptionsChosen?.includes("Hourly Rate") &&
                "Minimum hour for this vehicle is " + vehicleMiniumHour}
            </p>
          </div>
        </BookingOverall>
      </BookingFlexWrap>

      <BookingExtrasFlexWrap>
        <FeatureWrapper>
          <h4>Feature</h4>

          <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.vehicleData?.[0]?.air_con?.toLowerCase() ===
              "yes" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />
              )}
            </TickBox>

            <p>Air Conditioner Working</p>
          </SubFeatureWrap>

          <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.vehicleData?.[0]?.travel_available?.toLowerCase() ===
              "yes" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />
              )}
            </TickBox>

            <p>Available for travel </p>
          </SubFeatureWrap>

          <SubFeatureWrap>
            <TickBox>
              <QuestionImg width={"15px"} src={tickR} />
            </TickBox>

            <p>Available Per Hour</p>
          </SubFeatureWrap>

          <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.vehicleData?.[0]?.come_with_driver?.toLowerCase() ===
              "yes" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />
              )}
            </TickBox>

            <p>Comes with a driver </p>
          </SubFeatureWrap>

          {/* <SubFeatureWrap>
            <TickBox>
              <QuestionImg width={"15px"} src={tickX} />
            </TickBox>

            <p>Fully fuelled.</p>
          </SubFeatureWrap> */}

          <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.vehicleData?.[0]?.self_drive?.toLowerCase() ===
              "yes" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />
              )}
            </TickBox>
            <p>Available for self drive</p>
          </SubFeatureWrap>

          <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.vehicleData?.[0]?.night_trips?.toLowerCase() ===
              "yes" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />
              )}
            </TickBox>

            <p>Available for overnight trips</p>
          </SubFeatureWrap>

          {/* <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.insurance_option?.[0]?.come_with_driver ===
              "Yes" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />)}
            </TickBox>

            <p>Comprehensive insurance</p>
          </SubFeatureWrap> */}

          {/* <SubFeatureWrap>
            <TickBox>
              {vehicleInfoArray?.vehicleData[0]?.verified_status == "1" ? (
                <QuestionImg width={"15px"} src={tickR} />
              ) : (
                <QuestionImg width={"15px"} src={tickX} />
              )}
            </TickBox>

            <p>Verified </p>
          </SubFeatureWrap> */}
        </FeatureWrapper>

        <BoxExtras className="booking-section">
          <OwnersNoteCard
            vehicleInfoArray={vehicleInfoArray}
            cardtype={"car"}
          />

          <RoadlersCard />
        </BoxExtras>
      </BookingExtrasFlexWrap>

      <BoxExtras>
        <div className="subsection-header">
          <H11>Related Offers</H11>
        </div>

        <AssetOverall>
          {filterVehicleList?.slice(0, 4)?.map((item: any, id: any) => {
            return (
              <AssetCard
                item={item}
                firstPrice={item?.final_normal_price}
                discountPrice={item?.final_discounted_price}
                discount={item?.discount}
                name={item?.vehicle_name}
                locate={item?.vehicle_location || item?.state}
                vehicleImg={item?.image1}
                vehicleId={item?.id}
                key={id}
                year={item?.year}
                passengerNum={item?.num_passenger}
                rating={item?.rating}
                likes={item?.likes}
              />
            );
          })}
        </AssetOverall>
      </BoxExtras>

      <Footer />
    </>
  );
};

export default CarBooking;
