import React, { FC, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  Input,
  VerifyButton,
  DIV,
  INPUTS,
  Text,
  DropDown,
  Modal,
  Option,
  Div,
  Label,
  ColumnInput,
  InputField,
} from "./secondScreen.styles";
import { setVehicleDetailss } from "../../../slices/provider";
import { useAppDispatch, useAppSelector } from "../../../store";
const SecondScreen: FC<{
  setQuestion: any;
  setWidth: any;
  setVehicleDetails: any;
  vehicleDetails: any;
}> = ({ setQuestion, setWidth, setVehicleDetails, vehicleDetails }) => {
  const regex: RegExp = /^[A-Za-z]+$/; // Regex to match alphabetic characters only

  const vehicleInfo = vehicleDetails;

  const [isVisible, setIsVisible] = useState(false);
  const [year, setYear] = useState(Number(vehicleInfo?.year));
  const dispatch = useAppDispatch();

  useEffect(() => {
    setWidth(30);
    setQuestion(2);
  }, []);

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVisible((prev) => !prev);
  };

  const handleYear = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const inputValue = (event.target as HTMLTextAreaElement).value;
    const regex = /^[0-9\b]+$/;
    if (inputValue === "" || regex.test(inputValue)) {
      setYear(Number((event.target as HTMLTextAreaElement).value));
    }

    setVehicleDetails({
      ...vehicleDetails,
      year: (event.target as HTMLTextAreaElement).value,
    });
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        year: (event.target as HTMLTextAreaElement).value,
      })
    );
  };

  const handlePlateNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setVehicleDetails({
      ...vehicleDetails,
      plate_number: (event.target as HTMLTextAreaElement).value.trim(),
    });
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        plate_number: (event.target as HTMLTextAreaElement).value.trim(),
      })
    );
  };

  const handleBrand = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setVehicleDetails({
      ...vehicleDetails,
      brand_name: (event.target as HTMLTextAreaElement).value,
    });
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        brand_name: (event.target as HTMLTextAreaElement).value,
      })
    );
  };

  const handleName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setVehicleDetails({
      ...vehicleDetails,
      vehicle_name: (event.target as HTMLTextAreaElement).value,
    });
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        vehicle_name: (event.target as HTMLTextAreaElement).value,
      })
    );
  };

  return (
    <>
      <ColumnInput style={{ marginTop: 50 }}>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
          }}
        >
          <Label style={{ width: "70%" }}>Vehicle Name</Label>
          <Label style={{ width: "30%" }}>Year</Label>
        </div>
        <Input>
          <INPUTS
            type="text"
            value={vehicleInfo.vehicle_name}
            onChange={handleName}
            style={{
              width: "70%",
              padding: "0px 15px",
              height: "100%",
              fontSize: "15px",
              border: "none",
              outline: "none",
            }}
            placeholder="Vehicle Name"
          />

          <VerifyButton style={{ padding: "2px", width: "30%" }}>
            <INPUTS
              type="text"
              onChange={handleYear}
              value={Number(year)}
              style={{
                width: "50%",
                background: "transparent",
                padding: "0px 1px",
                color:"#000",
                fontWeight:"700",
                height: "100%",
                fontSize: "15px",
                border: "none",
                outline: "none",
              }}
            />
            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  setYear((prev: any) => prev + 1);
                  setVehicleDetails({
                    ...vehicleDetails,
                    year: year + 1,
                  });
                  dispatch(
                    setVehicleDetailss({ ...vehicleDetails, year: year + 1 })
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  setYear((prev: any) => prev - 1);
                  setVehicleDetails({
                    ...vehicleDetails,
                    year: year - 1,
                  });
                  dispatch(
                    setVehicleDetailss({ ...vehicleDetails, year: year - 1 })
                  );
                }}
              />
            </DropDown>
          </VerifyButton>
        </Input>
      </ColumnInput>

      <DIV style={{ marginTop: "20px" }}>
        <ColumnInput style={{ width: "50%" }}>
          <Label>Brand name</Label>
          <InputField
            type="text"
            value={vehicleInfo.brand_name}
            onChange={handleBrand}
            style={{
              width: "100% ",
              fontSize: "16px",
              border: "none",
              outline: "none",
            }}
            placeholder="Brand name"
          />
        </ColumnInput>
        <ColumnInput style={{ width: "50%" }}>
          <Label>Plate Number</Label>
          <InputField
            type="text"
            value={vehicleInfo.plate_number}
            onChange={handlePlateNumber}
            style={{
              width: "100%",
              ontSize: "16px",
              border: "none",
              outline: "none",
            }}
            placeholder="Plate number"
          />
        </ColumnInput>
      </DIV>
    </>
  );
};

export default SecondScreen;
