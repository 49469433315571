import React from 'react'
import CheckoutWalletCardComponent from '../checkoutWalletCardComponent'
import WalletCard from '../walletCardComponent'
import { CheckoutPayBtn, CheckoutPayWrapper, CheckoutSelectBtn, DropdownYellowBox, SelectWalletBtnWrap } from './checkoutPay.styles'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'
import { useGetWalletByIdQuery } from '../../services/walletApi'


import dropArrow from '../../assets/images/svg/arrow-down-vector.svg'
import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import { RootState, useAppSelector } from '../../store'
import LittleLoader from '../littleLoader'



const CheckoutPayComponent = ({showBox, onPay, setSubComponent, walletTotal, currency, walletPayIsLoading}:any) => {
    const { id = '', firstname = '', lastname = '' } = useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};
    const {data: walletData, refetch: refetchWallet, isSuccess: walletSuccess} = useGetWalletByIdQuery(id, {refetchOnFocus:true, refetchOnMountOrArgChange:true})

    if(walletPayIsLoading) {
        return <LittleLoader></LittleLoader>
    }

  return (
    <CheckoutPayWrapper display={showBox}>
        <BackBtnWrap top={'-50px'} onClick={() => {
            setSubComponent('a')
        }}>
                <img src={leftArrow} />
        </BackBtnWrap>
        <CheckoutWalletCardComponent
            currency={currency}
            walletTotal={walletTotal}
        />

        <SelectWalletBtnWrap>
            {/* <CheckoutSelectBtn>
                <p>Select Wallet</p>

                <DropdownYellowBox>
                    <img src={dropArrow} />
                </DropdownYellowBox>
            </CheckoutSelectBtn> */}
        </SelectWalletBtnWrap>

        <CheckoutPayBtn onClick={() => onPay()}>
            Pay
        </CheckoutPayBtn>
    </CheckoutPayWrapper>
  )
}

export default CheckoutPayComponent