import React, { useEffect, useRef, useState } from "react";
import {
  AccordionBody,
  AccordionContentWrap,
  AccordionHeader,
  AccordionOverall,
  ApprovedBtn,
  Arrow,
  RightAccordionHeadWrap,
} from "./profileaccordion.styles";

import arrow from "../../assets/images/svg/arrowDown.svg";
import camera from "../../assets/images/svg/add-img-yellow.svg";

import idfile from "../../assets/images/png/ID.png";
import { RootState, useAppSelector } from "../../store";
import axios from "axios";
import { baseUrl } from "../../services/providerApi";
import { baseImageUrl } from "../../services/utils";
import { AddImageBtn } from "../transactionAlertBox/transactionalertbox.styles";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";

const ProfileAccordion = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imagePrev, setImagePrev] = useState<any>();
  const [file, setFile] = useState<any>();

  const [showContent, setShowContent] = useState<boolean>(false);
  const { id, token } = useAppSelector<any>(
    (store: RootState) => store?.auth?.auth
  );
  const [idImage, setIdImage] = useState<any>();

  useEffect(() => {
    let formData: any = new FormData();

    formData.append("customer_id", id);

    axios({
      method: "post",
      url: `${baseUrl}search-customer`,
      data: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response: any) => {
        //   setLoading(false);
        //   if(response?.data.message==="success"){
        //     notifySuccess("Details saved successfully")
        //     navigate("/user-booking-page")
        //   }
        console.log(response?.data);
        setIdImage(response?.data?.data?.id_image);
      })
      .catch((error: any) => {
        //handle error
        //   setLoading(false);
      });
  }, []);

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImagePrev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImagePrev(e.target.result);
          setIdImage(e.target.result)
        }

        new Compressor(file[0], {
          quality: 0.6,
          success: (compressedResult: any) => {
         
            let formData: any = new FormData();

            formData.append("id", id);
            formData.append("user_id", id);
            formData.append("id_image", compressedResult);
      
      
            axios({
              method: "post",
              url: `${baseUrl}update-profile`,
              data: formData,
              headers: {
                // "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
              },
            })
              .then((response: any) => {
                // setLoading(false);
                if (response?.data.message === "success") {
                  notifySuccess("ID image saved successfully");
                  // navigate("/user-booking-page");
                }
              })
              .catch((error: any) => {
                //handle error
                // setLoading(false);
              });
          },
        });
      };
      reader.readAsDataURL(file[0]);
    }
    // setFrontImage(false)
    // setBackImage(true)
  };

  return (
    <>
    <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChange}
      />

      <AccordionOverall>
        <AccordionHeader
          onClick={() => {
            setShowContent(!showContent);
          }}
        >
          <div className="inner-accordion-header">
            <div className="doc-title">Documents</div>

            <RightAccordionHeadWrap>
              <ApprovedBtn>Approved</ApprovedBtn>

              <Arrow>
                <img src={arrow} />
              </Arrow>
            </RightAccordionHeadWrap>
          </div>
        </AccordionHeader>

        <AccordionBody>
          <AccordionContentWrap contentShow={showContent}>
            <div
              style={{
                border: "2px solid red",
                borderRadius: 7,
                overflow: "hidden",
                position:"relative"
              }}
            >
               <AddImageBtn>
                <img
                  src={camera} //@ts-ignore
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    if (fileInputRef.current != null) {
                      fileInputRef.current.click();
                    }
                  }}
                />
              </AddImageBtn>

              {idImage ? (
                idImage?.includes("vehDocs")?
                <img src={baseImageUrl+idImage} width={150} />:
                <img src={idImage} width={150} />
              ) : (
                <img src={idfile} width={150} />
              )}
            </div>
          </AccordionContentWrap>
        </AccordionBody>
      </AccordionOverall>
    </>
  );
};

export default ProfileAccordion;
