import React from 'react'
import "./styles/ReviewCard.css"
import stars from "../../assets/images/svg/stars.svg"

const ReviewCard = ({initial, firstName, comments}: any) => {
  return (
    <div className='review-card'>
      <div className="review-card-inner">
        <div className="initials">
          <div className="initials-circle">
              <h1>{initial}</h1>
          </div>
        </div>

        <div className="review-content">
          <div className="reviewers-name">
              <h3>{firstName}</h3>
          </div>

          <div className="reviewers-comment">
            <p className='reviewers-comment-text'>{comments}</p>
          </div>

          <div className="review-ratings">
            <img src={stars} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewCard