import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { INPUTS, DIV, FLEXDIV } from "./firstScreen.styles";
import { setDriverInfo } from "../../../slices/provider";
import { useAppDispatch } from "../../../store";

const FirstScreenDriver: FC<{
  setWidth: any;
  setDriverDetails: any;
  driverDetails: any;
}> = ({ setWidth, driverDetails, setDriverDetails }) => {
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const dispatch: any = useAppDispatch();

  // const libraries: (
  //     | 'places'
  //     | 'drawing'
  //     | 'geometry'
  //     | 'localContext'
  //     | 'visualization'
  // )[] = ['places', 'geometry', 'drawing'];

  // const { isLoaded } = useJsApiLoader({
  //     id: 'google-map-script',
  //     googleMapsApiKey: 'AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs',
  //     libraries,
  // })
  const [firstname, setFirstname] = useState(driverDetails?.firstname || "");
  const [lastname, setLastname] = useState(driverDetails?.lastname || "");
  const [location, setLocation] = useState(driverDetails?.address || "");

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setLocation(places);
        setDriverDetails({
          ...driverDetails,
          address: places,
        });
        dispatch(setDriverInfo({ ...driverDetails, address: places }));
      } else {
        setLocation(autocomplete.getPlace().name);
      }
    }
  };

  const regex: RegExp = /^[A-Za-z]+$/; // Regex to match alphabetic characters only

  const handleFirstname = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const value = (event.target as HTMLTextAreaElement).value;
    if (regex.test(value) || value === "") {
      setFirstname(value);
    }
    setDriverDetails({
      ...driverDetails,
      firstname: value,
    });
    dispatch(setDriverInfo({ ...driverDetails, firstname: value }));
  };
  const handleLastname = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const value = (event.target as HTMLTextAreaElement).value;
    if (regex.test(value) || value === "") {
      setLastname(value);
    }
    setDriverDetails({
      ...driverDetails,
      lastname: value,
    });
    dispatch(setDriverInfo({ ...driverDetails, lastname: value }));
  };

  useEffect(() => {
    setWidth(0);
  }, []);

  return (
    <>
      <FLEXDIV style={{ marginTop: "50px" }}>
        <INPUTS
          type="text"
          value={firstname}
          onChange={handleFirstname}
          style={{ padding: "20px", border: "none", outline: "none" }}
          placeholder="Firstname"
        />
        <INPUTS
          type="text"
          value={lastname}
          onChange={handleLastname}
          style={{ padding: "20px", border: "none", outline: "none" }}
          placeholder="Lastname"
        />
      </FLEXDIV>

      <DIV>
        <div className="autoFill">
          <Autocomplete
            className="autocomplete-wrap"
            onPlaceChanged={onPlaceChanged}
            onLoad={(value) => setAutocomplete(value)}
          >
            <INPUTS
              type="text"
              placeholder="Location"
              onChange={(e: any) => {
                setLocation(e.target.value);
              }}
              value={location}
              address={true}
              style={{ padding: "18px 5px" }}
            />
          </Autocomplete>
        </div>

        <IoLocationSharp
          style={{ color: "#FFA100", fontSize: 35, fontWeight: 600 }}
        />
      </DIV>
    </>
  );
};
export default FirstScreenDriver;
