import styled from 'styled-components'

export const MenuBoxOverall = styled.div `
    position: relative;
    width: 100%;
    height: 180px;

    & .inner-wrap {
        padding: 0 20px;
        display: flex;
        justify-content: center;
    }

    @media only screen and (max-width: 454px) {
        height: 120px;
    }

`

export const NavigationBar = styled.div `
    background-color: ${(props: any) => props.theme.cardColorBg};
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    width: 80%;
    height: 60px;
    position: relative;
    cursor: pointer;

    @media only screen and (max-width: 454px) {
        height: 40px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
`

export const NavigationPillWrap = styled.div `
    position: absolute;
    width: 100%;
    bottom: -30px;
    left: 0;

    & .navigation-inner {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        padding: 0 30px;

        a {
            text-decoration: none;
            color: unset;
        }
    }

    @media only screen and (max-width: 454px) {
        bottom: -20px;
    }
`

export const NavigationPill = styled.div `
     border-radius: 13px;
     border: solid 1px ${(props:any) => props.pillActive === true ? '#ffa100' : '#000'};
     background-color: ${(props:any) => props.pillActive === true ? '#ffa100' : props.theme.cartFooterBg};
     width: 50px;
     height: 50px;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;

     & p {
        position: absolute;
        bottom: -40px;
        text-align: center;
        font-size: 13px;
        color: ${(props:any) => props.theme.text};
     }

     @media only screen and (max-width: 454px) {
        width: 35px;
        height: 35px;
        border-radius: 8px;

        p {
            font-size: 10px;
            bottom: -30px;
        }
    }
`

export const NavigationPillImg = styled.img `
    width: 17px;
    filter: 
        ${(props:any) => props.pillActive === true ? '    invert(100%) sepia(12%) saturate(7450%) hue-rotate(258deg) brightness(118%) contrast(114%)' : props.theme.iconColor};

    @media only screen and (max-width: 454px) {
        width: 14px;
    }
`