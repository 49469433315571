import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  LabelDiv,
  Text,
  AddSpan,
  Add,
  P,
  VerifyButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  Wrapper,
  BlackOpacity,
  DropDown,
  Input,
  Label,
} from "./travelRates.styles";
import { IoIosArrowDown } from "react-icons/io";
import { ModalOne, TravelDiscountModal } from "../TravelModal";
import {
  useCarTravelAddMutation,
  useCarTravelUpdateMutation,
} from "../../../services/providerApi";
import {
  SubmitButton,
  SubmitDiv,
} from "../../providerVehicleProfile/regularRates/regularRates.styles";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";
import { useAppSelector } from "../../../store";
// import { SubmitButton, SubmitDiv } from '../regularRates/regularRates.styles';

type Props = {
  driverProfile?: any;
};
const TravelService: FC<Props> = ({ driverProfile }) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [travelData, setTravelData] = useState<any>();
  const [driverInfo, setDriverInfo] = useState<any>();

  useEffect(() => {
    if (driverProfile && driverProfile?.data[0] !== undefined) {
      setDriverInfo(driverProfile?.data[0]);
    }
  }, [driverProfile]);

  useEffect(() => {
    if (driverProfile && driverProfile?.data[0]) {
      setTravelData(driverProfile?.data[0]?.travel);
    }
  }, [driverProfile]);

  const [driverDetails, setDriverDetails] = useState<any>();

  const [isTravelModal, setIsTravelModal] = useState(false);
  const [trip, setTrip] = useState({});
  const [isTravelDiscountVisible, setIsTravelDiscountVisible] = useState(false);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saved, setSaved] = useState(false);

  const openModal = (tripData: any) => {
    setIsTravelModal(true);
    setTrip(tripData);
    setShowSaveButton(true);
  };

  const [updateVehicleTravel, { data, isLoading, isSuccess, isError, error }] =
    useCarTravelUpdateMutation();
  const [
    addVehicleTravel,
    {
      data: travel,
      isLoading: travelLoading,
      isSuccess: travelSucess,
      isError: travelIsError,
      error: travelError,
    },
  ] = useCarTravelAddMutation();

  const saveData = () => {
    setIsSaveLoading(true);
    updateVehicleTravel(travelData)
      .then((response: any) => {
        setIsSaveLoading(false);
        setShowSaveButton(false);
        setSaved(true);
      })
      .catch((err: any) => {
        setIsSaveLoading(false);
      });
  };

  const differences: any = [];

  travelData?.forEach((item2: any) => {
    const foundItem = driverProfile?.data[0]?.travel.find(
      (item1: any) => item1?.title === item2?.title
    );

    if (!foundItem) {
      differences.push(item2);
    }
  });


  const addData = () => {
    setIsSaveLoading(true);
    addVehicleTravel(differences)
      .then((response: any) => {
        setIsSaveLoading(false);
        setShowSaveButton(false);
        setSaved(true);
      })
      .catch((err: any) => {
        setIsSaveLoading(false);
      });
  };

  return (
    <>
      <LabelDiv>
        <Label>Travel Rate</Label>
        <AddSpan
          onClick={() => {
            setIsTravelModal(true);
            setTrip("");
          }}
        >
          <Add>+</Add>
          <Text style={{ fontSize: 12 }}>Add New destination </Text>
        </AddSpan>
      </LabelDiv>

      {travelData?.filter((trip, index, self) => {
      const destination = trip.destination;
      return self.findIndex((t) => t.destination === destination) === index;
    })
    .map((uniqueTrip) => {
      const address = uniqueTrip.destination;
      const words = address.split(" ");
      const firstTwoWords = words.slice(0, 1).join(" ");

      return (
        <FLEXDIV key={uniqueTrip.id}>
          <INPUTDIV>
            <label
              style={{
                padding: "14px 0px",
                color: "#868686",
                display: "flex",
                width: "31%",
                fontSize: "15px",
                fontWeight: "normal",
              }}
              htmlFor=""
            >
              Travel Trip
            </label>
            <VerifyButton onClick={() => openModal(uniqueTrip)}>
              <P style={{ left: "0px", top: "-37px" }}>Select Location</P>
              <Text>{firstTwoWords}</Text>
              <DropDown>
                <IoIosArrowDown
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                />
              </DropDown>
            </VerifyButton>
            <BLACKDIV style={{ width: "34%" }}>
              <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
              
                
            <div style={{ width: "50%" }} onClick={() => openModal(uniqueTrip)}>

               <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="₦0.00"
                value={uniqueTrip?.price}
                // onValueChange={(e: any) => {
                //   handleTravelPrice(e);
                // }}
              />
            </div>

              <DISCOUNT
                onClick={() => {
                  setIsTravelDiscountVisible(true);
                  setTrip(uniqueTrip);
                  setShowSaveButton(true);
                }}
              >
                <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                {uniqueTrip.discount}%
              </DISCOUNT>
            </BLACKDIV>
          </INPUTDIV>
        </FLEXDIV>
      );
    })
}

      {isTravelModal && (
        <ModalOne
          vehicleDetails={driverInfo}
          setVehicleDetails={setDriverInfo}
          travelDetails={travelData}
          setIsVisibleModalOne={setIsTravelModal}
          setTravelData={setTravelData}
          trip={trip}
          setIsDiscountVisible={undefined}
          setShowSaveButton={setShowSaveButton}
        />
      )}

      {isTravelDiscountVisible && (
        <TravelDiscountModal
          trip={trip}
          setShowSaveButton={setShowSaveButton}
          setIsVisibleModalOne={undefined}
          setTravelData={setTravelData}
          travelDetails={travelData}
          vehicleDetails={driverInfo}
          setVehicleDetails={setDriverInfo}
          setIsDiscountVisible={setIsTravelDiscountVisible}
        />
      )}

      {travelData?.length > driverProfile?.data[0]?.travel?.length ? (
        <>
          {saved ? (
            ""
          ) : (
            <SubmitDiv>
              <SubmitButton onClick={() => addData()}>
                Add and Update
              </SubmitButton>
            </SubmitDiv>
          )}
        </>
      ) : (
        showSaveButton && (
          <SubmitDiv>
            {isSaveLoading ? (
              <SubmitButton style={{ backgroundColor: "#0AB249" }}>
                Updating Pricing...
              </SubmitButton>
            ) : (
              <SubmitButton
                style={{ backgroundColor: "#0AB249" }}
                onClick={() => saveData()}
              >
                Save and Update Travel
              </SubmitButton>
            )}
          </SubmitDiv>
        )
      )}
    </>
  );
};
export default TravelService;
