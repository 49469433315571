import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passengers: [],
  travels: null,
  garage: [],
};

export const travelSlice = createSlice({
  name: "travel",
  initialState,
  reducers: {
    setPassengers: (state: any, action: any) => {
      const updatedPassengers = state.passengers
        ? [...state.passengers, action.payload]
        : [action.payload];

      return { ...state, passengers: updatedPassengers };
    },
    clearPassengers: () => {
      return { ...initialState, passengers: [] };
    },

    deleteLonePassenger: (state, action) => {
      const passengerIdToDelete = action.payload;
      if (state.passengers) {
        const updatedPassengers = state.passengers.filter(
          (passenger: any) => passenger.id !== passengerIdToDelete
        );
        return { ...state, passengers: updatedPassengers };
      }

      return state;
    },

    setTravels: (state:any, action:any) => {
      return { ...state, travels: action.payload };
    },
    setGarage: (state:any, action:any) => {
      return { ...state, garage: action.payload };
    }
   
  },
});

const { reducer, actions } = travelSlice;
export const {
  setPassengers,
  clearPassengers,
  deleteLonePassenger,
  setTravels,
  setGarage
} = actions;
export default reducer;
