import styled from 'styled-components'

export const AlertPageOverall = styled.div `
    display: flex;
    padding: 100px 40px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
`

export const AlertHeader = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;

    & h1 {
        color: ${(props: any) => props.theme.text};
        font-weight: 700;
        font-size: 25px;
        margin: 0;
        text-align: center;
        white-space: nowrap;
    }

    @media only screen and (max-width: 454px) {
        h1 {
            font-size: 18px;
        }
    }
`