import React, { useState } from 'react'
import { FundWalletComponentWrap, FundOptionsWrap, FundWalletOption, PillRadioWrap, CardTxt, OptionsImgBox, H1 } from '../fundWalletOne/fundwalletone.styles'
import { PillRadioBtn } from '../optionModal/optionmodal.styles'

import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import bank from '../../assets/images/svg/bank.svg'

const PaymentMethodComponent = ({displaybox, handleShowComponent}:any) => {
    const [fundType, setFundType] = useState('')

    const handleFundType = (type:any) => {
        setFundType(type)

        if(type === 'card') {
            handleShowComponent('success')
        }else {
            handleShowComponent('failed')
        }
    }
  return (
    <FundWalletComponentWrap display={displaybox}>
        <H1>I would like to pay with...</H1>


        <FundOptionsWrap>
            <FundWalletOption onClick={() => handleFundType('card')}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={fundType === 'card' ? 'card' : ''} />

                    <CardTxt>
                        My Card
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'#FFE8B6'}>
                    <img src={master} />

                    <img src={visa} />
                </OptionsImgBox>
            </FundWalletOption>

            <FundWalletOption onClick={() => handleFundType('bank')}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={fundType === 'bank' ? 'bank' : ''} />

                    <CardTxt>
                        My Bank
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'rgba(0, 95, 238, 0.31)'}>
                    <img src={bank} />
                </OptionsImgBox>

            </FundWalletOption>
        </FundOptionsWrap>
    </FundWalletComponentWrap>
  )
}

export default PaymentMethodComponent