import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  Input,
  VerifyButton,
  Div,
  Label,
  InputField,
  FlexDiv,
  InputFields,
  Text,
  DropDown,
  INPUTS,
  DIV,
  TextField,
} from "./profileForms.styles";

import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import { IoLocationSharp } from "react-icons/io5";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { LocationInput } from "../../providerCarReg/thirdScreen/thirdScreen.styles";
import { useCarUpdateMutation } from "../../../services/providerApi";

import {
  Modal,
  Option,
} from "../../providerCarReg/firstScreen/firstScreen.styles";
import { FirstModal, SecondModal } from "./Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  vehicleProfile: any;
};
const VehicleProfileForms: FC<Props> = ({ vehicleProfile }) => {
  const [updateVehicle, { data, isLoading, isSuccess, isError, error }] =
    useCarUpdateMutation();
  const [isVisible, setIsVisible] = useState(false);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);

  // const vehicleData = vehicleProfile?.data[0];

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVisible((prev) => !prev);
  };
  const openCategoryModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsCategoryVisible((prev) => !prev);
  };

  const [vehicleInfo, setVehicleInfo] = useState(vehicleProfile?.data[0]);
  const category = vehicleProfile?.data[0]?.category;

  const [autocomplete, setAutocomplete] = useState<any>(null);

  const [location, setLocation] = useState(
    vehicleInfo?.vehicle_location || vehicleInfo?.vehicle_address
  );

  // const libraries: (
  //   | "places"
  //   | "drawing"
  //   | "geometry"
  //   | "localContext"
  //   | "visualization"
  // )[] = ["places", "geometry", "drawing"];

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
  //   libraries,
  // });

  let obj = {
    vehicle_id: vehicleInfo?.id,
    vehicle_name: vehicleInfo?.vehicle_name,
    brand_name: vehicleInfo?.brand_name,
    plate_number: vehicleInfo?.plate_number,
    num_passengers: vehicleInfo?.num_passengers,
    year: vehicleInfo?.year,
    vehicle_for: vehicleInfo?.vehicle_for,
    vehicle_location:
      vehicleInfo?.vehicle_location || vehicleInfo?.vehicle_address,
    vehicle_type: vehicleInfo?.vehicle_type,
    file: vehicleInfo?.image1,
    file2: vehicleInfo?.image2,
    file3: vehicleInfo?.image3,
    file5: vehicleInfo?.image5,
    latitude: vehicleInfo?.latitude,
    longitude: vehicleInfo?.longitude,
    air_con: vehicleInfo?.air_con,
    come_with_driver: vehicleInfo?.come_with_driver,
    travel_carpool: vehicleInfo?.travel_carpool,
    fuel_type: vehicleInfo?.fuel_type,
    vehicle_tracker: vehicleInfo?.vehicle_tracker,
    night_trips: vehicleInfo?.night_trips,
    self_drive: vehicleInfo?.self_drive,
    charge: vehicleInfo?.charge,
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setLocation(places);
      } else {
        setLocation(autocomplete.getPlace().name);
      }
    }

    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setLocation(places);
        setVehicleInfo({
          ...vehicleInfo,
          vehicle_location: places,
        });
        setVehicleInfo({
          ...vehicleInfo,
          vehicle_address: places,
        });

        setLocation(places);
        obj["vehicle_location"] = places;
        //@ts-ignore
        updateVehicle([obj]).then((response: any) => {
          if (response?.data.message === "success") {
            notifySuccess("vehicle location updated successfully");
          }
        });
      } else {
        setLocation(autocomplete.getPlace().name);
      }
    }
  };

  const address = vehicleInfo?.vehicle_location || vehicleInfo?.vehicle_address;
  const geocoder = new window.google.maps.Geocoder();

  useEffect(() => {
    geocoder.geocode({ address }, (result: any, status) => {
      if (status === "OK") {
        setVehicleInfo({
          ...vehicleInfo,
          latitude: result[0]?.geometry.location.lat(),
          longitude: result[0]?.geometry.location.lng(),
        });
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

    //@ts-ignore
  }, [address]);

  const handleName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      vehicle_name: event.target.value,
    });

    obj["vehicle_name"] = event.target.value;

    //@ts-ignore
    updateVehicle([obj]).then((response: any) => {
      if (response?.data.message === "success") {
        notifySuccess("vehicle Name updated successfully");
      }
    });
  };

  const handleBrandName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      brand_name: event.target.value,
    });

    obj["brand_name"] = event.target.value;

    //@ts-ignore
    updateVehicle([obj]).then((response: any) => {
      if (response?.data.message === "success") {
        notifySuccess("vehicle Brand updated successfully");
      }
    });
  };
  const handlePlateNum = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      plate_number: event.target.value,
    });

    obj["plate_number"] = event.target.value;

    //@ts-ignore
    updateVehicle([obj]).then((response: any) => {
      if (response?.data.message === "success") {
        notifySuccess("vehicle Plate Number updated successfully");
      }
    });
  };

  const handleSeatNum = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      num_passengers: event.target.value,
    });

    obj["num_passengers"] = event.target.value;

    //@ts-ignore
    updateVehicle([obj]).then((response: any) => {
      if (response?.data.message === "success") {
        notifySuccess("vehicle No of Passengers updated successfully");
      }
    });
  };

  const handleLocation = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      vehicle_location: event.target.value,
    });
    setVehicleInfo({
      ...vehicleInfo,
      vehicle_address: event.target.value,
    });

    setLocation(event.target.value);

    obj["vehicle_location"] = event.target.value;

    //@ts-ignore
    updateVehicle([obj]);
  };

  const handleYear = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      year: event.target.value,
    });

    obj["year"] = event.target.value;

    //@ts-ignore
    updateVehicle([obj]);
  };

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <Div>
        <Label>What is this Vehicle for?</Label>

        <Input>
          {category === "Bike Registration" ? (
            <input
              type="text"
              style={{
                fontSize: "16px",
                border: "none",
                outline: "none",
                width: "65%",
              }}
              value="Rental and Delivery"
            />
          ) : (
            <input
              type="text"
              style={{
                fontSize: "16px",
                border: "none",
                outline: "none",
                width: "65%",
              }}
              value={vehicleInfo?.vehicle_for || vehicleInfo?.vehicle_for}
            />
          )}

          <VerifyButton
            onClick={openModal}
            className="select"
            style={{ width: "35%" }}
          >
            <Text>Select </Text>
            <DropDown>
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
              />
            </DropDown>
          </VerifyButton>
        </Input>
      </Div>
      {isVisible && (
        <FirstModal
          vehicleInfo={vehicleInfo}
          setIsVisible={setIsVisible}
          setVehicleInfo={setVehicleInfo}
        />
      )}
      <Div>
        <Label>Vehicle Name</Label>

        <Input>
          <TextField
            type="text"
            value={vehicleInfo?.vehicle_name}
            onChange={handleName}
          />
          <VerifyButton
            // onClick={openModal}
            className="select"
            style={{ width: "35%" }}
          >
            <INPUTS
              type="text"
              onChange={handleYear}
              value={Number(vehicleInfo?.year)}
              style={{
                fontWeight: "bold",
                background: "transparent",
                padding: "0px 1px",
                height: "100%",
                fontSize: "15px",
                border: "none",
                outline: "none",
              }}
            />
            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  // setYear((prev: any) => prev + 1)
                  setVehicleInfo({
                    ...vehicleInfo,
                    year: Number(vehicleInfo?.year) + 1,
                  });

                  obj["year"] = Number(vehicleInfo?.year) + 1;

                  //@ts-ignore
                  updateVehicle([obj]);
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  setVehicleInfo({
                    ...vehicleInfo,
                    year: Number(vehicleInfo?.year) - 1,
                  });

                  obj["year"] = Number(vehicleInfo?.year) - 1;

                  //@ts-ignore
                  updateVehicle([obj]);
                }}
              />
            </DropDown>
          </VerifyButton>
        </Input>
      </Div>

      <Div>
        <Label> Brand Name</Label>

        <FlexDiv>
          <InputFields
            type="text"
            value={vehicleInfo?.brand_name}
            onChange={handleBrandName}
          />
          <InputFields
            type="text"
            value={vehicleInfo?.plate_number}
            onChange={handlePlateNum}
            placeholder="Plate Number"
          />
        </FlexDiv>
      </Div>

      <Div>
        <Label> Number of seats</Label>

        <Input>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              type="text"
              placeholder="0"
              value={vehicleInfo?.num_passengers}
              onChange={handleSeatNum}
            />
            {/* {vehicleInfo && <Label style={vehicleInfo?.num_passengers?.length >= 2 ? { zIndex: 0, position: 'absolute', left: '55px', color: '#000', fontSize: 14 } : { fontSize: 14, position: 'absolute', left: '40px', color: '#000' }}>

                            {
                                vehicleInfo?.num_passengers == '1' ? 'Seat' : ' Seats'
                            }
                        </Label>} */}
            {/* <Label style={{ color: '#000', fontSize: 14 }}>Seats</Label> */}
          </div>
          <VerifyButton
            onClick={openCategoryModal}
            className="select"
            style={{ width: "50%" }}
          >
            <Text>{vehicleInfo?.vehicle_type} </Text>
            <DropDown>
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
              />
            </DropDown>
          </VerifyButton>
        </Input>
      </Div>

      {isCategoryVisible && (
        <SecondModal
          vehicleInfo={vehicleInfo}
          setIsCategoryVisible={setIsCategoryVisible}
          setVehicleInfo={setVehicleInfo}
        />
      )}

      <Div>
        <Label> Vehicle Location</Label>

        <Input style={{ padding: "0px 12px" }}>
          <Autocomplete
            className="autocomplete-wrap w-full"
            onPlaceChanged={onPlaceChanged}
            onLoad={(value) => setAutocomplete(value)}
          >
            <LocationInput
              type="text"
              style={{ color: "#000" }}
              // onChange={handleLocation}
              onChange={(e: any) => {
                setLocation(e.target.value);
              }}
              value={location}
              // value={location}
            />
          </Autocomplete>

          <IoLocationSharp
            style={{ color: "#FFA100", fontSize: 30, fontWeight: 500 }}
          />
        </Input>
      </Div>
    </>
  );
};
export default VehicleProfileForms;
