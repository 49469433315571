import styled from 'styled-components'

export const Page = styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
    position: relative;
    min-height: 60vh;
    width: 100vw;
   
`
export const H1 = styled.h1`
color: #FFD58C;
font-size:1.5em;

`
export const H11 = styled.h1`
color: #fff;
font-size:1.5em;

`

export const P = styled.p`
color: #EBAF4A;
font-size:.8em;

`

