import React, { useState, useEffect, FC } from "react";
// import ToggleBar from '../../../components/toggleBar'

import { Box, Button, ButtonArea, H4, Label } from "./button.styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  useGetFilterVehiclesQuery,
  useGetPinnedVehiclesQuery,
} from "../../../services/adminApi";
import {
  setAllDriver,
  setFilterPagination,
  setFilterPaginationData,
  setProviders,
  setSearchData,
  setVehicles,
} from "../../../slices/admin";
import axios from "axios";
import { baseUrl } from "../../../services/providerApi";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

type Props = {
  isVehicle?: boolean;
  isDriver?: boolean;
  isProvider?: boolean;
  isBusiness?: boolean;
};
interface VehicleInterface {
  countAccepted?: number;
  countApproved?: number;
  countBlocked?: number;
  countPending?: number;
  countPinned?: number;
  countRejected?: number;
  countTrashed?: number;
}

const ButtonGroups: FC<Props> = ({
  isBusiness,
  isProvider,
  isDriver,
  isVehicle,
}) => {
  const dispatch: any = useAppDispatch();

  const [currentPage, setCurrentPage] = useState<string | null>();
  const [pinned, setPinned] = useState<string>("0");
  const [vehicleCount, setVehicleCount] = useState<VehicleInterface>({
    countAccepted: 0,
    countApproved: 0,
    countBlocked: 0,
    countPending: 0,
    countPinned: 0,
    countRejected: 0,
    countTrashed: 0,
  });
  const [driverCount, setDriverCount] = useState<VehicleInterface>();
  const [providerCount, setProviderCount] = useState<VehicleInterface>();

  const {
    data: pinnedData,
    isFetching: isFetchingPinned,
    refetch: refetchPinned,
  } = useGetPinnedVehiclesQuery(pinned);

  const user: any = useAppSelector((state: any) => state?.auth?.auth);

  const getFiltered = async (data: string) => {
    const promise = toast.loading("Loading...");
    dispatch(setSearchData(""));

    await axios
      .get(`${baseUrl}filter-vehicle-by-status/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          dispatch(setFilterPagination(true));
          dispatch(setFilterPaginationData(data));

          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getPin = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-pin-vehicle/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getBlocked = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-blocked-vehicle/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getFilteredDriver = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-driver-status/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setAllDriver(response?.data));
          dispatch(setFilterPagination(true));
          dispatch(setFilterPaginationData(data));

          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getPinDrivers = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-pin-vehicle/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getBlockedDrivers = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-blocked-vehicle/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getFilteredProvider = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-provider-by-status/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          // dispatch(setProviders(response?.data))
          dispatch(setFilterPagination(true));
          dispatch(setFilterPaginationData(data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getPinProviders = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-pin-vehicle/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getBlockedProviders = async (data: string) => {
    const promise = toast.loading("Loading...");
    await axios
      .get(`${baseUrl}filter-blocked-vehicle/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getFilteredCount = async () => {
    await axios
      .get(`${baseUrl}count-vehicle-status`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          // dispatch(setAllDriver(response?.data))
          setVehicleCount(response?.data?.data);
        }
      })
      .catch((err) => {});
  };

  const getDriverCount = async () => {
    await axios
      .get(`${baseUrl}count-driver-status`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          setDriverCount(response?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const getProviderCount = async () => {
    await axios
      .get(`${baseUrl}count-provider-status`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          // dispatch(setAllDriver(response?.data))
          setProviderCount(response?.data?.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getFilteredCount();
    getDriverCount();
    getProviderCount();
  }, []);

  return (
    <>
      <Toaster />
      {isVehicle && (
        <Box>
          <ButtonArea>
            <Button
              style={{ background: "#005FEE" }}
              onClick={() => getFiltered("Pending")}
            >
              Unscreened
            </Button>
            <Label>{vehicleCount?.countPending || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#06AE2B" }}
              onClick={() => getFiltered("Approved")}
            >
              Accepted
            </Button>
            <Label>{vehicleCount?.countApproved || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#CF0303" }}
              onClick={() => getFiltered("Rejected")}
            >
              Rejected
            </Button>
            <Label>{vehicleCount?.countRejected || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#AA6C05" }}
              onClick={() => getPin("1")}
            >
              Pinned
            </Button>
            <Label>{vehicleCount?.countPinned || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button onClick={() => getFiltered("Trashed")} style={{ background: "#330A6F" }}>Trash</Button>
            <Label>{vehicleCount?.countTrashed || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#000" }}
              onClick={() => getBlocked("Blocked")}
            >
              Blocked
            </Button>
            <Label>{vehicleCount?.countBlocked || 0}</Label>
          </ButtonArea>
        </Box>
      )}

      {isDriver && (
        <Box>
          <ButtonArea>
            <Button
              style={{ background: "#005FEE" }}
              onClick={() => getFilteredDriver("Pending")}
            >
              Unscreened
            </Button>
            <Label>{driverCount?.countPending || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#06AE2B" }}
              onClick={() => getFilteredDriver("Approved")}
            >
              Accepted
            </Button>
            <Label>{driverCount?.countApproved || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#CF0303" }}
              onClick={() => getFilteredDriver("Rejected")}
            >
              Rejected
            </Button>
            <Label>{driverCount?.countRejected || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#AA6C05" }}
              onClick={() => getPinDrivers("1")}
            >
              Pinned
            </Button>
            <Label>{driverCount?.countPinned || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button style={{ background: "#330A6F" }}>Trash</Button>
            <Label>{driverCount?.countTrashed || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#000" }}
              onClick={() => getBlockedDrivers("Blocked")}
            >
              Blocked
            </Button>
            <Label>{driverCount?.countBlocked || 0}</Label>
          </ButtonArea>
        </Box>
      )}

      {isProvider && (
        <Box>
          <ButtonArea>
            <Button
              style={{ background: "#005FEE" }}
              onClick={() => getFilteredProvider("Pending")}
            >
              Unscreened
            </Button>
            <Label>{providerCount?.countPending || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#06AE2B" }}
              onClick={() => getFilteredProvider("Approved")}
            >
              Accepted
            </Button>
            <Label>{providerCount?.countAccepted || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#CF0303" }}
              onClick={() => getFilteredProvider("Rejected")}
            >
              Rejected
            </Button>
            <Label>{providerCount?.countRejected || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#AA6C05" }}
              // onClick={() => getPinProviders('1')}
            >
              Pinned
            </Button>
            <Label>{providerCount?.countPinned || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button style={{ background: "#330A6F" }}>Trash</Button>
            <Label>{providerCount?.countTrashed || 0}</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#000" }}
              // onClick={() => getBlockedProviders('Blocked')}
            >
              Blocked
            </Button>
            <Label>{providerCount?.countBlocked || 0}</Label>
          </ButtonArea>
        </Box>
      )}

      {isBusiness && (
        <Box>
          <ButtonArea>
            <Button
              style={{ background: "#005FEE" }}
              onClick={() => getFilteredProvider("Pending")}
            >
              Unscreened
            </Button>
            <Label>0</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#06AE2B" }}
              onClick={() => getFilteredProvider("Approved")}
            >
              Accepted
            </Button>
            <Label>0</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#CF0303" }}
              onClick={() => getFilteredProvider("Rejected")}
            >
              Rejected
            </Button>
            <Label>0</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#AA6C05" }}
              // onClick={() => getPinProviders('1')}
            >
              Pinned
            </Button>
            <Label>0</Label>
          </ButtonArea>
          <ButtonArea>
            <Button style={{ background: "#330A6F" }}>Trash</Button>
            <Label>0</Label>
          </ButtonArea>
          <ButtonArea>
            <Button
              style={{ background: "#000" }}
              // onClick={() => getBlockedProviders('Blocked')}
            >
              Blocked
            </Button>
            <Label>0</Label>
          </ButtonArea>
        </Box>
      )}
    </>
  );
};

export default ButtonGroups;
