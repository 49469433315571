import styled from 'styled-components'
import { ModalBox } from '../optionModal/optionmodal.styles'

export const ModalFilterBox = styled(ModalBox) `
    padding: 5px;

    & .inner-modal-box {
        background-color: #fff;
        border-radius: 15px;
        padding: 15px;
    }

    @media only screen and (max-width: 484px) {
        width: 320px;
    }
`

export const ModaLHeader = styled.div`
    display: flex;
    gap: 15px;
    margin-bottom: 30px;

    @media only screen and (max-width: 484px) {
        margin-bottom: 15px;
    }
`

export const LocationInputBox = styled.div `
    padding: 15px;
    display: flex;
    background-color: #F1EFEF;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    flex: 0.7;

    & input {
        outline: none;
        border: none;
        background-color: #f1efef;
        width: 100%;
        color: #000;

        &::placeholder {
            color: #868686;
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 464px) {
        padding: 8px;

        img {
            width: 18px !important;
        }

        & input {
            font-size: 12px;

            &::placeholder {
                font-size: 10px;
            }
        }
    }
`

export const GreyInputWrap = styled.input `
    outline: none;
    border: none;
    background-color: #f1efef;
    padding: 15px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #000;
    width: 100%;
    flex: 0.3;

    &::placeholder {
        color: #868686;
        font-size: 12px;
        text-align: center;
    }

    @media only screen and (max-width: 464px) {
        padding: 10px 8px;
        font-size: 12px;

        &::placeholder {
            font-size: 10px;
        }
    }
`

export const RangeWrap = styled.div `
    flex: 0.5;
    & p {
        font-size: 14px;
        font-weight: 700;
        color: #000;
    }

    @media only screen and (max-width: 484px) {
        p {
            font-size: 13px;
            margin-top: 0;
            margin-bottom: 5px;
        }
    }
`

export const RangeSubWrap = styled.div` 
    display: flex;
    gap: 10px;
`

export const PriceRangeInput = styled.input `
    outline: none;
    border: none;
    background-color: #f1efef;
    padding: 15px 10px;
    border-radius: 8px;
    color: #000;
    width: 100%;

    &::placeholder {
        color: #868686;
        font-size: 12px;
        text-align: center;
    }
`

export const YearRange = styled.div `
    background-color: #f1efef;
    padding: 11px 10px;
    border-radius: 8px;
    color: #000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    & p {
        margin: 0;
        color: #868686;
        font-size: 12px;
    }

    @media only screen and (max-width: 484px) {
        padding: 8px;

    }
`

export const PriceRangeWrap = styled(YearRange) `
    justify-content: center;
    padding: 14.5px 10px;

    & input {
        width: 100% !important;
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
    }

    @media only screen and (max-width: 484px) {
        padding: 8px;
    }
` 

export const ControlWrap = styled.div `
    display: flex;
    flex-direction: column;
    gap: 5px;

    & img {
        width: 11px;
        cursor: pointer;
    }

    @media only screen and (max-width: 484px) {
        & img {
            width: 8px;
        }
    }
`

export const VehicleFilterOverall = styled.div `

    & h3 {
        font-size: 14px;
        font-weight: 700;
        color: #000;
    }
`

export const VehiclePillWrap = styled.div `
    display: flex;
    flex-wrap: wrap;
    row-gap: 14px;
    column-gap: 18px;
    margin-bottom: 20px;

    &.period-pill {
        column-gap: 0px;
    }
`

export const VehicleTypePill = styled.div `
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 20%;

    & p {
        color: #000;
        margin: 0;
        font-weight: 600;
        font-size: 10px;
        white-space: nowrap;
    }
`

export const FuelType = styled.div <{active?:boolean}> `
    background-color: ${(props:any) => props.active ? "#ffa100" : "#f1efef"};
    padding: 3px 10px;
    border-radius: 3px;
    font-size: 10px;
    color: ${(props:any) => props.active ? "#000" : "#868686"};
    cursor: pointer;
`

export const FilterFooter = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
`

export const ClearFilterBtn = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    background-color: #f1efef;
    color: #868686;
    cursor: pointer;
    flex: 0.45;
    font-size: 12px;

    @media only screen and (max-width: 484px) {
        padding: 8px;
    }
`

export const SeeResultBtn = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    flex: 0.45;
    font-size: 12px;

    @media only screen and (max-width: 484px) {
        padding: 8px;
    }
`