import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: #242321;
padding:2em;

`

export const KinLabel = styled.label`
background: #059BBF;
color:#fff;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:55px;
margin-bottom:55px;
`
export const OwnerLabel = styled.label`
background: #001AFF;
color:#fff;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:55px;
`

