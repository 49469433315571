import React, { useEffect, useState } from "react";
import google from "../../../assets/images/png/google.png";
import facebook from "../../../assets/images/png/facebook.png";
import user from "../../../assets/images/png/user-placeholder.png";
import closedEye from "../../../assets/images/svg/closed-eye.svg";
import openedEye from "../../../assets/images/svg/opened-eye.svg";
import {
  AuthDiv,
  Div,
  H3,
  SignupDiv,
  Flex,
  Input,
  P,
  Box,
  Button,
  H2,
  SocialBox,
  DisabledButton,
  Icon,
} from "./third.styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useCreateAccountMutation } from "../../../services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ToastContainer, toast } from "react-toastify";
import { useCreateFiatWalletMutation } from "../../../services/walletApi";
import { clearAuth, setAuth } from "../../../slices/auth";
import countryToCurrency, { Currencies, Countries } from "country-to-currency";

const AuthBoxThree = ({ component, setComponent }: any) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
 
  const [btnLoad, setBtnLoad] = useState(false);

  const userOTP = useAppSelector((state: any) => state?.otp?.otp);
  let userPhone = userOTP?.phoneNumber;
  let userEmail = userOTP?.email;

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [walletCreateSuccess, setWalletCreateSuccess] = useState(false);
  const [eyeOpen, setEyeOpen] = useState<any>("password");

  const regex: RegExp = /^[A-Za-z]+$/; // Regex to match alphabetic characters only

  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const [createAccount, { data, isLoading, isSuccess, isError, error }] =
    useCreateAccountMutation();
  const [
    createFiatWallet,
    {
      data: walletData,
      isSuccess: walletIsSuccess,
      isError: walletIsError,
      error: walletError,
    },
  ] = useCreateFiatWalletMutation();

  useEffect(() => {
    if (isSuccess) {
      // dispatch(setOTPData({ phoneNumber: phoneNumber }))
      // dispatch(setOTPData({ email: '' }))
      navigate("/choose-service");
      setBtnLoad(false);
      dispatch(setAuth(data?.data));
      setSignUpSuccess(true);
    }
    if (isError && "status" in error!) {
      notifyError(error?.data?.message);
    }

    if (isError && "status" in error!) {
      if (isError && error.data?.message?.email) {
        notifyError("The email has already been taken");
      }
    }
    if (isError && "status" in error!) {
      if (isError && error.data?.message?.phone_number) {
        notifyError("The Phone number has already been taken");
      }
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handleFirstname = (e: any) => {
    const value = e.target.value;
    if (regex.test(value) || value === "") {
      setFirstname(value);
    }
  };
  const handleLastname = (e: any) => {
    const value = e.target.value;
    if (regex.test(value) || value === "") {
      setLastname(value);
    }
  };
  const handlePhone = (e: any) => {
    setPhone(e);
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleCreateFiatWallet = () => {
    let formData: any = new FormData();
    formData.append("currency_code", "NGN");

    // convert the formdata to JSON data
    let object: any = {};
    formData.forEach((value: any, key: any) => (object[key] = value));
    let value: any = JSON.stringify(object);

    createFiatWallet(formData);
  };

  useEffect(() => {
    if (signUpSuccess) {
      handleCreateFiatWallet();
      setWalletCreateSuccess(true);
    }
  }, [signUpSuccess]);

  useEffect(() => {
    if (walletCreateSuccess) {
      dispatch(clearAuth({}));
    }
  }, [walletCreateSuccess]);

  let supportedCodes = ["NGN", "USD", "CAD"];
  let currencyCode = countryToCurrency[currentLocation];
  

  // create an account provided the email provided is valid
  const handleCreateAccount = () => {
    if (firstname?.length < 3) {
      notifyError("Firstname Must be greater than ");
    } else if (lastname === "") {
      notifyError("Lastname Empty");
    } else if ((phone?.length || userPhone?.length) < 10 ) {
      notifyError("Phone Number provided Invalid");
    } else if (password === "") {
      notifyError("Password Empty");
    } else {
      setBtnLoad(true);

      let formData: any = new FormData();
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("password", password);
      userEmail
        ? formData.append("email", userEmail)
        : formData.append("email", email);
      userPhone
        ? formData.append("phone_number", userPhone)
        : formData.append("phone_number", phone);
        
      formData.append("currency_code", !supportedCodes.includes(currencyCode)
      ? "USD"
      : countryToCurrency[currentLocation]);
     
      createAccount(formData).then((response) => {});
    }
  };

  const handlePrevComponent = () => {
    setComponent(3);
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />

      <AuthDiv>
        <Div style={{ marginTop: -80 }}>
        <Icon>
          <FiChevronLeft
            onClick={handlePrevComponent}
            style={{fontSize: "30px", fontWeight: "700" }}
          />
          </Icon>
          <H3>Create Account</H3>
        </Div>
        <SignupDiv>
          <Flex>
            <Input>
              <input
                type="text"
                style={{
                  width: "100%",
                  padding: " 15px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Firstname"
                value={firstname}
                onChange={handleFirstname}
              />
            </Input>
            <Input>
              <input
                type="text"
                style={{
                  width: "80%",
                  padding: "15px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Lastname"
                value={lastname}
                onChange={handleLastname}
              />
              <img
                src={user}
                alt=""
                style={{ width: "17px", height: "20px" }}
              />
            </Input>
          </Flex>

          {userPhone && (
            <Input>
              <input
                type="email"
                style={{
                  width: "100%",
                  padding: "13px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Email"
                autoComplete="off"
                value={email}
                onChange={handleEmail}
              />
            </Input>
          )}

          {userEmail && (
            <Input style={{ padding: "0px" }}>
              <PhoneInput
                defaultCountry={currentLocation}
                value={phone}
                onChange={handlePhone}
                international
                withCountryCallingCode
              />
            </Input>
          )}

          <Input>
            <input
              type={eyeOpen}
              style={{
                width: "90%",
                padding: "15px",
                border: "none",
                outline: "none",
              }}
              placeholder="Password"
              autoComplete="new-password"
              value={password}
              onChange={handlePassword}
            />

            <div style={{ marginTop: 5 }}>
              {eyeOpen === "password" ? (
                <img
                  src={openedEye}
                  onClick={() => setEyeOpen("text")}
                  alt="opened eye"
                />
              ) : (
                <img
                  src={closedEye}
                  onClick={() => setEyeOpen("password")}
                  alt="closed eye"
                />
              )}
            </div>
          </Input>
        </SignupDiv>

        {btnLoad ? (
          <DisabledButton>Please Wait...</DisabledButton>
        ) : (
          <Button onClick={handleCreateAccount}>Create Account</Button>
        )}
        <br />
        {/* <H2>
                    <span>
                        ...........................
                    </span>
                    &nbsp; OR  &nbsp;
                    <span>
                        ...........................
                    </span>
                </H2>

                <SocialBox>
                    <img src={google} alt="" style={{ width: '40px', marginLeft: '-12px' }} />
                    <p>Continue to  sign-up with Google</p>
                </SocialBox>

                <SocialBox>
                    <img src={facebook} alt="" style={{ width: '40px' }} />
                    <p>Continue to sign-up with Facebook</p>
                </SocialBox> */}

        <Box>
          By continuing, you agree to Roadlers &nbsp;
          <span style={{ color: "#FFA100" }}>Terms of Use.</span>
          &nbsp;Read our
          <span style={{ color: "#FFA100" }}> Privacy Policy.</span>
        </Box>
      </AuthDiv>
    </>
  );
};

export default AuthBoxThree;
