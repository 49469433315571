import styled from 'styled-components'

export const RoadlerHeader = styled.header `
    height: 60px;
    background-color: ${(props: any) => props.theme.headerBg};
    padding: 0 20px;
    position: relative;
    z-index: 50;

    &  nav {
            .logo-wrapper {
                flex: 5%;
            }

            ul {
                display: none;
            }

            .buttons-wrapper {
                flex: unset;
                align-items: center;

                .login, .signup {
                    display: none;
                }

                .cart-icon {
                    filter: ${(props: any) => props.theme.cartColor};
                }
            }

            .toggle-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 7px;
                img {
                    width: 30px;
                }
            }

            .mobile-menu {
                display: block;
                transition: right 0.3s ease;
            }
    }

    @media only screen and (min-width: 421px) {
        nav {
            .buttons-wrapper {
                flex: unset;

            }
        }
    }

    @media only screen and (min-width: 491px) {
        nav {
            .buttons-wrapper {
                flex: unset;
            }
        }
    }

    @media only screen and (min-width: 558px) {
        nav {
            .buttons-wrapper {
                flex: unset;

                .login {
                    padding: 5px 20px;
                }
            }
        }
    }


    @media only screen and (min-width: 768px) {
        padding: 0 20px;

        & nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            .logo-wrapper {
                flex: 20%;

                img {
                    height: 85px;
                }
            }
            
            ul {
                display: flex;
                justify-content: center;
                flex: 60%;
                padding: 0;
                gap: 30px;

                li {
                    list-style: none;

                    a {
                        text-decoration: none;
                        color: #000;
                        
                    }

                    .blog-link {
                        color: $roadlersTextYellow;
                        font-weight: 600;
                    }
                }
            }

            .buttons-wrapper {
                flex: 30%;
                display: flex;
                justify-content: center;
                gap: 20px;

                a {
                    display: block;
                }

                .login, .signup {
                    display: block;
                }

                .login {
                    outline: none;
                    background-color: $white;
                    padding: 5px 10px;
                    border: solid 1px #4F4F4F;
                    border-radius: 8px;
                    text-decoration: none;
                    color: #000;
                }

                .signup {
                    outline: none;
                    background-color: rgba(0, 0, 0, 0.99);
                    padding: 5px 15px;
                    border: solid 1px #4F4F4F;
                    color: $white;
                    border-radius: 8px;
                    text-decoration: none;
                }
            }

            .toggle-wrapper {
                display: none;
            }

            .mobile-menu {
                position: fixed;
                top:0;
                right: -800px;
                width: 80%;
                height: 100vh;
                z-index: 600;
                background-color: #000;
                border-bottom: solid 2px $roadlersTextYellow;
                color: #fff;
                display: none;
                overflow-y: scroll;

                .inner-wrapper {
                    padding: 0 30px;
                    position: relative;

                    .mobile-list {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 0;

                        li {
                            padding: 15px 0px;
                        }

                        li.accordion-overall {
                                .accordion {
                                margin: 1em 0;
                                border: 1px solid #ccc;
                            }
                            
                            .accordion-header {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 1em;
                                cursor: pointer;
                                background-color: #f1f1f1;
                            }
                            
                            .accordion-header h3 {
                                margin: 0;
                            }
                            
                            .accordion-header .icon-chevron-up,
                            .accordion-header .icon-chevron-down {
                                font-size: 1.5em;
                                transition: transform 0.3s ease;
                            }
                            
                            .accordion-header .icon-chevron-up {
                                transform: rotate(180deg);
                            }
                            
                            .accordion-content {
                                max-height: 0;
                                overflow: hidden;
                                transition: max-height 0.3s ease;
                            }
                            
                            .accordion-content.open {
                                max-height: 200px; /* any value greater than the content height */
                            }
                        }
                    }
                }
            }

            .mobile-menu.active-mobile {
                top: 0;
                right: 0;
                z-index: 600;
                transition: top 0.3s ease;
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px)  {
        nav {
            .logo-wrapper {
                flex: 5%;
            }

            ul {
                flex: 30%;
            }
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        nav {

            .logo-wrapper {
                flex: 10%;
            }

            ul {
                flex: 30%;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        nav {
            ul {
                flex: 40%;
            }
        }
    }

    @media only screen and (min-width: 1201px) and (max-width: 1599px) {
        nav {
            ul {
                flex: 40%;
            }
        }
    }






`

export const CancelMenu = styled.div `
    display: flex;
    justify-content: flex-start;
    margin-bottom: 25px;
    margin-top: 20px;

    img {
        width: 30px;
        cursor: pointer;
    }
`

export const HeaderOverlay = styled.div `
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 500;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: ${(props:any) => props.showMenu ? 'block' : 'none' };
`

export const MenuBar = styled.div `
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
        border: solid 1px ${(props: any) => props.theme.body};
        width: 100%;
        position: relative;
    }

    & div::before {
        content: '';
        position: absolute;
        border: solid 1px ${(props: any) => props.theme.body};
        top: -12px;
        left: -1px;
        width: 100%;
    }

    & div::after {
        content: '';
        position: absolute;
        border: solid 1px ${(props: any) => props.theme.body};
        bottom : -12px;
        left: -1px;
        width: 100%;
    }
`