import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { Page, H1, H11, P } from './landing.styles';

// type Props = {
//     showAuthBox: boolean;
//     setShowLanding: (val: boolean) => void;
//     setShowAuthBox: (val: boolean) => void;
// };

const Landing: FC = () => {

   
    return (
        <Page>
            <H1 style={{ marginTop: '7rem' }}>on Roadlers </H1>
            <H11>You Could Earn </H11>
            <span style={{ display: 'flex', alignItems: 'center', gap: '.4em', marginBottom: '1em' }}>
                <P>Upto</P> <H11>$150 </H11><P>daily</P>
            </span>
           
        </Page>


    )
}

export default Landing