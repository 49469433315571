import React from 'react'
import { BigBox, ExtrasActionWrap, SuccessImg, SuccessWalletWrap, TextBox } from './successwallet.styles'

import success from '../../assets/images/png/success-img.png'
import receipt from '../../assets/images/svg/dark-receipt.svg'
import clock from '../../assets/images/svg/clock-history.svg'
import { useNavigate } from 'react-router-dom'

const SuccessWalletComponent = ({displaybox, currency, amount, walletText}:any) => {
    const navigate = useNavigate()

  return (
    <SuccessWalletWrap display={displaybox}>
        <BigBox>
            <TextBox>
                <h2>NGN {amount}</h2>
                <p>{walletText}</p> 
            </TextBox>

            <SuccessImg src={success} />
        </BigBox>

        {/* <ExtrasActionWrap bg={'#C6E0FF'}>
            <p>Get Receipt</p>

            <img src={receipt}/>
        </ExtrasActionWrap> */}

        <ExtrasActionWrap bg={'#FFD58C'} onClick={() => navigate("/user-wallet-page")}>
            <p>Go to history</p>

            <img src={clock}/>
        </ExtrasActionWrap>
    </SuccessWalletWrap>
  )
}

export default SuccessWalletComponent