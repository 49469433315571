import { DropdownWrap, DropdownContent } from './../dropdown/dropdown.styles';
import styled from "styled-components"

export const InsuranceDropdownWrap = styled(DropdownWrap) `
    border-radius: 9px;
    margin-top: 5px;
    margin-bottom: 0px !important;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.55);
    box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.55);
    padding: 13px 14px;
    align-items: center;

    & p {
        margin-top: 0 !important;
        font-size: 12px !important;
        white-space: nowrap;
        color: #000 !important;
    }

    & img {
        width: 13px;
    }
`

export const InsuranceDropdownContent = styled(DropdownContent) `
        z-index: 15;
        top: 50px;
        height: ${(props:any) => props.active ? '135px' : '0px'};

`