import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

// import Header from '../../../components/providerHeader/header'

import {
  Page,
  DIV,
  Button,
  H3,
  P,
  LoaderContainer,
  DivContainer,
  Loader,
  Loader2,
} from "./profileSetup.styles";
import FirstScreen from "../../../components/providerAccountSetup/firstScreen/firstScreen";
import SecondScreen from "../../../components/providerAccountSetup/secondScreen/secondScreen";
import BusinessFirstScreen from "../../../components/providerAccountSetup/BusinessAccount/firstScreen";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";

import { FiChevronLeft } from "react-icons/fi";
import { setRegisteredVehicle } from "../../../slices/provider";
import Header from "../../../components/providerHeader";
import GuarantorScreen from "../../../components/providerAccountSetup/guarantor/guarantorScreen";
import axios from "axios";
import { baseUrl } from "../../../services/providerApi";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";

const ProfileSetup: FC = () => {
  const user: any = useAppSelector((state: any) => state?.auth?.auth);
  const accountType: any = useAppSelector(
    (state: any) => state?.provider.account_type
  );
  const service = useAppSelector(
    (state: any) => state?.provider?.service_offering
  );
  const [value, setValue] = useState();
  let [component, setComponent] = useState(1);
  let [category, setCategory] = useState("");

  const navigate = useNavigate();

  const getProviderDetails = async () => {
    await axios
      .get(`${baseUrl}provider-next-of-kin/${user?.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response: any) => {
        setCategory(response?.data[0]?.category);
        if (
          accountType === "Business" ||
          response?.data[0]?.category === "Business"
        ) {
          navigate("/profile-setup");
        } else {
          if (response?.data[0]?.gender?.length > 1) {
            if (service === "Driver") {
              // navigate('/add-guarantor')
              navigate("/profile-setup");
            } else {
              navigate("/next-of-kin");
            }
          } else {
            navigate("/profile-setup");
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProviderDetails();
  }, []);
  return (
    <>
      <Header />
      <Page>
        <PagePadding className="padding">
          <>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {component === 2 && (
                <FiChevronLeft
                  onClick={() => setComponent(1)}
                  style={{ marginLeft: "-7px", color: "#fff", fontSize: 30 }}
                />
              )}

              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {component === 1 && (
                  <FiChevronLeft
                    onClick={() => {
                      navigate("/car-registration");
                    }}
                    style={{ marginLeft: "-7px", color: "#fff", fontSize: 30 }}
                  />
                )}
                {category?.toLowerCase().includes("business") ? (
                  service === "Driver" ? (
                    <H3>Finish Driver's Profile</H3>
                  ) : (
                    <H3>Finish Owner's Profile</H3>
                  )
                ) : (
                  <H3>Finish Your Profile</H3>
                )}
              </div>
            </div>
            {component === 1 ? (
              <>
                {accountType === "Business" ? (
                  <P>Upload Owner's data page</P>
                ) : (
                  <P>Upload your ID data page</P>
                )}
              </>
            ) : (
              <P style={{ fontSize: "15px" }}>You are almost done</P>
            )}
            <LoaderContainer>
              <DivContainer>
                <Loader />
                <Loader />
              </DivContainer>
              <DivContainer>
                <Loader />
                <Loader />
              </DivContainer>
              <DivContainer>
                <Loader />
                <Loader2 />
              </DivContainer>
            </LoaderContainer>

            <FirstScreen userCategory={category} />
          </>
        </PagePadding>
      </Page>
    </>
  );
};

export default ProfileSetup;
