import styled from "styled-components";

export const FilterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content:center;
  align-items: center;
  margin-top:15px;
  margin-bottom:-20px;
  color:#000;
  & button {
    display: flex;
    width:40%;
    border-radius:10px;
    background:white;
    border:none;
    justify-content:center;
    align-items: center;
    cursor:pointer;
     & .img-icon{
        width:50px;
     }
  }
`;
export const BlogBox = styled.div`
 
  padding:0px 30px;
  
`;
export const LoadMore = styled.button`
text-align:center;
background:#ffa100;
color:#000;
border:none;
padding:10px 50px;
font-weight:600;
border-radius:7px;
`;
export const LoadMoreDiv = styled.div`
display:flex;
align-items:centter;
justify-content:center;
margin:0px 0px 40px 0px;
padding:0px 0px 100px 0px;
`;

export const BlogBanner = styled.div`
  width: 100%;
  height: 400px;
  padding: 30px;
  border-radius: 50px;
  & .img-banner {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
`;
