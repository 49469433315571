import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { IoIosArrowBack } from 'react-icons/io';
import { RiUser3Fill, RiArrowDownSLine } from 'react-icons/ri';
import { FaSuitcase } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import ID from '../../../assets/images/png/ID.png'
import ownership from '../../../assets/images/png/ownership.png'
import whiteMark from '../../../assets/images/png/whiteMark.png'
import pdff from '../../../assets/images/png/pdff.png'

import { CancelButton } from '../../../components/providerCarReg/carImageComponent/index.styles';
import {
    DOCDIV, DOC, DOC1, P2, H4, H44, Button
} from './secondScreen.styles';
import { useNavigate } from 'react-router-dom';
import { useUploadInsuranceMutation } from '../../../services/providerApi';
import { useAppSelector } from '../../../store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'

const SecondScreen: FC = () => {

    const navigate = useNavigate()
    const [btnLoad, setBtnLoad] = useState<boolean>(false)

    const [image1, setImage1] = useState<any>();
    const [image1Prev, setImage1Prev] = useState<any>()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileExt, setFileExt] = React.useState('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const file = event.currentTarget.files;
        if (file != null) {
            setImage1Prev(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                if (e.target != null) {
                    setImage1Prev(e.target.result);
                }
                setImage1(file[0]);
                let Doc = file[0].name
                let extension: any = Doc.split(".").pop();
                setFileExt(extension)
            };
            reader.readAsDataURL(file[0]);
        }

        // setFrontImage(false)
        // setBackImage(true)
    };
    const [insuranceMonth, setInsuranceMonth] = useState<any>();
    const [insuranceYear, setInsuranceYear] = useState<any>();

    //@ts-ignore
    const handleInsuranceMonth = (event) => {
        setInsuranceMonth(event.target.value)
    }
    //@ts-ignore
    const handleInsuranceYear = (event) => {
        setInsuranceYear(event.target.value)
    }
    let currentYear: any = new Date().getFullYear();




    const [setupInsurance, { data, isLoading, isSuccess, isError, error }] = useUploadInsuranceMutation()

    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            setBtnLoad(false)
            // navigate('/provider-dashboard')
            notifySuccess('Insurance Document Submitted')
            setTimeout(() => {
                navigate('/provider-dashboard'); // Redirect to "/profile-setup" after 3 seconds
            }, 1000);
        }


        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
            }
            setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data])

    const user = useAppSelector((state: any) => state?.auth?.auth)
    const vehicleId = useAppSelector((state: any) => state?.provider?.vehicle[0]?.id)


    const handleFinish = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let formData: any = new FormData()
        formData.append('user_id', user?.id)
        formData.append('vehicle_id', vehicleId)
        formData.append('expire_month', insuranceMonth)
        formData.append('expire_year', insuranceYear)
        formData.append('image', image1)


        setupInsurance(formData)

    }
    const alertError = () => {
        notifyError('Please Fill all Fields')
    }



    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    return (
        <>
            <ToastContainer />

            <DOCDIV style={{ marginTop: '60px' }}>
                <DOC>
                    <DOC1 onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.preventDefault();
                        if (fileInputRef.current != null) {
                            fileInputRef.current.click()
                        }
                    }}>

                        {
                            image1Prev ?
                                (fileExt !== 'pdf' ?
                                    < img src={image1Prev} alt="" style={{ objectFit: 'cover', width: '100%' }} /> :
                                    <img src={pdff} alt="" width={80} />
                                ) : <img src={ownership} alt="" width={80} />

                        }


                        {
                            image1Prev ?
                                <CancelButton>X</CancelButton> : <span style={{ position: 'absolute', right: '13px', top: '10px' }}>&uarr;</span>
                        }

                    </DOC1>
                    <P2>Insurance Document</P2>
                </DOC>

            </DOCDIV>

            <H44>Upload image or PDF</H44>

            <H4>Document Expiration Date</H4>
            <DOCDIV>
                <div style={{ width: '50%', borderRadius: '7px', border: '1px solid #fff' }}>
                    <select
                        value={insuranceMonth} onChange={handleInsuranceMonth}

                        name="" id="" style={{ background: '#1D1C1C', borderRadius: '6px', width: '95%', outline: 'none', border: 'none', fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '.7em' }}>


                        <option value="">Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                    </select>
                </div>
                <div style={{ width: '50%', borderRadius: '7px', border: '1px solid #fff' }}>
                    <select name="" id="" value={insuranceYear} onChange={handleInsuranceYear}
                        style={{ borderRadius: '6px', background: '#1D1C1C', color: '#fff', width: '95%', outline: 'none', border: 'none', fontSize: '14px', fontWeight: 'bold', padding: '.7em' }}>

                        <option value="">Year </option>
                        <option value={currentYear}>{currentYear} </option>
                        <option value={currentYear + 1}>{currentYear + 1} </option>
                        <option value={currentYear + 2}>{currentYear + 2} </option>
                        <option value={currentYear + 3}>{currentYear + 3} </option>
                        <option value={currentYear + 4}>{currentYear + 4} </option>
                        <option value={currentYear + 5}>{currentYear + 5} </option>
                        <option value={currentYear + 6}>{currentYear + 6} </option>
                        <option value={currentYear + 7}>{currentYear + 7} </option>
                        <option value={currentYear + 8}>{currentYear + 8} </option>
                        <option value={currentYear + 9}>{currentYear + 9} </option>
                        <option value={currentYear + 10}>{currentYear + 10} </option>
                    </select>

                </div>

            </DOCDIV>


            {btnLoad ?
                <Button onClick={handleFinish}>
                    <h4>Please Wait...</h4>

                </Button> :
                <>
                    {
                        image1 === undefined || insuranceMonth === undefined || insuranceYear === undefined ?
                            <Button onClick={() => alertError()} style={{ background: 'darkgray', color: 'white' }}>
                                <h4>OK</h4>
                                <img src={whiteMark} alt="" width={28} height={23} />
                            </Button> :
                            <Button onClick={handleFinish}>
                                <h4>OK</h4>
                                <img src={whiteMark} alt="" width={28} height={23} />
                            </Button>
                    }

                </>
            }


            <input type="file" style={{ display: 'none' }} ref={fileInputRef} accept="image/jpeg,image/jpg,image/png,application/pdf" onChange={onChange} />

        </>

    )
}

export default SecondScreen