import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { getCode } from "iso-3166-1-alpha-2";
import ReactCountryFlag from "react-country-flag";

import {
  Div,
  H3,
  FlexDiv,
  Label,
  BalanceDiv,
  H4,
  H5,
  FlexBetween,
  FlexBox,
  Icondiv,
} from "./providerWallet.styles";

import wallet from "../../assets/images/png/walletMoney.png";
import zip from "../../assets/images/png/zip.png";
import FundWallet from "../../assets/images/png/fundWallet.png";
import withdrawal from "../../assets/images/png/withdrawal.png";
import walletLine from "../../assets/images/png/walletLine.png";
import walletLines from "../../assets/images/png/walletLines.png";
import walletLiness from "../../assets/images/png/walletLiness.png";
import swap from "../../assets/images/png/swap.png";
import transfer from "../../assets/images/png/transfer.png";
import collectMoney from "../../assets/images/png/collectMoney.png";
import phebe from "../../assets/images/png/phebe.png";
import canada from "../../assets/images/png/canada.png";
import WithdrawCurrencyModal from "../withdrawModal";
import WalletModal from "../walletModal";
import TransferWalletModal from "../transferWalletModal";
import SwapCurrencyModal from "../swapModal";
import CollectMoneyModal from "../collectMoneyModal";
import WalletCard from "../walletCardComponent";
import { WalletCardWrap } from "../createWalletCard/createWalletCard.styles";
import {
  useCreateVirtualWalletMutation,
  useGetWalletByIdQuery,
  useTopUpWalletMutation,
  useWalletTransferMutation,
  useWithdrawWalletMutation,
} from "../../services/walletApi";
import { useAppSelector } from "../../store";
import CreateWalletCard from "../createWalletCard";

const ProviderWallet: FC = () => {
  const userCountry = useAppSelector(
    (state: any) => state?.provider?.service_provider.country
  );

  const countryCode = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );

  const [selected, setSelected] = useState("");

  const { id, firstname, lastname } = useAppSelector(
    (state: any) => state?.auth?.auth
  );

  const [changeTab, setChangeTab] = useState<any>("tab1");
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [transferWalletModal, setTransferWalletModal] = useState(false);
  const [swapWalletModal, setSwapWalletModal] = useState(false);
  const [withdrawWalletModal, setWithdrawWalletModal] = useState(false);
  const [collectMoneyModal, setCollectMoneyModal] = useState(false);
  const [createWalletModal, setCreateWalletModal] = useState(false);
  const [bvn, setBvn] = useState<string>("");
  const [walletDetails, setWalletDetails] = useState<any>([]);
  const [bvnError, setBvnError] = useState("");
  const [topUpAmount, setTopUpAmount] = useState("");
  const [topUpErrorMessage, setTopUpErrorMessage] = useState("");
  const [transferErrorMessage, setTransferErrorMessage] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [creditWalletName, setCreditWalletName] = useState("");
  const [transferPassword, setTransferPassword] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawBank, setWithdrawBank] = useState("");
  const [withdrawAccountNo, setWithdrawAccountNo] = useState("");
  const [withdrawAccountName, setWithdrawAccountName] = useState("");
  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState("");

  const [createVirtualWallet, { data, isLoading, isSuccess, isError, error }] =
    useCreateVirtualWalletMutation();

  const [
    topUpWallet,
    {
      data: topUpData,
      isLoading: isTopUpLoading,
      isSuccess: isTopUpSuccess,
      isError: isTopUpError,
      error: topUpError,
    },
  ] = useTopUpWalletMutation();

  const [
    walletTransfer,
    {
      data: transferData,
      isLoading: isTransferLoading,
      isSuccess: isTransferSuccess,
      isError: isTransferError,
      error: transferError,
    },
  ] = useWalletTransferMutation();

  const [
    withdrawWallet,
    {
      data: withdrawData,
      isLoading: isWithdrawLoading,
      isSuccess: isWithdrawSuccess,
      isError: isWithdrawError,
      error: withdrawError,
    },
  ] = useWithdrawWalletMutation();

  const {
    data: walletData,
    refetch: refetchWallet,
    isSuccess: walletSuccess,
  } = useGetWalletByIdQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
console.log("walletData",walletData)
  useEffect(() => {
    refetchWallet();
  }, []);

  useEffect(() => {
    if (walletSuccess) {
      setWalletDetails(walletData?.data);
    }
  }, []);

  // USEFFECT FOR CREATE VIRTUAL WALLET
  useEffect(() => {
    if (isSuccess) {
      setShowWalletModal(true);
      setCreateWalletModal(false);
    }
    if (isError && "status" in error!) {
      setBvnError(error.data.message);

      setTimeout(() => {
        setBvnError("");
      }, 3000);
    }
  }, [isLoading, isSuccess, isError, data]);

  // USEFFECT FOR WALLET TOPUP
  useEffect(() => {
    if (isTopUpSuccess) {
      window.open(topUpData?.data?.authorization_url);
      setTopUpAmount("");
    }

    if (isTopUpError && "status" in topUpError!) {
      setTopUpErrorMessage(topUpError.data.message);

      setTimeout(() => {
        setTopUpErrorMessage("");
      }, 3000);
    }
  }, [topUpData, isTopUpLoading, isTopUpError, isTopUpSuccess, topUpError]);

  // USEFFECT FOR WALLET TRANSFER
  useEffect(() => {
    if (isTransferSuccess) {
      window.open(transferData?.data?.authorization_url);
      setTransferAmount("");
      setCreditWalletName("");
      setTransferPassword("");
    }

    if (isTransferError && "status" in transferError!) {
      setTransferErrorMessage(transferError.data.message);

      setTimeout(() => {
        setTransferErrorMessage("");
      }, 3000);
    }
  }, [
    transferData,
    isTransferLoading,
    isTransferError,
    isTransferSuccess,
    transferError,
  ]);

  // USEFFECT FOR WITHDRAWAL
  useEffect(() => {
    if (isWithdrawSuccess) {
      console.log(withdrawData?.data);
      setWithdrawAmount("");
      setWithdrawAccountName("");
      setWithdrawAccountNo("");
      setWithdrawBank("");
    }

    if (isWithdrawError && "status" in withdrawError!) {
      setWithdrawErrorMessage(withdrawError.data.message);

      setTimeout(() => {
        setWithdrawErrorMessage("");
      }, 3000);
    }
  }, [
    withdrawData,
    isWithdrawLoading,
    isWithdrawError,
    isWithdrawSuccess,
    withdrawError,
  ]);

  console.log(walletDetails, "ede");

  const removeWalletModal = () => {
    setShowWalletModal(false);
    setTransferWalletModal(false);
    setSwapWalletModal(false);
    setWithdrawWalletModal(false);
    setCollectMoneyModal(false);
    setCreateWalletModal(false);
  };

  const CreateVirtualWallet = () => {
    let formData: any = new FormData();

    if (bvn?.length === 11) {
      formData.append("firstname", firstname);
      formData.append("first_name", firstname);
      formData.append("lastname", lastname);
      formData.append("last_name", lastname);
      formData.append("bvn", bvn);

      createVirtualWallet(formData);
    } else if (!bvn) {
      setBvnError("Please type in your bvn");

      setTimeout(() => {
        setBvnError("");
      }, 3000);
    } else {
      setBvnError("Please type in correct bvn");

      setTimeout(() => {
        setBvnError("");
      }, 3000);
    }
  };

  const handleWalletModalDisplay = () => {
    if (!walletDetails?.virtual_account_number) {
      setCreateWalletModal(true);
    } else {
      setShowWalletModal(true);
    }
  };

  // TOPUP WALLET FUNCTION
  const onPay = () => {
    let formData: any = new FormData();

    formData.append("currency", "NGN");
    formData.append("amount", topUpAmount);

    topUpWallet(formData);
    console.log("pay");
  };

  // WALLET TRANSFER FUNCTION
  const onWalletTransfer = () => {
    let formData: any = new FormData();

    formData.append("creditWalletUsername", creditWalletName);
    formData.append("debitWalletUsername", walletDetails?.user_name);
    formData.append("password", transferPassword);
    formData.append("amount", Number(transferAmount));
    formData.append("currency", "NGN");

    walletTransfer(formData);
    console.log("pay");
  };

  // WITHDRAW FUNCTION
  const onWithdraw = () => {
    let formData: any = new FormData();

    formData.append("withdrawal_amount", withdrawAmount);
    formData.append("provider_username", walletDetails?.user_name);
    formData.append("bank_name", withdrawBank);
    formData.append("account_number", withdrawAccountNo);
    formData.append("account_name", withdrawAccountName);

    withdrawWallet(formData);
  };

  return (
    <>
      <WalletModal
        walletModalActive={showWalletModal}
        removeWalletModal={removeWalletModal}
      />
      <TransferWalletModal
        showTransferModal={transferWalletModal}
        removeWalletModal={removeWalletModal}
      />
      <SwapCurrencyModal
        showSwapModal={swapWalletModal}
        removeWalletModal={removeWalletModal}
      />
      <WithdrawCurrencyModal
        showWithdrawModal={withdrawWalletModal}
        removeWalletModal={removeWalletModal}
      />
      <CollectMoneyModal
        showCollectModal={collectMoneyModal}
        removeWalletModal={removeWalletModal}
      />
      <CreateWalletCard
        setCreateWalletModal={setCreateWalletModal}
        creatWalletActive={createWalletModal}
        setBvn={setBvn}
        handleCreateVirtualWallet={CreateVirtualWallet}
        bvnError={bvnError}
      />

      <Div style={{ marginTop: "90px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <img src={phebe} alt="" width={50} height={50} />
          <div>
            <H3 style={{ margin: "0" }}>Hello {firstname}</H3>
            <FlexDiv>
              <Label>Welcome to your Wallet</Label>
            </FlexDiv>
          </div>

          <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
              fontSize: "1.5em",
              lineHeight: "2em",
            }}
          />
        </div>

        <BalanceDiv>
          <img
            width={120}
            src={zip}
            style={{ position: "absolute", left: -15, top: -30 }}
          />
          <img
            width={120}
            src={wallet}
            style={{ position: "absolute", right: 10, top: 19 }}
          />
          <Label
            style={{
              position: "absolute",
              top: 68,
              left: 20,
              fontSize: "14px",
            }}
          >
            My Income
          </Label>
          <H4 style={{ position: "absolute", top: 73, left: 8 }}>
            {walletDetails?.currency_code || "NGN"}{" "}
            {Number(walletDetails?.balance)?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "0.00"}
          </H4>
          <FlexBetween
            onClick={() => {
              // setShowWalletModal(true)
              handleWalletModalDisplay();
            }}
          >
            <img src={FundWallet} width="30px" />
            <H5>Fund Wallet</H5>
          </FlexBetween>
        </BalanceDiv>

        {/* <BalanceDiv>
                    <WalletCard 
                    handleWalletModal={setShowWalletModal} 
                    handleWalletModalDisplay={handleWalletModalDisplay}
                        currency={walletDetails?.currency_code} 
                        amount={walletDetails?.balance} />
                </BalanceDiv> */}
      </Div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
          gap: "5px",
        }}
      >
        <img src={walletLine} alt="" />
        <img src={walletLines} alt="" />
        <img src={walletLiness} alt="" />
      </div>

      <Div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "42%",
            background: "#09051C",
            color: "#fff",
            padding: ".8em",
            border: "none",
            borderRadius: "5px",
          }}
        >
          <select
            name=""
            id=""
            style={{
              fontSize: "14px",
              outline: "none",
              background: "transparent",
              color: "#fff",
              border: "none",
            }}
          >
            <option value="">Select Currency </option>
          </select>
        </div>

        <FlexBox
          style={{ width: "42%" }}
          onClick={() => setWithdrawWalletModal(true)}
        >
          <img src={withdrawal} width="30px" />
          <H5>Withdraw</H5>
        </FlexBox>
      </Div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            width: "90%",
          }}
        >
          <Icondiv onClick={() => setTransferWalletModal(true)}>
            <img src={transfer} width="30px" />
            <Label
              style={{
                fontSize: "10px",
                position: "absolute",
                bottom: "-20px",
              }}
            >
              Transfer
            </Label>
          </Icondiv>
          <Icondiv onClick={() => setSwapWalletModal(true)}>
            <img src={swap} width="30px" />
            <Label
              style={{
                fontSize: "10px",
                position: "absolute",
                bottom: "-20px",
              }}
            >
              Swap
            </Label>
          </Icondiv>

          <FlexBox
            onClick={() => setCollectMoneyModal(true)}
            style={{
              width: "60%",
              background: "#09051C",
              gap: "5px",
              padding: "24px .4em",
            }}
          >
            <img src={collectMoney} width="40px" />
            <H5 style={{ color: "#fff" }}>Collect Money</H5>
          </FlexBox>
        </Div>
      </div>
    </>
  );
};
export default ProviderWallet;
