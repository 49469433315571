import styled from 'styled-components'

export const RequestDiv = styled.div`
background:#fff;

display:flex;
border-radius:10px;
overflow:hidden;
padding:1.2em 1em;
gap:10px;
position:relative;
width:92%;
`
export const Div = styled.div`
background:#fff;
display:flex;
flex-direction:column;
color:#000;
gap:8px;
margin-top:25px;

`

export const ImageDiv = styled.div`
align-items:center;
justify-content:center;
position:absolute;
top:0;
left:0px;
background:#fff;
border-radius:5px 0px 5px 0px;

`
export const Span = styled.div`
display:flex;
align-items:center;
gap:10px;
color: #6A6A6A;

`

export const FlexDiv = styled.div`
display:flex;
align-items:end;
justify-content:space-between;
width:100%;

`
export const H6 = styled.h6`
font-weight:700;
font-size:14px;
margin-bottom:0;
color: #868686;

`
export const H5= styled.h5`
font-weight:700;
margin-bottom:0;
color: #000;
margin-top:0;
`

export const AssignButton = styled.button`
font-weight:bold;
background: #09051C;
font-size:12px;
padding:.6em .8em;
border-radius:3px;
color:#fff;
outline:none;
border:none;
width:80px;
`

export const CallDiv = styled.div`
display:flex;
align-items:center;
justify-content:center;
gap:7px;
`
export const CallButton = styled.button`
font-weight:bold;
background: #09051C;
font-size:14px;
padding:.6em .8em;
border-radius:5px;
color:#fff;
outline:none;
border:none;
display:flex;
align-items:center;
justify-content:center;
`
export const AcceptButton = styled.button`
font-weight:700;
background: #63E52A;
font-size:12px;
padding:.6em .8em;
border-radius:3px;
color:#000;
width:80px;
outline:none;
border:none;
`
export const CancelButton = styled.button`
font-weight:700;
background: #D52B1E;
font-size:12px;
padding:.6em .8em;
border-radius:3px;
color:#fff;
width:80px;
outline:none;
border:none;
`
export const StartButton = styled.button`
font-weight:700;
background: #005FEE;
font-size:12px;
padding:.6em .8em;
border-radius:3px;
color:#fff;
width:80px;
outline:none;
border:none;
`
export const FinishButton = styled.button`
font-weight:700;
background: #000;
font-size:12px;
padding:.6em .8em;
border-radius:3px;
color:#fff;
width:80px;
outline:none;
border:none;
`


export const Label = styled.label`
font-weight:bold;
font-size:11px;
border-radius:3px;
color: #6A6A6A;


`
export const IDDiv = styled.div`
display:flex;
flex-direction:column;
position:absolute;
left:100px;
top:15px;
gap:5px;
`
export const StatusDiv = styled.div`
display:flex;
background: #E70909;
position:absolute;
left:15px;
top:-21px;
align-items:center;
justify-content:center;
width:90px;
padding:.3em .9em;
border-radius:7px 7px 0px 0px;
font-size:13px;
font-weight:700;
`
export const Box = styled.div`
height:130px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
`
export const UserDiv = styled.div`
padding:10px;
border-radius:50%;
display:flex;
align-items:center;
justify-content:center;
position:absolute;
right:45px;
top:-23px;
z-index:10;
`
export const StatusLabel = styled.label`
font-size:10px;
position:absolute;
right:100px;
top:-18px;
color:#fff;
`
export const StatusOption = styled.div`
font-size:30px;
position:absolute;
right:12px;
top:-23px;
color:#fff;
`

