import styled from "styled-components"

export const WalletCardWrap = styled.div `
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 7px 4px rgba(0,0,0,0.3);
    background-color: #09051C;
    z-index: 500;
    width: 400px;

    @media only screen and (max-width: 454px)  {
        width: 340px;

        &.cancel-card {
            width: 300px;
        }
    }


`

export const ImageContainer = styled.div `
    border-radius: inherit;
    width: 100%;
    
    img {
        width: 100%;
        overflow: hidden;
        display: block;
    }
` 

export const ActionsContainer = styled.div `
    border-radius: inherit;
    width: 100%;
    background-color: #fff;

    .inner-action-wrap {
        padding: 20px;
        display: block;

        &.cancel-modal {
            padding: 20px 35px;

            @media only screen and (max-width: 454px)  {
                padding: 15px 25px;
            } 
        }

        .header-section {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            color: #fff;
            font-weight: 700;
            font-size: 15px;
            text-align: center;
            margin-bottom: 20px;
            padding: 12px;
            background-color: #DA6317;
            width: 150px;
            white-space: nowrap;

            @media only screen and (max-width: 454px)  {
                width: 130px;
                font-size: 11px;
                padding: 9px 12px;
            } 

        }

        .content-section {
            margin-bottom: 20px;
            p {
                margin: 0;
                color: #000;
                font-size: 16px;            
            }

            @media only screen and (max-width: 454px)  {
                p {
                    font-size: 10px;
                }
            } 
        } 

        .input-section {
            input {
                border: none;
                outline: none;
                border-radius: 8px;
                box-shadow: 0px 0px 7px 4px rgba(0,0,0,0.14);
                width: 100%;
                padding: 13px 15px;
                color: #000;

                &::placeholder {
                    color; #868686C7 ;
                    font-weight: 500;
                    font-size: 13px;
                }
            }

            .bvn-error {
                font-size: 11px;
                color: #FF3A3A;
                margin-top: 5px
            }

            @media only screen and (max-width: 454px)  {
                input {
                    font-size: 10px;
                    padding: 10px 15px;

                    &::placeholder {
                        font-size: 11px;
                    }
                }

                .bvn-error {
                    font-size: 8px;
                    margin-top: 3px;
                }
            } 
        }

        .btn-overall {
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;
            column-gap: 15px;
            
            .create-btn {
                font-size: 14px;
                color: #000;
                font-weight: 600;
                padding: 11px;
                border-radius: 4px;
                cursor: pointer;
                background-color: #ffa100;
                min-width: 120px;
                text-align: center;

                &.back-btn {
                    background-color: #09051C;
                    color: #fff;
                }
            }


            @media only screen and (max-width: 454px)  {
                .create-btn {
                    font-size: 11px;
                    padding: 8px 11px;
                    min-width: 100px;
                }
            }
        }

    }

`