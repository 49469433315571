import React from "react";
import {
  Card,
  CardBtn,
  DriverImg,
  H1,
  ImgCard,
  P,
  TextCard,
  TextCardInner,
} from "./drivercard.styles";
import fullDriver from "../../assets/images/svg/fulltime-driver.svg";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const DriverRequestCard = ({
  driverTypeImg,
  cardHeader,
  cardContent,
  imgBg,
  buttonText,
  height,
}: any) => {
  return (
    <Card>
      <ImgCard imgBg={imgBg}>
        <DriverImg src={driverTypeImg} />
      </ImgCard>

      <TextCard height={height}>
        <TextCardInner>
          <div style={{ padding: "" }}>
            <H1>{cardHeader}</H1>
            <P>{cardContent}</P>
          </div>

          <div
            className="access-offer-wrap"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <HashLink to="/#vehicles">
              <CardBtn>{buttonText}</CardBtn>
            </HashLink>
          </div>
        </TextCardInner>
      </TextCard>
    </Card>
  );
};

export default DriverRequestCard;
