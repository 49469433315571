import { WhereOptionPill } from "./../../../components/locationModal/locationmodal.styles";
import { SaveBtn } from "./../../../components/extrasModal/extrasmodal.styles";
import styled from "styled-components";
import { BoxExtras } from "../Homepage/homepage.styles";
import { TicketInfoBox } from "../TicketPage/ticketpage.styles";

export const GarageHead = styled.div`
  position: relative;
  /* height: 300px; */
  width: 100%;
  /* overflow: hidden; */

  margin-bottom: 20px;
  height: 400px;
  padding: 0px 80px;
  @media only screen and (max-width: 484px) {
    padding: 0px;
    height: 250px;
  }
`;
export const GarageHeadImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
`;

export const GarageHeadTextWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media only screen and (max-width: 484px) {
    flex-direction: column;
  }

  & h3 {
    font-weight: 800;
    color: #ffa100;
    text-align: center;
    font-size: 55px;
    margin: 0;
    @media only screen and (min-width: 768px) and (max-width: 1025px) {
      font-size: 45px;
    }
    @media only screen and (max-width: 451px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 25px;
    }
  }

  & h1 {
    font-weight: 800;
    color: #fff;
    font-size: 55px;
    text-align: center;
    margin: 0;
    @media only screen and (min-width: 768px) and (max-width: 1025px) {
      font-size: 45px;
    }
    @media only screen and (max-width: 451px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 25px;
    }
  }
`;

export const GarageHeaderImgWrap = styled.div`
  margin-top: 25px;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  gap: 70px;
  z-index: 5;
  /* bottom: -50px; */
  left: 50%;
`;

export const VehicleBox = styled.div`
  & p {
    font-size: 12px;
    font-weight: 600;
    color: ${(props: any) => props.theme.text};
    margin: 0;
    text-align: center;
    margin-top: 15px;
  }
`;

export const VehicleImgBox = styled.div`
  background-color: ${(props: any) => props.boxBg};
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ffa100;
  border-radius: 9px;
  padding: 15px;

  & img {
    width: 40px;
  }
`;

export const TicketSearchWrap = styled.div`
  margin: 130px 0px 0px;
  padding: 0 250px;
  @media only screen and (min-width: 768px) and (max-width: 1025px) {
    padding: 0 150px;
  }
  @media only screen and (max-width: 484px) {
    padding: 0 40px;
  }

  & .subwrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    div {
      width: 100%;
    }
  }

  & .ticket-type-wrap {
    display: flex;
    gap: 30px;
    margin-top: 25px;
  }
`;

export const InputFlexWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${(props: any) => props.theme.text};
  gap: 5px;
  border-radius: 9px;

  .calendar {
    color: ${(props: any) => props.theme.body};
    position: absolute;
  }
  & img {
    filter: ${(props: any) => props.theme.cartColor};
  }

  & input {
    outline: none;
    border: none;
    width: 100%;
    background-color: transparent;
    color: ${(props: any) => props.theme.cartFooterBg};
    padding: 5px;
    &::placeholder {
      color: #868686;
      font-size: 13px;
    }
  }
`;

export const InputDateWrap = styled(InputFlexWrap)`
  flex: 0.3;

  & input::placeholder {
    color: #797979;
    font-size: 12px;
  }
`;

export const SearchTicketBtn = styled(SaveBtn)`
  border-radius: 9px;
  width: 50%;
  cursor: pointer;
  padding: 15px 15px;
  margin-top: 20px;
`;
export const TicketTypePill = styled(WhereOptionPill)`
  & div {
    border: solid 3px ${(props: any) => props.theme.text};
  }

  & p {
    color: ${(props: any) => props.theme.text};
  }
`;

export const LatestListingWrap = styled(BoxExtras)`
  padding: 0px 250px;

  @media only screen and (min-width: 768px) and (max-width: 1025px) {
    padding: 0 150px;
  }
  @media only screen and (max-width: 484px) {
    padding: 0px 30px;
  }
  @media only screen and (max-width: 390px) {
    padding: 0px 15px;
  }

  & a {
    text-decoration: none;
    color: unset;
  }
  .subsection-header {
    margin: -10px 0px 180px 0px;
  }
  & .action-div {
    margin-top: -100px;
    margin-bottom: 100px;
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    width: 100%;

    .edit {
      width: 100px;
      background: black;
      padding: 5px 8px;
      color: white;
      font-weight: bold;
      border: none;
      outline: none;
      border-radius: 7px;
    }
    .cancel {
      width: 100px;
      background: red;
      padding: 5px 8px;
      color: white;
      font-weight: bold;
      border: none;
      outline: none;
      border-radius: 7px;
    }
  }
  & .float-div {
    position: relative;
    padding-bottom: 200px;

    & .float-btn {
      position: fixed;
      bottom: 80px;
      z-index: 16;
      right: 40px;
      background: black;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        color: #ffd58c;
        font-size: 30px;
      }
    }
  }
`;

export const GarageCardInfoBox = styled(TicketInfoBox)`
  & p {
    color: #fff;
  }
`;
export const H1 = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0;

  @media only screen and (max-width: 484px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 25px;
    padding: 0px 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 45px;
  }
`;
