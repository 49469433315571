import styled from "styled-components";

export const BackgroundBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.98);
  transition: all 0.5s ease 0s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 17;
`;
export const ModalBox = styled.div`
background:#fff;
transition: all 0.5s ease 0s;
display:flex;
flex-direction:column;
padding:30px 20px;
border-radius:10px;
position:absolute;
box-shadow: 4px 4px 15px 2px #00000024;

width:85%;

& .modal-box{
    background-red;
    height:100%;
    width:100%;
    h2{
        color:#000;
        margin:0;
    }
    .passenger{
        margin-top:10px;
        display:flex;
        align-items:center;
        gap:10px;
        .details{
            h4{
                margin:0;
            }
            flex:0.95;
            color:#fff;
            background:#000;
            padding:10px;
            border-radius:7px;
        }
        .remove{
            flex:0.05;
            color:#000;
        }
    }
    
}
`;
export const H3 = styled.h3`
  color: #fff;
`;
export const Button = styled.button`
  background: #ffa100;
  color: #000;
  width: 50%;
  border: none;
  outline: none;
  padding: 10px 12px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 18px;
`;
