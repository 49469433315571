import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showTimeDate: false
};
const showTimeDateSlice = createSlice({
  name: 'showTimeDate',
  initialState,
  reducers: {
    setShowTimeDate: (state, action) => {
      return { ...state, showTimeDate: action.payload };
    },
    clearShowTimeDate: () => {
      return { showTimeDate: false };
    }
  }
});

const { reducer, actions } = showTimeDateSlice;
export const { setShowTimeDate, clearShowTimeDate} = actions;
export default reducer;