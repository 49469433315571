import styled from 'styled-components'

export const DropdownWrap = styled.div `
    background-color: #fff;
    border-radius: 11px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);
    padding: 15px;
    margin-bottom: 18px;
    gap: 10px;
    position: relative;

    & p {
        color: #000;
        font-size: 13px;
        margin: 0;

        span {
            color: #868686;
        }
    }

    & img {
        width: 30px;
    }

    @media only screen and (max-width: 454px)  {
        p {
            font-size: 10px;
        }

        img {
            width: 15px !important;
        }
    }

`

export const DropdownContent = styled.div `
    background-color: #fff;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);
    padding: ${(props:any) => props.active ? '30px 25px' : '0px 25px' } ;
    height: ${(props:any) => props.active ? '150px' : '0px'};
    transition: height 0.3s ease;
    overflow-y: scroll ;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;

    .content-text {
        font-size: 13px;
        color: #000;
        font-weight: 500;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 454px)  {
        .content-text {
            font-size: 10px;
        }
    }
`