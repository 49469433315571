import styled from 'styled-components'


export const DOCDIV = styled.div`
display:flex;
width:100%;
gap:10px;
margin-top:30px;

`
export const DOC= styled.div`
width:33%;
display:flex;
flex-direction:column;

`
export const DOC1 = styled.div`
display:flex;
background: #E7B2FFD9;
overflow:hidden;
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
position:relative;
color:#fff;
`

export const H4 = styled.h4`
font-weight:500;
color:#ffa100;
margin-top:40px;
`
export const H44 = styled.h4`
font-weight:500;
color:#FFF;
margin-top:10px;
`

export const P2 = styled.p`
font-weight:500;
color:#fff;
font-size:12px;
text-align:center;
`
export const Button = styled.div`
background: #000;
color: #fff;
padding:0px 18px;
border-radius:5px;
border:1px solid #ddd;
display:inline-flex;
align-items:center;
height:50px;
margin-top:3em;
`
