import { useEffect, useState } from "react";
import { setShowCarOption } from "../../slices/showCarOption";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import {
  CancelModalBox,
  ModalBox,
  ModalOverlay,
  OptionPill,
  PillRadioBtn,
} from "./optionmodal.styles";
import LocationModal from "../locationModal";
import { useGetVehicleByIdQuery } from "../../services/providerApi";
import { useParams } from "react-router-dom";
import { setShowLocation } from "../../slices/showLocation";

const OptionsModal = ({
  setTripOptions,
  tripOptionChoice,
  setPrice,
  previousPrice,
  tripOptionsList,
}: any) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { showCarOption } = useAppSelector<any>(
    (store: RootState) => store?.showCarOption
  );

  const carOptionModalControl = () => {
    dispatch(setShowCarOption(false));
  };

  useEffect(() => {
    if (previousPrice) {
      setPrice(Number(previousPrice));
    }
  }, [previousPrice]);

  // const tripOptionsList:any = [
  //     {tripOptions: 'Airport pickup/drop off', price: '10000'},
  //     {tripOptions: 'Set my hours - Min 6 hours', price: '1500'},
  //     {tripOptions: 'Half day - 6 hours', price: '7500'},
  //     {tripOptions: 'Full day - 11 hours', price: '14000'},
  //     {tripOptions: '2 Week - 12 days', price: '100000'},
  //     {tripOptions: '1 Month - 26 days', price: '160000'},
  // ]

  const handleOptionsChosen = (option: any) => {
    carOptionModalControl();
    setTripOptions(option?.service_title);
    setPrice(Number(option?.final_discounted_price));
    dispatch(setShowLocation(true));
  };
  const tripOptionsListWithprice = tripOptionsList?.filter((item: any) => {
    return Number(item?.final_discounted_price) > 0;
  });
  const params: any = useParams();

  const { data, isFetching, isLoading, refetch, error } =
    useGetVehicleByIdQuery(params?.vehicleId, {
      refetchOnMountOrArgChange: true,
    });

  const [whereModalBtn, setWhereModalBtn] = useState(false);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [transportType, setTransportType] = useState("Within state");
  const [vehicleInfoArray, setVehicleInfoArray] = useState<any>([]);
  const [tripChosenPrice, setTripChosenPrice] = useState<number>(0);

  const handleLocationComplete = (itemComplete: any) => {
    setWhereModalBtn(itemComplete);
  };
  useEffect(() => {
    refetch();
    setVehicleInfoArray(data?.data?.[0]);
  }, [params?.vehicleId, vehicleInfoArray, data]);

  return (
    <ModalOverlay activeModal={showCarOption}>
      <LocationModal
        setLocationBtn={handleLocationComplete}
        setOrigin={setOrigin}
        handleDestination={setDestination}
        setTransportType={setTransportType}
        states={vehicleInfoArray?.travelTrip}
        setPrice={setTripChosenPrice}
        // specialRequest={specialRequest}
      />

      <CancelModalBox onClick={() => carOptionModalControl()} />
      <ModalBox>
        <div className="inner-modal-box">
          {tripOptionsListWithprice?.map((item: any, id: any) => {
            return (
              <OptionPill key={id} onClick={() => handleOptionsChosen(item)}>
                <PillRadioBtn
                  activePill={
                    item?.service_title === tripOptionChoice ? "fill" : ""
                  }
                />
                <p>{item?.service_title}</p>
              </OptionPill>
            );
          })}
        </div>
      </ModalBox>
    </ModalOverlay>
  );
};

export default OptionsModal;
