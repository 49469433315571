import styled from 'styled-components'

export const CalendarDiv = styled.div`
background:#fff;

display:flex;
border-radius:10px;
overflow:hidden;
padding:.1em;
gap:10px;
position:relative;
margin-top:20px;
`
export const DoneButton = styled.button`
background:#000;
box-shadow: 10px 7px 25px 0px #1A0E0474;
padding:.6em 2em;
position:absolute;
bottom:15px;
right:20px;
color:#fff;
z-index:100;
border-radius:1em;
font-size:14px;
`


export const H6 = styled.h6`
font-weight:700;
font-size:14px;
margin-bottom:0;
color: #FFA100;

`


export const Label = styled.label`
font-weight:400;
font-size:11px;
border-radius:3px;
color: #fff;
`
export const FlexBetween = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin:40px 0px;
`

export const Button = styled.button`
background:#ffa100;
padding:.7em 1em;
border:none;
bottom:15px;
right:20px;
color:#000;
z-index:100;
border-radius:8px;
font-size:14px;
font-weight:700;
`