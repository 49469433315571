import React from "react";
import { usePagination, DOTS } from "../usePagination";
import { LI, LI2, PaginationWrap } from "./pagination.styles";

import leftArrow from "../../assets/images/svg/left-arrow.svg";
import rightArrow from "../../assets/images/svg/right-arrow.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const PaginationComponent = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  goToListTop,
  className,
}: any) => {
  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1]!;

  return (
    <PaginationWrap>
      <ul className="pagination-container">
        <LI
          className="pagination-item"
          onClick={onPrevious}
          disabled={currentPage === 1 ? true : false}
        >
          <div className="arrow-left">
            {/* <img src={leftArrow} /> */}
            <IoIosArrowBack />
          </div>
        </LI>

        {paginationRange?.map((pageNumber: any, id: any) => {
          if (pageNumber === DOTS) {
            return <li key={id}>&#8230;</li>;
          }

          return (
            <LI2
              className="pagination-item"
              key={id}
              onClick={() => {
                onPageChange(pageNumber);
                goToListTop();
              }}
              selected={pageNumber === currentPage ? true : false}
            >
              {pageNumber}
            </LI2>
          );
        })}

        <LI
          className="pagination-item"
          onClick={onNext}
          disabled={currentPage === lastPage ? true : false}
        >
          <div className="arrow-right">
            <IoIosArrowForward />
          </div>
        </LI>
      </ul>
    </PaginationWrap>
  );
};

export default PaginationComponent;
