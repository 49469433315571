import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const AdminProtected = ({ isSignedIn, role, children }: any) => {
    if (role === "admin") {
        return <>
            {children}
            <Outlet />
        </>

    } else {
        return <Navigate to='login' />

    }



}
export const AdminProvider = ({ isSignedIn, role, children }: any) => {
    if (role === "admin" || role === "provider" ) {
        return <>
            {children}
            <Outlet />
        </>

    } else {
        return <Navigate to='login' />

    }



}
export const ProviderCustomer = ({ isSignedIn, role, children }: any) => {
    if (role === "customer" || role === "provider" ) {
        return <>
            {children}
            <Outlet />
        </>

    } else {
        return <Navigate to='login' />

    }



}

export const ProvidersProtected = ({ isSignedIn, role, children }: any) => {
    if (role === "provider") {
        return <>
            {children}
            <Outlet />
        </>

    } else {
        return <Navigate to='login' />

    }



}
export const CustomersProtected = ({ isSignedIn, role, children }: any) => {
    if (role === "customer") {
        return <>
            {children}
            <Outlet />
        </>

    } else {
        return <Navigate to='login' />

    }

}

// export default AdminProtected