import React, { useEffect, useState } from 'react'
import { CheckBox, CheckBoxLabel, CheckBoxLabelTwo, CheckBoxTwo, CheckBoxWrapper } from './index.styles'
import { useVerifyProviderMutation } from '../../../services/adminApi'
import { useAppSelector } from '../../../store'
import { baseUrl } from '../../../services/providerApi'
import axios from 'axios'

const ToggleBar = ({ display, setDisplay }: any) => {

  const [hideProvider, { data, isLoading, isSuccess, isError, error }] = useVerifyProviderMutation()

  const [providerStatus, setProviderStatus] = useState<string>('')
 

  const { id, name, firstname, lastname, token
  } = useAppSelector((state: any) => state?.auth?.auth || '')

  const getProviderDetails = async () => {

    await axios.get(`${baseUrl}provider-next-of-kin/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      }
    }).then((response: any) => {

      if (response && response.data[0]?.status) {

        setProviderStatus(response.data[0]?.status)
        console.log('provider', response.data[0]?.status)
      }
    }).catch((err: any) => {
    })

  }

  useEffect(() => {
    getProviderDetails()
  }, [id])


  const handleHideBusiness = () => {
    setDisplay('0');

    let formData: any = new FormData()
    formData.append('id', id)
    formData.append("display", '0');
    formData.append('status', providerStatus)
    // setProviderStatus('Approve Loading');

    hideProvider(formData)
      .then((response: any) => {
        // setProviderStatus('Approved');
        //@ts-ignore
        // console.log(response?.data?.data?.status)
        if (response?.data?.data?.display === '0') {
          setDisplay('0');
        }

      })
      .catch((error) => {
        // Handle the error if needed
        console.error('Error verifying Provider:', error);
        // setVehicleStatus('Error');
      });
  }

  const handleShowBusiness = () => {
    setDisplay('1');

    let formData: any = new FormData()
    formData.append('id', id)
    formData.append("display", '1');
    formData.append('status', providerStatus)
    // setProviderStatus('Approve Loading');

    hideProvider(formData)
      .then((response: any) => {
        // setProviderStatus('Approved');
        //@ts-ignore
        // console.log(response?.data?.data?.status)
        if (response?.data?.data?.display === '1') {
          setDisplay('1');
        }

      })
      .catch((error) => {
        // Handle the error if needed
        console.error('Error verifying Provider:', error);
        // setVehicleStatus('Error');
      });
  }

  return (
    <>
      {
        display === '0' ?
          <CheckBoxWrapper onClick={handleShowBusiness}>
            <CheckBox id="checkbox" type="checkbox" />
            <CheckBoxLabel htmlFor="checkbox"  />
          </CheckBoxWrapper> :

          <CheckBoxWrapper onClick={handleHideBusiness}>
            <CheckBoxTwo id="checkbox" type="checkbox" checked />
            <CheckBoxLabelTwo htmlFor="checkbox"  />
          </CheckBoxWrapper>
      }

    </>
  )
}

export default ToggleBar