import React, { useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import {
  FlexDiv,
  Button,
  H4,
  Country,
  CountryDiv,
  FlexNoWrap,
  ButtonFlex,
} from "./index.styles";
import { RxHamburgerMenu } from "react-icons/rx";
import Stats from "../Stats/statsOne";
import StatsTwo from "../Stats/statsTwo";
import StatsThree from "../Stats/statsThree";
import StatsFour from "../Stats/statsFour";
import Ratings from "../DashboardRatings/Ratings";
import Reports from "../DashboardRatings/Reports";
import Members from "../Members";
import { baseUrl } from "../../../services/adminApi";
import axios from "axios";
import { useAppSelector } from "../../../store";
import { H3 } from "../Stats/stats.styles";
import usa from "../../../assets/images/png/usa.png";
import canada from "../../../assets/images/png/canada.png";
import CurrencyInput from "react-currency-input-field";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface VehicleInterface {
  countAccepted?: number;
  countApproved?: number;
  countBlocked?: number;
  countPending?: number;
  countPinned?: number;
  countRejected?: number;
  countTrashed?: number;
}
const DashboardInfo = () => {
  const [vehicleCount, setVehicleCount] = useState<VehicleInterface>();
  const [driverCount, setDriverCount] = useState<any>();
  const [requestCount, setRequestCount] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [nairaToDollar, setNairaToDollar] = useState<any>(0);
  const [nairaToCanada, setNairaToCanada] = useState<any>(0);
  const [dollarToCanada, setDollarToCanada] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const user: any = useAppSelector((state: any) => state?.auth?.auth);

  const getRates = async () => {
    await axios
      .get(`${baseUrl}fetch-currency-converter`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.status === true) {
          // dispatch(setAllDriver(response?.data))
          setNairaToDollar(response?.data?.data[0]?.ngn_to_usd);
          setDollarToCanada(response?.data?.data[0]?.usd_to_cad);
          setNairaToCanada(response?.data?.data[0]?.ngn_to_cad);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getRates();
  }, []);

  const getFilteredCount = async () => {
    await axios
      .get(`${baseUrl}count-vehicle-status`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          // dispatch(setAllDriver(response?.data))
          setVehicleCount(response?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const getFilteredRequest = async () => {
    await axios
      .get(`${baseUrl}count-request-status`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          // dispatch(setAllDriver(response?.data))
          setRequestCount(response?.data?.data);
        }
      })
      .catch((err) => {});
  };

  const getDriverCount = async () => {
    await axios
      .get(`${baseUrl}count-driver-status`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          // dispatch(setAllDriver(response?.data))
          setDriverCount(response?.data?.data);
        }
      })
      .catch((err) => {});
  };

  const getTotalCount = async () => {
    await axios
      .get(`${baseUrl}fetch-total-counts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        // dispatch(setAllDriver(response?.data))
        setTotalCount(response?.data?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getFilteredCount();
    getFilteredRequest();
    getDriverCount();
    getTotalCount();
  }, []);

  const updateCurrencies = () => {
    setLoading(true);
    let formData: any = new FormData();
    formData.append("ngn_to_usd", nairaToDollar);
    formData.append("usd_to_cad", dollarToCanada);
    formData.append("ngn_to_cad", nairaToCanada);
    formData.append("id", 1);
    axios({
      method: "post",
      url: `${baseUrl}update-currency-converter`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        setLoading(false);
        if (response?.data.status === true) {
          notifySuccess("Currency Updated ");
        } else {
          notifySuccess("Something went wrong");
        }
      })
      .catch(function (error) {});
  };
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />

      <FlexDiv>
        <Stats requestCount={requestCount} />
        <StatsTwo vehicleCount={vehicleCount} />
      </FlexDiv>

      {/* <Button>Cancelled</Button> */}

      <FlexDiv style={{ marginTop: "40px" }}>
        <StatsThree totalCount={totalCount} />
        <StatsFour driverCount={driverCount} />
      </FlexDiv>

      <FlexDiv style={{ marginTop: "80px" }}>
        <Ratings />
        <Reports />
      </FlexDiv>

      <H4>Today's Exchange Rate</H4>

      <FlexNoWrap style={{ marginTop: "80px" }}>
        <H3 style={{ color: "#fff" }}>1 USD to Naira</H3>
        <CountryDiv>
          <Country style={{ padding: "10px 14px" }}>
            <img src={usa} width={30} />
          </Country>
          <CurrencyInput
            decimalsLimit={2}
            prefix="₦"
            placeholder="0.00"
            value={nairaToDollar}
            style={{
              width: "100%",
              padding: 10,
              fontSize: 20,
              border: "none",
              outline: "none",
            }}
            onValueChange={(value) => setNairaToDollar(value)}
          />
        </CountryDiv>
      </FlexNoWrap>

      <FlexNoWrap style={{ marginTop: "30px" }}>
        <H3 style={{ color: "#fff" }}>1 CAD to Naira</H3>

        <CountryDiv>
          <Country style={{ padding: 15 }}>
            <img src={canada} width={30} />
          </Country>
          <CurrencyInput
            decimalsLimit={2}
            prefix="₦"
            placeholder="0.00"
            value={nairaToCanada}
            style={{
              width: "100%",
              padding: 10,
              fontSize: 20,
              border: "none",
              outline: "none",
            }}
            onValueChange={(value) => setNairaToCanada(value)}
          />
        </CountryDiv>
      </FlexNoWrap>

      <FlexNoWrap style={{ marginTop: "30px" }}>
        <H3 style={{ color: "#fff" }}>1 CAD to USD</H3>

        <CountryDiv>
          <Country style={{ padding: 15 }}>
            <img src={canada} width={30} />
          </Country>
          <CurrencyInput
            decimalsLimit={2}
            prefix="₦"
            placeholder="0.00"
            value={dollarToCanada}
            style={{
              width: "100%",
              padding: 10,
              fontSize: 20,
              border: "none",
              outline: "none",
            }}
            onValueChange={(value) => setDollarToCanada(value)}
          />
        </CountryDiv>
      </FlexNoWrap>

      <ButtonFlex>
        {loading ? (
          <Button>Loading...</Button>
        ) : (
          <Button onClick={() => updateCurrencies()}>Update Currency</Button>
        )}
      </ButtonFlex>

      {/* <H4>Members of Admin</H4>

      <Members />
      <Members />
      <Members /> */}
    </>
  );
};

export default DashboardInfo;
