import styled from 'styled-components'



export const DIV = styled.div`
display:flex;
align-items:center;
gap:15px;
height:120px;
position: relative;
margin-bottom:50px;
`

export const IMAGEDIV = styled.div`
display:flex;
width:59%;
border:1px solid #eee;
border-radius:10px;
height:130px;
position:relative;
justify-content:center;
align-items:center;

`
export const IMAGESDIV = styled.div`
display:flex;
align-items:center;
width:41%;
flex-wrap:wrap;
gap:10px;
border-radius:6px;
height:100%;
`
export const IMAGE1 = styled.div`
width:42%;
border:1px solid #eee;
border-radius:10px;
height:40%;
`

export const BUTTON = styled.div`
color:#FFA100;
background:#000;
position:absolute;
padding:.3em .7em;
border-radius:50%;
font-weight:600;
text-align:center;
top:-10px;
right:-10px;
font-size:14px;
`


export const H3 = styled.h3`
font-weight:500;
color:#fff;

`

export const LoaderContainer = styled.div`
display:flex;
gap:10px;
margin-bottom:60px;
margin-top:0px;
height:3px;
width:70%;

`
export const DivContainer = styled.div`
display:flex;
background-color:#fff;
height:3px;
width:100%;
`

export const AP = styled.p`
color:#fff;
font-size:12px;
margin-left:35px;
`
