import React, { useState } from 'react'
import { AppDispatch, useAppDispatch, useAppSelector, RootState } from '../../store'
import { TabItem, TabsContainer } from './tabcomponent.styles'

const TabComponent = ({tabOne, tabTwo, handleChange}: any) => {
    const [changeTab, setChangeTab] = useState<any>('tab1')

    const onTabChange = (tabValue: any) => {
        handleChange(tabValue)
        setChangeTab(tabValue)
    }

  return (
    <>
        <TabsContainer>
            <div className="inner-tab-wrap">
                <TabItem tabActive={changeTab === 'tab1' ? true : false} onClick={() => onTabChange('tab1')}>
                    {tabOne}
                </TabItem>

                <TabItem tabActive={changeTab === 'tab2' ? true : false}  onClick={() => onTabChange('tab2')}>
                    {tabTwo}
                </TabItem>
            </div>
        </TabsContainer>
    </>
  )
}

export default TabComponent