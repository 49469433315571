import React, { useState } from 'react'
import { CheckBox, CheckBoxLabel, CheckBoxLabelTwo, CheckBoxTwo, CheckBoxWrapper } from './vehicleToggle.styles'
import { useVerifyVehicleMutation } from '../../../services/adminApi'

const VehicleToggleBar = ({ display, setDisplay,id,status }: any) => {


  const [hideShowVehicle, { data, isLoading, isSuccess, isError, error }] = useVerifyVehicleMutation()
  console.log(display,'displaydata')


  let [isChecked, setIsChecked]=useState(false)
  const handleHideVehicle = () => {
    setIsChecked(true)
    setDisplay('0');
    let formData: any = new FormData()
    formData.append('id', id)
    formData.append("display", '0');
    formData.append('status', status)
    // setProviderStatus('Approve Loading');

    hideShowVehicle(formData)
      .then((response: any) => {
        // setProviderStatus('Approved');
        //@ts-ignore
        console.log(response?.data?.data?.display, 'display2')
        if (response?.data?.data?.display === '0') {
          setDisplay('0');
        }

      })
      .catch((error) => {
        // Handle the error if needed
        console.error('Error verifying Provider:', error);
        // setVehicleStatus('Error');
      });
  }

  const handleShowVehicle = () => {
    setIsChecked(true)
    setDisplay('1');

    let formData: any = new FormData()
    formData.append('id', id)
    formData.append("display", '1');
    formData.append('status', status)
    // setProviderStatus('Approve Loading');

    hideShowVehicle(formData)
      .then((response: any) => {
        // setProviderStatus('Approved');
        //@ts-ignore
        // console.log(response?.data?.data?.status)
        if (response?.data?.data?.display === '1') {
          setDisplay('1');
        }

      })
      .catch((error) => {
        // Handle the error if needed
        console.error('Error verifying Provider:', error);
        // setVehicleStatus('Error');
      });
  }

  return (
    <>
      {
        display === '0' ?
          <CheckBoxWrapper onChange={handleShowVehicle} checked={isChecked}>
            <CheckBox id="checkbox" type="checkbox" />
            <CheckBoxLabel htmlFor="checkbox" />
          </CheckBoxWrapper> :
          <CheckBoxWrapper>
            <CheckBoxTwo id="checkbox" type="checkbox"  onChange={handleHideVehicle} checked />
            <CheckBoxLabelTwo htmlFor="checkbox" />
          </CheckBoxWrapper>
      }
    </>
  )
}

export default VehicleToggleBar