import React, { useEffect, useRef, useState } from "react";
import {
  AddImageBox,
  AddImageBtn,
  AddImageSection,
  AlertWrapper,
  ButtonFooter,
  InsuranceInfoBox,
  InsuranceInfoFlexOverall,
  ProgressBox,
  ProgressWrap,
  SelectInput,
} from "./transactionalertbox.styles";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { ToastContainer, toast } from "react-toastify";

import map from "../../assets/images/png/map.png";
import car from "../../assets/images/svg/success-car.svg";
import arrow from "../../assets/images/svg/arrow-down-vector.svg";
import license from "../../assets/images/png/insurance-img.png";
import camera from "../../assets/images/svg/add-img-yellow.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import InsuranceDropdown from "../insuranceDropdown";
import { useUpdateInsuranceMutation } from "../../services/customerApi";
import { RootState, useAppSelector } from "../../store";
import { baseUrl } from "../../services/providerApi";
import axios from "axios";
import Compressor from "compressorjs";

import {
  ButtonLabel,
  FILEDIV,
  IDDIV,
  RegionDiv,
  UploadButton,
} from "../providerAccountSetup/firstScreen/firstScreen.styles";
import { HiArrowLongUp } from "react-icons/hi2";

const TransactionAlertBox = ({
  buttonText,
  insureDisplay,
  setIsDisplay,
}: any) => {
  const { id, token } = useAppSelector<any>(
    (store: RootState) => store?.auth?.auth
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [updateInsurance, { data, isLoading, isSuccess, isError, error }] =
    useUpdateInsuranceMutation();
  const navigate = useNavigate();
  const [imagePrev, setImagePrev] = useState<any>();
  const [userState, setUserState] = useState<string>("");
  const [userCountry, setUserCountry] = useState<string>("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [idNum, setIdNum] = useState("");
  const [dob, setDob] = useState("");
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  const genderList: any = [
    { name: "Male" },
    { name: "Female" },
    { name: "Others" },
  ];

  const idList: any = [
    // { name: "International passport" },
    { name: "Drivers Lisense" },
    // { name: "NIN" },
  ];

  // USEEFFECT FOR UPDATING INSURANCE
  useEffect(() => {
    if (isSuccess) {
      notifySuccess("Details succesfully updated");
      setIsDisplay("none");
    }
    if (isError && "status" in error!) {
      notifyError(error.data.message);
    }
  }, [data, isSuccess, isError, error]);

  const handleUpdateInsurance = () => {
    setLoading(true);
    if (
      !userCountry ||
      !userState ||
      !gender ||
      !dob ||
      !identification ||
      !idNum
    ) {
      notifyError("Please fill in all your information");
    } else {
      let formData: any = new FormData();

      formData.append("gender", gender);
      formData.append("dob", dob);
      formData.append("id_image", file);
      formData.append("id_number", idNum);
      formData.append("identification", identification);
      formData.append("country", userCountry);
      formData.append("city", userState);
      formData.append("id", id);
      formData.append("user_id", id);

      axios({
        method: "post",
        url: `${baseUrl}update-profile`,
        data: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response: any) => {
          setLoading(false);
          if (response?.data.message === "success") {
            notifySuccess("Details saved successfully");
            navigate("/user-booking-page");
          }
        })
        .catch((error: any) => {
          //handle error
          setLoading(false);
        });
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImagePrev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImagePrev(e.target.result);
        }

        new Compressor(file[0], {
          quality: 0.6,
          success: (compressedResult: any) => {
            setFile(compressedResult);
          },
        });
      };
      reader.readAsDataURL(file[0]);
    }
    // setFrontImage(false)
    // setBackImage(true)
  };

  return (
    <AlertWrapper>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChange}
      />

      <ToastContainer />
      <div className="inner-wrapper">
        {/* <h3>{alertHeader}</h3> */}

        <div className="image-wrap">
          <img src={map} />
        </div>

        <h3>Waiting for service provider to confirm</h3>

        <ProgressWrap>
          <ProgressBox>
            <div className="inner-progress-box">
              <img src={car} />
            </div>
          </ProgressBox>
        </ProgressWrap>

        <p>
          Your request has been sent to the vehicle owner pending acceptance.
        </p>

        <InsuranceInfoBox display={insureDisplay}>
          <h4>Who is driving this vehicle?</h4>

          <h6>Provide the following information for a complete booking</h6>

          <InsuranceInfoFlexOverall>
            <form>
              <label>
                Gender <span>*</span>{" "}
              </label>
              <InsuranceDropdown
                selectType={"Select"}
                list={genderList}
                choice={gender}
                setChoice={setGender}
              />
            </form>

            <form>
              <label>
                Date of Birth <span>*</span>{" "}
              </label>
              <SelectInput>
                <input
                  type="date"
                  value={dob}
                  onChange={(e: any) => setDob(e.target.value)}
                />
              </SelectInput>
            </form>
          </InsuranceInfoFlexOverall>

          <InsuranceInfoFlexOverall>
            <form>
              <label>
                Means of ID <span>*</span>{" "}
              </label>
              <InsuranceDropdown
                selectType={"Select ID Type"}
                list={idList}
                choice={identification}
                setChoice={setIdentification}
              />
            </form>

            <form>
              <label>
                ID Number <span>*</span>{" "}
              </label>
              <SelectInput bg={"#fff"}>
                <input
                  type="text"
                  placeholder="Type here..."
                  value={idNum}
                  onChange={(e: any) => setIdNum(e.target.value)}
                />
              </SelectInput>
            </form>
          </InsuranceInfoFlexOverall>

          <AddImageSection>
            <h4>Skip to Add Image Later</h4>

            <AddImageBox>
              {imagePrev ? (
                <>
                  <img
                    src={imagePrev}
                    alt=""
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </>
              ) : (
                <img
                  src={license}
                  //@ts-ignore
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    if (fileInputRef.current != null) {
                      fileInputRef.current.click();
                    }
                  }}
                />
              )}

              <AddImageBtn>
                <img
                  src={camera} //@ts-ignore
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    if (fileInputRef.current != null) {
                      fileInputRef.current.click();
                    }
                  }}
                />
              </AddImageBtn>
            </AddImageBox>
          </AddImageSection>

          <InsuranceInfoFlexOverall>
            <form>
              <label>
                Country <span>*</span>{" "}
              </label>
              {/* <SelectInput bg={"#000"}> */}
              <div className="country-select">
                <RegionDiv>
                  <CountryDropdown
                    value={userCountry}
                    onChange={setUserCountry}
                  />
                </RegionDiv>
              </div>
              {/* </SelectInput> */}
            </form>

            <form>
              <label>
                City <span>*</span>{" "}
              </label>
              {/* <SelectInput bg={"#000"}> */}
              <div className="country-select">
                <RegionDiv>
                  <RegionDropdown
                    country={userCountry}
                    value={userState}
                    onChange={setUserState}
                  />
                </RegionDiv>
              </div>
            </form>
          </InsuranceInfoFlexOverall>
        </InsuranceInfoBox>

        {insureDisplay === "block" ? (
          loading ? (
            <ButtonFooter btnBg={"#000"}>
              <h5>Loading...</h5>
            </ButtonFooter>
          ) : (
            <ButtonFooter btnBg={"#000"} onClick={handleUpdateInsurance}>
              <h5>Submit</h5>
            </ButtonFooter>
          )
        ) : (
          <Link to={"/user-booking-page"}>
            <ButtonFooter btnBg={"#000"}>
              <h5>{buttonText}</h5>
            </ButtonFooter>
          </Link>
        )}
      </div>
    </AlertWrapper>
  );
};

export default TransactionAlertBox;
