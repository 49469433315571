import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import {
  VerifyButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  Input,
  MARKDIV,
  Wrapper,
  BlackOpacity,
  Text,
  DropDown,
  Label,
  LabelTitle,
} from "./servicePrices.styles";
import {
  useAddNewCarPriceMutation,
  useDriverPriceUpdateMutation,
} from "../../../services/providerApi";
import {
  SubmitButton,
  SubmitDiv,
} from "../../providerVehicleProfile/regularRates/regularRates.styles";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";
import { useAppSelector } from "../../../store";

interface ComponentProps {
  driverProfile: any;
}
const ServicePrices: FC<ComponentProps> = ({ driverProfile }) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [driverInfo, setDriverInfo] = useState<any>(driverProfile);
  const [updateDriverPrice, { data, isLoading, isSuccess, isError, error }] =
    useDriverPriceUpdateMutation();
  const [
    addNewDriverPrice,
    {
      data: newData,
      isLoading: newIsLoading,
      isSuccess: newIsSucess,
      isError: newIsError,
      error: newError,
    },
  ] = useAddNewCarPriceMutation();

  const [price, setPrice] = useState("");

  const driverId = driverProfile?.data[0]?.id;
  const userId = driverProfile?.data[0]?.user_id;
  const serviceRates = [
    {
      price: 0,
      hour: 1,
      discount: 0,
      service_title: "Hourly Rate",
      user_id: userId,
      driver_id: driverId,
    },
    {
      price: 0,
      hour: 12,
      discount: 0,
      service_title: "Full Day Service",
      user_id: userId,
      driver_id: driverId,
    },
    {
      price: 0,
      hour: 3,
      discount: 0,
      service_title: "All Night Service",
      user_id: userId,
      driver_id: driverId,
    },

    {
      price: 0,
      hour: 1,
      discount: 0,
      service_title: "Weekly Service",
      user_id: userId,
      driver_id: driverId,
    },
    {
      price: 0,
      hour: 1,
      discount: 0,
      service_title: "Monthly Service",
      user_id: userId,
      driver_id: driverId,
    },
  ];

  useEffect(() => {
    if (driverProfile && driverProfile?.data[0] !== undefined) {
      setDriverInfo(driverProfile?.data[0]);
    }
  }, [driverProfile]);

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  let RegularRatesPrice =
    driverInfo?.service_price?.filter(function (data: any) {
      return data?.service_title;
    }) || [];

  const handleIncrease = (servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = driverInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        if (servicePrice?.service_title === "Full Day Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 20 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "All Night Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 10 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Hourly Rate") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 5 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }

        if (servicePrice?.service_title === "Weekly Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 5 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Monthly Service") {
          const currentMonth = Number(data?.hour);
          const newMonth = currentMonth + 1;
          const updatedMonth = newMonth <= 12 ? newMonth : currentMonth;
          return {
            ...data,
            hour: updatedMonth,
          };
        }
      }
      return data;
    });

    setDriverInfo({
      ...driverInfo,
      service_price: updatedServiceData,
    });
  };

  const handleDecrease = (servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = driverInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        if (servicePrice?.service_title === "Full Day Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 12 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "All Night Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 10 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Hourly Rate") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 3 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }

        if (servicePrice?.service_title === "Weekly Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 3 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Monthly Service") {
          const currentMonth = Number(data?.hour);
          const newMonth = currentMonth - 1;
          const updatedMonth = newMonth >= 1 ? newMonth : currentMonth;
          return {
            ...data,
            hour: updatedMonth,
          };
        }
      }
      return data;
    });

    setDriverInfo({
      ...driverInfo,
      service_price: updatedServiceData,
    });
  };

  const handlePrice = (
    event: React.ChangeEvent<HTMLInputElement>,
    servicePrice: any
  ): any => {
    setShowSaveButton(true);
    const updatedServiceData = driverInfo?.service_price.map((data: any) => {
      if (data.id === servicePrice.id) {
        setPrice(event.target.value);
        return {
          ...data,
          price: event.target.value,
        };
      }
      return data;
    });
    setDriverInfo({
      ...driverInfo,
      service_price: updatedServiceData,
    });
  };

  const updatePrice = (updatedRate, price) => {
    setShowSaveButton(true);

    const updatedServiceData = combinedRates.map((data: any) => {
      if (data.service_title === updatedRate.service_title) {
        setPrice(price);
        return {
          ...data,
          price: price,
        };
      }
      return data;
    });
    setDriverInfo({
      ...driverInfo,
      service_price: updatedServiceData,
    });
  };

  const combinedRates = [
    ...RegularRatesPrice,
    ...serviceRates.filter((defaultRate) => {
      return !RegularRatesPrice.some(
        (existingRate) =>
          existingRate.service_title === defaultRate.service_title
      );
    }),
  ];

  const newRate = combinedRates.filter((item) => !item.hasOwnProperty("id"));
  const oldRate = combinedRates.filter((item) => item.hasOwnProperty("id"));

  const saveData = () => {
    setIsSaveLoading(true);

    //@ts-ignore
    updateDriverPrice(oldRate)
      .then((response: any) => {
        // setUserId(response?.data?.data[0]?.user_id)
        setIsSaveLoading(false);
        setShowSaveButton(false);

        // setProviderDetails(response?.data)
      })
      .catch((err) => {
        setIsSaveLoading(false);
      });

    if (newRate) {
      //@ts-ignore
      addNewDriverPrice(newRate)
        .then((response: any) => {
          setIsSaveLoading(false);
          setShowSaveButton(false);

          // setProviderDetails(response?.data)
        })
        .catch((err) => {
          setIsSaveLoading(false);
        });
    }
  };

  return (
    <>
      <br />
      <br />

      <LabelTitle>Service Rates</LabelTitle>

      {combinedRates?.map((rate: any) => (
        <FLEXDIV key={rate.service_title}>
          <INPUTDIV>
            <label
              style={{
                color: "#868686",
                display: "flex",
                width: "40%",
                fontSize: "14px",
                fontWeight: "normal",
              }}
              htmlFor=""
            >
              {rate?.service_title}
            </label>
            <VerifyButton
            // onClick={openModal}
            >
              <P style={{ left: "0px", top: "-37px" }}>Hours</P>

              <Text>{rate?.hour || rate?.months || 0}</Text>
              <DropDown>
                {/* <IoIosArrowDown style={{ color: '#FFD58C', fontSize: 15, fontWeight: 600 }} /> */}

                <IoIosArrowUp
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  onClick={() => handleIncrease(rate)}
                />
                <IoIosArrowDown
                  style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  onClick={() => handleDecrease(rate)}
                />
              </DropDown>
            </VerifyButton>

            <BLACKDIV>
              <P style={{ left: "0px", top: "-37px" }}>Set Price</P>

              <Input>
                <CurrencyInput
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    color: "#fff",
                  }}
                  decimalsLimit={2}
                  prefix={formatCurrencyPrefix(currentLocation)}

                  placeholder="0.00"
                  value={rate?.price}
                  // onValueChange={(e) => handlePrice(e, rate)}
                  onValueChange={(e: any) => updatePrice(rate, e)}
                />
              </Input>
              <DISCOUNT>
                <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                {rate?.discount}%
              </DISCOUNT>
            </BLACKDIV>
          </INPUTDIV>
        </FLEXDIV>
      ))}

      {showSaveButton && (
        <SubmitDiv>
          {isSaveLoading ? (
            <SubmitButton style={{ backgroundColor: "#AD7108" }}>
              Updating Pricing...
            </SubmitButton>
          ) : (
            <SubmitButton
              style={{ backgroundColor: "#AD7108" }}
              onClick={() => saveData()}
            >
              Save and Update Pricing
            </SubmitButton>
          )}
        </SubmitDiv>
      )}
    </>
  );
};
export default ServicePrices;
