import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  IMAGE1,
  AP,
  BUTTON,
  IMAGEDIV,
  IMAGESDIV,
  DIV,
  H3,
} from "./vehicleProfile.styles";

import blueCar from "../../../assets/images/png/blueCar.png";

import VehicleProfileForms from "./profileForms";
import { Link, useNavigate } from "react-router-dom";
import {
  useCarPictureUpdateMutation,
  useGetVehicleByIdQuery,
} from "../../../services/providerApi";
import { useAppSelector } from "../../../store";
import { baseImageUrl } from "../../../services/utils";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { CancelButton } from "../../providerCarReg/carImageComponent/index.styles";
import Compressor from "compressorjs";

type Props = {
  vehicleProfile: any;
};
const VehicleProfile: FC<Props> = ({ vehicleProfile }) => {
  const user: any = useAppSelector((state: any) => state?.auth?.auth);
  const [updateVehiclePicture, { data, isLoading, isSuccess, isError, error }] =
    useCarPictureUpdateMutation();

  let [vehicleSide, setVehicleSide] = useState("Front");

  const [bigImagePrev, setBigImagePrev] = useState<any>(
    baseImageUrl + vehicleProfile?.data[0].image1
  );
  const [image1Prev, setImage1Prev] = useState<any>(
    baseImageUrl + vehicleProfile?.data[0].image1
  );
  const [image2Prev, setImage2Prev] = useState<any>(
    baseImageUrl + vehicleProfile?.data[0].image2
  );
  const [image3Prev, setImage3Prev] = useState<any>(
    baseImageUrl + vehicleProfile?.data[0].image3
  );
  const [image4Prev, setImage4Prev] = useState<any>(
    baseImageUrl + vehicleProfile?.data[0].image5
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const file2InputRef = useRef<HTMLInputElement>(null);
  const file3InputRef = useRef<HTMLInputElement>(null);
  const file4InputRef = useRef<HTMLInputElement>(null);
  const file5InputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const handlePrev = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (user?.user_role === "admin") {
      navigate("/list-vehicles");
    } else {
      navigate("/provider-dashboard");
    }
  };
  const IMG_QUALITY = 0.5;

  const onChangeFrontImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const promise = toast.loading("Loading...");
    event.persist();
    const file: any = event.currentTarget.files;
    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append("id", vehicleProfile?.data[0]?.id);
        formData.append("file1", compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData)
          .then((response: any) => {
            if (response?.data?.message === "success") {
              setBigImagePrev(baseImageUrl + response?.data?.data[0]?.image1);
              setImage1Prev(baseImageUrl + response?.data?.data[0]?.image1);
              setTimeout(() => {
                toast.dismiss(promise);
              }, 0);
              toast.success("Front Image Successfully Changed!");
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((err: any) => {});
      },
    });
  };
  const onChangeBackImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const promise = toast.loading("Loading...");
    event.persist();
    const file: any = event.currentTarget.files;
    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append("id", vehicleProfile?.data[0]?.id);
        formData.append("file2", compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData)
          .then((response: any) => {
            if (response?.data?.message === "success") {
              setBigImagePrev(baseImageUrl + response?.data?.data[0]?.image2);
              setImage2Prev(baseImageUrl + response?.data?.data[0]?.image2);
              setTimeout(() => {
                toast.dismiss(promise);
              }, 0);

              toast.success("Back Image Successfully Changed!");
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((err: any) => {});
      },
    });
  };

  const onChangeInteriorImage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const promise = toast.loading("Loading...");
    event.persist();
    const file: any = event.currentTarget.files;

    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append("id", vehicleProfile?.data[0]?.id);
        formData.append("file3", compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData)
          .then((response: any) => {
            if (response?.data?.message === "success") {
              setBigImagePrev(baseImageUrl + response?.data?.data[0]?.image3);
              setImage3Prev(baseImageUrl + response?.data?.data[0]?.image3);
              setTimeout(() => {
                toast.dismiss(promise);
              }, 0);

              toast.success("Interior Image Successfully Changed!");
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((err: any) => {});
      },
    });
  };

  const onChangeSideImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const promise = toast.loading("Loading...");
    event.persist();
    const file: any = event.currentTarget.files;

    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append("id", vehicleProfile?.data[0]?.id);
        formData.append("file5", compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData)
          .then((response: any) => {
            if (response?.data?.message === "success") {
              setBigImagePrev(baseImageUrl + response?.data?.data[0]?.image5);
              setImage4Prev(baseImageUrl + response?.data?.data[0]?.image5);
              setTimeout(() => {
                toast.dismiss(promise);
              }, 0);

              toast.success("Side Image Successfully Changed!");
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((err: any) => {});
      },
    });
  };

  return (
    <>
      <Toaster />

      <div
        style={{
          paddingTop: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "8px",
          marginBottom: "30px",
        }}
      >
        <BsChevronLeft
          //@ts-ignore
          onClick={
            //@ts-ignore
            handlePrev
          }
          style={{ color: "white", fontSize: 25, fontWeight: 600 }}
        />
        <H3>Vehicle Profile </H3>
      </div>
      <AP>Vehicle {vehicleSide} View</AP>
      <DIV>
        <IMAGEDIV>
          {/* <h1 style={{ position: 'absolute', top: -20, right: 10 }}>X</h1> */}
          <CancelButton
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();

              if (
                bigImagePrev ===
                baseImageUrl + vehicleProfile?.data[0]?.image1
              ) {
                if (fileInputRef.current != null) {
                  fileInputRef.current.click();
                }
              }

              if (
                bigImagePrev ===
                baseImageUrl + vehicleProfile?.data[0]?.image2
              ) {
                if (file2InputRef.current != null) {
                  file2InputRef.current.click();
                }
              }

              if (
                bigImagePrev ===
                baseImageUrl + vehicleProfile?.data[0]?.image3
              ) {
                if (file3InputRef.current != null) {
                  file3InputRef.current.click();
                }
              }

              if (
                bigImagePrev ===
                baseImageUrl + vehicleProfile?.data[0]?.image5
              ) {
                if (file5InputRef.current != null) {
                  file5InputRef.current.click();
                }
              }
            }}
          >
            X
          </CancelButton>
          <img
            src={bigImagePrev}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            alt=""
          />
        </IMAGEDIV>
        <IMAGESDIV>
          <IMAGE1
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setBigImagePrev(image4Prev);
              setVehicleSide("Side");
            }}
          >
            <img
              src={image4Prev}
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              alt=""
            />
          </IMAGE1>
          <IMAGE1
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setBigImagePrev(image3Prev);
              setVehicleSide("Interior");
            }}
          >
            <img
              src={image3Prev}
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              alt=""
            />
          </IMAGE1>
          <IMAGE1
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setBigImagePrev(image2Prev);
              setVehicleSide("Back");
            }}
          >
            <img
              src={image2Prev}
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              alt=""
            />
          </IMAGE1>
          <IMAGE1
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setBigImagePrev(image1Prev);
              setVehicleSide("Front");
            }}
          >
            <img
              src={image1Prev}
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              alt=""
            />
          </IMAGE1>
        </IMAGESDIV>
      </DIV>
      <VehicleProfileForms vehicleProfile={vehicleProfile} />

      <input
        type="file"
        name="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChangeFrontImage}
      />
      <input
        type="file"
        name="file2"
        style={{ display: "none" }}
        ref={file2InputRef}
        accept="image/*"
        onChange={onChangeBackImage}
      />
      <input
        type="file"
        name="file3"
        style={{ display: "none" }}
        ref={file3InputRef}
        accept="image/*"
        onChange={onChangeInteriorImage}
      />
      <input
        type="file"
        name="file5"
        style={{ display: "none" }}
        ref={file5InputRef}
        accept="image/*"
        onChange={onChangeSideImage}
      />
      {/* <input type="file" name="file4" style={{ display: 'none' }} ref={file4InputRef} accept="image/*" onChange={onChangesss} /> */}
    </>
  );
};
export default VehicleProfile;
