import React, { useEffect, useState } from 'react'
import FailedWalletComponent from '../failedWalletComponent'
import FundWalletBank from '../fundWalletBank'
import FundWalletOne from '../fundWalletOne'
import FundWalletThree from '../fundWalletThree'
import FundWalletTwo from '../fundWalletTwo'
import { CancelModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import OtpWalletComponent from '../otpWalletComponent'
import SuccessWalletComponent from '../successWalletComponent'
import { RoadlersBlackCircle, RoadlersHomeCone, WalletContentWrap, WalletModalBox, WalletModalHeader } from './walletmodal.styles'

import roadlersCone from '../../assets/images/svg/homebutton.svg'
import logo from '../../assets/images/svg/logo-small.svg'
import LittleLoader from '../littleLoader'

const WalletModal = ({walletModalActive, removeWalletModal, setTopUpAmount, topUpAmount, accountNumber, bankName, onPay, topUpErrorMessage, isTopUpLoading }:any) => {
    const [showComponent, setShowComponent] = useState('a')
    
    const handleShowComponent = (type: any) => {
        if(topUpAmount) {
            setShowComponent(type)
        }
        
    }

  return (
    <ModalOverlay activeModal={walletModalActive}>
        <CancelModalBox onClick={() => {removeWalletModal()
                handleShowComponent('a')
            }
        }/>
        <WalletModalBox>
            <RoadlersHomeCone src={roadlersCone} />
            <RoadlersBlackCircle>
                <img src={logo} />
            </RoadlersBlackCircle>
            <div className="inner-modal-box">
                <WalletModalHeader>
                    <div className=""></div>
                </WalletModalHeader>

                {
                    isTopUpLoading ?
                    <LittleLoader /> :
                    <WalletContentWrap>
                        <FundWalletOne displaybox={showComponent === 'a' ? 'block' : 'none'} handleShowComponent={handleShowComponent} setTopUpAmount={setTopUpAmount} topUpAmount={topUpAmount} />
                        <FundWalletTwo displaybox={showComponent === 'b' ? 'block' : 'none'} handleShowComponent={handleShowComponent} topUpAmount={topUpAmount} onPay={onPay} topUpErrorMessage={topUpErrorMessage}/>
                        <FundWalletThree displaybox={showComponent === 'c' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/>
                        <OtpWalletComponent displaybox={showComponent === 'otp' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/>
                        <SuccessWalletComponent displaybox={showComponent === 'success' ? 'block' : 'none'} />
                        <FailedWalletComponent displaybox={showComponent === 'failed' ? 'block' : 'none'} />
                        <FundWalletBank displaybox={showComponent === 'd' ? 'block' : 'none'} handleShowComponent={handleShowComponent} topUpAmount={topUpAmount} bankName={bankName} accountNumber={accountNumber} removeWalletModal={removeWalletModal}/>
                    </WalletContentWrap>
                }

            </div>
        </WalletModalBox>

    </ModalOverlay>
  )
}

export default WalletModal