import styled from 'styled-components'



export const ProgressOverall = styled.div `
    
`

export const H01 = styled.h3 `
    font-weight: 600;
    color: ${(props: any) => props.theme.text};
    margin-bottom: 70px;

    @media only screen and (max-width: 484px) {
        font-size: 15px;
    }
`

export const ProgressBarWrap = styled.div `
    background-color: ${(props: any) => props.theme.text};
    height: 3px;
    width: 100%;
    position: relative;
`

export const ProgressNumWrap = styled.div `
    width: 100%;
    display: ${(props: any) => props.barDisplay};
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: -58px;
`

export const ProgressBarInner = styled.div `
    background-color: #ffa100;
    height: 3px;
    width: ${(props:any) => props.barWidth};
    position: relative;
    transition: width ease 0.3s;
    
`

export const ProgressBarImage = styled.img `
    position: absolute;
    top: -36px;
    right: -38px;
    width: 80px;
    height:60px;
`

export const NumText = styled.p `
    font-size: 14px;
    font-weight: 600;
`