import styled from 'styled-components'


export const Input = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:white;
width:100%;
border-radius:7px;
overflow:hidden;
margin-top:40px;
`
export const INPUTS = styled.input`
width:50%;
border-radius:7px;
padding:20px;
font-size:17px;
border:none;
outline:none;
`

export const FLEXDIV = styled.div`
display:flex;
width:100%;
gap:20px;
padding:0px;

`