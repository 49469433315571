import React from 'react'
import { FlexSubInput, PasswordWrap, ProfileImg, SelectWallet, TransferAmountInput, TransferErrorMsg, TransferGreyBox, TransferHeaderWrap, TransferOneWrap, YellowBox } from './transferone.styles'
import { AddIconWrap } from '../fundWalletTwo/fundwallettwo.styles'
import { BoxesWrap, ColumnWrap, FlexInputWrap, GreyBox, InputBottomWrap } from '../fundWalletThree/fundwalletthree.styles'

import arrow from '../../assets/images/svg/arrowDown.svg'
import profile from '../../assets/images/svg/person-icon.svg'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import CurrencyInput from 'react-currency-input-field'



const TransferOneComponent = ({displaybox, handleShowComponent, onWalletTransfer, transferAmount, setTransferAmount, creditWalletName, setCreditWalletName, transferErrorMessage, transferPassword, setTransferPassword}:any) => {
  return (
    <TransferOneWrap display={displaybox}>
        <TransferHeaderWrap>
            <h3>I would like to transfer ....</h3>

            {/* <SelectWallet>
                <p>Select Wallet</p>
                <AddIconWrap>
                    <img src={arrow}/>
                </AddIconWrap>
            </SelectWallet> */}
        </TransferHeaderWrap>

        <TransferAmountInput>
            <label>Enter Amount</label>
            {/* <input type="text" placeholder='0.00' value={transferAmount} onChange={(e:any) => setTransferAmount(e.target.value)} /> */}
            <CurrencyInput 
            decimalsLimit={2}
            prefix="₦"
            placeholder='0.00'
            value={transferAmount}
            onValueChange={(value) => setTransferAmount(value)}
            />

            <div className="absolute-txt">This transaction fee is 0.05%</div>
        </TransferAmountInput>
        <TransferErrorMsg>{transferErrorMessage}</TransferErrorMsg>

        <TransferGreyBox>
            <FlexInputWrap>
                <input type="text"  placeholder='Registered email of reciever' value={creditWalletName} onChange={(e:any) => setCreditWalletName(e.target?.value)}/>
                <FlexSubInput>
                    {/* <YellowBox>
                        <img src={arrow} />
                    </YellowBox> */}

                    <ProfileImg src={profile} />
                </FlexSubInput>
            </FlexInputWrap>

            <BoxesWrap>
                <PasswordWrap>
                    {/* <label>My Password</label> */}
                    <input type="password" placeholder='Type in your password' value={transferPassword} onChange={(e: any) => setTransferPassword(e.target?.value)} />
                </PasswordWrap>

                {/* <InputBottomWrap>
                    <p>Save beneficiary</p>
                </InputBottomWrap> */}
            </BoxesWrap>
        </TransferGreyBox>

        <SubmitBtn onClick={() => 
            // handleShowComponent('otp')
            onWalletTransfer()
        }>
            Transfer
        </SubmitBtn>
    </TransferOneWrap>
  )
}

export default TransferOneComponent