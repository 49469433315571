import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport, IoWalletOutline } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';


import {
    AbsoluteDiv, Page, IconDiv, P, H5, FlexBox, FlexDiv, Div, Button, H6,PaymentHistoryWrapper
} from './wallet.styles';

// import Header from '../../../components/providerHeader/header'
import ProviderWallet from '../../../components/providerWallet/providerWallet';
import PaymentHistory from '../../../components/providerPaymentHistory/paymentHistory';
import Header from '../../../components/providerHeader';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';


const Wallet: FC = () => {




    return (
        <>
            <Header />
            <Page>
            <PagePadding className='padding'>

                <AbsoluteDiv>
                    <IconDiv>
                        <BiUser />
                        <P>Profile</P>
                    </IconDiv>
                    <IconDiv>
                        <IoCarSport />
                        <P>Bookings</P>
                    </IconDiv>
                    <IconDiv style={{background:'#ffa100'}}>
                        <IoWalletOutline style={{color:'#000'}} />
                        <P>Wallet</P>
                    </IconDiv>
                    <IconDiv>
                        <GiReceiveMoney />
                        <P>Earnings</P>
                    </IconDiv>
                    <IconDiv>
                        <BsCalendar2Minus />
                        <P>Requests</P>
                    </IconDiv>
                </AbsoluteDiv>

                <ProviderWallet />

                <div style={{ display: 'flex', justifyContent: 'end', padding: '0px .5em', marginTop: '50px' }}>
                    <FlexBox>
                        <H5>Payment History</H5>
                        <div style={{ background: 'white', padding: '.4em', display: 'flex', alignItems: 'center' }}>
                            <BsCalendar2Minus style={{ color: 'black', fontSize: '15px' }} />
                        </div>
                    </FlexBox>
                </div>

                <Div>
                    <FlexDiv>
                        <Button>Transfers</Button>
                        <H6>Requests</H6>
                    </FlexDiv>

                </Div>
                <PaymentHistoryWrapper>
                    <PaymentHistory />
                    <PaymentHistory />
                    <PaymentHistory />
                </PaymentHistoryWrapper>

                </PagePadding>
            </Page>
        </>
    )
}
export default Wallet