import React, { useState } from 'react'
import { PillRadioBtn } from '../optionModal/optionmodal.styles'
import { AmountInputWrap, BackBtnWrap, CardTxt, FundOptionsWrap, FundWalletComponentWrap, FundWalletOption, H1, OptionsImgBox, PillRadioWrap } from './fundwalletone.styles'
import CurrencyInput from 'react-currency-input-field';

import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import bank from '../../assets/images/svg/bank.svg'
import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'


const FundWalletOne = ({displaybox, handleShowComponent, setTopUpAmount, topUpAmount}:any) => {
    const [fundType, setFundType] = useState('')

    const handleFundType = (type:any) => {
        if(topUpAmount) {
            setFundType(type)
            if(type === 'card') {
                handleShowComponent('b')
            }else {
                handleShowComponent('d')
            }
        }

    }

  return (
    <FundWalletComponentWrap display={displaybox}>
        <H1>I would like fund my wallet with...</H1>

        <AmountInputWrap>
            <label>Enter Amount</label>
            <CurrencyInput 
            decimalsLimit={2}
            prefix="₦"
            placeholder='0.00'
            value={topUpAmount}
            onValueChange={(value) => setTopUpAmount(value)}
            />
            {/* <input type="text" placeholder='0.00' value={topUpAmount} onChange={(e:any) => setTopUpAmount(e.target.value)} /> */}
        </AmountInputWrap>

        <FundOptionsWrap>
            <FundWalletOption onClick={() => handleFundType('card')}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={fundType === 'card' ? 'card' : ''} />

                    <CardTxt>
                        My Card
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'#FFE8B6'}>
                    <img src={master} />

                    <img src={visa} />
                </OptionsImgBox>
            </FundWalletOption>

            <FundWalletOption onClick={() => handleFundType('bank')}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={fundType === 'bank' ? 'bank' : ''} />

                    <CardTxt>
                        My Bank
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'rgba(0, 95, 238, 0.31)'}>
                    <img src={bank} />
                </OptionsImgBox>

            </FundWalletOption>
        </FundOptionsWrap>
    </FundWalletComponentWrap>
  )
}

export default FundWalletOne