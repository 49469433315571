import styled from "styled-components";

export const RequestDiv = styled.div`
  background: #fff;

  display: flex;
  border-radius: 10px;
  box-shadow:${(props: any) => props.theme.shadowBox};

  overflow: hidden;
  padding: 0.9em 1em;
  gap: 10px;
  position: relative;
  margin-bottom: 50px;
`;
export const Div = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  color: #000;
  gap: 8px;
  margin-top: 25px;
  .travel-action {
    display: flex;
    gap: 5px;
    .view {
      button {

        background: #005fee;
        border: none;
        padding: 5px 10px;
        font-size:12px;
        color: #fff;
        border-radius: 5px;
        font-weight: bold;
      }
    }
    .seat {
      display: flex;
      border: 1px solid black;
      border-radius: 5px;
      overflow: hidden;
      gap: 5px;
      button {
        background: #fff;
        padding: 5px 10px;
        font-size:12px;
        color: #000;
        font-weight: bold;
        border: none;
        width: 60%;
        margin: 0;

      }
      label {
        background: #005fee;
        color: #fff;
        border-radius: 0px 5px 5px 0px;
        font-weight: bold;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }
`;

export const ImageDiv = styled.div`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0px;
  background: #fff;
  border-radius: 5px 0px 5px 0px;
`;
export const Span = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #6a6a6a;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
`;
export const H6 = styled.h6`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
  color: #868686;
`;
export const H5 = styled.h5`
  font-weight: 800;
  margin-bottom: 0;
  color: #000;
  margin-top: 0;
`;

export const RejectButton = styled.button`
  font-weight: bold;
  background: #09051c;
  font-size: 12px;
  padding: 0.6em 0.8em;
  border-radius: 3px;
  color: #fff;
  outline: none;
  border: none;
  width: 80px;
`;
export const AcceptButton = styled.button`
  font-weight: 700;
  background: #63e52a;
  font-size: 12px;
  padding: 0.6em 0.8em;
  border-radius: 3px;
  color: #000;
  width: 80px;
  outline: none;
  border: none;
`;
export const CancelButton = styled.button`
  font-weight: 700;
  background: #d52b1e;
  font-size: 12px;
  padding: 0.6em 0.8em;
  border-radius: 3px;
  color: #fff;
  width: 80px;
  outline: none;
  border: none;
`;
export const StartButton = styled.button`
  font-weight: 700;
  background: #005fee;
  font-size: 12px;
  padding: 0.6em 0.8em;
  border-radius: 3px;
  color: #fff;
  width: 80px;
  outline: none;
  border: none;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 11px;
  border-radius: 3px;
  color: #6a6a6a;
`;
export const IDDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100px;
  top: 15px;
  gap: 5px;
`;
export const StatusDiv = styled.div`
  display: flex;
  background: #e70909;
  position: absolute;
  left: 10px;
  top: -23px;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 0.3em 0.9em;
  border-radius: 7px 7px 0px 0px;
  font-size: 13px;
  font-weight: 700;
`;
export const Box = styled.div`
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
