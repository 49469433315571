import styled from 'styled-components'

export const CardOverall = styled.div `
    margin-top: 40px;
    margin-right: 0px;
    border: solid 1px ${(props: any) => props.theme.text};
    border-radius: 8px;
    background-color: ${(props:any) => props.theme.body};
    display: block;
    position: relative;

    & .inner-wrap {
        padding: 15px;

        .header-section {
            display: flex;
            align-items: center;
            gap: 20px;

            h3 {
                font-size: 22px;
                color: ${(props:any) => props.theme.text};
                font-weight: 600;

                @media only screen and (max-width: 454px) {
                    font-size: 18px;
                }
            }
        }

        .title {
            font-weight: 700;
            font-size: 18px;

            @media only screen and (max-width: 454px) {
                font-size: 15px;
            }
        }

        .content {
            p {
                @media only screen and (max-width: 454px) {
                    font-size: 12px;
                }
            }
        }
    }
`