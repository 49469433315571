import React, { useEffect, useRef, useState } from "react";
import { PillWrapper } from "../../pages/user/Homepage/homepage.styles";
import {
  CancelModalBox,
  ModalBox,
  ModalOverlay,
  OptionPill,
  PillRadioBtn,
} from "../optionModal/optionmodal.styles";
import { CancelWrap } from "../itemSizeModal/itemsize.styles";
import {
  ArrowDownWrap,
  DestinationOverall,
  InputBtn,
  InputIcons,
  LocationBox,
  LocationInputWrap,
  LocationModalBox,
  SpecialReqBtn,
  UserLocationWrap,
  WhereOptionPill,
  WhereRadioPill,
  YellowBgArrowWrap,
} from "../locationModal/locationmodal.styles";
import { SaveBtn } from "../extrasModal/extrasmodal.styles";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { setShowLocation } from "../../slices/showLocation";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import whereTag from "../../assets/images/svg/where-tag.svg";
import greyBox from "../../assets/images/svg/greybox.svg";
import arrowDown from "../../assets/images/svg/arrowDown.svg";
import cancel from "../../assets/images/svg/cancel-yellow.svg";
import smiley from "../../assets/images/png/smiley.png";

const center = { lat: 6.605874, lng: 3.349149 };

const BookingLocationModal = ({
  setLocationBtn,
  showLocationModal,
  setShowLocationModal,
  previousOrigin,
  previousDestination,
  withinOrWithout,
  states,
  setDistance,
}: any) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { showLocation } = useAppSelector<any>(
    (store: RootState) => store?.showLocation
  );

  const [userOrigin, setUserOrigin] = useState<any>("");
  const [userDestination, setUserDestination] = useState<any>("");
  const [map, setMap] = useState(null);
  const [directionResponse, setDirectionResponse] = useState(null);
  const [duration, setDuration] = useState("");
  const [where, setWhere] = useState("within");
  const [withinText, setWithinText] = useState("within");
  const [withoutText, setWithoutText] = useState("");
  const [choiceState, setChoiceState] = useState("");
  const [showLocationBox, setShowLocationBox] = useState("block");
  const [showErrorBox, setShowErrorBox] = useState("none");
  const [showAvailableState, setShowAvailableState] = useState("none");
  const [showFullModal, setShowFullModal] = useState(false);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null);

  // const libraries: (
  //   | "places"
  //   | "drawing"
  //   | "geometry"
  //   | "localContext"
  //   | "visualization"
  // )[] = ["places", "geometry", "drawing"];

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
  //   libraries,
  // });

  const locationModalControl = () => {
    setShowLocationModal(false);
  };

  useEffect(() => {
    if (previousOrigin) {
      setUserOrigin(previousOrigin);
      if (withinOrWithout?.includes("within")) {
        setWhere("within");
        setWithinText("within");
        setWithoutText("");
        setUserDestination(previousDestination);
      } else {
        setWhere("without");
        setWithinText("");
        setWithoutText("without");
        setChoiceState(previousDestination);
      }
    }
  }, [previousOrigin, previousDestination, withinOrWithout]);

  useEffect(() => {
    if (userOrigin) {
      if (userDestination) {
        calculateRoute();
      }

      if (choiceState) {
        calculateRoute();
      }
    }
  }, [userOrigin, userDestination, choiceState]);

  useEffect(() => {
    locationModalControl();
  }, [showFullModal]);

  async function calculateRoute() {
    // @ts-ignore
    if (userOrigin === "" || userDestination === "") {
      return setLocationBtn(false);
    }
    const directionService = new google.maps.DirectionsService();
    const results = await directionService.route({
      // @ts-ignore
      origin: userOrigin,
      // @ts-ignore
      destination: userDestination,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    // @ts-ignore
    setDirectionResponse(results);
    // @ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    // @ts-ignore
    setDuration(results.routes[0].legs[0].duration.text);
  }

  return (
    <div>
      <ModalOverlay activeModal={showLocationModal}>
        <CancelModalBox onClick={() => locationModalControl()} />

        {/*  */}
        <LocationModalBox boxDisplay={showLocationBox}>
          <div className="inner-modal-box">
            {/* GOOGLE MAP BOX */}
            <LocationBox>
              {/* Google map box */}
              {/*@ts-ignore */}
              <GoogleMap
                center={center}
                zoom={10}
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "14px",
                }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                {/* <Marker position={center} /> */}
                {directionResponse && (
                  <DirectionsRenderer directions={directionResponse} />
                )}
              </GoogleMap>
            </LocationBox>

            <UserLocationWrap>
              <h4>Where</h4>

              {/* SET WHETHER IT'S WITHIN STATE OR OUT OF STATE */}
              <div className="where-pill-wrap">
                <WhereOptionPill>
                  <WhereRadioPill pillActive={withinText} />

                  <p>Within State</p>
                </WhereOptionPill>

                <WhereOptionPill>
                  <WhereRadioPill pillActive={withoutText} />

                  <p>Travel out of state</p>
                </WhereOptionPill>
              </div>

              <div className="location-input-overall">
                <label>Pickup location</label>

                {/* USERS PICKUP LOCATION */}
                <LocationInputWrap>
                  <InputIcons src={whereTag} iconWidth={"30px"} />
                  <Autocomplete className="autocomplete-wrap">
                    <input
                      type="text"
                      placeholder="Type here"
                      value={userOrigin}
                      readOnly
                    />
                  </Autocomplete>
                </LocationInputWrap>

                {/* DESTINATION FOR WITHIN STATE */}
                <DestinationOverall
                  display={where == "within" ? "block" : "none"}
                >
                  <label>Farthest destination</label>
                  <LocationInputWrap>
                    <InputIcons src={greyBox} iconWidth={"30px"} />

                    <Autocomplete className="autocomplete-wrap">
                      <input
                        type="text"
                        placeholder="Type here"
                        value={userDestination}
                        readOnly
                      />
                    </Autocomplete>
                  </LocationInputWrap>
                </DestinationOverall>

                {/* DESTINATION FOR OUT OF STATE */}
                <DestinationOverall
                  display={where !== "within" ? "block" : "none"}
                >
                  <label>Travel Destination</label>
                  <LocationInputWrap>
                    <InputIcons src={greyBox} iconWidth={"30px"} />
                    <input
                      type="text"
                      placeholder="Select Destination"
                      defaultValue={choiceState}
                      readOnly
                    />
                  </LocationInputWrap>
                </DestinationOverall>
              </div>
            </UserLocationWrap>
          </div>
        </LocationModalBox>
      </ModalOverlay>
    </div>
  );
};

export default BookingLocationModal;
