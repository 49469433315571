import styled from 'styled-components'

export const TextInputOverall = styled.div `

`

export const InputLabelWrap = styled.div `
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
`

export const InputLabelLeftBox = styled.div `
    display: block;

    & p {
        color: #868686;
        font-weight: 700;
        font-size: 12px;
        margin: 0;
        white-space: nowrap;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 9px;
        }
    }
`

export const InputLabelRightBox = styled.div `
    display: block;

    & p {
        color: #ffa100;
        font-weight: 700;
        font-size: 13px;
        margin: 0;
    }

    & img {
        width: 15px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 8px;
        }
    }
`

export const PencilImage = styled.img `
    width: 30px;
    cursor: pointer;

    @media only screen and (max-width: 454px) {
        width: 10px !important;
    }
`

export const ProfileInputWrap = styled.div `
    border: solid 1px ${(props:any) => props.theme.text};
    background-color: #1D1C1C;
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-radius: 12px;
    padding-left: 25px;

    .PhoneInputCountry {
        padding: 0px !important;
        background: transparent;
    }

    & input{
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        color: #fff;
    }

    & input::placeholder {
        color: #868686;
        font-size: 15px;
    }

    @media only screen and (max-width: 454px) {
        padding: 8px 10px;
        border-radius: 8px;

        input {
            font-size: 12px;
        }

        input::placeholder {
            font-size: 12px;
        }
    }
`

export const ProfileInputBox = styled.input `
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    border: solid 1px red;
    color: ${(props: any) => props.type === 'password' ? '#ffa100' : '#fff'} !important;
    
    &::placeholder {
        color: #868686;
    }
`

export const ChangeBtn = styled.div `
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 25px;
    border-radius: 5px;
    cursor: pointer;
    color: #000;
    font-weight: 700;
    font-size: 14px;
`

export const UsernameInfo = styled.div `
    text-align: start;
    color: ${(props:any) => props.theme.text};
    font-size: 12px;
    /* margin-top: 5px; */
    position: absolute;
    width: 100%;
    top: 60px;
    left: 0;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
    }

`