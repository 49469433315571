import styled from "styled-components";

export const AuthDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-bottom: 30px;
  margin-top: 0px;
  width: 100%;
  transition: 0.5s;
  padding: 0px 170px;

  @media only screen and (min-width: 768px) and (max-width: 1025px) {
    margin-top: -80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0px;
  }
`;
export const SignupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  background: #f6f6f6;
  width: 80%;
  border-radius: 7px;
  padding: 2em;
  justify-content: center;
`;
export const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 2px 2px 5px #ddd;
`;

export const Button = styled.button`
  background: #ffa100;
  color: #000;
  width: 100%;
  border-radius: 30px;
  padding: 13px 10px;
  margin-top: 3rem;
  width: 55%;
  outline: none;
  border: none;
  font-size: 0.85rem;
  font-weight: bold;
`;
export const DisabledButton = styled.button`
  background: #000;
  color: #aaa;
  width: 100%;
  border-radius: 30px;
  padding: 13px 10px;
  margin-top: 3rem;
  width: 55%;
  outline: none;
  border: none;
  font-size: 0.85rem;
  font-weight: bold;
`;
export const Div = styled.div`
  width: 100%;
  padding: 0rem 2.4rem;
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 30px;
`;
export const P = styled.p`
  font-size: 0.8rem;
  width: 50%;
  text-align: left;
  color: #868686;
`;
export const H3 = styled.h3`
  font-weight: bold;
  color: ${(props: any) => props.theme.white};
`;

export const Text = styled.label`
  color: ${(props: any) => props.theme.white};
`;
