import styled from 'styled-components'




export const ModalContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
background: rgba(204, 204, 204, 0.96);
height:100vh;
width:100%;
position: fixed;
top:0px;
z-index:100;
left:0px;
bottom:0px;
`
export const Label = styled.p`
color: #000;
font-size:12px;
font-weight:600;
width:100%;
`

export const ImageContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:#000;
width:100%;
border-radius:20px;
object-fit:contain;
position: absolute;
top:30px;
z-index: 10;
height:140px;
`
export const TextContainer = styled.div`

background:#fff;
width:100%;
border-radius:20px;
bottom:20px;
padding:15px;
z-index: 11;
position: absolute;
`

export const Modal = styled.div`
width:225px;
border-radius:10px;
position:relative;
height:330px;
position:absolute;
`
export const Flex = styled.div`
display:flex;
justify-content:space-around;
margin-top:10px;
gap:10px;

`
export const BackButton = styled.button`
background:#ffa100;
padding:6px 12px;
color:#000;
width:30%;
outline:none;
border:none;
border-radius:7px;
font-weight:700;
`
export const DeleteButton = styled.button`
background:red;
padding:6px 12px;
color:#fff;
width:70%;
outline:none;
border:none;
border-radius:7px;
display:flex:
align-items:center;
justify-content:center;
font-weight:700;

`

export const Text = styled.p`
font-family: Nanum Brush Script;
font-size: 20px;
font-weight: 400;
text-align: left;
color:#000;
margin:0;
`




export const SetButton = styled.button`
background:black;
padding:6px 12px;
color:#fff;
width:70%;
outline:none;
border:none;
border-radius:7px;
display:flex:
align-items:center;
justify-content:center;
font-weight:700;

`
export const CancelBtn = styled.button`
background:red;
padding:6px 3px;
color:#fff;
width:30%;
outline:none;
border:none;
border-radius:7px;
display:flex:
align-items:center;
justify-content:center;
font-weight:700;

`