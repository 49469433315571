import React, { useEffect, useState } from "react";
import {
  AccesCodeBtn,
  ActionsBottomWrap,
  ActionsLeftSection,
  ActionsRightSection,
  ActionsTopWrap,
  BalanceWrap,
  CancelTripWrap,
  CompletedRatingBox,
  DownloadReceiptBtn,
  EnvelopeImgWrap,
  MessageBtn,
  PayNowWrap,
  PriceBox,
  ProgressExtrasWrap,
  ReceiptBtn,
  TripsCardActionsOverall,
  TripsCardBottomSection,
  TripsCardIdentityWrap,
  TripsCardImgWrap,
  TripsCardInfoOverall,
  TripsCardTopSection,
  TripsCardWrap,
  TripsInfoWrap,
  TripStatus,
} from "./tripscardcomponent.styles";
import ReactStars from "react-rating-stars-component";
import { createSearchParams, useNavigate } from "react-router-dom";

import car from "../../assets/images/png/trips-card-car.png";
import driver from "../../assets/images/png/bookdriver.png";
import location from "../../assets/images/svg/location-black.svg";
import calendar from "../../assets/images/svg/calendar-icon.svg";
import clock from "../../assets/images/svg/clock-icon.svg";
import envelope from "../../assets/images/svg/envelope.svg";
import call from "../../assets/images/svg/yellow-call.svg";
import receipt from "../../assets/images/svg/ticket-receipt-icon.svg";
import ellipse from "../../assets/images/png/ellipse.png";
import darkReceipt from "../../assets/images/svg/dark-receipt.svg";
import gift1 from "../../assets/images/svg/gift1.svg";
import person from "../../assets/images/svg/person-icon.svg";
import userWhite from "../../assets/images/png/user-white.png";

import BookingTimeModal from "../bookingTimeModal";
import BookingCalendarModal from "../bookingCalendarModal";
import moment from "moment";
import { useGetProviderByIdQuery } from "../../services/providerApi";
import { Link } from "react-router-dom";
import BookingLocationModal from "../bookingLocationModal";
import CancelBookingModal from "../cancelBookingModal";
import {
  useRateServiceMutation,
  useUpdateBookingMutation,
} from "../../services/customerApi";
import { RootState, useAppSelector } from "../../store";
import ReactWhatsapp from "react-whatsapp";
import { baseImageUrl } from "../../services/utils";
import TicketModal from "../providerDashboard/providerRequests/ticketPopup";
import jsPDF from "jspdf";
import locate from "../../assets/images/png/locate.png";
import message from "../../assets/images/svg/message.svg";
import one from "../../assets/images/png/one.png";
import header from "../../assets/images/jpg/header.jpg";
import wallet from "../../assets/images/png/wallets.png";
import wallets from "../../assets/images/png/wallet.png";
import icon from "../../assets/images/png/pdficon.png";

const TripsCardComponent = ({
  tripDetails,
  amount,
  currencyCode,
  bookingImage,
  tripPending,
  ticketBooked,
  duePay,
  progress,
  completed,
  canceled,
  giftPending,
  bookingId,
  bookingTitle,
  category,
  from,
  to,
  time,
  destination,
  date,
  providerId,
  origin,
  idForBooking,
  vehicleId,
  handleCancelBooking,
  request,
  travelId,
}: any) => {
  const { id } = useAppSelector<any>((store: RootState) => store?.auth?.auth);

  let [imageBlob, setImageBlob] = useState(header);
  let [walletImageBlob, setWalletImageBlob] = useState(header);

  const { data, isFetching, refetch, isSuccess } = useGetProviderByIdQuery(
    providerId,
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  const [
    updateBooking,
    {
      data: updateData,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateBookingMutation();

  const [
    rateService,
    {
      data: rateData,
      isLoading: isRateLoading,
      isSuccess: isRateSuccess,
      isError: isRateError,
      error: rateError,
    },
  ] = useRateServiceMutation();

  const navigate = useNavigate();
  const params = { vehicleId: `${vehicleId}` };
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [distance, setDistance] = useState("");
  const [dateArr, setDateArr] = useState<any>([]);
  const [providerNo, setProviderNo] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [ratings, setRatings] = useState();
  const roadlersAdmin = "+2349044442200";
  const [isTicket, setIsTicket] = useState(false);
  const [passengerInfo, setPassengerInfo] = useState({});

  let [headerBlob, setHeaderBlob] = useState(header);
  let [walletBlob, setWalletBlob] = useState(wallet);
  let [iconBlob, setIconBlob] = useState(icon);

  const goToVehicleBooking = () => {
    navigate({
      pathname: `/car-booking/${vehicleId}`,
      // search: `?${createSearchParams(params).toString()}`,
    });
  };

  useEffect(() => {
    refetch();
    setProviderNo(data?.phone_number);
  }, [data]);

  const ratingChanged = (newRating: any) => {
    setRatings(newRating);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      setShowCancelModal(false);
    }

    if (isUpdateError && "status" in updateError!) {
    }
  }, [isUpdateSuccess, isUpdateError, updateError, updateData]);

  useEffect((): any => {
    if (date) {
      const array: any = JSON?.parse(date);
      setDateArr(array);
    }
  }, [date]);

  const handleRebook = () => {
    if (bookingTitle?.toLowerCase()?.includes("driver")) {
      return navigate("/driver-booking");
    }

    if (bookingTitle?.toLowerCase()?.includes("delivery")) {
      return navigate("/delivery-booking");
    }
    if (category === "Travel Garage Booking") {
      return navigate(`/ticket-page/${travelId}/${vehicleId}`);
    }

    goToVehicleBooking();
  };

  const handleRateService = () => {
    let formData: any = new FormData();

    formData.append("user_id", id);
    formData.append("booking_id", idForBooking);
    formData.append("star_rating", ratings);
    formData.append("comment", ratings);
    formData.append("satisfactory_level", ratings);

    rateService(formData);
  };
  // useEffect(() => {
  //   handleRateService()
  // }, [ratings])

  const handlePassenger = (passengerData) => {
    setPassengerInfo(passengerData);
    setIsTicket((prev) => !prev);
  };
  const passengersDetails = request?.passenger?.filter((passenger) => {
    return passenger?.user_id == id;
  });

  const handleReceipt = () => {
    navigate("/receipt-page", { state: { tripDetails } });
  };

  return (
    <>
      <BookingTimeModal
        time={time}
        showTimeModal={showTimeModal}
        setShowTimeModal={setShowTimeModal}
      />

      <BookingCalendarModal
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
        dateArray={dateArr}
      />

      <BookingLocationModal
        previousOrigin={origin}
        previousDestination={destination}
        showLocationModal={showLocationModal}
        setShowLocationModal={setShowLocationModal}
        withinOrWithout={"within"}
        setDistance={setDistance}
      />

      <CancelBookingModal
        setShowCancelModal={setShowCancelModal}
        showCancelModal={showCancelModal}
        handleCancelBooking={() => handleCancelBooking(idForBooking)}
      />

      <TripsCardWrap>
        {(tripPending || giftPending) && (
          <TripStatus bg={"#005FEE"} text={"#fff"}>
            Pending
          </TripStatus>
        )}

        {ticketBooked && (
          <TripStatus bg={"#AA6C05"} text={"#fff"}>
            Booked
          </TripStatus>
        )}

        {duePay && (
          <TripStatus bg={"#FF3A3A"} text={"#000"}>
            Payment Due
          </TripStatus>
        )}

        {progress && (
          <TripStatus bg={"#FFA100"} text={"#000"}>
            In-progress
          </TripStatus>
        )}

        {completed && (
          <TripStatus bg={"#07B418"} text={"#000"}>
            Completed
          </TripStatus>
        )}

        {canceled && (
          <TripStatus bg={"#D52B1E"} text={"#fff"}>
            Canceled
          </TripStatus>
        )}

        {/* CARD TOP SECTION */}
        <TripsCardTopSection>
          {!giftPending && (
            <TripsCardImgWrap imgFit={"cover"}>
              {bookingTitle?.toLowerCase()?.includes("driver") ? (
                <img src={driver} />
              ) : (
                <img src={baseImageUrl + bookingImage} />
              )}
            </TripsCardImgWrap>
          )}

          {giftPending && (
            <TripsCardImgWrap imgFit={"contain"}>
              <img src={gift1} />
            </TripsCardImgWrap>
          )}

          <TripsCardIdentityWrap>
            <h5>ID: {bookingId}</h5>
            <h3>{!category?.includes("Travel Garage") && bookingTitle}</h3>
            <h6>{category?.includes("Travel Garage") && from + " to " + to}</h6>

            {ticketBooked && (
              <DownloadReceiptBtn>
                <p>Download Ticket</p>

                <img src={receipt} />
              </DownloadReceiptBtn>
            )}

            {duePay && (
              <PriceBox>
                <p>₦ 90,000</p>
              </PriceBox>
            )}

            {tripPending && (
              <PriceBox>
                <p>
                  {currencyCode}
                  {Number(amount).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </p>
              </PriceBox>
            )}

            {completed && (
              <PriceBox>
                <p>
                  {/* <p> {distance}</p> */} {currencyCode}
                  {Number(amount).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </p>
              </PriceBox>
            )}

            {canceled && (
              <PriceBox>
                {/* <p>{distance}</p> */}
                <p>
                  {currencyCode}

                  {Number(amount).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </p>
              </PriceBox>
            )}

            {progress && (
              <ProgressExtrasWrap>
                {/* <p>{distance}</p> */}
                <p>
                  {currencyCode}

                  {Number(amount)?.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </p>
                <img src={ellipse} style={{ marginTop: 10 }} />
              </ProgressExtrasWrap>
            )}
          </TripsCardIdentityWrap>
        </TripsCardTopSection>

        {/* CARD BOTTOM SECTION */}
        <TripsCardBottomSection>
          {/* LEFT BOTTOM */}
          <TripsCardInfoOverall>
            {giftPending && (
              <TripsInfoWrap>
                <img src={person} />
                <p>Paul</p>
              </TripsInfoWrap>
            )}

            {!giftPending && (
              <TripsInfoWrap onClick={() => setShowTimeModal(true)}>
                <img src={clock} />
                <p>{time}</p>
              </TripsInfoWrap>
            )}

            <TripsInfoWrap onClick={() => setShowLocationModal(true)}>
              <img src={location} />
              <p className="dest">{destination}</p>
            </TripsInfoWrap>

            <TripsInfoWrap onClick={() => setShowDateModal(true)}>
              <img src={calendar} />
              <p>
                {" "}
                {dateArr?.[0]}
                {dateArr?.length > 1 && ", ..."}
              </p>
            </TripsInfoWrap>
          </TripsCardInfoOverall>

          {/* RIGHT BOTTOM */}
          <TripsCardActionsOverall>
            <ActionsLeftSection>
              {!progress && !completed && !canceled && !giftPending && (
                <EnvelopeImgWrap>
                  {/* <img src={envelope} /> */}
                </EnvelopeImgWrap>
              )}
            </ActionsLeftSection>

            <ActionsRightSection>
              <ActionsTopWrap>
                {tripPending && (
                  <CancelTripWrap onClick={() => setShowCancelModal(true)}>
                    <p>Cancel</p>
                  </CancelTripWrap>
                )}

                {ticketBooked && (
                  <CancelTripWrap onClick={() => setShowCancelModal(true)}>
                    <p>Cancel</p>
                  </CancelTripWrap>
                )}

                {duePay && (
                  <PayNowWrap>
                    <p>Pay Now</p>
                  </PayNowWrap>
                )}
                {completed && (
                  <ReceiptBtn onClick={handleReceipt}>
                    <p>Receipt</p>
                    <img src={darkReceipt} />
                  </ReceiptBtn>
                )}
                {canceled && (
                  // {completed && (

                  <ReceiptBtn onClick={handleReceipt}>
                    <p>Receipt</p>
                    <img src={darkReceipt} />
                  </ReceiptBtn>
                )}

                <br />

                {/* {category?.includes("Travel Garage") && (
                  <ReceiptBtn style={{ margin: "10px 0px" }}>
                    <p>Travel Ticket</p>
                    <img src={darkReceipt} />
                  </ReceiptBtn>
                )} */}

                {canceled && (
                  <ReceiptBtn onClick={handleRebook}>
                    <p>Re-book</p>
                  </ReceiptBtn>
                  //    <ReceiptBtn>
                  //    <p>Receipt</p>
                  //    <img src={darkReceipt} />
                  //  </ReceiptBtn>
                )}

                {giftPending && (
                  <BalanceWrap>
                    <p>₦ 50,000</p>
                    <div>Balance</div>
                  </BalanceWrap>
                )}
              </ActionsTopWrap>

              {!completed && !canceled && !giftPending && (
                <div style={{ marginTop: 20 }}>
                  <ActionsBottomWrap>
                    <Link to={"tel:" + providerNo}>
                      <CancelTripWrap>
                        <img src={call} />
                      </CancelTripWrap>
                    </Link>

                    <MessageBtn>
                      <ReactWhatsapp
                        number={roadlersAdmin}
                        style={{ padding: 0 }}
                      >
                        Helpline
                      </ReactWhatsapp>
                    </MessageBtn>
                  </ActionsBottomWrap>
                </div>
              )}

              {/* {completed && (
                <ActionsBottomWrap>
                  <CompletedRatingBox>
                    <h4>Give a Review</h4>
                    <div className="">
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={18}
                        activeColor="#ffd700"
                      />
                    </div>
                  </CompletedRatingBox>
                </ActionsBottomWrap>
              )} */}

              {giftPending && (
                <ActionsBottomWrap>
                  <AccesCodeBtn>Access Code</AccesCodeBtn>
                </ActionsBottomWrap>
              )}
            </ActionsRightSection>
          </TripsCardActionsOverall>
        </TripsCardBottomSection>
      </TripsCardWrap>

      {category?.includes("Travel Garage") ? (
        <div
          style={{
            marginTop: -85,
            marginBottom: 75,
            display: "flex",
            gap: 5,
            alignItems: "center",
            width: "100%",
            padding: "0px 40px",
          }}
        >
          {passengersDetails?.map((passenger, i) => {
            return (
              <div
                key={passenger?.id}
                onClick={() => handlePassenger(passenger)}
                style={{
                  //   border: "1px solid white",
                  padding: "5px",
                  borderRadius: 5,
                  background: "#AA6C05",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={userWhite} width={30} height={25} />
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      {isTicket && (
        <TicketModal
          setIsTicket={setIsTicket}
          request={request}
          passengerInfo={passengerInfo}
        />
      )}
    </>
  );
};

export default TripsCardComponent;
