import React, { useRef, useState, useEffect } from 'react'

import "./styles/InputImage.css"

import Resizer from "react-image-file-resizer";

import cancelBtn from "../../assets/images/svg/cancel-icon-yellow.svg"
import yellowCross from "../../assets/images/svg/yellow-cross.svg"
import camera from "../../assets/images/svg/camera.svg"

const InputImage = ({setImageContent}: any) => {

    const [blogImage, setBlogImage] = useState<any | undefined>()
    useEffect(() => {
        setImageContent(blogImage)
    })
    const blogImgRef: any = useRef()

    const blogImgChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setBlogImage(file)
        } else {
            setBlogImage(null)
        }
    }

    const removeBlogImg = () => {
        setBlogImage(null)
    }

    return <div className="image-input-wrapper">
        <div className="circle-top-add">
            <img src={yellowCross} alt="" />
        </div>

        <div className="circle-bottom-add">
            <img src={yellowCross} alt="" />
        </div>

        <input type="file" className='blog-image-input' accept='image/*' ref={blogImgRef} onChange={blogImgChange} />

        <div className="image-inner-wrapper">

            {
                !blogImage &&
                <div className="upload-action-box">
                    <div className="action-btn" onClick={(e) => {
                        e.preventDefault()
                        blogImgRef.current.click()
                    }}>
                        <img src={camera} alt="" />
                        <p>Upload Image</p>
                    </div>
                </div>
            }

            {
                blogImage &&
                <div className="blog-img-wrapper">
                    <img src={URL.createObjectURL(blogImage)} alt="Cover " />
                    <button onClick={removeBlogImg}><img src={cancelBtn} alt="cancel btn" /></button>
                </div>
            }

        </div>
    </div>
}

export default InputImage