import React, { useState } from 'react'
import CollectMoneyOne from '../collectMoneyOne'
import CollectMoneyThree from '../collectMoneyThree'
import CollectMoneyTwo from '../collectMoneytwo'
import FailedWalletComponent from '../failedWalletComponent'
import { ModalOverlay, CancelModalBox } from '../optionModal/optionmodal.styles'
import OtpWalletComponent from '../otpWalletComponent'
import PaymentMethodComponent from '../paymentMethodComponent'
import SuccessWalletComponent from '../successWalletComponent'
import { WalletModalBox, WalletModalHeader, WalletContentWrap, RoadlersBlackCircle, RoadlersHomeCone } from '../walletModal/walletmodal.styles'
import WithdrawWalletComponent from '../withdrawWalletComponent'
import { ToastContainer, toast } from 'react-toastify';

import roadlersCone from '../../assets/images/svg/homebutton.svg'
import logo from '../../assets/images/svg/logo-small.svg'
import Loader from '../loader'
import LittleLoader from '../littleLoader'


const CollectMoneyModal = ({showCollectModal, removeWalletModal, onCreatePaymentLink, collectMoneyAmount, setCollectMoneyAmount, collectMoneyDescription, setCollectMoneyDescription, collectMoneyCurrency, setCollectMoneyCurrency, collectMoneyPayer, setCollectMoneyPayer, collectMoneyError, showComponent, setShowComponent, collectMoneyData, isCreatePayLinkLoading }: any) => {

    const handleShowComponent = (type: any) => {
        setShowComponent(type)
    }

    const handleNotify = () => {
        notifySuccess("Link sucessfully copied")
    }

    const notifySuccess = (text: any) => toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })

  return (
    <ModalOverlay activeModal={showCollectModal}>
        <ToastContainer />
        <CancelModalBox onClick={() => {removeWalletModal(false)
            handleShowComponent('a')
        }} />
        <WalletModalBox>
            <RoadlersHomeCone src={roadlersCone} />
            <RoadlersBlackCircle>
                <img src={logo} />
            </RoadlersBlackCircle>
            <div className="inner-modal-box">
                <WalletModalHeader>
                    <div className=""></div>
                </WalletModalHeader>

                {
                    isCreatePayLinkLoading ? 
                    <LittleLoader /> : 
                    <WalletContentWrap>
                        <OtpWalletComponent  displaybox={showComponent === 'otp' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/> 
                        <SuccessWalletComponent  displaybox={showComponent === 'success' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/>
                        <FailedWalletComponent  displaybox={showComponent === 'failed' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/> 

                        <CollectMoneyOne  displaybox={showComponent === 'a' ? 'block' : 'none'} handleShowComponent={handleShowComponent} onCreatePaymentLink={onCreatePaymentLink} collectMoneyAmount={collectMoneyAmount} setCollectMoneyAmount={setCollectMoneyAmount} collectMoneyDescription={collectMoneyDescription} setCollectMoneyDescription={setCollectMoneyDescription} collectMoneyPayer={collectMoneyPayer} setCollectMoneyPayer={setCollectMoneyPayer} collectMoneyError={collectMoneyError}/> 

                        <CollectMoneyTwo  displaybox={showComponent === 'b' ? 'block' : 'none'} handleShowComponent={handleShowComponent} collectMoneyData={collectMoneyData} handleNotify={handleNotify}/>

                        <CollectMoneyThree  displaybox={showComponent === 'c' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/>

                        <PaymentMethodComponent displaybox={showComponent === 'd' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/>
                    </WalletContentWrap>
                }
            </div>
        </WalletModalBox>

    </ModalOverlay>
  )
}

export default CollectMoneyModal