import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ServiceOffering from '../../providerCarReg/serviceOffering/serviceOffering';

// import ToggleBar from '../../../components/toggleBar'

import { FlexDiv, P, LabelDiv, Label, Div, InputDiv, BlackDiv, PP, LabelTitle } from './services.styles';
// import { ModalOne } from '../../providerCarReg/seventhScreen/Modal';
import { BLACKDIV, DIV, INPUTDIV } from '../../providerCarReg/seventhScreen/seventhScreen.styles';
import { FaChevronDown } from 'react-icons/fa';
import { ModalOne, 
    // SetAvailability 
} from './Modal';


type Props = {
    vehicleProfile: any;
}
const Services: FC<Props> = ({vehicleProfile }) => {

    const navigate = useNavigate()
    const [isVisibleModalOne, setIsVisibleModalOne] = useState(false)
    const [isVisibleAvailability, setIsVisibleAvailability] = useState(false)

    const handleSpecifications = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsVisibleModalOne(prev => !prev)
    };
    const handleAvailability = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsVisibleAvailability(prev => !prev)

    };

    const category = vehicleProfile?.data[0]?.category;
    return (
        <>


            <div style={{ margin: '60px 0px' }}>

            </div>
            <LabelTitle >Services</LabelTitle>


            {
                category !== 'Bike Registration' &&

                <INPUTDIV style={{ marginTop: '30px' }} onClick={handleSpecifications}>
                    <P style={{ fontSize: '15px', color: '#000' }}>Vehicle Specifications</P>
                    <DIV>
                        <FaChevronDown style={{ color: '#000', fontSize: '20px', fontWeight: 'bold' }} />
                    </DIV>
                </INPUTDIV>
            }
            {isVisibleModalOne &&
                <ModalOne vehicleProfile={vehicleProfile}/>
            }

            {/* <INPUTDIV style={{ marginTop: '40px' }} onClick={handleAvailability}>
                <P style={{ fontSize: '15px', color: '#000' }}>Set Avaliablity</P>

                <BLACKDIV>
                    <DIV >
                        <PP>Set</PP>
                    </DIV>
                </BLACKDIV>
            </INPUTDIV>
            {isVisibleAvailability && <SetAvailability
                vehicleProfile={vehicleProfile}
            />} */}







        </>
    )
}

export default Services