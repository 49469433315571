import React, { useEffect, FC, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import profile from "../../../assets/images/png/profile.png";
import vehicle from "../../../assets/images/png/yellow_vehicle.png";
import { IoIosArrowBack } from "react-icons/io";
import { BsCarFrontFill, BsFillPersonVcardFill } from "react-icons/bs";

import {
  DIV,
  H2,
  BUTTON,
  H4,
  DivContainer,
  IconDiv1,
  LoaderContainer,
  Loader,
  Loader2,
  Dotted,
  Container,
  IconDivContainer,
  P,
} from "./secondScreen.styles";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/footer";
// import { setAccount,setServiceOffering } from '../../../slices/provider';
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  baseUrl,
  useProviderUpdateMutation,
} from "../../../services/providerApi";
import { CiLogout } from "react-icons/ci";
import { setAuth, setRole } from "../../../slices/auth";
import { setServiceOffering } from "../../../slices/provider";
import axios from "axios";

type Props = {
  providerDetails: any;
  setProviderDetails: any;
  setShowFirstScreen: any;
};
const SecondScreen: FC<Props> = ({
  setShowFirstScreen,
  providerDetails,
  setProviderDetails,
}) => {
  let navigate = useNavigate();

  const [providerUpdate, { data, isLoading, isSuccess, isError, error }] =
    useProviderUpdateMutation();
  const service = useAppSelector(
    (state: any) => state?.provider?.service_offering
  );
  const user = useAppSelector((state: any) => state?.auth?.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuccess) {
      // dispatch(setAuth(data?.data))
    }
  }, [isLoading, isSuccess, isError, data]);

  useEffect(() => {
    if (data?.data?.service === "driver") {
      navigate("/driver-registration");
    }
    if (data?.data?.service === "vehicle-owner") {
      navigate("/car-registration");
    }
  }, [isSuccess]);

  const registrationNotification = async () => {
    let formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("option", "provider");
    axios({
      method: "post",
      url: `${baseUrl}send-reg-notify`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        // const message = response.data.data;
        console.log("response data ", response.data);
      })
      .catch(function (error) {
        //handle error
        console.log("error ", error.response.data);
      });
  };

  const handleProviderUpdate = (serviceOffering: string) => {
    providerDetails.service_offering = serviceOffering;
    let formData: any = new FormData();
    formData.append("id", user?.id);
    formData.append("service", serviceOffering);
    formData.append("category", providerDetails.account_type);
    formData.append("user_role", "provider");
    providerUpdate(formData).then(() => {
      dispatch(setRole("provider"));
      registrationNotification();
    });
  };

  const handleDriver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleProviderUpdate("driver");
    dispatch(setServiceOffering("Driver"));
  };

  const handleVehicleOwner = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleProviderUpdate("vehicle-owner");
    dispatch(setServiceOffering("Vehicle"));
  };
  const handleShowFirstScreen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowFirstScreen(true);
  };

  return (
    <>
      <DIV>
        <BUTTON onClick={handleShowFirstScreen}>
          <IoIosArrowBack
            style={{ padding: "0px", marginTop: "5px", marginLeft: "-10px" }}
          />
        </BUTTON>
        <H2>Set up your account </H2>
      </DIV>
      <H4>Earn With Roadlers </H4>
      <LoaderContainer>
        <DivContainer>
          <Loader />
          <Loader />
        </DivContainer>

        <DivContainer>
          <Loader2 />
          <Loader2 />
        </DivContainer>

        <DivContainer>
          <Loader2 />
          <Loader2 />
        </DivContainer>
      </LoaderContainer>
      <H4>What’s your service offering? </H4>

      <Container>
        <IconDivContainer>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <IconDiv1 onClick={handleVehicleOwner}>
              <Dotted
                style={
                  service === "Vehicle"
                    ? { background: "#ffa100" }
                    : { background: "#fff" }
                }
              />
              <BsCarFrontFill
                style={
                  service === "Vehicle"
                    ? { fontSize: "50px", color: "#ffa100" }
                    : { fontSize: "50px", color: "#000" }
                }
              />

              <p
                style={{
                  fontWeight: "600",
                  marginTop: "5px",
                  marginBottom: "0px",
                  color: "#000",
                  textDecoration: "none",
                }}
              >
                Vehicle Owner
              </p>
            </IconDiv1>

            <P>List a vehicle to move people or goods</P>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <IconDiv1 onClick={handleDriver}>
              <Dotted
                style={
                  service === "Driver"
                    ? { background: "#ffa100" }
                    : { background: "#fff" }
                }
              />
              <BsFillPersonVcardFill
                style={
                  service === "Driver"
                    ? { fontSize: "50px", color: "#ffa100" }
                    : { fontSize: "50px", color: "#000" }
                }
              />
              <p
                style={{
                  fontWeight: "600",
                  marginTop: "5px",
                  marginBottom: "0px",
                  color: "#000",
                }}
              >
                Driver
              </p>
            </IconDiv1>

            <P>Create a profile to drive people on Roadlers</P>
          </div>
        </IconDivContainer>
      </Container>
    </>
  );
};

export default SecondScreen;
