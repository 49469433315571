import { Important } from './../providerAccountSetup/firstScreen/firstScreen.styles';
import { AboutBox } from './../../pages/user/Homepage/homepage.styles';
import styled from 'styled-components'

export const  AlertWrapper = styled.div `
    border: solid 1px ${(props:any) => props.theme.text};
    background-color: #fff;
    border-radius: 9px;
    padding: 0 10px;
    width: 390px;

    & .inner-wrapper {
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap: 15px; */

        a {
            width: 100%;
            text-decoration: none;
            display: flex;
            justify-content: center;
        }

        h3 {
            color: #000;
            margin: 10px 0;
            font-weight: 700;
            font-size: 17px;
            text-align: center;
        }

        p {
            color: #6F6F71;
            font-size: 14px;
            font-weight: 600;
            margin-top: 30px;
        }

        .image-wrap {
            margin: 30px 0;
            margin-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 454px) {
        width: 330px;

        .inner-wrapper {
            h3 {
                font-size: 13px;
            }

            p {
                font-size: 11px;
            }
        }
    }
`

export const FailedAlertWrapper = styled(AlertWrapper) `
    padding: 0 15px;
    width: 350px;

    .image-wrap {
        margin-bottom: 30px !important;
        img {
            width: 170px !important;
        }
    }

    .progress-wrap {
        margin-top: 0px !important;
    }

    p {
        font-size: 16px !important;
    }

    .try-btn {
        h5 {
            color: #FFD58C;
        }
    }

    @media only screen and (max-width: 454px) {
        width: 330px;

        .image-wrap {
            img {
                width: 140px !important;
            }
        }

        p {
            font-size: 12px !important; 
        }
    }
`

export const ButtonFooter = styled.div `
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props:any) => props.btnBg};
    padding: 15px 25px;
    border-radius: 9px;
    cursor: pointer;
    width: 85%;

    & h5 {
        margin: 0;
        color: #fff;
        font-weight: 700;
        font-size: 17px;
    }

    @media only screen and (max-width: 454px) {
        padding: 10px 15px;
        border-radius: 4px;

        h5 {
            font-size: 13px;
        }
    }
`

export const ProgressWrap = styled.div ` 
    position: relative;
    display: block;
    width: 100%;
    margin-top: 20px;
`

export const ProgressBox = styled.div `
    position: relative;
    display: block;
    background-color: #000;
    border-radius: 3px;
    height: 3px;

    & .inner-progress-box {
        width: 30%;
        background-color: #ffa100;
        height: 100%;
        border-radius: inherit;
        position: relative;

        img {
            position: absolute;
            right: -50px;
            bottom: -27px;
            width: 100px;
        }
    }
`

export const InsuranceInfoBox = styled.div `
    margin: 30px 0;
    display: ${(props:any) => props.display};
    & h4 {
        color: #000;
        text-align: center;
        font-size: 19px;
        font-weight: 700;
        margin: 0;
    }

    & h6 {
        font-size: 13px;
        font-weight: 600;
        color: #6F6F71;
        margin: 10px 0;
    }
`

export const InsuranceInfoFlexOverall = styled.div `
    display: flex;
    gap: 15px;
    margin-top: 20px;

    & form {
        flex: 50%;
        width: 50%;
        display: block;

        label {
            color: #000;
            font-size: 12px;
            margin-left: 10px;

            span {
                color: #FF3A3A;
            }
        }
    }
`

export const SelectInput = styled.div `
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props:any) => props.bg};
    border: solid 1px #fff;
    border-radius: 9px;
    position: relative;
    padding: 10px 14px;
    gap: 5px;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.55);
    box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.55);

    & p {
        color: #868686;
        font-weight: 700;
        font-size: 12px !important;
        flex: 80%;
        margin: 0 !important;
    }

    & img {
        flex: 10%;
        width: 22px;
    }

    & input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;

        &::placeholder {
            color: #868686;
            font-size: 12px;
        }
    }

    & select {
        width: 100% !important;
        border: none !important;
        outline: none !important;
        background-color: transparent !important; 
        color: #fff !important;
    }
`

export const AddImageSection = styled.div `
    margin-top: 30px;

    & h4 {
        color: #000;
        font-weight: 600;
        font-size: 15px;
        margin: 0;
        text-align: unset;
    }
`

export const AddImageBox = styled.div `
    position: relative;
    border-radius: 13px;
    margin-top: 15px;
    overflow:hidden;

    & img {
        width: 100%;
    }
`

export const AddImageBtn = styled.div`
    border-radius: 4px;
    background-color: #000;
    display: flex;
    align-items: center;
    padding: 7px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;

    img {
        width: 23px;
    }
`

