import styled from 'styled-components'



export const ModalContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
background: rgba(204, 204, 204, 0.96);
height:100vh;
width:100%;
position: fixed;
top:0px;
z-index:100;
left:0px;
bottom:0px;
`

export const Label = styled.p`
color: #000;
font-size:13px;
font-weight:600;
display:flex;
flex-wrap:wrap;
width:100%;
white-space: pre-wrap;
word-wrap: break-word;
`

export const ImageContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:#000;
width:100%;
border-radius:20px;
object-fit:contain;
position: absolute;
top:10px;
z-index: 10;

`
export const TextContainer = styled.div`

background:#fff;
width:100%;
border-radius:20px;
bottom:35px;
padding:25px 15px 15px 15px;
z-index: 11;
position: absolute;
`

export const Modal = styled.div`
width:218px;
border-radius:10px;
position:relative;
height:290px;
position:absolute;

`
export const Flex = styled.div`
display:flex;
justify-content:space-around;
margin-top:10px;
gap:10px;

`
export const BackButton = styled.button`
background:#000;
padding:8px 12px;
color:#fff;
width:100%;
outline:none;
border:none;
border-radius:7px;
`
export const DeleteButton = styled.button`
background:#E70909;
padding:8px 12px;
color:#fff;
width:100%;
outline:none;
border:none;
border-radius:7px;
display:flex:
align-items:center;
justify-content:center;
`











