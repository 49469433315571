import styled from "styled-components";

export const ReceiptBodyWrap = styled.div`
  min-height: 90vh;
  padding: 060px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #242321;

  .inner-receipt-body-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
    width: 80%;
  }
`;

export const ReceiptHead = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  padding: 0 20px;

  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  p {
    font-size: 19px;
    margin: 0;
    font-weight: 600;
  }
`;

export const ReceiptSummaryTop = styled.div`
  width: 100%;

  .date-type-divide {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      font-size: 13px;
    }
  }

  .card-wrap {
    margin-top: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .top-section,
    .bottom-section {
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      .text-side {
        h4 {
          color: #fff;
          font-size: 20px;
          margin: 0;
          white-space: wrap;
        }
      }

      .image-side {
        img {
          width: 120px;
          height: 120px;
          border-radius: 14px;
          object-position: center;
          object-fit: cover;
        }
      }
    }

    .bottom-section {
      background-color: #ffd58c;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      padding-top: 20px;

      h4 {
        color: #000;
        font-size: 23px;
        font-weight: 700;
        margin: 0;
      }
    }
  }
`;

export const ReceiptSummaryBottom = styled.div`
  width: 100%;
  padding: 0 20px;

  .rate-type-header {
    padding: 12px;

    p {
      color: #ffd58c;
      font-size: 12px;
      font-weight: 600;
      margin: 0;
    }
  }

  .trip-summary-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 15px 12px;

    .wrap {
      color: #fff;
      font-weight: 600;
      font-size: 13px;
    }
  }
`;

export const TripSummaryBox = styled.div`
  border-radius: 12px;
  background-color: #fff;
  margin-top: 20px;
  width: 100%;

  .inner-trip-wrap {
    padding: 15px;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left-text,
    .right-text {
      color: #868686;
      font-size: 12px;
      margin: 0;
      font-weight: 600;
    }

    .right-text {
      color: #000;
    }
    .left-text {
      flex: 1;
    }
  }
`;

export const ReceiptActionFooter = styled.div`
  width: 100%;

  .receipt-footer-wrap {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
`;

export const SubfooterCards = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;

  img {
    width: 12px;
  }

  p {
    color: #ffd58c;
    margin: 0;
    font-size: 11px;
    font-weight: 600;
  }
`;
