import React, { useEffect, useState } from "react";
import {
  CardFooter,
  DistanceWrap,
  DriverName,
  DriverRadioBtn,
  PickDriverCard,
  PricePerDay,
  PricePerDayOverall,
  Rating,
  RidesCount,
  TopImgWrap,
} from "./pickdriver.styles";

import driverImg from "../../assets/images/png/phebe.png";
import star from "../../assets/images/svg/starr.svg";

const PickDriver = ({
  name,
  price,
  currency,
  activeStatus,
  latitude,
  longitude,
  pickupLong,
  pickupLat,
  driverServiceName,
  servicePrice,
  driverId,
  setDriverName,
  handleDriver,
  handleDriverPrice,
  travelState,
  travelPrice,
  driverPicture,
  imgLink,
}: any) => {
  const [distBtw, setDistBtw] = useState<any>("");
  const [amount, setAmount] = useState<any>("");

  console.log(Number(latitude), "lat");

  useEffect(() => {
    const distance = getDistanceFromLatLonInKm(
      pickupLat,
      pickupLong,
      Number(latitude),
      Number(longitude)
    );
    setDistBtw(distance < 1 ? "0.1" : distance);
    console.log("distance", distance);
    console.log("pickupLat", pickupLat);
    console.log("pickupLong", pickupLong);
    console.log("latitude", latitude);
    console.log("longitude", longitude);
  }, [latitude, longitude, pickupLat, pickupLong]);

  // GET DISTANCE FROM LATITUDE AND LONGITUDE
  function getDistanceFromLatLonInKm(
    lat1: any,
    lon1: any,
    lat2: any,
    lon2: any
  ) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg: any) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    if (travelState) {
      let filtered = travelPrice;

      if (travelState) {
        filtered = filtered?.filter((item: any) =>
          item?.destination?.toLowerCase()?.includes(travelState?.toLowerCase())
        );
      }

      setAmount(filtered);
    } else {
      let filtered = servicePrice;

      if (driverServiceName) {
        filtered = filtered?.filter((item: any) =>
          item?.service_title
            ?.toLowerCase()
            ?.includes("Hourly Rate"?.toLowerCase())
        );
      }

      setAmount(filtered);
    }
  }, [driverServiceName, travelState]);

  // console.log(servicePrice, amount[0]?.service_price, 'price')

  const handleDriverClick = () => {
    handleDriver(driverId);
    handleDriverPrice(Number(amount[0]?.service_price));
    setDriverName(name);
  };

  return (
    <PickDriverCard onClick={handleDriverClick}>
      <DistanceWrap>
        <p>{Math.ceil(distBtw)} km Away</p>
      </DistanceWrap>
      <div className="inner-wrap">
        <div className="card-top">
          <TopImgWrap>
            <img src={`${imgLink}${driverPicture}` || driverImg} />
          </TopImgWrap>

          <DriverRadioBtn active={activeStatus} />
        </div>

        <DriverName>{name}</DriverName>

        <PricePerDayOverall>
          {travelState ? (
            <PricePerDay>
              {currency}
              {`${Number(amount?.[0]?.service_price)?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}`}
              /travel
            </PricePerDay>
          ) : (
            <PricePerDay>
              {currency}
              {`${Number(amount?.[0]?.service_price)?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}`}
              /{driverServiceName?.includes("Full day price") && "day"}{" "}
              {driverServiceName?.includes("Night service") && "night"}{" "}
              {driverServiceName?.includes("Weekly service") && "week"}{" "}
              {driverServiceName?.includes("Monthly service") && "month"}{" "}
              {driverServiceName?.includes("Rate per hour") && "hour"}
            </PricePerDay>
          )}
        </PricePerDayOverall>

        <CardFooter>
          <RidesCount>20 Rides</RidesCount>

          <Rating>
            <img src={star} />
            <p>4.5</p>
          </Rating>
        </CardFooter>
      </div>
    </PickDriverCard>
  );
};

export default PickDriver;
