import styled from 'styled-components'



export const DIV = styled.div`
display:flex;
gap:20px;
justify-content:space-between;
flex-direction:column;
width:100%;

`

export const INPUTS = styled.input`
width:50%;
border-radius:7px;
font-size:17px;
font-weight:600;
`

export const P = styled.p`
color: #FFA100;
margin-left:13px;
font-size:15px;
margin-bottom:5px;
`
export const FLEXDIV = styled.div`
display:flex;
width:100%;
gap:20px;
padding:0px;

`
export const FLEXDIV2 = styled.div`
display:flex;
width:100%;
gap:5px;
background:#fff;
border-radius:7px;
overflow:hidden;
margin-top:10px;
border:none;

`
export const SELECTDIV = styled.div`

display:flex;
width:100%;
border-radius:7px;
background:#fff;
overflow:hidden;
align-items:center;
padding:18px 15px;
justify-content:space-between;
margin-bottom:-20px;
`
export const ICONDIV = styled.div`
background:#000;
color:#fff;
width:22%;
display:flex;
align-items:center;
justify-content:center;
font-size:28px;
border-radius:0px 8px 8px 0px;
`


export const Input = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:white;
width:100%;
border-radius:7px;
overflow:hidden;
margin-top:10px;
border:none;
`



export const Text = styled.div`
font-size:16px;
margin-top:3px;
color:#000;
font-weight:600;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`
export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-bottom:-40px;
position:absolute;
left:-100px;
width:360px;
z-index:1000;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:60%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`



`
export const Radio = styled.button`
border:3px solid #000;
padding:4px;
border-radius:50px;
`

export const Button = styled.button`
background: #FFA100;
color: #000;
border-radius:7px;
padding:8px 20px;
margin-top:3rem;
outline:none;
border:none;
font-size:16px;
font-weight:bold;
`
export const GuarantorLabel = styled.label`
background: #059BBF;
color:#fff;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:75px;
margin-bottom:55px;
`