import React, { useEffect, useState } from "react";
import {
  UserLocationWrap,
  LocationInputWrap,
  InputIcons,
  InputBtn,
} from "../locationModal/locationmodal.styles";
import {
  ModalOverlay,
  ModalBox,
  CancelModalBox,
} from "../optionModal/optionmodal.styles";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import {
  Calendar,
  DateObject,
  getAllDatesInRange,
} from "react-multi-date-picker";
import moment from "moment";

import calendar from "../../assets/images/svg/calendar.svg";
import {
  DatePreferencePill,
  DatePrefWrap,
  FooterBtn,
  InputSqrBtn,
  RadioPill,
  TimeBox,
  TimeDateModalBox,
} from "./timedatemodal.styles";
import { PillWrapper } from "../../pages/user/Homepage/homepage.styles";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { setShowTimeDate } from "../../slices/showTimeDate";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactWhatsapp from "react-whatsapp";
import { setShowExtraNote } from "../../slices/showExtraNote";

const TimeDateModal = ({
  vehicleId,
  activeModal,
  setTimeBtn,
  handleBookedDate,
  handlePickupTime,
  previousPickupDate,
  previousPickupTime,
  tripOptionsChosen,
  bookedDate,
  setDays,
}: any) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const dispatch: AppDispatch = useAppDispatch();
  const { showTimeDate } = useAppSelector<any>(
    (store: RootState) => store?.showTimeDate
  );
  const today = new Date();
  dayjs.extend(advancedFormat);
  const getToday = moment(today).format("MM/DD/YYYY");
  const [state, setState] = useState<any>({});
  const [value, setValue] = useState<any>([]);
  const [allDates, setAllDates] = useState<any>([]);
  const [randomDaysPill, setRandomDaysPill] = useState("random");
  const [multipleDays, setMultipleDays] = useState(true);
  const [rangeDaysPill, setRangeDaysPill] = useState("");
  const [rangeDays, setRangeDays] = useState(false);
  const [clockDisplay, setClockDisplay] = useState("none");
  const [dateDisplay, setDateDisplay] = useState("block");
  const [minHoursAvailable, setMinHoursAvailable] = useState(0);
  const [timeValue, setTimeValue] = useState<any>("");
  const [startDates, setStartDates] = useState(new Date());

  const theme = createTheme({
    palette: {},
  });

  const rangeArray: any = [];
  const randomArray: any = [];

  const format = "MM/DD/YYYY";

  const timeDateModalControl = () => {
    dispatch(setShowTimeDate(false));
  };

  useEffect(() => {
    if (previousPickupTime || previousPickupDate) {
      setAllDates(previousPickupDate);
      setValue(previousPickupDate);
      handlePickupTime(previousPickupTime);
      setTimeValue(dayjs(previousPickupTime, "hh:mm a "));
      return;
    } else {
      setTimeValue(today);
    }
  }, [previousPickupDate, previousPickupTime]);

  const selectDays = [
    "02/05/2023",
    "02/09/2023",
    "02/15/2023",
    "02/13/2023",
    "02/21/2023",
  ];

  // Array of days to disable (e.g., ["Monday", "Wednesday"])
  const disabledDaysOfWeek = ["Monday", "Wednesday"];

  // Function to check if a given date is a disabled day of the week
  const isDisabledDayOfWeek = (date: any) => {
    const dayOfWeek = dayjs(date).format("dddd");
    return disabledDaysOfWeek.includes(dayOfWeek);
  };

  // Function to disable specific days of the week
  const disableSpecificDaysOfWeek = (date: any) => {
    return isDisabledDayOfWeek(date);
  };

  // Changing date preference
  const setDaysRandom = () => {
    setRandomDaysPill("random");
    setRangeDaysPill("");
    setMultipleDays(true);
    setRangeDays(false);
  };

  const setDaysRange = () => {
    setRandomDaysPill("");
    setRangeDaysPill("range");
    setMultipleDays(false);
    setRangeDays(true);
  };

  const handleDateDisplay = () => {
    setDateDisplay("block");
    setClockDisplay("none");
  };
  let [previousMinute, setPreviousMinute] = useState<null | number>(null);

  const handleTimeDisplay = () => {
    setDateDisplay("none");
    setClockDisplay("block");
  };

  // PICKING DATE
  const allDatesValue = () =>
    allDates?.forEach((date: any) => {
      return rangeArray.push(date?.format());
    });

  const datesValue = () =>
    value?.map((date: any, index: any) => {
      return randomArray.push(date?.format());
    });

  const handleOnDateChange = () => {
    if (rangeDays === true) {
      allDatesValue();
      handleBookedDate(rangeArray);
      handlePickupTime(dayjs(timeValue).format("h:mm A"));

      dispatch(setShowTimeDate(false));
      dispatch(setShowExtraNote(true));
    } else {
      datesValue();
      handleBookedDate(randomArray);
      handlePickupTime(dayjs(timeValue).format("h:mm A"));
      dispatch(setShowTimeDate(false));
      if (currentLocation === "NG") {
        dispatch(setShowExtraNote(true));
      } else {
        return;
      }
    }
  };

  // USEFFECT I want to call the handlebookeddate function once a date has been picked
  useEffect(() => {
    if (value?.length > 0 || allDates?.length > 0) {
      if (rangeDays === true) {
        allDatesValue();
        handleBookedDate(rangeArray);
        console.log("work-range");
      } else {
        datesValue();
        handleBookedDate(randomArray);
        console.log("work-random");
      }
    }
  }, [value, allDates]);

  let hr = today.getHours();
  // USEEFFECT TO DETERMINE WHETHER OR NOT TO DISABLE TIME
  useEffect(() => {
    if (
      moment(bookedDate?.[0]).format("YYYY/MM/DD") ===
      moment(today).format("YYYY/MM/DD")
    ) {
      setMinHoursAvailable(hr + 6);
    } else {
      setMinHoursAvailable(0);
    }
  }, [startDates, today]);

  const threePM = dayjs().set("hour", minHoursAvailable).startOf("hour");

  const dynamicLink = `https://m.roadlers.com/car-rental/${vehicleId}`;

  const message = `Please, I want to make a special booking request of this vehicle. 
    Check this link for more details: ${dynamicLink}`;
  return (
    <ModalOverlay activeModal={showTimeDate}>
      <CancelModalBox onClick={() => timeDateModalControl()} />
      <div className="modal-inner-wrapper">
        {/* CLOCK DISPLAY */}
        <TimeDateModalBox boxDisplay={clockDisplay}>
          <div className="inner-modal-box">
            &nbsp;
            <TimeBox>
              &nbsp;
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  className={"localizationCustomStyle"}
                  dateAdapter={AdapterDayjs}
                >
                  <StaticTimePicker
                    displayStaticWrapperAs="mobile"
                    value={timeValue || dayjs(previousPickupTime, "HH:mm A ")}
                    onChange={(newValue) => {
                      setTimeValue(newValue);
                      if (newValue) {
                        const selectedMinutes = newValue.minute();
                        // Check if the minute was manually changed by the user.
                        if (previousMinute !== null && !isNaN(previousMinute)) {
                          // handleDateDisplay();
                          // handleOnDateChange();
                        }
                        setPreviousMinute(selectedMinutes);
                      }
                    }}
                    minTime={threePM}
                    renderInput={(params) => <TextField {...params} />}
                    // @ts-ignore
                    sx={{ background: "transparent" }}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </TimeBox>
            <UserLocationWrap>
              <div className="where-pill-wrap">
                <div className="yellow-dash"></div>
              </div>

              <div className="location-input-overall">
                <h3> Select Your Pickup Time Above</h3>

                <LocationInputWrap onClick={handleDateDisplay}>
                  <input
                    type="text"
                    placeholder="Select date"
                    value={bookedDate || previousPickupDate}
                  />

                  <InputSqrBtn>
                    <InputIcons src={calendar} iconWidth={"17px"} />
                  </InputSqrBtn>
                  <FooterBtn onClick={handleOnDateChange}>Done</FooterBtn>
                </LocationInputWrap>

                <p className="booking-info">
                  Booking can only be done 6 hours in advance for a more
                  immediate booking -{" "}
                  <span>
                    <ReactWhatsapp number="+2349044442200" message={message}>
                      speak to support
                    </ReactWhatsapp>{" "}
                  </span>
                </p>
              </div>
            </UserLocationWrap>
          </div>
          &nbsp;
        </TimeDateModalBox>

        {/* CALENDAR DISPLAY */}
        <TimeDateModalBox boxDisplay={dateDisplay}>
          <div className="inner-modal-box">
            <div className="calendar-wrap">
              <div className="header-wrap">
                <h3>Select Your Date Preference </h3>
              </div>

              <div className="select-type-wrap">
                <DatePrefWrap onClick={setDaysRandom}>
                  <RadioPill pillActive={randomDaysPill} />
                  <DatePreferencePill pillActive={randomDaysPill}>
                    Random Days
                  </DatePreferencePill>
                </DatePrefWrap>

                <DatePrefWrap onClick={setDaysRange}>
                  <RadioPill pillActive={rangeDaysPill} />
                  <DatePreferencePill pillActive={rangeDaysPill}>
                    Start Date
                  </DatePreferencePill>

                  <DatePreferencePill pillActive={rangeDaysPill}>
                    End Date
                  </DatePreferencePill>
                </DatePrefWrap>
              </div>

              <div className="subwrapper">
                <Calendar
                  value={value}
                  // onChange={setValue}
                  format={"MM/DD/YYYY"}
                  multiple={multipleDays}
                  sort
                  range={rangeDays}
                  // @ts-ignore
                  calendarPosition="bottom-center"
                  rangeHover={rangeDays}
                  // disabled
                  mapDays={({ date }: any) => {
                    let props: any = {};

                    if (selectDays.includes(date.format("MM/DD/YYYY")))
                      props.disabled = true;

                    return props;
                  }}
                  onChange={(dateObjects) => {
                    setValue(dateObjects);
                    // @ts-ignore
                    setAllDates(getAllDatesInRange(dateObjects));
                  }}
                  disabledDays={disableSpecificDaysOfWeek}
                  minDate={new Date()}
                  // plugins={[
                  //     <DatePanel markFocused />
                  //   ]}
                />
              </div>

              <div className="footer-btn-wrap">
                <FooterBtn
                  active={true}
                  onClick={() => timeDateModalControl()}
                  // onClick={handleTimeDisplay}
                >
                  Close
                </FooterBtn>
                {allDates?.length < 1 ? (
                  ""
                ) : (
                  <FooterBtn
                    active={false}
                    // onClick={handleOnDateChange}
                    onClick={handleTimeDisplay}
                  >
                    Done
                  </FooterBtn>
                )}
              </div>
            </div>
            {/* <ul>
                        {
                             // @ts-ignore
                            allDates.map((date,index) => <li key={index}>{date.format()}</li>)
                        }
                    </ul> */}
          </div>
        </TimeDateModalBox>
      </div>
    </ModalOverlay>
  );
};

export default TimeDateModal;
