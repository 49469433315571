import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Label,
  Flex,
  ImageContainer,
  TextContainer,
  BackButton,
  DeleteButton,
  ModalContainer,
} from "./popUp.styles";
import deleteImage from "../../../assets/images/png/modalOption.png";
import spinner from "../../../assets/images/gif/spinner.gif";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import {
  useDeleteDriverMutation,
  useDeleteVehicleMutation,
} from "../../../services/providerApi";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setAllVehicle } from "../../../slices/provider";
import {
  baseUrl,
  useVerifyDriverMutation,
  useVerifyVehicleMutation,
} from "../../../services/adminApi";
import { useNavigate } from "react-router-dom";
import { clearAuth, setRole } from "../../../slices/auth";

export const CancelModal = ({ setDeleteAccount }) => {
  const token = useAppSelector((state: any) => state?.auth?.auth?.token);
  const [isLoading, setisLoading] = useState<any>(false);
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const closeCancelModal = () => {
    setDeleteAccount(false);
  };
  const handleDelete = async () => {
    await axios
      .get(`${baseUrl}delete-account`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        notifySuccess("Account deleted successfully");
        dispatch(clearAuth({}))
        dispatch(setRole(''))

        localStorage.clear()
        navigate("/login");
      })
      .catch((err) => {});
  };

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />

      <ModalContainer onClick={() => closeCancelModal()}>
        <Modal>
          <ImageContainer>
            <img src={deleteImage} style={{ width: "100%" }} />
          </ImageContainer>
          <TextContainer>
            <Label>
              Are you sure you want to Delete this Account? All data attached to
              this account would be lost
            </Label>
            <Flex>
              <BackButton onClick={() => closeCancelModal()}>Cancel</BackButton>

              <>
                {isLoading ? (
                  <DeleteButton>
                    loading <img src={spinner} width={50} />
                  </DeleteButton>
                ) : (
                  <DeleteButton onClick={() => handleDelete()}>
                    Delete
                  </DeleteButton>
                )}
              </>
            </Flex>
          </TextContainer>
        </Modal>
      </ModalContainer>
    </>
  );
};
