import styled from 'styled-components'



export const DIV = styled.div`
display:flex;
align-items:center;
height:120px;
gap:15px;
@media only screen and (min-width: 700px) {
    height:220px;
}

`
export const ArrowDiv = styled.div`
display:flex;
align-items:center;
gap:15px;
padding:70px 0px 30px 0px;
color:${(props: any) => props.theme.white};

`

export const IMAGEDIV = styled.div`
display:flex;
width:59%;
border:1px solid ${(props: any) => props.theme.darkborder};
border-radius:15px;
height:100%;
position:relative;
justify-content:center;
align-items:center;
overflow:hidden;

`
export const IMAGESDIV = styled.div`
display:flex;
align-items:center;
width:41%;
flex-wrap:wrap;
gap:10px;
border-radius:6px;
height:100%;
`
export const IMAGE1 = styled.div`
width:45%;
border:1px solid ${(props: any) => props.theme.darkborder};
border-radius:10px;
height:42%;
overflow:hidden;
`

export const Button = styled.div`
background-color:#fff;
position:absolute;
padding:.4em .7em;
border-radius:5px;
font-weight:700;
text-align:center;
color:#000;
font-size:12px;
`
export const CancelButton = styled.div`
position:absolute;
font-size:20px;
padding:5px;
font-weight:700;
top:10px;
right:10px;
background:#000;
border-radius:50%;
width:30px;
height:30px;
display:flex;
align-items:center;
justify-content:center;
color:#ffa100;
`

export const H3 = styled.h3`
font-weight:500;
color: ${(props: any) => props.theme.white};

padding-top:70px;
`


export const LoaderContainer = styled.div`
display:flex;
gap:10px;
margin-bottom:60px;
margin-top:0px;
height:3px;
width:70%;

`
export const DivContainer = styled.div`
display:flex;
background-color: ${(props: any) => props.theme.white};

height:3px;
width:100%;
`

export const Loader = styled.div`
display:flex;
background: #FFA100;
width:50%;
`
export const Loader2 = styled.div`
display:flex;
background: #fff;
width:50%;
`


