import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { IoIosArrowDown } from "react-icons/io";

import {
  Input,
  VerifyButton,
  Text,
  DropDown,
  Modal,
  Option,
  Div,
  Label,
  TextField,
  ColumnInput,
} from "./firstScreen.styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  clearVehicleDetails,
  setVehicleDetailss,
} from "../../../slices/provider";

const FirstScreen: FC<{
  setWidth: any;
  setVehicleDetails: any;
  vehicleDetails: any;
}> = ({ setWidth, setVehicleDetails, vehicleDetails }) => {
  const [isVisible, setIsVisible] = useState(false);

  const dispatch: any = useAppDispatch();
  const user = useAppSelector((state: any) => state?.auth?.auth);
  const vehicleInfo = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails
  );

  useEffect(() => {
    setWidth(15);
  }, []);

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVisible((prev) => !prev);
  };

  const handleUsage = (purpose: string) => {
    setIsVisible((prev: any) => !prev);
    setVehicleDetails({
      ...vehicleDetails,
      vehicle_for: purpose,
    });

    dispatch(setVehicleDetailss({ ...vehicleDetails, vehicle_for: purpose }));
  };

  return (
    <>
      <ColumnInput>
        <Label>What is this vehicle for?</Label>
        <Input>
          <TextField 
            type="text"
            readOnly
            style={{}}
            placeholder="What is this Vehicle for?"
            value={vehicleDetails?.vehicle_for || vehicleInfo?.vehicle_for}
          />
          <VerifyButton
            onClick={openModal}
            className="select"
            style={{ width: "35%" }}
          >
            <Text>Select </Text>
            <DropDown>
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
              />
            </DropDown>
          </VerifyButton>
        </Input>
      </ColumnInput>

      {isVisible && (
        <Div>
          <Modal>
            <Label onClick={() => handleUsage("Rental Only")}>
              <Option>
                <input type="radio" name="vehicle_for" value="Rental only" />
                <Text> Rental only</Text>
              </Option>
            </Label>

            <Label onClick={() => handleUsage("Travel Carpool")}>
              <Option>
                <input type="radio" name="vehicle_for" value="Travel Carpool" />
                <Text>Travel Carpool</Text>
              </Option>
            </Label>

            <Label onClick={() => handleUsage("Rental and Travel")}>
              <Option>
                <input
                  type="radio"
                  value="Rental and Travel"
                  name="vehicle_for"
                />
                <Text>Rental and Travel</Text>
              </Option>
            </Label>

            {/* <Label onClick={() => handleUsage('Insurance Only')}>
                            <Option>
                                <input
                                    type="radio"
                                    value="Insurance only"
                                    name="vehicle_for"

                                />
                                <Text>Insurance only</Text>
                            </Option>
                        </Label> */}

            {/* <Label onClick={() => handleUsage('Travel Only')}>
                            <Option>
                                <input
                                    type="radio"
                                    value="Travel only"
                                    name="vehicle_for"

                                />
                                <Text>Travel only</Text>
                            </Option>
                        </Label> */}
          </Modal>
        </Div>
      )}
    </>
  );
};
export default FirstScreen;
