import React, { useEffect, useState } from "react";
import "./styles/BlogAdminList.css";

import banner from "../../../assets/images/jpg/blogbanner.jpg";
import car from "../../../assets/images/svg/car-filter.svg";
import driver from "../../../assets/images/svg/driver-filter.svg";
import travel from "../../../assets/images/svg/travel-filter.svg";
import owner from "../../../assets/images/svg/owner-filter.svg";

import Footer from "../../../components/footer";
import Header from "../../../components/header";
import BlogCard from "../../../components/blogcard";

import { useGetBlogListQuery } from "../../../services/blogApi";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/loader";
import { BlogBanner, BlogBox, FilterBox, LoadMore, LoadMoreDiv } from "../../user/BlogHome/index.styles";

const AdminBlogList = () => {
  const { data, isFetching, refetch } = useGetBlogListQuery();

  // USESTATE
  const [blogListData, setBlogListData] = useState<any[]>();
  const [visibleList, setVisibleList] = useState(4);
  const [categoryType, setCategoryType] = useState("");
  const [searchBlogCategory, setSearchBlogCategory] = useState<any>(
    categoryType || ""
  );
  const [findCategory, setFindCategory] = useState<any[]>();
  const [preLoad, setPreLoad] = useState(false);

  // useEffect(() => {
  //     if(isFetching) {
  //         setPreLoad(true)
  //     }else {
  //         setPreLoad(false)
  //     }
  // }, [data])

  useEffect(() => {
    refetch();
    setBlogListData(data?.data);
  }, [data]);

  useEffect(() => {
    const filteredData = blogListData
      ?.filter((item) =>
        item?.category
          ?.toLowerCase()
          .includes(searchBlogCategory?.toLowerCase())
      )
      ?.reverse();

    setFindCategory(filteredData);
  }, [blogListData, categoryType]);

  const handleShowMore = () => {
    setVisibleList((prevValue) => prevValue + 4);
  };

  return preLoad ? (
    <Loader />
  ) : (
    <div className="adminHomepage">
      <Header />
      <div className="blog-upload-link-wrapper">
        <NavLink to={"/upload-blog-page"}>
          <div className="link-btn">Create Blog</div>
        </NavLink>
      </div>

      <BlogBanner>
        <img src={banner} alt="blog banner" className="img-banner" />
      </BlogBanner>

      <FilterBox>
        <button
          className={categoryType === "cars" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("cars");
            setSearchBlogCategory("cars");
          }}
        >
          <img src={car} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Cars</p>
        </button>

        <button
          className={categoryType === "drive" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("drive");
            setSearchBlogCategory("drive");
          }}
        >
          <img src={driver} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Drivers</p>
        </button>

        <button
          className={categoryType === "travel" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("travel");
            setSearchBlogCategory("travel");
          }}
        >
          <img src={travel} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Travel</p>
        </button>

        <button
          className={categoryType === "owner" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("owner");
            setSearchBlogCategory("owner");
          }}
        >
          <img src={owner} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Ownership</p>
        </button>
      </FilterBox>
      {/* <div className="homepage-body"> */}
      {/* <div className="blog-category-filter">
          <div className="inner-category-filter">
            <button
              className={categoryType === "cars" ? "activeCategory" : ""}
              onClick={() => {
                setCategoryType("cars");
                setSearchBlogCategory("cars");
              }}
            >
              <img src={car} alt="" />
              <p>Cars</p>
            </button>

            <button
              className={categoryType === "drive" ? "activeCategory" : ""}
              onClick={() => {
                setCategoryType("drive");
                setSearchBlogCategory("drive");
              }}
            >
              <img src={driver} alt="" />
              <p>Drivers</p>
            </button>

            <button
              className={categoryType === "travel" ? "activeCategory" : ""}
              onClick={() => {
                setCategoryType("travel");
                setSearchBlogCategory("travel");
              }}
            >
              <img src={travel} alt="" />
              <p>Travel</p>
            </button>

            <button
              className={categoryType === "owner" ? "activeCategory" : ""}
              onClick={() => {
                setCategoryType("owner");
                setSearchBlogCategory("owner");
              }}
            >
              <img src={owner} alt="" />
              <p>Ownership</p>
            </button>
          </div>
        </div> */}

      {!blogListData?.length ? (
        <div className="go-to-upload">
          <h2>
            No Blog here yet! Click the button below to make your first post
          </h2>
          <NavLink to={"/upload-blog-page"}>
            <div className="go-to-upload-btn">Create Post</div>
          </NavLink>
        </div>
      ) : (
        findCategory?.slice(0, visibleList).map((item, id) => {
          return (
            <BlogBox key={id}>
              <BlogCard
                title={item.title}
                blogContent={item.content}
                readMinute={item.min_read}
                numLikes={item.likes}
                image={item.post_image}
                bloglink={item.link}
                buttonText={"Edit Blog"}
                pagename={"edit-blog"}
                id={item.id}
              />
            </BlogBox>
          );
        })
      )}

      <LoadMoreDiv>
        {!blogListData?.length || blogListData?.length <= visibleList ? (
          ""
        ) : (
          <LoadMore onClick={handleShowMore}>Load More</LoadMore>
        )}
      </LoadMoreDiv>
      {/* </div> */}

      {/* <Footer /> */}
    </div>
  );
};

export default AdminBlogList;
