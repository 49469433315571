import React, { FC, useCallback, useEffect, useState } from 'react'
import SetAvailability from '../../providerCarReg/setAvailability/setAvailability';
// import ServiceOffering from '../serviceOffering/serviceOffering';
// import SetAvailability from '../setAvailability/setAvailability';
// import ToggleBar from '../../../components/toggleBar'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Input, FLEXDIV, INPUTS } from './thirdScreen.styles';

const BikeThirdScreen: FC<{ setVehicleDetails: any, vehicleDetails: any, setWidth: any, setQuestion: any }> = ({ vehicleDetails, setVehicleDetails, setWidth, setQuestion }) => {

    const [phoneNumber, setPhoneNumber] = useState<string>(vehicleDetails?.phone_number)
    const [firstName, setFirstName] = useState<string>(vehicleDetails?.first_name)
    const [lastName, setLastName] = useState<string>(vehicleDetails?.last_name)

    useEffect(() => {
        setWidth(50)
        setQuestion(3)
    }, [])

    const handleFirstName = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setFirstName((event.target as HTMLTextAreaElement).value)
        setVehicleDetails({
            ...vehicleDetails,
            first_name: (event.target as HTMLTextAreaElement).value
        });
    };
    const handleLastName = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLastName((event.target as HTMLTextAreaElement).value)

        setVehicleDetails({
            ...vehicleDetails,
            last_name: (event.target as HTMLTextAreaElement).value
        });
    };
    const handlePhone = (e: any) => {
        setPhoneNumber(e)
        setVehicleDetails({
            ...vehicleDetails,
            phone_number: e
        });
    }

   

    return (
        <>

            <Input>
                <div className='driverCode'>

                    <PhoneInput
                        defaultCountry="NG"
                        value={phoneNumber}
                        onChange={handlePhone}
                        international
                        withCountryCallingCode

                    />
                </div>

            </Input>

            <FLEXDIV style={{ marginTop: '25px' }}>
                <INPUTS
                    type="text"
                    value={firstName}
                    onChange={handleFirstName}
                    placeholder='Rider’s Name'
                />
                <INPUTS
                    type="text"
                    value={lastName}
                    onChange={handleLastName}
                    placeholder='Lastname'
                />
            </FLEXDIV>
        </>
    )
}

export default BikeThirdScreen

