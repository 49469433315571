import styled from 'styled-components'

export const DriverDiv = styled.div`
background:#fff;
display:flex;
border-radius:10px;
padding:1em .7em;
gap:10px;
width:100%;
margin-top:3em;
position:relative;
`
export const PinnedInfoDiv = styled.div`
top:-32px;
display flex;
position:absolute;
align-items:center;
gap:13px;
width:96%;
`
export const TopLabel = styled.label`
font-size:9px;
`

export const JoinDiv = styled.div`
display flex;
align-items:center;
gap:4px;
`
export const Ellipses = styled.div`
display flex;
align-items:center;
justify-content:flex-end;
gap:2px;
flex:1;
`


export const MessageDiv = styled.div`
display flex;
align-items:center;
gap:2px;
position:relative;
`

export const Div = styled.div`
background:#fff;
display:flex;
flex-direction:column;
color:#000;

`
export const IdDiv = styled.div`
background:#fff;
display:flex;
flex-direction:column;
color:#000;
gap:6px;
`
export const AddressDiv = styled.div`
background:#fff;
display:flex;
flex-direction:column;
color:#000;
gap:10px;
margin-left:10px;
`


export const ImageDiv = styled.div`
background:#fff;
padding:.5em;
box-shadow: 4px 4px 15px 2px #00000024;
width:80px;
height:80px;
border-radius:10px;
display:flex;
align-items:center;
justify-content:center;
`
export const CheckMark = styled.div`
display:flex;
align-items:center;
gap:5px;
margin-bottom:-12px;
`
export const DriverDetails = styled.div`
position:relative;
display:flex;
gap:15px;

`
export const FlexDiv = styled.div`
display:flex;
gap:8px;
align-items:start;
justify-content:space-between;
`
export const H6 = styled.h6`
font-weight:700;
font-size:12px;
color:##868686;
margin:0;
@media only screen and (max-width: 390px)  {
    font-size:10px;

}

`

export const Button = styled.button`
font-weight:bold;
background: #FFD58C;
font-size:11px;
padding:2px 5px;
border-radius:3px;
color:#000;
outline:none;
border:none;
@media only screen and (max-width: 390px)  {
    font-size:10px;

}
`
export const TripsText = styled.label`
font-weight:bold;
font-size:13px;
`
export const Box = styled.div`
height:130px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
`
export const Id = styled.label`
font-weight:bold;
font-size:8px;
margin-top:2px;
`


export const StatusLabel = styled.label`
font-weight:bold;
font-size:12px;
padding:4px 5px;
border-radius:3px;
color:#fff;
background: #06AE2B;

@media only screen and (max-width: 390px)  {
    font-size:10px;

}

`
export const HideLabel= styled.label`
font-weight:bold;
background: #FFD58C;
border-radius:3px;
font-size:12px;
padding:4px 5px;
color:#000;
@media only screen and (max-width: 390px)  {
    font-size:10px;

}
`

export const CallDiv = styled.div`
display:flex;
align-items:center;
justify-content:center;
gap:7px;
`
export const CallButton = styled.button`
background: #09051C;
font-size:13px;
padding:.3em .8em;
border-radius:5px;
outline:none;
border:none;
display:flex;
align-items:center;
justify-content:center;
`
export const FirstNameDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
gap:5px;
`
export const NameLabel = styled.label`
font-size:12px;
font-weigt:bold;
@media only screen and (max-width: 390px)  {
    font-size:10px;

}
`
