import {createSlice} from '@reduxjs/toolkit';




const initialState = {
  otp: {},
};


const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    setOTPData: (state, action) => {
      return { ...state, otp: { ...state.otp, ...action.payload } };

    },
   
  },
});

const {reducer, actions} = otpSlice;
export const {
    setOTPData,
} = actions;
export default reducer;

