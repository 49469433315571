import React, { useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    Box,
    Buttons,
    ButtonArea,
    H4,
    Label
} from '../ButtonGroups/button.styles';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAllBooking, setFilterPagination, setFilterPaginationData } from '../../../slices/admin';
import { baseUrl, useGetAdminRequestsQuery } from '../../../services/adminApi';
import toast from 'react-hot-toast'
import { Toaster } from "react-hot-toast";
import axios from 'axios';



interface Request {
    book_status: string;
    provider_status: string;


}

const RequestButtonGroups = () => {
    const dispatch: any = useAppDispatch()
    const user: any = useAppSelector((state: any) => state?.auth?.auth)
    const adminRequest = useAppSelector((state: any) => state?.admin.allBooking)

    const [requestCount, setRequestCount] = useState<any>();
    const [requests, setRequests] = useState<any>();

    const getRequests = async () => {
        await axios.get(`${baseUrl}admin-list-request`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                setRequests(response?.data?.data)
                dispatch(setAllBooking(response?.data?.data))

            }

        }).catch((err) => {
        })
    }

    useEffect(() => {
        getRequests()
    }, [])

    const getFiltered = async (data: string) => {

        dispatch(setAllBooking(requests?.filter((request: any) => {
            return request?.book_status.includes(data)
        })))


    }

    const getFilteredRequest = async () => {
        await axios.get(`${baseUrl}count-request-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setRequestCount(response?.data?.data)
            }

        }).catch((err) => {
        })
    }
    useEffect(() => {
        getFilteredRequest()
    }, [])

    const requestOpenCounts = requests?.filter((request: any) => {
        return request?.book_status.includes('Open')
    })
    const requestPendingCounts = requests?.filter((request: any) => {
        return request?.book_status.includes('Pending')
    })
    const requestProgressCounts = requests?.filter((request: any) => {
        return request?.book_status.includes('Progress')
    })
    const requestCompletedCounts = requests?.filter((request: any) => {
        return request?.book_status.includes('Completed')
    })
    const requestCancelCounts = requests?.filter((request: any) => {
        return request?.book_status.includes('Cancel')
    })
    return (
        <>
            <Toaster />

            <Box>
                <ButtonArea>
                    <Buttons
                        onClick={() => getFiltered('Open')}
                        style={{ background: '#CF0303' }}
                    >Open</Buttons>
                    {/* <Label>{requestCount?.countOpen || 0}</Label> */}
                    <Label>{requestOpenCounts?.length}</Label>

                </ButtonArea>

                <ButtonArea>
                    <Buttons
                        onClick={() => getFiltered('Pending')}
                        style={{ background: '#005FEE' }}>Pending</Buttons>
                    <Label>{requestPendingCounts?.length}</Label>


                </ButtonArea>
                <ButtonArea>
                    <Buttons
                        onClick={() => getFiltered('In Progress')}
                        style={{ background: '#ffa100', color: '#000' }}>In Progress</Buttons>
                    <Label>{requestProgressCounts?.length}</Label>


                </ButtonArea>

                <ButtonArea>
                    <Buttons
                        //  onClick={closedFilter} 
                        onClick={() => getFiltered('Completed')}

                        style={{ background: '#07B418', color: '#000' }}>Closed</Buttons>
                    <Label>{requestCompletedCounts?.length}</Label>


                </ButtonArea>

                <ButtonArea>
                    <Buttons
                        //  onClick={cancelFilter} 
                        onClick={() => getFiltered('Cancel')}

                        style={{ background: '#000' }}>Cancelled</Buttons>
                    <Label>{requestCancelCounts?.length}</Label>

                </ButtonArea>

            </Box>

        </>
    )
}

export default RequestButtonGroups