import React from 'react'
import EarningWalletCard from '../../../components/earningsWalletCard'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import NavigationTab from '../../../components/navigationTab'
import WalletCard from '../../../components/walletCardComponent'
import { WalletCardWrap } from '../UserWalletPage/userwalletpage.styles'
import { ContentPageWrap, EarningContentInfo, EarningInfoFlex, IconsBox, LinkInputWrap, ReferralBox, SocialIcons, SocialIconsWrap, SocialMediaWrap, TransactionsBox } from './userearningpage.styles'

import insta from '../../../assets/images/svg/insta-link-icon.svg'
import whatsapp from '../../../assets/images/svg/whatsapp-icon-link.svg'
import twitter from '../../../assets/images/svg/twitter-link-icon.svg'
import facebook from '../../../assets/images/svg/facebook-link-icon.svg'

const UserEarningPage = () => {
  return (
    <>
        <Header />
        <NavigationTab earningPill={true} />

        <ContentPageWrap>
            <EarningWalletCard />

            <EarningInfoFlex>
                <ReferralBox>
                    <div className="referral-box">
                        10
                    </div>
                    <p>My Referrals</p>
                </ReferralBox>

                <TransactionsBox>
                    <div className="transaction-box">
                        24
                    </div>
                    <p>My Wallet Transactions</p>
                </TransactionsBox>
            </EarningInfoFlex>   

            <EarningContentInfo>
                <p>Get $1 every-time a new user registers with your referral code and uploads a vehicle from 2010 upwards or completes a transaction worth $20. For every transaction paid with your wallet your get $1 cash back </p>
            </EarningContentInfo> 

            <LinkInputWrap>
                <input type="text" placeholder='https://roadlers/Ehis' />

                <div className="copy-btn">
                    Copy
                </div>
            </LinkInputWrap>

            <SocialMediaWrap>
                <h2>Share Your Referal Link via</h2>

                <SocialIconsWrap>
                    <IconsBox bg={'rgba(113, 179, 238, 0.83)'} pad={'10px'}>
                        <SocialIcons src={insta} />
                    </IconsBox>

                    <IconsBox bg={'#FFD58C'} pad={'10px'}>
                        <SocialIcons src={facebook} />
                    </IconsBox>

                    <IconsBox bg={'#000'} pad={'17px 12px'}>
                        <SocialIcons src={twitter} />
                    </IconsBox>

                    <IconsBox bg={'rgba(254, 85, 85, 0.91)'} pad={'10px'}>
                        <SocialIcons src={whatsapp} />
                    </IconsBox>
                    
                </SocialIconsWrap>
            </SocialMediaWrap>
        </ContentPageWrap>        


        <Footer />
    </>
  )
}

export default UserEarningPage