import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    providerData: {},
    account_type: null,
    service_offering: null,
    saveNextOfKin: false,
    vehicle: [],
    vehicleId: null,
    profile: {},
    nextOfKin: {},
    guarantor: {},
    businessProfile: {},
    uploadedDriverInfo: {},
    serviceVisibility: {
        fullDayServiceVisible: true,
        halfDayServiceVisible: true,
        hourlyRateVisible: true,
        airportVisible: true,
        travelVisible: true,
        halfMonthVisible: true,
        fullMonthVisible: true,
        driverHourlyRateVisible: true,
        driverFullDayServiceVisible: true,
        driverAllNightServiceVisible: true,
        driverWeeklyServiceVisible: true,
        driverMonthlyVisible: true,
        driverTravelVisible: true,
    },
    allBike: [],
    allDriver: [],
    allRequest: [],
    requestCount: null,
    vehicleDetails: {
        title: 'Vehicle Registration',
        user_id: '',
        charge:15,
        vehicle_for: '',
        vehicle_name: '',
        year: '',
        brand_name: '',
        plate_number: '',
        num_passengers: '',
        vehicle_type: '',
        air_con: '',
        come_with_driver: '',
        vehicle_tracker: '',
        fuel_type: '',
        night_trips: '',
        self_drive: '',
        travel_carpool: '',
        vehicle_location: '',
        travel_trip: [],
        service_price: [{
            price: 0,
            hour: 12,
            discount: 0,
            service_title: 'Full Day Service'
        },
        {
            price: 0,
            hour: 6,
            discount: 0,
            service_title: 'Half Day Service'
        },
        {
            price: 0,
            hour: 1,
            discount: 0,
            service_title: 'Hourly Rate'
        },


        {
            price: 0,
            discount: 0,
            service_title: 'Airport Pickup'
        },
        {
            price: 0,
            hour: 1,
            discount: 0,
            service_title: 'Full Month Service'
        },
        {
            price: 0,
            hour: 1,
            discount: 0,
            service_title: 'Half Month Service'
        }


        ],
        availability: [],
    },
    driverInfo: {
        firstname: '',
        user_id: '',
        id: '',
        lastname: '',
        address: '',
        latitude: '',
        longitude: '',
        phone_number: '',
        availability: [],
        travel_trip: [],
        service_price: [
            {
                price: 0,
                hour: 1,
                discount: 0,
                service_title: 'Hourly Rate'
            },
            {
                price: 0,
                hour: 12,
                discount: 0,
                service_title: 'Full Day Service'
            },
            {
                price: 0,
                hour: 3,
                discount: 0,
                service_title: 'All Night Service'
            },

            {
                price: 0,
                hour: 1,
                discount: 0,
                service_title: 'Weekly Service'
            },
            {
                price: 0,
                hour: 1,
                discount: 0,
                service_title: 'Monthly Service'
            }
        ],
    },
    allVehicle: [],

};

export const providerSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {




        setVehicleIdValue: (state, action) => {
            return { ...state, vehicleId: action.payload };
        },
        setSaveNextOfKin: (state, action) => {
            return { ...state, saveNextOfKin: action.payload };
        },

        setRequestCount: (state, action) => {
            return { ...state, requestCount: action.payload };
        },
        setAccount: (state, action) => {
            return { ...state, account_type: action.payload };
        },
        setProviderData: (state, action) => {
            return { ...state, providerData: action.payload };
        },

        setServiceOffering: (state, action) => {
            return { ...state, service_offering: action.payload };
        },

        setRegisteredVehicle: (state, action) => {
            return { ...state, vehicle: action.payload };
        },

        setAllVehicle: (state, action) => {
            return { ...state, allVehicle: action.payload };
            // state.allVehicle = action.payload;

        },
        setUploadedDriverInfo: (state, action) => {
            return { ...state, uploadedDriverInfo: action.payload };

        },
        setProfile: (state, action) => {
            return { ...state, profile: { ...state.profile, ...action.payload } };

        },

        setNextOfKin: (state, action) => {
            return { ...state, nextOfKin: { ...state.nextOfKin, ...action.payload } };
        },
        setServiceVisibility: (state, action) => {
            return { ...state, serviceVisibility: { ...state.serviceVisibility, ...action.payload } };
        },
        setGuarantor: (state, action) => {
            return { ...state, guarantor: { ...state.guarantor, ...action.payload } };
        },
        setBusinessProfile: (state, action) => {
            return { ...state, businessProfile: { ...state.businessProfile, ...action.payload } };
        },

        setAllBike: (state, action) => {
            return { ...state, allBike: action.payload };
        },
        setAllDriver: (state, action) => {
            return { ...state, allDriver: action.payload };
        },
        setAllRequest: (state, action) => {
            return { ...state, allRequest: action.payload };
        },

        setVehicleDetailss: (state, action) => {
            return { ...state, vehicleDetails: action.payload };
        },
        setDriverInfo: (state, action) => {
            return { ...state, driverInfo: action.payload };
        },



        setLogout: (state, action) => {
            return { ...state, otp_number: null, };

        },

        clearProfile: (state, action) => {
            return { ...state, profile: {} };
        },
        clearNextOfKin: (state, action) => {
            return { ...state, nextOfKin: {} };
        },
        clearBusinessProfile: (state, action) => {
            return { ...state, businessProfile: {} };
        },


        clearVehicleDetails: (state, action) => {
            return {
                ...state, vehicleDetails: {
                    title: 'Vehicle Registration',
                    user_id: '',
                    charge:15,
                    vehicle_for: '',
                    vehicle_name: '',
                    year: '',
                    brand_name: '',
                    plate_number: '',
                    num_passengers: '',
                    vehicle_type: '',
                    air_con: '',
                    come_with_driver: '',
                    vehicle_tracker: '',
                    fuel_type: '',
                    night_trips: '',
                    self_drive: '',
                    travel_carpool: '',
                    vehicle_location: '',
                    file: '',
                    file2: '',
                    file3: '',
                    file5: '',
                    travel_trip: [],
                    service_price: [],
                    availability: [],
                },
            };
        },
        clearDriverInfo: (state, action) => {
            return {
                ...state, driverInfo: {
                    firstname: '',
                    user_id: '',
                    charge:15,
                    id: '',
                    lastname: '',
                    address: '',
                    latitude: '',
                    longitude: '',
                    phone_number: '',
                    id_number: '',
                    identification: '',
                    driver_picture: '',
                    availability: [],
                    travel_trip: [],
                    service_price: [],
                },
            };
        },


    }
});

const { reducer, actions } = providerSlice;
export const {
    setProviderData,
    setAccount,
    setServiceOffering,
    setRegisteredVehicle,
    setLogout,
    setAllVehicle,
    setProfile,
    setNextOfKin,
    setSaveNextOfKin,
    setGuarantor,
    setBusinessProfile,
    setAllBike,
    setAllDriver,
    setAllRequest,
    setVehicleIdValue,
    setServiceVisibility,
    setRequestCount,
    setVehicleDetailss,
    setDriverInfo,
    setUploadedDriverInfo,
    clearVehicleDetails,
    clearDriverInfo,
    clearBusinessProfile,
    clearProfile,
    clearNextOfKin
} = actions;
export default reducer;

