import styled from 'styled-components'

export const PaginationWrap = styled.div `
    & .pagination-container {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        gap: 10px;
        padding: 0;

        .pagination-item {

        }
    }
`

export const LI = styled.li `
    pointer-events: ${(props:any) => props.disabled === true ? 'none' : 'unset'};

    & .arrow-left, & .arrow-right {
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1.5px #ffa100;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 8px;
        padding: 7px;

        img {
            width: 25px;
        }
    }
`

export const LI2 = styled.li `
    border: solid 1.5px #ffa100;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    background-color: ${(props:any) => props.selected === true ? '#ffa100' : 'transparent'};
`