import React, { useEffect, useState } from "react";
import DeliveryTripsCard from "../../../components/deliveryTripsCard";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Loader from "../../../components/loader";
import NavigationTab from "../../../components/navigationTab";
import TabComponent from "../../../components/tabComponent";
import TripsCardComponent from "../../../components/tripsCardComponent";
import {
  useListCustomerBookingQuery,
  useUpdateBookingMutation,
} from "../../../services/customerApi";
import { useAppSelector, RootState } from "../../../store";
import { TripsCardOverall, TripsTabOverall } from "./userbookingpage.styles";
import axios from "axios";
import { baseUrl } from "../../../services/adminApi";

const UserBookingPage = () => {
  const [changeTab, setChangeTab] = useState<any>("tab1");
  const [bookingData, setBookingData] = useState<any>([]);

  const {
    id = "",
    firstname = "",
    lastname = "",
    token = "",
  } = useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};

  const { data, refetch, isLoading, isSuccess } = useListCustomerBookingQuery(
    id,
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );
  const [
    updateBooking,
    {
      data: updateData,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateBookingMutation();

  useEffect(() => {
    refetch();
    setBookingData(data?.data?.data);
  }, [data, updateData]);

  useEffect(() => {
    if (isSuccess) {
      setBookingData(data?.data?.data);
    }
  }, [data, isSuccess]);

  const handleCancelBooking = (idForBooking: any) => {
    let formData: any = new FormData();
    formData.append("booking_id", idForBooking);
    formData.append("provider_status", "Cancel");
    formData.append("book_status", "Cancel");

    axios({
      method: "post",
      url: `${baseUrl}cancel-request/${idForBooking}`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response: any) {
        //   const message = response.data.data;
        //   dispatch(setAllVehicle(response.data));
        //   setVehicleSearch("");
        //   // dispatch(setFilterPaginationData("search"))
        //   dispatch(setFilterPagination(true));
        console.log(response?.data);
      })
      .catch(function (error) {
        //handle error
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <NavigationTab bookingsPill={true} />

      <TripsTabOverall>
        <TabComponent
          handleChange={setChangeTab}
          tabOne={"My Trips"}
          tabTwo={"Trip History"}
        />
      </TripsTabOverall>

      <TripsCardOverall activeTab={changeTab === "tab1" ? true : false}>
        {bookingData?.map((item: any, id: string) => {
          if (item?.book_status?.includes("Pending")) {
            if (item?.booking_title?.includes("Delivery")) {
              return (
                <DeliveryTripsCard
                  tripPending={true}
                  key={id}
                  currencyCode={item?.currency_code}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  date={item?.pickup_date}
                  bookingImage={item?.book_picture}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  noOfItems={item?.no_of_items}
                  amount={item?.amount}
                  handleCancelBooking={handleCancelBooking}
                />
              );
            } else {
              return (
                <TripsCardComponent
                  key={id}
                  amount={item?.amount}
                  currencyCode={item?.currency_code}
                  tripPending={true}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  bookingImage={item?.book_picture}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  vehicleId={item?.vehicle_id}
                  category={item?.category}
                  from={item?.from}
                  to={item?.to}
                  handleCancelBooking={handleCancelBooking}
                  travelId={item?.travel_id}
                />
              );
            }
          }

          if (item?.book_status?.includes("Open")) {
            if (item?.booking_title?.includes("Delivery")) {
              return (
                <DeliveryTripsCard
                  tripPending={true}
                  key={id}
                  currencyCode={item?.currency_code}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  bookingImage={item?.book_picture}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  noOfItems={item?.no_of_items}
                  amount={item?.amount}
                  handleCancelBooking={handleCancelBooking}
                />
              );
            } else {
              return (
                <TripsCardComponent
                  key={id}
                  currencyCode={item?.currency_code}
                  amount={item?.amount}
                  tripPending={true}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  bookingImage={item?.book_picture}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  vehicleId={item?.vehicle_id}
                  category={item?.category}
                  from={item?.from}
                  to={item?.to}
                  request={item}
                  handleCancelBooking={handleCancelBooking}
                  travelId={item?.travel_id}
                />
              );
            }
          }

          if (item?.book_status?.includes("In Progress")) {
            if (item?.booking_title?.includes("Delivery")) {
              return (
                <DeliveryTripsCard
                  progress={true}
                  key={id}
                  currencyCode={item?.currency_code}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  bookingImage={item?.book_picture}
                  origin={item?.pickup_location}
                  noOfItems={item?.no_of_items}
                  amount={item?.amount}
                  handleCancelBooking={handleCancelBooking}
                />
              );
            } else {
              return (
                <TripsCardComponent
                  key={id}
                  progress={true}
                  currencyCode={item?.currency_code}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  bookingImage={item?.book_picture}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  vehicleId={item?.vehicle_id}
                  handleCancelBooking={handleCancelBooking}
                  amount={item?.amount}
                  category={item?.category}
                  from={item?.from}
                  to={item?.to}
                  travelId={item?.travel_id}
                />
              );
            }
          }
        })}

        {/* <TripsCardComponent progress={true}/>
                <TripsCardComponent ticketBooked={true}/> */}
        {/* <TripsCardComponent duePay={true}/> */}
        {/* <TripsCardComponent progress={true}/>
                <TripsCardComponent giftPending={true}/> */}
      </TripsCardOverall>

      <TripsCardOverall activeTab={changeTab === "tab2" ? true : false}>
        {bookingData?.map((item: any, id: any) => {
          if (item?.book_status?.includes("Complete")) {
            if (item?.booking_title?.includes("Delivery")) {
              return (
                <DeliveryTripsCard
                  completed={true}
                  key={id}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  bookingImage={item?.book_picture}
                  destination={item?.destination}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  noOfItems={item?.no_of_items}
                  amount={item?.amount}
                />
              );
            } else {
              return (
                <TripsCardComponent
                  key={id}
                  completed={true}
                  currencyCode={item?.currency_code}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  bookingImage={item?.book_picture}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  origin={item?.pickup_location}
                  vehicleId={item?.vehicle_id}
                  amount={item?.amount}
                  category={item?.category}
                  from={item?.from}
                  to={item?.to}
                  travelId={item?.travel_id}
                  tripDetails={item}
                />
              );
            }
          }

          if (item?.book_status?.includes("Cancel")) {
            if (item?.booking_title?.includes("Delivery")) {
              return (
                <DeliveryTripsCard
                  canceled={true}
                  key={id}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  bookingImage={item?.book_picture}
                  origin={item?.pickup_location}
                  noOfItems={item?.no_of_items}
                  amount={item?.amount}
                />
              );
            } else {
              return (
                <TripsCardComponent
                  key={id}
                  canceled={true}
                  currencyCode={item?.currency_code}
                  idForBooking={item?.id}
                  bookingId={item?.booking_id}
                  bookingTitle={item?.booking_title}
                  time={item?.pickup_time}
                  destination={item?.destination}
                  date={item?.pickup_date}
                  providerId={item?.provider_id}
                  bookingImage={item?.book_picture}
                  origin={item?.pickup_location}
                  vehicleId={item?.vehicle_id}
                  amount={item?.amount}
                  category={item?.category}
                  from={item?.from}
                  to={item?.to}
                  travelId={item?.travel_id}
                  tripDetails={item}
                />
              );
            }
          }
        })}
        {/* <TripsCardComponent duePay={true}/> */}
        {/* <TripsCardComponent completed={true}/>
                <TripsCardComponent canceled={true}/> */}
      </TripsCardOverall>
      <Footer />
    </>
  );
};

export default UserBookingPage;
