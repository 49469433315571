import styled from 'styled-components'

export const InsuranceAccordionWrap = styled.div `
    margin-bottom: 20px;
    .accordion-content {
        max-height: ${(props:any) => props.active ? '300px' : '0px'};
        overflow: hidden;
        transition: max-height 0.3s ease;
        display: flex;
        justify-content: center;
        padding: 0 10px;

        .accordion-subcontent {
            height: 100%;
            background-color: #EAEAEA;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
            width: 100%;
            padding: 10px 14px;

            p {
                color: #000;
                margin: 0;
                font-size: 13px;
            }
        }
    }

`

export const InsuranceBoxWrap = styled.div `
    position: relative;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;

    .inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 15px;

        .insurance-type {
            flex: 70%;
            color: #868686;
            font-weight: 700;
            font-size: 13px;
        }
    }
                            
`

export const InsuranceRadio = styled.div `
    border: solid 3px #000;
    border-radius: 50%;
    background-color: ${(props:any) => props.picked ? '#ffa100' : 'transparent'};
    width:17px;
    height: 17px;
`

// export const