import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { IoEllipsisHorizontalOutline, IoLocationSharp } from "react-icons/io5";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";
import { FiHeart, FiSend, FiStar } from "react-icons/fi";
import { BsCalendar3, BsEnvelope } from "react-icons/bs";
import { RiUser3Line } from "react-icons/ri";
import { HiEllipsisHorizontal } from "react-icons/hi2";

import {
  Box,
  DateDiv,
  H4,
  IconBox,
  ImageContainer,
  ImageDiv,
  Price,
  StatusButton,
  StatusOption,
  TextWrapper,
  UserDiv,
} from "./vehicles.styles";
import yellowVan from "../../../assets/images/png/yellowVan.png";
import pin from "../../../assets/images/png/pin.png";
import pinned from "../../../assets/images/png/YellowPin.png";
import deleteicon from "../../../assets/images/png/delete.png";

import {
  DetailsDiv,
  Span,
  LocationDiv,
  PriceDiv,
  PinnedInfoDiv,
  TopLabel,
  OldPrice,
  LocationSpan,
  H6,
  BottomLabel,
} from "../../providerDashboard/providerVehicles/vehicle.styles";
import { Ellipses, JoinDiv, MessageDiv } from "../Drivers/driver.styles";
import { DeleteModal } from "../../providerDashboard/deleteModal";
import { Link } from "react-router-dom";
import { setVehicleIdValue } from "../../../slices/provider";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useGetProviderByIdQuery } from "../../../services/adminApi";
import axios from "axios";
import { baseUrl } from "../../../services/providerApi";
import CallCustomer from "../CallCustomer";
import ProviderModal from "../ProviderModal/providerModal";
import { PinModal } from "../pinModal";
import { EllipsesModal } from "../Ellipses/Modal";
import { baseImageUrl } from "../../../services/utils";
import { RWebShare } from "react-web-share";
import BookingCalendarModal from "../../bookingCalendarModal";
import moment from "moment";
type Props = {
  vehicleData: any;
  setHide?: any;
  setUnHide?: any;
};
const VehicleBox: FC<Props> = ({ setUnHide, setHide, vehicleData }) => {
  const dispatch: any = useAppDispatch();

  const [providerDetails, setProviderDetails] = useState({});
  const [isEllipsesVisible, setIsEllipsesVisible] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [calendar, setCalendar] = useState<any>([]);

  // const {data, isFetching, refetch, isSuccess} = useGetProviderByIdQuery(vehicleData?.user_id, {refetchOnFocus:true, refetchOnMountOrArgChange:true})

  const token = useAppSelector((state: any) => state?.auth?.auth?.token);

  const [isProfile, setIsProfile] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isPin, setIsPin] = useState<boolean>(false);
  const [isUnPin, setIsUnPin] = useState<boolean>(false);
  const handleDeleteModal = async () => {
    setIsDelete((prev: boolean) => !prev);
  };
  const handlePinModal = async () => {
    setIsPin((prev: boolean) => !prev);
  };
  const handleUnPinModal = async () => {
    setIsUnPin((prev: boolean) => !prev);
  };

  const setVehicleId = (value: any) => {
    dispatch(setVehicleIdValue(value));
  };

  const fullDayPrice = vehicleData?.service_price?.filter((price: any) => {
    return price?.service_title === "Full Day Service";
  });

  const NormalPrice = fullDayPrice?.[0]?.price ?? 0;
  const discountedPrice = fullDayPrice?.[0]?.discount ?? 0;

  const discountCalc = (price: number, discount: number) => {
    let discountPrice = (discount / 100) * price;
    let newPrice = price - discountPrice;
    return newPrice;
  };

  const getUserProfile = async (userId: any) => {
    await axios
      .get(`${baseUrl}view-provider-profile/${Number(userId)}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProviderDetails(response?.data);
      })
      .catch((err) => {});
  };

  const getVehicleBookings = async () => {
    await axios
      .get(`${baseUrl}fetch-vehicle-calendar/${Number(vehicleData?.id)}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        const selectedAsset = response.data.data[0].off_days;
        let stringSplit = selectedAsset.split(",");

        setCalendar(stringSplit);

        console.log("stringSplit", stringSplit);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getVehicleBookings();
  }, [vehicleData?.id]);

  useEffect(() => {
    getUserProfile(vehicleData?.user_id);
  }, [vehicleData?.user_id]);

  const openEllipses = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsEllipsesVisible((prev) => !prev);
  };

  return (
    <>
      <BookingCalendarModal
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
        dateArray={calendar}
      />
      {isDelete && (
        <DeleteModal
          isVehicle={true}
          vehicleDisplay={vehicleData?.display}
          vehicleStatus={vehicleData?.status}
          admin={true}
          id={vehicleData?.id}
          setHide={setHide}
          setVehicleUnHide={setUnHide}
          setIsDelete={setIsDelete}
        />
      )}
      {isPin && (
        <PinModal id={vehicleData?.id} isVehicle={true} setIsPin={setIsPin} />
      )}
      {isUnPin && (
        <PinModal
          id={vehicleData?.id}
          isVehicle={true}
          setIsPin={setIsPin}
          isUnPin={isUnPin}
          setIsUnPin={setIsUnPin}
        />
      )}
      {isProfile && (
        <ProviderModal
          providerId={vehicleData?.user_id}
          setIsProfile={setIsProfile}
          providerDetails={providerDetails}
        />
      )}
      <ImageContainer>
        <PinnedInfoDiv>
          <JoinDiv>
            {vehicleData?.pin_status === "1" ? (
              <img src={pinned} onClick={handleUnPinModal} width={10} />
            ) : (
              <img src={pin} width={10} onClick={handlePinModal} />
            )}

            <TopLabel style={{ fontSize: 9 }}>
              {vehicleData?.plate_number?.toUpperCase()}
            </TopLabel>
          </JoinDiv>
          <img onClick={handleDeleteModal} src={deleteicon} width={10} />
          <MessageDiv>
            <BsEnvelope style={{ fontSize: 13 }} />
          </MessageDiv>

          {(vehicleData?.status === null ||
            vehicleData?.status === "Accepted" ||
            vehicleData?.status === "Pending") && (
            <StatusButton>
              <Link
                onClick={() => setVehicleId(vehicleData?.id)}
                to={
                  "/vehicle-profile/" +
                  vehicleData?.id +
                  "/" +
                  vehicleData?.user_id
                }
                style={{ color: "white", textDecoration: "none" }}
              >
                Screen
              </Link>
            </StatusButton>
          )}
          {vehicleData?.status === "Approved" && (
            <StatusButton style={{ backgroundColor: "#06AE2B" }}>
              <Link
                onClick={() => setVehicleId(vehicleData?.id)}
                to={
                  "/vehicle-profile/" +
                  vehicleData?.id +
                  "/" +
                  vehicleData?.user_id
                }
                style={{ color: "white", textDecoration: "none" }}
              >
                Approved
              </Link>
            </StatusButton>
          )}

          {vehicleData?.status === "Rejected" && (
            <StatusButton style={{ backgroundColor: "#E70909" }}>
              <Link
                onClick={() => setVehicleId(vehicleData?.id)}
                to={
                  "/vehicle-profile/" +
                  vehicleData?.id +
                  "/" +
                  vehicleData?.user_id
                }
                style={{ color: "white", textDecoration: "none" }}
              >
                Rejected
              </Link>
            </StatusButton>
          )}

          {vehicleData?.status === "Trashed" && (
            <StatusButton style={{ backgroundColor: "#330A6F" }}>
              <Link
                onClick={() => setVehicleId(vehicleData?.id)}
                to={
                  "/vehicle-profile/" +
                  vehicleData?.id +
                  "/" +
                  vehicleData?.user_id
                }
                style={{ color: "white", textDecoration: "none" }}
              >
                Trashed
              </Link>
            </StatusButton>
          )}

          {/* {vehicleData?.status === 'Accepted' && <StatusButton>
                        <Link

                            onClick={() => setVehicleId(vehicleData?.id)}
                            to={"/vehicle-profile/" + vehicleData?.id}
                            style={{ color: 'white', textDecoration: 'none' }}>

                            Pending
                        </Link>

                    </StatusButton>} */}
        </PinnedInfoDiv>

        <ImageDiv>
          <img
            style={{
              objectFit: "cover",
              borderRadius: "11px",
              background: "#fff",
              marginBottom: 0,
            }}
            src={
              baseImageUrl + vehicleData?.image1 ||
              baseImageUrl + vehicleData?.image2
            }
            alt={baseImageUrl + vehicleData?.image1}
            width="100%"
            height="100%"
          />
          <UserDiv
            onClick={() => {
              setIsProfile(true);
              getUserProfile(vehicleData?.user_id);
            }}
          >
            <RiUser3Line style={{ fontSize: 16 }} />
          </UserDiv>
          <DateDiv onClick={() => setShowDateModal(true)}>
            <BsCalendar3 style={{ fontSize: 12 }} />
          </DateDiv>
        </ImageDiv>

        <TextWrapper>
          <H4>
            {vehicleData?.vehicle_name} - {vehicleData?.year}
          </H4>
          <PriceDiv>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <H6>
                {vehicleData?.currency_code}
                {discountCalc(NormalPrice, discountedPrice)}
                <Span style={{ color: "#000" }}>/ Day</Span>
              </H6>
              <OldPrice style={{ color: "#000" }}>
                {vehicleData?.currency_code}
                {NormalPrice}
              </OldPrice>
            </div>

            <LocationSpan>
              <IoLocationSharp style={{ color: "red", fontSize: "16" }} />
              <LocationDiv style={{ fontWeight: "bold", color: "#000" }}>
                {vehicleData?.vehicle_location
                  ? vehicleData?.vehicle_location
                  : vehicleData?.vehicle_address}
              </LocationDiv>
            </LocationSpan>
          </PriceDiv>

          <DetailsDiv>
            <div
              style={{
                color: "#000",
                width: "40%",
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <FiHeart />
              <Span style={{ color: "#000" }}>{vehicleData?.likes || 0}</Span>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <RWebShare
                data={{
                  text: `Book this vehicle with the link below`,
                  url: `https://roadlers.com/car-booking/${vehicleData?.id}`,
                  title: "Book Vehicles on Roadlers",
                }}
              >
                <FiSend style={{ color: "#000" }} />
              </RWebShare>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MdOutlineAirlineSeatReclineNormal style={{ color: "#000" }} />
                <Span style={{ color: "#000" }}>
                  {vehicleData?.num_passengers}
                </Span>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <FiStar style={{ color: "#ffa100" }} />
                <Span style={{ color: "#000" }}>0</Span>
              </div>
            </div>
          </DetailsDiv>
        </TextWrapper>

        <StatusOption onClick={openEllipses}>
          <BottomLabel style={{ fontSize: 9 }}>
            Joined: {moment(vehicleData?.created_at).format("DD/MM/YYYY")}
          </BottomLabel>

          <HiEllipsisHorizontal style={{ color: "#FFD58C" }} />
        </StatusOption>
      </ImageContainer>

      {isEllipsesVisible && (
        <EllipsesModal
          isVehicle={true}
          setIsEllipsesVisible={setIsEllipsesVisible}
        />
      )}
    </>
  );
};

export default VehicleBox;
