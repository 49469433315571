import {createSlice} from '@reduxjs/toolkit';



const initialState = {
  link: '',
};


const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    setLink: (state, action) => {
      return {...state, link: action.payload};
    },
    clearLink: () => {
      return {link: ''};
    },
  },
});

const {reducer, actions} = linkSlice;
export const {
  setLink,
  clearLink,
} = actions;
export default reducer;