import React from "react";
import { QuestionImg } from "../../pages/user/CarBooking/CarBooking.styles";
import { CardOverall } from "./roadlerscard.styles";

import RoadlersLogo from "../../assets/images/svg/roadlers-circle.svg";

const RoadlersCard = () => {
  return (
    <CardOverall>
      <div className="inner-wrap">
        <div className="header-section">
          <QuestionImg src={RoadlersLogo} width={"50px"} />
          <h3>Roadlers</h3>
        </div>

        <div className="title">Listing Notice</div>

        <div className="content">
          <p>
            This vehicle is listed on Roadlers by vehicle owners, no payment is
            to be made directly to the driver, as Roadlers will not be liable
            for any transaction outside the platform. As insurance coverage are
            only available on booking done within the platform.
          </p>
        </div>
      </div>
    </CardOverall>
  );
};

export default RoadlersCard;
