import styled from "styled-components";

export const Card = styled.div`
  min-height: 160px;
  width: 350px;
  background-color: ${(props: any) => props.propBg};
  border-radius: 10px;
  position: relative;
  padding: 10px 22px;
  border: 1px solid ${(props: any) => props.theme.darkborder};
  @media only screen and (min-width: 767px) {
    width: 400px;
  }
  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 110px;
`;

export const CardInner = styled.div`
  padding: 23px 30px;
`;

export const H2 = styled.h2`
  color: ${(props: any) => props.theme.propBg};

  font-size: 16px;
  font-weight: 700;

  @media only screen and (min-width: 959px) {
    font-size: 20px;
  }
`;
export const H22 = styled.h2`
  color: #000;

  font-size: 16px;
  font-weight: 700;

  @media only screen and (min-width: 959px) {
    font-size: 20px;
  }
`;

export const P = styled.p`
  color: #000;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 1.5;
  text-align: justify;
  @media only screen and (min-width: 959px) {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 8px;
  border: solid 1px #fff;
  background-color: #000;
  color: #fff;
  font-weight: 800;
  margin: 20px 0;
  margin-bottom: 0;
`;
