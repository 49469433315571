import styled from 'styled-components'

export const CarouselSection = styled.div `
    padding: 30px;
    margin-top: 30px;
    display: flex;
    position: relative;
    gap: 20px;


    /* NOT BEING USED */
    & .left-carousel-section {
        flex: 0.75;
        position: relative;
        display: flex;
        background:red;
       
        flex-wrap: nowrap;
        overflow: hidden;

        .carousel-item {
            height: 350px;
            width: 100%;
            /* transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1); */

            img {
            object-fit: cover;
            }
        }
        

    }

    & .right-carousel-section {
        flex: 0.25;
        display: flex;
        flex-direction: column;
        gap: 20px;

        img {
            height: 100px;
        }
    }
`

export const CarouselImg = styled.img `
    border-radius: 15px;
    width: ${(props: any) => props.imgWidth};
    height: ${(props: any) => props.imgHeight};
    display: ${(props: any) => props.display};
`

export const VehicleNameBox = styled.div `
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 15px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    position: absolute;
    top: -40px;
    left: 50px;

    @media only screen and (max-width: 484px) {
        font-size: 13px;
    }
`

export const VehiclePrice = styled(VehicleNameBox) `
    background-color: #ffa100;
    color: #000;
    border-radius: 0px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    top: unset;
    left: 0;
    bottom: 30px;
    display: ${(props: any) => props.display};

    @media only screen and (max-width: 484px) {
        font-size: 13px;
    }
`