import styled from 'styled-components'
import { PaymentLinkHeader } from '../collectMoneyOne/collectmoneyone.styles'

export const CollectMoneyTwoWrap = styled.div `
    display: ${(props:any) => props.display};
    position: relative;
`

export const BarCodeOverall = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0;

    & img {
        width: 120px;
    }
`

export const OverFlowWrap = styled.div `
    height: 400px;
    overflow-y: scroll;
    margin-top: 30px;
`

export const LinkWrapper = styled(PaymentLinkHeader) `
    & img {
        width: 50px;
    }

    @media only screen and (max-width: 454px)  {
        img {
            width: 40px;
        }

        .right-side {
            h3 {
                font-size: 12px;
            }

            p {
                font-size: 9px;
            }
        }
    } 
`

export const H02 = styled.h2 `
    font-size: 15px;
    font-weight: 700;
    color: #09051C;
    margin: 0;

    @media only screen and (max-width: 454px)  {
        font-size: 12px;
    } 
`
export const H03 = styled(H02) `
    font-size: 13px;
    font-weight: 600;

    @media only screen and (max-width: 454px)  {
        font-size: 10px;
    } 
`

export const CurrencyWrap = styled.div `
    border-radius: 8px;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #09051C;

    & img {
        width: 20px;
    }

    & p {
        color: #fff;
        font-weight: 700;
        margin: 0;
    }

    @media only screen and (max-width: 454px)  {
        border-radius: 4px;

        img {
            width: 13px;
        }

        p {
            font-size: 8px;
        }
    } 
`

export const LinkBtnOverall = styled.div `
    margin-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
`

export const LinkBtns = styled.div `
    border-radius: 8px;
    color: ${(props:any) => props.txt};
    background-color: ${(props:any) => props.bg};
    cursor: pointer;
    padding: 13px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.4;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 10%);

    @media only screen and (max-width: 454px)  {
        font-size: 10px;
        padding: 10px 13px;
        border-radius: 4px; 
    }  
`
