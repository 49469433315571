import styled from 'styled-components'


export const ShadowDiv = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:7px;
justify-content:space-between;
padding:15px 13px;
position:relative;
margin-top:20px;
box-shadow: 4px 4px 15px 2px #00000024;
`

export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:3px 8px;
outline:none;
border:none;
border-radius:5px;
font-size:14px;
font-weight:700;
margin-left:5px;
`
export const P = styled.p`
color:${(props: any) => props.theme.white};
font-size:12px;
margin-top:48px;
margin-bottom:15px;
font-weight:600;
`
export const Label=styled.label`
font-size:14px;
font-weight:500;
color:#000;

`
export const Button=styled.button`
padding:6px;
border:2px solid #000;
border-radius:50%;
`
export const InfoDiv = styled.div`
display:flex;
align-items:center;
background: #D9D9D9;
width:95%;
border-radius:7px;

margin:-10px auto;
padding:15px;
margin-bottom:48px;
transition: all 0.5s ease 0s;

`
export const DisabledButton = styled.button`
background: #000;
color: #aaa;
padding:15px 12px;
display:flex;
align-items:center;
height:50px;
margin-top:50px;
text-align:center;
border-radius:7px;
border:none;
outline:none;
font-size:15px;
`