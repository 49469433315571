import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Page, KinLabel, ProviderLabel } from "./driverProfile.styles";
import Header from "../../../components/providerHeader";

// import SecondScreen from '../../../components/providerInsurance/secondScreen/secondScreen';
import { useNavigate, useParams } from "react-router-dom";
import DriverProfiles from "../../../components/providerDriverProfile/driverProfile/driverProfile";
// import RegularRates from "../../../components/providerVehicleProfile/regularRates/regularRates";
// import TravelRates from "../../../components/providerVehicleProfile/travelRates/travelRates";
// import LongTermRates from "../../../components/providerVehicleProfile/longTermRates/longTermRates";
// import Services from "../../../components/providerVehicleProfile/services/services";
// import Insurance from "../../../components/providerVehicleProfile/insurance/insurance";
// import Documents from "../../../components/providerVehicleProfile/documents/documents";
import {
  baseUrl,
  useGetDriverByIdQuery,
  useGetNextOfKinByProviderIdQuery,
  useGetProviderByIdQuery,
} from "../../../services/providerApi";

import DriverAvailability from "../../../components/providerDriverProfile/availability/availability";
import ServicePrices from "../../../components/providerDriverProfile/servicePrices/servicePrices";
import TravelService from "../../../components/providerDriverProfile/travelRates/travelRatesComponent";
// import IndividualProfile from '../../../components/providerVehicleProfile/IndividualProfile/IndividualProfile';
import GuarantorProfile from "../../../components/providerDriverProfile/guarantor/guarantorProfile";
import {
  AcceptButton,
  PublishButton,
  PublishedButton,
  RejectButton,
  VerifyProfileDiv,
} from "../VehicleProfile/vehicleProfile.styles";
import { useAppSelector } from "../../../store";
import axios from "axios";
import {
  useVerifyDriverMutation,
  useVerifyProviderMutation,
} from "../../../services/adminApi";
import BusinessScreen from "../../../components/providerVehicleProfile/business/business";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";
import IndividualProfile from "../../../components/providerDriverProfile/IndividualProfile/IndividualProfile";

const DriverProfile: FC = () => {
  const { id, user_id } = useParams();

  const user: any = useAppSelector((state: any) => state?.auth?.auth);

  const {
    data: driverProfile,
    isFetching: isFetchingDriverProfile,
    refetch: refetchDriverProfile,
  } = useGetDriverByIdQuery(id);
  const { data, isFetching, refetch } = useGetProviderByIdQuery(user_id);

  const [
    verifyProvider,
    {
      data: verifyProviderData,
      isLoading: verifyProviderLoading,
      isSuccess: isSuccessVerifyProvider,
      isError: isErrorVerifyProvider,
      error: errorVerifyProvider,
    },
  ] = useVerifyProviderMutation();

  const [providerInfo, setProviderInfo] = useState<any>();
  const [driverInfo, setDriverInfo] = useState<any>();

  useEffect(() => {
    if (data) {
      setProviderInfo(data);
    }
  }, [data, refetch]);

  useEffect(() => {
    refetchDriverProfile();
  }, [driverProfile, refetchDriverProfile]);

  useEffect(() => {
    refetch();
  }, [user_id]);

  useEffect(() => {
    refetchDriverProfile();
  }, [id]);

  let [driverStatus, setDriverStatus] = useState<any>(
    driverProfile?.data[0]?.status
  );
  let [userStatus, setUserStatus] = useState<string>();

  let [providerStatus, setProviderStatus] = useState<string>();

  const [
    verifyDriver,
    {
      data: verifyDriverData,
      isLoading: verifyDriverLoading,
      isSuccess: isSuccessVerifyDriver,
      isError: isErrorVerifyVehicle,
      error: errorVerifyVehicle,
    },
  ] = useVerifyDriverMutation();

  const getDriverDetails = async () => {
    await axios
      .get(`${baseUrl}fetch-driver-provider/${Number(id)}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response && response?.data?.data[0] !== undefined) {
          setDriverInfo(response?.data);
        }
        setDriverStatus(response?.data?.data[0]?.status);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDriverDetails();
  }, [id]);

  console.log(driverInfo, "driverInfo");

  const getProviderDetails = async () => {
    await axios
      .get(`${baseUrl}provider-next-of-kin/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProviderStatus(response.data[0]?.status);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProviderDetails();
  }, []);

  const handleVerifyDriver = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "2");
    formData.append("status", "Accepted");
    setDriverStatus("Accepted Loading");
    verifyDriver(formData)
      .then((response) => {
        //@ts-ignore
        setDriverStatus(response?.data?.data?.status);
      })
      .catch((error: any) => {
        // Handle the error if needed
        console.error("Error verifying vehicle:", error);
        // setVehicleStatus('Error');
      });
  };
  const handleRejectDriver = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "0");
    formData.append("status", "Rejected");
    setDriverStatus("Rejected Loading");
    verifyDriver(formData)
      .then((response) => {
        //@ts-ignore
        setDriverStatus(response?.data?.data?.status);
      })
      .catch((error: any) => {
        // Handle the error if needed
        console.error("Error verifying Driver:", error);
        // setVehicleStatus('Error');
      });
  };

  const handleVerifyProfile = () => {
    let formData: any = new FormData();
    formData.append("id", user_id);
    formData.append("display", "1");
    formData.append("status", "Approved");
    setProviderStatus("Approve Loading");

    verifyProvider(formData)
      .then((response) => {
        setProviderStatus("Approved");
        //@ts-ignore
        // console.log(response?.data?.data?.status)
        if (response?.data?.data?.status === "Approved") {
          setProviderStatus("Accepted");
        }
      })
      .catch((error) => {
        // Handle the error if needed
        console.error("Error verifying Provider:", error);
        // setVehicleStatus('Error');
      });
  };
  const handleRejectProfile = () => {
    let formData: any = new FormData();
    formData.append("id", user_id);
    formData.append("display", "0");
    formData.append("status", "Rejected");
    setProviderStatus("Rejected Loading");

    verifyProvider(formData)
      .then((response) => {
        //@ts-ignore
        if (response?.data?.data?.status === "Rejected") {
          setProviderStatus("Rejected");
          handleRejectDriver();
        }
      })
      .catch((error) => {
        // Handle the error if needed
        console.error("Error verifying Provider:", error);
        // setVehicleStatus('Error');
        // setProviderStatus('')
      });
  };

  const handlePublishDriver = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "1");
    formData.append("status", "Approved");

    setDriverStatus("LoadingPublish");

    verifyDriver(formData)
      .then((response: any) => {
        if (response?.data?.data?.status === "Approved") {
          setDriverStatus("Approved");
        }
      })
      .catch((error: any) => {
        // Handle the error if needed
        console.error("Error verifying vehicle:", error);
        // setVehicleStatus('Error');
      });
  };

  console.log("providerStatus", providerStatus);
  return (
    <>
      <Header />
      <Page>
        <PagePadding>
          <DriverProfiles driverProfile={driverInfo} />
          <DriverAvailability driverProfile={driverInfo} />
          <ServicePrices driverProfile={driverInfo} />
          <TravelService driverProfile={driverInfo} />
          <br />
          <br />

          {user?.user_role === "admin" && (
            <>
              <VerifyProfileDiv
                style={{ marginTop: "40px", marginBottom: "60px" }}
              >
                {(driverStatus === "Accepted" ||
                  driverStatus === "Approved") && (
                  <AcceptButton>Accepted</AcceptButton>
                )}
                {(driverStatus === "Pending" ||
                  driverStatus === "Rejected" ||
                  driverStatus === null) && (
                  <AcceptButton onClick={handleVerifyDriver}>
                    Accept
                  </AcceptButton>
                )}
                {driverStatus === "Accepted Loading" && (
                  <AcceptButton>Loading..</AcceptButton>
                )}

                {driverStatus === "Rejected" && (
                  <RejectButton>Rejected</RejectButton>
                )}
                {(driverStatus === "Pending" ||
                  driverStatus === "Accepted" ||
                  driverStatus === null ||
                  driverStatus === "Approved") && (
                  <RejectButton onClick={handleRejectDriver}>
                    Reject
                  </RejectButton>
                )}
                {driverStatus === "Rejected Loading" && (
                  <RejectButton>Loading..</RejectButton>
                )}
              </VerifyProfileDiv>
            </>
          )}

          {/* <ProviderLabel>Provider Details </ProviderLabel> */}
          <br />
          <br />
          <br />
          {/* {!providerInfo?.category?.toLowerCase().includes('business') && */}

          <IndividualProfile
            driverProfile={driverProfile}
            userId={providerInfo?.id}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
          />
          {/* } */}
          <GuarantorProfile
            userId={providerInfo?.id}
            providerInfo={driverProfile}
            setProviderInfo={setProviderInfo}
          />

          <br />
          <br />
          <br />

          {providerInfo?.category?.toLowerCase().includes("business") && (
            <KinLabel>Business Info</KinLabel>
          )}
          <br />
          <br />

          {providerInfo?.category?.toLowerCase().includes("business") && (
            <BusinessScreen
              userId={providerInfo?.id}
              providerInfo={providerInfo}
              setProviderInfo={setProviderInfo}
            />
          )}

          <br />
          <br />
          <br />
          {user?.user_role === "admin" && (
            <VerifyProfileDiv>
             {(providerStatus === "Approved" ||
                providerStatus === "Accepted") && (
                <AcceptButton>Accepted</AcceptButton>
              )}
              {(providerStatus === "0" ||
                providerStatus === "Pending" ||
                providerStatus === "Rejected" ||
                providerStatus === null) && (
                <AcceptButton onClick={handleVerifyProfile}>
                  Accept
                </AcceptButton>
              )}
              {providerStatus === "Approve Loading" && (
                <AcceptButton>Loading..</AcceptButton>
              )}

              {providerStatus === "Rejected" && (
                <RejectButton>Rejected</RejectButton>
              )}
            

              {(providerStatus === "Approved" ||
                providerStatus === "0" ||
                providerStatus === "Pending" ||
                providerStatus === "Accepted" ||
                providerStatus === null) && (
                <RejectButton onClick={handleRejectProfile}>
                  Reject
                </RejectButton>
              )}

              {providerStatus === "Rejected Loading" && (
                <RejectButton>Loading..</RejectButton>
              )}
            </VerifyProfileDiv>
          )}

          {user?.user_role === "admin" && (
            <VerifyProfileDiv>
              {user?.user_role === "admin" &&
                driverStatus === "Approved" &&
                providerStatus !== "Rejected" && (
                  <PublishedButton>Published</PublishedButton>
                )}
              {user?.user_role === "admin" &&
                (providerStatus === "Approved" ||
                  providerStatus === "Accepted") &&
                driverStatus === "Accepted" && (
                  <PublishButton onClick={handlePublishDriver}>
                    Publish
                  </PublishButton>
                )}
              {user?.user_role === "admin" &&
                driverStatus === "LoadingPublish" && (
                  <PublishButton>Loading...</PublishButton>
                )}
            </VerifyProfileDiv>
          )}
        </PagePadding>
      </Page>
    </>
  );
};
export default DriverProfile;
