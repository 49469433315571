import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 60vh;
    /* width: 100vw; */
    /* place-items: center; */
    transition: .5s;
    background-color: ${(props: any) => props.theme.body};

    display:flex;
    flex-direction:column;
`

export const LandingPageBtnWrap = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const Button = styled.div`
background: #FFA100;
color: #000;
width:100%;
border-radius:30px;
padding:12px 10px;
width:55%;
outline:none;
border:none;
font-size:16px;
font-weight:700;
text-align:center;
margin-top:60px;
`