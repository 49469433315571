import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import providerPlaceholder from '../../../assets/images/png/providerPlaceholder.png'
import { GiCheckMark } from 'react-icons/gi';




import { DriverDiv, Div, ImageDiv, CheckMark, DriverDetails, FlexDiv, H6, Label, Button } from './driver.styles';
import { Link } from 'react-router-dom';
import { baseImageUrl } from '../../../services/utils';
const Driver: FC<{ driverDetails: any }> = ({ driverDetails }) => {
    return (
        <>
            <DriverDiv>
                <Div>
                    <ImageDiv>
                        <img style={{ objectFit: 'cover' }} src={baseImageUrl + driverDetails?.driver_picture}
                            width='100%'
                            height='100%'
                        />
                    </ImageDiv>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>Guarantor</p>
                    </CheckMark>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>ID</p>
                    </CheckMark>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{
                            fontSize: '9px', fontWeight: '800',
                            width: '60px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{driverDetails?.identification} </p>
                    </CheckMark>

                </Div>

                <Div>
                    <DriverDetails>

                        <p style={{ position: 'absolute', bottom: '30px', fontSize: '9px', fontWeight: 'bold' }}>First</p>
                        <p style={{ position: 'absolute', bottom: '30px', right: '0', fontSize: '9px', fontWeight: 'bold' }}>Lastname</p>

                        <FlexDiv>
                            <H6>{driverDetails?.firstname}</H6>
                            <H6>{driverDetails?.lastname}</H6>
                        </FlexDiv>
                    </DriverDetails>

                    <DriverDetails>

                        <p style={{ position: 'absolute', bottom: '30px', fontSize: '9px', fontWeight: 'bold' }}>Phone Number</p>

                        <FlexDiv>
                            <H6>{driverDetails?.phone_number || '.'}</H6>
                        </FlexDiv>
                    </DriverDetails>

                    <DriverDetails>
                        <p style={{ position: 'absolute', bottom: '30px', fontSize: '9px', fontWeight: 'bold' }}>Location</p>
                        <FlexDiv>
                            <H6>{driverDetails?.address || '.'} </H6>
                        </FlexDiv>
                    </DriverDetails>
                </Div>

                <Div style={{ position: 'relative', width: '100%' }}>
                    {(driverDetails?.status === 'Pending' || driverDetails?.status === 'Accepted') &&

                        <Label style={{ background: '#1794da', position: 'absolute', top: '13px', right: '0px' }}>Pending</Label>
                    }

                    {driverDetails?.status === 'Approved' &&

                        <Label style={{ background: '#06ae2b', position: 'absolute', top: '13px', right: '0px' }}>Approved</Label>
                    }

                    {driverDetails?.status === 'Rejected' &&

                        <Label style={{ background: '#cf0303', position: 'absolute', top: '13px', right: '0px' }}>Rejected</Label>
                    }
                   
                    <Button style={{ position: 'absolute', top: '40px', right: '0px' }}>

                        <Link to={"/driver-profile/" + driverDetails?.id + "/" + driverDetails?.user_id} style={{ color: '#000', textDecoration: 'none' }}>
                            Edit
                        </Link>
                    </Button>
                </Div>

            </DriverDiv>

        </>

    )
}

export default Driver