import React, { FC, useEffect, useRef, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import requestImage from "../../../assets/images/png/requests.png";
import vehicles from "../../../assets/images/png/vehicles.png";
import drivers from "../../../assets/images/png/drivers.png";

import { Div, FlexDiv, Label, Button } from "./providerActions.styles";
import ProviderVehicles from "../providerVehicles/providerVehicles";
import ProviderDrivers from "../providerDrivers/providerDrivers";
import ProviderRequests from "../providerRequests/providerRequests";
import {
  useGetBikesQuery,
  useGetRequestsQuery,
  useGetVehiclesQuery,
} from "../../../services/providerApi";
import { useAppSelector } from "../../../store";

const ProviderActions: FC<{
  requestComponent: any;
  setRequestComponent: any;
}> = ({ requestComponent, setRequestComponent }) => {
  const reqRef: any = useRef();

  let [vehicleComponent, setVehicleComponent] = useState(false);
  let [driverComponent, setDriverComponent] = useState(false);

  const handleVehicleComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setVehicleComponent((prev) => !prev);
    setDriverComponent(false);
    setRequestComponent(false);
  };

  const handleDriverComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDriverComponent((prev) => !prev);
    setRequestComponent(false);
    setVehicleComponent(false);
  };
  const handleRequestComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setRequestComponent((prev: any) => !prev);
    setVehicleComponent(false);
    setDriverComponent(false);
  };

  useEffect(() => {
    setTimeout(() => {
      reqRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }, [requestComponent === true]);

  const {
    data: providerRequests,
    isFetching: isFetchingRequests,
    refetch: refetchRequests,
  } = useGetRequestsQuery();

  useEffect(() => {
    refetchRequests();
  }, [refetchRequests]);

  const oldRequestCount = useAppSelector(
    (state: any) => state?.provider.requestCount
  );
  let newRequestCount: number = providerRequests?.data?.length;
  let requestCount = newRequestCount - oldRequestCount;

  return (
    <>
      <FlexDiv>
        <Div onClick={handleVehicleComponent}>
          <img width={90} src={vehicles} alt="" />
          <Button
            style={
              vehicleComponent
                ? { background: "#f8b858" }
                : { background: "transparent" }
            }
          >
            My Vehicles
          </Button>
        </Div>
        <Div onClick={handleDriverComponent}>
          <img width={90} src={drivers} alt="" />
          <Button
            style={
              driverComponent
                ? { background: "#f8b858", color: "#000" }
                : { background: "transparent" }
            }
          >
            My Drivers
          </Button>
        </Div>
        <Div onClick={handleRequestComponent}>
          <img width={90} src={requestImage} alt="" />

          <Button
            style={
              requestComponent
                ? { background: "#f8b858", color: "#000" }
                : { background: "transparent" }
            }
          >
            My Requests
          </Button>
          {requestCount >= 1 && <Label>{requestCount}</Label>}
        </Div>
      </FlexDiv>

      {vehicleComponent && <ProviderVehicles />}
      {driverComponent && <ProviderDrivers />}
      {requestComponent && (
        <div ref={reqRef}>
          <ProviderRequests />
        </div>
      )}
    </>
  );
};

export default ProviderActions;
