import React, { useEffect, useState } from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import NavigationTab from '../../../components/navigationTab'
import { DeleteAccountBtn, EditCameraWrap, InputComponentWrap, PageWrapper, ProfileFlexWrap, ProfileImgWrap } from './userprofilepage.styles'
import ProfileTextInputComponent from '../../../components/profileTextInputComponent'
import ProfileAccordion from '../../../components/profileAccordion'
import { ToastContainer, toast } from 'react-toastify';

import camera from '../../../assets/images/svg/edit-camera.svg'
import profile from '../../../assets/images/png/wallet-profile.png'
import { RootState, useAppSelector } from '../../../store'
import { PhoneNumber } from 'libphonenumber-js/types'
import { useUpdateUsernameMutation } from '../../../services/customerApi'

import { CancelModal } from "./popUp";


const UserProfilePage = () => {

  const [deleteAccount, setDeleteAccount] = useState(false);

    const { id,firstname, lastname, phone_number, email, username, change_username
    } = useAppSelector<any>((store: RootState) => store?.auth?.auth)

    const [updateUsername, { data, isLoading, isSuccess, isError, error }] = useUpdateUsernameMutation();

    const [newUsername, setNewUsername] = useState(username)
    const [newFirstname, setNewFirstname] = useState(firstname)
    const [newLastname, setNewLastname] = useState(lastname)
    const [newPhoneno, setNewPhoneno] = useState('')
    const [newMail, setNewMail] = useState(email)
    const [newPassword, setNewPassword] = useState('')


    useEffect(() => {
        if(isSuccess) {
            notifySuccess('Username succesfully changed')
        }

        if(isError && 'status' in error!) {
            notifyError(error.data.message)
        }

    }, [data, isSuccess, isLoading, isError, error])

    const handleUsernameUpdate = () => {
        let formData:any = new FormData();

        if(!newUsername) {
            notifyError('Type in new username')
        }else if(username === newUsername) {
            notifyError('Username not changed')
        }else {
            formData.append("id", id);
            formData.append("username", newUsername);
    
            updateUsername(formData)
        }
    }

    const notifyError = (text: any) => toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const notifySuccess = (text: any) => toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
  return (
    <>
        <ToastContainer />
        <Header/>
        <NavigationTab profilePill={true} />

        <PageWrapper>
            <ProfileFlexWrap>
                <ProfileImgWrap>
                    <EditCameraWrap>
                        <img src={camera} alt="" />
                    </EditCameraWrap>
                    <img className='profile-img' src={profile} alt="" />
                </ProfileImgWrap>

                <ProfileTextInputComponent 
                    labelName={'First Name'}
                    inputPlaceholder={'First Name'}
                    inputBox={true}
                    inputType={'text'}
                    isReadOnly={true}
                    newUserValue={newFirstname}
                    setChangeInputValue={setNewFirstname}
                />

                <ProfileTextInputComponent 
                    labelName={'Last Name'}
                    inputPlaceholder={'Last Name'}
                    inputBox={true}
                    edit={true}
                    inputType={'text'}
                    isReadOnly={true}
                    newUserValue={newLastname}
                    setChangeInputValue={setNewLastname}
                />
            </ProfileFlexWrap>

            <InputComponentWrap>
                <ProfileTextInputComponent
                    labelName={'Phone Number'}
                    verified={true}
                    inputPhoneBox={true}
                    inputValue={phone_number}
                    isReadOnly={true}
                />
            </InputComponentWrap>

            <InputComponentWrap>
                <ProfileTextInputComponent 
                    labelName={'Email'}
                    verified={true}
                    inputBox={true}
                    inputType={'email'}
                    inputPlaceholder={'Type mail'}
                    isReadOnly={true}
                    newUserValue={newMail}
                    setChangeInputValue={setNewMail}
                />
            </InputComponentWrap>

            <InputComponentWrap>
                <ProfileTextInputComponent 
                    labelName={'Password'}
                    inputType={'password'}
                    inputPlaceholder={'Type password'}
                    inputBox={true}
                    changeBtnBox={false}
                    newUserValue={newPassword}
                    setChangeInputValue={setNewPassword}
                />
            </InputComponentWrap>

            <InputComponentWrap>
                <ProfileTextInputComponent 
                    labelName={'User Name'}
                    inputType={'text'}
                    inputPlaceholder={'Type username'}
                    inputBox={true}
                    changeBtnBox={change_username === '0' ? true : false}
                    handleUsername={handleUsernameUpdate}
                    newUserValue={newUsername}
                    setChangeInputValue={setNewUsername}
                    isReadOnly={false}
                    isChangeWarning={true}
                    isUsernameChanged={change_username === '0' ? true : false}
                />
            </InputComponentWrap>

            {/* FOR THE PROVIDERS */}
            <ProfileAccordion />

            <DeleteAccountBtn onClick={()=>setDeleteAccount(true)}>
                Delete Account
            </DeleteAccountBtn>

        </PageWrapper>

        <Footer />

        {deleteAccount && (
        <CancelModal  setDeleteAccount={setDeleteAccount} />
      )}
    </>
  )
}

export default UserProfilePage