import { LocalizationProvider, StaticTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { CancelModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import { TimeBox, TimeDateModalBox } from '../timeDateModal/timedatemodal.styles'
import { BookingTimeOverlay } from './bookingtimemodal.styles';
import { createTheme, ThemeProvider } from '@mui/material';

const BookingTimeModal = ({time, showTimeModal, setShowTimeModal}:any) => {
    const [timeValue, setTimeValue] = useState<any>()
    const theme = createTheme({
        palette: {}
    });



  return (
    <BookingTimeOverlay activeModal={showTimeModal}>
        <CancelModalBox onClick={() => setShowTimeModal(false)} />

        <div className="modal-inner-wrapper">
            <TimeDateModalBox className="booking-time-wrap" boxDisplay={true} >
                <TimeBox className="booking-time-box">
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticTimePicker 
                                value={dayjs(time,  'HH:mm A ')}
                                onChange={(newValue) => {
                                    setTimeValue(newValue)
                                }}
                                renderInput={(params:any) => <TextField 
                                    {...params} value={dayjs(time,  'HH:mm A ')} variant="outlined" 
                                    InputProps={{
                                    readOnly: true,
                                }} />}
                                // @ts-ignore
                                sx={{ background: 'transparent' }}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </TimeBox>
            </TimeDateModalBox>
        </div>
    </BookingTimeOverlay>
  )
}

export default BookingTimeModal