import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";

import "./assets/styles/App.css";

import store from "./store";
import { useState } from "react";
import AppWrapper from "./wrapper";

function App() {
  let persistor = persistStore(store);
  // const {token} = useAppSelector<any>((store: RootState) => store?.auth.auth)
  const [isSignedIn, setIsSignedIn] = useState(false);

  // useEffect(() => {
  //   if(token?.length) {
  //     setIsSignedIn(true)
  //   }else {
  //     setIsSignedIn(false)
  //   }
  // }, [token])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="App">
          <AppWrapper />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
