import React, { FC, useEffect, useState } from "react";
import Customer from "../Customers/customer";
import { BackgroundBox, Button, H3, ModalBox } from "./callcustomer.styles";
import { Link } from "react-router-dom";
import { useAdminCustomerDataMutation } from "../../../services/adminApi";

type Props = {
  setShowCustomer: any;
  customerId: string;
};
const CallCustomer: FC<Props> = ({ customerId, setShowCustomer }) => {
  let [customerInfo, setCustomerInfo] = useState<any>();
  const [customerData, { data, isLoading, isSuccess, isError, error }] =
    useAdminCustomerDataMutation();
  useEffect(() => {
    if (isLoading) {
    }
    if (isSuccess) {
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        // notifyError('Network Connection Failed')
      }
    }
  }, [customerId]);

  const handleGetCustomer = () => {
    let formData: any = new FormData();
    formData.append("customer_id", customerId);

    customerData(formData).then((response: any) => {
      console.log("data?.data", response?.data);
      setCustomerInfo(response?.data?.data);
    });
  };
  useEffect(() => {
    handleGetCustomer();
  }, [customerId]);


  return (
    <>
      <BackgroundBox onClick={() => setShowCustomer(false)}>
        <ModalBox>
          <H3>Customer Data</H3>
          <Customer
            driverDetails={customerInfo}
            isModal={true}
            isCustomer={true}
          />

          <Button>
            <a href={`tel: ${customerInfo?.phone_number}`} style={{color:"#000"}}>Call Client</a>
          </Button>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default CallCustomer;
