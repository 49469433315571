import styled from "styled-components";

export const TicketPageWrap = styled.div`
  position: relative;
`;

export const TicketInfoWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;

  .border {
    border-right: solid 1px ${(props: any) => props.border};
    height: 40px;
    width: 1px;
  }
`;

export const TicketInfoBox = styled.div`
  padding: 0px;
  & h3 {
    margin: 0;
    color: #ebaf4a;
    font-weight: 700;
    font-size: 10px;
  }

  & p {
    text-align: center;
    color: ${(props: any) => props.theme.text};
    font-weight: 700;
    font-size: 9px;
    margin: 8px 0;
  }
`;

export const TicketDateBox = styled.div`
  display: flex;
  align-items: center;
  // padding: 0 20px;
  gap: 6px;

  & h3 {
    margin: 0;
    color: #ebaf4a;
    font-weight: 700;
    font-size: 11px;
  }

  & p {
    text-align: center;
    color: ${(props: any) => props.theme.text};
    font-weight: 700;
    font-size: 10px;
    margin: 0;
  }
`;

export const NameInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 20px;

  .seats {
    background: #ffd58c;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 10px;
    border-radius: 5px;
    margin: 0;
    width: 50%;
    h6 {
      color: #000;
      margin: 0;
      font-size: 20px;
    }
    button {
      background: #09051c;
      color: #fff;
      border: none;
      outline: none;
      font-size: 23px;
      font-weight: 700;
      padding: 0px 8px;
    }
  }

  .extra {
    width: 50%;
    background: #09051c;
    padding: 8px 10px;
    border-radius: 5px;
  }
`;

export const NameInput = styled.input`
  outline: none;
  border: none;
  padding: 15px;
  width: 100%;
  background-color: #fff;
  border: solid 1px ${(props: any) => props.theme.text};
  border-radius: 12px;
  flex: 0.5;

  &::placeholder {
    color: #868686;
    font-size: 13px;
  }
`;

export const MailWrap = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #fff;
  border: solid 1px ${(props: any) => props.theme.text};
  border-radius: 12px;
  padding: 15px 5px 15px 55px;
  position: relative;
  flex: 1;
  box-shadow: 4px 4px 15px 2px #00000024;

  & input {
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
  }
`;

export const MailIconBox = styled.div`
  position: absolute;
  height: 55px;
  top: -3px;
  left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 18px;
  border-radius: 12px;
  background-color: #000;
`;

export const ExtrasBox = styled.div`
  background-color: #09051c;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0.2;

  & p {
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    margin: 0;
  }
`;
