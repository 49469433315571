import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import filter from '../../../assets/images/png/filter.png'

import { Div, DriverDiv } from './adminProviders.styles';

import { FiSearch } from 'react-icons/fi';

import Provider from './provider';
import DriverRegistration from '../../../pages/Service_provider/DriverRegistration/driverRegistration';
import { useAppDispatch, useAppSelector } from '../../../store';
import { baseUrl, useGetAdminProvidersQuery } from '../../../services/adminApi';
import { setProviders } from '../../../slices/admin';
import axios from 'axios';
import toast from 'react-hot-toast'
import { Toaster } from "react-hot-toast";
import { PaginationButton, PaginationDiv } from '../Drivers/adminDrivers.styles';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';



const AdminBusinessProviders: FC = () => {

    const dispatch: any = useAppDispatch()
    const [currentPage, setCurrentPage] = useState<Number>(1);
    const user: any = useAppSelector((state: any) => state?.auth?.auth)
    const filterPagination = useAppSelector((state: any) => state?.admin?.filterPagination)
    const filterPaginationData = useAppSelector((state: any) => state?.admin?.filterPaginationData)
    const providers = useAppSelector((state: any) => state?.admin?.allProvider)

    let promise: any;

    const getProviders = async () => {
        await axios.get(`${baseUrl}admin-list-business?page=${currentPage}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                dispatch(setProviders(response?.data))
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);
                toast.success('Successfully Loaded!')
            }

        }).catch((err) => {
        })
    }

    useEffect(() => {
        getProviders()
    }, [currentPage])

    const handlePageChange = (newPage: number) => {
        // Validate newPage to prevent requesting invalid pages
        if (newPage >= 1) {
            setCurrentPage(newPage);
            promise = toast.loading('Loading...');

        }
    };
    const getFilteredProvider = async (data: string, pageNo: any) => {
        const promise = toast.loading('Loading...');
        await axios.get(`${baseUrl}filter-provider-status/${data}?page=${pageNo}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {

                dispatch(setProviders(response?.data))
                // dispatch(setFilterPagination(true))
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);
                toast.success('Successfully Loaded!')
            }

        }).catch((err) => {
        })
    }
    const handlePageChangeTwo = (newPage: number) => {
        if (newPage >= 1) {
            getFilteredProvider(filterPaginationData, newPage)

        }
    };
    let paginationData = providers?.data?.links
    let realPagination = paginationData?.filter((pagination: any) => {
        return Number(pagination.label)
    })

    return (
        <>
            <Toaster />
            <DriverDiv>
                {
                    providers?.data?.data?.map((provider: any) => {
                        return <Provider
                            key={provider.id}
                            providerDetails={provider}
                            isModal={false} />
                    })
                }
                {/* </div> */}
            </DriverDiv>

            <PaginationDiv>
                {realPagination?.map((pageData: any, index: number) => (
                    <PaginationButton
                        key={index}
                        onClick={() => {
                            filterPagination === true ? handlePageChangeTwo(Number(pageData.label)) :
                                handlePageChange(Number(pageData.label))
                        }
                        }
                        disabled={pageData.active}

                    >
                        {pageData.label === '&laquo; Previous' && !pageData.active ? (
                            <><BiChevronLeft /></>
                        ) : pageData.label === 'Next &raquo;' && !pageData.active ? (
                            <><BiChevronRight /></>
                        ) : (
                            <>{pageData.label}</>
                        )}
                    </PaginationButton>
                ))}
            </PaginationDiv>


        </>
    )
}

export default AdminBusinessProviders