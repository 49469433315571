import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'



import { ToastContainer, toast } from 'react-toastify'
import { Box } from './requests.styles'
const NoData: FC = () => {

    return (
        <Box>
            <h4>No Data Available</h4>
        </Box>

    )
}

export default NoData