import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import ID from "../../../assets/images/png/ID.png";
import snap from "../../../assets/images/png/snap.png";
import ownership from "../../../assets/images/png/ownership.png";
import {
  DIV,
  INPUTS,
  P,
  VerifyButton,
  Text,
  DropDown,
  COLDIV,
  RegionDiv,
  SELECTDIV,
  ButtonLabel,
  IDDIV,
  FILEDIV,
  SelectDate,
  Flex,
  UploadButton,
  Important,
  Modal,
  Div,
  Label,
  Option,
  Radio,
  Button,
  FLEXDIV2,
  ICONDIV,
  Input,
  InputField,
  NameDIV,
} from "./firstScreen.styles";
import { BiCalendar } from "react-icons/bi";
import { HiArrowLongUp } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import { BsChevronLeft, BsEnvelopeFill } from "react-icons/bs";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  baseUrl,
  useCarOwnerProfileMutation,
  useDriverPictureUpdateMutation,
  useDriverProfileUpdateMutation,
  useDriverUpdateMutation,
  useUpdateProfileMutation,
} from "../../../services/providerApi";

import "react-multi-date-picker/styles/colors/yellow.css";
import type { Value } from "react-multi-date-picker";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FLEXDIV } from "../secondScreen/secondScreen.styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { createTheme, ThemeProvider } from "@mui/material";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { setProfile } from "../../../slices/provider";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { baseImageUrl } from "../../../services/utils";
import axios from "axios";
import { setRole } from "../../../slices/auth";
import Compressor from "compressorjs";
import { LocationInput } from "../../providerCarReg/thirdScreen/thirdScreen.styles";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { FaMapMarkerAlt } from "react-icons/fa";
import { VerifiedButton } from "../../customerSignUp/secondStep/second.styles";

type Props = {
  userCategory?: string;
};
const FirstScreen: FC<Props> = ({ userCategory }) => {
  const theme = createTheme({
    palette: {},
  });
  const color = "#c44242";
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const eighteenYearsAgo = dayjs().subtract(6574, "day");

  const dispatch: any = useAppDispatch();
  const user = useAppSelector((state: any) => state?.auth?.auth);
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const category = useAppSelector(
    (state: any) => state?.provider?.account_type
  );
  const profileStore = useAppSelector((state: any) => state?.provider?.profile);
  const serviceOffering = useAppSelector(
    (state: any) => state?.provider?.service_offering
  );
  const driverInfo = useAppSelector(
    (state: any) => state?.provider?.uploadedDriverInfo
  );

  let convertedDob: any = profileStore?.dob || "01/01/2000";
  const [day, month, year] = convertedDob?.split("/");
  const date = new Date(`${month}/${day}/${year}`);
  const formattedDate = date?.toString();

  const [value, setValue] = useState<String | Number | null>();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const handlePhone = (e: any) => {
    setPhoneNumber(e);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefBusiness = useRef<HTMLInputElement>(null);
  const [genderModal, setGenderModal] = useState<boolean>(false);
  const [idModal, setIdModal] = useState<boolean>(false);
  const [image1Prev, setImage1Prev] = useState<any>();

  const [image1, setImage1] = useState<any>();
  const [btnLoad, setBtnLoad] = useState<boolean>(false);

  const [passId, setPassId] = useState<boolean>(false);
  // const [btnLoad, setBtnLoad] = useState<boolean>(false)
  // const [btnLoad, setBtnLoad] = useState<boolean>(false)

  const [gender, setGender] = useState<string>(profileStore?.gender);
  const [meansofID, setMeansofID] = useState<string>(
    profileStore?.identification
  );
  const [IDNumber, setIDNumber] = useState<string>(profileStore?.id_number);

  //to capture individual registration
  const [firstName, setFirstName] = useState<string>(user?.firstname);
  const [lastName, setLastName] = useState<string>(user?.lastname);
  const [DOB, setDOB] = useState<any>(formattedDate);
  const [userState, setUserState] = useState<string>(profileStore?.state);
  const [userCountry, setUserCountry] = useState<string>(profileStore?.country);

  //to capture business Vehicle registration
  const [ownerFirstName, setOwnerFirstName] = useState<string>("");
  const [ownerLastName, setOwnerLastName] = useState<string>("");
  const [ownerEmail, setOwnerEmail] = useState<string>("");
  const [ownerDOB, setOwnerDOB] = useState<any>(formattedDate);

  //to capture  Driver registration
  const [driverFirstName, setDriverFirstName] = useState<string>(
    driverInfo?.firstname
  );
  const [driverLastName, setDriverLastName] = useState<string>(
    driverInfo?.lastname
  );

  const handleIDNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIDNumber((event.target as HTMLTextAreaElement).value);
    dispatch(
      setProfile({ id_number: (event.target as HTMLTextAreaElement).value })
    );
  };

  const handleDriverFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDriverFirstName((event.target as HTMLTextAreaElement).value);
  };
  const handleDriverLastName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDriverLastName((event.target as HTMLTextAreaElement).value);
  };
  const handleOwnerFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOwnerFirstName((event.target as HTMLTextAreaElement).value);
  };
  const handleOwnerLastName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOwnerLastName((event.target as HTMLTextAreaElement).value);
  };
  const handleOwnerEmail = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOwnerEmail((event.target as HTMLTextAreaElement).value);
  };

  const handleCountry = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let country: any = event;
    setUserCountry(country);
    dispatch(setProfile({ country }));
  };

  const handleState = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let state: any = event;
    setUserState(state);
    dispatch(setProfile({ state }));
  };

  const handleFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(
      setProfile({ firstname: (event.target as HTMLTextAreaElement).value })
    );
    setFirstName((event.target as HTMLTextAreaElement).value);
  };

  const handleLastName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(
      setProfile({ lastname: (event.target as HTMLTextAreaElement).value })
    );
    setLastName((event.target as HTMLTextAreaElement).value);
  };

  const openModalIDModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIdModal((prev) => !prev);
    setGenderModal(false);
  };
  const openGenderModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGenderModal((prev) => !prev);
    setIdModal(false);
  };
  const selectMale = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGender("Male");
    dispatch(setProfile({ gender: "Male" }));
    setGenderModal(false);
  };
  const selectFemale = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGender("Female");
    dispatch(setProfile({ gender: "Female" }));
    setGenderModal(false);
  };
  const selectOthers = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGender("Others");
    dispatch(setProfile({ gender: "Others" }));
    setGenderModal(false);
  };
  const selectNIN = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setMeansofID("NIN");
    dispatch(setProfile({ identification: "NIN" }));
    setIdModal(false);
  };
  const selectInternationalPassport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setMeansofID("International Passport");
    dispatch(setProfile({ identification: "International Passport" }));
    setIdModal(false);
  };
  const selectLicense = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setMeansofID("Drivers License");
    dispatch(setProfile({ identification: "Drivers License" }));
    setIdModal(false);
  };
  const [updateDriverPicture, { data: IdImageData }] =
    useDriverPictureUpdateMutation();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImage1Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage1Prev(e.target.result);
        }

        new Compressor(file[0], {
          quality: 0.6,
          success: (compressedResult: any) => {
            setImage1(compressedResult);

            const formData = new FormData();
            formData.append("id", driverInfo?.id);
            formData.append("id_image", compressedResult);

            //@ts-ignore
            updateDriverPicture(formData)
              .then((response: any) => {
                if (response?.data?.message === "success") {
                  notifySuccess("ID Image Saved");
                } else {
                  notifyError("Something went wrong");
                }
              })
              .catch((err) => {});
          },
        });
      };
      reader.readAsDataURL(file[0]);
    }
    // setFrontImage(false)
    // setBackImage(true)
  };
  const [updateProfile, { data, isLoading, isSuccess, isError, error }] =
    useUpdateProfileMutation();
  const [
    carOwnerProfile,
    {
      data: carOwnerData,
      isLoading: loadingCarOwnerProfile,
      isSuccess: successCarOwner,
      isError: isErrorCarOwner,
      error: errorCarOwner,
    },
  ] = useCarOwnerProfileMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);

      if (userCategory?.toLowerCase().includes("business")) {
        navigate("/add-business");
      } else {
        if (serviceOffering === "Driver") {
          navigate("/add-guarantor");
        } else {
          navigate("/next-of-kin");
        }
      }
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  useEffect(() => {
    if (loadingCarOwnerProfile) {
      setBtnLoad(true);
    }
    if (successCarOwner) {
      setBtnLoad(false);

      if (userCategory?.toLowerCase().includes("business")) {
        navigate("/add-business");
      } else {
        if (serviceOffering === "Driver") {
          navigate("/add-guarantor");
        } else {
          navigate("/next-of-kin");
        }
      }
    }

    if (isErrorCarOwner && "status" in errorCarOwner!) {
      if (
        isErrorCarOwner &&
        errorCarOwner.status.toString() === "FETCH_ERROR"
      ) {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isErrorCarOwner, carOwnerData]);

  const alertError = () => {
    notifyError("Please check if all details are correctly filled");
  };

  const userStates = useAppSelector((state: any) => state?.auth);

  const handleVerification = async () => {
    try {
      let endpoint = "";
      if (meansofID === "NIN") {
        endpoint = `${baseUrl}verify-nin?nin_no=${IDNumber}`;
      }
      if (meansofID === "Drivers License") {
        endpoint = `${baseUrl}verify-licence?licence_no=${IDNumber}&dob=${moment(
          DOB
        ).format("YYYY-MM-DD")}`;
      }
      if (meansofID === "International Passport") {
        endpoint = `${baseUrl}verify-passport?passport_no=${IDNumber}&lastname=${
          driverLastName || lastName
        }`;
      }
      const response = await axios.post(endpoint, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response?.data.data.toLowerCase().includes("wrong" || "invalid")) {
        notifyError(response?.data.data);
      } else {
        notifySuccess(response?.data.data);
        setPassId(true);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message.includes(
          "There were validation errors for International Passport Verification"
        )
      ) {
        notifyError("Your International is inValid");
        setPassId(false);
      } else if (
        error?.response?.data?.message.includes(
          "There were validation errors for Virtual NIN Full Details"
        )
      ) {
        notifyError("Your NIN Virtual Number is inValid");
        setPassId(false);
      } else if (
        error?.response?.data?.message.includes(
          "There were validation errors for Driver Licence Full Details"
        )
      ) {
        notifyError("Your Driver's License is inValid");
        setPassId(false);
      } else {
        notifyError(error?.response?.data?.message);
        setPassId(false);
      }
    }
  };

  const handleUpdateBusinessProfile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(setRole("provider"));

    let formData: any = new FormData();
    formData.append("vehicle_id", lastVehicleDetails[0]?.id);
    formData.append("address", address);

    formData.append("firstname", ownerFirstName);
    formData.append("lastname", ownerLastName);
    formData.append("email", ownerEmail);

    formData.append("gender", gender);
    formData.append("dob", DOB);
    formData.append("id_number", IDNumber);
    formData.append("id_image", image1);
    formData.append("identification", meansofID);

    formData.append("phone_number", phoneNumber);
    formData.append("category", "business");
    formData.append("type", "different");

    carOwnerProfile(formData)
      .then((response: any) => {
        dispatch(setRole("provider"));
      })
      .catch((err) => {});
  };
  const handleUpdateProfile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let formData: any = new FormData();
    formData.append("id", user?.id);
    formData.append("gender", gender);
    formData.append("dob", DOB);
    formData.append("id_number", IDNumber);
    formData.append("id_image", image1);
    formData.append("identification", meansofID);
    formData.append("state", userState);
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("email", user?.email);
    formData.append("country", userCountry);
    formData.append("phone_number", user?.phone_number);
    formData.append("category", category?.toLowerCase());
    formData.append("service", "vehicle_driver");
    formData.append("user_role", "provider");

    updateProfile(formData)
      .then((response: any) => {
        dispatch(setRole(response?.data?.data?.user_role));
      })
      .catch((err) => {});
  };
  const [
    updateDriverProfile,
    {
      data: driverData,
      isLoading: driverLoading,
      isSuccess: driverSuccess,
      isError: driverIsError,
      error: driverError,
    },
  ] = useDriverProfileUpdateMutation();

  const handleUpdateDriverProfile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let formData: any = new FormData();
    formData.append("id", driverInfo?.id);
    formData.append("country", userCountry);
    formData.append("state", userState);
    formData.append("dob", DOB);
    formData.append("identification", meansofID);
    formData.append("id_number", IDNumber);
    formData.append("gender", gender);
    formData.append("firstname", driverFirstName);
    formData.append("lastname", driverLastName);
    // formData.append('id_image', image1)
    //@ts-ignore
    updateDriverProfile(formData)
      .then((response: any) => {
        // console.log(response)
        if (response?.data?.message === "success") {
          if (userCategory?.toLowerCase().includes("business")) {
            navigate("/add-business");
          } else {
            navigate("/add-guarantor");
          }
        }
      })
      .catch((err) => {});
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const lastVehicleDetails: any = useAppSelector(
    (state: any) => state?.provider.vehicle
  );

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [address, setAddress] = useState("");

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setAddress(places);
      } else {
        setAddress(autocomplete.getPlace().name);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChange}
      />

      {!userCategory?.toLowerCase().includes("business") ? (
        serviceOffering === "Driver" ? (
          <>
            <NameDIV>
              <FLEXDIV style={{ marginTop: "10px" }}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                  }}
                >
                  <P>
                    Firstname <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    style={{ padding: "17px", border: "none", outline: "none" }}
                    value={driverFirstName}
                    onChange={handleDriverFirstName}
                  />
                </div>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                  }}
                >
                  <P>
                    Lastname <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    style={{ padding: "17px", border: "none", outline: "none" }}
                    value={driverLastName}
                    onChange={handleDriverLastName}
                  />
                </div>
              </FLEXDIV>
            </NameDIV>

            <DIV style={{ marginTop: 25 }}>
              <COLDIV>
                <P>
                  Gender <Important>*</Important>
                </P>
                <SELECTDIV>
                  <Text>{gender ? gender : "Select"}</Text>
                  <DropDown onClick={openGenderModal}>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </SELECTDIV>
                <div>
                  {genderModal && (
                    <Div style={{ width: "300px" }}>
                      <Modal>
                        <Option onClick={selectMale}>
                          <Radio
                            style={
                              gender === "Male"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> Male</Text>
                        </Option>
                        <Option onClick={selectFemale}>
                          <Radio
                            style={
                              gender === "Female"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> Female</Text>
                        </Option>
                        <Option onClick={selectOthers}>
                          <Radio
                            style={
                              gender === "Others"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> Others</Text>
                        </Option>
                      </Modal>
                    </Div>
                  )}
                </div>
              </COLDIV>
              <COLDIV>
                <P>
                  Date of Birth <Important>*</Important>
                </P>
                <Flex>
                  <INPUTS
                    type="text"
                    readOnly
                    style={{
                      fontSize: "13px",
                      padding: "19px 0px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder={moment(DOB).format("DD/MM/YYYY")||DOB}
                    value={moment(DOB).format("DD/MM/YYYY")||DOB}
                  />
                  <SelectDate>
                    <BiCalendar
                      style={{ color: "#fff", position: "absolute" }}
                    />

                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          open={open}
                          onOpen={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          value={DOB}
                          disableMaskedInput={true}
                          maxDate={eighteenYearsAgo}
                          showToolbar={false}
                          onChange={(date: any) => {
                            setDOB(moment(date).format("DD/MM/YYYY"));

                          }}
                          renderInput={(params) => (
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onClick={(e: any) => setOpen(true)}
                              onKeyDown={onKeyDown}
                              {...params}
                            />
                          )}
                          // @ts-ignore

                          sx={{ background: "transparent" }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </SelectDate>
                </Flex>
              </COLDIV>
            </DIV>

            <COLDIV style={{ width: "100%" }}>
              <P>
                Means of ID <Important>*</Important>
              </P>

              {passId === true ? (
                <SELECTDIV style={{ backgroundColor: "#eee" }}>
                  <Label>{meansofID ? meansofID : "Select"}</Label>

                  <DropDown>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </SELECTDIV>
              ) : (
                <SELECTDIV>
                  <Label>{meansofID ? meansofID : "Select"}</Label>

                  <DropDown onClick={openModalIDModal}>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </SELECTDIV>
              )}

              <div>
                {idModal && (
                  <Div>
                    <Modal style={{ width: "70%" }}>
                      <Option onClick={selectInternationalPassport}>
                        <Radio
                          style={
                            meansofID === "International Passport"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>International Passport</Text>
                      </Option>
                      <Option onClick={selectLicense}>
                        <Radio
                          style={
                            meansofID === "Drivers License"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>Drivers License</Text>
                      </Option>
                      <Option onClick={selectNIN}>
                        <Radio
                          style={
                            meansofID === "NIN"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> NIN</Text>
                      </Option>
                    </Modal>
                  </Div>
                )}
              </div>
            </COLDIV>

            <COLDIV style={{ width: "100%", marginTop: 30 }}>
              <P>
                ID Number <Important>*</Important>
              </P>

              <div
                style={
                  passId === true
                    ? {
                        borderRadius: 7,
                        position: "relative",
                        backgroundColor: "#ddd",
                        border: "none",
                      }
                    : {
                        borderRadius: 7,
                        position: "relative",
                        backgroundColor: "#fff",
                        border: "none",
                      }
                }
              >
                {passId === true ? (
                  <INPUTS
                    type="text"
                    value={IDNumber}
                    // onChange={handleIDNumber}
                    readOnly
                    style={{
                      backgroundColor: "#ddd",
                      fontWeight: "600",
                      width: "80%",
                      border: "none",
                      padding: "17px",
                      outline: "none",
                    }}
                    placeholder={
                      meansofID === "NIN" ? "NIN Virtual Number" : "ID Number"
                    }
                  />
                ) : (
                  <INPUTS
                    type="text"
                    value={IDNumber}
                    onChange={handleIDNumber}
                    style={{
                      fontWeight: "600",
                      width: "80%",
                      border: "none",
                      padding: "17px",
                      outline: "none",
                    }}
                    placeholder={
                      meansofID === "NIN" ? "NIN Virtual Number" : "ID Number"
                    }
                  />
                )}

                {/* {currentLocation === "NG" ? (
                  <>
                    {passId === true ? (
                      <VerifiedButton
                        style={{ position: "absolute", right: 12, top: "15px" }}
                      >
                        Verified
                      </VerifiedButton>
                    ) : (
                      <VerifyButton
                        onClick={handleVerification}
                        style={{ position: "absolute", right: 12, top: "15px" }}
                      >
                        Verify
                      </VerifyButton>
                    )}
                  </>
                ) : (
                  ""
                )} */}
              </div>
            </COLDIV>

            <IDDIV
              style={{
                marginTop: 45,
                background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${ID})`,
                objectFit: "cover",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              {image1Prev ? (
                <>
                  <img
                    src={image1Prev}
                    alt=""
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                  <FILEDIV
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.preventDefault();
                      if (fileInputRef.current != null) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    <img src={snap} alt="" width="30px" />
                  </FILEDIV>
                </>
              ) : (
                <UploadButton
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    if (fileInputRef.current != null) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <ButtonLabel>Upload ID </ButtonLabel>
                  <img src={ownership} alt="" width="50px" />
                  <HiArrowLongUp style={{ fontSize: "22px" }} />
                </UploadButton>
              )}
            </IDDIV>

            <DIV style={{ marginTop: 25 }}>
              <COLDIV>
                <P>
                  Country <Important>*</Important>
                </P>

                <div className="country-select">
                  <RegionDiv>
                    <CountryDropdown
                      value={userCountry}
                      //@ts-ignore
                      onChange={handleCountry}
                    />
                  </RegionDiv>
                </div>
              </COLDIV>

              <COLDIV>
                <P>
                  State <Important>*</Important>
                </P>
                <div className="country-select">
                  <RegionDiv>
                    <RegionDropdown
                      country={userCountry}
                      value={userState}
                      //@ts-ignore
                      onChange={handleState}
                    />
                  </RegionDiv>
                </div>
              </COLDIV>
            </DIV>

            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept="image/*"
              onChange={onChange}
            />

            {image1 === undefined ||
            // gender === undefined ||
            meansofID === undefined ||
            IDNumber === undefined ||
            userState === undefined ||
            userCountry === undefined?
             (
              <DIV style={{ justifyContent: "center" }}>
                <Button
                  onClick={() => alertError()}
                  style={{ background: "#000", color: "gray" }}
                >
                  Continue
                </Button>
              </DIV>
            ) : (
              <>
                {btnLoad ? (
                  <DIV style={{ justifyContent: "center" }}>
                    <Button style={{ background: "#000", color: "grey" }}>
                      Please Wait...
                    </Button>
                  </DIV>
                ) : (
                  <DIV style={{ justifyContent: "center" }}>
                    <Button onClick={handleUpdateDriverProfile}>
                      Continue
                    </Button>
                  </DIV>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <NameDIV>
              <FLEXDIV style={{ marginBottom: "25px" }}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                  }}
                >
                  <P>
                    Firstname <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    style={{ padding: "17px", border: "none", outline: "none" }}
                    value={firstName}
                    onChange={handleFirstName}
                  />
                </div>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                  }}
                >
                  <P>
                    Lastname <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    style={{ padding: "17px", border: "none", outline: "none" }}
                    value={lastName}
                    onChange={handleLastName}
                  />
                </div>
              </FLEXDIV>
            </NameDIV>

            <DIV>
              <COLDIV>
                <P>
                  Gender <Important>*</Important>
                </P>
                <SELECTDIV>
                  <Text>{gender ? gender : "Select"}</Text>
                  <DropDown onClick={openGenderModal}>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </SELECTDIV>
                <div>
                  {genderModal && (
                    <Div style={{ width: "300px" }}>
                      <Modal>
                        <Option onClick={selectMale}>
                          <Radio
                            style={
                              gender === "Male"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> Male</Text>
                        </Option>
                        <Option onClick={selectFemale}>
                          <Radio
                            style={
                              gender === "Female"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> Female</Text>
                        </Option>
                        <Option onClick={selectOthers}>
                          <Radio
                            style={
                              gender === "Others"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> Others</Text>
                        </Option>
                      </Modal>
                    </Div>
                  )}
                </div>
              </COLDIV>
              <COLDIV>
                <P>
                  Date of Birth <Important>*</Important>
                </P>
                <Flex>
                  <INPUTS
                    type="text"
                    readOnly
                    style={{
                      fontSize: "13px",
                      padding: "19px 0px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder={moment(DOB).format("DD/MM/YYYY")||DOB}
                    value={moment(DOB).format("DD/MM/YYYY")||DOB}

                  />
                  <SelectDate>
                    <BiCalendar
                      style={{ color: "#fff", position: "absolute" }}
                    />

                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          open={open}
                          onOpen={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          value={DOB}
                          disableMaskedInput={true}
                          maxDate={eighteenYearsAgo}
                          showToolbar={false}
                          onChange={(date: any) => {
                            // setDOB(JSON.stringify(new Date(date).toString()));
                            setDOB(moment(date).format("DD/MM/YYYY"));

                          }}
                          renderInput={(params) => (
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onClick={(e: any) => setOpen(true)}
                              onKeyDown={onKeyDown}
                              {...params}
                            />
                          )}
                          // @ts-ignore

                          sx={{ background: "transparent" }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </SelectDate>
                </Flex>
              </COLDIV>
            </DIV>

            <COLDIV style={{ width: "100%" }}>
              <P>
                Means of ID <Important>*</Important>
              </P>

              {passId === true ? (
                <SELECTDIV style={{ backgroundColor: "#eee" }}>
                  <Label>{meansofID ? meansofID : "Select"}</Label>

                  <DropDown>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </SELECTDIV>
              ) : (
                <SELECTDIV>
                  <Label>{meansofID ? meansofID : "Select"}</Label>

                  <DropDown onClick={openModalIDModal}>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </SELECTDIV>
              )}

              <div>
                {idModal && (
                  <Div>
                    <Modal style={{ width: "70%" }}>
                      <Option onClick={selectInternationalPassport}>
                        <Radio
                          style={
                            meansofID === "International Passport"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>International Passport</Text>
                      </Option>
                      <Option onClick={selectLicense}>
                        <Radio
                          style={
                            meansofID === "Drivers License"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>Drivers License</Text>
                      </Option>
                      <Option onClick={selectNIN}>
                        <Radio
                          style={
                            meansofID === "NIN"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> NIN</Text>
                      </Option>
                    </Modal>
                  </Div>
                )}
              </div>
            </COLDIV>

            <COLDIV style={{ width: "100%", marginTop: 30 }}>
              <P>
                ID Number <Important>*</Important>
              </P>

              <div
                style={
                  passId === true
                    ? {
                        borderRadius: 7,
                        position: "relative",
                        backgroundColor: "#ddd",
                        border: "none",
                      }
                    : {
                        borderRadius: 7,
                        position: "relative",
                        backgroundColor: "#fff",
                        border: "none",
                      }
                }
              >
                {passId === true ? (
                  <INPUTS
                    type="text"
                    value={IDNumber}
                    // onChange={handleIDNumber}
                    readOnly
                    style={{
                      backgroundColor: "#ddd",
                      fontWeight: "600",
                      width: "80%",
                      border: "none",
                      padding: "17px",
                      outline: "none",
                    }}
                    placeholder={
                      meansofID === "NIN" ? "NIN Virtual Number" : "ID Number"
                    }
                  />
                ) : (
                  <INPUTS
                    type="text"
                    value={IDNumber}
                    onChange={handleIDNumber}
                    style={{
                      fontWeight: "600",
                      width: "80%",
                      border: "none",
                      padding: "17px",
                      outline: "none",
                    }}
                    placeholder={
                      meansofID === "NIN" ? "NIN Virtual Number" : "ID Number"
                    }
                  />
                )}

                {/* {currentLocation === "NG" ? (
                  <>
                    {passId === true ? (
                      <VerifiedButton
                        style={{ position: "absolute", right: 12, top: "15px" }}
                      >
                        Verified
                      </VerifiedButton>
                    ) : (
                      <VerifyButton
                        onClick={handleVerification}
                        style={{ position: "absolute", right: 12, top: "15px" }}
                      >
                        Verify
                      </VerifyButton>
                    )}
                  </>
                ) : (
                  ""
                )} */}
              </div>
            </COLDIV>

            <IDDIV
              style={{
                background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${ID})`,
                objectFit: "cover",
                backgroundPosition: "center",
                backgroundSize: "cover",
                marginTop: 50,
              }}
            >
              {image1Prev ? (
                <>
                  <img
                    src={image1Prev}
                    alt=""
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                  <FILEDIV
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.preventDefault();
                      if (fileInputRef.current != null) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    <img src={snap} alt="" width="30px" />
                  </FILEDIV>
                </>
              ) : (
                <UploadButton
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    if (fileInputRef.current != null) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <ButtonLabel>Upload ID </ButtonLabel>
                  <img src={ownership} alt="" width="50px" />
                  <HiArrowLongUp style={{ fontSize: "22px" }} />
                </UploadButton>
              )}
            </IDDIV>

            <DIV style={{ marginTop: 25 }}>
              <COLDIV>
                <P>
                  Country <Important>*</Important>
                </P>

                <div className="country-select">
                  <RegionDiv>
                    <CountryDropdown
                      value={userCountry}
                      //@ts-ignore
                      onChange={handleCountry}
                    />
                  </RegionDiv>
                </div>
              </COLDIV>

              <COLDIV>
                <P>
                  State <Important>*</Important>
                </P>
                <div className="country-select">
                  <RegionDiv>
                    <RegionDropdown
                      country={userCountry}
                      value={userState}
                      //@ts-ignore
                      onChange={handleState}
                    />
                  </RegionDiv>
                </div>
              </COLDIV>
            </DIV>

            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept="image/*"
              onChange={onChange}
            />

            {image1 === undefined ||
            gender === undefined ||
            meansofID === undefined ||
            IDNumber === undefined ||
            userState === undefined ||
            userCountry === undefined ?
            (
              <DIV style={{ justifyContent: "center" }}>
                <Button
                  onClick={() => alertError()}
                  style={{ background: "#000", color: "gray" }}
                >
                  Continue
                </Button>
              </DIV>
            ) : (
              <>
                {btnLoad ? (
                  <DIV style={{ justifyContent: "center" }}>
                    <Button style={{ background: "#000", color: "grey" }}>
                      Please Wait...
                    </Button>
                  </DIV>
                ) : (
                  <DIV style={{ justifyContent: "center" }}>
                    <Button onClick={handleUpdateProfile}>Continue</Button>
                  </DIV>
                )}
              </>
            )}
          </>
        )
      ) : (
        <>
          {serviceOffering === "Driver" ? (
            <>
              <NameDIV>
                <FLEXDIV style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                    }}
                  >
                    <P>
                      Firstname <Important>*</Important>
                    </P>
                    <INPUTS
                      type="text"
                      style={{
                        padding: "17px",
                        border: "none",
                        outline: "none",
                      }}
                      value={driverFirstName}
                      onChange={handleDriverFirstName}
                    />
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                    }}
                  >
                    <P>
                      Lastname <Important>*</Important>
                    </P>
                    <INPUTS
                      type="text"
                      style={{
                        padding: "17px",
                        border: "none",
                        outline: "none",
                      }}
                      value={driverLastName}
                      onChange={handleDriverLastName}
                    />
                  </div>
                </FLEXDIV>
              </NameDIV>
              <DIV style={{ marginTop: 25 }}>
                <COLDIV>
                  <P>
                    Gender <Important>*</Important>
                  </P>
                  <SELECTDIV>
                    <Text>{gender ? gender : "Select"}</Text>
                    <DropDown onClick={openGenderModal}>
                      <IoIosArrowDown
                        style={{
                          color: "#FFD58C",
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      />
                    </DropDown>
                  </SELECTDIV>
                  <div>
                    {genderModal && (
                      <Div style={{ width: "300px" }}>
                        <Modal>
                          <Option onClick={selectMale}>
                            <Radio
                              style={
                                gender === "Male"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> Male</Text>
                          </Option>
                          <Option onClick={selectFemale}>
                            <Radio
                              style={
                                gender === "Female"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> Female</Text>
                          </Option>
                          <Option onClick={selectOthers}>
                            <Radio
                              style={
                                gender === "Others"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> Others</Text>
                          </Option>
                        </Modal>
                      </Div>
                    )}
                  </div>
                </COLDIV>
                <COLDIV>
                  <P>
                    Date of BirthSS <Important>*</Important>
                  </P>
                  <Flex>
                    <INPUTS
                      type="text"
                      readOnly
                      style={{
                        fontSize: "13px",
                        padding: "19px 0px",
                        border: "none",
                        outline: "none",
                      }}
                      placeholder={moment(DOB).format("DD/MM/YYYY")||DOB}
                      value={moment(DOB).format("DD/MM/YYYY")||DOB}
                    />
                    <SelectDate>
                      <BiCalendar
                        style={{ color: "#fff", position: "absolute" }}
                      />

                      <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            value={DOB}
                            disableMaskedInput={true}
                            maxDate={eighteenYearsAgo}
                            showToolbar={false}
                            onChange={(date: any) => {
                              // setDOB(JSON.stringify(new Date(date).toString()));
                            setDOB(moment(date).format("DD/MM/YYYY"));
                              
                            }}
                            renderInput={(params) => (
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onClick={(e: any) => setOpen(true)}
                                onKeyDown={onKeyDown}
                                {...params}
                              />
                            )}
                            // @ts-ignore

                            sx={{ background: "transparent" }}
                          />
                        </LocalizationProvider>
                      </ThemeProvider>
                      
                    </SelectDate>
                  </Flex>
                </COLDIV>
              </DIV>

              <DIV>
                <COLDIV>
                  <P>
                    Means of ID <Important>*</Important>
                  </P>
                  <SELECTDIV>
                    <Label>{meansofID ? meansofID : "Select"}</Label>

                    <DropDown onClick={openModalIDModal}>
                      <IoIosArrowDown
                        style={{
                          color: "#FFD58C",
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      />
                    </DropDown>
                  </SELECTDIV>

                  <div>
                    {idModal && (
                      <Div>
                        <Modal style={{ width: "70%" }}>
                          <Option onClick={selectInternationalPassport}>
                            <Radio
                              style={
                                meansofID === "International Passport"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text>International Passport</Text>
                          </Option>
                          <Option onClick={selectLicense}>
                            <Radio
                              style={
                                meansofID === "Drivers License"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text>Drivers License</Text>
                          </Option>
                          <Option onClick={selectNIN}>
                            <Radio
                              style={
                                meansofID === "NIN"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> NIN</Text>
                          </Option>
                        </Modal>
                      </Div>
                    )}
                  </div>
                </COLDIV>
                <COLDIV>
                  <P>
                    ID Number <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    value={IDNumber}
                    onChange={handleIDNumber}
                    style={{
                      fontWeight: "600",
                      padding: "17px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="ID Number"
                  />
                </COLDIV>
              </DIV>

              <IDDIV
                style={{
                  background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${ID})`,
                  objectFit: "cover",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                {image1Prev ? (
                  <>
                    <img
                      src={image1Prev}
                      alt=""
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                    <FILEDIV
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        event.preventDefault();
                        if (fileInputRef.current != null) {
                          fileInputRef.current.click();
                        }
                      }}
                    >
                      <img src={snap} alt="" width="30px" />
                    </FILEDIV>
                  </>
                ) : (
                  <UploadButton
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.preventDefault();
                      if (fileInputRef.current != null) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    <ButtonLabel>Upload ID </ButtonLabel>
                    <img src={ownership} alt="" width="50px" />
                    <HiArrowLongUp style={{ fontSize: "22px" }} />
                  </UploadButton>
                )}
              </IDDIV>

              <DIV style={{ marginTop: 25 }}>
                <COLDIV>
                  <P>
                    Country <Important>*</Important>
                  </P>

                  <div className="country-select">
                    <RegionDiv>
                      <CountryDropdown
                        value={userCountry}
                        //@ts-ignore
                        onChange={handleCountry}
                      />
                    </RegionDiv>
                  </div>
                </COLDIV>

                <COLDIV>
                  <P>
                    State <Important>*</Important>
                  </P>
                  <div className="country-select">
                    <RegionDiv>
                      <RegionDropdown
                        country={userCountry}
                        value={userState}
                        //@ts-ignore
                        onChange={handleState}
                      />
                    </RegionDiv>
                  </div>
                </COLDIV>
              </DIV>

              {image1 === undefined ||
              // gender === undefined ||
              meansofID === undefined ||
              IDNumber === undefined ||
              userState === undefined ||
              userCountry === undefined ?
              (
                <DIV style={{ justifyContent: "center" }}>
                  <Button
                    onClick={() => alertError()}
                    style={{ background: "#000", color: "gray" }}
                  >
                    Continue
                  </Button>
                </DIV>
              ) : (
                <>
                  {btnLoad ? (
                    <DIV style={{ justifyContent: "center" }}>
                      <Button style={{ background: "#000", color: "grey" }}>
                        Please Wait...
                      </Button>
                    </DIV>
                  ) : (
                    <DIV style={{ justifyContent: "center" }}>
                      <Button onClick={handleUpdateDriverProfile}>
                        Continue
                      </Button>
                    </DIV>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <DIV>
                <COLDIV>
                  <P>
                    Firstname <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    value={ownerFirstName}
                    onChange={handleOwnerFirstName}
                    style={{
                      fontWeight: "600",
                      padding: "17px",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </COLDIV>

                <COLDIV>
                  <P>
                    Lastname <Important>*</Important>
                  </P>
                  <INPUTS
                    type="text"
                    value={ownerLastName}
                    onChange={handleOwnerLastName}
                    style={{
                      fontWeight: "600",
                      padding: "17px",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </COLDIV>
              </DIV>

              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                }}
              >
                <P>
                  Email Address <Important>*</Important>
                </P>

                <FLEXDIV2>
                  <ICONDIV style={{ width: "28%" }}>
                    <BsEnvelopeFill />
                  </ICONDIV>
                  <Input>
                    <InputField
                      type="text"
                      value={ownerEmail}
                      onChange={handleOwnerEmail}
                    />
                  </Input>
                </FLEXDIV2>
              </div>

              <div
                style={{
                  marginTop: 25,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                }}
              >
                <P>
                  Phone Number <Important>*</Important>
                </P>

                <Input style={{ marginTop: -10 }}>
                  <PhoneInput
                    defaultCountry="NG"
                    value={phoneNumber}
                    onChange={handlePhone}
                    international
                    withCountryCallingCode
                  />
                </Input>
              </div>

              <P style={{ marginTop: 35 }}>
                Address <Important>*</Important>
              </P>
              <DIV style={{ marginTop: 20 }}>
                <FLEXDIV2>
                  <ICONDIV style={{ width: "28%" }}>
                    <FaMapMarkerAlt />
                  </ICONDIV>
                  <Input>
                    <Autocomplete
                      className="autocomplete-wrap"
                      onPlaceChanged={onPlaceChanged}
                      onLoad={(value) => setAutocomplete(value)}
                    >
                      <InputField
                        onChange={(e: any) => {
                          setAddress(e.target.value);
                        }}
                        value={address}
                        type="text"
                      />
                    </Autocomplete>
                  </Input>
                </FLEXDIV2>
              </DIV>

              <DIV>
                <COLDIV>
                  <P>
                    Gender <Important>*</Important>
                  </P>
                  <SELECTDIV>
                    <Text>{gender ? gender : "Select"}</Text>
                    <DropDown onClick={openGenderModal}>
                      <IoIosArrowDown
                        style={{
                          color: "#FFD58C",
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      />
                    </DropDown>
                  </SELECTDIV>
                  <div>
                    {genderModal && (
                      <Div style={{ width: "300px" }}>
                        <Modal>
                          <Option onClick={selectMale}>
                            <Radio
                              style={
                                gender === "Male"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> Male</Text>
                          </Option>
                          <Option onClick={selectFemale}>
                            <Radio
                              style={
                                gender === "Female"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> Female</Text>
                          </Option>
                          <Option onClick={selectOthers}>
                            <Radio
                              style={
                                gender === "Others"
                                  ? { background: "#ffa100" }
                                  : { background: "#fff" }
                              }
                            ></Radio>
                            <Text> Others</Text>
                          </Option>
                        </Modal>
                      </Div>
                    )}
                  </div>
                </COLDIV>
                <COLDIV>
                  <P>
                    Date of Birth <Important>*</Important>
                  </P>
                  <Flex>
                    <INPUTS
                      type="text"
                      readOnly
                      style={{
                        fontSize: "13px",
                        padding: "19px 0px",
                        border: "none",
                        outline: "none",
                      }}
                      placeholder={moment(DOB).format("DD/MM/YYYY")||DOB}
                      value={moment(DOB).format("DD/MM/YYYY")||DOB}
                    />
                    <SelectDate>
                      <BiCalendar
                        style={{ color: "#fff", position: "absolute" }}
                      />

                      <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            value={DOB}
                            disableMaskedInput={true}
                            maxDate={eighteenYearsAgo}
                            showToolbar={false}
                            onChange={(date: any) => {
                              // setDOB(JSON.stringify(new Date(date).toString()));
                              // alert(JSON.stringify(new Date(date).toString()))
                              setDOB(moment(date).format("DD/MM/YYYY"));

                            }}
                            renderInput={(params) => (
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onClick={(e: any) => setOpen(true)}
                                onKeyDown={onKeyDown}
                                {...params}
                              />
                            )}
                            // @ts-ignore

                            sx={{ background: "transparent" }}
                          />
                        </LocalizationProvider>
                      </ThemeProvider>
                    </SelectDate>
                  </Flex>
                </COLDIV>
              </DIV>

              <COLDIV style={{ width: "100%" }}>
                <P>
                  Means of ID <Important>*</Important>
                </P>

                {passId === true ? (
                  <SELECTDIV style={{ backgroundColor: "#eee" }}>
                    <Label>{meansofID ? meansofID : "Select"}</Label>

                    <DropDown>
                      <IoIosArrowDown
                        style={{
                          color: "#FFD58C",
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      />
                    </DropDown>
                  </SELECTDIV>
                ) : (
                  <SELECTDIV>
                    <Label>{meansofID ? meansofID : "Select"}</Label>

                    <DropDown onClick={openModalIDModal}>
                      <IoIosArrowDown
                        style={{
                          color: "#FFD58C",
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      />
                    </DropDown>
                  </SELECTDIV>
                )}

                <div>
                  {idModal && (
                    <Div>
                      <Modal style={{ width: "70%" }}>
                        <Option onClick={selectInternationalPassport}>
                          <Radio
                            style={
                              meansofID === "International Passport"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text>International Passport</Text>
                        </Option>
                        <Option onClick={selectLicense}>
                          <Radio
                            style={
                              meansofID === "Drivers License"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text>Drivers License</Text>
                        </Option>
                        <Option onClick={selectNIN}>
                          <Radio
                            style={
                              meansofID === "NIN"
                                ? { background: "#ffa100" }
                                : { background: "#fff" }
                            }
                          ></Radio>
                          <Text> NIN</Text>
                        </Option>
                      </Modal>
                    </Div>
                  )}
                </div>
              </COLDIV>

              <COLDIV style={{ width: "100%", marginTop: 30 }}>
                <P>
                  ID Number <Important>*</Important>
                </P>

                <div
                  style={
                    passId === true
                      ? {
                          borderRadius: 7,
                          position: "relative",
                          backgroundColor: "#ddd",
                          border: "none",
                        }
                      : {
                          borderRadius: 7,
                          position: "relative",
                          backgroundColor: "#fff",
                          border: "none",
                        }
                  }
                >
                  {passId === true ? (
                    <INPUTS
                      type="text"
                      value={IDNumber}
                      // onChange={handleIDNumber}
                      readOnly
                      style={{
                        backgroundColor: "#ddd",
                        fontWeight: "600",
                        width: "80%",
                        border: "none",
                        padding: "17px",
                        outline: "none",
                      }}
                      placeholder={
                        meansofID === "NIN" ? "NIN Virtual Number" : "ID Number"
                      }
                    />
                  ) : (
                    <INPUTS
                      type="text"
                      value={IDNumber}
                      onChange={handleIDNumber}
                      style={{
                        fontWeight: "600",
                        width: "80%",
                        border: "none",
                        padding: "17px",
                        outline: "none",
                      }}
                      placeholder={
                        meansofID === "NIN" ? "NIN Virtual Number" : "ID Number"
                      }
                    />
                  )}

                  {/* {currentLocation === "NG" ? (
                    <>
                      {passId === true ? (
                        <VerifiedButton
                          style={{
                            position: "absolute",
                            right: 12,
                            top: "15px",
                          }}
                        >
                          Verified
                        </VerifiedButton>
                      ) : (
                        <VerifyButton
                          onClick={handleVerification}
                          style={{
                            position: "absolute",
                            right: 12,
                            top: "15px",
                          }}
                        >
                          Verify
                        </VerifyButton>
                      )}
                    </>
                  ) : (
                    ""
                  )} */}
                </div>
              </COLDIV>

              <IDDIV
                style={{
                  background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${ID})`,
                  objectFit: "cover",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  marginTop: 50,
                }}
              >
                {image1Prev ? (
                  <>
                    <img
                      src={image1Prev}
                      alt=""
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                    <FILEDIV
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        event.preventDefault();
                        if (fileInputRefBusiness.current != null) {
                          fileInputRefBusiness.current.click();
                        }
                      }}
                    >
                      <img src={snap} alt="" width="30px" />
                    </FILEDIV>
                  </>
                ) : (
                  <UploadButton
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.preventDefault();
                      if (fileInputRefBusiness.current != null) {
                        fileInputRefBusiness.current.click();
                      }
                    }}
                  >
                    <ButtonLabel>Upload ID </ButtonLabel>
                    <img src={ownership} alt="" width="50px" />
                    <HiArrowLongUp style={{ fontSize: "22px" }} />
                  </UploadButton>
                )}
              </IDDIV>

              {ownerFirstName?.length < 3 ||
              ownerLastName?.length < 3 ||
              ownerEmail?.length < 3 ||
              address?.length < 3 ||
              phoneNumber?.length < 11 ?
              (
                <DIV style={{ justifyContent: "center" }}>
                  <Button
                    onClick={() => alertError()}
                    style={{ background: "#000", color: "gray" }}
                  >
                    Continue
                  </Button>
                </DIV>
              ) : (
                <>
                  {btnLoad ? (
                    <DIV style={{ justifyContent: "center" }}>
                      <Button style={{ background: "#000", color: "grey" }}>
                        Please Wait...
                      </Button>
                    </DIV>
                  ) : (
                    <DIV style={{ justifyContent: "center" }}>
                      <Button onClick={handleUpdateBusinessProfile}>
                        Continue
                      </Button>
                    </DIV>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}


<input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChange}
      />

<input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRefBusiness}
        accept="image/*"
        onChange={onChange}
      />
    </>
  );
};

export default FirstScreen;
