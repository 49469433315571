import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    StatsBox, P, IconDiv, H3, Button, Div, StatsDiv, Stat, ButtonGroup
} from './stats.styles';
import folder from '../../../assets/images/png/folder.png'

import { BsCalendar3, BsFillFolderFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';


const Stats = ({ requestCount }: any) => {


    let totalCount;
    if (requestCount !== undefined) {

        //@ts-ignore
        totalCount = Object.values(requestCount).reduce((acc, count) => acc + count, 0);
    }

    return (
        <>
            <StatsDiv>
                <StatsBox>
                    <Link to='/list-requests' style={{ textDecoration: 'none' }}>
                        <P>Total Requests</P>
                        <IconDiv >
                            <BsCalendar3 style={{ fontSize: '11px' }} />
                        </IconDiv>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '10px' }}>
                            <img src={folder} alt="" width="35px" />
                            <H3>{totalCount}</H3>
                        </div>
                    </Link>

                </StatsBox>
                <Div>

                    <ButtonGroup>
                        <Button style={{ background: '#CF0303' }}>
                            Open
                        </Button>
                        <Stat>{requestCount?.countOpen || 0}</Stat>

                    </ButtonGroup>

                    <ButtonGroup>
                        <Button style={{ background: '#005FEE' }}>
                            Pending
                        </Button>
                        <Stat>{requestCount?.countPending || 0}</Stat>

                    </ButtonGroup>

                    <ButtonGroup>
                        <Button style={{ background: '#ffa100', color: '#000' }}>
                            In Progress
                        </Button>
                        <Stat>{requestCount?.countProgress || 0}</Stat>

                    </ButtonGroup>

                    <ButtonGroup>
                        <Button style={{ background: '#07B418', color: '#000' }}>
                            Closed
                        </Button>
                        <Stat>{requestCount?.countCompleted || 0}</Stat>

                    </ButtonGroup>



                </Div>
            </StatsDiv>
        </>
    )
}

export default Stats