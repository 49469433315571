import React from 'react'
import Header from '../../../components/header'
import Footer from '../../../components/footer'
import TransactionAlertBox from '../../../components/transactionAlertBox'
import { AlertPageOverall, AlertHeader } from '../TransactionSuccessPage/successpage.styles'
import { Link } from 'react-router-dom'
import { AlertWrapper, ProgressWrap, ProgressBox, ButtonFooter, FailedAlertWrapper } from '../../../components/transactionAlertBox/transactionalertbox.styles'

import smiley from '../../../assets/images/png/failed-wallet.png'



const TransactionFailedPage = () => {
  return (
    <>
        <Header />
        <AlertPageOverall>
        
            <AlertHeader>
                <h1>Oops Transaction Unsuccessful</h1>
            </AlertHeader>

            <FailedAlertWrapper>
                <div className="inner-wrapper">
                    {/* <h3>{alertHeader}</h3> */}

                    <div className="image-wrap">
                        <img src={smiley}/>
                    </div>

                    <h3>Please check your payment details and balance</h3>

                    <ProgressWrap className="progress-wrap">
                      <ProgressBox>
                      </ProgressBox>
                    </ProgressWrap>

                    <p style={{textAlign: 'center'}}>
                    There are several ways you can try to pay, through your wallet and through bank or card
                    </p>


                    <Link to={'/cart'}>
                      <ButtonFooter className="try-btn" btnBg={"#000"}>
                          <h5>{'Try Again'}</h5>
                      </ButtonFooter>
                    </Link>

                </div>
            </FailedAlertWrapper>

        </AlertPageOverall>
        <Footer />
    </>
  )
}

export default TransactionFailedPage