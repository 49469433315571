import styled from 'styled-components'
import { CurrencyWrap, OverFlowWrap } from '../collectMoneytwo/collectmoneytwo.styles'
import { SubmitBtn } from '../toggleBar/togglebar.styles'

export const CollectMoneyThreeWrap = styled.div `
    display: ${(props:any) => props.display};
`

export const TextHeadWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;

    & h2 {
        font-weight: 700;
        font-size: 16px;
        color: #000;
        margin: 0;
        text-align: center;
        
        span {
            color: #AA6C05;
        }
    }

    @media only screen and (max-width: 454px)  {
        h2 {
            font-size: 12px;
        }
    } 
`

export const DarkGreyBox = styled.div `
    background-color: #EAEAEA;
    border-radius: 13px;
    padding: 20px;
    margin: 20px 0;
`

export const DescriptionBox = styled.div `
    background-color: #fff;
    border-radius: 14px;
    padding: 15px;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
`

export const DescriptionTxt = styled.p `
    font-size: 12px;
    color: #09051C;
    margin: 0;
    text-align: center;

    @media only screen and (max-width: 454px)  {
        font-size: 9px;
    }   
`

export const H14 = styled.h6 `
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    color: #868686;
    text-align: center;

    @media only screen and (max-width: 454px)  {
        font-size: 11px;
    } 
`

export const AmtPayBox = styled(CurrencyWrap) `
    padding: 9px 25px;
    margin-top: 10px;
    & h5 {
        font-size: 11px;
        color: #FFD58C;
        margin: 0;
    }

    @media only screen and (max-width: 454px)  {
        h5 {
            font-size: 8px;
        }  
    } 
`

export const NameFootWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 7px;

    & .profile-wrap {
        border-radius: 50%;
        background-color: #FFD58C;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        img {
            width: 25px;
        }
    }

    & p {
        font-weight: 700;
        font-size: 16px;
        color: #09051C;
    }

    @media only screen and (max-width: 454px)  {
        gap: 7px;

        .profile-wrap {
            img {
                width: 15px;
            }
        }

        p {
            font-size: 12px;
        }
    } 
`

export const OverflowBox = styled(OverFlowWrap) `
    height: 200px;
`

export const PayBtn = styled(SubmitBtn) `
    background-color: #FFA100;

    & p {
        color: #09051C;
    }
`