import React, { FC, useEffect, useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import {
    H3, P, DOCDIV, DOC1, DOC2, DOC3, DOC, P2, H4, ProofVerify, VerifyDiv, AcceptButton,
    RejectButton
} from './vehicleDoc.styles';
import Footer from '../../../../components/footer'
import ownership from '../../../../assets/images/png/ownership.png'
import license from '../../../../assets/images/png/license.png'
import worthiness from '../../../../assets/images/png/worthiness.png'
import pdff from '../../../../assets/images/png/pdff.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from '../../../../store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import { baseUrl } from '../../../../services/providerApi';
import { Button, Div } from '../documents.styles';
import { useVerifyVehicleDocumentMutation } from '../../../../services/adminApi';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import { baseImageUrl } from '../../../../services/utils';
import { SubmitButton, SubmitDiv } from '../../regularRates/regularRates.styles';

type Props = {
    vehicleProfile: any;
    setLicenseDocStatus?: any;
    setProofDocStatus?: any;
    user_id?:any;
}
const VehicleDoc: FC<Props> = ({ user_id,setProofDocStatus, setLicenseDocStatus, vehicleProfile }) => {
    const [verifyVehicleDoc, { data, isLoading, isSuccess, isError, error }] = useVerifyVehicleDocumentMutation()

    const [verifyLicense, { data: licenseData, isLoading: loadingLicense, isSuccess: licenseSuccess,
        isError: licenseIsError, error: licenseError }] = useVerifyVehicleDocumentMutation()

    const [verifyWorthiness, { data: worthinessData, isLoading: loadingWorthiness, isSuccess: worthinessSuccess,
        isError: worthinessIsError, error: worthinessError }] = useVerifyVehicleDocumentMutation()


    const user = useAppSelector((state: any) => state?.auth?.auth)
    const vehicleId = vehicleProfile?.data[0]?.id
    const vehicleDoc = vehicleProfile?.data[0]?.vehicle_document2
    const token = useAppSelector((state: any) => state?.auth?.auth?.token)
    const [isSaveLoadingProof, setIsSaveLoadingProof] = useState(false)
    const [isSaveLoadingLicense, setIsSaveLoadingLicense] = useState(false)
    const [isSaveLoadingWorthiness, setIsSaveLoadingWorthiness] = useState(false)

    const [showSaveButtonProof, setShowSaveButtonProof] = useState(false)
    const [showSaveButtonLicense, setShowSaveButtonLicense] = useState(false)
    const [showSaveButtonWorthiness, setShowSaveButtonWorthiness] = useState(false)


    

    const ProofOfOwnership = vehicleDoc?.filter((document: any) => {
        return document?.doc_type?.includes('proof')
    })
    const roadWorthiness = vehicleDoc?.filter((document: any) => {
        return document?.doc_type?.includes('road')
    })
    const vehicleLicense = vehicleDoc?.filter((document: any) => {
        return document?.doc_type?.includes('vehicle')
    })
    const [proofStatus, setProofStatus] = useState<any>(ProofOfOwnership[0]?.status || '');
    const [licenseStatus, setLicenseStatus] = useState<any>(vehicleLicense[0]?.status || '');
    const [worthinessStatus, setWorthinessStatus] = useState<any>(roadWorthiness[0]?.status || '');
    const proofInputRef = useRef<HTMLInputElement>(null);
    const licenseInputRef = useRef<HTMLInputElement>(null);
    const worthinessInputRef = useRef<HTMLInputElement>(null);
    const [proofImage, setProofImage] = useState<any>(ProofOfOwnership?.length > 0 ? baseImageUrl + ProofOfOwnership[0]?.document : '');
    const [licenseImage, setLicenseImage] = useState<any>(vehicleLicense?.length > 0 ? baseImageUrl + vehicleLicense[0]?.document : '');
    const [worthinessImage, setWorthinessImage] = useState<any>(roadWorthiness?.length > 0 ? baseImageUrl + roadWorthiness[0]?.document : '');

    // const [proofYear, setProofYear] = useState<any>();
    const [licenseYear, setLicenseYear] = useState<any>(vehicleLicense[0]?.year);
    const [worthinessYear, setWorthinessYear] = useState<any>(roadWorthiness[0]?.year);
    // const [proofMonth, setProofMonth] = useState<any>();
    const [licenseMonth, setLicenseMonth] = useState<any>(vehicleLicense[0]?.month);
    const [worthinessMonth, setWorthinessMonth] = useState<any>(roadWorthiness[0]?.month);

    const [proofImageClicked, setProofImageClicked] = useState<boolean>(false);
    const [licenseImageClicked, setLicenseImageClicked] = useState<boolean>(false);
    const [worthinessImageClicked, setWorthinessImageClicked] = useState<boolean>(false);


    const [proofImagePrev, setProofImagePrev] = useState<any>(ProofOfOwnership?.length > 0 ? baseImageUrl + ProofOfOwnership[0]?.document : '')
    const [licenseImagePrev, seticenseImagePrev] = useState<any>(vehicleLicense?.length > 0 ? baseImageUrl + vehicleLicense[0]?.document : '')
    const [worthinessImagePrev, setWorthinessImagePrev] = useState<any>(roadWorthiness?.length > 0 ? baseImageUrl + roadWorthiness[0]?.document : '')


    const [proofExt, setProofExt] = React.useState('');
    const [licenseExt, setLicenseExt] = React.useState('');
    const [worthinessExt, setWorthinessExt] = React.useState('');

    const [btnLoad, setBtnLoad] = useState(false)

    //@ts-ignore


    const onChangeProof = async (event: React.ChangeEvent<HTMLInputElement>) => {

        event.persist();
        const file = event.currentTarget.files;
        if (file != null) {
            setProofImagePrev(file);
            let reader = new FileReader();
            //@ts-ignore
            reader.readAsDataURL(file[0])
            reader.onload = (e) => {
                if (e.target != null) {
                    setProofImagePrev(e.target.result);
                }
                //@ts-ignore
                let Doc = file[0].name
                let arr: any = Doc.split(".").pop();
                setProofExt(arr)
                setProofImage(file[0])
                setProofImageClicked(true)
                setWorthinessImageClicked(false)
                setLicenseImageClicked(false)
                setShowSaveButtonProof(true)
            }
        };

    };

    const onChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const file = event.currentTarget.files;
        if (file != null) {
            seticenseImagePrev(file);
            let reader = new FileReader();
            //@ts-ignore
            reader.readAsDataURL(file[0])
            reader.onload = (e) => {
                if (e.target != null) {
                    seticenseImagePrev(e.target.result);
                }
                setLicenseImage(file[0]);
                setProofImageClicked(false)
                setWorthinessImageClicked(false)
                setLicenseImageClicked(true)
                //@ts-ignore
                let Doc = file[0].name
                let arr: any = Doc.split(".").pop();
                setLicenseExt(arr)
                setShowSaveButtonLicense(true)
            }

        };

    };

    const onChangeWorthiness = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const file = event.currentTarget.files;
        if (file != null) {
            setWorthinessImagePrev(file);
            let reader = new FileReader();
            //@ts-ignore
            reader.readAsDataURL(file[0])
            reader.onload = (e) => {
                if (e.target != null) {
                    setWorthinessImagePrev(e.target.result);
                }
                setWorthinessImage(file[0]);
                setProofImageClicked(false)
                setLicenseImageClicked(false)
                setWorthinessImageClicked(true)
                //@ts-ignore
                let Doc = file[0].name
                let arr: any = Doc.split(".").pop();
                setWorthinessExt(arr)
                setShowSaveButtonWorthiness(true)
            }

        };

    };




    let currentYear: any = new Date().getFullYear();



    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const handleAcceptProof = () => {
        let formData: any = new FormData();
        formData.append('id', ProofOfOwnership[0]?.id);
        formData.append("comment", ProofOfOwnership[0]?.comment);
        formData.append("status", 'Accepted');
        setProofStatus('Loading')

        verifyVehicleDoc(formData).then((response: any) => {
            setProofDocStatus(response?.data?.data?.status)
            setProofStatus(response?.data?.data?.status)


        })


    }

    const handleRejectProof = () => {
        let formData: any = new FormData();
        formData.append('id', ProofOfOwnership[0]?.id);
        formData.append("comment", ProofOfOwnership[0]?.comment);
        formData.append("status", 'Rejected');
        verifyVehicleDoc(formData)
        setProofStatus('Loading')
        setProofDocStatus('Rejected')
    }
    const handleAcceptLicense = () => {
        let formData: any = new FormData();
        formData.append('id', vehicleLicense[0]?.id);
        formData.append("comment", vehicleLicense[0]?.comment);
        formData.append("status", 'Accepted');
        verifyLicense(formData)
        setLicenseStatus('Loading')
        verifyLicense(formData).then((response: any) => {
            setLicenseDocStatus(response?.data?.data?.status)
            setLicenseStatus(response?.data?.data?.status)


        })
    }
    const handleRejectLicense = () => {
        let formData: any = new FormData();
        formData.append('id', vehicleLicense[0]?.id);
        formData.append("comment", vehicleLicense[0]?.comment);
        formData.append("status", 'Rejected');
        verifyLicense(formData)
        setLicenseStatus('Loading')
        setLicenseDocStatus('Rejected')
    }

    const handleAcceptWorthiness = () => {
        let formData: any = new FormData();
        formData.append('id', roadWorthiness[0]?.id);
        formData.append("comment", roadWorthiness[0]?.comment);
        formData.append("status", 'Accepted');
        verifyWorthiness(formData)
        setWorthinessStatus('Loading')
    }
    const handleRejectWorthiness = () => {
        let formData: any = new FormData();
        formData.append('id', roadWorthiness[0]?.id);
        formData.append("comment", roadWorthiness[0]?.comment);
        formData.append("status", 'Rejected');
        verifyWorthiness(formData)
        setWorthinessStatus('Loading')
    }

    useEffect(() => {
        if (isLoading) {
        }

        if (isSuccess) {
            setProofStatus(data?.data?.status)
        }
        if (licenseSuccess) {
            setLicenseStatus(licenseData?.data?.status)

        }
        if (worthinessSuccess) {
            setWorthinessStatus(worthinessData?.data?.status)

        }

        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
                // setIsDelete(false)
            }
            // setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data, licenseSuccess, licenseData,
        worthinessData, worthinessSuccess])








    const handleUpload = async () => {
        setIsSaveLoadingProof(true)

        try {

            if (ProofOfOwnership.length < 1) {
                let formData = new FormData();
                formData.append('file', proofImage);
                formData.append('doc_type', 'proof_of_ownership');
                formData.append('vehicle_id', vehicleId);
                formData.append('user_id', user_id);


                axios({
                    method: "post",
                    url: `${baseUrl}proof-of-ownership`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/formdata",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(function (response) {
                    const message = response.data.data;
                    setIsSaveLoadingProof(false)
                    setShowSaveButtonProof(false)


                }).catch(function (error) {
                    //handle error
                });
            } else {
                let formData = new FormData();
                formData.append('id', ProofOfOwnership[0]?.id);
                formData.append('file', proofImage);
                formData.append('doc_type', 'proof_of_ownership');
                formData.append('vehicle_id', vehicleId);
                formData.append('user_id', user_id);

                axios({
                    method: "post",
                    url: `${baseUrl}update-vehicle-document`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/formdata",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(function (response) {
                    const message = response.data.data;
                    setIsSaveLoadingProof(false)
                    setShowSaveButtonProof(false)
                }).catch(function (error) {
                    //handle error
                    setIsSaveLoadingProof(false)
                    setShowSaveButtonProof(false)
                });
            }
        } catch (error) {
            setBtnLoad(false)

            console.error(error);
        }
    };

    const handleUploadLicense = async () => {
        setIsSaveLoadingLicense(true)

        try {
            if (vehicleLicense.length < 1) {
                let formData = new FormData();
                formData.append('file', licenseImage);
                formData.append('doc_type', 'vehicle_licence');
                formData.append('vehicle_id', vehicleId);
                formData.append('year', licenseYear);
                formData.append('month', licenseMonth);
                formData.append('user_id', user_id);

                axios({
                    method: "post",
                    url: `${baseUrl}vehicle-licence`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/formdata",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(function (response) {
                    const message = response.data.data;
                    setIsSaveLoadingLicense(false)
                    setShowSaveButtonLicense(false)

                }).catch(function (error) {
                    //handle error
                    setIsSaveLoadingLicense(false)
                    setShowSaveButtonLicense(false)

                });
            } else {
                let formData = new FormData();
                formData.append('id', vehicleLicense[0]?.id);
                formData.append('file', licenseImage);
                formData.append('doc_type', 'vehicle_licence');
                formData.append('vehicle_id', vehicleId);
                formData.append('doc_expire_year', licenseYear);
                formData.append('doc_expire_month', licenseMonth);
                formData.append('user_id', user_id);

                axios({
                    method: "post",
                    url: `${baseUrl}update-vehicle-document`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/formdata",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(function (response) {
                    const message = response.data.data;
                    setIsSaveLoadingLicense(false)
                    setShowSaveButtonLicense(false)

                }).catch(function (error) {
                    //handle error
                    setIsSaveLoadingLicense(false)
                    setShowSaveButtonLicense(false)
                });
            }
        } catch (error) {
            setBtnLoad(false)
            console.error(error);
        }
    };
    const handleUploadWorthiness = async () => {
        setIsSaveLoadingWorthiness(true)
        try {
            if (roadWorthiness?.length < 1) {
                let formData = new FormData();
                formData.append('file', worthinessImage);
                formData.append('doc_type', 'road-worthiness');
                formData.append('vehicle_id', vehicleId);
                formData.append('year', worthinessYear);
                formData.append('month', worthinessMonth);
                formData.append('user_id', user_id);

                axios({
                    method: "post",
                    url: `${baseUrl}road-worthiness`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/formdata",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(function (response) {
                    const message = response.data.data;
                    setIsSaveLoadingWorthiness(false)
                    setShowSaveButtonWorthiness(false)
                }).catch(function (error) {
                    //handle error
                    setIsSaveLoadingWorthiness(false)
                    setShowSaveButtonWorthiness(false)
                });
            } else {
                let formData = new FormData();
                formData.append('id', roadWorthiness[0]?.id);
                formData.append('file', worthinessImage);
                formData.append('doc_type', 'road-worthiness');
                formData.append('vehicle_id', vehicleId);
                formData.append('doc_expire_year', worthinessYear);
                formData.append('doc_expire_month', worthinessMonth);
                formData.append('user_id', user_id);

                axios({
                    method: "post",
                    url: `${baseUrl}update-vehicle-document`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/formdata",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(function (response) {
                    const message = response.data.data;
                    setIsSaveLoadingWorthiness(false)
                    setShowSaveButtonWorthiness(false)
                }).catch(function (error) {
                    //handle error
                    setIsSaveLoadingWorthiness(false)
                    setShowSaveButtonWorthiness(false)
                });
            }
        } catch (error) {
            setBtnLoad(false)

            console.error(error);
        }
    };
    return (
        <>
            <ToastContainer />
            <DOCDIV>
                <DOC onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.preventDefault();
                    setProofImageClicked(true)
                    setLicenseImageClicked(false)
                    setWorthinessImageClicked(false)
                }}>
                    <DOC1>
                        {
                            proofImage ?
                                (proofExt !== 'pdf' ?
                                    < img src={proofImagePrev} alt="" style={{ objectFit: 'cover', width: '100%' }} /> :
                                    <img src={pdff} alt="" width={80} />) :
                                <img src={ownership} alt="" width={80}
                                    //@ts-ignore
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        event.preventDefault();
                                        if (proofInputRef.current != null) {
                                            proofInputRef.current.click()
                                        }
                                    }}
                                />
                        }

                        {proofImagePrev ?

                            <span
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    event.preventDefault();
                                    if (proofInputRef.current != null) {
                                        proofInputRef.current.click()
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '5px',
                                    zIndex: 1000,
                                    color: '#ffa100',
                                    background: '#000',
                                    borderRadius: '50%',
                                    width: '26px',
                                    height: '26px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <AiOutlineClose style={{ fontSize: '17px' }} />
                            </span> :
                            <span style={{ position: 'absolute', right: '13px', top: '10px' }}>&uarr;</span>
                        }
                        {
                            proofImage ?
                                <span style={{ display: 'flex', alignItems: 'center', background: '#000', padding: '1px 5px', position: 'absolute', left: '13px', bottom: '10px' }}>

                                    <FaRegEye onClick={(e) => {
                                        e.preventDefault();
                                        const target = e.target as HTMLAnchorElement;
                                        window.open(baseImageUrl + ProofOfOwnership[0]?.document, '_blank');
                                    }} />

                                </span > : ''
                        }
                    </DOC1 >
                    <P2>Proof of ownership</P2>
                </DOC >
                <DOC
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.preventDefault();
                        setLicenseImageClicked(true)
                        setProofImageClicked(false)
                        setWorthinessImageClicked(false)
                    }}>
                    <DOC2>
                        {
                            licenseImage ?
                                (licenseExt !== 'pdf' ?
                                    < img src={licenseImagePrev} alt="" style={{ objectFit: 'cover', width: '100%' }} /> :
                                    <img src={pdff} alt="" width={80} />) :
                                <img src={worthiness} alt="" width={80}
                                    //@ts-ignore
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        event.preventDefault();
                                        if (licenseInputRef.current != null) {
                                            licenseInputRef.current.click()
                                        }
                                    }}
                                />
                        }

                        {licenseImagePrev ?

                            <span
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    event.preventDefault();
                                    if (licenseInputRef.current != null) {
                                        licenseInputRef.current.click()
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '5px',
                                    zIndex: 1000,
                                    color: '#ffa100',
                                    background: '#000',
                                    borderRadius: '50%',
                                    width: '26px',
                                    height: '26px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <AiOutlineClose style={{ fontSize: '17px' }} />
                            </span> :
                            <span style={{ position: 'absolute', right: '13px', top: '10px' }}>&uarr;</span>
                        }


                        {
                            licenseImage ?
                                <span style={{ display: 'flex', alignItems: 'center', background: '#000', padding: '1px 5px', position: 'absolute', left: '13px', bottom: '10px' }}>

                                    <FaRegEye onClick={(e) => {
                                        e.preventDefault();
                                        const target = e.target as HTMLAnchorElement;
                                        window.open(baseImageUrl + vehicleLicense[0]?.document, '_blank');
                                    }} />

                                </span> : ''
                        }

                    </DOC2>
                    <P2 >Vehicle License</P2>
                </DOC>
                <DOC onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.preventDefault();
                    setLicenseImageClicked(false)
                    setProofImageClicked(false)
                    setWorthinessImageClicked(true)

                }}>
                    <DOC3>
                        {
                            worthinessImage ?
                                (worthinessExt !== 'pdf' ?
                                    < img src={worthinessImagePrev} alt="" style={{ objectFit: 'cover', width: '100%' }} /> :
                                    <img src={pdff} alt="" width={80} />) :
                                <img src={license} alt="" width={80}
                                    //@ts-ignore
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        event.preventDefault();
                                        if (worthinessInputRef.current != null) {
                                            worthinessInputRef.current.click()
                                        }
                                    }}
                                />
                        }

                        {worthinessImagePrev ?

                            <span
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    event.preventDefault();
                                    if (worthinessInputRef.current != null) {
                                        worthinessInputRef.current.click()
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '5px',
                                    zIndex: 1000,
                                    color: '#ffa100',
                                    background: '#000',
                                    borderRadius: '50%',
                                    width: '26px',
                                    height: '26px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <AiOutlineClose style={{ fontSize: '17px' }} />
                            </span> :
                            <span style={{ position: 'absolute', right: '13px', top: '10px' }}>&uarr;</span>
                        }
                        {
                            worthinessImage ?
                                <span style={{ display: 'flex', alignItems: 'center', background: '#000', padding: '1px 5px', position: 'absolute', left: '13px', bottom: '10px' }}>

                                    <FaRegEye onClick={(e) => {
                                        e.preventDefault();
                                        const target = e.target as HTMLAnchorElement;
                                        window.open(baseImageUrl + roadWorthiness[0]?.document, '_blank');
                                    }} />
                                </span> : ''
                        }

                    </DOC3>
                    <P2 >Road Worthiness</P2>
                    <P2 style={{ color: '#EBAF4A', marginTop: '-5px' }}>(Optional)</P2>
                </DOC>

            </DOCDIV >

            {/* license expiry date */}
            {
                licenseImageClicked &&
                <>
                    <H4>Document Expiration Date</H4>
                    <DOCDIV>
                        <div style={{ width: '50%', borderRadius: '7px', border: '1px solid #fff' }}>
                            <select name="" id=""
                                value={licenseMonth} onChange={(event:any)=>setLicenseMonth(event.target.value)}

                                style={{ borderRadius: '6px', background: '#1D1C1C', color: '#fff', width: '95%', outline: 'none', border: 'none', fontSize: '14px', fontWeight: 'bold', padding: '.7em' }}>
                                <option value="">Month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </div>
                        <div style={{ width: '50%', borderRadius: '7px', border: '1px solid #fff' }}>
                            <select name="" id=""
                                value={licenseYear} onChange={(event:any)=>setLicenseYear(event.target.value)}
                                style={{ borderRadius: '6px', background: '#1D1C1C', color: '#fff', width: '95%', outline: 'none', border: 'none', fontSize: '14px', fontWeight: 'bold', padding: '.7em' }}>

                                <option value="">Year </option>
                                <option value={currentYear}>{currentYear} </option>
                                <option value={currentYear + 1}>{currentYear + 1} </option>
                                <option value={currentYear + 2}>{currentYear + 2} </option>
                                <option value={currentYear + 3}>{currentYear + 3} </option>
                                <option value={currentYear + 4}>{currentYear + 4} </option>
                                <option value={currentYear + 5}>{currentYear + 5} </option>
                                <option value={currentYear + 6}>{currentYear + 6} </option>
                                <option value={currentYear + 7}>{currentYear + 7} </option>
                                <option value={currentYear + 8}>{currentYear + 8} </option>
                                <option value={currentYear + 9}>{currentYear + 9} </option>
                                <option value={currentYear + 10}>{currentYear + 10} </option>
                            </select>
                        </div>
                    </DOCDIV>
                </>

            }

            {/* road worthiness expiry date */}

            {
                worthinessImageClicked &&
                <>
                    <H4>Document Expiration Date</H4>
                    <DOCDIV>
                        <div style={{ width: '50%', borderRadius: '7px', border: '1px solid #fff' }}>

                            <select name="" id="" value={worthinessMonth} onChange={(event:any)=>setWorthinessMonth(event.target.value)}
                                style={{ borderRadius: '6px', background: '#1D1C1C', color: '#fff', width: '95%', outline: 'none', border: 'none', fontSize: '14px', fontWeight: 'bold', padding: '.7em' }}>
                                <option value="">Month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </div>
                        <div style={{ width: '50%', borderRadius: '7px', border: '1px solid #fff' }}>

                            <select name="" id="" value={worthinessYear} onChange={(event:any)=>setWorthinessYear(event.target.value)}
                                style={{ borderRadius: '6px', background: '#1D1C1C', color: '#fff', width: '95%', outline: 'none', border: 'none', fontSize: '14px', fontWeight: 'bold', padding: '.7em' }}>

                                <option value="">Year </option>
                                <option value={currentYear}>{currentYear} </option>
                                <option value={currentYear + 1}>{currentYear + 1} </option>
                                <option value={currentYear + 2}>{currentYear + 2} </option>
                                <option value={currentYear + 3}>{currentYear + 3} </option>
                                <option value={currentYear + 4}>{currentYear + 4} </option>
                                <option value={currentYear + 5}>{currentYear + 5} </option>
                                <option value={currentYear + 6}>{currentYear + 6} </option>
                                <option value={currentYear + 7}>{currentYear + 7} </option>
                                <option value={currentYear + 8}>{currentYear + 8} </option>
                                <option value={currentYear + 9}>{currentYear + 9} </option>
                                <option value={currentYear + 10}>{currentYear + 10} </option>
                            </select>
                        </div>
                    </DOCDIV>
                </>

            }

            {
                user?.user_role === 'admin' &&
                <VerifyDiv>
                    <ProofVerify>
                        {proofStatus === 'Accepted' && <AcceptButton>Accepted</AcceptButton>}
                        {(proofStatus === 'Pending' || proofStatus === 'Rejected') && <AcceptButton onClick={handleAcceptProof}>Accept</AcceptButton>}
                        {proofStatus === 'Loading' && <AcceptButton >Loading..</AcceptButton>}
                        {proofStatus === 'Rejected' && <RejectButton>Rejected</RejectButton>}
                        {(proofStatus === 'Pending' || proofStatus === 'Accepted') && <RejectButton onClick={handleRejectProof}>Reject</RejectButton>}
                        {proofStatus === 'Loading' && <RejectButton>Loading..</RejectButton>}

                    </ProofVerify>

                    <ProofVerify>
                        {licenseStatus === 'Accepted' && <AcceptButton>Accepted</AcceptButton>}
                        {(licenseStatus === 'Pending' || licenseStatus === 'Rejected') && <AcceptButton onClick={handleAcceptLicense}>Accept</AcceptButton>}
                        {licenseStatus === 'Loading' && <AcceptButton >Loading..</AcceptButton>}
                        {licenseStatus === 'Rejected' && <RejectButton>Rejected</RejectButton>}
                        {(licenseStatus === 'Pending' || licenseStatus === 'Accepted') && <RejectButton onClick={handleRejectLicense}>Reject</RejectButton>}
                        {licenseStatus === 'Loading' && <RejectButton>Loading..</RejectButton>}
                    </ProofVerify>

                    <ProofVerify>
                        {worthinessStatus === 'Accepted' && <AcceptButton>Accepted</AcceptButton>}
                        {(worthinessStatus === 'Pending' || worthinessStatus === 'Rejected') && <AcceptButton onClick={handleAcceptWorthiness}>Accept</AcceptButton>}
                        {worthinessStatus === 'Loading' && <AcceptButton >Loading..</AcceptButton>}


                        {worthinessStatus === 'Rejected' && <RejectButton>Rejected</RejectButton>}
                        {(worthinessStatus === 'Pending' || worthinessStatus === 'Accepted') && <RejectButton onClick={handleRejectWorthiness}>Reject</RejectButton>}
                        {worthinessStatus === 'Loading' && <RejectButton>Loading..</RejectButton>}
                    </ProofVerify>
                </VerifyDiv>
            }

            <input type="file" style={{ display: 'none' }} accept="image/jpeg,image/jpg,image/png,application/pdf" ref={proofInputRef} onChange={onChangeProof} />
            <input type="file" style={{ display: 'none' }} accept="image/jpeg,image/jpg,image/png,application/pdf" ref={licenseInputRef} onChange={onChangeLicense} />
            <input type="file" style={{ display: 'none' }} accept="image/jpeg,image/jpg,image/png,application/pdf" ref={worthinessInputRef} onChange={onChangeWorthiness} />
           
            {
                showSaveButtonProof && <SubmitDiv>
                    {isSaveLoadingProof ? <SubmitButton style={{ backgroundColor: "#a10505" }}>
                        Updating Document...
                    </SubmitButton> :
                        <SubmitButton style={{ backgroundColor: "#a10505" }} onClick={() => handleUpload()}>
                            Update Proof of Ownership
                        </SubmitButton>}
                </SubmitDiv>
            }

            {
                (showSaveButtonLicense && licenseMonth && licenseYear) && <SubmitDiv>
                    {isSaveLoadingLicense ? <SubmitButton style={{ backgroundColor: "#a10505" }}>
                        Updating License...
                    </SubmitButton> :
                        <SubmitButton style={{ backgroundColor: "#a10505" }} onClick={() => handleUploadLicense()}>
                            Update License
                        </SubmitButton>}
                </SubmitDiv>
            }

            {
                (showSaveButtonWorthiness && worthinessMonth && worthinessYear) && <SubmitDiv>
                    {isSaveLoadingWorthiness ? <SubmitButton style={{ backgroundColor: "#a10505" }}>
                        Updating Road Worthiness...
                    </SubmitButton> :
                        <SubmitButton style={{ backgroundColor: "#a10505" }} onClick={() => handleUploadWorthiness()}>
                            Update Road Worthiness
                        </SubmitButton>}
                </SubmitDiv>
            }
        </>

    )
}

export default VehicleDoc