import styled from "styled-components"

export const DeliveryTopSide = styled.div `
    padding: 15px 15px;
    display: flex;
    align-items: center;
    gap: 5px;
`
export const DeliverPersonImgWrap = styled.div `
    width: 60px;
    height: 60px;
    border-radius: 50%;

    img {
        width: 100%;
        border-radius: inherit;
    }

    @media only screen and (max-width: 454px) {
        width: 40px;
        height: 40px;
    }
`

export const TimeWrapper = styled.div `
    background-color: #FFD58C;
    padding: 4px 10px;
    border-radius: 4px;
    gap: 3px;
    display: flex;
    align-items: center;

    & img {
        width: 20px;
    }

    p {
        white-space: nowrap;
        color: #000;
        font-size: 13px;
        margin: 0;
    }

    @media only screen and (max-width: 454px) {
        img {
            width: 15px;
        }

        p {
            font-size: 10px;
        }
    }
`

export const PickupWrap = styled.div `
    padding: 8px 15px;
    border-radius: 4px;
    background-color: #868686;
    

    p {
        margin: 0;
        font-weight: 600;
        color: #fff;
        font-size: 15px;
        white-space: nowrap;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media only screen and (max-width: 454px) {
        padding: 6px 10px;

        p {
            font-size: 11px;
            white-space: nowrap;
        }
    }
`

export const DeliveryBottomSide = styled.div `
    padding: 15px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ItemInfoWrap = styled.div `
    .name-wrap {
        color: #000;
        font-weight: 700;
        font-size: 14px;
        white-space:nowrap;
        margin-bottom: 10px;
    }

    .price-wrapper {
        background-color: #383837;
        border-radius: 4px;
        color: #fff;
        font-weight: 700;
        font-size: 13px;
        padding: 4px;
        margin-bottom: 10px;
        text-align: center;
        white-space: nowrap;
    }

    .no-of-deliveries {
        color: #868686 ;
        font-size: 13px;
        font-weight: 600;
        padding: 5px;
    }

    @media only screen and (max-width: 454px) {
        .name-wrap {
            font-size: 11px;
        }

        .price-wrapper {
            font-size: 10px;
        }

        .no-of-deliveries {
            font-size: 10px;
        }
    }
`

export const ItemImgWrap = styled.div`
    flex: 0.3;

    img {
        width: 80px;
    }
`

export const DeliveryActionSection = styled.div `
    flex: 0.4;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
`