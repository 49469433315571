import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'

import snap from "../../../assets/images/png/snap.png";

import {
  DIV,
  INPUTS,
  P,
  VerifyButton,
  Text,
  DropDown,
  COLDIV,
  RegionDiv,
  SELECTDIV,
  ButtonLabel,
  IDDIV,
  FILEDIV,
  SelectDate,
  Flex,
  UploadButton,
  Important,
  Modal,
  Div,
  Label,
  Option,
  Radio,
  Button,
  FLEXDIV2,
  ICONDIV,
  Input,
  InputField,
  NameDIV,
} from "./IndividualProfile.styles";

import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import { BsEnvelopeFill } from "react-icons/bs";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  useCarOwnerProfileMutation,
  useUpdateProfileMutation,
} from "../../../services/providerApi";
import DatePicker from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon";
import "react-multi-date-picker/styles/colors/yellow.css";
import type { Value } from "react-multi-date-picker";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { setProfile } from "../../../slices/provider";
import { FLEXDIV } from "../kin/kin.styles";
import axios from "axios";
import { SubmitButton, SubmitDiv } from "../regularRates/regularRates.styles";
import { ColumnWrap } from "../../fundWalletThree/fundwalletthree.styles";
import { baseImageUrl } from "../../../services/utils";
import { BiCalendar } from "react-icons/bi";
import {
  baseUrl,
  useUpdateOwnerProfileMutation,
} from "../../../services/adminApi";
import { useAppSelector } from "../../../store";
import { Autocomplete } from "@react-google-maps/api";
import { FaMapMarkerAlt } from "react-icons/fa";

type Props = {
  userId: any;
  providerInfo: any;
  setProviderInfo: any;
  vehicleId?: any;
  driverProfile?: any;
  vehicleProfile?: any;
};
const IndividualProfile: FC<Props> = ({
  vehicleProfile,
  driverProfile,
  vehicleId,
  setProviderInfo,
  providerInfo,
  userId,
}) => {
  const theme = createTheme({
    palette: {},
  });

  const [open, setOpen] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState(
    vehicleProfile?.data[0]?.carowner_profile[
      vehicleProfile?.data[0]?.carowner_profile?.length - 1
    ]
  );

  const [carOwnerInfo, setCarOwnerInfo] = useState(
    vehicleProfile?.data[0]?.carowner_profile[
      vehicleProfile?.data[0]?.carowner_profile?.length - 1
    ]
  );

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };
  useEffect(() => {
     
    if(providerInfo){
      setProviderInfo(providerInfo)
    }
  }, [providerInfo ])

  const token = useAppSelector((state: any) => state?.auth?.auth?.token);

  const getBusinessCarOwnerDetails = async () => {
    await axios
      .get(`${baseUrl}fetch-car-owner-detail/${vehicleId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setOwnerInfo(response?.data?.data[response?.data?.data.length - 1]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBusinessCarOwnerDetails();
  }, []);

  const eighteenYearsAgo = dayjs().subtract(6574, "day");

  let [image1Prev, setImage1Prev] = useState<any>(
    baseImageUrl + providerInfo?.id_image
  );

  let [imageBusinessPrev, setImageBusinessPrev] = useState<any>(
    baseImageUrl + ownerInfo?.id_image
  );

  useEffect(() => {
    if (providerInfo) {
      setImage1Prev(baseImageUrl + providerInfo?.id_image);
    }
  }, []);

  //@ts-ignore
  let convertedDob: any = providerInfo?.dob ;
  let day, month, year;
  //@ts-ignore
  if (providerInfo?.dob) {
    [day, month, year] = convertedDob?.split("/");
  }

  let date = new Date(`${month}/${day}/${year}`);
  let formattedDate = date?.toString();


   //@ts-ignore
   let convertedDobBusiness: any = ownerInfo?.dob;
   let d, m, y;
   //@ts-ignore
   if (ownerInfo?.dob) {
     [d, m, y] = convertedDobBusiness?.split("/");
   }
 
   let dateBusiness = new Date(`${m}/${d}/${y}`);
   let formattedDateBusiness = dateBusiness?.toString();


  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefBusiness = useRef<HTMLInputElement>(null);
  
  const [genderModal, setGenderModal] = useState<boolean>(false);
  const [idModal, setIdModal] = useState<boolean>(false);
  //@ts-ignore
  const [image1, setImage1] = useState<any>();
  const [btnLoad, setBtnLoad] = useState<boolean>(false);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [DOB, setDOB] = useState<any>(formattedDate || new Date());

  const handleIDNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      id_number: (event.target as HTMLTextAreaElement).value,
    }));
  };
  const handleIDNumberBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setOwnerInfo((prevState: any) => ({
      ...prevState,
      id_number: (event.target as HTMLTextAreaElement).value,
    }));
  };
  const handleFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      firstname: (event.target as HTMLTextAreaElement).value,
    }));
  };
  const handleLastName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      lastname: (event.target as HTMLTextAreaElement).value,
    }));
  };
  const handleEmail = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      email: (event.target as HTMLTextAreaElement).value,
    }));
  };

  const handlePhone = (e: any) => {
    setShowSaveButton(true);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      phone_number: e,
    }));
  };

  const handleCountry = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    let country: any = event;
    setProviderInfo((prevState: any) => ({
      ...prevState,
      country: country,
    }));
  };

  const handleState = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    let state: any = event;
    setProviderInfo((prevState: any) => ({
      ...prevState,
      state: state,
    }));
  };

  const openModalIDModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIdModal((prev) => !prev);
    setGenderModal(false);
  };
  const openGenderModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGenderModal((prev) => !prev);
    setIdModal(false);
  };
  const selectMale = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);
    setProviderInfo((prevState: any) => ({
      ...prevState,
      gender: "Male",
    }));
  };

  const selectMaleBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);
    setOwnerInfo((prevState: any) => ({
      ...prevState,
      gender: "Male",
    }));
  };
  const selectFemale = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      gender: "Female",
    }));
  };
  const selectFemaleBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);
    setOwnerInfo((prevState: any) => ({
      ...prevState,
      gender: "Female",
    }));
  };
  const selectOthers = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);

    setProviderInfo((prevState: any) => ({
      ...prevState,
      gender: "Others",
    }));
  };

  const selectOthersBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setGenderModal(false);

    setOwnerInfo((prevState: any) => ({
      ...prevState,
      gender: "Others",
    }));
  };

  const selectNIN = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setIdModal(false);
    setProviderInfo((prevState: any) => ({
      ...prevState,
      identification: "NIN",
    }));
  };

  const selectNINBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setIdModal(false);
    setOwnerInfo((prevState: any) => ({
      ...prevState,
      identification: "NIN",
    }));
  };
  const selectInternationalPassport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setIdModal(false);
    setProviderInfo((prevState: any) => ({
      ...prevState,
      identification: "International Passport",
    }));
  };
  const selectInternationalPassportBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);
    setIdModal(false);
    setOwnerInfo((prevState: any) => ({
      ...prevState,
      identification: "International Passport",
    }));
  };
  const selectLicense = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setIdModal(false);
    setProviderInfo((prevState: any) => ({
      ...prevState,
      identification: "Drivers License",
    }));
  };

  const selectLicenseBusiness = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowSaveButton(true);

    setIdModal(false);
    setOwnerInfo((prevState: any) => ({
      ...prevState,
      identification: "Drivers License",
    }));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSaveButton(true);

    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImage1Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage1Prev(e.target.result);
        }
        setImage1(file[0]);
      };
      reader.readAsDataURL(file[0]);

      setProviderInfo((prevState: any) => ({
        ...prevState,
        id_image: file[0],
      }));
    }
    // setFrontImage(false)
    // setBackImage(true)
  };

  const onChangeBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSaveButton(true);

    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImageBusinessPrev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImageBusinessPrev(e.target.result);
        }
        setImage1(file[0]);
      };
      reader.readAsDataURL(file[0]);

      setOwnerInfo((prevState: any) => ({
        ...prevState,
        id_image: file[0],
      }));
    }
    // setFrontImage(false)
    // setBackImage(true)
  };
  const [updateProfile, { data, isLoading, isSuccess, isError, error }] =
    useUpdateProfileMutation();
  const [
    updateOwnerProfile,
    {
      data: updateData,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateIsError,
      error: updateError,
    },
  ] = useUpdateOwnerProfileMutation();

  const handleUpdateProfile = () => {
    setIsSaveLoading(true);

    let formData: any = new FormData();
    formData.append("id", providerInfo?.id);
    formData.append("gender", providerInfo?.gender);
    formData.append("dob", providerInfo?.dob);
    formData.append("id_number", providerInfo?.id_number);
    formData.append("id_image", providerInfo?.id_image);
    formData.append("identification", providerInfo?.identification);
    formData.append("state", providerInfo?.state);
    formData.append("country", providerInfo?.country);
    formData.append("firstname", providerInfo?.firstname);
    formData.append("lastname", providerInfo?.lastname);
    formData.append("email", providerInfo?.email);
    formData.append("phone_number", providerInfo?.phone_number);
    formData.append("user_role", providerInfo?.user_role);

    updateProfile(formData)
      .then((response: any) => {
        // setUserId(response?.data?.data[0]?.user_id)
        setIsSaveLoading(false);
        setShowSaveButton(false);

        if (response?.error?.data?.message) {
          notifyError(response?.error?.data?.message);
        }
        notifySuccess(response?.data?.message);

        // setProviderDetails(response?.data)
      })
      .catch((err) => {
        setIsSaveLoading(false);
        notifyError("Error Occured");
      });
  };

  const handleUpdateOwnerProfile = () => {
    setIsSaveLoading(true);

    let formData: any = new FormData();
    formData.append("id", ownerInfo?.id);
    formData.append("address", ownerInfo?.address);
    formData.append("firstname", ownerInfo?.firstname);
    formData.append("lastname", ownerInfo?.lastname);
    formData.append("email", ownerInfo?.email);
    formData.append("gender", ownerInfo?.gender);
    formData.append("dob", ownerInfo?.dob);
    formData.append("identification", ownerInfo?.identification);
    formData.append("id_number", ownerInfo?.id_number);
    formData.append("phone_number", ownerInfo?.phone_number);
    formData.append("id_image", ownerInfo?.id_image);

    updateOwnerProfile(formData)
      .then((response: any) => {
        // setUserId(response?.data?.data[0]?.user_id)
        setIsSaveLoading(false);
        setShowSaveButton(false);

        if (response?.error?.data?.message) {
          notifyError(response?.error?.data?.message);
        }
        notifySuccess(response?.data?.message);

        // setProviderDetails(response?.data)
      })
      .catch((err) => {
        setIsSaveLoading(false);
        notifyError("Error Occured");
      });
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const [autocomplete, setAutocomplete] = useState<any>(null);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setOwnerInfo({
          ...ownerInfo,
          address: places,
        });
        // dispatch(setVehicleDetailss({ ...vehicleDetails, vehicle_location: places }));
      } else {
        setOwnerInfo({
          ...ownerInfo,
          address: autocomplete.getPlace().name,
        });
      }
    }
  };

  console.log(carOwnerInfo);
  const [
    carOwnerProfile,
    {
      data: carOwnerData,
      isLoading: loadingCarOwnerProfile,
      isSuccess: successCarOwner,
      isError: isErrorCarOwner,
      error: errorCarOwner,
    },
  ] = useCarOwnerProfileMutation();

  const handleUpdateCarOwnerProfile = () => {
    let formData: any = new FormData();
    formData.append("vehicle_id", vehicleId);
    formData.append("address", carOwnerInfo?.address);

    formData.append("firstname", carOwnerInfo?.firstname);
    formData.append("lastname", carOwnerInfo?.lastname);
    formData.append("email", carOwnerInfo?.email);

    formData.append("phone_number", carOwnerInfo?.phone_number);
    formData.append("category", "business");
    formData.append("type", "different");

    carOwnerProfile(formData)
      .then((response: any) => {
        console.log(response, "response");
      })
      .catch((err) => {});
  };

  return (
    <>
      <ToastContainer />
      {providerInfo?.category?.toLowerCase() !== "business" ? (
        <>
          <NameDIV>
            <FLEXDIV style={{ marginTop: "10px" }}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <P>
                  Firstname <Important>*</Important>
                </P>
                <INPUTS
                  type="text"
                  style={{ padding: "17px", border: "none", outline: "none" }}
                  value={providerInfo?.firstname}
                  onChange={handleFirstName}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <P>
                  Lastname <Important>*</Important>
                </P>
                <INPUTS
                  type="text"
                  style={{ padding: "17px", border: "none", outline: "none" }}
                  value={providerInfo?.lastname}
                  onChange={handleLastName}
                />
              </div>
            </FLEXDIV>

            <DIV
              style={{
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <P>
                Phone <Important>*</Important>
              </P>

              <Input className="phone">
                <PhoneInput
                  defaultCountry="NG"
                  defaultValue={providerInfo?.phone_number}
                  onChange={handlePhone}
                  international
                  withCountryCallingCode
                  value={providerInfo?.phone_number}
                />
              </Input>
            </DIV>

            <div
              style={{
                marginTop: -30,
                display: "flex",
                flexDirection: "column",
                gap: 15,
              }}
            >
              <P>
                Email Address <Important>*</Important>
              </P>

              <FLEXDIV2>
                <ICONDIV>
                  <BsEnvelopeFill />
                </ICONDIV>
                <Input>
                  <InputField
                    type="text"
                    value={providerInfo?.email}
                    onChange={handleEmail}
                  />
                </Input>
              </FLEXDIV2>
            </div>
          </NameDIV>

          <DIV>
            <COLDIV>
              <P>
                Gender <Important>*</Important>
              </P>
              <SELECTDIV>
                <Text>
                  {providerInfo?.gender ? providerInfo?.gender : "Select"}
                </Text>
                <DropDown onClick={openGenderModal}>
                  <IoIosArrowDown
                    style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
                  />
                </DropDown>
              </SELECTDIV>
              <div>
                {genderModal && (
                  <Div style={{ width: "300px" }}>
                    <Modal>
                      <Option onClick={selectMale}>
                        <Radio
                          style={
                            providerInfo?.gender === "Male"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> Male</Text>
                      </Option>
                      <Option onClick={selectFemale}>
                        <Radio
                          style={
                            providerInfo?.gender === "Female"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> Female</Text>
                      </Option>
                      <Option onClick={selectOthers}>
                        <Radio
                          style={
                            providerInfo?.gender === "Others"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> Others</Text>
                      </Option>
                    </Modal>
                  </Div>
                )}
              </div>
            </COLDIV>
            <COLDIV>
              <P>
                Date of Birth <Important>*</Important>
              </P>
              <Flex>
                <INPUTS
                  type="text"
                  readOnly
                  style={{
                    fontSize: "13px",
                    padding: "19px 0px",
                    border: "none",
                    outline: "none",
                  }}
                  placeholder={moment(formattedDate || new Date()).format(
                    "DD/MM/YYYY"
                  )}
                  value={moment(formattedDate || new Date()).format(
                    "DD/MM/YYYY"
                  )}
                />

                <SelectDate>
                  <BiCalendar style={{ color: "#fff", position: "absolute" }} />
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        value={DOB}
                        disableMaskedInput={true}
                        maxDate={eighteenYearsAgo}
                        showToolbar={false}
                        onChange={(date: any) => {
                          setShowSaveButton(true);
                          // @ts-ignore
                          setProviderInfo((prevState: any) => ({
                            ...prevState,
                            // @ts-ignore
                            dob: moment(
                              JSON.stringify(new Date(date).toString())
                            ).format("DD/MM/YYYY"),
                          }));

                          // setDOB(JSON.stringify(new Date(date).toString()))
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onClick={(e: any) => setOpen(true)}
                            onKeyDown={onKeyDown}
                            {...params}
                          />
                        )}
                        // @ts-ignore
                        sx={{ background: "transparent" }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </SelectDate>
              </Flex>
            </COLDIV>
          </DIV>
          <DIV>
            <COLDIV>
              <P>
                Means of ID <Important>*</Important>
              </P>
              <SELECTDIV>
                <Label>
                  {providerInfo?.identification
                    ? providerInfo?.identification
                    : "Select"}
                </Label>

                <DropDown onClick={openModalIDModal}>
                  <IoIosArrowDown
                    style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
                  />
                </DropDown>
              </SELECTDIV>

              <div>
                {idModal && (
                  <Div>
                    <Modal style={{ width: "70%" }}>
                      <Option onClick={selectInternationalPassport}>
                        <Radio
                          style={
                            providerInfo?.identification ===
                            "International Passport"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>International Passport</Text>
                      </Option>
                      <Option onClick={selectLicense}>
                        <Radio
                          style={
                            providerInfo?.identification === "Drivers License"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>Drivers License</Text>
                      </Option>
                      <Option onClick={selectNIN}>
                        <Radio
                          style={
                            providerInfo?.identification === "NIN"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> NIN</Text>
                      </Option>
                    </Modal>
                  </Div>
                )}
              </div>
            </COLDIV>
            <COLDIV>
              <P>
                ID Number <Important>*</Important>
              </P>
              <INPUTS
                type="text"
                //@ts-ignore
                value={providerInfo?.id_number}
                onChange={handleIDNumber}
                style={{
                  fontWeight: "600",
                  padding: "17px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="ID Number"
              />
            </COLDIV>
          </DIV>

          <IDDIV
            style={{
              background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${image1Prev})`,
              objectFit: "cover",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <img
              src={image1Prev}
              alt={image1Prev}
              width="100%"
              style={{ objectFit: "cover" }}
            />
            <FILEDIV
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                if (fileInputRef.current != null) {
                  fileInputRef.current.click();
                }
              }}
            >
              <img src={snap} alt="" width="30px" />
            </FILEDIV>
          </IDDIV>

          <DIV style={{ marginTop: 25 }}>
            <COLDIV>
              <P>
                Country <Important>*</Important>
              </P>

              <div className="country-select">
                <RegionDiv>
                  <CountryDropdown
                    value={providerInfo?.country}
                    //@ts-ignore
                    onChange={handleCountry}
                  />
                </RegionDiv>
              </div>
            </COLDIV>

            <COLDIV>
              <P>
                State <Important>*</Important>
              </P>
              <div className="country-select">
                <RegionDiv>
                  <RegionDropdown
                    country={providerInfo?.country}
                    value={providerInfo?.state}
                    //@ts-ignore
                    onChange={handleState}
                  />
                </RegionDiv>
              </div>
            </COLDIV>
          </DIV>

          {showSaveButton && (
            <SubmitDiv>
              {isSaveLoading ? (
                <SubmitButton style={{ backgroundColor: "#001aff" }}>
                  Updating Profile...
                </SubmitButton>
              ) : (
                <SubmitButton
                  style={{ backgroundColor: "#001aff" }}
                  onClick={() => handleUpdateProfile()}
                >
                  Save and Update Profile
                </SubmitButton>
              )}
            </SubmitDiv>
          )}
        </>
      ) : carOwnerInfo ? (
        <>
          <DIV style={{ marginTop: 10 }}>
            <COLDIV>
              <P>
                Firstname<Important>*</Important>
              </P>
              <INPUTS
                type="text"
                value={ownerInfo?.firstname}
                onChange={(e: any) => {
                  setOwnerInfo({
                    ...ownerInfo,
                    firstname: e.target.value,
                  });
                  setShowSaveButton(true);
                }}
                style={{
                  fontWeight: "600",
                  padding: "17px",
                  border: "none",
                  outline: "none",
                }}
              />
            </COLDIV>

            <COLDIV>
              <P>
                Lastname <Important>*</Important>
              </P>
              <INPUTS
                type="text"
                value={ownerInfo?.lastname}
                onChange={(e: any) => {
                  setOwnerInfo({
                    ...ownerInfo,
                    lastname: e.target.value,
                  });
                  setShowSaveButton(true);
                }}
                style={{
                  fontWeight: "600",
                  padding: "17px",
                  border: "none",
                  outline: "none",
                }}
              />
            </COLDIV>
          </DIV>

          <div
            style={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              gap: 15,
            }}
          >
            <P>
              Email Address <Important>*</Important>
            </P>

            <FLEXDIV2>
              <ICONDIV style={{ width: "28%" }}>
                <BsEnvelopeFill />
              </ICONDIV>
              <Input>
                <InputField
                  type="text"
                  value={ownerInfo?.email}
                  onChange={(e: any) => {
                    setOwnerInfo({
                      ...ownerInfo,
                      email: e.target.value,
                    });
                    setShowSaveButton(true);
                  }}
                />
              </Input>
            </FLEXDIV2>
          </div>

          <div
            style={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              gap: 15,
            }}
          >
            <P>
              Phone Number <Important>*</Important>
            </P>

            <Input style={{ marginTop: -10 }}>
              <PhoneInput
                defaultCountry="NG"
                value={ownerInfo?.phone_number}
                onChange={(e: any) => {
                  setOwnerInfo({
                    ...ownerInfo,
                    phone_number: e,
                  });
                  setShowSaveButton(true);
                }}
                international
                withCountryCallingCode
              />
            </Input>
          </div>

          <DIV style={{marginTop:20}}>
            <COLDIV>
              <P>
                Gender <Important>*</Important>
              </P>
              <SELECTDIV>
                <Text>{ownerInfo?.gender ? ownerInfo?.gender : "Select"}</Text>
                <DropDown onClick={openGenderModal}>
                  <IoIosArrowDown
                    style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
                  />
                </DropDown>
              </SELECTDIV>
              <div>
                {genderModal && (
                  <Div style={{ width: "300px" }}>
                    <Modal>
                      <Option onClick={selectMaleBusiness}>
                        <Radio
                          style={
                            ownerInfo?.gender === "Male"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> Male</Text>
                      </Option>
                      <Option onClick={selectFemaleBusiness}>
                        <Radio
                          style={
                            ownerInfo?.gender === "Female"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> Female</Text>
                      </Option>
                      <Option onClick={selectOthersBusiness}>
                        <Radio
                          style={
                            ownerInfo?.gender === "Others"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> Others</Text>
                      </Option>
                    </Modal>
                  </Div>
                )}
              </div>
            </COLDIV>
            <COLDIV>
              <P>
                Date of Birth <Important>*</Important>
              </P>
              <Flex>
                <INPUTS
                  type="text"
                  readOnly
                  style={{
                    fontSize: "13px",
                    padding: "19px 0px",
                    border: "none",
                    outline: "none",
                  }}
                  placeholder={moment(formattedDateBusiness || new Date()).format(
                    "DD/MM/YYYY"
                  )}
                  value={moment(formattedDateBusiness || new Date()).format(
                    "DD/MM/YYYY"
                  )}
                />

                <SelectDate>
                  <BiCalendar style={{ color: "#fff", position: "absolute" }} />
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        value={DOB}
                        disableMaskedInput={true}
                        maxDate={eighteenYearsAgo}
                        showToolbar={false}
                        onChange={(date: any) => {
                          setShowSaveButton(true);
                          // @ts-ignore
                          setOwnerInfo((prevState: any) => ({
                            ...prevState,
                            // @ts-ignore
                            dob: moment(
                              JSON.stringify(new Date(date).toString())
                            ).format("DD/MM/YYYY"),
                          }));

                          // setDOB(JSON.stringify(new Date(date).toString()))
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onClick={(e: any) => setOpen(true)}
                            onKeyDown={onKeyDown}
                            {...params}
                          />
                        )}
                        // @ts-ignore
                        sx={{ background: "transparent" }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </SelectDate>
              </Flex>
            </COLDIV>
          </DIV>
          <DIV>
            <COLDIV>
              <P>
                Means of ID <Important>*</Important>
              </P>
              <SELECTDIV>
                <Label>
                  {ownerInfo?.identification
                    ? ownerInfo?.identification
                    : "Select"}
                </Label>

                <DropDown onClick={openModalIDModal}>
                  <IoIosArrowDown
                    style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
                  />
                </DropDown>
              </SELECTDIV>

              <div>
                {idModal && (
                  <Div>
                    <Modal style={{ width: "70%" }}>
                      <Option onClick={selectInternationalPassportBusiness}>
                        <Radio
                          style={
                            ownerInfo?.identification ===
                            "International Passport"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>International Passport</Text>
                      </Option>
                      <Option onClick={selectLicenseBusiness}>
                        <Radio
                          style={
                            ownerInfo?.identification === "Drivers License"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text>Drivers License</Text>
                      </Option>
                      <Option onClick={selectNINBusiness}>
                        <Radio
                          style={
                            ownerInfo?.identification === "NIN"
                              ? { background: "#ffa100" }
                              : { background: "#fff" }
                          }
                        ></Radio>
                        <Text> NIN</Text>
                      </Option>
                    </Modal>
                  </Div>
                )}
              </div>
            </COLDIV>
            <COLDIV>
              <P>
                ID Number <Important>*</Important>
              </P>
              <INPUTS
                type="text"
                //@ts-ignore
                value={ownerInfo?.id_number}
                onChange={handleIDNumberBusiness}
                style={{
                  fontWeight: "600",
                  padding: "17px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="ID Number"
              />
            </COLDIV>
          </DIV>

          <IDDIV
            style={{
              background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${image1Prev})`,
              objectFit: "cover",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <img
              src={imageBusinessPrev}
              alt={imageBusinessPrev}
              width="100%"
              style={{ objectFit: "cover" }}
            />
            <FILEDIV
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                if (fileInputRefBusiness.current != null) {
                  fileInputRefBusiness.current.click();
                }
              }}
            >
              <img src={snap} alt="" width="30px" />
            </FILEDIV>
          </IDDIV>

          <P style={{ marginTop: 35 }}>
            Address <Important>*</Important>
          </P>
          <DIV style={{ marginTop: 20 }}>
            <FLEXDIV2>
              <ICONDIV style={{ width: "28%" }}>
                <FaMapMarkerAlt style={{ color: "#fff" }} />
              </ICONDIV>
              <Input>
                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={(value) => setAutocomplete(value)}
                >
                  <InputField
                    onChange={(e: any) => {
                      setOwnerInfo({
                        ...ownerInfo,
                        address: e.target.value,
                      });
                      setShowSaveButton(true);
                    }}
                    value={ownerInfo?.address}
                    type="text"
                  />
                </Autocomplete>
              </Input>
            </FLEXDIV2>
          </DIV>

          {showSaveButton && (
            <SubmitDiv>
              {isSaveLoading ? (
                <SubmitButton style={{ backgroundColor: "#001aff" }}>
                  Updating Profile...
                </SubmitButton>
              ) : (
                <SubmitButton
                  style={{ backgroundColor: "#001aff" }}
                  onClick={() => handleUpdateOwnerProfile()}
                >
                  Save and Update Profile
                </SubmitButton>
              )}
            </SubmitDiv>
          )}
        </>
      ) : (
        <>
          <DIV style={{ marginTop: 10 }}>
            <COLDIV>
              <P>
                Firstname<Important>*</Important>
              </P>
              <INPUTS
                type="text"
                value={carOwnerInfo?.firstname}
                onChange={(e: any) => {
                  setCarOwnerInfo({
                    ...carOwnerInfo,
                    firstname: e.target.value,
                  });
                  setShowSaveButton(true);
                }}
                style={{
                  fontWeight: "600",
                  padding: "17px",
                  border: "none",
                  outline: "none",
                }}
              />
            </COLDIV>

            <COLDIV>
              <P>
                Lastname <Important>*</Important>
              </P>
              <INPUTS
                type="text"
                value={carOwnerInfo?.lastname}
                onChange={(e: any) => {
                  setCarOwnerInfo({
                    ...carOwnerInfo,
                    lastname: e.target.value,
                  });
                  setShowSaveButton(true);
                }}
                style={{
                  fontWeight: "600",
                  padding: "17px",
                  border: "none",
                  outline: "none",
                }}
              />
            </COLDIV>
          </DIV>

          <div
            style={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              gap: 15,
            }}
          >
            <P>
              Email Address <Important>*</Important>
            </P>

            <FLEXDIV2>
              <ICONDIV style={{ width: "28%" }}>
                <BsEnvelopeFill />
              </ICONDIV>
              <Input>
                <InputField
                  type="text"
                  value={carOwnerInfo?.email}
                  onChange={(e: any) => {
                    setCarOwnerInfo({
                      ...carOwnerInfo,
                      email: e.target.value,
                    });
                    setShowSaveButton(true);
                  }}
                />
              </Input>
            </FLEXDIV2>
          </div>

          <div
            style={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              gap: 15,
            }}
          >
            <P>
              Phone Number <Important>*</Important>
            </P>

            <Input style={{ marginTop: -10 }}>
              <PhoneInput
                defaultCountry="NG"
                value={carOwnerInfo?.phone_number}
                onChange={(e: any) => {
                  setCarOwnerInfo({
                    ...carOwnerInfo,
                    phone_number: e,
                  });
                  setShowSaveButton(true);
                }}
                international
                withCountryCallingCode
              />
            </Input>
          </div>

          <P style={{ marginTop: 35 }}>
            Address <Important>*</Important>
          </P>
          <DIV style={{ marginTop: 20 }}>
            <FLEXDIV2>
              <ICONDIV style={{ width: "28%" }}>
                <FaMapMarkerAlt style={{ color: "#fff" }} />
              </ICONDIV>
              <Input>
                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={(value) => setAutocomplete(value)}
                >
                  <InputField
                    onChange={(e: any) => {
                      setCarOwnerInfo({
                        ...carOwnerInfo,
                        address: e.target.value,
                      });
                      setShowSaveButton(true);
                    }}
                    value={carOwnerInfo?.address}
                    type="text"
                  />
                </Autocomplete>
              </Input>
            </FLEXDIV2>
          </DIV>

          {showSaveButton && (
            <SubmitDiv>
              {isSaveLoading ? (
                <SubmitButton style={{ backgroundColor: "#001aff" }}>
                  Updating Profile...
                </SubmitButton>
              ) : (
                <SubmitButton
                  style={{ backgroundColor: "#001aff" }}
                  onClick={() => handleUpdateCarOwnerProfile()}
                >
                  Update Owner Info
                </SubmitButton>
              )}
            </SubmitDiv>
          )}
        </>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChange}
      />
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRefBusiness}
        accept="image/*"
        onChange={onChangeBusiness}
      />
    </>
  );
};

export default IndividualProfile;
