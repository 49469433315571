import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  searchCurrentPage: 1,
  driverCurrentPage: 1,
  providerCurrentPage: 1,
  businessCurrentPage: 1,
  allVehicle: null,
  allDriver: null,
  allProvider: null,
  allBusiness: null,
  allCustomer: null,
  allBooking: null,
  filterPagination: false,
  filterPaginationData: false,
  searchData: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setVehicles: (state, action) => {
      return { ...state, allVehicle: action.payload };
    },
    setPageNumber: (state, action) => {
      return { ...state, currentPage: action.payload };
    },
    setSearchPageNumber: (state, action) => {
      return { ...state, searchCurrentPage: action.payload };
    },
    setDriverPageNumber: (state, action) => {
      return { ...state, driverCurrentPage: action.payload };
    },
    setProviderPageNumber: (state, action) => {
      return { ...state, driverCurrentPage: action.payload };
    },
    setBusinessPageNumber: (state, action) => {
        return { ...state, driverCurrentPage: action.payload };
      },

    setFilterPagination: (state, action) => {
      return { ...state, filterPagination: action.payload };
    },
    setFilterPaginationData: (state, action) => {
      return { ...state, filterPaginationData: action.payload };
    },
    setSearchData: (state, action) => {
      return { ...state, searchData: action.payload };
    },


    setRequest: (state, action) => {
      return { ...state, allRequest: action.payload };
    },

    setProviders: (state, action) => {
      return { ...state, allProvider: action.payload };
    },
    setBusiness: (state, action) => {
        return { ...state, allBusiness: action.payload };
      },

    setRegisteredVehicle: (state, action) => {
      return { ...state, vehicle: action.payload };
    },

    setAllVehicle: (state, action) => {
      return { ...state, allVehicle: action.payload };
      // state.allVehicle = action.payload;
    },
    setAllCustomer: (state, action) => {
      return { ...state, allCustomer: action.payload };
      // state.allVehicle = action.payload;
    },
    setProfile: (state, action) => {
      return { ...state, profile: action.payload };
      // state.allVehicle = action.payload;
    },

    setAllBike: (state, action) => {
      return { ...state, allBike: action.payload };
    },
    setAllDriver: (state, action) => {
      return { ...state, allDriver: action.payload };
    },
    setAllBooking: (state, action) => {
      return { ...state, allBooking: action.payload };
    },

    setLogout: (state, action) => {
      return { ...state, otp_number: null };
    },
  },
});

const { reducer, actions } = adminSlice;
export const {
  setVehicles,
  // setDrivers,
  setAllCustomer,
  setFilterPagination,
  setFilterPaginationData,
  setProviders,
  setRegisteredVehicle,
  setLogout,
  setAllVehicle,
  setProfile,
  setAllBike,
  setAllDriver,
  setAllBooking,
  setRequest,
  setPageNumber,
  setDriverPageNumber,
  setProviderPageNumber,
  setBusinessPageNumber,
  setBusiness,
  setSearchData,
  setSearchPageNumber
} = actions;
export default reducer;
