import React, { useEffect, useState } from "react";
import Cart from "../../../components/cart";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { SearchOverall, SearchInput } from "../CarBooking/CarBooking.styles";
import {
  AssetOverall,
  BoxExtras,
  H11,
  SearchBtn,
} from "../Homepage/homepage.styles";
import {
  CallBox,
  CartBoxWrap,
  CartOverallWrap,
  CartPageFooterWrap,
  CheckoutBtn,
  CouponWrap,
  PaddingWrap,
  TotalPriceWrap,
} from "./cartPage.styles";
import { LocationInputWrap } from "../../../components/locationModal/locationmodal.styles";
import SearchComponent from "../../../components/searchComponent";
import OffersCard from "../../../components/offersCard";
import { NavLink } from "react-router-dom";
import {
  AppDispatch,
  RootState,
  useAppSelector,
  useAppDispatch,
} from "../../../store";
import { createSearchParams, useNavigate } from "react-router-dom";
import AssetCard from "../../../components/assetCard";
import { clearCartItem, deleteFromCart } from "../../../slices/cart";
import DeliveryCart from "../../../components/deliveryCart";
import CheckoutComponent from "../../../components/checkoutComponent";
import DriverCart from "../../../components/driverCart";
import SearchUpdateComponent from "../../../components/searchUpdateComponent";
import {
  useAddBookingMutation,
  useCreateVirtualWalletMutation,
  useGetWalletByIdQuery,
  usePayWithStripeMutation,
  usePayWithWalletMutation,
  useTopUpWalletMutation,
} from "../../../services/walletApi";
import CreateWalletCard from "../../../components/createWalletCard";

import phone from "../../../assets/images/svg/call-icon.svg";
import tick from "../../../assets/images/svg/white-tickr.svg";
import axios from "axios";
import { baseUrl } from "../../../services/providerApi";

const CartPage = () => {
  localStorage.setItem("roadlersLastVisited", "/cart");
  const codeCurrency = useAppSelector((state: any) => state?.cart?.cart[0]);

  const { cart } = useAppSelector<any>((store: RootState) => store?.cart);
  const { favourites } = useAppSelector<any>(
    (store: RootState) => store?.favourites
  );
  const { token = "", insurance_check = "" } =
    useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};

  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [deliveryCartId, setDeliveryCartId] = useState("");
  const [payWithCardError, setPayWithCardError] = useState("");
  const [timedAddBookingError, setTimedAddBookingError] = useState("");
  const [addBookingError, setAddBookingError] = useState("");
  const [walletDetails, setWalletDetails] = useState<any>([]);
  const [bookingDetails, setBookingDetails] = useState<any>([]);
  const [payWithCardResult, setPayWithCardResult] = useState<any>([]);
  const [createWalletModal, setCreateWalletModal] = useState(false);
  const [bvn, setBvn] = useState<string>("");
  const [bvnError, setBvnError] = useState("");
  const [bookingOrigin, setBookingOrigin] = useState("");
  const [checkoutSubComponent, setCheckoutSubComponent] = useState("a");
  const [topupAmount, setTopupAmount] = useState<any>("");
  const [topupErrorMessage, setTopupErrorMessage] = useState("");
  const [insuranceResult, setInsuranceResult] = useState<boolean>();
  const [createBvnShow, setCreateBvnShow] = useState(false);

  const {
    id = "",
    firstname = "",
    lastname = "",
  } = useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};

  const {
    data: walletData,
    refetch: refetchWallet,
    isSuccess: walletSuccess,
  } = useGetWalletByIdQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [addBooking, { data, isLoading, isSuccess, isError, error }] =
    useAddBookingMutation();

  const [
    payWithWallet,
    {
      data: walletPayData,
      isLoading: walletPayIsLoading,
      isSuccess: walletPayIsSuccess,
      isError: walletPayIsError,
      error: walletPayError,
    },
  ] = usePayWithWalletMutation();

  const [
    payWithCard,
    {
      data: cardPayData,
      isLoading: cardPayIsLoading,
      isSuccess: cardPayIsSuccess,
      isError: cardPayIsError,
      error: cardPayError,
    },
  ] = useTopUpWalletMutation();

  const [
    payWithStripe,
    {
      data: stripePayData,
      isLoading: stripePayIsLoading,
      isSuccess: stripePayIsSuccess,
      isError: stripePayIsError,
      error: stripePayError,
    },
  ] = usePayWithStripeMutation();

  const [
    topUpWallet,
    {
      data: topUpData,
      isLoading: isTopUpLoading,
      isSuccess: isTopUpSuccess,
      isError: isTopUpError,
      error: topUpError,
    },
  ] = useTopUpWalletMutation();

  const [
    createVirtualWallet,
    {
      data: virtualWalletData,
      isLoading: virtualWalletLoading,
      isSuccess: virtualWalletSuccess,
      isError: virtualWalletisError,
      error: virtualWalletError,
    },
  ] = useCreateVirtualWalletMutation();

  useEffect(() => {
    if (cart) {
      // Function to check if any object in the array has a value, here we are looking at insurance
      function checkValue(arr: any, property: any) {
        for (let i = 0; i < arr?.length; i++) {
          if (arr[i][property]?.length > 0) {
            return true; // Found a matching object
          }
        }
        return false; // No matching object found
      }

      const hasValue = checkValue(cart, "insurance");
      setInsuranceResult(hasValue);
    }
  }, [cart]);

  // USEEFFECT FOR FETCHING WALLET DETAILS
  useEffect(() => {
    refetchWallet();
  }, [walletData, virtualWalletData]);

  useEffect(() => {
    if (walletSuccess) {
      setWalletDetails(walletData?.data);
    }
  }, [walletData, virtualWalletData]);

  // USEFFECT FOR CREATE VIRTUAL WALLET
  useEffect(() => {
    if (virtualWalletSuccess) {
      setCreateWalletModal(false);
      setCreateBvnShow(false);
    }
    if (virtualWalletisError && "status" in virtualWalletError!) {
      setBvnError(virtualWalletError.data.message);

      setTimeout(() => {
        setBvnError("");
      }, 3000);
    }
  }, [
    virtualWalletisError,
    virtualWalletSuccess,
    virtualWalletError,
    virtualWalletData,
  ]);

  // USEFFECT FOR WALLET TOPUP
  useEffect(() => {
    if (isTopUpSuccess) {
      window.open(topUpData?.data?.authorization_url);
      setTopupAmount("");
      setShowCheckoutModal(false);
      setCheckoutSubComponent("a");
    }

    if (isTopUpError && "status" in topUpError!) {
      setTopupErrorMessage(topUpError.data.message);

      setTimeout(() => {
        setTopupErrorMessage("");
      }, 3000);
    }
  }, [topUpData, isTopUpLoading, isTopUpError, isTopUpSuccess, topUpError]);

  // USEEFFECT FOR ADDBOOKING
  useEffect(() => {
    if (isLoading) {
    }
    if (isSuccess) {
      setBookingDetails(data?.data);
      setAddBookingError("");
      if (bookingOrigin === "wallet") {
        handlePayWithWallet();
      } else {
        handlePayWithCard();
      }
    }
    if (isError && "status" in error!) {
      setTimedAddBookingError(error.data.message);
      setAddBookingError(error.data.message);

      setTimeout(() => {
        setTimedAddBookingError("");
      }, 5000);
    }
  }, [isLoading, isSuccess, isError, data]);

  // USEEFFECT FOR PAYING WITH CARD
  useEffect(() => {
    if (cardPayIsSuccess) {
      setPayWithCardResult(cardPayData?.data);
      // dispatch(clearCartItem());
      setShowCheckoutModal(false);
      setCheckoutSubComponent("a");
      window.open(cardPayData?.data?.authorization_url, "_self");
    }
    if (cardPayIsError && "status" in cardPayError!) {
      setShowCheckoutModal(false);
      setTimedAddBookingError(cardPayError.data.message);
      setCheckoutSubComponent("a");
      navigate("/transaction-failed");

      setTimeout(() => {
        setTimedAddBookingError("");
      }, 5000);
    }
  }, [cardPayIsSuccess, cardPayIsError, cardPayData, cardPayIsLoading]);
  // stripePayData
  // USEFFECT FOR PAYING WITH WALLET
  useEffect(() => {
    if (walletPayIsSuccess) {
      setShowCheckoutModal(false);
      setCheckoutSubComponent("a");
      if (insuranceResult) {
        if (insurance_check === "0") {
          navigate("/transaction-insurance-success");
          dispatch(clearCartItem());
        } else {
          navigate("/transaction-success");
          dispatch(clearCartItem());
        }
      } else {
        navigate("/transaction-success");
        dispatch(clearCartItem());
      }
    }

    if (walletPayIsError && "status" in walletPayError!) {
      setShowCheckoutModal(false);
      setCheckoutSubComponent("a");
      navigate("/transaction-failed");
    }
  }, [
    walletPayData,
    walletPayError,
    walletPayIsError,
    walletPayIsLoading,
    walletPayIsSuccess,
  ]);

  // DELETING AN ITEM FROM THE CART
  const handleDel = (id: any) => {
    dispatch(deleteFromCart(id));
  };

  // FUNCTION TO REDIRECT TO EDIT PAGE FOR DELIVERIES
  const handleEditDeliveryCart = (id: any) => {
    const params = { deliveryId: id };

    navigate({
      pathname: "/edit-delivery-booking",
      search: `?${createSearchParams(params).toString()}`,
    });
  };

  // FUNCTION TO REDIRECT TO EDIT PAGE FOR CARS
  const handleEditCarBooking = (id: any) => {
    const params = { bookingId: id };

    navigate({
      pathname: "/edit-car-booking",
      search: `?${createSearchParams(params).toString()}`,
    });
  };

  // FUNCTION TO REDIRECT TO EDIT PAGE FOR CARS
  const handleEditDriverBooking = (id: any) => {
    const params = { bookingId: id };

    navigate({
      pathname: "/edit-driver-booking",
      search: `?${createSearchParams(params).toString()}`,
    });
  };

  // USEFFECT CONTAINING FUNCTION TO CALCULATE TOTAL PRICE OF CART CONTENTS
  useEffect(() => {
    let sumTotal = 0;
    cart?.forEach((item: any) => {
      sumTotal += item?.amount;
    });

    setTotal(sumTotal);
  }, [cart]);

  // SHOW CHECKOUT MODAL
  const handleCheckout = (value: any) => {
    if (total) {
      if (token) {
        handleAddBooking("card");
        // setShowCheckoutModal(value);
        // }
      } else {
        navigate("/login");
      }
    }
  };

  // ADD BOOKING FUNCTION
  const handleAddBooking = (from: any) => {
    addBooking(cart);
    setBookingOrigin(from);
    const hasTravelGarage = cart?.some(
      (booking) => booking?.category === "Travel Garage Booking"
    );

    const travelIds = cart?.map((booking) => booking?.travel_id);
    const travelSeats = cart?.map((booking) => booking?.num_of_seats);

    let formDatas = new FormData();
    formDatas.append("id", travelIds);
    formDatas.append("num_picked", travelSeats);

    if (hasTravelGarage) {
      axios({
        method: "post",
        url: `${baseUrl}count-passenger-seat`,
        data: formDatas,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(function (response) {
          const message = response.data.data;
          console.log(response, "reee");
        })
        .catch(function (error) {
          //handle error
        });
    }
  };

  // HANDLE CREATE VIRTUAL WALLET
  const onCreateVirtualWallet = () => {
    let formData: any = new FormData();

    if (bvn?.length === 11) {
      formData.append("firstname", firstname);
      formData.append("first_name", firstname);
      formData.append("lastname", lastname);
      formData.append("last_name", lastname);
      formData.append("bvn", bvn);

      createVirtualWallet(formData);
    } else if (!bvn) {
      setBvnError("Please type in your bvn");

      setTimeout(() => {
        setBvnError("");
      }, 3000);
    } else {
      setBvnError("Please type in correct bvn");

      setTimeout(() => {
        setBvnError("");
      }, 3000);
    }
  };

  // TOPUP WALLET FUNCTION
  const onWalletTopup = () => {
    let formData: any = new FormData();

    if (!topupAmount) {
      setTopupErrorMessage("Please type an amount");

      setTimeout(() => {
        setTopupErrorMessage("");
      }, 4000);
    } else {
      formData.append("currency", "NGN");
      formData.append("amount", topupAmount);

      topUpWallet(formData);
    }
  };

  const STRIPE_PERCENT = 2.9 / 100;
  // PAY WITH PAYSTACK OR STRIPE FUNCTION
  const handlePayWithCard = () => {
    if (codeCurrency?.currency_code !== "NGN") {
      const datas = {
        currency: codeCurrency?.currency_code,
        amount:
          data?.data?.total_amount +
            (STRIPE_PERCENT * data?.data?.total_amount) +
          0.3,
        transactionReference: data?.data?.billing_reference,
      };
      //@ts-ignore
      payWithStripe([datas]).then(async (response: any) => {
        window.open(response?.data?.data?.paymentIntent?.url, "_self");
      });
    } else {
      let formData: any = new FormData();
      formData.append("currency", "NGN");
      formData.append("amount", data?.data?.total_amount);
      formData.append("billingReference", data?.data?.billing_reference);
      payWithCard(formData);
    }
  };

  // PAY WITH WALLET FUNCTION
  const handlePayWithWallet = () => {
    let formData: any = new FormData();

    formData.append("currency", walletDetails?.currency_code);
    formData.append("amount", data?.data?.total_amount);
    formData.append("billingReference", data?.data?.billing_reference);

    payWithWallet(formData);
  };
  return (
    <CartOverallWrap>
      <CheckoutComponent
        showCheckout={showCheckoutModal}
        handleCheckout={handleCheckout}
        amountTotal={total}
        handlePayWithCard={handlePayWithCard}
        payWithCardError={payWithCardError}
        timedAddBookingError={timedAddBookingError}
        handleAddBooking={handleAddBooking}
        addBookingError={addBookingError}
        currency={walletDetails?.currency_code}
        walletTotal={walletDetails?.balance}
        handlePayWithWallet={handlePayWithWallet}
        subComponent={checkoutSubComponent}
        setSubComponent={setCheckoutSubComponent}
        onWalletTopup={onWalletTopup}
        topupAmount={topupAmount}
        setTopupAmount={setTopupAmount}
        accountNumber={walletDetails?.virtual_account_number}
        bankName={walletDetails?.bank_name}
        topupErrorMessage={topupErrorMessage}
        createBvnShow={createBvnShow}
        setCreateBvnShow={setCreateBvnShow}
        setCreateWalletModal={setCreateWalletModal}
        creatWalletActive={createWalletModal}
        setBvn={setBvn}
        bvn={bvn}
        handleCreateVirtualWallet={onCreateVirtualWallet}
        isLoading={virtualWalletLoading}
        bvnError={bvnError}
        walletPayIsLoading={walletPayIsLoading}
        cardPayIsLoading={cardPayIsLoading}
      />

      {/* <CreateWalletCard setCreateWalletModal={setCreateWalletModal} creatWalletActive={createWalletModal} setBvn={setBvn} bvn={bvn} handleCreateVirtualWallet={onCreateVirtualWallet}  /> */}
      <Header />

      {/* <SearchComponent /> */}
      <PaddingWrap>
        <SearchUpdateComponent />
      </PaddingWrap>

      <CartBoxWrap>
        {cart
          ?.slice(0)
          ?.reverse()
          ?.map((item: any) => {
            if (item?.booking_title?.includes("Delivery")) {
              return (
                <DeliveryCart
                  key={item?.booking_id}
                  booking_title={item?.booking_title}
                  deliveryArray={item?.delivery}
                  pickup_date={item?.pickup_date}
                  pickup_time={item?.pickup_time}
                  item_size={item?.item_size}
                  no_of_escort={item?.escort_personel}
                  escort_days={item?.escort_days}
                  instructions={item?.passengers_instruction}
                  pickup_location={item?.pickup_location}
                  handleDelete={() => handleDel(item?.booking_id)}
                  currencyCode={item?.currency_code}
                  handleEditBooking={() =>
                    handleEditDeliveryCart(item?.booking_id)
                  }
                  amount={item?.price}
                  no_of_items={item?.no_of_items}
                  total={item?.total}
                />
              );
            } else if (item?.category?.includes("Driver")) {
              return (
                <DriverCart
                  key={item?.booking_id}
                  booking_title={item?.booking_title}
                  pickup_date={item?.pickup_date}
                  pickup_time={item?.pickup_time}
                  pickup_location={item?.pickup_location}
                  no_of_escort={item?.escort_personel}
                  escort_days={item?.escort_days}
                  instructions={item?.passengers_instruction}
                  image={item?.image}
                  transport_options={item?.transport_options}
                  category={item?.category}
                  amount={item?.amount}
                  no_of_hours={item?.hours_needed}
                  state_location={item?.state_location}
                  driver_id={item?.driver_id}
                  provider_id={item?.provider_id}
                  currencyCode={item?.currency_code}
                  user_id={item?.user_id}
                  handleDelete={() => handleDel(item?.booking_id)}
                  handleEditBooking={() =>
                    handleEditDriverBooking(item?.booking_id)
                  }
                  bookingId={item?.booking_id}
                  total={item?.total}
                />
              );
            } else {
              return (
                <Cart
                  key={item?.booking_id}
                  booking_title={item?.booking_title}
                  pickup_date={item?.pickup_date}
                  pickup_time={item?.pickup_time}
                  pickup_location={item?.pickup_location}
                  no_of_escort={item?.escort_personel}
                  escort_days={item?.escort_days}
                  instructions={item?.passengers_instruction}
                  image={item?.image}
                  transport_options={item?.transport_options}
                  category={item?.category}
                  currencyCode={item?.currency_code}
                  amount={item?.amount}
                  no_of_hours={item?.hours_needed}
                  state_location={item?.state_location}
                  plate_number={item?.plate_number}
                  vehicle_id={item?.vehicle_id}
                  provider_id={item?.provider_id}
                  vehicle_year={item?.vehicle_year}
                  handleDelete={() => handleDel(item?.booking_id)}
                  handleEditBooking={() =>
                    handleEditCarBooking(item?.booking_id)
                  }
                  bookingId={item?.booking_id}
                  total={item?.total}
                  from={item?.from}
                  to={item?.to}
                />
              );
            }
          })}
      </CartBoxWrap>

      <CartPageFooterWrap>
        {/* <div className="subsection">
            <CallBox>
              <img src={phone} />
              <div className="questions-text">
                <p>Ask Questions</p>
              </div>
            </CallBox>

            <CouponWrap>
              <input type="text" placeholder='Add a Coupon Code' />
              <CallBox>
                <img className='tick-icon' src={tick}/>
              </CallBox>
            </CouponWrap>
          </div> */}

        <div className="subsection">
          <TotalPriceWrap>
            <p>
              {cart[0]?.currency_code}
              {`${total?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}`}
            </p>
          </TotalPriceWrap>

          <CheckoutBtn onClick={() => handleCheckout(true)}>
            Checkout
          </CheckoutBtn>
        </div>
      </CartPageFooterWrap>

      <BoxExtras>
        <div className="subsection-header">
          <H11>Favourites</H11>
        </div>

        <AssetOverall>
          {favourites
            ?.slice(0)
            ?.reverse()
            ?.slice(0, 4)
            ?.map((item: any, id: any) => (
              <AssetCard
                item={item}
                firstPrice={item?.final_normal_price}
                discountPrice={item?.final_discounted_price}
                discount={item?.discount}
                name={item?.vehicle_name}
                locate={item?.vehicle_location || item?.state}
                vehicleImg={item?.image1}
                vehicleId={item?.id}
                key={id}
                year={item?.year}
                passengerNum={item?.num_passenger}
                rating={item?.rating}
                likes={item?.likes}
              />
            ))}
          {/* <OffersCard />
                <OffersCard /> */}
        </AssetOverall>
      </BoxExtras>

      <Footer />
    </CartOverallWrap>
  );
};

export default CartPage;
