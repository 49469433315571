import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceOffering from "../serviceOffering/serviceOffering";
// import ToggleBar from '../../../components/toggleBar'

import { FaChevronDown } from "react-icons/fa";
import {
  P,
  DIV,
  INPUTDIV
} from "./seventhScreen.styles";
import { ModalOne, SetAvailability } from "./Modal";
import ServiceFee from "../serviceFee";
import { useAppSelector } from "../../../store";
const SeventhScreen: FC<{
  setVehicleDetails: any;
  vehicleDetails: any;
  setWidth: any;
  setQuestion: any;
}> = ({ vehicleDetails, setVehicleDetails, setWidth, setQuestion }) => {

  
  const [isVisibleModalOne, setIsVisibleModalOne] = useState(true);

  useEffect(() => {
    setWidth(100);
    setQuestion(10);
  }, []);


  const handleSpecifications = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVisibleModalOne((prev) => !prev);
  };
 

  return (
    <>
      <INPUTDIV style={{ marginTop: "50px" }} onClick={handleSpecifications}>
        <P>Vehicle Specifications</P>

        <DIV>
          <FaChevronDown
            style={{ color: "#000", fontSize: "20px", fontWeight: "bold" }}
          />
        </DIV>
      </INPUTDIV>
      {isVisibleModalOne && (
        <ModalOne
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
        />
      )}

      <ServiceFee
        vehicleDetails={vehicleDetails}
        setVehicleDetails={setVehicleDetails}
      />
    </>
  );
};

export default SeventhScreen;
