import styled from 'styled-components'
import { SaveBtn } from '../../../components/extrasModal/extrasmodal.styles'
import { LocationInputWrap } from '../../../components/locationModal/locationmodal.styles'

export const CartOverallWrap = styled.div `
    
`

export const PaddingWrap = styled.div`
    margin-top: 30px;
    padding: 30px;
    padding-left: 50px;

    @media only screen and (max-width: 484px) {
        margin-top: 0px;
    }

    @media only screen and (min-width: 500px) {
        display: flex;
        justify-content: center;
    }  
`

export const CartBoxWrap = styled.div `
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    justify-content: center;
`

export const CartPageFooterWrap = styled.div `
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-end;
    align-items: flex-end;

    & .subsection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 70%;

        a {
            text-decoration: none;
            color: unset;
            flex: 0.5;
        }

        @media only screen and (min-width: 800px)  {
            width: 50%;
        }

        @media only screen and (min-width: 1100px)  {
            width: 30%;
        }
    }
`

export const CallBox = styled.div `
    position: relative;
    background-color: #000;
    border-radius: 7px;
    height: 100%;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .questions-text {
        position: absolute;
        bottom: -10px;
        display: flex;
        width: 100%;
        left: 0;
        justify-content: center;
        align-items: center;

        p {
            color: ${(props: any) => props.theme.text};
            font-size: 6px;
            margin: 0;
        }
    }

    & img {
        width: 30px;
    }

    @media only screen and (max-width: 454px) {
        img {
            width: 20px;
        }

        .questions-text {
            p {
                white-space: nowrap;
            }
        }
    }
`

export const CouponWrap = styled(LocationInputWrap) `
    padding: 9px;
    border: solid 1px  ${(props: any) => props.theme.text};
    margin: 0;
    flex: 1;

    @media only screen and (max-width: 454px) {
        padding: 4px 8px;
        border-radius: 4px;
    }

    & .tick-icon {
        width: 13px;

        @media only screen and (max-width: 454px) {
            width: 10px;
        }
    }
`

export const TotalPriceWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #fff;
    border-radius: 8px;
    padding: 10px;
    flex: 0.5;
    background-color: #000;

    & p {
        font-size: 13px;
        font-weight: 700;
        color: #ffa100;
        margin: 0;
        white-space: nowrap;
    }

    @media only screen and (max-width: 454px) {
        border-radius: 4px;
        padding: 8px 8px;

        p {
            font-size: 10px;
            white-space: nowrap;
        }
    }
    
`

export const CheckoutBtn = styled(SaveBtn) `
    flex: 0.5;
    cursor: pointer;

    @media only screen and (max-width: 454px) {
        border-radius: 4px;
    }
`
