import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentLocation: <any>{},
  };
  const currentLocationSlice = createSlice({
    name: 'currentLocation',
    initialState,
    reducers: {
      setCurrentLocation: (state, action) => {
        return { ...state, currentLocation: action.payload };
      }
    }
  });
  
  const { reducer, actions } = currentLocationSlice;
  export const { setCurrentLocation} = actions;
  export default reducer;