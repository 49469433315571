import styled from "styled-components";

export const RoadlersFooter = styled.footer`
  position: relative;
  z-index: 15;

  & .footer-body {
    padding: 40px 80px;
    background-color: transparent;

    .footer-body-inner {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      .first-section {
        flex: 30%;

        h2 {
          color: ${(props: any) => props.text};
          font-size: 24;
          width: 350px;
          margin: 0;
          font-weight: 600;
        }
      }
    }
  }

  & .footer-foot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    background-color: #000000;
    color: #fff;
  }

  @media only screen and (max-width: 991px) {
    & .footer-body {
      .footer-body-inner {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    & .footer-body {
      padding: 40px;
    }
  }

  @media only screen and (max-width: 651px) {
    & .footer-body {
      padding: 40px;
    }
  }

  @media only screen and (max-width: 454px) {
    .footer-body {
      padding: 30px;
      .footer-body-inner {
        .first-section {
          h2 {
            font-size: 20px;
          }
        }
      }
    }
  }
`;

export const FooterHeader = styled.div`
  border-bottom: solid 1px #ffa100;
  padding: 0 80px;
  @media only screen and (max-width: 484px) {
    padding: 0 20px;
  }
  background-color: transparent;

  & .footer-header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-wrapper {
      img {
        height: 65px;
      }
    }

    .social-icon-wrapper {
      display: flex;
      gap: 10px;

      img {
        // filter: ${(props: any) => props.theme.iconColor};
        width: 30px;
      }
      .twitter-social {
        color: ${(props: any) => props.theme.headerBg};
        font-size: 30px;
      }
    }
  }

  @media only screen and (min-width: 385px) {
    & .footer-header-inner {
      .social-icon-wrapper {
        gap: 30px;
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 0 40px;
    }
  }
`;
export const FooterContent = styled.div`
  @media only screen and (max-width: 484px) {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 0px 0px 25px 25px;
  }
  display: flex;
  padding: 0px 0px 80px 80px;
  gap: 250px;
  .left-footer-content {
    display: flex;
    gap: 100px;
    @media only screen and (max-width: 484px) {
      gap: 20px;
    }
  }
  .left-side {
    padding-top: 30px;
  }
  .right-side {
    display: flex;
    gap: 15px;
    flex-direction: column;

    a {
      color: ${(props: any) => props.theme.headerBg};
      font-size: 13px;
      font-weight: 400;
    }
    .chat {
      font-weight: 600;
      font-size: 15px;
      border: 1px solid ${(props: any) => props.theme.headerBg};
      border-radius: 5px;
      padding: 8px 20px;
      text-align: center;
      margin-top: 15px;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    @media only screen and (max-width: 484px) {
      display: flex;
      //   align-items: flex-end;
    }
  }
`;
export const LocationBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AddressDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    font-size: 13px;
    font-weight: 400;
    @media only screen and (max-width: 484px) {
      font-size: 11px;
    }
  }
  .email {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .email p {
    margin: 0;
  }
`;
export const H2 = styled.h2`
  font-size: 23px;
  font-weight: 500;
`;
export const H3 = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;
