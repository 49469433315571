import React from 'react'
import { AbsoluteButton, CardContentWrap, CardImgWrap, CardTextWrap, ItemCardWrap } from './walletcard.styles'

import wallet from '../../assets/images/png/card-purse.png'
import fund from '../../assets/images/svg/fund-img.svg'

const WalletCard = ({handleWalletModal, handleWalletModalDisplay, currency, amount}: any) => {

    const handleShowWalletModal = () => {
        handleWalletModal(true)
    }

  return (
    <>
        <ItemCardWrap>
            <div className="inner-item-card">
                <CardContentWrap>
                    <CardTextWrap>
                        <p>My Total Balance</p>

                        {
                            amount ? 
                            <h2> <span>{currency}</span> {`${(Number(amount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h2> :
                            <h2>0</h2>
                        }

                    </CardTextWrap>
                    <CardImgWrap>
                        <img src={wallet}/>
                    </CardImgWrap>
                </CardContentWrap>
            </div>

            <AbsoluteButton onClick={handleWalletModalDisplay}>
                <div className="inner-button">
                    <img src={fund} />

                    <p>Fund Wallet</p>
                </div>
            </AbsoluteButton>
        </ItemCardWrap>
    </>
  )
}

export default WalletCard