import styled from 'styled-components'



export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:12px;
border:1px solid ${(props: any) => props.theme.text};

gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
`
export const InputDivHourly = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:12px;
gap:5px;
padding:9px 11px;
border:1px solid ${(props: any) => props.theme.text};

@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.driverHourlyRateVisible ? '10' : '4'};

`
export const InputDivFullDay = styled.div`
display:flex;
align-items:center;
background:white;
border:1px solid ${(props: any) => props.theme.text};
width:100%;
border-radius:12px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.driverFullDayServiceVisible ? '10' : '4'};

`
export const InputDivAllNight = styled.div`
display:flex;
align-items:center;
background:white;
border:1px solid ${(props: any) => props.theme.text};

width:100%;
border-radius:12px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.driverAllNightServiceVisible ? '10' : '4'};

`
export const InputDivWeekly = styled.div`
display:flex;
align-items:center;
background:white;
border:1px solid ${(props: any) => props.theme.text};

width:100%;
border-radius:12px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.driverWeeklyServiceVisible ? '10' : '4'};

`
export const InputDivMonthly = styled.div`
display:flex;
align-items:center;
background:white;
border:1px solid ${(props: any) => props.theme.text};

width:100%;
border-radius:12px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.driverMonthlyVisible ? '10' : '4'};

`

export const ServiceLabel = styled.label`
color:black;
font-size:13px;
font-weight:medium;
width:38%;
display:flex;
@media only screen and (max-width: 380px) {
    font-size:12px;
    font-weight:medium;
    }
`








export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:40%;
justify-content:space-around;
border-radius:8px;
position:relative;
padding:9px 6px;
@media only screen and (max-width: 380px) {
    padding:7px 6px ;

    }
`


export const HourButton = styled.button`
background: #FFD58C;
color: #000;
padding:5px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
position:relative;
display:flex;
align-items:center;
justify-content:space-between;
gap:8px;
width:20%;
`
export const DISCOUNT = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:5px;
border-radius:5px;
font-size:13px;
position:relative;

`


export const FLEXDIV = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
margin-top:50px;

`

export const P = styled.p`
color:${(props: any) => props.theme.white};
font-size:10px;
position:absolute;
font-weight:500;

`

export const MARKDIV = styled.div`
display:flex;
align-items:center;
background: #FFD58C;
border-radius:5px;
padding:0.2em;
z-index:8;
`

export const Text = styled.div`
font-size:15px;
color:#000;
font-weight:700;
width:100%;

`
export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
display:flex;
align-items:center;
justify-content:center;
gap:7px;
border-radius:5px;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
flex-direction:column;
`
export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-bottom:-40px;
position:absolute;
right:100px;
width:240px;
z-index:1000;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:60%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Radio = styled.button`
border:3px solid #000;
padding:4px;
border-radius:50px;
`
export const Label = styled.label`

width:100%;


`
