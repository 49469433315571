import styled from 'styled-components'


export const Input = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:7px;
justify-content:space-between;
padding:2px 10px;
margin-top:48px;
position:relative;
`

export const Button = styled.button`
background: #FFD58C;
color: #000;
padding:8px 25px;
outline:none;
border:none;
border-radius:5px;
font-size:13px;
font-weight:bold;
margin-left:5px;
position:relative;
`
export const P = styled.p`
position:absolute;
right:0px;
bottom:40px;
color:#fff;
font-size:10px;
z-index:22222222;
`
export const Label = styled.label`
background: #E59EFE;
color:#000;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px;
border-radius:5px;
margin-top:55px;
`