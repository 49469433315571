import { useEffect, useState } from "react";
import "./styles/BlogCard.css"

import blogimg from "../../assets/images/jpg/blogcard.jpg"
import likeIcon from "../../assets/images/svg/like-icon.svg"
import likeFilled from "../../assets/images/svg/likeFilled.svg"

import { NavLink } from "react-router-dom";
import { usePostBlogLikeMutation } from "../../services/blogApi";
import StyledButton from "../button";


const BlogCard = ({title, blogContent, readMinute, numLikes, image, bloglink, buttonText, pagename, id }: any) => {
  const [postBlogLike, {data, isLoading, isSuccess, isError, error}] = usePostBlogLikeMutation()

  const [likeBtn, setLikeBtn] = useState(false)
  const [likesCount, setLikesCount] = useState<number | any>()

  const likesNoCount = Number(numLikes)


  useEffect(() => {
    setLikesCount(likesNoCount)
  }, [])

  const handleBlogPostLike = (numLike: number) => {
    let formData: any = new FormData()

    formData.append('post_id', id)
    formData.append('likes', numLike)

    postBlogLike(formData)
  }

  const handleLikesSub = () => {
    setLikeBtn(false)
      const newCount = likesCount - 1;
      if(newCount < 0 ) {
        setLikesCount(0)
      }else {
        setLikesCount(newCount)
      }
      handleBlogPostLike(0)
  }

  const handleLikesAdd = () => {
    setLikeBtn(true)
    const newCount = likesCount + 1;
    setLikesCount(newCount)
    handleBlogPostLike(1)
  }

  return (
    <div className='blogcard-container'>
      <div className="inner-wrapper">
          <div className="blogcard-image-title">
            {/* <div className="blogcard-img"> */}
              <img src={`https://api.roadlers.com/storage/${image}`} alt="" />
            {/* </div> */}
            <div className="blogcard-title">
              <div className="blogcard-title-wrapper">
                <h2>{title}</h2>
              </div>
            </div>
          </div>

          <div className="blogcard-content">
            <div className="inner-content-wrapper">
              <div className="title-wrapper">
                <h2>
                  {title}
                </h2>
                <hr />
              </div>

              <div className="subcontent-wrapper">
                <p
                  dangerouslySetInnerHTML={{
                    __html: blogContent
                  }} 
                />

              </div>

              <div className="blogcontent-footer">
                <div className="likes">
                  {
                    pagename === "edit-blog" ?
                    <div className="img-wrapper">
                        <button>
                          <img src={likeFilled} alt="" />
                        </button>
                    </div> :
                    <div className="img-wrapper">
                        {
                          likeBtn === false && (
                            <button onClick={() => handleLikesAdd()}>
                              <img src={likeIcon} alt="" />
                            </button>
                          )         
                        }
  
                        {
                          likeBtn === true && 
                          <button onClick={() => handleLikesSub()}>
                            <img src={likeFilled} alt="" />
                          </button>
                        }
                    </div>

                  }




                  <div className="likes-no">
                    <p>{likesCount} {likesCount === 1 ? "like" : "likes" }</p>
                  </div>
                </div>

                <div className="readmore-wrapper">
                  <div className="read-minute">
                    <p>{Math.round(readMinute)} Min read</p>
                  </div>

                  
                    <NavLink to={`/${pagename}/${bloglink}`}>
                      <StyledButton buttonText={buttonText} />
                    </NavLink>
                    
                  
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default BlogCard