import React, { FC, useEffect, useState } from 'react'
import {
    ModalContainer, Modal, Label, Flex, ImageContainer, TextContainer, BackButton, DeleteButton, Text
} from './index.styles';
import deleteImage from '../../../assets/images/png/modalOption.png'
import spinner from '../../../assets/images/gif/spinner.gif'

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'
// import { useDeleteVehicleMutation } from '../../../services/providerApi';
// import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setServiceVisibility, setVehicleDetailss } from '../../../slices/provider';
// import { setAllVehicle } from '../../../slices/provider';

type Props = {

    setIsDriverHourlyDel?: any;
    setDriverHourlyRatePrice?: any;
    isDriverHourlyDel?: any;
    driverDetails: any;
    setDriverDetails: any;
    deleteFor: any;
    setHourlyRateHour?: any;
    setDiscount?: any;
    isFullDayDel?: any;
    setIsFullDayDel?: any;
    setFullDayServiceHour?: any;
    setFullDayServicePrice?: any;
    setIsAllNightDel?: any;
    setAllNightServiceHour?: any;
    setAllNightServicePrice?: any;
    isAllNightDel?: any;
    setIsWeeklyDel?: any;
    setWeeklyServiceHour?: any;
    setWeeklyServicePrice?: any;
    isWeeklyDel?: any;
    setIsMonthlyDel?: any;
    setMonthlyServiceHour?: any;
    setMonthlyServicePrice?: any;
    isMonthlyDel?: any;
    setHourlyDiscount?:any;
    setFullDayDiscount?:any;
    setAllNightDiscount?:any;
    setWeeklyDiscount?:any;
    setMonthlyDiscount?:any;
    
}


export const DeleteModal: FC<Props> = ({
    isDriverHourlyDel,
    setDriverHourlyRatePrice,
    setIsDriverHourlyDel,
    driverDetails,
    setDriverDetails,
    deleteFor,
    setHourlyRateHour,
    setDiscount,
    isFullDayDel,
    setFullDayServiceHour,
    setIsFullDayDel,
    setFullDayServicePrice,
    setAllNightServiceHour,
    setAllNightServicePrice,
    isAllNightDel,
    setIsAllNightDel,
    setIsWeeklyDel,
    setWeeklyServiceHour,
    setWeeklyServicePrice,
    isWeeklyDel,
    setIsMonthlyDel,
    setMonthlyServiceHour,
    setMonthlyServicePrice,
    isMonthlyDel,
    setHourlyDiscount,
    setFullDayDiscount,
    setAllNightDiscount,
    setWeeklyDiscount,
    setMonthlyDiscount
}) => {

    const dispatch: any = useAppDispatch()


    // const [deleteVehicle, { data, isLoading, isSuccess, isError, error }] = useDeleteVehicleMutation()






    const deleteService = (title: string) => {
        setDriverDetails((prevDetails: any) => {
            const updatedServicePrice = prevDetails.service_price.map((detail: any) => {
                if (detail.service_title === title) {
                    return {
                        ...detail,
                        price: 0,
                        hour: 0,
                        discount: 0,
                    };
                } else {
                    return detail;
                }
            });
            
            return { ...prevDetails, service_price: updatedServicePrice };
        });
    };
    

    const handleDelete = async () => {
        dispatch(setServiceVisibility({ driverFullDayServiceVisible: false }))
        deleteService(deleteFor)
        setFullDayServicePrice('')
        setFullDayDiscount('0')
        setIsFullDayDel(false)

    }


    const handleDeleteAllNight = async () => {
        dispatch(setServiceVisibility({ driverAllNightServiceVisible: false }))
        deleteService(deleteFor)
        setAllNightServicePrice('')
        setAllNightDiscount('0')
        setIsAllNightDel(false)

    }
    const handleDeleteHourly = async () => {
        dispatch(setServiceVisibility({ driverHourlyRateVisible: false }))
        deleteService(deleteFor)
        setDriverHourlyRatePrice('')
        setHourlyDiscount('0')
        setDriverHourlyRatePrice('')
        setIsDriverHourlyDel(false)
    }


    const handleDeleteWeekly = async () => {
        dispatch(setServiceVisibility({ driverWeeklyServiceVisible: false }))
        deleteService(deleteFor)
        setWeeklyDiscount('0')
        setIsWeeklyDel(false)

    }

    const handleDeleteMonthly = async () => {
        dispatch(setServiceVisibility({ driverMonthlyVisible: false }))
        deleteService(deleteFor)
        setMonthlyDiscount('0')
        setIsMonthlyDel(false)
        setMonthlyServicePrice('')

    }
    const handleDeleteFullMonth = async () => {
        // dispatch(setServiceVisibility({ fullMonthVisible: false }))
        // deleteService(deleteFor)
        // setDiscount('0')
        // setIsFullMonthDel(false)
        // setFullMonthServicePrice('')

    }

    
    
    const handleCancelHourlyModal = async () => {
        setIsDriverHourlyDel((prev: any) => !prev)

    }
    const handleCancelFullDayModal = async () => {
        setIsFullDayDel((prev: any) => !prev)

    }
    const handleCancelAllNightModal = async () => {
        setIsAllNightDel((prev: any) => !prev)

    }
    const handleCancelWeeklyModal = async () => {
        setIsWeeklyDel((prev: any) => !prev)

    }
    const handleCancelMonthlyModal = async () => {
        setIsMonthlyDel((prev: any) => !prev)

    }
    // const handleCancelHalfMonthModal = async () => {
    //     setIsHalfMonthDel((prev: any) => !prev)

    // }

    // const handleDeleteDriverHourly = async () => {
    //     dispatch(setServiceVisibility({ driverHourlyRateVisible: false }))
    //     deleteService(deleteFor)
    //     setHourlyRatePrice('')
    //     setDiscount('0')
    //     setIsHourlyDel(false)

    // }
    const handleCancelDriverHourlyModal = async () => {
        setIsDriverHourlyDel((prev: any) => !prev)

    }


    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",

    })

    return (
        <ModalContainer>
            <ToastContainer />

            <Modal>
                <ImageContainer>
                    <img src={deleteImage} style={{ width: '100%' }} />
                </ImageContainer>
                <TextContainer>
                    <Text>Note</Text>
                    <Label>
                        By clicking this button your vehicle will be unavaliable for <span style={{ fontSize: '13px', fontWeight: '700' }}>{deleteFor}</span>,
                        to reactivate click the green tick. Do you want to proceed?
                    </Label>

                    <Flex>

                        {isFullDayDel &&
                            <>
                                <DeleteButton onClick={handleDelete}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelFullDayModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }

                        {isAllNightDel &&
                            <>
                                <DeleteButton onClick={handleDeleteAllNight}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelAllNightModal}>
                                    Cancel
                                </BackButton>
                            </>



                        }


                        {isWeeklyDel &&
                            <>
                                <DeleteButton onClick={handleDeleteWeekly}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelWeeklyModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }
                       
                        {isMonthlyDel &&
                            <>
                                <DeleteButton onClick={handleDeleteMonthly}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelMonthlyModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }
                         {/*
                        {isFullMonthDel &&
                            <>
                                <DeleteButton onClick={handleDeleteFullMonth}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelFullMonthModal}>
                                    Cancel
                                </BackButton>
                            </>
                        } */}


                        {isDriverHourlyDel &&
                            <>
                                <DeleteButton onClick={handleDeleteHourly}>
                                    Proceed
                                </DeleteButton>

                                <BackButton onClick={handleCancelHourlyModal}>
                                    Cancel
                                </BackButton>
                            </>
                        }

                    </Flex>
                </TextContainer>


            </Modal>
        </ModalContainer >

    )
}


