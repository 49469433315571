import styled from "styled-components"

export const CheckBoxWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
     margin-top: 8px;
`

export const CheckBoxLabel = styled.label`
    position: absolute;
    top: -2px;
    left: 5px;
    width: 20px;
    height: 12px;
    border-radius: 15px;
    background-color: #bebebe;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        margin: 2px;
        background: #000;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`

export const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    
    border-radius: 15px;
    width: 20px;
    height: 12px;
    border:  1px solid green;
    outline: none;

    &:checked + ${CheckBoxLabel} {
        background: #000;
border:1px solid #fff;
        &::after {
            content: "";
            display: flex;
            border-radius: 50%;
            width: 7px;
            height: 7px;
            margin: 1.3px;
            margin-left: 10px;
            background: #ffa100;
            box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
            transition: 0.2s;
            
        }
    }

`

export const CheckBoxLabelTwo = styled.label`
    position: absolute;
    top: -2px;
    left: 5px;
    width: 20px;
    height: 12px;
    border-radius: 15px;
    background-color: #bebebe;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        margin: 2px;
        background: #000;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`

export const CheckBoxTwo = styled.input`
    opacity: 0;
    z-index: 1;
    
    border-radius: 15px;
    width: 20px;
    height: 12px;
    border:  1px solid green;
    outline: none;

    &:checked + ${CheckBoxLabelTwo} {
        background: #000;
border:1px solid #fff;
        &::after {
            content: "";
            display: flex;
            border-radius: 50%;
            width: 7px;
            height: 7px;
            margin-top: 1.25px;
            margin-left: 10px;
            background: #ffa100;
            box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
            transition: 0.2s;
            
        }
    }

`