import React, { useEffect, useMemo, useRef, useState } from "react";
import AssetCard from "../../../components/assetCard";
import DriverRequestCard from "../../../components/driverRequestCard";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import HelloBox from "../../../components/helloBox";
import SignupCard from "../../../components/signupCard";
import SpecialEventCard from "../../../components/specialEventCard";
import ToggleBar from "../../../components/toggleBar";
import TravelGarageBox from "../../../components/travelGarageBox";

import {
  AboutBox,
  Banner,
  BookNow,
  BoxH1,
  BoxP,
  Icons,
  CenterWrapper,
  Container,
  ContentBottom,
  ContentLeft,
  ContentRight,
  DeliveryBox,
  DriverImgLeft,
  FilterPill,
  FlexEndWrap,
  FlexStartWrap,
  H1,
  H11,
  H12,
  H13DIV,
  P,
  Page,
  PillWrapper,
  SearchWrap,
  SectionFilter,
  VehicleImgRight,
  InputBox,
  FilterBtn,
  SearchBtn,
  PS,
  FilterVerifiedHeader,
  RadioOverall,
  RadioBtn,
  AssetOverall,
  BoxSection,
  SwiperWrapper,
  BoxExtras,
  UniqueGiftImg,
  BannerWrap,
  PL,
  DeliveryBoxInfo,
  DeliveryTxtHeader,
  WhiteDeliveryTxt,
  YellowDeliveryTxt,
  DeliveryImageBox,
  DeliveryImageWrap,
  DeliveryContentBox,
  StartDeliveryBtn,
  YoutubeWrap,
  SearchFilterBox,
  PillMiniWrapper,
  FilterBoxWrap,
  SecondBoxContainer,
  FilterVerifiedHeaderWrap,
  FirstAboutBox,
  Slider,
  ModelDiv,
  BlackOpacity,
  WatchNow,
  HorizontalLine,
  BoxExtra,
  H14,
  VideoDiv,
} from "./homepage.styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { FreeMode, Navigation, EffectFade, Autoplay } from "swiper";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../store";
import { setTheme } from "../../../slices/theme";
import { setShowFilter } from "../../../slices/showFilter";
import { TypeAnimation } from "react-type-animation";
import FilterModal from "../../../components/filterBoxModal";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useGetGiftCardListQuery,
  useGetPricingListQuery,
  useGetVehicleListQuery,
} from "../../../services/customerApi";
import PaginationComponent from "../../../components/paginationComponent";
import {
  ExtrasInputWrap,
  HeaderFilterPill,
  HeaderImgWrap,
  HeaderInput,
  SearchHeaderSection,
  SearchHeaderWrap,
} from "../SearchPage/searchpage.styles";
import SearchUpdateComponent from "../../../components/searchUpdateComponent";
import {
  SearchWrapper,
  UserImageWrap,
  InputOverall,
  VehicleTypeInput,
  VehicleYearInput,
  UserStateWrap,
  FilterSearchBtn,
} from "../../../components/searchUpdateComponent/searchUpdate.styles";

import banner from "../../../assets/images/png/hero.png";
import model from "../../../assets/images/png/model.png";
import left from "../../../assets/images/jpg/left.jpeg";
import right from "../../../assets/images/jpg/right.jpeg";
import banner2 from "../../../assets/images/png/2.png";
import banner3 from "../../../assets/images/png/3.png";
import banner4 from "../../../assets/images/png/4.png";
import phone from "../../../assets/images/svg/phone.svg";
import vehicleRight from "../../../assets/images/svg/rightVehicle.svg";
import driverImgLeft from "../../../assets/images/svg/leftDriver.svg";
import deliveryman from "../../../assets/images/svg/deleiveryman.svg";
import location from "../../../assets/images/png/locationBlack.png";
import filter from "../../../assets/images/png/filterPng.png";
import gift1 from "../../../assets/images/svg/gift1.svg";
import gift2 from "../../../assets/images/svg/gift2.svg";
import gift3 from "../../../assets/images/svg/gift3.svg";
import gift4 from "../../../assets/images/svg/gift4.svg";
import searchIcon from "../../../assets/images/svg/header-search-icon.svg";
import carProp from "../../../assets/images/png/carrr.png";
import driverProp from "../../../assets/images/svg/driverProp.svg";
import chaffeur from "../../../assets/images/png/chaffeur.png";
import vehRental from "../../../assets/images/png/vehRental.png";
import travelImg from "../../../assets/images/png/travelImg.png";
import delivery from "../../../assets/images/png/delivery.png";
import specialEvents from "../../../assets/images/svg/special-events.svg";
import refereWin from "../../../assets/images/png/refer-to-win.png";
import userImg from "../../../assets/images/png/dummyImg.png";
import { setPaginationNum } from "../../../slices/pagination";
import LocationInputModal from "../../../components/locationSearchModal";
import Loader from "../../../components/loader";
import ReactWhatsapp from "react-whatsapp";
import { setSearchData } from "../../../slices/showCarOption";
import { RxMixerHorizontal } from "react-icons/rx";
import { FaPlay } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

const Homepage = () => {
  localStorage.setItem("roadlersLastVisited", "/");
  const navigate = useNavigate();
  const { data, isFetching, isLoading, refetch, isSuccess } =
    useGetVehicleListQuery({
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    });

  const {
    data: giftCardData,
    isFetching: idfetchingGift,
    refetch: refetchGift,
    isSuccess: isSuccessGift,
  } = useGetGiftCardListQuery();

  const {
    data: pricingListData,
    isFetching: idfetchingPriceList,
    refetch: refetchPriceList,
    isSuccess: isSuccessPrice,
  } = useGetPricingListQuery();

  const { currentLocation } = useAppSelector<any>(
    (store: RootState) => store?.currentLocation
  );
  const dispatch: AppDispatch = useAppDispatch();

  const { showFilter } = useAppSelector<any>(
    (store: RootState) => store?.showFilter
  );
  const { paginationNum } = useAppSelector<any>(
    (store: RootState) => store?.setPaginationNum
  );
  const [filterModal, setFilterModal] = useState("");

  const [vehicleListData, setVehicleListData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterPill, setFilterPill] = useState<any[]>([]);
  const [findCar, setFindCar] = useState([]);
  const [minYear, setMinYear] = useState<any>(1700);
  const [maxYear, setMaxYear] = useState<any>(2030);
  const [dummyYear, setDummyYear] = useState<any>();
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("100000000");
  const [vehicleLocation, setVehicleLocation] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleBrand, setVehicleBrand] = useState("");

  const [vehicleType, setVehicleType] = useState<any[]>([]);
  const [vehicleFuelType, setVehicleFuelType] = useState("");
  const [assetDisplay, setAssetDisplay] = useState("");
  const [resultsNumber, setResultsNumber] = useState("0");
  const [pickupLatLong, setPickupLatLong] = useState<any>();
  const [city, setCity] = useState("");
  const [nearbyVehicleArr, setNearbyVehicleArr] = useState<any[]>([]);
  const [periodType, setPeriodType] = useState("");
  const [fuelType, setFuelType] = useState("");

  const searchListRef: any = useRef();
  const driverboxRef: any = useRef();
  const clearAssetDisplay = () => {
    setAssetDisplay("");
  };

  const handleFilterPill = (filterValue: any) => {
    if (vehicleType?.includes(filterValue)) {
      const updateArr = vehicleType?.filter(
        (item: any) => item !== filterValue
      );
      setVehicleType(updateArr);
    } else {
      setFilterPill([...filterPill, filterValue]);
      setVehicleType([...vehicleType, filterValue]);
      clearAssetDisplay();
    }
  };

  const vehicleCarTypes = [
    { vehicleName: "Luxury Car" },
    { vehicleName: "SUV" },
    { vehicleName: "Saloon Car" },
    { vehicleName: "Sport Car" },
    { vehicleName: "Vintage Car" },
    { vehicleName: "Flat Bird" },
    { vehicleName: "Mini Bus" },
    { vehicleName: "Coaster Bus" },
    { vehicleName: "School Bus" },
    { vehicleName: "Luxury Bus" },
    { vehicleName: "Van " },
    { vehicleName: "Truck" },
    { vehicleName: "Trailers" },
    { vehicleName: "Pick-up Truck" },
    { vehicleName: "Caterpillers" },
    { vehicleName: "Power Bike " },
    { vehicleName: "Delivery Bikes" },
    { vehicleName: "Boats" },
    { vehicleName: "Helicopters" },
    { vehicleName: "Jets" },
    { vehicleName: "Emergency " },
  ];

  const subCarList: any = [
    { carType: "SUV" },
    { carType: "Saloon Car" },
    { carType: "Luxury Car" },
  ];

  const subBusList: any = [
    { busType: "Minibus" },
    { busType: "Coaster Bus" },
    { busType: "Luxury Bus" },
    { busType: "School Bus" },
  ];

  const subBikeList: any = [
    { bikeType: "Rent Bikes" },
    { bikeType: "Make a delivery" },
  ];

  let PageSize = 8;

  useEffect(() => {
    refetch();
    refetchGift();
    refetchPriceList();
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setVehicleListData(data?.data);
    }
  }, [isSuccess]);

  // FILTER THROUGH TO SORT OBJECTS ACCORDING TO DISTANCE
  function getObjectsByDistance(objects: any, address: any) {
    const R = 6371; // Radius of the earth in km
    const { latitude: lat1, longitude: lon1 } = address;

    // Calculate distances for each object
    const objectsWithDistances = objects.map((obj: any) => {
      const { latitude: lat2, longitude: lon2 } = obj;
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in km
      return { ...obj, distance };
    });

    // Sort objects based on distance in ascending order
    const sortedObjects = objectsWithDistances.sort(
      (a: any, b: any) => a.distance - b.distance
    );

    return sortedObjects;
  }

  function toRad(value: any) {
    return (value * Math.PI) / 180; // Convert degrees to radians
  }

  useEffect(() => {
    if (pickupLatLong) {
      const objects = vehicleListData;
      const address = {
        latitude: Number(pickupLatLong?.lat),
        longitude: Number(pickupLatLong?.lng),
      };
      const nearbyObjects = getObjectsByDistance(objects, address);
      setNearbyVehicleArr(nearbyObjects);
    }
  }, [pickupLatLong]);

  useEffect(() => {
    let filtered: any =
      nearbyVehicleArr?.length > 0 ? nearbyVehicleArr : vehicleListData;

    if (pickupLatLong) {
      filtered = nearbyVehicleArr;
    }

    if (minPrice && maxPrice) {
      filtered = filtered?.filter(
        (item: any) =>
          Number(item?.final_discounted_price) >= Number(minPrice) &&
          Number(item?.final_discounted_price) <= Number(maxPrice)
      );
    }
    // if (vehicleName) {
    //     filtered = filtered?.filter((item: any) => item?.vehicle_name.toLowerCase()?.includes(vehicleName?.toLowerCase()) || item?.year.toLowerCase()?.includes(vehicleName?.toLowerCase())
    //     )
    // }

    if (vehicleBrand) {
      filtered = filtered?.filter((item: any) =>
        item?.brand_name.toLowerCase()?.includes(vehicleBrand?.toLowerCase())
      );
    }

    if (minYear && maxYear) {
      filtered = filtered?.filter(
        (item: any) =>
          Number(item?.year) >= Number(minYear) &&
          Number(item?.year) <= Number(maxYear)
      );
    }
    if (vehicleType && vehicleType?.length > 0) {
      filtered = filtered?.filter((item: any) =>
        vehicleType.some((name) =>
          item?.vehicle_type.toLowerCase()?.includes(name.toLowerCase())
        )
      );
    }

    if (periodType) {
      filtered = filtered?.filter((item: any) =>
        item?.service_price?.some((item: any) =>
          item?.service_title?.includes(periodType)
        )
      );
    }
    if (fuelType) {
      filtered = filtered?.filter((item: any) =>
        item?.fuel_type?.toLowerCase()?.includes(fuelType?.toLowerCase())
      );
    }

    setFindCar(filtered);
    setResultsNumber(filtered?.length);
  }, [
    vehicleLocation,
    vehicleListData,
    minPrice,
    minYear,
    maxPrice,
    maxYear,
    vehicleName,
    vehicleType,
    pickupLatLong,
    nearbyVehicleArr,
    periodType,
    fuelType,
  ]);

  const filterModalControl = () => {
    dispatch(setShowFilter(true));
  };

  // let [currentTableData, setCurrentTableData]=useState([])
  const currentTableData = useMemo(() => {
    const firstPageIndex = (paginationNum - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return findCar?.slice(firstPageIndex, lastPageIndex);
  }, [paginationNum, vehicleListData, findCar]);

  // useEffect(() => {
  //   const firstPageIndex = (paginationNum - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   setCurrentTableData(findCar?.slice(firstPageIndex, lastPageIndex))

  // }, [paginationNum, vehicleListData, findCar]);

  useEffect(() => {
    if (!currentTableData?.length) {
      dispatch(setPaginationNum(1));
    }
  }, [currentTableData]);

  const addMaxYear = () => {
    setMaxYear(maxYear + 1);
  };

  const subtractMaxYear = () => {
    if (maxYear > 1700) {
      setMaxYear(maxYear - 1);
    }
  };

  const addMinYear = () => {
    setMinYear(minYear + 1);
  };

  const subtractMinYear = () => {
    if (minYear > 1700) {
      setMinYear(minYear - 1);
    }
  };

  const handleClearFilter = () => {
    setMinYear(1700);
    setMaxYear(2030);
    setMinPrice("0");
    setMaxPrice("100000000");
    setPeriodType("");
    setVehicleLocation("");
    setVehicleName("");
    setVehicleType([]);
    setFuelType("");
  };

  const goToListTop: any = () => {
    window.scrollTo({
      top: searchListRef?.current.offsetTop,
      behavior: "smooth",
    });
  };

  const goToDriverTop: any = () => {
    window.scrollTo({
      top: driverboxRef?.current.offsetTop,
      behavior: "smooth",
    });
  };

  const [locationGps, setLocationGps] = useState(currentLocation?.city);
  const [showModal, setShowModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showSecondVideo, setShowSecondVideo] = useState(false);

  const handleWatchNowClick = () => {
    setShowVideo((prev) => !prev);
  };
  const handleWatchSecondNowClick = () => {
    setShowSecondVideo((prev) => !prev);
  };

  return (
    <Page>
      <FilterModal
        modalFilter={showFilter}
        minYear={minYear}
        maxYear={maxYear}
        setMinYear={setMinYear}
        setMaxYear={setMaxYear}
        addMaxYear={addMaxYear}
        addMinYear={addMinYear}
        subtractMaxYear={subtractMaxYear}
        subtractMinYear={subtractMinYear}
        minPrice={minPrice}
        maxPrice={maxPrice}
        setMinPrice={setMinPrice}
        setMaxPrice={setMaxPrice}
        vehicleLocation={vehicleLocation}
        setVehicleLocation={setVehicleLocation}
        vehicleName={vehicleName}
        setVehicleName={setVehicleName}
        setVehicleBrand={setVehicleBrand}
        vehicleBrand={vehicleBrand}
        isHomepage={true}
        vehicleType={vehicleType}
        setVehicleType={setVehicleType}
        clearFilter={handleClearFilter}
        resultsNo={resultsNumber}
        handleFilterPill={handleFilterPill}
        setPickupLatLong={setPickupLatLong}
        setCity={setCity}
        setPeriodType={setPeriodType}
        periodType={periodType}
        fuelType={fuelType}
        setFuelType={setFuelType}
      />

      <LocationInputModal
        location={locationGps}
        setLocation={setLocationGps}
        setCity={setCity}
        showModal={showModal}
        setPickupLatLong={setPickupLatLong}
        setShowModal={setShowModal}
      />
      <Header />

      {/* SECTION 1*/}
      <Container>
        <ContentLeft>
          <H11>Find Vehicles</H11>
          <H12>You Like in 2 Mins</H12>
          <H13DIV>
            <TypeAnimation
              sequence={[
                "Travel", // Types 'Poeple'
                2000, // Waits 2s
                "Rent", // Deletes 'Goods' and types 'People'
                2000, // Waits 2s
              ]}
              wrapper="h1"
              speed={20}
              cursor={true}
              repeat={Infinity}
              style={{ fontWeight: 800, marginBottom: 0, marginTop: "10px" }}
            />
          </H13DIV>
          <ContentBottom>
            {/* <BookNow>
                        Book Now
                        <Icons loading="lazy" src={phone} />
                    </BookNow> */}
          </ContentBottom>
        </ContentLeft>

        <ContentRight>
          <BannerWrap>
            <Banner src={banner} loading="lazy" />
          </BannerWrap>
        </ContentRight>
      </Container>

      <SectionFilter>
        <p className="detail-text">Chauffeured & Self Drive</p>

        <div>
          <h2>Find it - Book it - Ride it</h2>
        </div>

        {/* <FilterVerifiedHeaderWrap>
          <FilterVerifiedHeader>
            <p>Go Pro, see only verified vehicles</p>
            <ToggleBar />
          </FilterVerifiedHeader>
        </FilterVerifiedHeaderWrap> */}

        <FilterBoxWrap>
          <SearchWrapper ref={searchListRef}>
            <UserImageWrap>
              <img src={userImg} />
            </UserImageWrap>

            <div className="inner-search-wrap">
              <InputOverall>
                <VehicleTypeInput
                  placeholder="What Vehicle ?"
                  value={vehicleName}
                  onChange={(e: any) => {
                    setVehicleName(e.target.value);
                  }}
                />
              </InputOverall>

              <UserStateWrap onClick={() => setShowModal(true)}>
                <img src={location} />

                <p>{city || currentLocation?.city}</p>

                <div className="absolute-txt">Where?</div>
              </UserStateWrap>

              <FilterSearchBtn
                onClick={() => {
                  // filterModalControl
                  dispatch(setSearchData(vehicleName));
                  navigate("/search-page");
                }}
              >
                {/* <img src={searchIcon} /> */}
                <FiSearch style={{ width: 20, height: 20 }} />
                {/* <RxMixerHorizontal style={{ width: 20, height: 20 }} /> */}
                <div className="absolute-txt">Search</div>
              </FilterSearchBtn>
            </div>
          </SearchWrapper>
        </FilterBoxWrap>

        {filterPill?.includes("bike") && (
          <RadioOverall>
            {subBikeList?.map((item: any, id: any) => {
              return (
                <PillMiniWrapper
                  key={id}
                  onClick={() => setAssetDisplay(item?.bikeType)}
                >
                  <RadioBtn
                    radioActive={item?.bikeType === assetDisplay ? "fill" : ""}
                  />
                  {item?.bikeType}
                </PillMiniWrapper>
              );
            })}
          </RadioOverall>
        )}

        {!assetDisplay?.includes("Make a delivery") &&
          (isLoading ? (
            <Loader />
          ) : (
            <>
              <div id="vehicles"></div>
              <AssetOverall>
                {currentTableData?.map((item: any, id: any) => {
                  return (
                    <AssetCard
                      item={item}
                      firstPrice={item?.final_normal_price}
                      discountPrice={item?.final_discounted_price}
                      discount={item?.discount}
                      name={item?.vehicle_name}
                      locate={
                        item?.vehicle_location
                          ? item?.vehicle_location
                          : item?.vehicle_address
                      }
                      vehicleImg={item?.image1}
                      vehicleId={item?.id}
                      key={id}
                      year={item?.year}
                      passengerNum={item?.num_passenger}
                      rating={item?.rating}
                      likes={item?.likes}
                    />
                  );
                })}
              </AssetOverall>

              <PaginationComponent
                currentPage={paginationNum}
                totalCount={findCar?.length}
                pageSize={PageSize}
                onPageChange={(page: any) => dispatch(setPaginationNum(page))}
                goToListTop={() => goToListTop()}
              />
            </>
          ))}

        {assetDisplay?.includes("Make a delivery") && (
          <>
            <DeliveryBoxInfo>
              <DeliveryTxtHeader>
                <WhiteDeliveryTxt>Want To Make A Delivery?</WhiteDeliveryTxt>

                <YellowDeliveryTxt>Let’s Make That Happen</YellowDeliveryTxt>
              </DeliveryTxtHeader>

              <DeliveryImageBox>
                <DeliveryImageWrap>
                  <img loading="lazy" src={delivery} />
                </DeliveryImageWrap>
              </DeliveryImageBox>

              <DeliveryContentBox>
                <p>
                  Need to make a bike delivery to any destination? Make a
                  request now and it will be processed immediately.
                </p>
              </DeliveryContentBox>

              <NavLink to={"/delivery-booking"}>
                <StartDeliveryBtn>
                  <p>Start Delivery Now</p>
                </StartDeliveryBtn>
              </NavLink>
            </DeliveryBoxInfo>
          </>
        )}
      </SectionFilter>

      {/* DRIVER'S SECTION */}
      <BoxSection>
        <div className="subsection-header">
          <H11>How Would You Like To Move?</H11>
        </div>

        <PillMiniWrapper
          onClick={() => goToListTop()}
          justify={"center"}
          ref={driverboxRef}
        >
          {/* <NavLink to="/driver-booking"> */}
          <FilterPill>Rent</FilterPill>
          {/* </NavLink> */}

          <NavLink to="/travel-garage">
            <FilterPill active={true}>Chatter</FilterPill>
          </NavLink>

          <NavLink to="/travel-garage">
            <FilterPill>Travel</FilterPill>
          </NavLink>
        </PillMiniWrapper>

        <Slider>
          <SwiperWrapper>
            <Swiper
              modules={[Navigation]}
              // spaceBetween={10}
              //   slidesPerView={1}
              initialSlide={1} // Set the initial slide to be the middle one
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              breakpoints={{
                0: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
                520: {
                  slidesPerView: 1.2,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                840: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <DriverRequestCard
                  imgBg={"#ffa100"}
                  driverTypeImg={chaffeur}
                  buttonText="Request Now"
                  cardContent={
                    "Need a daily drivers? get drivers for a day or more, recuit a driver with experience from Roadlers."
                  }
                  cardHeader={"Chaffeur"}
                  height="250px"
                />
              </SwiperSlide>

              <SwiperSlide>
                <DriverRequestCard
                  imgBg={"#fff"}
                  buttonText="Rent Now"
                  driverTypeImg={vehRental}
                  cardHeader={"Vehicle Rental"}
                  height="360px"
                  cardContent={
                    "Roadlers is your prime destination for vehicle rental, offering both self-drive and chauffeured car rental options. We've got the perfect car for every occasion, whether you're looking for an economical ride or a luxurious SUV."
                  }
                />
              </SwiperSlide>

              <SwiperSlide>
                <DriverRequestCard
                  imgBg={"#fff"}
                  driverTypeImg={travelImg}
                  buttonText="Travel Now"
                  cardHeader={"Travel Vehicle"}
                  cardContent={`Choose from two distinct Roadlers travel experiences  
1. Carpool Passenger Travel: Get a ride with other riders heading to your destination. An affordable choice and a socializing experience.
2. Charter a travel car: Opt for a private vehicle that will take you to wherever you want. Perfect for those who enjoy comfort and flexibility.`}
                  height="425px"
                />
              </SwiperSlide>
            </Swiper>
          </SwiperWrapper>
        </Slider>
      </BoxSection>

      <TravelGarageBox />

      {/* <HelloBox />     */}
      <VideoDiv>
        <YoutubeWrap className="no-scrollbar">
          {showVideo ? (
            <ModelDiv>
              <iframe
                src="https://youtube.com/embed/xaDTcYV9jUA?autoplay=1"
                title="A day with Roadlers🚀__ #nigerian #carrental #lagos #abuja #driver #travel #naija #car roadlers.com"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </ModelDiv>
          ) : (
            <ModelDiv className="">
              <BlackOpacity></BlackOpacity>
              <WatchNow onClick={() => handleWatchNowClick()}>
                <FaPlay style={{ color: "#ffa100", fontSize: 20 }} />
                Watch The Story
              </WatchNow>
              <img src={right} />
            </ModelDiv>
          )}

          {showSecondVideo ? (
            <ModelDiv>
              <iframe
                width="320"
                height="560"
                src="https://www.youtube.com/embed/Yxe_LmxN-Q4?autoplay=1"
                title="A day with Roadlers🚀__ #nigerian #carrental #lagos #abuja #driver #travel #naija #car roadlers.com"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </ModelDiv>
          ) : (
            <ModelDiv className="">
              <BlackOpacity></BlackOpacity>
              <WatchNow onClick={() => handleWatchSecondNowClick()}>
                <FaPlay style={{ color: "#ffa100", fontSize: 20 }} />
                Watch The Story
              </WatchNow>
              <img src={left} />
            </ModelDiv>
          )}
        </YoutubeWrap>
      </VideoDiv>
      <BoxExtra>
        <div className="subsection-header">
          <H14>Start Earning</H14>
          <HorizontalLine />
        </div>

        <FlexStartWrap>
          <SignupCard
            propBg={"#fff"}
            ownerType={"Vehicle Owners"}
            imgProp={carProp}
            contentText={
              "Own a vehicle from 2008 till date? Join the largest community of vehicle owners to start earning today!"
            }
            onClick={() => navigate("/provider-signup")}
          />

          <SignupCard
            ownerType={"Drivers"}
            contentText={
              "Have you been driving for over 3 years now, sign up today to start earning. "
            }
            propBg={"#FCDBA5"}
            onClick={() => navigate("/provider-signup")}
          />
        </FlexStartWrap>
      </BoxExtra>

      <Footer />
    </Page>
  );
};

export default Homepage;
