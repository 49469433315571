import { useMemo } from "react"

export const DOTS = '...'

export const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage
}:any) => {
    const paginationRange = useMemo(() => {
        // Implementation Logic goes here, this returns the total number of pages as an array

        // Calculating the total number of pages possible from totalCount and pagesSize
        const totalPageCount = Math.ceil(totalCount/pageSize)

        

        // Implementing a custom range function
        const range = (start:any, end:any) => {
            const length = end - start + 1;

            return Array.from({length}, (_, index) => index + start)
        }

        // To determine the number of pages that can be displayed on the pagination box i.e. Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5

        /* 
            Case:1 
            If totalPageCount is less than or equal to totalPageNumbers
        */
       if(totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
       }

        // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
        );

        // We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    
        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);
    
            return [...leftRange, DOTS, totalPageCount];
        }

        /*
    	    Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
        
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }
        
        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }

    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
}