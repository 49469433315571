import styled from 'styled-components'

export const AuthDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
min-height:100vh;
margin-bottom: 30px;
`
export const SignupDiv = styled.div`
display:flex;
flex-direction:column;
gap:1.5em;
background:#F6F6F6;
width:80%;
height:250px;
border-radius:7px;
padding:2em;
`
export const Input = styled.div`

background:white;
display:flex;
align-items:center;
border-radius:5px;
overflow:hidden;
box-shadow:2px 2px 10px  #ccc;
width:100%;
`

export const Button = styled.button`
background: #FFA100;
color: #000;
width:100%;
border-radius:30px;
padding:15px 10px;
margin-top:3rem;
width:55%;
outline:none;
border:none;
font-size:1rem;
font-weight:bold;
`
export const DisabledButton = styled.button`
background: #000;
color: #aaa;
width:100%;
border-radius:30px;
padding:15px 10px;
margin-top:3rem;
width:55%;
outline:none;
border:none;
font-size:1rem;
font-weight:bold;
`

export const Flex = styled.div`
display:flex;
gap:.4em;
width:100%;

`



export const P = styled.p`
 font-size: .7rem;
 width:70%;
 text-align:left;
 color:#000;

`
export const H2 = styled.h2`
color:white;
font-weight:normal;
font-size:.7em;
display:flex;
justify-content:space-between;
align-items:center;
margin-top:4em;
margin-bottom:4em;
`
export const SocialBox = styled.div`
background:white;
border-radius:5px;
width:80%;
display:flex;
justify-content:center;
align-items:center;
margin-bottom:1.8em;
padding:5px 15px;
font-size:.7em;
gap:.1rem;
color:#000;

`
export const Box = styled.div`
 font-size: .8rem;
 width:70%;
 line-height:2;
 text-align:center;
 color:${(props: any) => props.theme.white};

`
export const Div = styled.div`
width:100%;
padding:0rem 2rem;
margin-top:50px;
display:flex;
align-items:center;
gap:15px;
`
export const H3 = styled.h3`
 
font-weight:bold;
color:${(props: any) => props.theme.white};
`
export const Icon = styled.div`
color:${(props: any) => props.theme.white};
`