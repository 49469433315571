import styled from "styled-components"
import { ModalOverlay } from "../optionModal/optionmodal.styles"

export const CancelBookngModalOverlay = styled(ModalOverlay) `
    .inner-modal-box {
        height: unset !important;
    }
`

export const AlertModalHead = styled.div `
    h2 {
        color: #000;
        font-size: 18px;
        font-weight: 700;
    }

    @media only screen and (max-width: 454px)  {
        h2 {
            font-size: 14px;
        }
    }
`

export const AlertModalBody = styled.div`
    margin-top: 10px;

    p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 11px;
        }
    }
`

export const AlertModalFooter = styled.div `
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
`

export const YesBtn = styled.div `
    background-color: #E70909;
    border-radius: 9px;
    padding: 10px;
    flex: 45%;
    color: #fff;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    white-space: nowrap;

    @media only screen and (max-width: 454px) {
        font-size: 11px;
        border-radius: 4px;
        padding: 6px 10px;
    }
`

export const NoBtn = styled(YesBtn)`
    background-color: #000;
    color: #fff;
`