import React, { useEffect, useState } from 'react'
import { BarCodeOverall, CollectMoneyTwoWrap, CurrencyWrap, H02, H03, LinkBtnOverall, LinkBtns, LinkWrapper, OverFlowWrap } from './collectmoneytwo.styles'
import { GreyWrap } from '../collectMoneyOne/collectmoneyone.styles'
import { FlexInputWrap } from '../fundWalletThree/fundwalletthree.styles'
import { RWebShare } from "react-web-share";
import ReactCountryFlag from "react-country-flag"
import { ToastContainer, toast } from 'react-toastify';

import barcode from '../../assets/images/png/barcode.png'
import linkImg from '../../assets/images/png/link-img.png'
import creditCard from '../../assets/images/svg/credit-card.svg'
import person from  '../../assets/images/svg/person-icon.svg'
import canada from '../../assets/images/png/canada.png'

import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'
import { useGetPaymentLinkQuery } from '../../services/walletApi'
import { useNavigate } from 'react-router-dom'


const CollectMoneyTwo = ({displaybox, handleShowComponent, collectMoneyData, handleNotify}:any) => {
    const [paymentLinkData, setPaymentLinkData] = useState([])
    const navigate = useNavigate()

    const {data, refetch, isSuccess} = useGetPaymentLinkQuery(collectMoneyData?.paymentlink
        , {refetchOnFocus:true, refetchOnMountOrArgChange:true})

    useEffect(() => {
        refetch()

        if(isSuccess) {
            setPaymentLinkData(data?.data)
        }

    }, [collectMoneyData, data])


    const notifySuccess = (text: any) => toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })

  return (
    <CollectMoneyTwoWrap display={displaybox}>
        <BackBtnWrap top={'-44px'} onClick={() => handleShowComponent('a')}>
            <img src={leftArrow} />
        </BackBtnWrap>  
           
        <BarCodeOverall>
            <img src={barcode} />
        </BarCodeOverall>

        {/* <OverFlowWrap> */}
            <LinkWrapper>
                <img src={linkImg} />
                <div className="right-side">
                    <h3>Your Payment Link For...</h3>
                    <p>2nd May 2023  09:50 PM  </p>
                </div>
            </LinkWrapper>

            <GreyWrap>
                <FlexInputWrap>
                    <H03>{collectMoneyData?.payer_name}</H03>
                    <img src={person} />
                </FlexInputWrap>

                <FlexInputWrap>
                    <H02>{collectMoneyData?.currency} {(collectMoneyData?.amount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}</H02>
                    <CurrencyWrap>
                        <ReactCountryFlag countryCode={"NG"} />
                        <p>{collectMoneyData?.currency}</p>
                    </CurrencyWrap>
                </FlexInputWrap>

                <FlexInputWrap>
                    <p className='collect-money'>{collectMoneyData?.description}</p>
                    <img src={creditCard} />
                </FlexInputWrap>

                <FlexInputWrap>
                    <p className='collect-money'>{`www.roadlers.com/payment-link/${collectMoneyData?.paymentlink}`  }</p>
                </FlexInputWrap>
            </GreyWrap>

            <LinkBtnOverall>
            <RWebShare
                data={{
                text: `Payment link from ${collectMoneyData?.receiver_name}`,
                url: `https://www.roadlers.com/payment-link/${collectMoneyData?.paymentlink}`  ,
                title: "Roadlers Payment Link",
                }}
                // onClick={() => console.log("shared successfully!")}
            >
                <LinkBtns txt={'#09051C'} bg={'#FFD58C'}>
                    Share Link
                </LinkBtns>
            </RWebShare>


                <LinkBtns txt={'#fff'} bg={'#09051C'} onClick={() => 
                    {
                        navigator.clipboard.writeText(`https://www.roadlers.com/payment-link/${collectMoneyData?.paymentlink}`);

                        handleNotify()
                    } } >
                    Copy Link
                </LinkBtns>
            </LinkBtnOverall>
        {/* </OverFlowWrap> */}
    </CollectMoneyTwoWrap>
  )
}

export default CollectMoneyTwo