import React, { FC, useEffect, useRef, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

import {
  H3,
  P,
  DOCDIV,
  DOC1,
  DOC2,
  DOC3,
  DOC,
  P2,
  H4,
  Skip,
  FlexDiv,
  UploadButton,
  DisabledButton,
} from "./vehicleDoc.styles";
import Footer from "../../../components/footer";
import ownership from "../../../assets/images/png/ownership.png";
import license from "../../../assets/images/png/license.png";
import worthiness from "../../../assets/images/png/worthiness.png";
import pdff from "../../../assets/images/png/pdff.png";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../../store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../../services/providerApi";
import { setRegisteredVehicle } from "../../../slices/provider";

const VehicleDoc: FC = () => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  const proofInputRef = useRef<HTMLInputElement>(null);
  const licenseInputRef = useRef<HTMLInputElement>(null);
  const worthinessInputRef = useRef<HTMLInputElement>(null);

  const [proofImage, setProofImage] = useState<any>();
  const [licenseImage, setLicenseImage] = useState<any>();
  const [worthinessImage, setWorthinessImage] = useState<any>();

  // const [proofYear, setProofYear] = useState<any>();
  const [licenseYear, setLicenseYear] = useState<any>();
  const [worthinessYear, setWorthinessYear] = useState<any>();
  // const [proofMonth, setProofMonth] = useState<any>();
  const [licenseMonth, setLicenseMonth] = useState<any>();
  const [worthinessMonth, setWorthinessMonth] = useState<any>();

  const [proofImageClicked, setProofImageClicked] = useState<boolean>(false);
  const [licenseImageClicked, setLicenseImageClicked] =
    useState<boolean>(false);
  const [worthinessImageClicked, setWorthinessImageClicked] =
    useState<boolean>(false);

  const [proofImagePrev, setProofImagePrev] = useState<any>();
  const [licenseImagePrev, seticenseImagePrev] = useState<any>();
  const [worthinessImagePrev, setWorthinessImagePrev] = useState<any>();

  const [proofExt, setProofExt] = React.useState("");
  const [licenseExt, setLicenseExt] = React.useState("");
  const [worthinessExt, setWorthinessExt] = React.useState("");

  const [btnLoad, setBtnLoad] = useState(false);

  //@ts-ignore

  const onChangeProof = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setProofImagePrev(file);
      let reader = new FileReader();
      //@ts-ignore
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        if (e.target != null) {
          setProofImagePrev(e.target.result);
        }
        setProofImage(file[0]);
        //@ts-ignore
        let Doc = file[0].name;
        let arr: any = Doc.split(".").pop();
        setProofExt(arr);
        setProofImageClicked(true);
        setWorthinessImageClicked(false);
        setLicenseImageClicked(false);

        // const base64String = reader.result.split(',')[1];
        // setProofImage(base64String)
      };
    }
  };

  const onChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      seticenseImagePrev(file);
      let reader = new FileReader();
      //@ts-ignore
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        if (e.target != null) {
          seticenseImagePrev(e.target.result);
        }
        setLicenseImage(file[0]);
        setProofImageClicked(false);
        setWorthinessImageClicked(false);
        setLicenseImageClicked(true);
        //@ts-ignore
        let Doc = file[0].name;
        let arr: any = Doc.split(".").pop();
        setLicenseExt(arr);
      };
    }
  };

  const onChangeWorthiness = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setWorthinessImagePrev(file);
      let reader = new FileReader();
      //@ts-ignore
      reader.readAsDataURL(file[0]);
      reader.onload = (e) => {
        if (e.target != null) {
          setWorthinessImagePrev(e.target.result);
        }
        setWorthinessImage(file[0]);
        setProofImageClicked(false);
        setLicenseImageClicked(false);
        setWorthinessImageClicked(true);
        //@ts-ignore
        let Doc = file[0].name;
        let arr: any = Doc.split(".").pop();
        setWorthinessExt(arr);
      };
    }
  };

  const token = useAppSelector((state: any) => state?.auth?.auth?.token);
  const vehicle = useAppSelector((state: any) => state?.provider.vehicle);
  const vehicleId: string = vehicle[0]?.id;
  const userId: string = useAppSelector((state: any) => state?.auth?.auth?.id);

  const handleUpload = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setBtnLoad(true);
    try {
      const formData1 = new FormData();
      formData1.append("user_id", userId);
      formData1.append("vehicle_id", vehicleId);
      formData1.append("file", proofImage);
      formData1.append("doc_type", "proof_of_ownership");

      const formData2 = new FormData();
      formData2.append("user_id", userId);
      formData2.append("vehicle_id", vehicleId);
      formData2.append("file", licenseImage);
      formData2.append("doc_type", "vehicle_licence");
      formData2.append("year", licenseYear);
      formData2.append("month", licenseMonth);

      const formData3 = new FormData();
      formData3.append("user_id", userId);
      formData3.append("vehicle_id", vehicleId);
      formData3.append("file", worthinessImage);
      formData3.append("doc_type", "road-worthiness");
      formData3.append("year", worthinessYear);
      formData3.append("month", worthinessMonth);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Add the authorization header
        },
      };

      const requests = [
        axios.post(`${baseUrl}proof-of-ownership`, formData1, config),
        axios.post(`${baseUrl}vehicle-licence`, formData2, config),
        axios.post(`${baseUrl}road-worthiness`, formData3, config),
      ];

      const responses = await Promise.all(requests);
      if (responses) {
        setBtnLoad(false);
        notifySuccess("Successfully Uploaded, You will be redirected shortly");

        setTimeout(() => {
          navigate("/profile-setup"); // Redirect to "/profile-setup" after 3 seconds
        }, 3000);
      }
    } catch (error) {
      setBtnLoad(false);

      console.error(error);
    }
  };

  // when the skip button in clicked, bypass vehicle document registration which marks a vehicle unverified
  const handleSkip = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    navigate("/profile-setup");
  };

  let currentYear: any = new Date().getFullYear();

  //@ts-ignore
  const handleLicenseYear = (event) => {
    setLicenseYear(event.target.value);
  };

  //@ts-ignore
  const handleLicenseMonth = (event) => {
    setLicenseMonth(event.target.value);
  };

  //@ts-ignore
  const handleWorthinessYear = (event) => {
    setWorthinessYear(event.target.value);
  };

  //@ts-ignore
  const handleWorthinessMonth = (event) => {
    setWorthinessMonth(event.target.value);
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return (
    <>
      <ToastContainer />

      <FlexDiv>
        <H3>Vehicle Documents</H3>
        <Skip onClick={handleSkip}>Skip</Skip>
      </FlexDiv>
      <P>Upload image or PDF of document to verify your vehicle </P>

      <DOCDIV>
        <DOC
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            setProofImageClicked(true);
            setLicenseImageClicked(false);
            setWorthinessImageClicked(false);
          }}
        >
          <DOC1>
            {proofImage ? (
              proofExt !== "pdf" ? (
                <img
                  src={proofImagePrev}
                  alt=""
                  style={{ objectFit: "cover", width: "100%" }}
                />
              ) : (
                <img src={pdff} alt="" width={80} />
              )
            ) : (
              <img
                src={ownership}
                alt=""
                width={80}
                //@ts-ignore
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  if (proofInputRef.current != null) {
                    proofInputRef.current.click();
                  }
                }}
              />
            )}

            {proofImagePrev ? (
              <span
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  if (proofInputRef.current != null) {
                    proofInputRef.current.click();
                  }
                }}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "5px",
                  zIndex: 1000,
                  color: "#ffa100",
                  background: "#000",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiOutlineClose style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span
                style={{ position: "absolute", right: "13px", top: "10px" }}
              >
                &uarr;
              </span>
            )}
          </DOC1>
          <P2>Proof of ownership</P2>
        </DOC>
        <DOC
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            setLicenseImageClicked(true);
            setProofImageClicked(false);
            setWorthinessImageClicked(false);
          }}
        >
          <DOC2>
            {licenseImage ? (
              licenseExt !== "pdf" ? (
                <img
                  src={licenseImagePrev}
                  alt=""
                  style={{ objectFit: "cover", width: "100%" }}
                />
              ) : (
                <img src={pdff} alt="" width={80} />
              )
            ) : (
              <img
                src={license}
                alt=""
                width={80}
                //@ts-ignore
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  if (licenseInputRef.current != null) {
                    licenseInputRef.current.click();
                  }
                }}
              />
            )}

            {licenseImagePrev ? (
              <span
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  if (licenseInputRef.current != null) {
                    licenseInputRef.current.click();
                  }
                }}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "5px",
                  zIndex: 1000,
                  color: "#ffa100",
                  background: "#000",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiOutlineClose style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span
                style={{ position: "absolute", right: "13px", top: "10px" }}
              >
                &uarr;
              </span>
            )}
          </DOC2>
          <P2>Vehicle License</P2>
        </DOC>
        <DOC
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            setLicenseImageClicked(false);
            setProofImageClicked(false);
            setWorthinessImageClicked(true);
          }}
        >
          <DOC3>
            {worthinessImage ? (
              worthinessExt !== "pdf" ? (
                <img
                  src={worthinessImagePrev}
                  alt=""
                  style={{ objectFit: "cover", width: "100%" }}
                />
              ) : (
                <img src={pdff} alt="" width={80} />
              )
            ) : (
              <img
                src={license}
                alt=""
                width={80}
                //@ts-ignore
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  if (worthinessInputRef.current != null) {
                    worthinessInputRef.current.click();
                  }
                }}
              />
            )}

            {worthinessImagePrev ? (
              <span
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  if (worthinessInputRef.current != null) {
                    worthinessInputRef.current.click();
                  }
                }}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "5px",
                  zIndex: 1000,
                  color: "#ffa100",
                  background: "#000",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiOutlineClose style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span
                style={{ position: "absolute", right: "13px", top: "10px" }}
              >
                &uarr;
              </span>
            )}
          </DOC3>
          <P2>Road Worthiness</P2>
          <P2 style={{ color: "#EBAF4A", marginTop: "-5px" }}>(Optional)</P2>
        </DOC>
      </DOCDIV>

      {/* license expiry date */}
      {licenseImageClicked && (
        <>
          <H4>Document Expiration Date</H4>
          <DOCDIV>
            <div
              style={{
                width: "50%",
                borderRadius: "7px",
                border: "1px solid #fff",
              }}
            >
              <select
                name=""
                id=""
                value={licenseMonth}
                onChange={handleLicenseMonth}
                style={{
                  borderRadius: "6px",
                  background: "#1D1C1C",
                  color: "#fff",
                  width: "95%",
                  outline: "none",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: ".7em",
                }}
              >
                <option value="">Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <div
              style={{
                width: "50%",
                borderRadius: "7px",
                border: "1px solid #fff",
              }}
            >
              <select
                name=""
                id=""
                value={licenseYear}
                onChange={handleLicenseYear}
                style={{
                  borderRadius: "6px",
                  background: "#1D1C1C",
                  color: "#fff",
                  width: "95%",
                  outline: "none",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: ".7em",
                }}
              >
                <option value="">Year </option>
                <option value={currentYear}>{currentYear} </option>
                <option value={currentYear + 1}>{currentYear + 1} </option>
                <option value={currentYear + 2}>{currentYear + 2} </option>
                <option value={currentYear + 3}>{currentYear + 3} </option>
                <option value={currentYear + 4}>{currentYear + 4} </option>
                <option value={currentYear + 5}>{currentYear + 5} </option>
                <option value={currentYear + 6}>{currentYear + 6} </option>
                <option value={currentYear + 7}>{currentYear + 7} </option>
                <option value={currentYear + 8}>{currentYear + 8} </option>
                <option value={currentYear + 9}>{currentYear + 9} </option>
                <option value={currentYear + 10}>{currentYear + 10} </option>
              </select>
            </div>
          </DOCDIV>
        </>
      )}

      {/* road worthiness expiry date */}

      {worthinessImageClicked && (
        <>
          <H4>Document Expiration Date</H4>
          <DOCDIV>
            <div
              style={{
                width: "50%",
                borderRadius: "7px",
                border: "1px solid #fff",
              }}
            >
              <select
                name=""
                id=""
                value={worthinessMonth}
                onChange={handleWorthinessMonth}
                style={{
                  borderRadius: "6px",
                  background: "#1D1C1C",
                  color: "#fff",
                  width: "95%",
                  outline: "none",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: ".7em",
                }}
              >
                <option value="">Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <div
              style={{
                width: "50%",
                borderRadius: "7px",
                border: "1px solid #fff",
              }}
            >
              <select
                name=""
                id=""
                value={worthinessYear}
                onChange={handleWorthinessYear}
                style={{
                  borderRadius: "6px",
                  background: "#1D1C1C",
                  color: "#fff",
                  width: "95%",
                  outline: "none",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: ".7em",
                }}
              >
                <option value="">Year </option>
                <option value={currentYear}>{currentYear} </option>
                <option value={currentYear + 1}>{currentYear + 1} </option>
                <option value={currentYear + 2}>{currentYear + 2} </option>
                <option value={currentYear + 3}>{currentYear + 3} </option>
                <option value={currentYear + 4}>{currentYear + 4} </option>
                <option value={currentYear + 5}>{currentYear + 5} </option>
                <option value={currentYear + 6}>{currentYear + 6} </option>
                <option value={currentYear + 7}>{currentYear + 7} </option>
                <option value={currentYear + 8}>{currentYear + 8} </option>
                <option value={currentYear + 9}>{currentYear + 9} </option>
                <option value={currentYear + 10}>{currentYear + 10} </option>
              </select>
            </div>
          </DOCDIV>
        </>
      )}

      <>
        {btnLoad ? (
          <DisabledButton>Please Wait...</DisabledButton>
        ) : (
          <>
            {proofImage === undefined ||
            licenseImage === undefined ||
            licenseMonth === undefined ||
            licenseYear === undefined ? (
              <DisabledButton>
                <h4>Upload Document</h4>
              </DisabledButton>
            ) : (
              <UploadButton onClick={handleUpload}>
                <h4>Upload Document</h4>
              </UploadButton>
            )}
          </>
        )}
      </>

      <br />
      <br />
      <br />

      <input
        type="file"
        style={{ display: "none" }}
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        ref={proofInputRef}
        onChange={onChangeProof}
      />
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        ref={licenseInputRef}
        onChange={onChangeLicense}
      />
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        ref={worthinessInputRef}
        onChange={onChangeWorthiness}
      />
    </>
  );
};

export default VehicleDoc;
