import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import whiteMark from '../../../assets/images/png/whiteMark.png'

import {
    VerifyButton, ShadowDiv, Label, Button, InfoDiv, P, DisabledButton
} from './firstScreen.styles';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useUploadInsuranceMutation } from '../../../services/providerApi';
import { useAppSelector } from '../../../store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
const SetupInsurance: FC = () => {
    const navigate = useNavigate()

    const [isTenPercentInfo, setIsTenPercentInfo] = useState<boolean>(false);
    const [isTwelvePercentInfo, setIsTwelvePercentInfo] = useState<boolean>(false);
    const [percent, setPercent] = useState<number>(0);
    const [btnLoad, setBtnLoad] = useState<boolean>(false)


    const handlePercentOption = (option: string): void => {
        if (option === 'TenPercent') {
            setIsTenPercentInfo(prev => !prev)
            setIsTwelvePercentInfo(false)
        }
        if (option === 'TwelvePercent') {
            setIsTwelvePercentInfo(prev => !prev)
            setIsTenPercentInfo(false)

        }

    }
    const handlePercent = (value: number): void => {
        setPercent(value)
        console.log('value', value)
        if (value === 12) {
            setIsTwelvePercentInfo(true)
            setIsTenPercentInfo(false)
        } else if (value === 10) {
            setIsTwelvePercentInfo(false)
            setIsTenPercentInfo(true)
        }

    }



    const [setupInsurance, { data, isLoading, isSuccess, isError, error }] = useUploadInsuranceMutation()

    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            setBtnLoad(false)
            // navigate('/provider-dashboard')
            notifySuccess('Insurance Document Submitted')
            setTimeout(() => {
                navigate('/provider-dashboard'); // Redirect to "/profile-setup" after 3 seconds
            }, 2000);
        }


        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
            }
            setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data])

    const user = useAppSelector((state: any) => state?.auth?.auth)
    const vehicleId = useAppSelector((state: any) => state?.provider?.vehicle[0]?.id)

    console.log('percent', percent)
    const handleFinish = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let formData: any = new FormData()
        formData.append('user_id', user?.id)
        formData.append('vehicle_id', vehicleId)
        formData.append('insurance_percent', percent)



        setupInsurance(formData)

    }
    const alertError = () => {
        notifyError('Please Select any Insurance Option')
    }



                        

    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    
    return (
        <>
            <ToastContainer />
            {/* <P>Select insurance benefit as service fee</P>
            <ShadowDiv>
                <Button onClick={() => handlePercent(10)} style={percent === 10 ? { background: '#ffa100' } : {}}></Button>
                <Label>10% fee on every transaction </Label>

                <VerifyButton onClick={() => handlePercentOption('TenPercent')}>
                    <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
                </VerifyButton>


            </ShadowDiv>

            {isTenPercentInfo &&
                <InfoDiv>
                    <ul style={{ color: '#000', fontSize: 13, fontWeight: 600 }}>
                        <li style={{ marginBottom: 12 }}>Cover shall be granted based on the following: fire, accidental damage, and 3rd party liability.
                        </li>
                        <li style={{ marginBottom: 12 }}>
                            Cover shall only be active when the vehicle is rented through Roadlers.
                        </li>
                        <li style={{ marginBottom: 12 }}>
                            Renters will bear an excess of N10,000 or 10% of any claim, whichever is higher.
                        </li>
                    </ul>
                </InfoDiv>
            }


            <ShadowDiv>
                <Button onClick={() => handlePercent(12)} style={percent === 12 ? { background: '#ffa100' } : {}}></Button>
                <Label>12% fee on every transaction </Label>

                <VerifyButton onClick={() => handlePercentOption('TwelvePercent')}>
                    <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
                </VerifyButton>


            </ShadowDiv>

            {
                isTwelvePercentInfo &&
                <InfoDiv>
                    <ul style={{ color: '#000', fontSize: 13, fontWeight: 600 }}>
                        <li style={{ marginBottom: 12 }}>
                            Cover shall be granted based on the following: fire, accidental damage, and 3rd party liability.

                        </li>
                        <li style={{ marginBottom: 12 }}>
                            Cover shall only be active when the vehicle is rented through Roadlers.
                        </li>
                        <li style={{ marginBottom: 12 }}>
                            Renters will bear an excess of N10,000 or 10% of any claim, whichever is higher.

                        </li>
                        <li style={{ marginBottom: 12 }}>
                            Medical benefit: - N50,000

                        </li>
                        <li style={{ marginBottom: 12 }}>
                            Death/ Permanent Disability - N100,000

                        </li>


                    </ul>
                </InfoDiv>
            } */}


            {btnLoad ?
                <DisabledButton >
                    <h4 style={{ color: 'gray' }}>Please Wait...</h4>
                </DisabledButton> :
                <>

                    
                        <DisabledButton onClick={handleFinish}>
                            <h4 style={{ fontSize: 20 }}>OK</h4>
                            <img src={whiteMark} alt="" width={28} height={23} />
                        </DisabledButton>



                </>
            }
        </>

    )
}

export default SetupInsurance