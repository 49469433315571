import { colors } from './../../assets/styles/Theme';
import styled from 'styled-components'

export const CancelWrap = styled.div `
    display: flex;
    justify-content: flex-end;
    
    & img {
        width: 30px;
    }

    @media only screen and (max-width: 454px)  {
        img {
            width: 20px;
        }
    }
`

export const HeaderContent = styled.div `
    margin-top: 20px;
    color: #fff;
    font-size: 14px;

    @media only screen and (max-width: 454px)  {
        font-size: 12px;
        margin-top: 15px;
    }
`

export const ParcelsTypeContainer = styled.div`
    height: unset;
    overflow-y: scroll;

    @media only screen and (max-width: 454px) {
        height: unset;
    }
`

export const ParcelOverall = styled.div`
    margin-top: 40px;
    position: relative;
    border-radius: 12px;

    @media only screen and (max-width: 454px)  {
        margin-top: 20px;
    }

`

export const ImgWrap = styled.div `
    border-radius: 12px;

    & img {
        border-radius: 12px;
        object-fit: cover;
        width: 100%;
        height: 210px;
    }

    @media only screen and (max-width: 454px) {
        img {
            height: 150px;
        }
    }
`

export const ChoiceBox = styled.div `
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 20px;
    gap: 30px;

    & p {
        margin: 0;
        color: #868686;
        font-size: 16px;
    }

    @media only screen and (max-width: 454px)  {
        p {
            font-size: 11px;
        }
    }
`

export const SaveBtnWrap = styled.div `
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;

    @media only screen and (max-width: 454px)  {
        margin-top: 15px;
    }
`