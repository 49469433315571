import styled from 'styled-components'




export const Add = styled.button`
background: #FFD58C;
color: #000;
padding:2px 2px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
height:20px;
width:20px;
display:flex;
align-items:center;
justify-content:center;
`
export const AddSpan = styled.span`
background: #09051C;
display:flex;
align-items:center;
gap:10px;
padding:5px ;
justify-content:center;
`
// export const Text = styled.span`
// color:#fff;
// font-size:12px;
// `





// export const Label = styled.label`
// background: #0AB249;
// font-weight:bold;
// font-size:13px;
// margin-left:15px;
// padding:5px 10px;
// border-radius:5px;
// top:55px;
// `
// export const P = styled.p`
// color:#fff;
// font-size:9px;
// position:absolute;
// font-weight:800;

// `








export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:9px;
gap:5px;
padding:8px 10px;
position:relative;
`

export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:40%;
justify-content:space-around;
padding:8px 0px;
border-radius:5px;
position:relative;

`

export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:6px 4px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
width:34%;
position:relative;
display:flex;
align-items:center;
justify-content:center;
gap:5px;
`
export const DISCOUNT = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:0.3em;
border-radius:5px;
font-size:11px;
position:relative;

`


export const FLEXDIV = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
margin-top:50px;
position:relative;

`
export const TITLE = styled.div`
display:flex;
gap:10px;
position:relative;
`
export const P = styled.p`
color:#fff;
font-size:8.5px;
position:absolute;
font-weight:normal;

`



export const Wrapper = styled.div`



`
export const BlackOpacity = styled.div`



`

export const Text = styled.div`
font-size:16px;
margin-top:1px;
`

export const Texts = styled.p`
font-size:12px;
top:-30px;
left:40px;
position:absolute;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
position:absolute;
width:90%;
z-index:10000;
top:200px;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:24px;
width:100%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`
background: #0AB249;
font-size:13px;
padding:5px;
border-radius:5px;
text-center
font-weight:bold;
`
export const Input = styled.input`
width: 40%; 
outline: none; 
border: none; 
color: #fff; 
background: transparent;
`
export const ButtonArea = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:100%;
`
export const SaveButton = styled.button`
background: linear-gradient(0deg, #868686, #D9D9D9);
padding:7px 10px;
font-size:15px;
font-weight:bold;
border:none;
border-radius:5px;
`
export const DoneButton = styled.button`
background: #ffa100;
padding:7px 10px;
font-size:15px;
font-weight:bold;
border:none;
border-radius:5px;

`

export const LabelDiv = styled.div`
display:flex;
gap:10px;
align-items:center;
margin-top:70px;
`
