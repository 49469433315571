import React, { useEffect, useState } from "react";
import "./styles/BlogHome.css";

import banner from "../../../assets/images/jpg/blogbanner.jpg";
import car from "../../../assets/images/svg/car-filter.svg";
import driver from "../../../assets/images/svg/driver-filter.svg";
import travel from "../../../assets/images/svg/travel-filter.svg";
import owner from "../../../assets/images/svg/owner-filter.svg";

import Footer from "../../../components/footer";
import Header from "../../../components/header";
import BlogCard from "../../../components/blogcard";

import { useGetBlogListQuery } from "../../../services/blogApi";
import Loader from "../../../components/loader";
import { BlogBanner, BlogBox, FilterBox, LoadMore, LoadMoreDiv } from "./index.styles";

const BlogHome = () => {
  const { data, isFetching, refetch } = useGetBlogListQuery();

  // USESTATE
  const [blogListData, setBlogListData] = useState<any[]>();
  const [visibleList, setVisibleList] = useState(4);
  const [categoryType, setCategoryType] = useState("");
  const [searchBlogCategory, setSearchBlogCategory] = useState<any>(
    categoryType || ""
  );
  const [findCategory, setFindCategory] = useState<any[]>();
  const [preLoad, setPreLoad] = useState(false);

  useEffect(() => {
    if (isFetching) {
      setPreLoad(true);
    } else {
      setPreLoad(false);
    }
  }, [data]);

  useEffect(() => {
    refetch();
    setBlogListData(data?.data);
    const filteredData = blogListData
      ?.filter((item) =>
        item?.category
          ?.toLowerCase()
          .includes(searchBlogCategory?.toLowerCase())
      )
      ?.reverse();

    setFindCategory(filteredData);
  }, [blogListData, categoryType, refetch, data]);

  const handleShowMore = () => {
    setVisibleList((prevValue) => prevValue + 4);
  };

  const handlerefetch = () => {
    refetch();
  };

  return preLoad ? (
    <Loader />
  ) : (
    <div className="homepage">
      <Header themes={true} />

      <BlogBanner>
        <img src={banner} alt="blog banner" className="img-banner" />
      </BlogBanner>

      <FilterBox>
        <button
          className={categoryType === "cars" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("cars");
            setSearchBlogCategory("cars");
          }}
        >
          <img src={car} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Cars</p>
        </button>

        <button
          className={categoryType === "drive" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("drive");
            setSearchBlogCategory("drive");
          }}
        >
          <img src={driver} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Drivers</p>
        </button>

        <button
          className={categoryType === "travel" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("travel");
            setSearchBlogCategory("travel");
          }}
        >
          <img src={travel} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Travel</p>
        </button>

        <button
          className={categoryType === "owner" ? "activeCategory" : ""}
          onClick={() => {
            setCategoryType("owner");
            setSearchBlogCategory("owner");
          }}
        >
          <img src={owner} alt="" className="img-icon" />
          <p style={{color:"#000"}}>Ownership</p>
        </button>
      </FilterBox>

      {!blogListData?.length ? (
        <div className="go-to-upload">
          <h2>No Blog yet </h2>
        </div>
      ) : (
        <>
          <br />
          {findCategory?.slice(0, visibleList).map((item, id) => {
            return (
              <BlogBox key={id}>
                <BlogCard
                  title={item.title}
                  blogContent={item.content}
                  readMinute={item.min_read}
                  numLikes={item.likes}
                  image={item.post_image}
                  bloglink={item.link}
                  buttonText="Read More"
                  pagename={"blogpost"}
                  id={item.id}
                />
              </BlogBox>
            );
          })}
        </>
      )}

      <LoadMoreDiv>
        {!blogListData?.length || blogListData?.length <= visibleList ? (
          ""
        ) : (
          <LoadMore onClick={handleShowMore}>Load More</LoadMore>
        )}
      </LoadMoreDiv>
      {/* </div> */}

      {/* <Footer /> */}
    </div>
  );
};

export default BlogHome;
