import React from 'react'
import './littleloader.css'

import logo from "../../assets/images/svg/roadlers-logo-no-bg.svg"

const LittleLoader = () => {
  return (
    <div className='little-loader-wrapper'>
        <div className="lds-ring">
            {/* <img src={logo}  alt="roadlers logo" className='embed-logo'/> */}
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default LittleLoader