import styled from "styled-components";

export const Card = styled.div`
  min-height: 600px;
  /* width: 320px; */
  border-radius: 16px;
  position: relative;
`;

export const ImgCard = styled.div`
  border-radius: 16px;
  height: 200px;
  width: 100%;
  border: solid 1px black;
  /* background-color: ${(props: any) => props.imgBg}; */
`;

export const DriverImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  /* border: none; */
`;

export const TextCard = styled.div`
  position: absolute;
  padding: 0px 10px 30px 10px;
  width: 99.5%;
  left: 0;
  top: 145px;
  height: ${(props: any) => props.height};
  border: solid 1px #ebaf4a;
  border-radius: 16px;
  background-color: ${(props: any) => props.theme.driverCardBg};
`;
export const TextCardInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 5px 18px;
  & .access-offer-wrap {
    a {
      text-decoration: none;
      color: unset;
    }
  }
`;

export const H1 = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-align: left;
  margin: 10px 0px;

  @media only screen and (max-width: 484px) {
    font-size: 17px;
  }
`;

export const P = styled.p`
  font-size: 13.5px;
  color: #fff;
  margin-top: 20px;
  font-weight: 500;
  text-align: justify;
  line-height: 1.95;
  @media only screen and (max-width: 484px) {
    font-size: 13px;
  }
`;

export const CardBtn = styled.div`
  border: solid 1px white;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  margin-top: 20px;
  padding: 10px;
  width: 150px;
  display: flex;
  justify-content: center;
  font-size: 13px;

  @media only screen and (max-width: 484px) {
    font-size: 12px;
  }
`;
