import styled from 'styled-components'


export const H4 = styled.h4`
color:#fff;
margin-top:50px;
`
export const FilterBox = styled.div`
display:flex;
margin-top:40px;
gap:8px;
`
export const DateRangeModal = styled.div`
display:flex;
align-items:center;
justify-content:center;
background: rgba(204, 204, 204, 0.96);
height:100vh;
width:100%;
position: fixed;
top:0px;
z-index:100;
left:0px;
bottom:0px;
`
export const WhiteModal = styled.div`
background:#fff;
display:flex;
flex-direction:column;
align-items:flex-start;
justify-content:center;

padding:20px;
`

export const CalendarFilterButton = styled.button`
background:#ffa100;
outline:none;
border:none;
padding:7px 12px;
border-radius:10px;
font-size:15px;
font-weight:700;
`
export const FilterIcon = styled.div`
display:flex;
justify-content:center;
align-items:center;
background:#ffc561;
border-radius:8px;
padding:5px;
width:8%;

@media only screen and (max-width: 450px)  {
  width:15%;
}
`
export const SearchBoxDiv = styled.div`
display:flex;
background:#3a3937;
align-items:center;
border-radius:10px;
padding:9px;
width:84%;
@media only screen and (max-width: 450px)  {
  width:70%;
padding:8px 5px;

}

`
export const SearchBox = styled.input`
display:flex;
background:#3a3937;
outline:none;
border:none;
color:#fff;
font-size:15px;
width:100%;
  @media only screen and (max-width: 450px)  {
    ::placeholder {
        font-size:11px;
    
      }
}
`
export const FilterDate = styled.div`
background:#fff;
border-radius:8px;
padding:5px;
display:flex;
align-items:center;
justify-content:center;
width:8%;

@media only screen and (max-width: 450px)  {
  width:15%;
}
`




