import styled from 'styled-components'

export const WithdrawWrap = styled.div `
    display: ${(props:any) => props.display};
`
export const PaddingWrap = styled.div`
    padding: 0 13px;

    @media only screen and (max-width: 454px)  {
        padding: 0px;
    }
`

export const H22 = styled.h2 `
    font-size: 17px;
    font-weight: 700;
    color: #000;
    margin: 40px 20px;
    margin-bottom: 0px;

    @media only screen and (max-width: 454px)  {
        font-size: 13px;
    }
` 

export const SaveBankOverall = styled.div `
    display: flex;
    justify-content: flex-end;
`

export const SaveBankBox = styled.div `
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: center;
    background-color: #fff;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);

    & p {
        margin: 0;
        font-size: 11px;
        font-weight: 500;
        color: #868686;
    }
`

export const WithdrawError = styled.div `
    position: absolute;
    top: 60px;
    left: 0;
    color: #D52B1E;
    font-size: 12px;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 454px)  {
        font-size: 9px;
        top: 48px;
    }
`