import styled from 'styled-components'
import { TabsContainer } from '../tabComponent/tabcomponent.styles'

export const HistoryCardWrap = styled(TabsContainer) `
    margin-bottom: 20px;
    
    & .inner-history-card-wrap {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

export const LeftHistoryBox = styled.div `
    flex: 0.3;
    & p {
        color: #000;
        font-weight: 700;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
    }

    & .date-time-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        white-space: nowrap;
    }

    & h4 {
        color: #868686;
        margin: 0;
        font-weight: 700;
        font-size: 13px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 9px;
        }

        h4 {
            font-size: 9px;
        }
    }
`

export const RightHistoryBox = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0.6;

    & img {
        width: 20px;
    }

    @media only screen and (max-width: 454px) {
        img {
            width: 14px;
        }
    }
`

export const AmountTransfer = styled.div `
    color: ${(props:any) => props.type === 'credit' ? '#128E40' : '#E70909'};
    font-size: 14px;
    white-space: nowrap;
    font-weight: 700;

    p {
        color: ${(props:any) => props.type === 'credit' ? '#128E40' : '#E70909'};
        font-size: 14px;
        white-space: nowrap;
        font-weight: 700;
        margin: 0;
    }

    @media only screen and (max-width: 454px) {
        font-size: 10px;

        p {
            font-size: 10px
        }
    }
`