import { FlexInputWrap } from './../fundWalletThree/fundwalletthree.styles';
import styled from 'styled-components'

export const OTPWrap = styled.div `
    display: ${(props:any) => props.display};
    position: relative;
`

export const OTPInputWrap = styled(FlexInputWrap) `
    margin-top: 40px;
    & input {
        font-size: 16px;
        &::placeholder {
            color: #BBBABA;
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 454px)  {
        input {
            font-size: 12px;
        }
    }
`

export const OTPinfoWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    margin-top: 10px;
    padding: 0 10px;

    p {
        font-size: 9px;
        font-weight:400;
        color: #868686;
        margin: 0;
    }

    h5 {
        font-size: 9px;
        font-weight: 600;
        margin: 0;
        color: #868686;
    }
`