import React, { useState } from "react";
import "./styles/Header.css";
import {
  CancelMenu,
  HeaderOverlay,
  Li,
  MenuBar,
  RoadlerHeader,
  Ul,
} from "./Header.styles";
import ToggleBar from "../toggleBar";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { setTheme } from "../../slices/theme";
import { Icons } from "../../pages/user/Homepage/homepage.styles";
import { NavLink, useNavigate } from "react-router-dom";

import logo from "../../assets/images/png/logo.png";
import profileIcon from "../../assets/images/png/user-icon.png";
import cartIcon from "../../assets/images/png/cartIcon.png";
import giftIcon from "../../assets/images/svg/gift-icon-header.svg";
import cancel from "../../assets/images/svg/cancel-yellow.svg";
import home from "../../assets/images/svg/home-link.svg";
import vehicle from "../../assets/images/svg/vehicle-link.svg";
import driver from "../../assets/images/svg/driver-link.svg";
import delivery from "../../assets/images/svg/delivery-link.svg";
import promotion from "../../assets/images/svg/promotion-link.svg";
import blog from "../../assets/images/svg/blog-link.svg";
import user from "../../assets/images/svg/user-link.svg";
import partner from "../../assets/images/svg/partner-link.svg";
import { clearAuth, setRole } from "../../slices/auth";
import { BsCartPlus } from "react-icons/bs";

const Header = ({ themes }: any) => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cart } = useAppSelector<any>((store: RootState) => store?.cart);
  const { theme } = useAppSelector<any>((store: RootState) => store?.theme);
  const { token = "" } =
    useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};

  const user_role =
    useAppSelector<any>((store: RootState) => store?.auth?.user_role) || "";

  const [menuShow, setMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenu = () => {
    setMenuShow(!menuShow);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const themeToggle = () => {
    theme === "dark" ? dispatch(setTheme("light")) : dispatch(setTheme("dark"));
  };

  const handleLogout = () => {
    dispatch(clearAuth({}));
    dispatch(setRole(""));

    localStorage.clear();
    navigate("/login");
  };

  return (
    <RoadlerHeader>
      <HeaderOverlay
        showMenu={menuShow}
        onClick={() => {
          setMenuShow(false);
        }}
      />
      <nav>
        <div className="logo-wrapper">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>

        <Ul>
          <Li>
            <NavLink to="/">Home</NavLink>
          </Li>
          <Li>
            <NavLink to="/">Services</NavLink>
          </Li>
          <Li>
            <NavLink to="/">Partner</NavLink>
          </Li>
          <Li>
            <NavLink
              className="blog-link"
              // to="https://roadlers-blog.gr-site.com/"
              to="/blog"
            >
              Blog
            </NavLink>
          </Li>
        </Ul>

        <div className="buttons-wrapper">
          {/* {
                    token && 
                    <NavLink to={'/user-earning-page'}>
                        <Icons className="gift-icon" src={giftIcon} />
                    </NavLink>
                } */}

          <NavLink to={"/cart"}>
            {/* <Icons className="header-icon" src={cartIcon} /> */}
            <BsCartPlus
              style={{ color: "#bbb", width: 28, height: 28, marginRight: 7 }}
            />
            {cart?.length > 0 && (
              <div className="notification">{cart?.length}</div>
            )}
          </NavLink>
          <li
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!themes && <ToggleBar onClick={themeToggle} />}
          </li>

          {user_role === "admin" && (
            <NavLink to={"/admin-dashboard"}>
              <Icons src={profileIcon} />
            </NavLink>
          )}
          {user_role === "provider" && (
            <NavLink to={"/provider-dashboard"}>
              <Icons src={profileIcon} />
            </NavLink>
          )}
          {user_role === "customer" && (
            <NavLink to={"/user-profile-page"}>
              <Icons src={profileIcon} />
            </NavLink>
          )}

          {token.length ? (
            <>
              <button
                onClick={() => handleLogout()}
                className="signup"
                style={{ fontSize: "16px" }}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <NavLink to="/login" className="login">
                Login
              </NavLink>

              <NavLink to="/customer-signup" className="signup">
                Sign-Up
              </NavLink>
            </>
          )}

          <div className="toggle-wrapper" onClick={handleMobileMenu}>
            <MenuBar>
              <div></div>
            </MenuBar>
          </div>
        </div>

        <div className={menuShow ? "mobile-menu active-mobile" : "mobile-menu"}>
          <div className="inner-wrapper">
            <CancelMenu>
              <img
                src={cancel}
                onClick={() => {
                  setMenuShow(false);
                }}
              />
            </CancelMenu>

            <ul className="mobile-list">
              <li>
                <NavLink to="/">
                  <img src={home} /> Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  <img src={vehicle} /> Vehicles
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  {" "}
                  <img src={driver} /> Drivers
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  {" "}
                  <img src={delivery} /> Deliveries
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  {" "}
                  <img src={promotion} /> Promotions
                </NavLink>
              </li>
              {token ? (
                <li>
                  <NavLink to="/individual-reg">
                    {" "}
                    <img src={partner} />
                    Partner with us
                  </NavLink>
                </li>
              ) : (
                ""
              )}
              <li className="accordion-overall">
                <div className="accordion">
                  <div className="accordion-header" onClick={toggleAccordion}>
                    <span>
                      <img src={user} />
                    </span>
                    <h3>Sign Up</h3>
                  </div>

                  <div
                    className={`accordion-content ${
                      isOpen ? "open" : "closed"
                    }`}
                  >
                    <ul className="signup-call-wrap">
                      <li>
                        <NavLink to="/customer-signup">
                          Create an Account
                        </NavLink>
                      </li>
                      {token ? (
                        <>
                          <li>
                            <NavLink to="/individual-reg">
                              List your vehicle
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/individual-reg">Be a Driver</NavLink>
                          </li>
                          <li>
                            <NavLink to="/individual-reg">
                              Be a Logistics Partner
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/individual-reg">
                              Business Affiliate
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                    {/* <p>Content for section 1 goes here</p> */}
                  </div>
                </div>
              </li>
              {token ? (
                <li
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                  onClick={handleLogout}
                >
                  <img src="" /> Logout
                </li>
              ) : (
                <li>
                  <NavLink to="/login">Login</NavLink>
                </li>
              )}

              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <NavLink
                  className="blog-link"
                  // to="https://roadlers-blog.gr-site.com/"
                  to="/blog"
                >
                  {" "}
                  <img src={blog} /> Blog
                </NavLink>
                {!themes && <ToggleBar onClick={themeToggle} />}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </RoadlerHeader>
  );
};

export default Header;
