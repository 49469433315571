import styled from 'styled-components'

export const FlexDiv = styled.div`
 display:flex;
width:100%;
margin-top:20px;
align-items:center;
justify-content:space-between;
margin-bottom:10px;
`
export const PriceDiv = styled.div`
 display:flex;
width:100%;
align-items:start;
margin-top:10px;
justify-content:space-between;

`
export const DetailsDiv = styled.div`
 display:flex;
width:100%;
margin-top:30px;
`
export const Flex = styled.div`
background: #FF2B2B;
color:#fff;
padding:2px 4px;
border-radius:5px;
display:flex;
align-items:center;
gap:2px;

`



export const Div = styled.div`
margin-top:0px;
`
export const ImageDiv = styled.div`
width:170px;
overflow-x:hidden;
border-radius:12px;
background:#000;
border:none;
outline:none;
position:relative;
`
export const ImageDetails = styled.div`
width:100%;
border-radius:0;
padding:.8em;
`

export const H5 = styled.h5`
color:#fff;
margin:0;
font-weight:900;
font-size:11.5px;
`
export const H6 = styled.h6`
color:#ffa100;
margin:0;
font-weight:700;
font-size:12px;
position:relative;
`
export const OldPrice = styled.label`
color:#fff;
font-weight:400;
text-decoration:line-through;
font-size:12px;

`

export const Label = styled.label`
color:${(props: any) => props.theme.white};
font-size:7px;
font-weight:600;
margin-bottom:3px;
`
export const StatusLabel = styled.label`
font-size:13px;
z-index:11;
font-weight:600;
top:10px;
right:10px;
position:absolute;
border-radius:7px;
padding:5px 9px;
`

export const Span = styled.span`
color:#fff;
font-size:10px;
`
export const LocationSpan = styled.div`
display:flex;
align-items:center;
font-size:10px;

`
export const LocationDiv = styled.div`
width:40px;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (min-width: 800px)  {
    width:60px;
}
`
export const PinnedInfoDiv = styled.div`
top:-30px;
display flex;
position:absolute;
align-items:center;
gap:13px;
width:100%;
@media only screen and (max-width: 390px)  {
    gap: 9px;
}
`

export const TopLabel = styled.label`
font-size:10px;
width:55px;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
@media only screen and (max-width: 390px)  {
    font-size: 8px;
width:45px;

}
`
export const BottomLabel = styled.label`
font-size:10px;

@media only screen and (max-width: 390px)  {
    font-size: 8px;

}
`



