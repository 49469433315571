import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiDashboard3Line } from 'react-icons/ri';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';


import {
    DriverCounterBox,
    Page,
} from './requests.styles';



import { Navigate, useNavigate } from 'react-router-dom';
import ProviderBalance from '../../../components/providerDashboard/providerBalance/providerBalance';
import ProviderActions from '../../../components/providerDashboard/providerActions/providerActions';
import AdminHeader from '../../../components/adminHeader/';
import DashboardInfo from '../../../components/Admin/DahboardInfo';
import Counter from '../../../components/Admin/Counter';
import Filter from '../../../components/Admin/Filter';
import ButtonGroups from '../../../components/Admin/ButtonGroups';
import Vehicles from '../../../components/Admin/Vehicles';
import CounterFilter from '../../../components/Admin/CounterFilter';
import RequestButtonGroups from '../../../components/Admin/RequestButtonGroup';
import AdminRequests from '../../../components/Admin/AdminRequest/adminRequests';
import LinkHeader from '../../../components/Admin/Header/header';
import { PagePadding } from '../Dashboard/dashboard.styles';
import { baseUrl } from '../../../services/adminApi';
import axios from 'axios';
import { useAppSelector } from '../../../store';

const ListRequests: FC = () => {
    const [requests, setRequests] = useState([])
    const user: any = useAppSelector((state: any) => state?.auth?.auth)

    const getRequest = async () => {

        await axios.get(`${baseUrl}admin-list-request`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                setRequests(response?.data?.data)
                
            }

        }).catch((err) => {
        })
    }

    useEffect(() => {
        getRequest()
    }, [])
    
    return (
        <>
            <AdminHeader />
            <Page>

                <PagePadding className='padding'>


                    <LinkHeader />

                    <DriverCounterBox>

                        <Counter request={true}  requestsData={requests}/>
                        <CounterFilter request={true} requests={requests}/>
                    </DriverCounterBox>
                    <Filter request={true}  />
                    <RequestButtonGroups />
                    <AdminRequests />
                </PagePadding>
            </Page>
        </>
    )
}
export default ListRequests