import React, { FC, useEffect, useRef, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import {
  DROPDOWN,
  DROPDOWNCOL,
  DROPDOWNDIV,
  DIVS,
  PPP,
  P,
  Button,
} from "./thirdScreen.styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { clearDriverInfo, setDriverInfo } from "../../../slices/provider";
import { useAppDispatch, useAppSelector } from "../../../store";
import ServiceFee from "../../providerCarReg/serviceFee";

const ThirdScreen: FC<{
  setWidth: any;
  setDriverDetails: any;
  driverDetails: any;
}> = ({ setWidth, driverDetails, setDriverDetails }) => {
  const dispatch: any = useAppDispatch();
  const driverInfo = useAppSelector(
    (state: any) => state?.provider?.driverInfo
  );
  useEffect(() => {
    setWidth(60);
  }, []);

  let [carDriver, setCarDriver] = useState(false);
  const [bikeDriver, setBikeDriver] = useState(false);
  const [fullTime, setFullTime] = useState(false);
  const [truckDriver, setTruckDriver] = useState(false);

  const carDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setCarDriver(true);
    let newArr = [...driverDetails?.availability, "Car Driving"];
    let updatedDetails = { ...driverDetails, availability: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state

    dispatch(setDriverInfo(updatedDetails)); // Dispatch the updated details
  };

  const remCarDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setCarDriver(false);
    const removeele = driverDetails?.availability?.filter((f: any) => {
      return f !== "Car Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability: removeele,
    });
    dispatch(setDriverInfo({ ...driverDetails, availability: removeele }));
  };

  const remBikeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setBikeDriver(false);
    const removeele = driverDetails?.availability?.filter((f: any) => {
      return f !== "Bike Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability: removeele,
    });
    dispatch(setDriverInfo({ ...driverDetails, availability: removeele }));
  };

  const bikeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setBikeDriver(true);
    let newArr = [...driverDetails?.availability, "Bike Driving"];

    let updatedDetails = { ...driverDetails, availability: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state

    dispatch(setDriverInfo(updatedDetails)); // Dispatch the updated details
  };

  const remFullTimeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setFullTime(false);
    const removeele = driverDetails?.availability?.filter((f: any) => {
      return f !== "Full Time Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability: removeele,
    });
    dispatch(setDriverInfo({ ...driverDetails, availability: removeele }));
  };

  const fullTimeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setFullTime(true);
    let newArr = [...driverDetails?.availability, "Full Time Driving"];

    let updatedDetails = { ...driverDetails, availability: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state

    dispatch(setDriverInfo(updatedDetails)); // Dispatch the updated details
  };

  const remTruckDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTruckDriver(false);
    const removeele = driverDetails?.availability?.filter((f: any) => {
      return f !== "Truck Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability: removeele,
    });
    dispatch(setDriverInfo({ ...driverDetails, availability: removeele }));
  };

  const truckDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTruckDriver(true);
    let newArr = [...driverDetails?.availability, "Truck Driving"];
    let updatedDetails = { ...driverDetails, availability: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state

    dispatch(setDriverInfo(updatedDetails)); // Dispatch the updated details
  };
  return (
    <>
      <P>Select all that works with your schedule</P>
      <DROPDOWNCOL>
        <DROPDOWN>
          <DROPDOWNDIV>
            {/* this removes the availability option */}

            {driverInfo?.availability?.includes("Car Driving") ? (
              <DIVS onClick={remCarDriving}>
                <Button style={{ background: "#ffa100" }}></Button>
                <PPP>Car Driving</PPP>
              </DIVS>
            ) : (
              <DIVS onClick={carDriving}>
                <Button style={{ background: "#fff" }}></Button>
                <PPP>Car Driving</PPP>
              </DIVS>
            )}
            {driverInfo?.availability?.includes("Bike Driving") ? (
              <DIVS onClick={remBikeDriving}>
                <Button style={{ background: "#ffa100" }}></Button>
                <PPP>Bike Driving</PPP>
              </DIVS>
            ) : (
              <DIVS onClick={bikeDriving}>
                <Button style={{ background: "#fff" }}> </Button>
                <PPP>Bike Driving</PPP>
              </DIVS>
            )}
          </DROPDOWNDIV>
          <DROPDOWNDIV>
            {driverInfo?.availability?.includes("Full Time Driving") ? (
              <DIVS onClick={remFullTimeDriving}>
                <Button style={{ background: "#ffa100" }}></Button>
                <PPP>Full-time Driving</PPP>
              </DIVS>
            ) : (
              <DIVS onClick={fullTimeDriving}>
                <Button style={{ background: "#fff" }}></Button>
                <PPP>Full-time Driving</PPP>
              </DIVS>
            )}
            {driverInfo?.availability?.includes("Truck Driving") ? (
              <DIVS onClick={remTruckDriving}>
                <Button style={{ background: "#ffa100" }}></Button>
                <PPP>Truck Driving</PPP>
              </DIVS>
            ) : (
              <DIVS onClick={truckDriving}>
                <Button style={{ background: "#fff" }}> </Button>
                <PPP>Truck Driving</PPP>
              </DIVS>
            )}
          </DROPDOWNDIV>
        </DROPDOWN>
      </DROPDOWNCOL>

      <ServiceFee
        driver={true}
        vehicleDetails={driverDetails}
        setVehicleDetails={setDriverDetails}
      />
    </>
  );
};
export default ThirdScreen;
