import styled from 'styled-components'
import { DarkBtnWrap } from '../../pages/user/UserWalletPage/userwalletpage.styles'

export const TripsCardWrap = styled.div `
    margin-bottom: 100px;
    min-height: 200px;
    border-radius: 14px;
    background-color: #fff;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.13);
    position: relative;
    width: 335px;

    @media only screen and (max-width: 454px) {
        min-height: 170px;
        width: 300px;
    }

    @media only screen and (min-width: 580px) {
        width: 420px;
    }
`

export const TripStatus = styled.div `
    position: absolute;
    background-color: ${(props:any) => props.bg};
    top: -43px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    padding: 8px 35px;
    color: ${(props:any) => props.text};
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    width: 108px;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        top: -23.5px;
        padding: 5px 20px;
    }
`

export const TripsCardTopSection = styled.div `
    width: 100%;
    border-radius: inherit;
    display: flex;
    gap: 15px;
`

export const TripsCardImgWrap = styled.div `
    flex: 0.38;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.13);

    & img {
        width: 100%;
        height: 120px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        object-fit: ${(props:any) => props.imgFit};
        object-position: center;
        display: block;
    }

    @media only screen and (max-width: 454px) {
        img {
            height: 80px;
        }
    }
`

export const TripsCardIdentityWrap = styled.div `
    flex: 0.62;
    padding-top: 20px;
    position: relative;


    & h5 {
        color: #C4C4C4;
        margin: 0;
        font-weight: 700;
        font-size: 13px;
    }
    & h6 {
        color: #000;
        margin: 0;
        font-weight: 700;
        font-size: 13px;
    }

    & h3 {
        color: #000;
        font-weight: 700;
        font-size: 17px;
        margin: 3px 0;
    }

    @media only screen and (max-width: 454px) {
        h5,h6 {
            font-size: 10px;
        }

        h3 {
            font-size: 14px;
        }
    }
`

export const DownloadReceiptBtn = styled.div `
    position: absolute;
    bottom: 0;
    right: 20px;
    border-radius: 5px;
    padding: 7px 0;
    background-color: #AA6C05;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 139.1px;
    gap: 10px;

    & p {
        font-size: 11px;
        font-weight: 700;
        color: #fff;
        margin: 0;
    }

    & img {
        width: 20px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 9px;
        }
    }
`

export const CompletedRatingBox = styled.div `
    width: 100%;

    & h4 {
        color: #868686;
        font-size: 13px;
        font-weight: 700;
        margin: 0;
        text-align: right;
    }

    & div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3px;

        img {
            width: 15px;
        }
    }

    @media only screen and (max-width: 454px) {
        h4 {
            font-size: 10px;
        }

        div {
            img {
                width: 12px;
            }
        }
    }
`

export const PriceBox = styled.div `
    position: absolute;
    bottom: 0;
    right: 20px;

    & p {
        font-weight: 700;
        font-size: 14px;
        color: #868686;
        margin: 0;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 12px;
        }
    }
`

export const ProgressExtrasWrap = styled.div `
    position: absolute;
    bottom: -40px;
    right: 20px;

    & p {
        font-weight: 700;
        font-size: 14px;
        color: #868686;
        margin: 0;
        text-align: center;
    }

    & img {
        margin-top: 5px;
        width: 60px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 10px;
        }

        img {
            width: 40px;
            margin: 0;
        }
    }
`

export const TripsCardBottomSection = styled(TripsCardTopSection) `
    padding: 10px 0;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    @media only screen and (max-width: 454px) {
        padding-bottom: 10px;
    }
`

export const TripsCardInfoOverall = styled.div `
    padding-left: 15px;
    flex: 0.6;

    @media only screen and (max-width: 454px) {
        flex: 0.5;
    }
`

export const TripsInfoWrap = styled.div `
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;

    & p {
        color: #6A6A6A;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;

        &.dest {
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    & img {
        width: 20px;
    }

    @media only screen and (max-width: 454px) {
            p {
                font-size: 10px;

                &.dest {
                    width: 100px;
                }
            }

            img {
                width: 16px;
            }

        }
`

export const TripsCardActionsOverall = styled.div `
    flex: 0.4;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 454px) {
        flex: 0.45;
    }
`

export const ActionsLeftSection = styled.div `
    display: flex;
    align-items: flex-end;
    flex: 0.3;
`

export const EnvelopeImgWrap = styled.div `
    display: block;

    img {
        width: 30px;
    }

    @media only screen and (max-width: 454px) {
        width: 20px;
    }

`

export const ActionsRightSection = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    flex: 1;
`

export const ActionsTopWrap = styled.div `
    position: relative;
`

export const ActionsBottomWrap = styled.div `
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
`

export const CancelTripWrap = styled(DarkBtnWrap) `
    /* width: 75%; */
    margin: 0;
    padding: 10px;
    border-radius: 5px;

    p {
        font-size: 12px;
    }

    img {
        width: 13px;
    }

    @media only screen and (max-width: 454px) {
        padding: 6px;

        p {
            font-size: 9px;
        }
        img {
            width: 10px;
        }
    }
`

export const PayNowWrap = styled(CancelTripWrap) `
`

export const BalanceWrap = styled(PayNowWrap)`
    position: relative;
    min-width: 100px;

    & div {
        position: absolute;
        font-size: 13px;
        font-weight: 700;
        color: #6A6A6A;
        margin: 0;
        padding-left: 7px;
        top: -20px;
        left: 0;
    }

    @media only screen and (max-width: 454px) {
        div {
            font-size: 10px;
        }
    }
`

export const ReceiptBtn = styled(CancelTripWrap) `
    background-color: #EAEAEA;
    padding: 12px 18px;

    & p { 
        color: #000;
    }

    @media only screen and (max-width: 454px) {
        padding: 7px 10px;
    }
`

export const MessageBtn = styled.div `
    background-color:#FFD58C ;
    color: #000;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    padding: 0px 15px;
    

    button {
        cursor: pointer;
        background-color: transparent;
        border: none; 
        outline: none;
        font-weight: 700;
        font-size: 12px;
        color: #000;
    }

    @media only screen and (max-width: 454px) {
        padding: 7px;
        font-size: 10px;

        button {
            padding: 7px;
            font-size: 10px;
        }
    }
`

export const AccesCodeBtn = styled(MessageBtn)`
    width: 100%;
`