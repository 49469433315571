import {createSlice} from '@reduxjs/toolkit';
const initialState = {
    showLocation: false,
};

const showLocationSlice = createSlice({
    name: 'showLocation',
    initialState,
    reducers: {
        setShowLocation: (state, action) => {
            return {...state, showLocation: action.payload};
        },
        clearShowLocation: () => {
            return {showLocation: false}
        }
    }
});

const {reducer, actions} = showLocationSlice;
export const {setShowLocation, clearShowLocation} = actions;
export default reducer

