import { Wrapper } from './../travelGarageBox/garagebox-styles';
import { ModalBox } from './../optionModal/optionmodal.styles';
import styled from 'styled-components'

export const ExtrasModalBox = styled(ModalBox) `

    & .inner-modal-box {
        height: 600px;
        overflow-y: scroll;

        @media only screen and (max-width: 484px) {
            
            max-height: 500px;
        }
    }

    & .header-box-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-section {
        
            h3 {
                font-size: 16px;
                font-weight: 700;
            }

            hr {
                width: 40%;
                margin: 0;
                border: solid 1.5px #ffa100;
            }
        }

        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: #ffa100;
            font-weight: 700;
            cursor: pointer;
            font-size: 18px;

            p {
                color: rgb(255, 161, 0);
                font-weight: 700;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    & .textarea-wrap {
        border: solid 1px #fff;
        border-radius: 9px;
        background-color: transparent;
        padding: 10px 15px;
        margin-top: 40px;

        .btn-wrap {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
`

export const InputBoxOverall = styled.div `
    margin-top: 20px;
    & label {
        font-weight: 700;
        font-size: 13px;
        color: #C4C4C4;
    }

    & .extra-inner-wrapper {
        margin-top: 10px;
    }

    & .amount-wrap-overall {
        display: flex;
        justify-content: flex-start;
    }
`

export const ExtraInputWrap = styled.div `
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 5px;
    position: relative;

    & .arrows-wrap {
        margin-right: 15px;
        display: flex;
        align-items: center;
    }
`

export const ExtraInput = styled.input `
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;
    
    &::placeholder {
        color: #8F8E8E;
    }
`

export const ExtraAmtWrap = styled.div `
    padding: 10px  20px;
    border-radius: 8px;
    border: solid 1px #fff;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    margin-top: 15px;

    @media only screen and (max-width: 484px) {
        font-size: 13px;
        padding: 6px 10px;
    }
`

export const InstructionBox = styled.textarea `
    outline: none;
    border: none;
    width: 100%;
    color: #fff;
    background-color: transparent;

    &::placeholder {
        color: #8F8E8E;
    }
`

export const SaveBtn = styled.div `
    background-color: #ffa100;
    padding: 10px 20px;
    color: #000;
    font-weight: 700;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    @media only screen and (max-width: 484px) {
        padding: 7px 10px;
        font-size: 12px;
    }
`

export const ExtrasAccordionWrap = styled.div`
    background-color: #000;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border: solid 1px #868686;
    width: 100%;
    height: ${(props: any) => props.contentShow ? '220px' : 0 };
    max-height: ${(props: any) => props.contentShow ? '9999px' : 0 }  ;
    position: absolute;
    top: 50px;
    left: 0;
    transition: height 0.3s ease, max-height 0.4s;

    .extra-inner-wrapper {
        position: relative;
        padding: 15px;
        display: ${(props:any) => props.contentShow ? 'block' : 'none'};
        height: ${(props: any) => props.contentShow ? '220px' : 0 };
    }
`