import styled from "styled-components"

export const CheckBoxWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: ${(props: any) => props.top}; */
`

export const CheckBoxLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 38px;
    height: 20px;
    border-radius: 15px;
    background-color: #bebebe;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        margin: 3px;
        background: #ffffff;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`

export const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 38px;
    height: 20px;
    border: solid 1px blue;
    outline: none;

    &:checked + ${CheckBoxLabel} {
        background: #ffa100;

        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 13px;
            height: 13px;
            margin: 3px;
            margin-left: 21px;
            background: #ffffff;
            box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
            transition: 0.2s;
        }
    }
`

export const SubmitBtn = styled.button `
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    margin: 30px 0px;
    background-color: #242321;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    color: #fff;
    position: relative;

    & p{
        color: #fff;
        font-weight: 700;
        font-size: 17px;
        margin: 0;
    }

    @media only screen and (max-width: 454px) {
        padding: 12px 10px;
        margin: 20px 0;

        p {
            font-size: 13px;
        }
    }
`

export const InactiveSubmitBtn = styled(SubmitBtn) `
    background-color: rgba(134, 134, 134, 0.31);
    box-shadow: unset;

    p {
        color: #fff;
    }
`