import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  Page,
  KinLabel,
  OwnerLabel,
  StatusDiv,
  VerifyButton,
  PublishButton,
  VerifiedButton,
  PublishedButton,
  VerifyProfileDiv,
  AcceptButton,
  RejectButton,
  RejectedDiv,
  ApprovedDiv,
} from "./vehicleProfile.styles";

// import SecondScreen from '../../../components/providerInsurance/secondScreen/secondScreen';
import { useNavigate, useParams } from "react-router-dom";
import VehicleProfile from "../../../components/providerVehicleProfile/vehicleProfile/vehicleProfile";
import RegularRates from "../../../components/providerVehicleProfile/regularRates/regularRates";
import TravelRates from "../../../components/providerVehicleProfile/travelRates/travelRates";
import LongTermRates from "../../../components/providerVehicleProfile/longTermRates/longTermRates";
import Services from "../../../components/providerVehicleProfile/services/services";
import Insurance from "../../../components/providerVehicleProfile/insurance/insurance";
import Documents from "../../../components/providerVehicleProfile/documents/documents";
import {
  baseUrl,
  useCarUpdateMutation,
  useGetInsuranceQuery,
  useGetNextOfKinByProviderIdQuery,
  useGetProviderByIdQuery,
  useGetVehicleByIdQuery,
} from "../../../services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../store";
import SecondScreen from "../../../components/providerAccountSetup/secondScreen/secondScreen";
import FirstScreen from "../../../components/providerAccountSetup/firstScreen/firstScreen";
import Header from "../../../components/providerHeader";
import axios from "axios";
import { setNextOfKin, setProfile } from "../../../slices/provider";
import KinScreen from "../../../components/providerVehicleProfile/kin/kin";
import IndividualProfile from "../../../components/providerVehicleProfile/IndividualProfile/IndividualProfile";
import {
  useVerifyProviderMutation,
  useVerifyVehicleMutation,
} from "../../../services/adminApi";
import BusinessScreen from "../../../components/providerVehicleProfile/business/business";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";

interface ProviderInfo {
  gender?: string;
  id_number?: string;
  country?: string;
  state?: string;
  id_image?: string;
  dob?: string;
  identification?: string;
  category?: string;
}
const VProfile = () => {
  const { id, user_id }:any = useParams<any>();

  const user: any = useAppSelector((state: any) => state?.auth?.auth);

  const token = useAppSelector((state: any) => state?.auth?.auth?.token);

  //@ts-ignore
  const {
    data: vehicleProfile,
    isFetching: isFetchingVehicleProfile,
    refetch: refetchVehicleProfile,
  } = useGetVehicleByIdQuery(id);
  const {
    data: insuranceData,
    isFetching: isFetchingInsuranceData,
    refetch: refetchInsuranceData,
  } = useGetInsuranceQuery(id);
  const dispatch: any = useAppDispatch();
  const [userId, setUserId] = useState();
  const [providerInfo, setProviderInfo] = useState<ProviderInfo>({});
  let [vehicleStatus, setVehicleStatus] = useState<string>();
  let [providerStatus, setProviderStatus] = useState<string>();
  let [vehicleDoc, setVehicleDoc] = useState([]);

  const licenseVerify:any = vehicleDoc?.filter((doc: any) => {
    return doc?.doc_type === "vehicle_licence";
  });

  const proofVerify:any = vehicleDoc?.filter((doc: any) => {
    return doc?.doc_type === "proof_of_ownership";
  });

  //@ts-ignore
  let [licenseDocStatus, setLicenseDocStatus] = useState<any>(
    licenseVerify[0]?.status
  );
  //@ts-ignore
  let [proofDocStatus, setProofDocStatus] = useState(proofVerify[0]?.status);
  const getVehicleDetails = async () => {
    await axios
      .get(`${baseUrl}fetch-vehiclebyidsingle/${Number(id)}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setUserId(response?.data?.data[0]?.user_id);
        setVehicleStatus(response?.data?.data[0]?.status);
        setVehicleDoc(response?.data?.data[0]?.vehicle_document2);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getVehicleDetails();
  }, [id, licenseDocStatus, proofDocStatus]);

  const getProviderDetails = async () => {
    await axios
      .get(`${baseUrl}provider-next-of-kin/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProviderInfo(response.data[0]);
        setProviderStatus(response.data[0]?.status);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProviderDetails();
  }, [user_id]);

  useEffect(() => {
    refetchVehicleProfile();
  }, []);

  useEffect(() => {
    refetchInsuranceData();
  }, []);

  const [vehicleInfo, setVehicleInfo] = useState(vehicleProfile?.data[0]);
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const [updateVehicle, { data, isLoading, isSuccess, isError, error }] =
    useCarUpdateMutation();

  const [
    verifyVehicle,
    {
      data: verifyVehicleData,
      isLoading: verifyVehicleLoading,
      isSuccess: isSuccessVerifyVehicle,
      isError: isErrorVerifyVehicle,
      error: errorVerifyVehicle,
    },
  ] = useVerifyVehicleMutation();

  const [
    verifyProvider,
    {
      data: verifyProviderData,
      isLoading: verifyProviderLoading,
      isSuccess: isSuccessVerifyProvider,
      isError: isErrorVerifyProvider,
      error: errorVerifyProvider,
    },
  ] = useVerifyProviderMutation();

  useEffect(() => {
    if (verifyVehicleLoading) {
      setLoading(true);
      setProfileLoading(true);
    }
    if (isSuccessVerifyVehicle) {
      setLoading(false);
      setProfileLoading(false);
    }

    if (isErrorVerifyVehicle && "status" in errorVerifyVehicle!) {
      if (
        isErrorVerifyVehicle &&
        errorVerifyVehicle.status.toString() === "FETCH_ERROR"
      ) {
        // notifyError('Network Connection Failed')
      }
      // setBtnLoad(false)
      setLoading(false);
    }
  }, [
    verifyVehicleLoading,
    isSuccessVerifyVehicle,
    isErrorVerifyVehicle,
    verifyVehicleData,
  ]);

  const handleResetVehicle = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "0");
    formData.append("status", "Pending");
    // setVehicleStatus('Rejected Loading')
    verifyVehicle(formData)
      .then((response) => {
        //@ts-ignore
        setVehicleStatus(response?.data?.data?.status);
      })
      .catch((error) => {
        // Handle the error if needed
        // setVehicleStatus('Error');
      });
  };

  const handleVerifyVehicle = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "2");
    formData.append("status", "Accepted");
    setVehicleStatus("Accepted Loading");
    verifyVehicle(formData)
      .then((response) => {
        //@ts-ignore
        setVehicleStatus(response?.data?.data?.status);
      })
      .catch((error) => {
        // Handle the error if needed
        // setVehicleStatus('Error');
      });
  };

  const handleRejectVehicle = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "0");
    formData.append("status", "Rejected");
    setVehicleStatus("Rejected Loading");
    verifyVehicle(formData)
      .then((response) => {
        //@ts-ignore
        setVehicleStatus(response?.data?.data?.status);
      })
      .catch((error) => {
        // Handle the error if needed
        // setVehicleStatus('Error');
      });
  };

  const handleVerifyProfile = () => {
    let formData: any = new FormData();
    formData.append("id", user_id);
    formData.append("display", "1");
    formData.append("status", "Approved");
    setProviderStatus("Approve Loading");

    verifyProvider(formData)
      .then((response: any) => {
        // setProviderStatus('Approved');
        if (response?.data?.data?.status === "Approved") {
          setProviderStatus(response?.data?.data?.status);
        }
      })
      .catch((error) => {
        // Handle the error if needed
        // setVehicleStatus('Error');
      });
  };
  const handleRejectProfile = () => {
    let formData: any = new FormData();
    formData.append("id", user_id);
    formData.append("display", "0");
    formData.append("status", "Rejected");
    setProviderStatus("Rejected Loading");

    verifyProvider(formData)
      .then((response) => {
        //@ts-ignore
        if (response?.data?.data?.status === "Rejected") {
          setProviderStatus("Rejected");

          let formDataTwo: any = new FormData();
          formDataTwo.append("id", id);
          formDataTwo.append("display", "0");
          formDataTwo.append("status", "Rejected");
          setProviderStatus("Rejected");

          verifyVehicle(formDataTwo)
            .then((response: any) => {
              // setProviderStatus('Approved');
              if (response?.data?.data?.status === "Rejected") {
                setProviderStatus(response?.data?.data?.status);
                setVehicleStatus(response?.data?.data?.status);
              }
            })
            .catch((error) => {
              // Handle the error if needed
              // setVehicleStatus('Error');
            });
        }
      })
      .catch((error) => {
        // Handle the error if needed
        // setVehicleStatus('Error');
        // setProviderStatus('')
      });
  };

  const handlePublishVehicle = () => {
    let formData: any = new FormData();
    formData.append("id", id);
    formData.append("display", "1");
    formData.append("status", "Approved");
    formData.append(
      "verified_status",
      licenseVerify[0]?.status === "Accepted" &&
        proofVerify[0]?.status === "Accepted"
        ? "1"
        : "0"
    );

    setVehicleStatus("LoadingPublish");

    verifyVehicle(formData)
      .then((response: any) => {
        setVehicleStatus("Approved");
      })
      .catch((error) => {
        // Handle the error if needed
        // setVehicleStatus('Error');
      });
  };

  return (
    <>
      <Header />
      <Page>
        <PagePadding className="padding">
          {isFetchingVehicleProfile ? (
            "Loading"
          ) : (
            <>
              {vehicleProfile?.data[0]?.status === null && (
                <StatusDiv>Pending Approval</StatusDiv>
              )}
              {(vehicleProfile?.data[0]?.status === "Pending" ||
                vehicleProfile?.data[0]?.status === "Accepted") && (
                <StatusDiv>Pending Approval</StatusDiv>
              )}

              {vehicleProfile?.data[0]?.status === "Approved" && (
                <ApprovedDiv>Approved</ApprovedDiv>
              )}
              {vehicleProfile?.data[0]?.status === "Rejected" && (
                <RejectedDiv>Rejected</RejectedDiv>
              )}

              <VehicleProfile vehicleProfile={vehicleProfile} />

              <div id="regularRates" style={{ margin: "70px 0px" }}>
                <RegularRates vehicleProfile={vehicleProfile} />
              </div>
              <TravelRates
                vehicleProfile={vehicleProfile}
                vehicleInfo={vehicleInfo}
                setVehicleInfo={setVehicleInfo}
              />
              <LongTermRates vehicleProfile={vehicleProfile} />
              <Services vehicleProfile={vehicleProfile} />

              <Insurance vehicleProfile={vehicleProfile} userId={userId} />

              <br />
              <br />
              <br />

              {user?.user_role === "admin" && (
                <>
                  <VerifyProfileDiv>
                    {(vehicleStatus === "Accepted" ||
                      vehicleStatus === "Approved") && (
                      <AcceptButton>Accepted</AcceptButton>
                    )}
                    {(vehicleStatus === "Pending" ||
                      vehicleStatus === "Rejected" ||
                      vehicleStatus === "Trashed" ||
                      vehicleStatus === null) && (
                      <AcceptButton onClick={handleVerifyVehicle}>
                        Accept
                      </AcceptButton>
                    )}
                    {vehicleStatus === "Accepted Loading" && (
                      <AcceptButton>Loading..</AcceptButton>
                    )}

                    {vehicleStatus === "Rejected" && (
                      <RejectButton>Rejected</RejectButton>
                    )}
                    {(vehicleStatus === "Pending" ||
                      vehicleStatus === "Accepted" ||
                      vehicleStatus === null ||
                      vehicleStatus === "Approved") && (
                      <RejectButton onClick={handleRejectVehicle}>
                        Reject
                      </RejectButton>
                    )}
                    {vehicleStatus === "Rejected Loading" && (
                      <RejectButton>Loading..</RejectButton>
                    )}
                  </VerifyProfileDiv>
                </>
              )}

              {/* {vehicleDoc?.length > 0 ? */}
              <Documents
                setProofDocStatus={setProofDocStatus}
                setLicenseDocStatus={setLicenseDocStatus}
                vehicleProfile={vehicleProfile}
                user_id={user_id}
              />

              {/* } */}

              <br />
              <br />
              <br />
              <OwnerLabel style={{ marginTop: 100 }}>Owner </OwnerLabel>

              <IndividualProfile
                userId={userId}
                providerInfo={providerInfo}
                setProviderInfo={setProviderInfo}
                vehicleId={id}
                vehicleProfile={vehicleProfile}
              />

              <br />
              <br />
              {providerInfo?.category?.toLowerCase().includes("business") ? (
                <KinLabel>Business Info</KinLabel>
              ) : (
                <KinLabel>Next of Kin </KinLabel>
              )}
              <br />
              <br />

              {providerInfo?.category?.toLowerCase().includes("business") ? (
                <BusinessScreen
                  userId={userId}
                  providerInfo={providerInfo}
                  setProviderInfo={setProviderInfo}
                />
              ) : (
                <KinScreen
                  userId={userId}
                  providerInfo={providerInfo}
                  setProviderInfo={setProviderInfo}
                />
              )}
              <br />
              <br />
            </>
          )}

          {user?.user_role === "admin" && (
            <VerifyProfileDiv>
              {(providerStatus === "Approved" ||
                providerStatus === "Accepted") && (
                <AcceptButton>Accepted</AcceptButton>
              )}
              {(providerStatus === "0" ||
                providerStatus === "Pending" ||
                providerStatus === "Rejected" ||
                providerStatus === null) && (
                <AcceptButton onClick={handleVerifyProfile}>
                  Accept
                </AcceptButton>
              )}
              {providerStatus === "Approve Loading" && (
                <AcceptButton>Loading..</AcceptButton>
              )}

              {providerStatus === "Rejected" && (
                <RejectButton>Rejected</RejectButton>
              )}
              {(providerStatus === "Approved" ||
                providerStatus === "0" ||
                providerStatus === "Pending" ||
                providerStatus === "Accepted" ||
                providerStatus === null) && (
                <RejectButton onClick={handleRejectProfile}>
                  Reject
                </RejectButton>
              )}
              {providerStatus === "Rejected Loading" && (
                <RejectButton>Loading..</RejectButton>
              )}
            </VerifyProfileDiv>
          )}

          {user?.user_role === "admin" && vehicleStatus === "Approved" && (
            <PublishedButton>Published</PublishedButton>
          )}
          {user?.user_role === "admin" &&
            providerStatus === "Approved" &&
            vehicleStatus === "Accepted" && (
              <PublishButton onClick={handlePublishVehicle}>
                Publish
              </PublishButton>
            )}
          {user?.user_role === "admin" &&
            vehicleStatus === "LoadingPublish" && (
              <PublishButton>Loading...</PublishButton>
            )}
        </PagePadding>
      </Page>
    </>
  );
};
export default VProfile;
