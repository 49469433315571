import { YellowBox } from './../transferOne/transferone.styles';
import styled from 'styled-components'

export const SwapWalletWrap = styled.div `
    display: ${(props:any)=> props.display} ;
`
export const SwapContentWrap = styled.div `
    margin: 30px 0;
    margin-bottom: 70px;
`

export const SelectTypeWrap = styled.div `
    background-color:#F6F6F6;
    border-radius: 11px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);
    padding: 10px 20px;
    gap: 10px;
    position: relative;

    & .absolute-txt {
        position: absolute;
        left: 10px;
        bottom: -23px;
        color: #C17C2D;
        font-size: 12px;
        font-weight: 700;
        padding: 0 5px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 6px 13px;

        .absolute-txt {
            font-size: 9px;
            bottom: -19px;
        }
    }
`

export const AmountSectionWrap = styled.div `
    & label {
        color: #868686;
        padding: 5px 3px;
        font-size: 13px;

    }

    & input {
        background-color: transparent;
        outline: none;
        border: none;
        width: 100%;
        font-size: 16px;
        font-weight: 700;

        &::placeholder {
            color: #000;
            font-size: 16px;
            font-weight: 700;
        }
    }

    @media only screen and (max-width: 454px)  {
        label {
            padding: 3px 1px;
            font-size: 9px;
            white-space: nowrap;
        }
        
        input {
            font-size: 12px;

            &::placeholder {
                font-size: 12px;
            }
        }
    }
`
export const SelectOptionBox = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 14px 7px;
    border-radius: 7px;
    background-color: #000;
    cursor: pointer;
    min-width: 140px;

    & p {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
    }

    @media only screen and (max-width: 454px)  {
        padding: 10px 7px;
        min-width: 110px;
        border-radius: 4px;

        p {
            font-size: 9px;
        }
    }
`

export const SelectYellowBox = styled(YellowBox) `
    padding: 4px;

    & img {
        width: 15px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 3px;
        border-radius: 2px;

        img {
            width: 8px;
        }
    }
`

export const SwapIconWrap = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    & img {
        width: 30px;
    }

    @media only screen and (max-width: 454px)  {
        img {
            width: 20px;
        }
    }
`