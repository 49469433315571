import React, { FC, useEffect, useState } from "react";
import {
  DateBox,
  DateLocationWrap,
  GarageCard,
  GarageCardImgWrap,
  GarageTopSection,
  LocationBox,
  TicketPriceBox,
} from "./garagecard.styles";

import car from "../../assets/images/png/garage-car.png";
import userImg from "../../assets/images/png/phebe.png";
import test from "../../assets/images/jpg/test.jpg";
import calendar from "../../assets/images/png/cal.png";
import location from "../../assets/images/svg/location-tag.svg";
import seatYellow from "../../assets/images/png/seatYellow.png";

import moment from "moment";
import {
  useDeleteTravelGarageMutation,
  useGetProviderByIdQuery,
  useGetVehicleByIdQuery,
} from "../../services/providerApi";
import { baseImageUrl } from "../../services/utils";
import { useAppSelector } from "../../store";
import { InputIcons } from "../locationModal/locationmodal.styles";

const GarageListingCard: FC<{ travel: any }> = ({ travel }) => {
  const user = useAppSelector((state: any) => state?.auth?.auth);

  const { data, isFetching, refetch } = useGetProviderByIdQuery(
    travel?.provider_id
  );
  const {
    data: vehicleProfile,
    isFetching: isFetchingVehicleProfile,
    refetch: refetchVehicleProfile,
  } = useGetVehicleByIdQuery(travel?.vehicle_id);

  useEffect(() => {
    refetch();
  }, [data]);
  useEffect(() => {
    refetchVehicleProfile();
  }, [vehicleProfile]);

  const formatDate = (dateString: Date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      //@ts-ignore
      options
    );
    return formattedDate;
  };

  return (
    <GarageCard>
      <GarageTopSection>
        <GarageCardImgWrap>
          <div className="user-subwrapper">
            <img
              className="img"
              src={
                data?.profile_image
                  ? baseImageUrl + data?.profile_image
                  : userImg
              }
            />
          </div>

          <div className="details-subwrapper">
            <h1>{data?.firstname.split("-")[0]}</h1>

            {travel?.departure_date !== "undefined" &&
            travel?.departure_date !== "null" ? (
              <div className="seats">
                <InputIcons src={seatYellow} style={{ width: "12px" }} />

                <p>{travel?.seat_count}</p>
              </div>
            ) : (
              <div className="seats">
                <InputIcons src={seatYellow} style={{ width: "12px" }} />

                <p>{vehicleProfile?.data[0]?.num_passengers}</p>
              </div>
            )}
          </div>

          <div className="img-subwrapper">
            <div className="img">
              <img
                className="imgFill"
                src={
                  vehicleProfile?.data[0]?.image1
                    ? baseImageUrl + vehicleProfile?.data[0]?.image1
                    : car
                }
                alt={baseImageUrl + vehicleProfile?.data[0]?.image1}
              />
              {/* <img src={test} /> */}
            </div>

            {/* <img className="img" src={(baseImageUrl + vehicleProfile?.data[0]?.image1) || car} /> */}
            <LocationBox>
              <img src={location} />
              <p>
                {travel?.from} to {travel?.to}
              </p>
            </LocationBox>
          </div>

          <DateLocationWrap>
            <DateBox>
              {travel?.departure_date !== "undefined" &&
              travel?.departure_date !== "null" ? (
                <>
                  <div className="leaving">
                    <h5>Leaving</h5>
                    <p>{travel?.departure_time}</p>
                  </div>
                  <div className="date">
                    <img src={calendar} />
                    {/* <p>Dec | 30 | 2022</p> */}
                    <p>{formatDate(travel.departure_date)}</p>
                  </div>
                </>
              ) : (
                <div className="leaving">
                  <p>Vehicle is available for chatter</p>
                </div>
              )}
            </DateBox>
          </DateLocationWrap>
          <div className="trip-details trip">
            <h4>Pickup:</h4>
            <p>{travel.pickup_address}</p>
          </div>
          <div className="trip-details">
            <h4>Destination:</h4>
            <p>{travel.destination} </p>
          </div>

          <div className="priceBox">
            <button>
              {travel?.currency}{" "}
              {Number(travel.price).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </button>
          </div>
        </GarageCardImgWrap>
      </GarageTopSection>
    </GarageCard>
  );
};

export default GarageListingCard;
