import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    showCarOption: false,
  searchData: "",

  };
  const showCarOptionSlice = createSlice({
    name: 'showCarOption',
    initialState,
    reducers: {
      setShowCarOption: (state, action) => {
        return { ...state, showCarOption: action.payload };
      },
      setSearchData: (state, action) => {
        return { ...state, searchData: action.payload };
      },
      clearShowCarOption: (state, action) => {
        return { ...state, showCarOption: false };
      }
    }
  });
  
  const { reducer, actions } = showCarOptionSlice;
  export const { setShowCarOption,setSearchData, clearShowCarOption} = actions;
  export default reducer;