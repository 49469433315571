import styled from "styled-components";

export const BackgroundBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.98);
  transition: all 0.5s ease 0s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 601;
`;
export const ModalBox = styled.div`
  background: #fff;
  transition: all 0.5s ease 0s;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 10px;
  position: absolute;

  width: 75%;
  .image-container {
    background: black;
    border-radius: 20px 20px 0px 0px;
  }
  .text-container {
    margin-top: -25px;
    background: #fff;
    border-radius: 20px;
    padding: 15px;
    box-shadow: 4px 4px 15px 2px #00000024;

    p,
    h2 {
      color: #000;
      margin: 0;
    }
    .buttons {
      margin-top: 20px;
      display: flex;
      gap: 10px;
      .theme {
        background: #ffa100;
        font-weight: bold;
        padding: 7px 15px;
        border-radius: 7px;
        margin: 0;
        color: #000;
        outline:none;
        border:none;
      }
      .black {
        background: #000;
        font-weight: bold;
        padding: 7px 15px;
        border-radius: 7px;
        margin: 0;
        color: #fff;
        outline:none;
        border:none;
      }
    }
  }
`;
export const H3 = styled.h3`
  color: #fff;
`;
export const Button = styled.button`
  background: #ffa100;
  color: #000;
  width: 50%;
  border: none;
  outline: none;
  padding: 10px 12px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 18px;
`;
