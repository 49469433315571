import React, { FC, useEffect, useState } from 'react'
import {
    ModalContainer, Modal, Label, Flex, ImageContainer, TextContainer, BackButton, DeleteButton, Text, SetButton, CancelBtn
} from './index.styles';
import deleteImage from '../../../../assets/images/png/modalOption.png'
import spinner from '../../../assets/images/gif/spinner.gif'
import xmark from '../../../../assets/images/png/X.png'

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'
// import { useDeleteVehicleMutation } from '../../../services/providerApi';
// import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setServiceVisibility, setVehicleDetailss } from '../../../../slices/provider';
// import { setAllVehicle } from '../../../slices/provider';

type Props = {
    service?: string;
    setServicePopUp?: any;
    setComponent?: any;
    isHalfDayService?: any;
    setHalfDayPopUp?: any;
    fullDayPopUp?: any;
    halfDayPopUp?: any,
    setFullDayPopUp?: any;
    hourlyPopUp?: any;
    setHourlyPopUp?: any;
    isFullDayService?: any;
    isHourlyService?: any;
    airportPopUp?: any;
    setAirportPopUp?: any;
    isAirportService?: any;
    travelPopUp?: any;
    setTravelPopUp?: any;
    travelServicePrice?: any;
    fullMonthPopUp?: any;
    halfMonthPopUp?: any;
    setFullMonthPopUp?: any;
    setHalfMonthPopUp?: any;
    isFullMonthService?: any;
    isHalfMonthService?: any;
    vehicleDetails?:any;
}
export const PopupModal: FC<Props> = ({vehicleDetails, travelPopUp, setTravelPopUp, travelServicePrice, airportPopUp, setAirportPopUp, isAirportService, hourlyPopUp, isFullDayService,
    isHourlyService, fullMonthPopUp,
    halfMonthPopUp,
    setFullMonthPopUp,
    setHalfMonthPopUp,
    isFullMonthService,
    isHalfMonthService,
    setHourlyPopUp, setFullDayPopUp, halfDayPopUp, fullDayPopUp, setHalfDayPopUp, isHalfDayService, setComponent, setServicePopUp, service }) => {


    const dispatch: any = useAppDispatch()

    const handleCancelHalfTime = () => {
        dispatch(setServiceVisibility({ halfDayServiceVisible: false }))
        setHalfDayPopUp(false)

        if(vehicleDetails?.vehicle_type.includes("Bike")){
            setComponent(5)
        }else{
            setComponent(4)
        }
    }

    const handleCancelFullTime = () => {
        dispatch(setServiceVisibility({ fullDayServiceVisible: false }))
        setFullDayPopUp(false)
        if(vehicleDetails?.vehicle_type.includes("Bike")){
            setComponent(5)
        }else{
            setComponent(4)
        }
    }
    const handleCancelHourly = () => {
        dispatch(setServiceVisibility({ hourlyRateVisible: false }))
        setComponent(4)
        setHourlyPopUp(false)
    }
    const handleCancelAirport = () => {
        dispatch(setServiceVisibility({ airportVisible: false }))
        setComponent(5)
        setAirportPopUp(false)
    }
    const handleCancelTravel = () => {
        setTravelPopUp(false)
        setComponent(5)
        dispatch(setServiceVisibility({ travelVisible: false }))

    }
    const handleCancelHalfMonth = () => {
        dispatch(setServiceVisibility({ halfMonthVisible: false }))
        setComponent(6)
        setHalfMonthPopUp(false)


    }
    const handleCancelFullMonth = () => {
        dispatch(setServiceVisibility({ fullMonthVisible: false }))
        setComponent(6)
        setFullMonthPopUp(false)


    }

    const handleSetFullTime = () => {
        setFullDayPopUp(false)
        setHalfDayPopUp(false)
        if(vehicleDetails?.vehicle_type.includes("Bike")){
            setComponent(5)
        }else{
            setComponent(4)
        }
    }

    const handleSetHalfTime = () => {
        setHalfDayPopUp(false)
        if(vehicleDetails?.vehicle_type.includes("Bike")){
            setComponent(5)
        }else{
            setComponent(4)
        }
    }
    const handleSetHourly = () => {
        setHourlyPopUp(false)
        setComponent(4)
    }

    const handleSetAirport = () => {
        setAirportPopUp(false)
        setComponent(5)
    }

    const handleSetTravel = () => {
        setTravelPopUp(false)
        setComponent(5)
    }

    const handleSetFullMonth = () => {
        setFullMonthPopUp(false)
        setComponent(6)
    }

    const handleSetHalfMonth = () => {
        setHalfMonthPopUp(false)
        setComponent(6)
    }





    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",

    })

    return (
        <ModalContainer>
            <ToastContainer />

            <Modal>
                <ImageContainer>
                    <img src={deleteImage} style={{ width: '100%' }} />
                </ImageContainer>
                <TextContainer>
                    <Text>Note</Text>
                    <Label>
                        You are yet to input price detail for <span style={{ fontSize: '13px', fontWeight: '700' }}>{service}</span>,
                        you can click on the cancel button if you don't want a price detail for {service}
                    </Label>

                    <Flex>

                        {
                            fullDayPopUp && <SetButton onClick={handleSetFullTime}>
                                Set Full Day
                            </SetButton>
                        }
                        {/* {
                            fullDayPopUp && <CancelBtn onClick={handleCancelFullTime}>
                               Cancel
                            </CancelBtn>
                        } */}

                        {
                            halfDayPopUp && <SetButton onClick={handleSetHalfTime}>
                                Set Half Day
                            </SetButton>
                        }
                        {
                            halfDayPopUp && <CancelBtn onClick={handleCancelHalfTime}>
                               Cancel

                            </CancelBtn>
                        }


                        {
                            hourlyPopUp && <SetButton onClick={handleSetHourly}>
                                Set Hourly Rate
                            </SetButton>
                        }
                        {/* {
                            hourlyPopUp && <CancelBtn onClick={handleCancelHourly}>
                               Cancel

                            </CancelBtn>
                        } */}

                        {
                            airportPopUp && <SetButton onClick={handleSetAirport}>
                                Set Airport
                            </SetButton>
                        }
                        {
                            airportPopUp && <CancelBtn onClick={handleCancelAirport}>
                               Cancel

                            </CancelBtn>
                        }

                        {
                            travelPopUp && <SetButton onClick={handleSetTravel}>
                                Set Travel
                            </SetButton>
                        }

                        {
                            travelPopUp && <CancelBtn onClick={handleCancelTravel}>
                               Cancel

                            </CancelBtn>
                        }
                        {
                            fullMonthPopUp && <SetButton style={{background:'#000'}} onClick={handleSetFullMonth}>
                                Set Full Month
                            </SetButton>
                        }

                        {
                            fullMonthPopUp && <CancelBtn onClick={handleCancelFullMonth}>
                               Cancel

                            </CancelBtn>
                        }
                        {
                            halfMonthPopUp && <SetButton onClick={handleSetHalfMonth}>
                                Set Half Month
                            </SetButton>
                        }

                        {
                            halfMonthPopUp && <CancelBtn onClick={handleCancelHalfMonth}>
                               Cancel

                            </CancelBtn>
                        }


                    </Flex>
                </TextContainer>


            </Modal>
        </ModalContainer>

    )
}


