import React, { useRef } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {
  ReceiptActionFooter,
  ReceiptBodyWrap,
  ReceiptHead,
  ReceiptSummaryBottom,
  ReceiptSummaryTop,
  SubfooterCards,
  TripSummaryBox,
} from "./receiptpage.styles";
import { jsPDF } from "jspdf";

import profile from "../../../assets/images/png/wallet-profile.png";
import car from "../../../assets/images/png/trips-card-car.png";
import download from "../../../assets/images/svg/icon-download.svg";
import send from "../../../assets/images/svg/icon-send.svg";
import canada from "../../../assets/images/png/canada.png";
import nigeria from "../../../assets/images/png/nigeria.png";
import usa from "../../../assets/images/png/usa.png";
import { useLocation } from "react-router-dom";
import { baseImageUrl } from "../../../services/utils";
import html2canvas from "html2canvas";

const Index = () => {
  const doc = new jsPDF();

  const location = useLocation();
  const { tripDetails } = location.state || {}; // Access the state data
  const receiptRef = useRef(null);

  const downloadImage = async () => {
    if (receiptRef.current) {
      const canvas = await html2canvas(receiptRef.current);
      const dataUrl = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "Roadlers_Reciept.png";
      link.click();
    }
  };
  // const currency=["USD", "CAD", "NGN"]
  return (
    <>
      {/* <Header /> */}
      <ReceiptBodyWrap ref={receiptRef}>
        <div className="inner-receipt-body-wrap">
          <ReceiptHead>
            <img src={profile} alt="" />
            <p>Hi {tripDetails?.firstname}</p>
          </ReceiptHead>

          <ReceiptSummaryTop>
            <div className="date-type-divide">
              <p>{tripDetails?.complete_date}</p>

              <p>{tripDetails?.category}</p>
            </div>

            <div className="card-wrap">
              <div className="top-section">
                <div className="text-side">
                  <h4>
                    Your Receipt For Your Booking - {tripDetails?.booking_title}
                  </h4>
                </div>

                <div className="image-side">
                  <img
                    src={`${baseImageUrl}${tripDetails?.book_picture}`}
                    alt=""
                  />
                </div>
              </div>

              <div className="bottom-section">
                <div className="left-title">
                  <h4>Total:</h4>
                </div>

                <div className="right-value">
                  <h4>
                    {tripDetails?.currency_code}
                    {tripDetails?.amount}
                  </h4>
                </div>
              </div>
            </div>
          </ReceiptSummaryTop>

          <ReceiptSummaryBottom>
            <div className="rate-type-header">
              {/* <p>Hourly Rate</p> */}
              <p>Booking ID</p>
            </div>

            <div className="trip-summary-header">
              {/* <div className="wrap">12 Hours</div>
              <div className="wrap">$25/H</div>
              <div className="wrap">$264</div> */}
              <div className="wrap">{tripDetails?.booking_id}</div>
            </div>

            <TripSummaryBox>
              <div className="inner-trip-wrap">
                {/* <p className="left-text">Insurance (PAI)</p>

                <p className="right-text">$ 2.5</p> */}
                <p className="left-text">Pickup Location </p>

                <p className="right-text">{tripDetails?.pickup_location}</p>
              </div>
            </TripSummaryBox>

            <TripSummaryBox>
              <div className="inner-trip-wrap">
                {/* <p className="left-text">Paystack charges </p>

                <p className="right-text">$3.5</p> */}
                <p className="left-text">Destination</p>

                <p className="right-text">{tripDetails?.destination}</p>
              </div>
            </TripSummaryBox>

            {/* <TripSummaryBox>
              <div className="inner-trip-wrap">
                <p className="left-text">Promotion</p>

                <p className="right-text">-$10</p>
              </div>
            </TripSummaryBox>

            <TripSummaryBox>
              <div className="inner-trip-wrap">
                <p className="left-text">Tax fee</p>

                <p className="right-text">$34.32</p>
              </div>
            </TripSummaryBox> */}

            <ReceiptActionFooter>
              <div className="receipt-footer-wrap">
                <SubfooterCards onClick={downloadImage}>
                  <img src={download} alt="" />
                  <p>Download</p>
                </SubfooterCards>

                {/* <SubfooterCards>
                  <img src={send} alt="" />
                  <p>Send Email</p>
                </SubfooterCards> */}

                {tripDetails?.currency_code === "CAD" && (
                  <SubfooterCards>
                    <img src={canada} alt="" />
                    <p>CAD</p>
                  </SubfooterCards>
                )}
                {tripDetails?.currency_code === "USD" && (
                  <SubfooterCards>
                    <img src={usa} alt="" />
                    <p>USD</p>
                  </SubfooterCards>
                )}
                {tripDetails?.currency_code === "NGN" && (
                  <SubfooterCards>
                    <img src={nigeria} alt="" />
                    <p>NGN</p>
                  </SubfooterCards>
                )}
                {tripDetails?.currency_code === "CAD" && (
                  <SubfooterCards>
                    <img src={canada} alt="" />
                    <p>CAD</p>
                  </SubfooterCards>
                )}
              </div>
            </ReceiptActionFooter>
          </ReceiptSummaryBottom>
        </div>
      </ReceiptBodyWrap>
      {/* <Footer /> */}
    </>
  );
};

export default Index;
