import styled from 'styled-components'

export const CheckoutPayWrapper = styled.div `
    display: ${(props:any) => props.display};
    position: relative;
`

export const SelectWalletBtnWrap = styled.div `
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
`

export const CheckoutSelectBtn = styled.div `
    display: flex;
    background-color: #09051C;
    justify-content: space-between;
    border-radius: 7px;
    padding: 10px 13px;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    & p {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: #fff;
        white-space: nowrap;
    }

    @media only screen and (max-width: 454px)  {
        border-radius: 5px;

        p {
            font-size: 10px;
        }
    } 
`

export const DropdownYellowBox = styled.div `
    background-color: #FFD58C;
    border-radius: 3px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
        width: 10px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 3px;
        border-radius: 2px;

        img {
            width: 7px;
        }
    } 
`

export const CheckoutPayBtn = styled.div `
    margin-top: 20px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    background-color: #242321;

    @media only screen and (max-width: 454px)  {
        font-size: 12px;
    } 
`