import styled from 'styled-components'



export const Input = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:9px;
overflow:hidden;
margin-top:5px;
justify-content:space-around;
padding:12px 12px;
position:relative;

`
export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:8px 12px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
display:flex;
align-items:center;
justify-content:center;
gap:10px;
`
export const Text = styled.div`
font-size:16px;
margin-top:3px;
white-space:nowrap;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
flex-direction:column;
`

export const Div = styled.div`
margin-top:15px;
`
export const Label = styled.label`
font-size: 12px;
color: #fff;
margin-left:20px;
  
`
export const InputField = styled.input`
color: #868686;
font-size: 13px;
border:none;
  
`
export const TextField = styled.input`
color: #000;
font-weight:600;
font-size: 16px;
border:none;
  outline:none;
  width:65%;
`

export const FlexDiv = styled.div`
display:flex;
align-items:center;
gap:15px; 
`
export const InputFields = styled.input`
color: #000;
font-weight:bold;
font-size: 15px;
border:none;
  padding:20px;
  border-radius:7px;
  width:50%;
`


export const DIV = styled.span`
display:flex;
align-items:center;
background:#fff;
width:100%;
border-radius:9px;
padding:0px 8px;
margin-top:8px;
justify-content:space-between;

`


export const INPUTS = styled.input`
width:50%;
padding:10px 0px;
border:none;
outline:none;
width:100%;
font-size:16px;
`
