import React, { useEffect, useState } from "react";
import { QuestionImg } from "../../pages/user/CarBooking/CarBooking.styles";
import { OwnersCardWrap } from "./ownersCard.styles";

import stars from "../../assets/images/svg/stars.svg";
import location from "../../assets/images/svg/location-black.svg";
import { Link } from "react-router-dom";

const OwnersNoteCard = ({ cardtype, vehicleInfoArray }: any) => {
  const address =
    vehicleInfoArray?.vehicleData[0]?.vehicle_location ||
    vehicleInfoArray?.vehicleData[0]?.vehicle_address;
  const [locationInfo, setLocationInfo] = useState<{
    city?: string;
    country?: string;
  } | null>(null);
  async function getCityStateFromAddress(address: string) {
    const apiKey = "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs"; // Replace with your Google Maps API key

    // Format the URL for the Google Maps Geocoding API
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      // Fetch data from the API
      const response = await fetch(url);
      const data = await response.json();

      // Check if the API returned a valid response
      if (data.status === "OK") {
        let city, country;

        // Extract city and state from the formatted address
        data.results[0].address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        return { city, country };
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  useEffect(() => {
    getCityStateFromAddress(address)
      .then((result) => {
        if (result) {
          setLocationInfo(result);
        } else {
          console.log("Failed to fetch city and country");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [locationInfo, address]);

  return (
    <OwnersCardWrap>
      <div className="inner-card-wrap">
        <div className="headers-wrap">
          <h3>
            {cardtype === "driver" && "Driver's Note"}
            {cardtype === "car" && "About"}
            {cardtype === "travel" && "Owners Note"}
          </h3>
          <hr />
        </div>

        <div className="location-wrap">
          {cardtype !== "travel" && (
            <>
              <QuestionImg width={"25px"} src={location} />
              {locationInfo && (
                <p>{locationInfo?.city + " " + locationInfo?.country}</p>
              )}
            </>
          )}
        </div>

        <div className="content-wrap">
          {cardtype === "driver" && (
            <>
              <p>
                At Roadlers, our executive drivers are professionally trained
                individuals dedicated to your safety and comfort. He/she would
                work with you{" "}
                <b>
                  10 hours daily, extra hours are charged at 1,000 naira hourly.
                </b>
              </p>

              <p>
                <b>Traveling across Nigeria?</b> make an out of state booking
                and our support team will assist you in completing your order.
              </p>
            </>
          )}
          {cardtype === "car" && (
            <p>
              Explore flexible vehicle rental options with Roadlers. Whether you
              need a car for an hourly, for a day, weekly or monthly, we've got
              you covered. Our standard daily rental includes 10 hours, with the
              option for special requests for services outside the state. Have
              questions? Contact our support team at{" "}
              <a href="mailto:support@roadlers.com">support@roadlers.com </a>
              for assistance
            </p>
          )}
          {cardtype === "travel" && (
            <p>
              Please ensure accurate passenger details are entered in your
              booking ticket as they will be used for access verification into
              the vehicle. For special inquiries or chartering a vehicle for
              travel, reach out to{" "}
              <a href="mailto:support@roadlers.com">support@roadlers.com </a>
              Your safety and satisfaction are our priorities at Raodlers
            </p>
          )}
        </div>

        <div className="owners-footer">
          {/* <QuestionImg src={stars}  /> */}

          {/* {vehicleInfoArray?.vehicleData[0]?.verified_status == "1" && (
            <div className="verified-wrap">Verified</div>
          )} */}
        </div>
      </div>
    </OwnersCardWrap>
  );
};

export default OwnersNoteCard;
