import styled from 'styled-components'

export const FundWalletThreeWrap = styled.div `
    display: ${(props: any) => props.display};
    position: relative;
`

export const HeaderSection = styled.div `
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;

    & h3 {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }

    & .right-side {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 40px;
        }
    }
`

export const GreyBox = styled.div `
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
    margin: 30px 0;
`

export const FlexInputWrap = styled.div `
    background-color: #fff;
    border-radius: 11px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);
    padding: 15px;
    margin-bottom: 18px;
    gap: 10px;
    position: relative;

    & input {
        border: none;
        outline: none;
        background-color: transparent;
        width: 100%;

        &::placeholder {
            color: #868686;
            font-size: 13px;
        }
    }

    & img {
        width: 30px;
    }

    & label {
        color: #868686;
        font-size: 11px;
    }

    & p {
        color: #868686;
        font-size: 13px;
        margin: 0;

        &.collect-money {
            color: #000;
        }
    }

    p.error-msg {
        position: absolute;
        font-size: 8px;
        color: red;
        bottom: -13px;
        left: 0px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 10px 15px;
        border-radius: 6px;

        input {
            font-size: 10px;

            &::placeholder {
                font-size: 10px;
            }
        }

        img {
            width: 15px;
        }

        p {
            font-size: 10px;
        }

        label {
            font-size: 8px;
        }
    }
`

export const BoxesWrap = styled.div `
    display: flex;
    align-items: center;
    gap: 10px;
`

export const ColumnWrap = styled(FlexInputWrap) `
    flex-direction: column;
    padding: 10px 15px;
    align-items: unset;
    gap: 3px;
    flex: 0.5;
`

export const InputBottomWrap = styled(FlexInputWrap) `
    flex: 0.5;

    & p {
        font-size: 11px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 14px;
        p {
            font-size: 8px;
        }
    }
`